import React, { useContext } from 'react';
import { Metadata } from '@latitude/metadata';
import Layout from '@/components/layout';
import Lframe from '@/components/Lframe/Lframe';
import { SITE_URL, TITLE_SUFFIX } from '@/utils/constants';
import Hero from './_hero';
import DigitalWalletsLinks from './_links';
import { PageContext } from '@/context/PageContext';
import HeroBanner from '@/components/lab-components/HeroBanner';

const DigitalWalletsPage = ({ location }) => {
  /** Contentful Data */
  const contentfulPageData = useContext(PageContext);
  const state = contentfulPageData?.updatedBody || {};
  /** - END - */
  return (
    <Layout location={location}>
      <main className="navigation-spacer">
        <Metadata
          title={`Add your credit card to your digital wallet | ${TITLE_SUFFIX}`}
          description="Digital wallets are now available to Latitude customers.."
          canonical={`${SITE_URL}${location.pathname}`}
        />
        {state?.heroBannerData?.[0] ? (
          <HeroBanner {...state?.heroBannerData[0]} />
        ) : (
          <>
            <Lframe payAnyWay />
            <Hero />
          </>
        )}
        <DigitalWalletsLinks location={location} />
      </main>
    </Layout>
  )
};

export default DigitalWalletsPage;
