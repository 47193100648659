import React, { Component } from 'react';
import classnames from 'classnames';
import { trackEvent } from 'latitude-analytics';
import './_fee-savings-calculator.scss';

class FeeSavingsCalculator extends Component {
  componentDidMount() {
    const id = 'fee-savings-calculator-script';
    const script = document.createElement('script');

    script.id = id;
    script.src = '/calculator/static/js/calculator.js?v=20180514';
    script.async = true;
    script.onload = () => {
      const sliderKnob = document.querySelector('.range-slider-knob');
      const sliderInput = document.querySelector('.range-slider-hidden');

      sliderKnob.addEventListener('mouseup', () => {
        if (trackEvent) {
          trackEvent(this.props.analytics, {
            category: 'calculator',
            action: 'interaction',
            label: 'spend-slider',
            location: 'Calculate your savings',
            value: sliderInput.value
          });
        }
      });
    };
    document.body.appendChild(script);
  }

  render() {
    return (
      <div
        className={classnames(
          'fee-savings-calculator',
          this.props.isBasic && 'fee-savings-calculator--basic'
        )}
      >
        <div id="app" />
      </div>
    );
  }
}

export default FeeSavingsCalculator;
