import React, { useContext } from 'react';
import Layout from '../../components/layout';
import Hero from '../../components/Hero/Hero';
import Metadata from '../../components/Metadata/Metadata';
import utilityHero from '../../images/hero/utility-hero.jpg';
import Section from '../../components/Section/Section';
import CardLinks from '../../components/CardLinks/CardLinks';
import { CardGroup, CardGroupItem } from '../../components/CardGroup/CardGroup';
import { PageContext } from '@/context/PageContext';
import HeroBanner from '@/components/lab-components/HeroBanner';

const GetCreditScore = props => { 
  /** Contentful Data */
  const contentfulPageData = useContext(PageContext);
  const state = contentfulPageData?.updatedBody || {};
  /** - END - */

  return (
    <Layout location={props.location}>
      <main className="navigation-spacer">
        <Metadata
          title="Finance Brokers"
          description="Do you have any questions or concerns? Find out the different ways you can contact us at Latitude Financial Services."
          canonical="/brokers/get-credit-score/"
        />

      {state?.heroBannerData?.[0] ? (
          <HeroBanner {...state?.heroBannerData[0]} />
        ) : (
        <Hero
          h1="Gemstone Calculator"
          bg={utilityHero}
          darkBg
          intro="The Gemstone Calculator is currently unavailable, please bear with us while we build you a better one – apologies for any inconvenience. Need to discuss a deal? Contact your Latitude Relationship Manager or contact Broker Support on 1800 035 902."
        />
      )}

        <Section>
          <CardGroup>
            <CardGroupItem col={3}>
              <CardLinks
                title="Our offering"
                listLinks={[
                  { title: 'Loans', href: '/loans/' },
                  { title: 'Credit Cards', href: '/credit-cards/' }
                ]}
              />
            </CardGroupItem>
            <CardGroupItem col={3}>
              <CardLinks
                title="Tools and calculators"
                listLinks={[
                  {
                    title: 'Loan repayment calculator',
                    href:
                      '/calculators-and-tools/personal-loan-repayment-calculator/'
                  },
                  {
                    title: 'Budget planning tool',
                    href: '/budget-planner/'
                  }
                ]}
              />
            </CardGroupItem>
            <CardGroupItem col={3}>
              <CardLinks
                title="About us"
                listLinks={[
                  {
                    title: 'Who we are',
                    href: '/about-us/'
                  },
                  { title: 'Products', href: '/about-us/' },
                  {
                    title: 'Leadership team',
                    href: '/about-us/our-company/#leadership-team'
                  },
                  { title: 'Media', href: '/about-us/news-room/' },
                  { title: 'Careers', href: '/careers/' },
                  { title: 'Investor relations', href: '/investor-relations/' }
                ]}
              />
            </CardGroupItem>
          </CardGroup>
        </Section>
      </main>
    </Layout>
  )
};
export default GetCreditScore;
