import React from 'react';
import styled from 'styled-components';
import { BREAKPOINT, CARD_COLOR } from '../../utils/constants';

import LFRAME_HOME from './images/lframe-home.webp';
import LFRAME_LOANS from './images/lframe-loans.webp';
import LFRAME_PIF from './images/lframe-pay-it-faster.png';
import LFRAME_COMPARE_LOANS from './images/lframe-compare-loans.png';
import LFRAME_PAY_ANYWAY from './images/lframe-pay-any-way.png';
import LFRAME_CL from './images/lframe-new-and-used-car.webp';
import LFRAME_CARDS from './images/lframe-cards.png';
import LFRAME_IP from './images/lframe-instant-provisioning.png';
import LFRAME_PL_CALCULATOR from './images/lframe-calculator-personal-loan-repayment.webp';
import LFRAME_CL_CALCULATOR from './images/lframe-calculator-car-loan-repayment.webp';
import LFRAME_CALCULATORS from './images/lframe-calculator.png';
import LFRAME_BP from './images/lframe-calculator-budget-planner.webp';
import LFRAME_BOAT_LOAN from './images/lframe-boat-loan.webp';
import LFRAME_CARAVAN_LOAN from './images/lframe-caravan-loan.webp';
import LFRAME_MOTORBIKE_LOAN from './images/lframe-motor-bike-loan.png';
import LFRAME_COMMERCIAL_LOAN from './images/lframe-commercial-vehicle-loan.png';
import LFRAME_DEBT_CON_LOAN from './images/lframe-debt-con-v2.webp';
import LFRAME_TRAVEL_LOAN from './images/lframe-travel-loan.webp';
import LFRAME_HOME_RENO_LOAN from './images/lframe-home-reno-loan.webp';
import LFRAME_BETTER_THINGS from './images/lframe-better-things.png';
import LFRAME_RATE from './images/lframe-determine-rate.png';
import LFRAME_CONTACT from './images/lframe-contact-us.png';
import LFRAME_HARDSHIP from './images/lframe-financial-hardship.png';
import LFRAME_BIF from './images/lframe-business-if.png';
import LFRAME_PL_APPLY from './images/lframe-apply.png';
import LFRAME_BROKERS from './images/lframe-brokers.png';
import LFRAME_ABOUTUS from './images/lframe-about-us.png';
import LFRAME_SOLAR_LOAN from './images/lframe-solar-loan.webp';
import LFRAME_TRAVEL_INSPIRATION from './images/lframe-inspiration.png';

import LSHAPE_BLUE from './images/personal-loan.webp';
import LSHAPE_LIGHT_BLUE from './images/lshape-light-blue.svg';
import LSHAPE_PURPLE from './images/lshape-purple.svg';

const Lframe = props => {
  const defaultBgPosSM_X = `calc(100vw - ${BREAKPOINT.SM} - ((100vw - ${BREAKPOINT.SM} ) / 2) + 15px )`;
  const calculatorPageStyles = `
        background-position: 25px 0;
        z-index: auto;

        @media (min-width: ${BREAKPOINT.MD}) {
          background-position: 54vw 0;
        }

        @media (min-width: ${BREAKPOINT.LG}) {
          background-size: 1250px auto;
          background-position: 50vw 0;
        }

        @media (min-width: ${BREAKPOINT.XL}) {
          background-position: 53vw 0;
        }`;

  const L = styled.div`
        position: absolute;
        top: 0;
        right: 0;
        z-index: 1;
        width: 100vw;
        height: 1237px;
        background-repeat: no-repeat;
        pointer-events: none;

        background-size: 720px;
        background-position: 15px 0;

        @media (min-width: ${BREAKPOINT.SM}) {
          background-position: ${defaultBgPosSM_X} 0;
        }

        @media (min-width: ${BREAKPOINT.MD}) {
          background-size: 992px;
          background-position: 50vw 0;
        }

        @media (min-width: ${BREAKPOINT.LG}) {
          background-size: 1200px;

        }

      /* Home */
      ${props => {
        return (
          props.home &&
          `background-image: url(${LFRAME_HOME});
          @media (max-width: ${BREAKPOINT.SM}) {
            right:0px;
            background-size: 300px;
            background-position: 75px 0;
            height:300px;
            width:430px;
            top: 30px;
          }
          
        @media (min-width: ${BREAKPOINT.MD}) {
          top: -48px;
          background-position: 50vw 7vw;
          background-size:auto;
        }
        `
        );
      }}

      /* Loans */
      ${props => {
        return (
          props.loans &&
          `
          background-image: url(${LFRAME_LOANS});
          height: 1267px;
          background-size: 770px;
        `
        );
      }}

      /* Pay it fast */
      ${props => {
        return props.payItFaster && `background-image: url(${LFRAME_PIF});`;
      }}

      /* Compare Our Loans */
      ${props => {
        return (
          props.compareLoans &&
          `background-image: url(${LFRAME_COMPARE_LOANS});`
        );
      }}

      /* Pay Any Way */
      ${props => {
        return (
          props.payAnyWay && `background-image: url(${LFRAME_PAY_ANYWAY});`
        );
      }}

      /* Personal Loan */
      ${props => {
        return props.personalLoans && `background-image: url(${LSHAPE_BLUE});`;
      }}

      /* Car Loan */
      ${props => {
        return (
          props.carLoans &&
          `
          background-image: url(${LFRAME_CL});
          @media (max-width: ${BREAKPOINT.LG}) {
            top: 30px;
          }
        `
        );
      }}

      /* Cards */
      ${props => {
        return (
          props.cards &&
          `background-image: url(${LFRAME_CARDS});
        @media (max-width: ${BREAKPOINT.SM}) {
          right:0px;
          background-size: 300px;
          background-position: 75px 0;
          height:300px;
          width:430px;
          top: 0px;
        }
        
      @media (min-width: ${BREAKPOINT.MD}) {
        top: -48px;
        background-position: 53vw 9vw;
        background-size:auto;
      }
        `
        );
      }}

      /* Instant provisioning */
      ${props => {
        return (
          props.instantProvisioning && `background-image: url(${LFRAME_IP});`
        );
      }}

      /* Infinity */
      ${props => {
        return props.infinity && `background-image: url(${LSHAPE_PURPLE});`;
      }}

      /* Card low rate */
      ${props => {
        return (
          props.cardLowRate && `background-image: url(${LSHAPE_LIGHT_BLUE});`
        );
      }}

      /* Card low rate for mobile layout*/
      ${props => {
        return (
          props.cardLowRate &&
          props.mobileLayout &&
          `
          background-image: url(${LSHAPE_LIGHT_BLUE});
          @media (max-width: ${BREAKPOINT.LG}) {
            height: 288px;
          }
          height: 500px;
          `
        );
      }}

      /* Simple */
      ${props => {
        return (
          props.simple &&
          `
        @media (min-width: 0) {
          max-height: 280px;
          background-position: 50vw 0;
        }
        `
        );
      }}

      /* PL calculator */
      ${props => {
        return (
          props.personalLoanRepaymentCalculator &&
          `
          background-image: url(${LFRAME_PL_CALCULATOR});
          ${calculatorPageStyles}
        `
        );
      }}

      ${props => {
        return (
          props.carLoanRepaymentCalculator &&
          `
          background-image: url(${LFRAME_CL_CALCULATOR});
          ${calculatorPageStyles}
        `
        );
      }}

      ${props => {
        return (
          props.budgetPlanner &&
          `
          background-image: url(${LFRAME_BP});
          ${calculatorPageStyles}
          @media (min-width: ${BREAKPOINT.LG}) {
            background-size: 880px auto;
          }
        `
        );
      }}

      ${props => {
        return (
          props.calculators &&
          `
          background-image: url(${LFRAME_CALCULATORS});
        `
        );
      }}

      /* boat loan */
      ${props => {
        return props.boatLoans && `background-image: url(${LFRAME_BOAT_LOAN});`;
      }}

      /* caravan loan */
      ${props => {
        return (
          props.caravanLoans && `background-image: url(${LFRAME_CARAVAN_LOAN});`
        );
      }}

      /* motor bike loan */
      ${props => {
        return (
          props.motorbikeLoans &&
          `background-image: url(${LFRAME_MOTORBIKE_LOAN});`
        );
      }}

      /* commercial vehicle loan */
      ${props => {
        return (
          props.commercialVehicleLoans &&
          `background-image: url(${LFRAME_COMMERCIAL_LOAN});`
        );
      }}

      /* debt consolidation */
      ${props => {
        return (
          props.debtConsolidationLoan &&
          `background-image: url(${LFRAME_DEBT_CON_LOAN});`
        );
      }}

      /* debt consolidation for loans1*/
      ${props => {
        return (
          props.debtConsolidationLoan &&
          props.loans1 &&
          `
          background-image: url(${LFRAME_DEBT_CON_LOAN});
          @media (max-width: ${BREAKPOINT.LG}) {
            height: 288px;
          }
          height: 398px;
          `
        );
      }}

      /* travel loan */
      ${props => {
        return (
          props.travelLoan && `background-image: url(${LFRAME_TRAVEL_LOAN});`
        );
      }}

      /* home reno loan */
      ${props => {
        return (
          props.homeReno && `background-image: url(${LFRAME_HOME_RENO_LOAN});`
        );
      }}

      /* Solar loan */
      ${props => {
        return (
          props.solarLoan && `background-image: url(${LFRAME_SOLAR_LOAN});`
        );
      }}

      /* Make better things happen */
      ${props => {
        return (
          props.betterThings &&
          `
          background-image: url(${LFRAME_BETTER_THINGS});
          @media (max-width: ${BREAKPOINT.LG}) {
            top: 36px;
          }
        `
        );
      }}

      /* Determine your rate */
      ${props => {
        return (
          props.determineRate &&
          `
          background-image: url(${LFRAME_RATE});
          background-size: 850px !important;
          @media (max-width: ${BREAKPOINT.LG}) {
            background-size: 650px !important;
          }
        `
        );
      }}

      /* Contact us */
      ${props => {
        return props.contactUs && `background-image: url(${LFRAME_CONTACT});`;
      }}

      /* Financial hardship */
      ${props => {
        return (
          props.financialHardship &&
          `
          background-image: url(${LFRAME_HARDSHIP});
          @media (max-width: ${BREAKPOINT.LG}) {
            top: 30px;
          }
        `
        );
      }}
      /* Business IF */
      ${props => {
        return (
          props.businessIF &&
          `
      background-image: url(${LFRAME_BIF});

        `
        );
      }}

      /* Personal Loan Apply */
      ${props => {
        return (
          props.personalLoanApply &&
          `
          background-image: url(${LFRAME_PL_APPLY});
          position: relative;
          background-size: 491px;
          height: 130px;
          width: 100%;
          background-color: ${CARD_COLOR.BLUE_LIGHTER};

          @media (min-width: ${BREAKPOINT.XS}) {
            background-size: 550px;
            height: 145px;
          }

          @media (min-width: ${BREAKPOINT.SM}) {
            background-position: top right;
          }

          @media (min-width: ${BREAKPOINT.MD}) {
            background-size: 750px;
            height: 195px;
          }

          @media (min-width: ${BREAKPOINT.LG}) {
            background-size: 900px;
            height: 235px;
          }

          @media (min-width: ${BREAKPOINT.XL}) {
            background-size: 1150px;
            height: 240px;
          }
        `
        );
      }}

      /* Broker page */
      ${props => {
        return (
          props.brokers &&
          `
          z-index: 0;
          background-image: url(${LFRAME_BROKERS});
          @media (max-width: ${BREAKPOINT.LG}) {
            top: 32px;
          }

        `
        );
      }}

      /* TravelInspirtation page */
      ${props => {
        return (
          props.travel &&
          `
          z-index: 0;
          background-image: url(${LFRAME_TRAVEL_INSPIRATION});
          @media (max-width: ${BREAKPOINT.LG}) {
            top: 32px;
          }

        `
        );
      }}

        /* About us */
        ${props =>
          props.aboutUs &&
          `
          background-image: url(${LFRAME_ABOUTUS});
          background-size: 770px;
          `}
      /*
       * Following styles are global overrides, should be kept at bottom
       */

      /* If page has a sticky cta, need to drop the lframe so it does not get cut off */
      ${props => {
        return (
          props.hasMobileStickyCta &&
          `
        @media (max-width: ${BREAKPOINT.LG}) {
          top: 48px;
        }
      `
        );
      }}

      /* Background image */
      ${({ bgImage, title }) => {
        return (
          bgImage &&
          `background-image: url(${bgImage});

          ${title && ` title="${title}"`}`
        );
      }}

      // end of L styled.div styles
  `;

  if (props.ariaLabel) {
    return (
      <L {...props}>
        <span role="img" aria-label={props.ariaLabel} />
      </L>
    );
  } else {
    return <L {...props} />;
  }
};

export default Lframe;
