import React, { useEffect, useContext } from 'react';
import Helmet from 'react-helmet';
import styled from 'styled-components';
import {
  BREAKPOINT,
  BUTTON_STYLE
} from '@latitude/core/utils/constants';
import { Flex } from '@latitude/box';
import { AnalyticsLocationProvider } from 'latitude-analytics';
import { Link } from '@latitude/link';
import { Accordion } from '@latitude/accordion';
import { ImportantInformation } from '@latitude/important-information';
import StickyNavigationBranded from '@/components/StickyNavigation/StickyNavigationBranded';
import HeroBranded, {
  HeroImage
} from '../../components/HeroBranded/HeroBranded';
import Layout from '../../components/layout';
import Section from '../../components/Section/Section';
import MobileAppInstallPrompts from '@latitude/mobile-app-install-prompt/MobileAppInstallPrompt';
import ManageYourLoan from '../../components/ManageYourLoan/ManageYourLoan';
import Text from '../../components/Text/Text';
import { Strong } from '../../components/Text/Bold';
import { Hero } from '@latitude/hero';
import { HeroText } from '@/components/HeroBranded/HeroBranded';
import Promo, { PromoSection } from '../../components/Promo/Promo';
import footerData from '@/data/json/footer.json';
import allDataJson from './latitudepay-closure.json';

import heroBannerImage from './images/latitudepay-decomission-banner.webp';
import whatsHappeningImage from './images/whats-happening.webp';
import goodShoppingImage from './images/good-shopping.webp';

import { PageContext } from '@/context/PageContext';
import { CONTENTFUL_COMPONENTS } from '@/utils/constants';
import PromoBanner from '@/components/lab-components/PromoBanner';
import { useSetState } from '@/utils/hooks';
import { NotificationBanner } from '@latitude/banner';
import HeroBanner from '@/components/lab-components/HeroBanner';

// const APPLY_URL = getUrlFromEnv('funnel-start-motor-loan');

const LatitudePayClosurePage = props => {
  // const applyUrl = `${getUrlFromEnv(
  //   'funnel-start-loans'
  // )}${SOFT_QUOTE_LOANS_AU_URL}`;
  // const [modalIsOpen, setModalIsOpen] = useState(false);
  // const [fixedVsVariableModalIsOpen, setFixedVsVariableModalIsOpen] = useState(
  //   false
  // );
  // const PL_TRACK_PRODUCT_LOCATION = 'Personal Loan';

  /** Contentful Data */
  const contentfulPageData = useContext(PageContext);
  const state = contentfulPageData?.updatedBody || {};
  /** - END - */

  return (
    <Layout location={props.location} customFooter={footerData}>
      <MobileAppInstallPrompts />
      <main
        className="navigation-spacer"
        css={`
          background-color: #f8f8f8;
        `}
      >
        <Helmet>
          <link
            rel="canonical"
            href="https://www.latitudefinancial.com.au/loans/"
          />
          <meta
            name="description"
            content="We offer loan types to suit many situations, including buying a car, renovating a home or travelling. Explore our personal loans & vehicle finance options."
          />
          <title>
            Choose From Our Loan Types | Latitude Financial Services
          </title>
        </Helmet>
        {!state?.alertData && (
          <NotificationBanner siteName="latitudefinancial-com-au" />
        )}

        {state?.heroBannerData?.[0] ? (
          <HeroBanner {...state?.heroBannerData[0]} />
        ) : (
          <Section
            css={`
              && {
                background-color: #0555c8;
                padding: 0;
                background-image: url(${heroBannerImage});

                h1 {
                  margin: 0;
                  font-size: 48px;
                  font-size: 3rem;
                  line-height: 50px;
                  line-height: 3.125rem;
                  color: #000;
                }

                p {
                  font-size: 18px;
                  font-size: 1.125rem;
                  line-height: 1.5;
                  color: #000;
                }

                [class^='Herostyles__HeroImage-'] {
                  display: none;
                }

                @media (min-width: ${BREAKPOINT.MD}) {
                  [class^='Herostyles__HeroContent-'] {
                    min-width: 60%;
                  }

                  [class^='Herostyles__HeroInner-'] {
                  }
                }

                @media (max-width: ${BREAKPOINT.MD}) {
                  && > .container.section__content {
                    padding: 0;
                  }
                }
              }
            `}
          >
            <Hero
              backgroundImage={require('./images/latitudepay-decomission-banner.webp')}
              heading="LatitudePay has closed"
              intro="Thanks for shopping with us. LatitudePay is no longer available for customers or merchants. But don’t worry, Latitude is still here for your life’s moments big and small."
              className="page-banner-lfs--bg"
            >
              {
                <div
                  id="hero"
                  css={`
                  && {
                    padding: 0 15px;
                    color: #000:

                    @media (min-width: ${BREAKPOINT.LG}) {
                      padding: 0;
                    }
                  }
                `}
                >
                  <h1>LatitudePay has closed</h1>
                  <HeroText
                    css={`
                      && {
                        color: #000;
                        letter-spacing: -0.55px;
                        margin-right: 0px;
                      }
                    `}
                  >
                    Thanks for shopping with us. LatitudePay is no longer
                    available for customers or merchants. But don't worry,
                    Latitude is still here for your life's moments big and
                    small.
                  </HeroText>
                </div>
              }
            </Hero>
          </Section>
        )}

        <div className="d-none d-lg-block" css="position:relative; z-index:10;">
          <StickyNavigationBranded
            items={allDataJson.nav}
            offsetElem="[data-sticky-navigation-offset]"
            {...state?.inPageNavData?.[0]}
          />
          {/* <StickyNavigation
            items={allDataJson.nav}
            isSticky={this.state.isNavSticky}
            onStickyNavStateChange={this.handleStickyNavStateChange}
            offsetElem="[data-sticky-navigation-offset]"
          />           */}
        </div>

        <Section className="bg-lightest" id="whats-happening">
          <PromoSection
            css={`
              padding-top: 10px !important;
              padding-bottom: 0 !important;
              .section__content {
                max-width: 962px !important;
              }
              .section__content [class^='Promo__PromoContentContainer-'] {
                padding-top: 0;
                padding-right: 0;
              }
              p[class^='Text__StyledText-'] {
                margin-top: 10px;
              }
              a[class^='Linkstyled__StyledLink-'] {
                padding: 10px;
                line-height: 14px;
                background-color: #0046aa !important;
                border-color: #0046aa !important;
              }
              @media (max-width: ${BREAKPOINT.LG}) {
                div [class^='Promo__PromoFrameContainer-'] {
                  background-color: transparent;
                }
                div [class^='Promo__PromoFrameContainer-'] img {
                  position: relative;
                  right: 20px;
                  background-color: #004ebd;
                }
              }
            `}
          >
            <Promo
              title="What's happening"
              description=" <div>
              <p>
                <ul>
                  <li>
                    LatitudePay is no longer available in Australia.
                  </li>
                  <li>
                    We will close all customer accounts once any outstanding balance has been paid.
                  </li>
                </ul>
              </p>
            </div>"
              frameImage={
                <img src={whatsHappeningImage} alt="what's happening" />
              }
              frameAlignment="left"
              frameBackgroundColor="#004EBD"
              frameBorderColor="transparent;"
              verticalAlignContent={false}
            />
          </PromoSection>
        </Section>

        <div
          id="faq"
          data-contentful={state?.faqData?.[0]?.contentfulID}
          css={`
            && {
              background-color: #f8f8f8;
              padding-top: 10px;
              padding-bottom: 0;
              @media (min-width: ${BREAKPOINT.LG}) {
                padding-top: 42px;
                padding-bottom: 0;
              }
            }
          `}
        >
          <AnalyticsLocationProvider
            location={state?.faqData?.[0]?.title || 'Customer FAQs'}
          >
            <ManageYourLoan
              heading={state?.faqData?.[0]?.title || 'Customer FAQs'}
              description={
                state?.faqData?.[0]?.description ||
                'As you may be aware, LatitudePay closed on 11th April 2023. Thank you for being a customer, we’ve loved helping you shop for life’s moments big and small, but we had to make the difficult decision to stop offering this service. However, we are still here to support you and here is what you need to know:'
              }
            >
              <Accordion
                titleBgColor="grey-light"
                items={
                  state?.faqData?.[0]?.data || [
                    {
                      id: 'item-1',
                      title: 'How can I request a closure letter? ',
                      content: (
                        <div className="mt-4 w-100">
                          <div className="row">
                            <div className="mt-1 col-12">
                              <Text>
                                You can request for a closure letter if you had
                                a LatitudePay account with us. You will need to
                                raise a request for an account closure letter{' '}
                                <a
                                  href="https://latitudefs.zendesk.com/hc/en-au/requests/new?ticket_form_id=19263713458321"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  here
                                </a>
                              </Text>
                            </div>
                          </div>
                        </div>
                      )
                    },
                    {
                      id: 'item-2',
                      title:
                        'What happens to the personal information you collected from me?',
                      content: (
                        <div className="w-100">
                          <div className="row">
                            <div className="col-12">
                              <Text>
                                We treat all customer information carefully and
                                you can view our{' '}
                                <a
                                  href="https://www.latitudefinancial.com.au/privacy/"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  Privacy Policy here
                                </a>
                                .<br />
                                <Strong>Please Note:</Strong> We will manage any
                                requests as per Latitude privacy policy.
                                <br />
                                If you would like to learn more on what personal
                                information LatitudePay holds you can raise a
                                request{' '}
                                <a
                                  href="https://latitudefs.zendesk.com/hc/en-au/requests/new?ticket_form_id=19263713458321"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  here
                                </a>
                              </Text>
                            </div>
                          </div>
                        </div>
                      )
                    },
                    {
                      id: 'item-3',
                      title:
                        'How can I view my purchase history, can I log in?',
                      content: (
                        <div className="w-100">
                          <div className="row">
                            <div className="mt-1 col-12">
                              <Text>
                                Unfortunately, you can no longer log into the
                                customer portal as it is now closed. However,
                                you can raise a request to obtain previous plan
                                history data{' '}
                                <a
                                  href="https://latitudefs.zendesk.com/hc/en-au/requests/new?ticket_form_id=19263713458321"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  here
                                </a>
                              </Text>
                            </div>
                          </div>
                        </div>
                      )
                    },
                    {
                      id: 'item-4',
                      title: 'How do Returns and Refunds work?',
                      content: (
                        <div className="w-100">
                          <div className="row">
                            <div className="mt-1 col-12">
                              <Text>
                                Returns and refunds will always be determined by
                                the returns policy of the individual retailer or
                                service provider you bought the item or service
                                from. You should be able to find out your
                                options by checking their returns policy on
                                their website or contacting them directly.
                                <br />
                                <br />
                                If you need to return something, you will have
                                to talk to the merchant directly. If you have
                                any concerns with merchant providing refund,
                                please raise a request{' '}
                                <a
                                  href="https://latitudefs.zendesk.com/hc/en-au/requests/new?ticket_form_id=19263713458321"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  here
                                </a>
                                .
                              </Text>
                            </div>
                          </div>
                        </div>
                      )
                    },
                    {
                      id: 'item-5',
                      title: 'Can I contact someone about my account?',
                      content: (
                        <div className="w-100">
                          <div className="row">
                            <div className="mt-1 col-12">
                              <Text>
                                Even though the product is now closed you can
                                still get in touch with our team{' '}
                                <a
                                  href="https://latitudefs.zendesk.com/hc/en-au/requests/new?ticket_form_id=19263713458321"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  here
                                </a>
                                .
                              </Text>
                            </div>
                          </div>
                        </div>
                      )
                    },
                    {
                      id: 'item-6',
                      title: 'What will appear on my credit file?',
                      content: (
                        <div className="w-100">
                          <div className="row">
                            <div className="mt-1 col-12">
                              <Text>
                                You can learn more about this{' '}
                                <a
                                  href="https://latitudefs.zendesk.com/hc/en-au/articles/4405579502097-Comprehensive-Credit-Reporting"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  here
                                </a>
                                .
                              </Text>
                            </div>
                          </div>
                        </div>
                      )
                    },
                    {
                      id: 'item-7',
                      title:
                        'There is an error on my credit file that was reported by LatitudePay, what should I do?',
                      content: (
                        <div className="w-100">
                          <div className="row">
                            <div className="mt-1 col-12">
                              <Text>
                                If you believe LatitudePay has reported
                                something in error regarding your credit
                                history, you are able to contact us{' '}
                                <a
                                  href="https://latitudefs.zendesk.com/hc/en-au/requests/new?ticket_form_id=19263713458321"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  here
                                </a>
                                .
                              </Text>
                            </div>
                          </div>
                        </div>
                      )
                    },
                    {
                      id: 'item-8',
                      title:
                        'How long is information retained on my Credit Report?',
                      content: (
                        <div className="w-100">
                          <div className="row">
                            <div className="mt-1 col-12">
                              <Text>
                                Please see below table extracted from the Office
                                of the Australian Information Commissioner:{' '}
                                <br />
                                <table
                                  class="tg table-bordered"
                                  border="1"
                                  cellspacing="0"
                                  cellpadding="5"
                                >
                                  <thead>
                                    <tr>
                                      <th class="tg-0lax">
                                        Types of Credit Information
                                      </th>
                                      <th class="tg-0lax">
                                        Maximum Reporting Period
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td>
                                        <p>Credit Enquiry&nbsp;</p>
                                      </td>
                                      <td>
                                        <p>5 years</p>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <p>
                                          Current consumer credit obligations
                                        </p>
                                      </td>
                                      <td>
                                        <p>
                                          2 years (from the end of the consumer
                                          credit)
                                        </p>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <p>
                                          Repayment history information&nbsp;
                                        </p>
                                      </td>
                                      <td>
                                        <p>2 years</p>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <p>Financial Hardship Information</p>
                                      </td>
                                      <td>
                                        <p>1 year&nbsp;</p>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <p>Default Information&nbsp;</p>
                                      </td>
                                      <td>
                                        <p>5 years</p>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <p>
                                          Serious credit infringement
                                          information&nbsp;
                                        </p>
                                      </td>
                                      <td>
                                        <p>7 years</p>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <p>Judgments&nbsp;</p>
                                      </td>
                                      <td>
                                        <p>
                                          5 years from date of judgment&nbsp;
                                        </p>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <p>Bankruptcy&nbsp;</p>
                                      </td>
                                      <td>
                                        <p>The later of:</p>

                                        <ul>
                                          <li>
                                            5 years starting on the day you
                                            became bankrupt, or
                                          </li>
                                          <li>
                                            2 years starting on the day you were
                                            no longer bankrupt
                                          </li>
                                        </ul>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <p>Debt Agreement</p>
                                      </td>
                                      <td>
                                        <p>The later of:</p>

                                        <ul>
                                          <li>
                                            5 years from the day the agreement
                                            was made
                                          </li>
                                          <li>
                                            2 years from the day the agreement
                                            was:
                                          </li>
                                        </ul>

                                        <p>terminated</p>

                                        <p>
                                          o&nbsp;&nbsp;&nbsp;&nbsp;ends when the
                                          agreement ends under s 185N of the
                                          Bankruptcy Act 1966
                                        </p>

                                        <p>
                                          o&nbsp;&nbsp;&nbsp;&nbsp;an order was
                                          made declaring the agreement void
                                        </p>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </Text>
                            </div>
                          </div>
                        </div>
                      )
                    },
                    {
                      id: 'item-9',
                      title: 'How can I make a complaint?',
                      content: (
                        <div className="w-100">
                          <div className="row">
                            <div className="mt-1 col-12">
                              <Text>
                                If you have a complaint, you can let us know{' '}
                                <a
                                  href="https://latitudefs.zendesk.com/hc/en-au/articles/360020889237-Making-a-Complaint"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  here
                                </a>
                                .
                              </Text>
                            </div>
                          </div>
                        </div>
                      )
                    },
                    {
                      id: 'item-10',
                      title: 'What does this mean for your other products?',
                      content: (
                        <div className="w-100">
                          <div className="row">
                            <div className="mt-1 col-12">
                              <Text>
                                We continue to offer our other long-standing
                                products – View our product offerings{' '}
                                <a
                                  href="https://www.latitudefinancial.com.au/"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  here
                                </a>
                                .
                              </Text>
                            </div>
                          </div>
                        </div>
                      )
                    }
                  ]
                }
              />

              {/* <Responsive spacing={MARGIN.M24}>
                <Link
                  button={BUTTON_STYLE.TERTIARY}
                  href="/mobile-app/"
                  trackId="text-link-mobile-app"
                  width="255px"
                >
                  Manage my loan in App
                </Link>
              </Responsive> */}
            </ManageYourLoan>
          </AnalyticsLocationProvider>
        </div>

        <div
          id="faq-merchant"
          data-contentful={state?.faqData?.[1]?.contentfulID}
          css={`
            && {
              background-color: #f8f8f8;
              padding-top: 10px;
              padding-bottom: 0;
              @media (min-width: ${BREAKPOINT.LG}) {
                padding-top: 42px;
                padding-bottom: 0;
              }
            }
          `}
        >
          <AnalyticsLocationProvider
            location={state?.faqData?.[1]?.title || 'Customer FAQs'}
          >
            <ManageYourLoan
              heading={state?.faqData?.[1]?.title || 'Merchant FAQs'}
            >
              <Accordion
                titleBgColor="grey-light"
                items={
                  state?.faqData?.[1]?.data || [
                    ({
                      id: 'item-1',
                      title:
                        'How do I access previous transactions or reporting?',
                      content: (
                        <div className="mt-4 w-100">
                          <div className="row">
                            <div className="mt-1 col-12">
                              <Text>
                                We are still here to assist you if you need any
                                support accessing old transaction information.
                                You can now raise a request to access any
                                previous purchase information or reporting{' '}
                                <a
                                  href="https://latitudefs.zendesk.com/hc/en-au/requests/new?ticket_form_id=18364767918225"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  here
                                </a>
                              </Text>
                            </div>
                          </div>
                        </div>
                      )
                    },
                    {
                      id: 'item-2',
                      title: 'Need help regarding a payment?',
                      content: (
                        <div className="w-100">
                          <div className="row">
                            <div className="col-12">
                              <Text>
                                You can raise a concern regarding payment
                                queries{' '}
                                <a
                                  href="https://latitudefs.zendesk.com/hc/en-au/requests/new?ticket_form_id=18364767918225"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  here
                                </a>{' '}
                                and a Latitude representative will be in contact
                                with you to assist.
                              </Text>
                            </div>
                          </div>
                        </div>
                      )
                    },
                    {
                      id: 'item-3',
                      title: 'Wish to raise a complaint?',
                      content: (
                        <div className="w-100">
                          <div className="row">
                            <div className="mt-1 col-12">
                              <Text>
                                You can raise a concern or feedback through{' '}
                                <a
                                  href="https://latitudefs.zendesk.com/hc/en-au/requests/new?ticket_form_id=18364767918225"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  here
                                </a>{' '}
                                and a Latitude representative will be in contact
                                with you to assist.
                              </Text>
                            </div>
                          </div>
                        </div>
                      )
                    },
                    {
                      id: 'item-4',
                      title:
                        'How do I remove the plug ins from my website? Where can I get support?',
                      content: (
                        <div className="w-100">
                          <div className="row">
                            <div className="mt-1 col-12">
                              <Text>
                                To remove our plugin from your website, please
                                refer to the list below and select the relevant
                                link associated with the ecommerce platform:
                                <ul>
                                  <li>
                                    <a
                                      href="https://resources.latitudefinancial.com/docs/latitude-pay/bigcommerce#5-disable-latitudepayLatitudePay"
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      BigCommerce
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      href="https://resources.latitudefinancial.com/docs/latitude-pay/magento1/#removing-plugin"
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      Magento 1
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      href="https://resources.latitudefinancial.com/docs/latitude-pay/magento2/#removeuninstall-latitudepay"
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      Magento 2
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      href="https://resources.latitudefinancial.com/docs/latitude-pay/opencart2/#uninstallation"
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      OpenCart 2
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      href="https://resources.latitudefinancial.com/docs/latitude-pay/opencart3/#uninstallation"
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      OpenCart 3
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      href="https://resources.latitudefinancial.com/docs/latitude-pay/prestashop-1-6/#uninstallation"
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      PrestaShop 1.6
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      href="https://resources.latitudefinancial.com/docs/latitude-pay/prestashop-1-7/#uninstallation"
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      PrestaShop 1.7
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      href="https://resources.latitudefinancial.com/docs/latitude-pay/shopify/#removeuninstall-latitudepay"
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      Shopify
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      href="https://resources.latitudefinancial.com/docs/latitude-pay/woocommerce/#removeuninstall-woocommerce-plugin"
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      WooCommerce
                                    </a>
                                  </li>
                                </ul>
                                For more information, please visit our{' '}
                                <a
                                  href="https://resources.latitudefinancial.com/docs/latitude-pay/bigcommerce/#5-disable-latitudepayLatitudePay"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  Resource Toolkit
                                </a>
                              </Text>
                            </div>
                          </div>
                        </div>
                      )
                    },
                    {
                      id: 'item-5',
                      title: 'Can’t see an answer to your problem?',
                      content: (
                        <div className="w-100">
                          <div className="row">
                            <div className="mt-1 col-12">
                              <Text>
                                You can raise a request through{' '}
                                <a
                                  href="https://latitudefs.zendesk.com/hc/en-au/requests/new?ticket_form_id=18364767918225"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  here
                                </a>{' '}
                                and a Latitude representative will be in contact
                                with you to assist.
                              </Text>
                            </div>
                          </div>
                        </div>
                      )
                    })
                  ]
                }
              />

              {/* <Responsive spacing={MARGIN.M24}>
                <Link
                  button={BUTTON_STYLE.TERTIARY}
                  href="/mobile-app/"
                  trackId="text-link-mobile-app"
                  width="255px"
                >
                  Manage my loan in App
                </Link>
              </Responsive> */}
            </ManageYourLoan>
          </AnalyticsLocationProvider>
        </div>
        {state?.promoBannerData?.[0] ? (
          <PromoBanner {...state?.promoBannerData[0]} />
        ) : (
          <Section className="bg-lightest" id="good-shopping">
            <PromoSection
              css={`
                padding-top: 10px !important;
                .section__content {
                  max-width: 962px !important;
                }
                .section__content [class^='Promo__PromoContentContainer-'] {
                  padding-top: 0;
                  padding-right: 0;
                }
                p[class^='Text__StyledText-'] {
                  margin-top: 10px;
                }
                a[class^='Linkstyled__StyledLink-'] {
                  padding: 10px;
                  line-height: 14px;
                  background-color: #0046aa !important;
                  border-color: #0046aa !important;
                }
                @media (max-width: ${BREAKPOINT.LG}) {
                  div [class^='Promo__PromoFrameContainer-'] {
                    background-color: transparent;
                  }
                  div [class^='Promo__PromoFrameContainer-'] img {
                    position: relative;
                    right: 20px;
                    background-color: #004ebd;
                  }
                }
              `}
            >
              <Promo
                title="Good shopping is in our DNA."
                description="When you've been around for over 100 years, you understand when your customers shopping needs are changing. That's why we know the time is right to close LatitudePay. The good news is, we have other options like a Gem Visa that offers Interest Free<sup>*</sup> payment options to keep you covered."
                frameImage={<img src={goodShoppingImage} alt="good shopping" />}
                frameAlignment="left"
                frameBackgroundColor="#004EBD"
                frameBorderColor="transparent;"
                ctaButtons={
                  <Flex
                    css={`
                      justify-content: center;
                      @media (min-width: ${BREAKPOINT.LG}) {
                        justify-content: start;
                      }
                    `}
                  >
                    <Link
                      button={BUTTON_STYLE.SECONDARY}
                      href="https://www.latitudefinancial.com.au/"
                      trackId="good-shopping-section"
                      width="200px"
                      trackEventData={{
                        location: 'good-shopping-section'
                      }}
                    >
                      Our Products
                    </Link>
                  </Flex>
                }
                verticalAlignContent={false}
              />
            </PromoSection>
          </Section>
        )}

        <ImportantInformation
          data={allDataJson.importantinfocontent}
          sectionOneColummClasses="col-10 offset-1 col-lg-5 offset-lg-1"
          sectionTwoColummClasses="col-10 offset-1 col-lg-5 offset-lg-0"
          {...state?.importantInfoData?.[0]}
        />
      </main>
    </Layout>
  );
};

const StyledHeroBranded = styled(HeroBranded)`
  ${HeroImage} {
    height: 260px;

    @media (min-width: ${BREAKPOINT.MD}) {
      height: auto;
    }
  }
`;

export default LatitudePayClosurePage;
