import { PRODUCT_NAME } from '@/utils/constants';

export default [
  {
    id: '/',
    label: `${PRODUCT_NAME} website`
  },
  {
    id: '/mobile/',
    label: `${PRODUCT_NAME} Mobile Application`
  }
];
