import { getUrlFromEnv } from '@/utils/getUrlFromEnvUtil';
import {
  SOFT_QUOTE_AU_URL,
  SOFT_QUOTE_LOANS_AU_URL,
  SOFT_QUOTE_BROKER_URL,
  SOFT_QUOTE_KB_URL,
  SOFT_QUOTE_NZ_URL,
  PL_BETTER_START_MINIMUM_AMOUNT,
  PL_BROKER_MINIMUM_AMOUNT,
  PL_MINIMUM_AMOUNT,
  PL_DEFAULT_WIDGET_AMOUNT,
  PL_SYMPLE_DEFAULT_WIDGET_AMOUNT,
  LOAN_PAY_FREQUENCY, PL_SYMPLE_DEFAULT_PERIOD, PL_DEFAULT_PERIOD,
} from '@/utils/constants';

/* redirectUrl
 * returns the correct url for soft quote
 *
 */
export const redirectUrl = (channel='au', loans1=false) => {
  let quoteUrl = '';
  let isBroker = false;
  let softQuoteBaseUrl = loans1 ? getUrlFromEnv('funnel-start-loans') : getUrlFromEnv('funnel-start-soft-quote');
  if (channel === 'broker') {
    isBroker = true;
    quoteUrl = `${softQuoteBaseUrl}${SOFT_QUOTE_BROKER_URL}`;
  } else if (channel === 'gem') {
    quoteUrl = `${softQuoteBaseUrl}${SOFT_QUOTE_NZ_URL}`;
  } else if (channel === 'kiwibank') {
    quoteUrl = `${softQuoteBaseUrl}${SOFT_QUOTE_KB_URL}`;
  } else {
    quoteUrl = loans1 ? `${softQuoteBaseUrl}${SOFT_QUOTE_LOANS_AU_URL}` : `${softQuoteBaseUrl}${SOFT_QUOTE_AU_URL}`;
  }
  // TODO: For local testing only
  // quoteUrl = '${SOFT_QUOTE_AU_URL}';

  const amount =
    (typeof window !== 'undefined' && sessionStorage.getItem('loanAmount')) ??
    (isBroker ? PL_BROKER_MINIMUM_AMOUNT : PL_MINIMUM_AMOUNT);
  const purpose =
    (typeof window !== 'undefined' && sessionStorage.getItem('purpose')) ??
    'purpose';
  const purposeOther =
    (typeof window !== 'undefined' && sessionStorage.getItem('purposeOther')) ??
    '';
  let purposeOtherText = purpose === 'other' ? `&other=${purposeOther}` : '';

  const partnername =
    typeof window !== 'undefined' && sessionStorage.getItem('partnername');
  let partnernameText =
    partnername !== undefined && partnername !== null
      ? `&partnername=${partnername}`
      : '';

  const repaymentPeriod =
    (typeof window !== 'undefined' &&
      sessionStorage.getItem('repaymentPeriod')) ??
    '5';
  const redirectUrl = `${quoteUrl}?amount=${amount}&purpose=${purpose}&period=${repaymentPeriod}${purposeOtherText}${partnernameText}`;
  return redirectUrl;
};

/* resetToDefault
 * Reset session storage values used in soft quote widget
 *
 */
export function resetToDefault(isBroker = false, purpose = 'purpose') {
  if (typeof window !== 'undefined') {
    sessionStorage.setItem(
      'loanAmount',
      isBroker ? PL_BROKER_MINIMUM_AMOUNT : PL_MINIMUM_AMOUNT
    );
    sessionStorage.setItem('purpose', purpose);
    sessionStorage.setItem('repaymentPeriod', '5');
    sessionStorage.setItem('purposeOther', '');
  }
}

// TODO: this is a hack
// using a js function as a href in a link.
// this forces the link to open in the same window.
export function softQuoteHrefFunc(url) {
  return `javascript: if( typeof window !== 'undefined' ){ window.location = '${url}' };`;
}

export function getEstimateRateWidgetDefaults({isBroker = false, isBetterStart = false, purpose = false, loans1 = false }) {
  let defaultPurpose;
  const defaultPurposeOther = '';
  let defaultLoanAmount;
  let defaultRepaymentPeriod;
  const defaultLoanPayFrequency = LOAN_PAY_FREQUENCY.MONTHLY;

  if (isBetterStart) {
    defaultPurpose = 'debt-consolidation';
    defaultLoanAmount = PL_BETTER_START_MINIMUM_AMOUNT;
    defaultRepaymentPeriod = '5.25';
    return { defaultPurpose, defaultLoanAmount, defaultRepaymentPeriod, defaultPurposeOther, defaultLoanPayFrequency }
  }
  defaultPurpose = purpose || 'purpose';
  defaultRepaymentPeriod = loans1 ? PL_SYMPLE_DEFAULT_PERIOD : PL_DEFAULT_PERIOD; // default repayment period is 5 for broker and non-broker loans
  if (isBroker) {
    defaultLoanAmount = PL_BROKER_MINIMUM_AMOUNT;
  } else {
    defaultLoanAmount = loans1 ? PL_SYMPLE_DEFAULT_WIDGET_AMOUNT : PL_DEFAULT_WIDGET_AMOUNT;
  }
  return { defaultPurpose, defaultLoanAmount, defaultRepaymentPeriod, defaultPurposeOther, defaultLoanPayFrequency }
}
