import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { Link } from '@latitude/link';
import { BUTTON_STYLE } from '@latitude/core/utils/constants';
import RatesBox from '../RatesBox/RatesBox';
import './_loan-card.scss';
import SvgInline from '../SvgInline/SvgInline';
import VSpacing from '../Spacing/VSpacing';
import { parseContent } from '../../utils/helpers';

const LoanCard = props => (
  <div className={classnames('loan-card', props.className)}>
    <div className="loan-card__header">
      {props.icon && (
        <SvgInline name={props.icon} className="loan-card__icon" />
      )}

      <h3 className="loan-card__title">{props.title}</h3>
      {props.description && (
        <p className="loan-card__description">{props.description}</p>
      )}
    </div>
    <div className="loan-card__rates">
      {props.rateBoxType && (
        <RatesBox
          isBranded={props.isBranded}
          type={props.rateBoxType}
          size={props.rateBoxSize || 'large'}
          fullWidth={props.rateBoxFullWidth}
          css="margin-bottom: 20px;"
        />
      )}
      {props.rateBoxType2 && (
        <RatesBox
          isBranded={props.isBranded}
          theme="secondary"
          type={props.rateBoxType2}
          size={props.rateBoxSize || 'large'}
          fullWidth={props.rateBoxFullWidth}
          css="margin-bottom:20px"
        />
      )}
    </div>
    {/* Use when content beneath rate box needs to be levelled */}
    {props.vSpacingAfterRateBox && (
      <VSpacing heights={props.vSpacingAfterRateBox} />
    )}
    {props.listItems1Title && props.listItems1Title}
    {props.listItems1 && (
      <ul className="loan-card__list">
        {props.listItems1.map((item, i) => (
          <li key={i} className="loan-card__list-item">
            {parseContent(item)}
          </li>
        ))}
      </ul>
    )}
    {props.listItems2Title && props.listItems2Title}
    {props.listItems2 && (
      <ul className="loan-card__list">
        {props.listItems2.map((item, i) => (
          <li key={i} className="loan-card__list-item">
            {parseContent(item)}
          </li>
        ))}
      </ul>
    )}
    <div className="loan-card__button-container">
      <Link
        {...props.button2}
        className={classnames(
          'loan-card__button button--primary',
          props.button2.className
        )}
        button={BUTTON_STYLE.PRIMARY}
        trackProductId={props.trackProductId}
        trackEventData={{
          location: props.trackProductLocation,
          ...props.button2.trackEventData
        }}
        width={props.button2.width ? props.button2.width : '100%'}
      >
        {props.button2.text}
      </Link>
      <Link
        {...props.button1}
        className={classnames('loan-card__button', props.button1.className)}
        button={BUTTON_STYLE.TERTIARY}
        width={props.button1.width ? props.button1.width : '100%'}
        trackProductId={props.trackProductId}
        trackEventData={{
          location: props.trackProductLocation
        }}
      >
        {props.button1.text}
      </Link>
    </div>
  </div>
);

/* eslint-disable react/forbid-prop-types */
LoanCard.propTypes = {
  title: PropTypes.node,
  description: PropTypes.string,
  rateBoxType: PropTypes.string,
  rateBoxType2: PropTypes.string,
  vSpacingAfterRateBox: PropTypes.object,
  listItems1: PropTypes.array,
  listItems2: PropTypes.array,
  listItems1Title: PropTypes.string,
  listItems2Title: PropTypes.string,
  button1: PropTypes.object,
  button2: PropTypes.object
};

export default LoanCard;
