import React from 'react';
import Helmet from 'react-helmet';

import Layout from '@/components/layout';
import { SecondaryHero } from '@latitude/hero';
import { Box } from '@latitude/box';
import { Heading2 } from '@latitude//heading';
import { Link } from '@latitude/link';
import { Text } from '@latitude/text';
import { COLOR } from '@latitude/core/utils/constants';

import UploadCards from './components/_uploadCards';
import { Main, BrDesktop } from './components/_uploadStyle';

const Page = props => (
  <Layout location={props.location}>
    <Main className="navigation-spacer">
      <Helmet>
        <link
          rel="canonical"
          href="https://www.latitudefinancial.com.au/upload/error"
        />
        <title>Document upload error | Latitude Financial</title>
        <meta name="description" content="Document upload error" />
      </Helmet>

      <SecondaryHero
        heading={
          <>
            Our upload tool <BrDesktop />
            had an error
          </>
        }
      />

      <Box.Section backgroundColor="transparent" padding="40px 0 0">
        <Heading2 align="center" color={COLOR.BLACK}>
          Continue uploading your documents
        </Heading2>
        <Text align="center">
          Apologies for the inconvenience, please continue uploading your
          documents. <br />
          If this error occurs again, please{' '}
          <Link
            href="https://latitudefs.zendesk.com/hc/en-au"
            target="_blank"
            rel="noopener noreferrer"
          >
            contact us
          </Link>
          .
        </Text>
      </Box.Section>

      <UploadCards />
    </Main>
  </Layout>
);

export default Page;
