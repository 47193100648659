import React, { useContext, useEffect, useState } from 'react';
import { AnalyticsLocationProvider } from 'latitude-analytics';
import { getUrlFromEnv } from '@/utils/getUrlFromEnvUtil';
import {
  COLOR,
  BREAKPOINT,
  MARGIN,
  ALIGN
} from '@latitude/core/utils/constants';
import { HorizontalRule } from '@latitude/horizontal-rule';
import { Link } from '@latitude/link';
import { Metadata } from '@latitude/metadata';
import { StickyNavigation } from '@latitude/sticky-navigation';
import { Box } from '@latitude/box';
import Layout from '@/components/layout';
import { SITE_URL, TITLE_SUFFIX } from '@/utils/constants';
import { Accordion } from '@latitude/accordion';
import { Hero } from '@latitude/hero';
import { Vertical } from '@latitude/spacing';
import { Lframe } from '@latitude/lframe';
import HowToApplyComponent from '@latitude/how-to-apply';
import { Heading3, Heading4, Heading6 } from '@latitude/heading';
import {
  MinimumMonthly,
  Flexible,
  OffersContainer,
  EqualMonthly,
  OfferTile,
  PlanTypes,
  PLAN_TYPE_MINIMUM_MONTHLY_GEM,
  PLAN_TYPE_EQUAL_MONTHLY,
  PLAN_TYPE_FLEXIBLE_MONTHLY,
  PLAN_TYPE_MINIMUM_MONTHLY
} from '@latitude/interest-free-plans';
import { List, ListItem } from '@latitude/list';
import { InstalmentInterestFreeCalculator } from '@latitude/instalment-interest-free-calculator';
import { ImportantInformation } from '@latitude/important-information';
import LabRatesAndFees from '@/components/lab-components/LabRatesAndFees';
import { StaticImage } from 'gatsby-plugin-image';

import WhyChoose from '../gemvisa/_why-choose';
import RatesAndFees from '../gemvisa/_rates-and-fees';
import bgImageBbqg from './images/bbqg_hero_background_optimised.png';

import { PageContext } from '@/context/PageContext';
import HeroBanner from '@/components/lab-components/HeroBanner';
import { INLINES } from '@contentful/rich-text-types';

const APPLY_URL = getUrlFromEnv('funnel-start-gemvisa-partners');

const title = 'Get that BBQ now on interest free with Latitude Gem Visa';
const description =
  'Shop interest free on BBQs, outdoor living, gas/wood heating, accessories and more.';

const TabsInterestFreeOffers = ({ offerTabs, title, basePath }) => {
  const [activeLinkId, setActiveLinkId] = useState(null);
  const tabData = offerTabs?.map(offerTab => ({
    id: offerTab?.tabHeader?.url,
    label: offerTab?.tabHeader?.text
  }));
  const defaultLink = tabData?.[0]?.id;

  const OPTIONS = {
    renderNode: {
      [INLINES.HYPERLINK]: (node, children) => (
        <Link target="_blank" rel="noopener noreferrer" href={node.data.uri}>
          {children}
        </Link>
      )
    }
  };

  useEffect(() => {
    setActiveFromHash();
    window.addEventListener('hashchange', setActiveFromHash, false);
  }, []);

  const setActiveFromHash = () => {
    const { hash } = location;
    if (hash && hash !== '') {
      const tabIds = tabData.map(tab => tab.id);
      setActiveLinkId(false); // unset, forces TabNavigation to rerender
      if (tabIds.includes(hash.toLowerCase())) {
        setActiveLinkId(hash.toLowerCase());
      } else {
        setActiveLinkId(defaultLink);
      }
    } else {
      setActiveLinkId(defaultLink);
    }
  };

  const onTabClickHandler = linkId => {
    setActiveLinkId(linkId);
  };

  return (
    <>
      {activeLinkId && offerTabs?.length > 1 && (
        <Box
          css={`
            border-top: 1px solid rgba(0, 0, 0, 0.13);
            padding: 0;
            max-width: 1110px;
            margin: auto;

            @media (min-width: ${BREAKPOINT.MD}) {
              border: none;
              padding-top: 40px;
            }

            p {
              background-color: white;
              position: relative;
              padding-top: 16px;
              z-index: 1;
            }

            /**
           * Selector overrides for horizontal spacing between tabs
           */

            div {
              justify-content: center;
            }

            a {
              flex: 0 1 auto;
            }
          `}
        >
          <AnalyticsLocationProvider location="Show me offers available at">
            <TabNavigation
              heading="Show me offers available at"
              backgroundColor="white"
              basepath={basePath}
              data={tabData}
              defaultActiveTabId={defaultLink}
              onTabClick={onTabClickHandler}
            />
          </AnalyticsLocationProvider>
        </Box>
      )}

      <Box.Section
        backgroundColor={COLOR.GREY6}
        css={
          offerTabs?.length > 1 &&
          `
            /* Vertical spacing overrides */
            margin-top: -15px;

            @media (min-width: ${BREAKPOINT.MD}) {
              margin-top: -42px;
            }
          `
        }
      >
        {offerTabs?.map(
          offerTab =>
            activeLinkId === offerTab?.tabHeader?.url && (
              <OffersContainer heading={title ? title : ''}>
                {offerTab?.tabPanel?.map(
                  ({ planType, termsConds, termsScrollToId, ...rest }) => (
                    <OfferTile
                      {...planType}
                      {...rest}
                      termsScrollToId={termsScrollToId}
                      terms={
                        !termsScrollToId && (
                          <>{documentToReactComponents(termsConds, OPTIONS)}</>
                        )
                      }
                      trackTermsEventData={{
                        location: `Offer tile - ${planType}, ${rest?.numberOf} ${rest?.frequency}, ${rest?.applicableTo}, min $${rest?.minPurchaseAmount}`
                      }}
                    />
                  )
                )}
              </OffersContainer>
            )
        )}
      </Box.Section>
    </>
  );
};

const BBQGalorePage = ({ location }) => {
  /** Contentful Data */
  const contentfulPageData = useContext(PageContext);
  const state = contentfulPageData?.updatedBody || {};

  const featureSliderData = [
    {
      heading: 'Why choose Latitude Gem Visa?',
      featureCards: [
        {
          icon: 'icon-party-popper',
          title: 'Flexible, long-term payment options',
          text:
            'We&apos;re here for all your big-ticket items. We&apos;ve partnered with some of Australia&apos;s best brands and retailers to give you fantastic long-term Interest Free plans.'
        },
        {
          icon: 'icon-unlimited-rewards-branded',
          title: '0% interest for 6 months',
          text:
            'Shopping is better with Latitude Gem Visa, and with over 40 million locations where Visa is accepted, you&apos;ll get six months Interest Free when you spend $250 or more.'
        },
        {
          icon: 'icon-cardless-branded',
          title: 'Ready, Set, Tap',
          text:
            'Introducing our new instant cardless payment feature where you can apply, get approval, and start shopping from your phone in minutes.'
        },
        {
          icon: 'icon-app-latitude-branded',
          title: 'Stay on track with your spending',
          text:
            'With the Latitude App, it&apos;s never been easier to manage your payments and stay on top of your due dates.'
        }
      ]
    }
  ];
  /** - END - */

  return (
    <Layout location={location} noHeader noFooter>
      <Metadata
        title={`${title} | ${TITLE_SUFFIX}`}
        description={description}
        canonical={`${SITE_URL}${location.pathname}`}
      />

      {state?.heroBannerData?.[0] ? (
        <HeroBanner {...state?.heroBannerData[0]} />
      ) : (
        <Box backgroundColor={COLOR.GREY6}>
          <Lframe bgImage={bgImageBbqg} />
          <Hero
            title={title}
            text={`${description} Minimum spend applies. See details below.`}
          />
        </Box>
      )}

      <StickyNavigation
        css={`
          position: relative;
          z-index: 1;
          @media (max-width: ${BREAKPOINT.LG}) {
            display: none;
          }
        `}
        items={[
          {
            anchor: 'hot-offers',
            label: 'Hot offers'
          },
          {
            anchor: 'how-to-show-interest-free',
            label: 'How to shop interest free'
          },
          {
            anchor: 'why-us',
            label: 'Why us?'
          },
          {
            anchor: 'rates-and-fees',
            label: 'Rates & Fees'
          }
        ]}
        offsetElem="[data-sticky-navigation-offset]"
        {...state?.inPageNavData?.[0]}
      />

      <div
        css={`
          .accordion .accordion__button-fixed {
            position: relative;
            top: auto !important;
          }
        `}
      >
        {state?.interestFreeOffersData?.[0] ? (
          <TabsInterestFreeOffers
            {...state?.interestFreeOffersData?.[0]}
            basePath={contentfulPageData?.path}
          />
        ) : (
          <Box.Section id="hot-offers" backgroundColor={COLOR.GREY6}>
            <AnalyticsLocationProvider location="Hot offers">
              <OffersContainer heading="Hot offers">
                <EqualMonthly
                  applicableTo="On purchases over $1000.In-Store Only."
                  numberOf={50}
                  frequency="MONTHS"
                  minFinancedAmount={1000}
                  termsScrollToId="important-info"
                />
                <MinimumMonthly
                  applicableTo="On purchases over $1000. In-Store only."
                  numberOf={24}
                  frequency="MONTHS"
                  minFinancedAmount={1000}
                  termsScrollToId="important-info"
                />
                <MinimumMonthly
                  applicableTo="On purchases over $500."
                  numberOf={12}
                  frequency="MONTHS"
                  minFinancedAmount={500}
                  termsScrollToId="important-info"
                />
                <MinimumMonthly
                  applicableTo="On purchases over $300. In-Store only."
                  numberOf={6}
                  frequency="MONTHS"
                  minFinancedAmount={300}
                  termsScrollToId="important-info"
                />
                <Flexible
                  applicableTo="On purchases over $500. In-Store only."
                  numberOf={12}
                  frequency="MONTHS"
                  minFinancedAmount={500}
                  termsScrollToId="important-info"
                />
              </OffersContainer>
            </AnalyticsLocationProvider>
          </Box.Section>
        )}

        <PlanTypes
          heading="Latitude interest free payment plans explained"
          types={[
            PLAN_TYPE_MINIMUM_MONTHLY_GEM,
            PLAN_TYPE_EQUAL_MONTHLY,
            PLAN_TYPE_MINIMUM_MONTHLY,
            PLAN_TYPE_FLEXIBLE_MONTHLY
          ]}
        />
        <div
          css={`
            > div {
              padding-top: 0;
            }
          `}
        >
          <Box.Section backgroundColor={COLOR.GREY6}>
            <AnalyticsLocationProvider location="Equal Monthly Plan Calculator">
              <Accordion
                fgColor="grey-dark"
                titleBgColor={COLOR.WHITE}
                css={`
                  .accordion__item {
                    margin-bottom: 0;
                    button {
                      padding-left: 24px;
                      color: #000000;
                      font-family: Roboto;
                      font-size: 18px;
                      font-weight: 500;
                      letter-spacing: 0;
                      line-height: 28px;
                      font-weight: 500 !important;
                    }
                  }
                `}
                items={[
                  {
                    content: (
                      <div
                        className="px-4 py-3"
                        css={`
                          @media (max-width: 432px) {
                            &.px-4 {
                              padding-left: 0 !important;
                              padding-right: 0 !important;
                            }
                          }
                        `}
                      >
                        <InstalmentInterestFreeCalculator
                          isGemVisa
                          annualFee={0}
                          establishmentFee={0}
                          establishmentFeeText="&nbsp;"
                          minimumAmount={500}
                          monthlyFee={10.95}
                          monthlyTerms={[50]}
                          importantInfo={[
                            'These repayments are for Equal monthly payment plans only and do not take into account any other purchases or balances.',
                            'The monthly, weekly and daily repayments are approximate only.',
                            "Monthly repayments are required, exact amount specified in the customer's statement.",
                            'Minimum financed amount applies. See current promotions for terms and conditions.',
                            'Various plans only available during specific promotional periods.'
                          ]}
                        />
                      </div>
                    ),
                    id: 'equal-monthly-repayments-calculator',
                    title: 'Calculate the right Equal Monthly plan for you'
                  }
                ]}
              />
            </AnalyticsLocationProvider>
          </Box.Section>
        </div>
        <HorizontalRule />
        <Box
          id="how-to-show-interest-free"
          backgroundColor={COLOR.GREY6}
          css={`
            padding: 48px 0;
            z-index: 1;
            @media (min-width: ${BREAKPOINT.LG}) {
              padding: 72px 0;
            }
          `}
        >
          <Heading4 color={COLOR.BLACK} align={ALIGN.CENTER} isResponsive>
            How to shop interest free
          </Heading4>
          <AnalyticsLocationProvider location="How to shop interest free">
            <HowToApplyComponent
              box1={
                <Vertical spacing={MARGIN.M16}>
                  <Heading3
                    color={COLOR.BLACK}
                    align={ALIGN.CENTER}
                    isResponsive={false}
                  >
                    Don&apos;t have an interest free card?
                  </Heading3>
                  <Heading4
                    color={COLOR.BLACK}
                    align={ALIGN.CENTER}
                    isResponsive={false}
                  >
                    Apply now for Latitude Gem Visa
                  </Heading4>
                  <div style={{ margin: '20px auto' }}>
                    <StaticImage
                      src="../../../images/credit-cards/latitude-gem-front.png"
                      alt="Gem visa card"
                      width={116}
                    />
                  </div>
                  <Heading6 marginBottom="8px">How to apply</Heading6>
                  <List>
                    <ListItem>Apply online in 10 minutes</ListItem>
                    <ListItem>
                      Once approved, simply activate your card online using the
                      Latitude app
                    </ListItem>
                    <ListItem>
                      <strong>In-store purchases</strong>: provide us with your
                      photo ID and account number
                    </ListItem>
                    <ListItem>
                      <strong>Online purchases</strong>: checkout using your
                      Latitude Gem Visa account number and have your mobile
                      phone on hand for verification
                    </ListItem>
                  </List>
                  <Link
                    button="primary"
                    css="width: 200px; margin: 0 auto;"
                    href={`${APPLY_URL}?merchantID=000412002&channel=Online&source=0&stream=Upstream`}
                    data-trackid="apply-now"
                    trackEventData={{
                      category: 'cta',
                      action: 'application-link'
                    }}
                    trackProductId={['CCAUGEM']}
                  >
                    Apply now
                  </Link>
                </Vertical>
              }
              box2={
                <Vertical spacing={MARGIN.M16}>
                  <Heading3
                    color={COLOR.BLACK}
                    align={ALIGN.CENTER}
                    isResponsive={false}
                  >
                    Have one of these cards?
                  </Heading3>
                  <Heading4
                    color={COLOR.BLACK}
                    align={ALIGN.CENTER}
                    isResponsive={false}
                  >
                    Start shopping interest free now!
                  </Heading4>
                  <div css="margin: 20px auto;">
                    <StaticImage
                      src="../../../images/credit-cards/interest-free-cards.webp"

                      alt="Interest free cards"
                      width={200}
                    />
                  </div>
                  <Heading6 marginBottom="8px">
                    How to shop interest free
                  </Heading6>
                  <List>
                    <ListItem>
                      For online purchases simply enter your card or account
                      number during the checkout process
                    </ListItem>
                    <ListItem>
                      Or visit your nearest store and present your card at
                      checkout
                    </ListItem>
                  </List>
                  <Link
                    button="tertiary"
                    css="width: 200px; margin: 0 auto;"
                    href="https://www.barbequesgalore.com.au"
                    data-trackid="start-shopping-now"
                  >
                    Start shopping now
                  </Link>
                </Vertical>
              }
            />
          </AnalyticsLocationProvider>
        </Box>
        <HorizontalRule />
        <WhyChoose
          featureData={state?.featureSliderData?.[0] || featureSliderData[0]}
        />
        <HorizontalRule />
        {state?.ratesAndFeesData?.[0] ? (
          <LabRatesAndFees {...state.ratesAndFeesData[0]} />
        ) : (
          <RatesAndFees />
        )}
        <div id="important-info">
          <ImportantInformation
            data={{
              content: {
                importantInformationSectionOne: [
                  '<strong>Interest Free with Minimum Monthly Payment plans (Interest Free Promotion). Available on 6, 12 & 24 Month Terms</strong><br/>Approved customers only. Conditions, fees and charges apply. Minimum spends apply. Monthly payments must be made during the promotional period. Interest and payments are payable after the interest free period expires. Paying only the minimum monthly payment will not pay out the purchase before the end of the plan. At the end of the plan, interest will be charged at 29.99% p.a. Account service fee of $10.95 per month applies<sup>*</sup>.',
                  '<strong>Interest Free with Equal Monthly Payments (Instalment Interest Free Promotion). Available on 50 Month Terms</strong><br />Approved customers only. Conditions, fees and charges apply. Minimum spends apply. Payable by 50 approximate equal monthly instalments (exact amounts specified in your statement). At the end of the plan, interest will be charged at 29.99% p.a. Account service fee of $10.95 per month applies<sup>*</sup>',
                  '<strong>Interest Free with Flexible Payments (Buy Now Pay Later Promotion). Available on 12 Months Terms</strong><br/>Approved customers only. Conditions, fees and charges apply. Minimum spends apply. Interest and payments are payable on any outstanding balance after the plan expires. At the end of the plan, interest will be charged at 29.99% p.a. Account service fee of $10.95 per month applies<sup>*</sup>'
                ],
                importantInformationSectionTwo: [
                  '<strong>General Terms and Conditions</strong><br/>This notice is given under the Latitude Gem Visa Conditions of Use (as applicable), which specify all other conditions for this offer. Credit is provided by Latitude Finance Australia ABN 42 008 583 588 Australian Credit Licence number 392145.',
                  'Visa and the Visa brand are registered trademarks of Visa International.',
                  '<sup>1</sup>&nbsp;To take advantage of up to 55 days interest free on everyday credit card purchases, you need to pay the full closing balance (excluding un-expiring 0% Interest Payment Plans) on each statement of account by the applicable due date.',
                  '<sup>®</sup>&nbsp;Registered to BPAY Pty Ltd ABN 69 079 137 518'
                ]
              }
            }}
            sectionOneColummClasses="col-10 offset-1 col-lg-5 offset-lg-1"
            sectionTwoColummClasses="col-10 offset-1 col-lg-5 offset-lg-0"
            {...state?.importantInfoData?.[0]}
          />
        </div>
      </div>
    </Layout>
  );
};

export default BBQGalorePage;
