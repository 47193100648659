import React, { useContext } from 'react';
import Helmet from 'react-helmet';
import { BUTTON_STYLE } from '@latitude/core/utils/constants';
import {
  BREAKPOINT,
  COLOR,
  PADDING,
  SOFT_QUOTE_LOANS_AU_URL
} from '@/utils/constants';
import HeroBranded from '@/components/HeroBranded/HeroBranded';
import ConfettiFramedContainer from '@/components/ConfettiFramedContainer/ConfettiFramedContainer';
import { Box } from '@/components/Box/Box';
import { getUrlFromEnv } from '@/utils/getUrlFromEnvUtil';
import Section from '../../components/Section/Section';
import LoanRates from '../../components/LoanRates/LoanRates';
import Layout from '../../components/layout';
import PageData from '@/data/pages/loans/personal-car-motor-loans.json';
import PLData from '@/data/pages/personal-loan.json';
import { ImportantInformation } from '@latitude/important-information';
import FeesAndCharges from '@/components/PersonalLoanDetails/PersonalLoanDetails';
import imgPersonalCarAndMotorLoans from '../../images/hero/personal-car-and-motor-loans.png';
import HeroBanner from '@/components/lab-components/HeroBanner';
import { PageContext } from '@/context/PageContext';

const PersonalCarAndMotorLoanPage = props => { 
  /** Contentful Data */
  const contentfulPageData = useContext(PageContext);
  const state = contentfulPageData?.updatedBody || {};
  /** - END - */

  return (
    <Layout location={props.location}>
      <main className="navigation-spacer">
        <Helmet>
          <link
            rel="canonical"
            href="https://www.latitudefinancial.com.au/loans/personal-car-and-motor-loans/"
          />
          <title>{PageData.metaTitle}</title>
          <meta name="description" content={PageData.metaDesc} />
        </Helmet>

        {state?.heroBannerData?.[0] ? (
          <HeroBanner {...state?.heroBannerData[0]} />
        ) : (
          <HeroBranded
            css={`
              background: ${COLOR.GREY6};
              @media (max-width: ${BREAKPOINT.MD}) {
                .HeroContent {
                  background-color: ${COLOR.WHITE};
                }
              }
              @media (min-width: ${BREAKPOINT.MD}) {
                .HeroContent {
                  padding: 48px 0 0 0;
                }
              }
            `}
            title="You could still get a great loan"
            text="We could help with our Personal Loan product."
            imageContent={
              <div
                css={`
                  height: 100%;
                  display: flex;
                  align-items: flex-end;
                  justify-content: center;
                  @media (min-width: ${BREAKPOINT.MD}) {
                    justify-content: flex-start;
                  }
                `}
              >
                <img
                  src={imgPersonalCarAndMotorLoans}
                  alt="Personal car and motor loans"
                  css={`
                    height: inherit;
                    position: absolute;
                    left: 12px;
                    top: 0;
                    @media (min-width: ${BREAKPOINT.MD}) {
                      height: 360px;
                      position: static;
                      margin-left: 80px;
                    }
                  `}
                  />
                </div>
              }
              buttonProps={{
                href: '/personal-loan',
                children: 'Find out more',
                trackId: 'find-out-more',
                trackProductId:['PLAULF-WEB'],
                trackEventData: {
                  category: 'cta',
                  action: 'discovery-link'
                },
                width: '200px',
                type: 'tertiary',
                className: 'pl-4 pr-4'
            }}
          />
        )}

        <ConfettiFramedContainer
          framedContainerTheme={{
            frameColor: '#B1DBFF'
          }}
          confettiPosition="topAndBottom"
          minHeight="318px"
          className="bg-white mt-lg-4"
        >
          <Box
            backgroundColor={COLOR.BLUE_BABY}
            className="text-align-left px-5"
            padding={PADDING.P24}
          >
            <div>
              <h4 className="text-center text-color-deep-blue">
                To qualify for a car or motor loan your next car must be under 15
                years old, and your motorbike, boat or other vehicle under 7 years
                old.
              </h4>
            </div>
            <div>
              <h3 className="text-center text-color-deep-blue">
                But our Personal Loan could help get you in the driver&apos;s
                seat.
              </h3>
            </div>
          </Box>
        </ConfettiFramedContainer>


          <Section className="bg-f8f8f8 pt-0 pb-0">
            <LoanRates
              isBranded
              rateBoxType="variableSym"
              rateBoxType2="fixedSym"
              fees={PLData.content.aboutTheLoan}
              button1={{
                href: '/personal-loan/',
                trackId: 'personal-loan-car-find-out-more',
                trackProductId:['PLAULF-WEB'],
                trackEventData: {
                  action: 'discovery-link',
                  category: 'cta',
                  location: 'Fees & charges',
                },
                text: 'Find out more',
                button: BUTTON_STYLE.TERTIARY
              }}
              button2={{
                href: `${getUrlFromEnv('funnel-start-loans')}${SOFT_QUOTE_LOANS_AU_URL}`,
                target: '_self',
                trackId: 'personal-car-and-motor-loan-get-my-rate',
                trackProductId:['PLAULF-WEB'],
                trackEventData: {
                  action: 'quote-link',
                  category: 'cta',
                  location: 'Fees & charges',
                },
                text: 'Get Rate Estimate',
                button: BUTTON_STYLE.PRIMARY
              }}
            />
          </Section>

          <FeesAndCharges
              data={PLData.content.feesAndCharges}
            />

          <ImportantInformation
            data={require('@/data/json/disclaimer/personal-loan1.json')}
            sectionOneColummClasses="col-10 offset-1 col-lg-5 offset-lg-1"
            sectionTwoColummClasses="col-10 offset-1 col-lg-5 offset-lg-0"
            additionalPaymentsDisclaimerOne
          {...state?.importantInfoData?.[0]}
        />
      </main>
    </Layout>
  );
};
export default PersonalCarAndMotorLoanPage;
