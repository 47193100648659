import React, { useEffect } from 'react';

function ZendeskRedirectPage({ location }) {

  useEffect(() => {
    if (window?.location) {
      window.location.replace('https://assets.latitudefinancial.com/legals/28degreescard/purchase-protection-insurance-termsconds.pdf');
    }
  }, []);

  return <></>;
}

export default ZendeskRedirectPage;
