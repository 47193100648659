import React from 'react';
import { AnalyticsLocationProvider } from 'latitude-analytics';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import Accordion from './Accordion/Accordion';
import Section from '@latitude/section';
import { RATES_AND_FEES_COLOR } from '@/utils/constants';
import './_labRatesAndFees.scss';
import Button from '@/components/lab-components/Button';
import { INLINES, BLOCKS } from '@contentful/rich-text-types';
import { Typography } from '../Typography';

const LabRatesAndFees = ({ title, rates, accordions, backgroundColor }) => {
  const isBlue = backgroundColor === RATES_AND_FEES_COLOR.BLUE;
  const RateItem = ({ number }) => (
    <div className={`rateItem ${isBlue && 'blue'}`}>
      {number && documentToReactComponents(number)}
    </div>
  );

  const getAccordionTriggerBgColor = ratesAndFeesBgColor => {
    if (ratesAndFeesBgColor === RATES_AND_FEES_COLOR.GREY) return 'white';
    return 'grey';
  };

  const renderOptions = {
    renderNode: {
      [INLINES.EMBEDDED_ENTRY]: (node, _children) => {
        if (node?.data?.target?.contentType?.sys?.id === 'simpleButton') {
          return (
            <Button
              theme="Link"
              rel="noopener noreferrer"
              disableDefaultFunnelTracking={true}
              trackEventData={{
                location: 'ratesandfees'
              }}
              href={node?.data?.target?.url}
              text={node?.data?.target?.text}
              isHomepageButton={false}
            />
          );
        }
      },
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <Typography.SUBTITLE>{children}</Typography.SUBTITLE>
      )
    }
  };

  return (
    <AnalyticsLocationProvider location={title}>
      <div className="labFeesAndRates" id="rates">
        <Section
          className={`feature-section common_tag_style ${
            backgroundColor === RATES_AND_FEES_COLOR.GREY
              ? 'bg-grey-light'
              : 'bg-white'
          }`}
        >
          {title && <div className="heading">{title}</div>}
          <div className={`rateList ${isBlue && 'blue'}`}>
            {rates?.map((rate, index) => (
              <RateItem key={index} {...rate} />
            ))}
          </div>
          <Accordion
            titleBgColor={getAccordionTriggerBgColor(backgroundColor)}
            contentBgColor="grey-dark"
            isOpen
            items={accordions.map(item => ({
              id: item.name,
              title: item.title,
              iconName: item?.icon,
              content: (
                <div className="rich-text">
                  {documentToReactComponents(item.content, renderOptions)}
                </div>
              )
            }))}
            hasSmoothScroll={false}
          />
        </Section>
      </div>
    </AnalyticsLocationProvider>
  );
};

export default LabRatesAndFees;
