import React, { useEffect, useContext } from 'react';
import Helmet from 'react-helmet';

import CorporateResponsibilitySection from '../../components/CorporateResponsibility/CorporateResponsibilitySection';
import Layout from '../../components/layout';
import StickyNavigationBranded from '../../components/StickyNavigation/StickyNavigationBranded';
import PageData from './data/corporate-responsibility.json';
import heroImage from '../../images/hero/corporate-responsibility.webp';

import { PageContext } from '@/context/PageContext';
import { CONTENTFUL_COMPONENTS } from '@/utils/constants';
import HeroBanner from '@/components/lab-components/HeroBanner';
import { useSetState } from '@/utils/hooks';
import PageHeaderSection from '@/components/PageLayout/PageHeader';

const CorporateResponsibilityPage = props => {
  /** Contentful Data */
  const contentfulPageData = useContext(PageContext);
  const state = contentfulPageData?.updatedBody || {};
  /** - END - */

  return (
    <Layout location={props.location}>
      <main className="navigation-spacer">
        <Helmet>
          <title>{PageData.meta.title}</title>
          <meta name="description" content={PageData.meta.description} />
          <link
            rel="canonical"
            href="https://www.latitudefinancial.com.au/about-us/corporate-responsibility/"
          />
        </Helmet>
      </main>

      <div css="position:relative;">
        {state?.heroBannerData?.[0] ? (
          <HeroBanner {...state?.heroBannerData[0]} />
        ) : (
          <PageHeaderSection
            pageImage={heroImage}
            title="Corporate Responsibility"
            subTitle={
              <>
                At Latitude we want to help everyone live better and this
                extends well beyond our products and services.
              </>
            }
          />
        )}
      </div>

      <StickyNavigationBranded
        items={PageData.stickyNavigation}
        trackId="corporate-responsibility-stickynav"
        {...state?.inPageNavData?.[0]}
      />
      <CorporateResponsibilitySection />
    </Layout>
  );
};

export default CorporateResponsibilityPage;
