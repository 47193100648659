import React from 'react';
import PersonalLoansPageContent from '@/components/PersonalLoanPageContent/PersonalLoansPageContent';

/* This page is a replication of /personal-loan/ with amended copy because Google has flagged our current paid media links to the AU Personal Loans page - please don't delete
Please refer to CEPW-1518 for more information
*/

const PersonalLoanPage = props => {
  return (
    <PersonalLoansPageContent
      {...props}
      title="Personal Loan - Fixed Interest Rate"
      description="Whether your dream is to renovate your home or go travelling, Latitude's personal loan can help make that come true faster. Apply in less than 10 minutes."
    />
  );
};

export default PersonalLoanPage;
