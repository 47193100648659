import * as React from 'react';
import styled from 'styled-components';
import { StickyNavigation } from '@latitude/sticky-navigation';

const StickyNavigationBranded = styled(StickyNavigation)`
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.13);
  .sticky-navigation__nav-link,
  .sticky-navigation__phone-number {
    font-weight: 300;
    color: #000;
    font-size: 14px;
  }
  .sticky-navigation__phone-icon {
    fill: #000;
  }

  .sticky-navigation__cta {
    border-radius: 4px;
    margin-top: 5px;
    height: 48px;
  }

  .sticky-navigation__nav-item {
    a .label {
      color: #000 !important;
    }
  }
`;
export default props => <StickyNavigationBranded {...props} />;
