import React from 'react';
import styled, { css } from 'styled-components';
import { Link } from '@latitude/link';
import { Text } from '@latitude/text';
import {
  COLOR,
  PADDING,
  FONT_FAMILY,
  FONT_WEIGHT,
  BREAKPOINT
} from '../../utils/constants';

const Article = ({ isBranded, thumbnail, path, title }) => {
  const ArticleTile = styled(Link)`
    display: block;
    position: relative;
    min-height: 245px;
    overflow: hidden;
    background-image: url(/life-done-better/images/${thumbnail});
    background-size: cover;
    margin-bottom: 30px;
    ${isBranded &&
      css`
        max-width: 350px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 0;
        padding-bottom: 84px;
        background-repeat: no-repeat;
        background-size: 100% auto;
        overflow: visible;
        background-color: ${COLOR.WHITE};
        @media (min-width: ${BREAKPOINT.LG}) {
        }
        &:after {
          content: '';
          background-color: ${COLOR.WHITE};
          width: 100%;
          height: 45px;
          transform: skew(0deg, -6deg);
          position: absolute;
          bottom: 59px;
          left: 0;
          z-index: 3;
        }
      `}
  `;

  const ArticleTextContainer = styled.div`
    position: absolute;
    bottom: 0;
    height: 110px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${COLOR.BLUE};
    padding: ${PADDING.P16};
    ${isBranded &&
      css`
        background-color: ${COLOR.WHITE};
        bottom: 0;
        height: 84px;
        z-index: 4;
        padding: 16px 16px 24px;
        @media (min-width: ${BREAKPOINT.LG}) {
          height: 84px;
        }
        p {
          font-family: ${FONT_FAMILY.TITLE};
          font-weight: ${FONT_WEIGHT.SEMI_BOLD};
          color: ${COLOR.BLACK};
          font-size: 16px;
          padding: 0;
          @media (min-width: ${BREAKPOINT.LG}) {
            font-size: 18px;
          }
        }
      `}
  `;

  return (
    <ArticleTile
      href={path}
      trackEventData={{
        label: title
      }}
      noStyle
    >
      <ArticleTextContainer>
        <Text color="white" align="center">
          {title}
        </Text>
      </ArticleTextContainer>
    </ArticleTile>
  );
};

export default Article;
