import styled, { css } from 'styled-components';
import { Link } from '@latitude/link';
import { BREAKPOINT, COLOR, FONT_WEIGHT } from '@latitude/core/utils/constants';

export const Tile = styled.div`
  border-bottom: 1px solid ${COLOR.GREY10};
  font-weight: 300;
  font-size: 14px;
  line-height: 1.43;
  margin: 0;
  position: relative;
  padding: 24px;
  
  strong {
    font-weight: 500;
    display: block;
  }

  ul {
    margin: 8px 0 0;
    list-style-type: none;
  }
  
  li {
    margin: 0 0 10px;
  }

  h5 {
    line-height: 26px;
    margin-bottom: 0;

    @media (min-width: ${BREAKPOINT.XL}) {
      display: flex;
      align-items: center;
      justify-content: center;
      &.expand {
        min-height: 52px;
      }
    }
  }

  p {
    color: ${COLOR.BLACK}
  }

  &:first-child {
    background-color: ${COLOR.BLUE_BABY};
    text-align: center;

    ${props =>
      props.purple &&
      css`
        background-color: #f2f2fe;
      `}
    ${props =>
      props.pink &&
      css`
        background-color: #ffeff7;
      `}
    ${props =>
      props.grey &&
      css`
        background-color: #f2f2f2;
        display: flex;
        flex-direction: column;
      `}
  }

  @media (min-width: ${BREAKPOINT.LG}) {
    flex: 1 1 50%;

    &:after {
      display: block;
      width: 1px;
      height: 80%;
      content: '';
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      position: absolute;
      background-color: ${COLOR.GREY10};
    }
    
    &:first-child, 
    &:last-child {
      &:after {
        display: none;
      }
    }
  }
`;

export const TileContainer = styled.div`
  background-color: ${COLOR.WHITE};
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.07);
  max-width: 340px;
  display: flex;
  flex-direction: column;
  margin: 0 auto 30px;

  &:last-child {
    margin-bottom: 0;
  }

  @media (min-width: ${BREAKPOINT.LG}) {
    flex-direction: row;
    margin-left: 0;
    margin-right: 0;
    max-width: none;
    flex: 1 1 50%;
  
    ${props =>
      props.half &&
      css`
        display: inline-flex;
        flex: 1 1 50%;
      `}
    ${props =>
      props.quarter &&
      css`
        display: inline-flex;
        flex: 1 1 25%;
      `}
    ${props =>
      props.threeQuarter &&
      css`
        height: 296px;
        width: 837px;

        h5 {
          min-height: auto;
        }
      `}
    ${props =>
      props.isOnlyChild &&
      css`
        max-width: calc(50% - 15px);
      `}
  }

  @media (min-width: ${BREAKPOINT.XL}) {
    height: 296px;
    min-width: auto;

    ${props =>
      props.quarter &&
      css`
        flex: 1 1 auto;
        width: 260px;
      `};


  }
`;

export const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 340px;
  margin: 0 auto 30px;

  @media (min-width: ${BREAKPOINT.LG}) {
    flex-direction: row;
    max-width: none;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 30px;
    justify-content: space-between;

    ${props =>
      props.leftAlign &&
      css`
        justify-content: flex-start;
      `};
  }

  @media (min-width: ${BREAKPOINT.XL}) {
    &:last-child {
      max-width: 532px;
    }
  }

  ${TileContainer} {
    margin-left: 0;
    margin-right: 0;

    @media (min-width: ${BREAKPOINT.LG}) {
      margin-bottom: 0;
      margin-right: 30px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
`;

export const TileIcon = styled.div`
  display: flex;
  margin: auto;
  width: 96px;
  height: 96px;
  align-items: center;
  justify-content: center;
`;

export const TileButtonLink = styled(Link)`
  font-weight: ${FONT_WEIGHT.NORMAL};
  margin: auto auto 0;
`;

/*
 * Conditionally render boxes for layout variant(s)
 */

export const MediaQueryWrapper = styled.div`
  ${props =>
    props.isVisibleLarge &&
    css`
      display: none;
      @media (min-width: ${BREAKPOINT.XL}) {
        display: flex;
        min-width: 540px;
      }
    `};

  ${props =>
    props.isVisibleSmallToMedium &&
    css`
      @media (min-width: ${BREAKPOINT.LG}) {
        display: flex;
      }
      @media (min-width: ${BREAKPOINT.XL}) {
        display: none;
      }
    `};
`;
