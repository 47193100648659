import React, { useContext } from 'react';
import { PageContext } from '@/context/PageContext';

import { Metadata } from '@latitude/metadata';
import Section from '@latitude/section';
import { ALIGN, COLOR } from '@latitude/core/utils/constants';
import { ListNumbered } from '@latitude/list-numbered';
import { Heading4 } from '@latitude/heading';

import Layout from '@/components/layout';
import PageHeaderSection from '@/components/PageLayout/PageHeader';
import HeroBanner from '@/components/lab-components/HeroBanner';
import { SITE_URL } from '@/utils/constants';

import BalanceTransferFaqSection from './_balance-transfer-faq';
import BalanceTransferInfoSection from '../_balance-transfer-info';
import imgPageHero from '../../../images/credit-cards/hero-balance-transfer-offer.webp';
import imgOffer from './images/balance-transfer-promo.webp';
import GoMicrositeHeader from './_go-microsite-header';

const HOW_TO_APPLY_STEPS = [
  'Open the Latitude App (please ensure you download the latest version) or visit the Latitude Service Centre by clicking the Log in button, located in the top right corner of the Latitude website.',
  'Log in using your Latitude ID.',
  'Select the Balance Transfer tab and fill in the form using JWEANULQ promo code (case sensitive) to get 0% p.a. for 12 months on balance transfers with 3% balance transfer fee. T&Cs apply.',
  "If you don't have a Latitude ID, simply register from the login page and follow the steps above.",
  "<div>For more information on Terms and Conditions <a href='https://assets.latitudefinancial.com/legals/terms-conditions/balance-transfer-termsconds.pdf' target='_blank'>click here</a>.</div>"
];
const IMPORTANT_INFO = [
  "<sup id='note-star'>*</sup>&nbsp;Balance transfer applies to eligible customer's Australian non-Latitude credit cards only. Balance transfer amounts commencing from $500 or more, up to 85% of your Latitude GO Mastercard credit limit. Monthly payments required (exact amounts specified in your statement). Paying only the minimum monthly payment may not pay out the balance transfer amount before the end of the promotional period. If there is an outstanding balance after the promotional period, interest will be charged at the cash annual percentage rate, currently 29.99% (subject to change). A once-off balance transfer fee of 3% applies to the amount being transferred. This will be charged to your Latitude GO Mastercard credit card account when the balance transfer is processed.",
  'Offer expires on 20 November 2024 and is only available to existing customers who have submitted application form using promo code: JWEANULQ. If this promo code is not included in the application form, then the standard balance transfer rate may apply. Eligibility criteria apply. Subject to approval.',
  'Conditions of use set out the T&Cs of the Latitude GO Mastercard credit card. Credit provided by Latitude Finance Australia ABN 42 008 583 588 Australian Credit Licence number 392145.'
];

function BalanceTransferOfferV2Page({ location }) {
  /** Contentful Data */
  const contentfulPageData = useContext(PageContext);
  const state = contentfulPageData?.updatedBody || {};
  /** - END - */

  return (
    <Layout location={location} hasSecNav>
      <main
        className="navigation-spacer"
        css={`
          margin-top: 120px !important;
          background-color: #f8f8f8;

          div[class^='MicrositeHeader__SecondBar'].microsite-header__nav-wrap {
            margin-top: 8px;
          }
        `}
      >
        <Metadata
          title={'Balance Transfer Offer | GO Mastercard'}
          description={'Balance transfer 0% offer'}
          keywords="balance transfer"
          canonical={`${SITE_URL}${location.pathname}`}
          noIndex
        />

        <GoMicrositeHeader data={state.secondaryNavData} />

        {state?.heroBannerData?.[0] ? (
          <HeroBanner {...state?.heroBannerData[0]} />
        ) : (
          <PageHeaderSection
            title={
              <>
                Enjoy 0% p.a. for 12 months on balance transfers.<sup>*</sup>
              </>
            }
            subTitle={
              <>
                Offer ends 20 November 2024. 3% balance transfer fee applies.
                <br />
                <small>
                  Visit the Latitude Service Centre or Latitude App to apply.
                </small>
              </>
            }
            pageImage={imgPageHero}
            pageImageAlt="0% Balance Transfer"
          />
        )}

        <Section id="how-to-apply">
          <Heading4 align={ALIGN.CENTER} color={COLOR.BLACK}>
            How to apply
          </Heading4>
          <div
            css={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              justifyContent: 'space-evenly',
              margin: '10pt auto 10pt auto',
              padding: '5pt'
            }}
          >
            <div css={{ width: '350px', height: 'auto' }}>
              <img
                css={{ width: '100%' }}
                src={imgOffer}
                alt="Balance Transfer Offer"
              />
            </div>
            <div
              css={{
                maxWidth: '500px',
                padding: '0 10pt'
              }}
            >
              <ListNumbered
                data={HOW_TO_APPLY_STEPS}
                viewStyle="compact"
                counterBgColor={COLOR.BLUE_DEEP}
                counterVerticalAlign
                separator="none"
              />
            </div>
          </div>
        </Section>

        <BalanceTransferFaqSection anchor="faqs" />
        <BalanceTransferInfoSection
          anchor="important-Info"
          data={IMPORTANT_INFO}
          contentfulData={state?.importantInfoData?.[0]}
        />
      </main>
    </Layout>
  );
}

export default BalanceTransferOfferV2Page;
