import React from 'react';
import { Text, Bold } from '@latitude/text';
import { Heading6 } from '@latitude/heading';
import { Vertical } from '@latitude/spacing';
import { Link } from '@latitude/link';
import { Tel } from '@latitude/tel';
import {
  BREAKPOINT,
  BUTTON_STYLE,
  MARGIN
} from '@latitude/core/utils/constants';
import { Box } from '@latitude/box';
import { PRODUCT_NAME } from '@/utils/constants';
import imgDownloadGooglePlay from './images/download-google-play.svg';
import imgDownloadAppStore from './images/download-appstore.svg';

const oscURL = 'https://gomastercard-online.gomastercard.com.au';

const oscRegistrationURL =
  'https://gomastercard-online.gomastercard.com.au/access/registration';

const oscLoginURL =
  'https://gomastercard-online.gomastercard.com.au/access/login';

const directDebitRequestFormURL =
  'https://latitudefs.zendesk.com/hc/en-au/requests/new?ticket_form_id=360000137778';

const conditionsOfUseURL =
  'https://assets.latitudefinancial.com/legals/conditions-of-use/gomc-au/cou.pdf';

const mobileAppURL = '/mobile-app/';

const reduceCreditLimitURL =
  'https://latitudefs.zendesk.com/hc/en-au/requests/new?ticket_form_id=360000143958';

const managingYourRepaymentsURL =
  'https://latitudefs.zendesk.com/hc/en-au/articles/4409583421457-Paying-your-account-FAQs';

const helpCenterURL =
  'https://latitudefs.zendesk.com/hc/en-au/articles/5078660193041-Latitude-GO-Mastercard-and-Gem-Visa-APR-Increase';

const faqs = {
  items: [
    {
      groupName: 'Application and activation',
      groupData: [
        {
          title: `What do I need to apply for a ${PRODUCT_NAME}?`,
          content: (
            <Vertical spacing={MARGIN.M16}>
              <Text>First check you're eligible. This means being both:</Text>
              <ol>
                <li>Over 18</li>
                <li>A permanent resident of Australia</li>
              </ol>
              <Text>
                To speed up your application, it`ll also help to have the below
                handy: Driver`s License, Passport or Proof of Age Card Details
                of your income assets and liabilities Employer's contact number.
              </Text>
            </Vertical>
          )
        },
        {
          title: 'What will my credit limit be?',
          content: (
            <Text>
              It depends on your situation. Once you fill out your application,
              we'll be able to tell you what your credit limit is.
            </Text>
          )
        },
        {
          title: 'When can I expect to receive a response on my application?',
          content: (
            <Text>
              If you apply online, you should get an answer in 60 seconds. If
              you apply in-store you'll also receive a response quickly. In some
              instances, we might need more information (and time) to process
              your application.
            </Text>
          )
        },
        {
          title: 'Can I track my application?',
          content: (
            <Text>
              Of course. We'll email you with any updates on your application
              &mdash; or if you'd prefer them by phone, call us on{' '}
              <Tel no="1300 552 079" /> with your application number ready.
            </Text>
          )
        },
        {
          title: 'How do I activate my credit card?',
          content: (
            <Vertical spacing={MARGIN.M16}>
              <Text>Either:</Text>
              <ol>
                <li>
                  Call us on <Tel no="1300 552 079" /> to activate your card and
                  set up your PIN. It'll only take a couple of minutes.
                </li>
                <li>
                  Activate it online. Register for our{' '}
                  <Link href={oscRegistrationURL}>Online Service Centre</Link>,
                  navigate to 'My Account' and select 'Manage Cards'. Have your
                  phone handy as you`ll need your SMS code to set your PIN.
                </li>
              </ol>
              <Text>
                And your card`s almost ready to go &mdash; just don`t forget to
                sign it first.
              </Text>
            </Vertical>
          )
        },
        {
          title: 'I am an additional cardholder. How do I activate my card?',
          content: (
            <>
              <Vertical spacing={MARGIN.M16}>
                <ol>
                  <li>
                    Firstly, check with the primary account holder that they've
                    activated their card. You will not be able to activate your
                    card until theirs is active.
                  </li>
                  <li>
                    Call us on <Tel no="1300 462 273" /> to activate your card
                    and set up your PIN. It'll only take a couple of minutes.
                  </li>
                  <li>
                    Prompt the interactive voice response with 'Activate my
                    card' and follow the instructions.
                  </li>
                  <li>
                    You will need to know the primary account holder's date of
                    birth and the mobile phone number listed on the account.
                  </li>
                  <li>
                    A verification code will be sent to the mobile phone number
                    listed on the account, so keep that close while you are on
                    the line.
                  </li>
                  <li>
                    We suggest you continue to follow the prompts and set a PIN
                    while you are there.
                  </li>
                </ol>
                <Text>
                  And your card's almost ready to go - just don't forget to sign
                  it first.
                </Text>
              </Vertical>
            </>
          )
        },
        {
          title: 'Can I personalise my PIN?',
          content: (
            <Text>
              Absolutely. If you'd like to change your PIN to something more
              memorable for you, call us on <Tel no="1300 552 079" />.
            </Text>
          )
        }
      ]
    },
    {
      groupName: 'Gambling and lottery',
      groupData: [
        {
          title: `Can I use my card on gambling and lottery transactions?`,
          content: (
            <Text>
              We no longer authorise credit card purchases registered under a
              gambling and lottery merchant code. These transactions will
              include most gambling and lottery purchases. Gambling and lottery
              transactions are purchases made at gambling associated venues and
              online sites.
            </Text>
          )
        },
        {
          title: `What's the use of my credit card if I can't use it how I want to?`,
          content: (
            <Text>
              While we understand that gambling and lottery purchases can form
              part of normal entertainment and everyday life for many people,
              Latitude along with other credit providers, including banks feel
              that using a credit card to pay for gambling and lottery is not
              suitable.
            </Text>
          )
        },
        {
          title: `My card was declined and the terminal said 'Do Not Honour', what does this mean?`,
          content: (
            <Text>
              This decline could be because we no longer authorise gambling and
              lottery purchases on Latitude products.
            </Text>
          )
        },
        {
          title: 'Why are you declining gambling transactions?',
          content: (
            <Text>
              We recognise that gambling is a growing issue in Australia, and we
              are here to help support the financial wellbeing of our customers.
              While our customers may like the flexibility of using their card
              for these occasional purchases, Latitude along with other credit
              providers, including banks feel that using a credit card to pay
              for gambling and lottery is not suitable. If you find yourself
              vulnerable to gambling and you`d like to speak to someone from an
              independent organisation about your situation, please call
              Gambling Help Online.{' '}
              <Link href="https://www.gamblinghelponline.org.au/">
                www.gamblinghelponline.org.au
              </Link>{' '}
              <Tel no="1800 858 858" />
            </Text>
          )
        },
        {
          title: 'Can I use my card at the casino restaurant?',
          content: (
            <Text>
              As a result of the gambling and lottery transactions being
              declined on Latitude products, non-gambling and lottery
              transactions such as food or beverages purchased at some
              international casinos may also be affected and is dependent on the
              way these businesses comply with local laws. Bistros at clubs and
              sports venues throughout Australia that offer pokies should still
              accept credit cards for meals and drinks.
            </Text>
          )
        },
        {
          title:
            'Gambling is a form of entertainment/recreation for me, so why do these purchases need to be blocked? ',
          content: (
            <Text>
              We recognise that for many Australians, gambling is a form of
              entertainment and recreation. Latitude along with other credit
              providers, including banks feel that using a credit card to pay
              for gambling and lottery is not suitable.
            </Text>
          )
        },
        {
          title:
            'What else has the financial services industry done in this space?',
          content: (
            <Vertical spacing={MARGIN.M16}>
              <Text>
                Victoria: A venue operator must not allow a person to obtain
                cash from a facility in the approved venue or a cash advance
                from a credit account.
              </Text>
              <Text>
                Queensland: All automatic teller machines installed in the
                licensee`s licensed premises are only available for the use of
                debit cards.
              </Text>
              <Text>
                New South Wales: A hotelier or club must not permit a cash
                dispensing facility to be used, or to be installed, or located
                in any part of the hotel, or club premises, if the facility is
                capable of providing cash from a credit card account.
              </Text>
              <Text>
                Northern Territory: ATM and EFTPOS Facilities will only have
                access to debit accounts; access to credit accounts will not be
                permitted.
              </Text>
              <Text>
                Tasmania: A casino operator must not allow a person to obtain,
                from a cash facility, a cash advance from a credit account.
                South Australia: The holder of a gaming machine license must not
                provide, or allow another person to provide, a cash facility
                within a gaming area on the licensed premises. Australian
                Capital Territory: A hotelier or club must not permit a cash
                dispensing facility to be used, or to be installed, or located
                in any part of the hotel, or club premises, if the facility is
                capable of providing cash from a credit card account. Western
                Australia: In the gaming areas of the Casino EFTPOS is only to
                be used to access savings or cheque (not credit) accounts in
                accordance with relevant legislative and regulatory
                requirements, and subject to transaction limits.
              </Text>
              <Text>
                South Australia: The holder of a gaming machine license must not
                provide, or allow another person to provide, a cash facility
                within a gaming area on the licensed premises.
              </Text>
              <Text>
                Australian Capital Territory: A hotelier or club must not permit
                a cash dispensing facility to be used, or to be installed, or
                located in any part of the hotel, or club premises, if the
                facility is capable of providing cash from a credit card
                account.
              </Text>
              <Text>
                Western Australia: In the gaming areas of the Casino EFTPOS is
                only to be used to access savings or cheque (not credit)
                accounts in accordance with relevant legislative and regulatory
                requirements, and subject to transaction limits.
              </Text>
            </Vertical>
          )
        },
        {
          title: 'What else has the government done in this space?',
          content: (
            <>
              <Text>
                Over the past 20 years Australian governments have introduced
                several restrictions on the use of credit cards for gambling.
                These include:
                <br />
                <br />
              </Text>
              <ul>
                <li>
                  Australian state and territory governments prohibited access
                  to credit in casino and gambling areas of licensed venues in
                  the early 2000s.
                  <br />
                  This mean a person cannot use a credit card to gamble or
                  withdraw cash from an ATM in gambling areas of licensed venues
                  and casinos.
                  <br />
                  When these reforms were introduced the online gambling did not
                  operate in Australia.
                </li>
                <li>
                  In February 2018, the Federal Government prohibited online
                  gambling operators from offering credit to gamblers as part of
                  the National Consumer Protection Framework for Online
                  Wagering.
                </li>
                <li>
                  On 27 November 2019, the Federal Minister for Minister for
                  Communications, the Hon. Paul Fletcher introduced legislation
                  to establish the National Online Gambling Self-Exclusion
                  Register.
                </li>
              </ul>
            </>
          )
        },
        {
          title:
            'Where could credit cards be used for gambling? (As at March 2020)',
          content: (
            <Text>
              Online and betting apps - Yes. However, in February 2018, the
              Federal Government prohibited online gambling operators from
              offering credit to gamblers as part of the National Consumer
              Protection Framework for Online Wagering. <br />
              <br />
              Poker machines - No credit cards or access to cash advances
              through ATMS in gambling areas.
              <br />
              <br />
              Casinos - No. Casinos extend lines of credit from their own credit
              facilities to their customers in VIP rooms, but they do not
              provide credit cards in those areas. <br />
              <br />
              On-track - horse racing and greyhound racing - No credit cards or
              access to cash advances through ATMS in venue. <br />
              <br />
              Lottery tickets/online and in venue - Yes.
              <br />
              <br />
              TAB outlets - No.
            </Text>
          )
        }
      ]
    },
    {
      groupName: 'Managing your account',
      groupData: [
        {
          title: 'How do I access my account online?',
          content: (
            <Vertical spacing={MARGIN.M16}>
              <Text>
                You can do this either through our{' '}
                <Link href={oscURL}>Online Service Centre</Link> or the{' '}
                <Link href={mobileAppURL}>Latitude App.</Link>
              </Text>
              <Heading6>Online Service Centre</Heading6>
              <Text>Register for the Online Service Centre to easily:</Text>
              <ul>
                <li>Manage your account and update your details</li>
                <li>Access your statements anywhere and anytime</li>
                <li>View your pending transactions instantly</li>
              </ul>
              <Text>
                It only takes a few minutes to sign up, just make sure you have
                your phone on hand. Otherwise you'll need your card plus a
                recent statement to help you answer some questions.
              </Text>
              <Link
                button={BUTTON_STYLE.SECONDARY}
                width="200px"
                href={oscRegistrationURL}
                targetBlank
              >
                Register now
              </Link>
              <Heading6>Latitude App</Heading6>
              <Text>
                Enjoy easy access to your account from your mobile or iPad. Here
                you can:
              </Text>
              <ul>
                <li>Quickly track the balance on your card</li>
                <li>Check the payment due date</li>
                <li>View all transaction information </li>
                <li>Pay someone by bank transfer</li>
              </ul>
              <Text>
                Simply search &quot;Latitude App&quot; in Google Play or the App
                Store to download it to your device.
              </Text>
              <Box
                css={`
                  @media (min-width: ${BREAKPOINT.LG}) {
                    display: flex;
                  }
                `}
              >
                <Link
                  noStyle
                  className="link--no-style mr-2"
                  href="https://apps.apple.com/app/apple-store/id1373059866"
                  trackEventData={{
                    label: 'Download on the App Store'
                  }}
                >
                  <img
                    src={imgDownloadAppStore}
                    alt="Download on the AppStore"
                    height={60}
                    width={192}
                  />
                </Link>
                <Link
                  noStyle
                  className="link--no-style"
                  href="https://play.google.com/store/apps/details?id=com.latitudefinancial.latitudeapp"
                  trackEventData={{
                    label: 'Get it on Google Play'
                  }}
                >
                  <img
                    src={imgDownloadGooglePlay}
                    alt="Get it on Google Play"
                    height={60}
                    width={192}
                  />
                </Link>
              </Box>
            </Vertical>
          )
        },
        {
          title: 'How do I change my contact details?',
          content: (
            <Vertical spacing={MARGIN.M16}>
              <Text>
                The easiest way to update your contact details is through the{' '}
                <Link href={oscLoginURL}>Online Service Centre</Link>.
              </Text>
              <Text>
                Or just call us on <Tel no="1300 552 079" /> and we'll get it
                sorted.
              </Text>
            </Vertical>
          )
        },
        {
          title: 'Can I add an extra cardholder?',
          content: (
            <Text>
              Yes. Simply complete this{' '}
              <Link href="/credit-cards/gomastercard/additional-cardholder/">
                online application form
              </Link>{' '}
              to add a family member or partner.
            </Text>
          )
        },
        {
          title: 'Where can I find a list of other available forms?',
          content: (
            <Vertical spacing={MARGIN.M16}>
              <Text>Right here:</Text>
              <ul>
                <li>
                  <Link href="https://latitudefs.zendesk.com/hc/en-au/requests/new?ticket_form_id=360000140718">
                    Close Account form
                  </Link>{' '}
                  &mdash; Use this form to close your account.{' '}
                </li>
                <li>
                  <Link href={conditionsOfUseURL}>
                    Conditions of use and credit guide
                  </Link>{' '}
                  &mdash; Get to know how your {PRODUCT_NAME} works.
                </li>
                <li>
                  <Link href={directDebitRequestFormURL}>
                    Direct debit request form
                  </Link>{' '}
                  &mdash; Set-up direct debits to automatically pay off the
                  minimum amount each month.
                </li>
                <li>
                  <Link href="https://assets.latitudefinancial.com/forms/cards/identity-verification/identity-verification.pdf">
                    Identity verification form
                  </Link>
                </li>
                <li>
                  <Link href={reduceCreditLimitURL}>
                    Reduce Credit Limit form
                  </Link>
                </li>
                <li>
                  <Link href="https://latitudefs.zendesk.com/hc/en-au/requests/new?ticket_form_id=360000130337">
                    Authority to disclose and maintain information
                  </Link>{' '}
                  - Authorise another person to receive information relating to
                  your account.
                </li>
              </ul>
            </Vertical>
          )
        }
      ]
    },
    {
      groupName: 'Credit Limits',
      groupData: [
        {
          title: 'What happens if I go over my credit limit?',
          content: (
            <Vertical spacing={MARGIN.M16}>
              <Text>
                While there are no charges for going over your credit limit,
                it's important you stay within it to keep you on track. If you
                do go over, we'll give you a heads up through email, SMS or post
                within 2 business days.
              </Text>
              <Text>
                Your statement will identify the total due which includes any
                over limit amounts.
              </Text>
              <Text>
                We recommend registering for the{' '}
                <Link href={oscRegistrationURL}>Online Service Centre</Link> to
                track your balance.
              </Text>
            </Vertical>
          )
        },
        {
          title: "What's my credit limit?",
          content: (
            <Text>
              Your credit limit can be found under 'Account Summary' in the{' '}
              <Link href={oscURL}>Online Service Centre</Link> or in the{' '}
              <Link href={mobileAppURL}>Latitude App</Link>. It's also available
              on the top right of your monthly statement.
            </Text>
          )
        },
        {
          title: 'How do I reduce the credit limit on my card?',
          content: (
            <ul>
              <li>
                Complete the{' '}
                <Link href={reduceCreditLimitURL}>
                  Reduce Credit Limit Online Form
                </Link>{' '}
                or
              </li>
              <li>
                Log in to the <Link href={oscURL}>Online Service Centre</Link>,
                navigate to 'My Account' and select 'Manage My Credit Limit'
              </li>
            </ul>
          )
        },
        {
          title: "What's my cash limit?",
          content: (
            <Vertical spacing={MARGIN.M16}>
              <Text>
                Your cash limit can be found under 'Account Summary' in the{' '}
                <Link href={oscURL}>Online Service Centre</Link>. It's also
                available on the top right of your monthly statement.
              </Text>
              <Text>
                Just keep in mind it's not uncommon for your cash limit to be
                different from your credit limit. Daily cash withdrawal limits
                will also apply.
              </Text>
            </Vertical>
          )
        }
      ]
    },
    {
      groupName: 'Payments',
      groupData: [
        {
          title: 'How can I make a payment?',
          content: (
            <Vertical spacing={MARGIN.M16}>
              <Text>
                You can pay off your credit card with any of the below options:
              </Text>
              <Heading6>Latitude App</Heading6>
              <Text>
                Simply download the{' '}
                <Link href={mobileAppURL}>Latitude App</Link> from the App Store
                or Google Play. Open the app and create your Latitude ID (this
                is different to your Online Service Centre login). Once your
                credit cards are linked, you'll be able to make payments via
                bank transfer with your BSB and Account Number (Australia only).
              </Text>
              <Heading6>Online Service Centre</Heading6>
              <Text>
                The quickest and easiest way to make a payment. Simply log into
                the{' '}
                <a
                  href="https://servicecentre.latitudefinancial.com.au/"
                  target="_blank"
                >
                  Latitude Service Centre
                </a>
                , click 'Pay my Account' and enter your bank details.
                Registering is easy and it only takes a few minutes, just make
                sure you have your card or 16-digit account number on hand.
                <br />
                If you use the Latitude Service Centre via "Pay My Account",
                your payment may take a few days to reflect on your Card
                balance, but will show on your transactions list straight away
                (as pending). We do not charge you a fee for payments made via
                the Latitude Service Centre.
              </Text>
              <Heading6>Direct Debit</Heading6>
              <Text>
                Set up automatic payments from your bank account by calling us
                on <Tel no="1300 552 079" /> or completing a{' '}
                <Link href={directDebitRequestFormURL}>
                  direct debit request form
                </Link>
                .
              </Text>
              <Heading6>BPAY</Heading6>
              <Text>
                BPAY is available 24 hours a day, 7 days a week. To make a
                payment, quote the {PRODUCT_NAME} Biller Code (443887), your
                account number and the amount you want to pay.
                <br />
                <br />
                Remember payments are processed during business hours and can
                take up to 3 business days to clear &mdash; so make sure you do
                them well before the due date.
              </Text>
              <Text>A $1.95 fee applies to each BPAY payment.</Text>
              <Heading6>Australia Post</Heading6>
              <Text>
                Simply take your statement to any Australia Post Office to make
                a payment. A $3.95 handling fee will be charged for each
                transaction.
              </Text>
            </Vertical>
          )
        },
        {
          title: "What if I can't afford to make my monthly payment?",
          content: (
            <Text>
              If something changes and you're finding it hard to manage your
              repayments, don't worry - our Hardship Care team are here to help.
              We may be able to tailor an arrangement to suit your needs. To
              learn more, visit our{' '}
              <Link href="/hardship-care/">Hardship Care page</Link>.
            </Text>
          )
        },
        {
          title: 'How can I track when my payments are due?',
          content: (
            <Vertical spacing={MARGIN.M16}>
              <Text>
                Each month you'll receive a statement which sets out your
                minimum monthly payment amount, the payment due date, plus any
                overdue payments and over limit amounts.
              </Text>
              <Text>
                We also recommend setting up email alerts through the{' '}
                <Link href={oscURL}>Online Service Centre</Link> to remind you
                of when payments are due.
              </Text>
            </Vertical>
          )
        },
        {
          title: 'Can I make a payment on a particular interest-free purchase?',
          content: (
            <Text>
              Absolutely. If you want a payment to go towards a particular
              purchase, just let us know on <Tel no="1300 552 079" />. Keep in
              mind your payments automatically go towards the highest interest
              product first.
            </Text>
          )
        },
        {
          title:
            "What happens if I don't pay off my full purchase amount within the 0% Interest Payment Plan period?",
          content: (
            <Vertical spacing={MARGIN.M16}>
              <Text>
                These things happen. But the good news is you'll only pay
                interest from when your interest-free plan ends.
              </Text>
              <Text>
                After your plan ends, the outstanding balance will continue to
                show on your statement until it's paid.
              </Text>
            </Vertical>
          )
        },
        {
          title: 'How is the minimum monthly payment (MMP) calculated?',
          content: (
            <Vertical spacing={MARGIN.M16}>
              <Text>
                The MMP is 3% of the total outstanding balance or $25, whichever
                is more. But if you have any overdue amounts, over limit amounts
                and interest free payment plans, you'll need to pay these as
                well each month.
              </Text>
              <Text>
                To make sure you pay off the minimum amount on time, we
                encourage you to set up a direct debit. To arrange this, call us
                on <Tel no="1300 552 079" /> or complete a{' '}
                <Link href={directDebitRequestFormURL}>
                  direct debit request form
                </Link>
                .
              </Text>
              <Text>
                Remember, to avoid being charged interest at the end of your
                interest-free period, it's likely you'll need to pay off more
                than the minimum payment each month.
              </Text>
            </Vertical>
          )
        },
        {
          title:
            'What\'s the difference between the "Managing Your Repayment" and the "Modified Closing Balance" sections on my statement?',
          content: (
            <Vertical spacing={MARGIN.M16}>
              <Text>
                For more information on the Managing Your Repayment section,
                please click <Link href={managingYourRepaymentsURL}>here</Link>.
              </Text>
            </Vertical>
          )
        },
        {
          title: 'How do I set up direct debit to pay my account?',
          content: (
            <Text>
              You can set up automatic repayments from your bank account by
              calling us on <Tel no="1300 552 079" /> or completing a{' '}
              <Link href={directDebitRequestFormURL}>
                direct debit request form
              </Link>
            </Text>
          )
        },
        {
          title:
            "I made a payment via BPAY and it's not appearing on my statement or the OSC?",
          content: (
            <Vertical spacing={MARGIN.M16}>
              <Text>
                It can take up to 3 business days for your payment to appear in
                the <Link href={oscLoginURL}>Online Service Centre</Link>.
              </Text>
              <Text>
                If you'd like to be notified when your payment's been received,
                you can sign up for confirmation emails in the{' '}
                <Link href={oscLoginURL}>Online Service Centre</Link>.
              </Text>
            </Vertical>
          )
        },
        {
          title: "Will you remind me when my payment's due?",
          content: (
            <Text>
              You can set reminders up in the{' '}
              <Link href={oscLoginURL}>Online Service Centre</Link> under
              'Manage My Details'. We'll send you an alert 7 days before
              payment's due.
            </Text>
          )
        },
        {
          title: 'What is my Biller Code / Customer Reference Number?',
          content: (
            <Vertical spacing={MARGIN.M16}>
              <Text>
                The Biller Code for {PRODUCT_NAME} is 443887. Your Customer
                Reference Number (CRN) is your 'Account Number' located at the
                top right of your statement.
              </Text>
              <Text>
                You can access your statements by logging into the{' '}
                <Link href={oscLoginURL}>Online Service Centre</Link>.
              </Text>
            </Vertical>
          )
        }
      ]
    },
    {
      groupName: 'Interest',
      groupData: [
        {
          title: 'Can I make a payment on a particular interest-free purchase?',
          content: (
            <Text>
              Absolutely. If you want a payment to go towards a particular
              purchase, just call us on <Tel no="1300 552 079" />. Keep in mind
              your payments automatically go towards the highest interest
              product first.
            </Text>
          )
        },
        {
          title:
            "What happens if I don't pay off my full purchase amount within the 0% Interest Payment Plan period?",
          content: (
            <Vertical spacing={MARGIN.M16}>
              <Text>
                These things happen. But the good news is you'll only pay
                interest from when your interest-free plan ends.
              </Text>
              <Text>
                After your plan ends, the outstanding balance will continue to
                show on your statement until it's paid.
              </Text>
            </Vertical>
          )
        },
        {
          title: 'What is the Payment to Reduce Future Interest amount?',
          content: (
            <Vertical spacing={MARGIN.M16}>
              <Text>
                The Payment to Reduce Future Interest (PRFI) tells you how much
                you might like to pay to reduce your interest &mdash; or avoid
                it altogether.
              </Text>
              <Text>
                It includes everyday purchases, cash advances and balance
                transfers, plus any payments owed on interest-free purchases
                that are expiring/have expired, up to the next payment due.
              </Text>
            </Vertical>
          )
        },
        {
          title:
            'Why does the Payment to Reduce Future Interest (PRFI) amount change each month?',
          content: (
            <Text>
              The PRFI amount depends on any expiring interest-free purchases,
              plus any everyday purchases, cash advances or balance transfers
              you made during that month.
            </Text>
          )
        },
        {
          title:
            'Can I only get long term 0% interest purchases at the store where I initially made my purchase?',
          content: (
            <Text>
              Not at all. With {PRODUCT_NAME}, you can access a range of
              interest-free payment plans at{' '}
              <Link href="https://www.interestfree.com.au/shop-interest-free/view-retailer-listing.html">
                thousands of stores
              </Link>{' '}
              around Australia, including fashion, furniture, technology, travel
              and more. You can shop again up to your available credit limit
              without having to re-apply.
            </Text>
          )
        },

        {
          title:
            'Do I have to pay off my 0% interest purchase before I can use my card again?',
          content: (
            <Text>
              Nope. You can shop up to your available credit limit, and make
              extra interest-free purchases or just use your card for the little
              everyday things.
            </Text>
          )
        },

        {
          title:
            'What is Flexible Interest Free plans (also called Buy Now, Pay Later plans)?',
          content: (
            <Text>
              It's a payment option that means you don't have to pay anything
              during the initial promotional period. But as you'll start to gain
              interest if there's an outstanding balance after this offer period
              ends, it's best to pay off the item in full before this interest
              starts kicking in.
            </Text>
          )
        },
        {
          title:
            'What is Minimum Monthly Interest Free plans (also called Interest Free plans)?',
          content: (
            <Vertical spacing={MARGIN.M16}>
              <Text>
                This lets you buy things on your card without paying interest
                during the interest-free term. You'll still need to make a
                minimum payment each month though. Interest-free plans start
                from 6 months.
              </Text>
              <Text>
                <sup>*</sup>Remember, paying only the minimum monthly payment
                won't pay off your plan's balance, but you can make extra
                payments any time.
              </Text>
            </Vertical>
          )
        },
        {
          title:
            'What is Equal Monthly Interest Free plans (also called Instalment Interest Free plans)?',
          content: (
            <Text>
              A payment plan that lets you keep it simple and pay the same
              amount every month. Plans vary from 6 months and up to 60 months
              during select promotional periods at{' '}
              <Link href="/interest-free-offers/">our partnered retailers</Link>
              .
            </Text>
          )
        },
        {
          title: 'Why has my purchase rate increased?',
          content: (
            <Vertical spacing={MARGIN.M16}>
              <Text>
                Latitude has made some changes, head over to our{' '}
                <Link href={helpCenterURL}>Help Centre</Link> to learn more.
              </Text>
            </Vertical>
          )
        }
      ]
    },
    {
      groupName: 'Security',
      groupData: [
        {
          title: 'What happens if we detect a suspicious transaction?',
          content: (
            <Text>
              If we identify any suspicious activity on your account, our Fraud
              Team may contact you by phone or SMS using an automated contact
              system. Here you'll be able to verify if transactions are genuine
              using your keypad. If you're uncertain, our system will transfer
              you over to someone in our team. (If this happens outside business
              hours, our team will contact you first thing the next day).
            </Text>
          )
        },
        {
          title: 'Who do I call if my card is lost or stolen?',
          content: (
            <Vertical spacing={MARGIN.M16}>
              <Text>
                Call our lost and stolen card team on <Tel no="1800 005 809" />.
                We're here 24/7.
              </Text>
              <Text>
                If you're overseas call <Tel no="+61 287 478 170" /> or{' '}
                <Tel no="+1 636 722 7111" /> (Mastercard Global Service).
              </Text>
            </Vertical>
          )
        },
        {
          title: 'How can I help protect myself from fraud?',
          content: (
            <ul>
              <li>Always sign your card as soon as you get it</li>
              <li>
                Choose a PIN that's memorable only to you and never write it
                down
              </li>
              <li>
                Regularly check your account statements to make sure there are
                no unauthorised transactions
              </li>
              <li>
                Never use an EFTPOS Terminal or ATM if you believe it's been
                tampered with
              </li>
              <li>Make sure your contact details remain up to date</li>
              <li>
                If your card expires or you don't want to keep it, cut through
                the signature panel and magnetic strip before you get rid of it
              </li>
              <li>
                Always know where your card is, and keep it in sight if you're
                using it.
              </li>
            </ul>
          )
        },
        {
          title: 'How does Mastercard SecureCode™ work?',
          content: (
            <Vertical spacing={MARGIN.M16}>
              <Text>
                Mastercard SecureCode™ performs a real-time risk assessment on
                transactions made at participating online retailers. In most
                cases you won't notice any change, however, on some transactions
                we may prompt you to enter a six-character security code, which
                will be sent to you instantly via SMS. This is to ensure that
                the transaction you are about to make is authorised by you.
              </Text>
              <Text>
                When you make an online purchase at a participating merchant, a
                page may automatically appear asking for a unique security code
                or answers to security questions. This is similar to the way
                your bank asks for your PIN at the ATM. When you correctly enter
                the code (or answer the security questions) your card issuer
                confirms that you are the authorised cardholder and your
                purchase continues.
              </Text>
            </Vertical>
          )
        },
        {
          title: 'How does Mastercard SecureCode protect me?',
          content: (
            <Text>
              When you correctly enter the security code (or answer the security
              questions) during an online purchase at a participating merchant,
              you confirm that you are the authorised cardholder and your
              purchase continues. If the security questions are answered
              incorrectly, the purchase will not go through. So even if someone
              knew your card number, they would not be able to use your account
              at that merchant.
            </Text>
          )
        },
        {
          title: 'Why am I seeing this Mastercard SecureCode screen? ',
          content: (
            <Text>
              This authentication screen may appear when you are shopping online
              at participating Mastercard SecureCode merchants. Once you have
              confirmed your identity by correctly entering either the security
              code (or answering the security questions) that are on the page,
              you will be able to continue with your purchase.
            </Text>
          )
        },
        {
          title: 'What happens if I don`t receive a security code?',
          content: (
            <Text>
              If you don't receive a security code, this may mean that the
              mobile number that is registered to your account is not up to
              date. You can still proceed with your transaction by selecting the
              button 'I didn't receive a code' which will direct you to the
              security questions that are personal to you and your account. Once
              you have answered the security questions correctly you will be
              able to continue with your purchase.
            </Text>
          )
        }
      ]
    },
    {
      groupName: 'Transaction Dispute',
      groupData: [
        {
          title: 'How do I dispute a transaction on my account?',
          content: (
            <Vertical spacing={MARGIN.M16}>
              <Text>
                If a transaction doesn't look right and the following{' '}
                <Bold>does not</Bold> apply to you, call us on{' '}
                <Tel no="1300 552 079" /> so we can investigate the issue and
                block your card if needed.
              </Text>
              <Heading6>
                Scenario: I didn't authorise the transaction / I don't recognise
                the company name on my statement
              </Heading6>
              <ol>
                <li>
                  Many companies use different trading names to identify their
                  stores, especially online merchants. If you don't recognise a
                  merchant description on your statement, a quick internet
                  search or phone call to the company may help clear this up.
                </li>
                <li>
                  If your account has extra cardholders, check whether they
                  could have made the purchase(s).
                </li>
                <li>
                  Also keep in mind there might be a delay from when you buy
                  something to when it shows on your statement.
                </li>
              </ol>
            </Vertical>
          )
        },
        {
          title:
            'When can I request Latitude to resolve a disputed transaction?',
          content: (
            <Text>
              Latitude can dispute transaction(s) for you on everyday purchases
              if certain conditions are met. It's not available on your Interest
              Free Payment Plan (Buy Now, Pay Later, Interest Free or Instalment
              Interest Free purchases).
            </Text>
          )
        },
        {
          title:
            'Will I be held liable for unauthorised transactions on my account?',
          content: (
            <Text>
              No &mdash; not if you didn't contribute to the loss and let us
              know immediately.
            </Text>
          )
        },
        {
          title:
            'Who do I speak to if I have a question about my interest free transaction?',
          content: (
            <Vertical spacing={MARGIN.M16}>
              <Text>
                If you've been given the incorrect promotion or interest free
                period, contact us on <Tel no="1300 552 079" />.
              </Text>
              <Text>
                If your question relates to the goods or service, it's best to
                contact the merchant/store directly.
              </Text>
            </Vertical>
          )
        }
      ]
    },
    {
      groupName: 'Using your card',
      groupData: [
        {
          title: 'How do I make an interest-free purchase?',
          content: (
            <Vertical spacing={MARGIN.M16}>
              <Text>
                For longer interest-free terms, show your card or driver`s
                licence at{' '}
                <Link href="https://www.interestfree.com.au/shop-interest-free/view-retailer-listing.html">
                  participating stores
                </Link>{' '}
                to find out which interest-free promotions are on. You can then
                purchase up to your available credit limit
              </Text>{' '}
            </Vertical>
          )
        },
        {
          title:
            'Can I only get long term 0% interest purchases at the store where I initially made my purchase?',
          content: (
            <Text>
              Not at all. With {PRODUCT_NAME}, you can access a range of
              interest-free payment plans at{' '}
              <Link href="https://www.interestfree.com.au/shop-interest-free/view-retailer-listing.html">
                thousands of stores
              </Link>{' '}
              around Australia, including fashion, furniture, technology, travel
              and more. You can shop again up to your available credit limit
              without having to re-apply.
            </Text>
          )
        },
        {
          title:
            'Do I have to pay off my 0% interest purchase before I can use my card again?',
          content: (
            <Text>
              Nope. You can shop up to your available credit limit, and make
              extra interest-free purchases or just use your card for the little
              everyday things.
            </Text>
          )
        },
        {
          title: 'Do I have to pay an ATM fee for withdrawing money?',
          content: (
            <Vertical spacing={MARGIN.M16}>
              <Text>
                It's free to withdraw cash from a Westpac ATM, but you may be
                charged for using non-Westpac ATMs. (You'll be advised of this
                at the time.)
              </Text>
              <Text>
                Keep in mind you'll also be charged a cash advance fee for
                withdrawing cash from any ATM.
              </Text>
            </Vertical>
          )
        },
        {
          title:
            "What's the cash advance fee for withdrawing money at an ATM in Australia and overseas?",
          content: (
            <Vertical spacing={MARGIN.M16}>
              <Text>The following fees apply for cash advances:</Text>
              <Text>
                <Bold>Cash advance fee (Australia):</Bold> $4 or 3.5% of the
                cash advance, whichever is greater.
              </Text>
              <Text>
                <Bold>Cash advance fee (international):</Bold> 3.5% of the cash
                advance.
              </Text>
              <Text>
                When withdrawing cash overseas, your account will be debited in
                Australian dollars at the daily exchange rate. For more details
                refer to your{' '}
                <Link href={conditionsOfUseURL}>Conditions of Use</Link>{' '}
                booklet.
              </Text>
              <Text>
                Remember that interest on cash advances is charged from the
                withdrawal date and continues to be charged until you pay off
                the balance in full (including any previous charges). There
                isn't an interest free period like there is on purchases.
              </Text>
              <Text>A daily cash limit may also apply.</Text>
            </Vertical>
          )
        },
        {
          title: 'Can I use my card on everyday purchases?',
          content: (
            <Vertical spacing={MARGIN.M16}>
              <Text>
                You sure can &mdash; both in-store and online. {PRODUCT_NAME}{' '}
                offers up to 55 days interest free on all credit card
                transactions
                <sup>^</sup>.
              </Text>
            </Vertical>
          )
        },
        {
          title:
            'Why is the date of purchase not accurately reflected on my statement?',
          content: (
            <Text>
              Often there'll be a delay from when you make a purchase to when it
              shows on your statement.
            </Text>
          )
        }
      ]
    },
    {
      groupName: 'Latitude Mobile App',
      groupData: [
        {
          title: 'What is the Latitude App?',
          content: (
            <Vertical spacing={MARGIN.M16}>
              <Text>
                {PRODUCT_NAME} is provided by Latitude Financial Services and
                the Latitude App is the new app designed for mobile devices. It
                allows you to manage your {PRODUCT_NAME} 24 hours a day with
                access to your account balance, available credit and transaction
                history. Plus, you can use the app to find out ways to pay.
              </Text>
            </Vertical>
          )
        },
        {
          title: 'How do I get started? ',
          content: (
            <Vertical spacing={MARGIN.M16}>
              <Text>
                You`ll need to download the app and create a Latitude ID. The
                Latitude ID is created when you successfully register in the
                Latitude App. This allows you to link all of your products under
                your unique ID, so you can see and manage them when you log in
                to the app. Latitude has created this ID to keep your details
                secure, and make signing in easy.
              </Text>
            </Vertical>
          )
        },
        {
          title: `What is the process of linking my ${PRODUCT_NAME} to my Latitude ID?`,
          content: (
            <Vertical spacing={MARGIN.M16}>
              <Text>
                The process for adding all Latitude Financial Services cards is
                the same, and you will need to enter your {PRODUCT_NAME} number
                and DOB in order to receive a SMS verification. We are working
                to make this more simple in future. As an added security
                measure, we will check that the names on all accounts are
                identical (these may need to be updated otherwise). If there are
                any discrepancies in details between any accounts, you will need
                to get in touch to update your details.
              </Text>
            </Vertical>
          )
        },
        {
          title: 'How long is my email and SMS verification number valid for?',
          content: (
            <Vertical spacing={MARGIN.M16}>
              <Text>
                The verification number that has been sent to you via the email
                provided is valid for 24 hours. The SMS verification code is
                valid for three minutes.{' '}
              </Text>{' '}
            </Vertical>
          )
        },
        {
          title: "What should I do if I haven't received my SMS verification?",
          content: (
            <Vertical spacing={MARGIN.M16}>
              <Text>
                To ensure you receive your SMS verification during the account
                linking process you will need to make sure the mobile number
                associated with that particular card is up to date. Once you
                have updated the mobile number you will need to wait 24 hours to
                re-attempt linking your account.
              </Text>
            </Vertical>
          )
        },
        {
          title: 'Can I use touch ID/fingerprint ID for the Latitude App?',
          content: (
            <Vertical spacing={MARGIN.M16}>
              <Text>
                Yes, on second log in of the Latitude App you will be offered to
                use touch ID/fingerprint ID on the app. This can later be
                changed in Settings.
              </Text>
            </Vertical>
          )
        },
        {
          title: 'How secure is the Latitude App?',
          content: (
            <Vertical spacing={MARGIN.M16}>
              <Text>
                The Latitude App is protected with a password chosen by you
                during the registration process.
              </Text>
            </Vertical>
          )
        },
        {
          title: 'How do I download the Latitude App? ',
          content: (
            <Vertical spacing={MARGIN.M16}>
              <Text>Download the Latitude App</Text>
              <Box
                css={`
                  @media (min-width: ${BREAKPOINT.LG}) {
                    display: flex;
                  }
                `}
              >
                <Link
                  noStyle
                  className="link--no-style mr-2"
                  href="https://apps.apple.com/app/apple-store/id1373059866"
                  trackEventData={{
                    label: 'Download on the App Store'
                  }}
                >
                  <img
                    src={imgDownloadAppStore}
                    alt="Download on the AppStore"
                    height={60}
                    width={192}
                  />
                </Link>
                <Link
                  noStyle
                  className="link--no-style"
                  href="https://play.google.com/store/apps/details?id=com.latitudefinancial.latitudeapp"
                  trackEventData={{
                    label: 'Get it on Google Play'
                  }}
                >
                  <img
                    src={imgDownloadGooglePlay}
                    alt="Get it on Google Play"
                    height={60}
                    width={192}
                  />
                </Link>
              </Box>
            </Vertical>
          )
        },
        {
          title: 'What do I need to use the Latitude App?',
          content: (
            <Text>
              To use the Latitude App you must have internet access to launch
              the app. iPhone users must have at least iOS 10.3 or above.
              Android users must have at least Android 5.0/Lollipop or above.
            </Text>
          )
        },
        {
          title: 'Where can I find the Latitude App Terms and Conditions? ',
          content: (
            <Text>
              Please find the{' '}
              <Link href="https://mobile.assets.latitudefinancial.com/mobile-apps/terms-and-conditions/au-latitude-mobile-app.pdf">
                Latitude App Terms and Conditions here
              </Link>
              .
            </Text>
          )
        },
        {
          title: 'Can I view my interest free plans on the Latitude App? ',
          content: (
            <Text>
              Yes, Interest free plans are available in the Latitude App. Simply
              tap on your card and select the `Interest Free` tab to show your
              active interest free purchases.
            </Text>
          )
        },
        {
          title:
            'What is the difference between the Latitude App and Latitude Online Service Centre?',
          content: (
            <Text>
              The Latitude App is designed for mobile and offers faster access
              to your balances and payments. You`ll also be able to check all
              your Latitude cards in one place. Currently, the OSC has more
              features, but the Latitude App is catching up!
            </Text>
          )
        },
        {
          title: 'Can I make a payment via the Latitude App?',
          content: (
            <Text>
              Yes, the Latitude App has the capability to make direct transfer
              payments using your BSB and Account Number.
            </Text>
          )
        },
        {
          title:
            'How many days until I can make another payment via the Latitude App?',
          content: (
            <Text>
              It can take up to five days for your payment to process. Once it
              has been processed, you will be able to make another payment
              through this method.
            </Text>
          )
        },
        {
          title: 'Why did I get logged out of the Latitude App?',
          content: (
            <Text>
              The Latitude App will automatically log you out after
              approximately two minutes of inactivity.
            </Text>
          )
        },
        {
          title: 'Can I see my Latitude GO Rewards Points in the Latitude App?',
          content: (
            <Text>
              Your Latitude GO Rewards points are currently not available in the
              Latitude App. You can find these on your statement or online in
              the Online Service Centre.
            </Text>
          )
        },
        {
          title:
            'Can additional card holders link their credit card in the Latitude App?',
          content: (
            <Text>
              No, currently only the primary card holder is able to link their
              card in the Latitude App.
            </Text>
          )
        },
        {
          title: 'What happens if my mobile is stolen? ',
          content: (
            <Text>
              Your Latitude account will still require your password to enter so
              the account will be safe. If you are concerned contact Latitude.
            </Text>
          )
        },
        {
          title: 'What should I do if my access has been compromised? ',
          content: (
            <Text>
              Inform Latitude immediately and we will ensure your account is
              secure.
            </Text>
          )
        }
      ]
    },
    {
      groupName: 'Is this Credit Card right for me?',
      groupData: [
        {
          title:
            'How do I know if I have the right Credit Card for my circumstances?',
          content: (
            <Text>
              Choosing the right credit card product for your actual needs and
              uses is not necessarily a set and forget decision. Latitude
              encourages you to review your requirements regularly. Latitude has
              a <Link href="/credit-cards">range of credit cards</Link> from
              Interest Free to Travel so the choice is yours.
            </Text>
          )
        },
        {
          title: 'Where can I find out information about other Credit Cards?',
          content: (
            <Text>
              An overview of Latitude's credit cards{' '}
              <Link href="/credit-cards">can be found here</Link>. Use the drop
              down to compare different cards by category such as: Interest
              Free, Low Interest Rate and Travel.
            </Text>
          )
        },
        {
          title:
            'Is there an independent resource available to learn more about borrowing and credit cards?',
          content: (
            <Text>
              The government's{' '}
              <Link href="https://www.moneysmart.gov.au/borrowing-and-credit/credit-cards">
                MoneySmart website{' '}
              </Link>{' '}
              is a valuable resource to learn more about borrowing and credit.
            </Text>
          )
        },
        {
          title: 'What can I do if I want to change cards?',
          content: (
            <Text>
              The easiest way to change cards is to pay your account in full and
              close your existing account. You will then need to apply for the
              card you want &mdash; a full assessment is required when you
              apply.
            </Text>
          )
        }
      ]
    }
  ]
};

export default faqs;
