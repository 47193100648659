import React from 'react';
import { Box } from '@latitude/box';
import { Text } from '@latitude/text';
import { COLOR, MARGIN, PADDING, ALIGN, FONT_WEIGHT } from '@latitude/core/utils/constants';
import { List, ListItem } from '@latitude/list';
import { Heading4, Heading5 } from '@latitude/heading';
import SingleTextBox from '@latitude/single-text-box';

const MedicalList = () => {return (
    <Box
        paddingTop={PADDING.P48}
        paddingBottom={PADDING.P48}
        backgroundColor={COLOR.GREY6}
        css={`
          [class^='Spacing__StyledResponsive'] {
            margin: auto;
          }
          div[class*='SingleTextBox__ContentBox-'] {
            max-width: 800px;
          }
        `}
      >
        <SingleTextBox
          box1={
            <>
              <Heading4
                marginBottom={MARGIN.M16}
                color={COLOR.BLACK}
              >
                What can I use my solar and battery loan for?
              </Heading4>
              <Text
                marginBottom={MARGIN.M16}
                fontWeight={FONT_WEIGHT.MEDIUM}
              >
                You can use your Latitude solar and battery loan if you&apos;re borrowing
              for one of the below categories:
              </Text>
              <div class="row">
                <div class="col-sm">
              <List>
                <ListItem>
                  Solar panels &amp; inverters
                </ListItem>
                <ListItem>
                  Batteries &amp; storage
                </ListItem>
                <ListItem>
                  Electric vehicle charging solutions
                </ListItem>
                <ListItem>
                  Energy efficient appliances
                </ListItem>
                </List>
                </div>
                <div class="col-sm">
                    <List>
                <ListItem>
                  Double &amp; triple glazing
                </ListItem>
                <ListItem>
                  Solar heating &amp; hot water services
                </ListItem>
                <ListItem>
                  Lighting upgrade
                </ListItem>
                <ListItem>
                  All things green &amp; focussed on carbon reduction
                </ListItem>
              </List>
              </div>
              </div>
            </>
          }
        />
    </Box>

)};

export default MedicalList;
