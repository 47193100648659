import React, { useState, useEffect, useContext } from 'react';
import { marked } from 'marked';
import ReactHTMLParser from 'react-html-parser';
import Helmet from 'react-helmet';
import { StaticImage } from 'gatsby-plugin-image';
import { FeaturesSlider } from '@latitude/features-slider';
import { Link } from '@latitude/link';
import { DigitalWallets } from '@latitude/digital-wallets';
import { Horizontal } from '@latitude/spacing';
import { HorizontalRule } from '@latitude/horizontal-rule';
import {
  BUTTON_STYLE,
  BREAKPOINT,
  MARGIN
} from '@latitude/core/utils/constants';
import RatesAndFees from '@/components/RatesAndFees/RatesAndFees';
import LabRatesAndFees from '@/components/lab-components/LabRatesAndFees';
import PageData from '../../data/pages/latitude-infinity.json';
import StickyNavigationBranded from '../../components/StickyNavigation/StickyNavigationBranded';
import Faq from '../../components/Faq/AccordionSidebarFaq';
import Layout from '../../components/layout';
import { ImportantInformation } from '@latitude/important-information';
import HeroBranded from '../../components/HeroBranded/HeroBranded';
import Section from '../../components/Section/Section';
import PromoVisaOffers from '../../components/MarketingBanner/PromoVisaOffers';
import PaymentOptions, {
  PaymentOptionsColumn,
  PaymentOptionIcon,
  PaymentOptionTitle,
  PaymentOptionDescription,
  PaymentOptionImages,
  PaymentOptionButtons,
  PaymentOptionNote,
  PaymentOptionCallout,
  PaymentOptionList,
  PaymentOptionListItem,
  PaymentOptionHeading
} from '../../components/PaymentOptions/PaymentOptions';
import { LFS_APP } from '../../utils/constants';
import Text from '../../components/Text/Text';
import Lframe from '../../components/Lframe/Lframe';
import SvgInline from '../../components/SvgInline/SvgInline';
import { Strong } from '../../components/Text/Bold';
import { CalloutSection } from './_components';
import { PageContext } from '@/context/PageContext';
import { CONTENTFUL_COMPONENTS } from '../../utils/constants';
import { useSetState } from '@/utils/hooks';
import FeeChangesCCSection from './_feeChanges';
import { NotificationBanner } from '@latitude/banner';
import HeroBanner from '@/components/lab-components/HeroBanner';

const LatitudeInfinity = props => {
  /** Contentful Data */
  const contentfulPageData = useContext(PageContext);
  const state = contentfulPageData?.updatedBody || {};

  const featureSliderData = [
    {
      heading:
        'Already using Latitude Infinity Rewards Visa card? <br/>Don’t forget these great features.',
      featureCards: PageData.whyChooseLatitude
    }
  ];
  /** - END - */

  return (
    <Layout location={props.location}>
      <main className="navigation-spacer" css="background-color: #f8f8f8;">
        <Helmet>
          <link
            rel="canonical"
            href="https://www.latitudefinancial.com.au/credit-cards/latitude-infinity-rewards-credit-card/"
          />
          <title>
            Latitude Infinity Rewards Credit Card | Latitude Financial
          </title>
          <meta name="description" content="" />
        </Helmet>
        {!state?.alertData && (
          <NotificationBanner siteName="latitudefinancial-com-au" />
        )}

        {state?.heroBannerData?.[0] ? (
          <HeroBanner {...state?.heroBannerData[0]} />
        ) : (
          <div css="position:relative;">
            <Lframe infinity />
            <HeroBranded
              imageContent={
                <div
                  css={`
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    @media (min-width: ${BREAKPOINT.LG}) {
                      justify-content: flex-end;
                    }
                  `}
                >
                  <StaticImage
                    src="../../images/hero/infinity-card-w-illustrations.png"
                    alt="Infinity Credit Card"
                    width={274}
                    height={310}
                  />
                </div>
              }
              title={
                <>
                  Latitude Infinity <br /> Rewards Visa
                </>
              }
              text={
                <>
                  The Latitude Infinity Rewards Visa is no longer taking new
                  applications. If you&rsquo;re after a flexible card
                  alternative, view all our{' '}
                  <a href="/credit-cards">credit card options</a>.
                </>
              }
            />
          </div>
        )}

        <div
          className="d-none d-lg-block"
          css="position: relative; z-index: 6;"
        >
          <StickyNavigationBranded
            items={PageData.nav}
            phoneNumber="1300 552 079"
            offsetElem="[data-sticky-navigation-offset]"
            {...state?.inPageNavData?.[0]}
          />
        </div>

        <FeaturesSlider
          key={(state?.featureSliderData?.[0] || featureSliderData[0]).heading}
          id="why-choose"
          data={
            (state?.featureSliderData?.[0] || featureSliderData[0]).featureCards
          }
          className="why-choose"
          heading={
            (state?.featureSliderData?.[0] || featureSliderData[0]).heading
          }
          subheading={
            (state?.featureSliderData?.[0] || featureSliderData[0]).description
          }
          css={`
            h5 {
              @media (max-width: ${BREAKPOINT.XS}) {
                height: 48px;
              }
              @media (min-width: ${BREAKPOINT.SM}) {
                height: 64px;
              }
            }
          `}
        />

        <Section
          id="offers"
          css={`
            && {
              background-color: #f8f8f8;
              padding-top: 48px;
              padding-bottom: 0;

              @media (min-width: ${BREAKPOINT.LG}) {
                padding-top: 30px;
                padding-bottom: 0;
                margin-bottom: -16px;
              }
            }
          `}
        >
          <div
            className="visaOffers"
            css={`
              display: flex;
              justify-content: center;
            `}
          >
            <PromoVisaOffers />
          </div>
        </Section>
        {state?.ratesAndFeesData?.[0] ? (
          <LabRatesAndFees {...state.ratesAndFeesData[0]} />
        ) : (
          <RatesAndFees
            id="rates"
            ratesListProps={{ data: PageData.ratesList }}
            accordionProps={{
              items: [
                {
                  id: 'international-fees',
                  title: 'International fees',
                  content: (
                    <div className="accordion-custom">
                      <div className="d-lg-flex accordion__row">
                        <p className="w-100">
                          <strong>International transaction fees</strong>
                        </p>
                        <p className="w-100">
                          3% of the Australian dollar amount
                        </p>
                      </div>

                      <div className="d-lg-flex accordion__row">
                        <p className="w-100">
                          <strong>International ATM fee</strong>
                        </p>
                        <p className="w-100">
                          $4, on top of the cash advance fee
                        </p>
                      </div>

                      <div className="d-lg-flex accordion__row">
                        <p className="w-100">
                          <strong>International over the counter fee</strong>
                        </p>
                        <p className="w-100">
                          $5, on top of the cash advance fee
                        </p>
                      </div>
                    </div>
                  )
                },
                {
                  id: 'other-fees',
                  title: 'Other fees',
                  content: (
                    <div className="accordion-custom">
                      <div className="d-lg-flex accordion__row">
                        <p className="w-100">
                          <strong>Cash advance fee</strong>
                        </p>
                        <p className="w-100">
                          $4 or 3.5% of the cash advance or cash equivalent
                          transaction, whichever is greater.
                        </p>
                      </div>

                      <div className="d-lg-flex accordion__row">
                        <p className="w-100">
                          <strong>Payment handling fee</strong>
                        </p>
                        <p className="w-100">
                          $0 when you pay via the{' '}
                          <a href="https://www.latitudefinancial.com.au/mobile-app/">
                            Latitude App
                          </a>
                          ,{' '}
                          <a href="https://servicecentre.latitudefinancial.com.au/login">
                            Latitude Service Centre
                          </a>{' '}
                          or direct debit.
                          <br />
                          $1.95 (online using BPAY<sup>®</sup>)<br />
                          $3.95 (in person at Australia Post)
                        </p>
                      </div>

                      <div className="d-lg-flex accordion__row">
                        <p className="w-100">
                          <strong>Paper statements</strong>
                        </p>
                        <p className="w-100">$5.00 per statement</p>
                      </div>

                      <div className="d-lg-flex accordion__row">
                        <p className="w-100">
                          <strong>Late fee</strong>
                        </p>
                        <p className="w-100">$45</p>
                      </div>
                    </div>
                  )
                },
                {
                  id: 'other-information',
                  title: 'Other information',
                  content: (
                    <div className="accordion-custom">
                      <div className="d-lg-flex accordion__row">
                        <p className="w-100 col-sm-8">
                          Please read the Conditions of Use to ensure you
                          understand the important information about your credit
                          card account.
                        </p>
                        <p className="w-100 col-sm-4">
                          <a href="https://assets.latitudefinancial.com/legals/conditions-of-use/latitude-infinity/cou-sep-2023.pdf">
                            Conditions of Use
                          </a>
                        </p>
                      </div>
                    </div>
                  )
                }
              ]
            }}
          />
        )}

        <Section
          heading="Payment Options"
          id="payment"
          className="section--payment-options"
        >
          <PaymentOptions
            data={[
              {
                id: 'latitude-app',
                title: 'Latitude App',
                content: (
                  <>
                    <PaymentOptionsColumn>
                      <PaymentOptionIcon>
                        <SvgInline name="payment-option-latitude-app" />
                      </PaymentOptionIcon>
                      <PaymentOptionTitle>Latitude App</PaymentOptionTitle>
                      <PaymentOptionDescription>
                        Make repayments in just a few taps with our mobile app.
                      </PaymentOptionDescription>
                      <PaymentOptionImages>
                        <Link
                          href="https://itunes.apple.com/app/apple-store/id1373059866?pt=118165058&ct=InfPayOpt0319&mt=8"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="d-inline"
                          data-trackid="payment-options-itunes-app-link"
                          trackEventData={{
                            label: LFS_APP.APP_STORE
                          }}
                        >
                          <img
                            src={
                              require('../../images/badge-app-store.svg')
                                .default
                            }
                            height="48px"
                            alt="app store"
                          />
                        </Link>
                        <Link
                          href="https://play.google.com/store/apps/details?id=com.latitudefinancial.latitudeapp&referrer=utm_source%3DInfPayOpt0319"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="d-inline"
                          data-trackid="payment-options-googleplay-app-link"
                          trackEventData={{
                            label: LFS_APP.GOOGLE_PLAY
                          }}
                        >
                          <img
                            src={
                              require('../../images/badge-google-play.svg')
                                .default
                            }
                            height="48px"
                            alt="play store"
                          />
                        </Link>
                      </PaymentOptionImages>
                    </PaymentOptionsColumn>
                    <PaymentOptionsColumn>
                      <PaymentOptionList>
                        <PaymentOptionListItem>
                          <Strong>Get the app</Strong>
                          <Text fontSize="14px">
                            Simply download the Latitude App from the App Store
                            or Google Play
                          </Text>
                        </PaymentOptionListItem>
                        <PaymentOptionListItem>
                          <Strong>Create a Latitude ID</Strong>
                          <Text fontSize="14px">
                            Open the app and create your Latitude ID (this is
                            different to your Online Service Centre login).
                          </Text>
                        </PaymentOptionListItem>
                        <PaymentOptionListItem>
                          <Strong>Link your card</Strong>
                          <Text fontSize="14px">
                            Once your credit cards are linked, you’ll be able to
                            make payments via bank transfer with your BSB and
                            Account Number (Australia only).
                          </Text>
                        </PaymentOptionListItem>
                      </PaymentOptionList>
                    </PaymentOptionsColumn>
                    <PaymentOptionsColumn>
                      <PaymentOptionNote>
                        <Text fontSize="14px">
                          <Strong>Note:</Strong> Please be aware that bank
                          transfer payments can take three business days to
                          clear and you can only make one payment to your card
                          account every five days.
                        </Text>
                      </PaymentOptionNote>
                      <PaymentOptionCallout>
                        <Strong>No Fees</Strong>
                        <Text fontSize="14px">
                          We do not charge a fee for payments made via direct
                          bank transfer on the Latitude App.
                        </Text>
                      </PaymentOptionCallout>
                    </PaymentOptionsColumn>
                  </>
                )
              },
              {
                id: 'online-service-centre',
                title: 'Latitude Service Centre',
                content: (
                  <>
                    <PaymentOptionsColumn>
                      <PaymentOptionIcon>
                        <SvgInline name="payment-option-online" />
                      </PaymentOptionIcon>
                      <PaymentOptionTitle>
                        Latitude Service Centre
                      </PaymentOptionTitle>
                      <PaymentOptionDescription>
                        One of the quickest &amp; easiest way to make
                        repayments.
                      </PaymentOptionDescription>
                      <PaymentOptionButtons>
                        <Horizontal spacing={MARGIN.M8}>
                          <Link
                            target="_blank"
                            button={BUTTON_STYLE.SECONDARY}
                            href="https://servicecentre.latitudefinancial.com.au/login"
                            trackId="payment-options-service-center-login-link"
                            width="156px"
                          >
                            Login
                          </Link>
                          <Link
                            target="_blank"
                            button={BUTTON_STYLE.TERTIARY}
                            href="https://servicecentre.latitudefinancial.com.au/register"
                            trackId="payment-options-service-center-register-link"
                            width="156px"
                          >
                            Register
                          </Link>
                        </Horizontal>
                      </PaymentOptionButtons>
                    </PaymentOptionsColumn>
                    <PaymentOptionsColumn>
                      <PaymentOptionHeading>
                        To make a payment
                      </PaymentOptionHeading>
                      <PaymentOptionList>
                        <PaymentOptionListItem>
                          <Strong>Login to the Latitude Service Centre</Strong>
                        </PaymentOptionListItem>
                        <PaymentOptionListItem>
                          <Strong>Click 'Pay My Account'</Strong>
                          <Text fontSize="14px">
                            Then enter your bank details.
                          </Text>
                        </PaymentOptionListItem>
                      </PaymentOptionList>
                      <PaymentOptionHeading>Registering</PaymentOptionHeading>
                      <Text fontSize="14px">
                        Registering is easy and only takes a few minutes, just
                        make sure you have your card or 16-digit account number
                        on hand.
                      </Text>
                    </PaymentOptionsColumn>
                    <PaymentOptionsColumn>
                      <Text fontSize="14px">
                        <Strong>Note:</Strong> Please be aware that payments can
                        take <em>3 to 5 business days</em> to clear.
                      </Text>
                      <PaymentOptionCallout>
                        <Strong>No Fees</Strong>
                        <Text fontSize="14px">
                          We do not charge a fee for payments made via the
                          Latitude Service Centre.
                        </Text>
                      </PaymentOptionCallout>
                    </PaymentOptionsColumn>
                  </>
                )
              },
              {
                id: 'bpay',
                title:
                  'BPAY<sup style="font-size: 0.75em; top: -0.5em;">&reg;</sup>',
                content: (
                  <>
                    <PaymentOptionsColumn>
                      <PaymentOptionIcon>
                        <SvgInline name="payment-option-bpay" />
                      </PaymentOptionIcon>
                      <PaymentOptionTitle>
                        BPAY<sup>®</sup>
                      </PaymentOptionTitle>
                      <PaymentOptionDescription>
                        Available 24 hours a day, seven days a week.
                      </PaymentOptionDescription>
                    </PaymentOptionsColumn>
                    <PaymentOptionsColumn>
                      <PaymentOptionHeading>
                        To make a payment
                      </PaymentOptionHeading>
                      <PaymentOptionList>
                        <PaymentOptionListItem>
                          <Strong>Quote the Biller code</Strong>
                          <Text fontSize="14px">
                            Latitude Infinity Rewards Biller Code (31989)
                          </Text>
                        </PaymentOptionListItem>
                        <PaymentOptionListItem>
                          <Strong>Your account number</Strong>
                        </PaymentOptionListItem>
                        <PaymentOptionListItem>
                          <Strong>The amount you wish to pay</Strong>
                        </PaymentOptionListItem>
                      </PaymentOptionList>
                    </PaymentOptionsColumn>
                    <PaymentOptionsColumn>
                      <Text fontSize="14px">
                        <Strong>Note:</Strong> If you’re paying by BPAY
                        <sup>&reg;</sup> on your due date, you must complete the
                        transaction before your bank’s cut off time, otherwise
                        the transaction may be processed on the following day.
                        Some BPAY<sup>&reg;</sup> payments can take up to three
                        days to appear on your account.
                      </Text>
                      <PaymentOptionCallout>
                        <Strong>Fees</Strong>
                        <Text fontSize="14px">
                          A payment handling fee of $1.95 applies for all
                          payments made via BPAY<sup>&reg;</sup> to pay your
                          account.
                        </Text>
                      </PaymentOptionCallout>
                    </PaymentOptionsColumn>
                  </>
                )
              },
              {
                id: 'direct-debit',
                title: 'Direct Debit',
                titleHtml: 'Direct Debit',
                content: (
                  <>
                    <PaymentOptionsColumn>
                      <PaymentOptionIcon>
                        <SvgInline name="payment-option-direct-debit" />
                      </PaymentOptionIcon>
                      <PaymentOptionTitle>Direct Debit</PaymentOptionTitle>
                      <PaymentOptionDescription>
                        Easily make recurring repayments.
                      </PaymentOptionDescription>
                    </PaymentOptionsColumn>
                    <PaymentOptionsColumn longColumn>
                      <Text>
                        The easiest (and quickest) way to pay off your credit
                        card is with a <strong> monthly direct debit</strong>.
                      </Text>
                      <br />
                      <Text>
                        You can choose whether to pay a set amount, the monthly
                        minimum or the full closing balance, and on your due
                        date each month your payment will automatically happen!
                      </Text>
                      <br />
                      <Text>
                        <Link href="https://latitudefs.zendesk.com/hc/en-au/articles/360007246298-Set-up-a-direct-debit">
                          Click here to find out more about setting up a direct
                          debit.
                        </Link>
                      </Text>
                    </PaymentOptionsColumn>
                  </>
                )
              }
            ]}
            tabContentMinHeight="385px"
          />
        </Section>

        <DigitalWallets
          heading="Digital wallets"
          subheading="You can pay any way on your phone or wearable tech device with just a tap."
        />
        <HorizontalRule />
        <Faq
          data={PageData.content.faq.concat(
            require('../../data/pages/credit-cards/faq-gambling.json')
          )}
          {...state?.faqData?.[0]}
        />

        <CalloutSection />

        <ImportantInformation
          data={PageData.content.importantInformation}
          sectionOneColummClasses="col-10 offset-1 col-lg-5 offset-lg-1"
          sectionTwoColummClasses="col-10 offset-1 col-lg-5 offset-lg-0"
          {...state?.importantInfoData?.[0]}
        />
      </main>
    </Layout>
  );
};

export default LatitudeInfinity;
