import React from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import { Typography } from '../Typography';
import StandaloneTextBody from './StandaloneTextBody';
import Section from '@latitude/section';
import './_standalone_text.scss';
import { Link } from '@latitude/link';

const DARK_BGS = ['#5e47b9'];

const StandaloneText = ({ text, align, background, styles }) => {
  const alignment =
    align.toUpperCase() === 'LEFT'
      ? 'left'
      : align.toUpperCase() === 'RIGHT'
      ? 'right'
      : 'center';
  const OPTIONS = {
    renderNode: {
      [BLOCKS.HEADING_1]: (_node, children) => (
        <Typography.H1>{children}</Typography.H1>
      ),
      [BLOCKS.HEADING_2]: (_node, children) => (
        <Typography.H2>{children}</Typography.H2>
      ),
      [BLOCKS.HEADING_3]: (_node, children) => (
        <Typography.H3>{children}</Typography.H3>
      ),
      [BLOCKS.HEADING_4]: (_node, children) => (
        <Typography.H4>{children}</Typography.H4>
      ),
      [BLOCKS.HEADING_5]: (_node, children) => (
        <Typography.H5>{children}</Typography.H5>
      ),
      [BLOCKS.HEADING_6]: (_node, children) => (
        <Typography.H6>{children}</Typography.H6>
      ),
      [BLOCKS.PARAGRAPH]: (_node, children) => (
        <Typography.BODY>{children}</Typography.BODY>
      ),
      [INLINES.EMBEDDED_ENTRY]: (node, _children) => {
        if (node?.data?.target?.contentType?.sys?.id === 'standaloneTextBody') {
          return <StandaloneTextBody {...node.data.target} />;
        }
      },
      [INLINES.HYPERLINK]: (node, children) => (
        <Link
          target="_blank"
          rel="noopener noreferrer"
          href={node?.data?.uri}
        >
          {children}
        </Link>
      )
    }
  };
  return (
    <div
      className={`standalone-text-wrapper ${
        DARK_BGS.includes(background) ? 'standalone-text-wrapper--dark' : ''
      }`}
      style={{
        overflow: 'auto',
        backgroundColor: background,
        textAlign: alignment,
      }}
      css={styles}
    >
      <Section className="standalone-text">
        {documentToReactComponents(text, OPTIONS)}
      </Section>
    </div>
  );
};

export default StandaloneText;
