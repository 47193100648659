import React from 'react';
import classnames from 'classnames';
import styled, { css } from 'styled-components';
import {
  COLOR,
  FONT_FAMILY,
  FONT_SIZE,
  LINE_HEIGHT,
  INPUT_PADDING
} from '../../utils/constants';
import chevronGrey from './images/chevron-grey.svg';
import chevron from './images/chevron.svg';

const StyledSelect = styled.select`
  opacity:1;
  appearance: none;
  -moz-appearance: none;
  text-indent: 0.01px;
  text-overflow: '';
  border: ${({ showError, widget }) =>
    !widget && showError ? `1px solid ${COLOR.TEXT_ERROR}` : 'none'};
  border-radius: 0;
  ${({ disabled }) => disabled ? '' : css`cursor: pointer;`}
  background: ${({ widget }) => (widget ? 'none' : `${COLOR.WHITE}`)};
  border-bottom: ${({ widget, showError }) =>
    widget
      ? showError
        ? `4px solid ${COLOR.TEXT_ERROR}`
        : `1px solid ${COLOR.BLUE}`
      : 'none'};
  outline: none;
  font-family: ${({ widget }) =>
    widget ? `${FONT_FAMILY.TITLE}` : `${FONT_FAMILY.BODY}`};
  color: ${({ cssColor }) => cssColor};
  font-size: ${({ widget }) => (widget ? `20px` : `${FONT_SIZE.NORMAL}`)};
  line-height: ${LINE_HEIGHT.STANDARD.NORMAL};
  padding: ${({ widget }) =>
    widget ? `${INPUT_PADDING.WIDGET}` : `${INPUT_PADDING.NORMAL}`};
  max-width: 100%;
  background-image: ${({ widget, disabled }) => (disabled ? null :(widget ? `url(${chevron})`:`url(${chevronGrey})`))};
  
  ${({disabled}) =>
    !disabled && css`
      background-size: 10px 5px;
      background-position: center right 10px;
      background-repeat: no-repeat;
  `}
  ${({ widget, disabled }) =>
    widget && !disabled &&
    css`
      padding-right: 32px;
      background-position: center right 0;
      background-repeat: no-repeat;
      background-size: unset;
  `}
  ${({widget}) => 
    widget && css`font-weight: 600;`
  }

  &::-ms-expand {
    display: none;
  }
`;

const StyledErrorContainer = styled.div`
  position: relative;
  padding: 10px 0 0 0;
  text-align: ${({ widget }) => (widget ? 'left !important' : '')};
`;

const StyledErrorMessage = styled.div`
  color: ${COLOR.TEXT_ERROR};
`;

const StyledLabel = styled.label`
  color: ${({ showError }) => (showError ? COLOR.TEXT_ERROR : 'inherit')};

  ${({ widget }) =>
    widget &&
    css`
      margin-bottom: 0;
      font-size: ${FONT_SIZE.LARGE};
      font-weight: 300;
      text-align: left;
    `}
`;

const Select = props => {
  const {
    id,
    label,
    placeholder,
    onChange,
    onBlur,
    name,
    selectedValue,
    values,
    className,
    showError,
    showErrorMessage,
    widget,
    color = COLOR.BODY,
    disabled = false,
  } = props;

  var placeholderOption = '';
  if (placeholder) {
    placeholderOption = (
      <option value="" key={placeholder} disabled defaultValue>
        {placeholder}
      </option>
    );
  }
  return (
    <>
      {label && (
        <StyledLabel
          id={`${id}-label`}
          htmlFor={id}
          showError={!widget && showError}
          widget={widget}
        >
          {label}
        </StyledLabel>
      )}
      <StyledSelect
        id={id}
        cssColor={color}
        onChange={onChange}
        onBlur={onBlur}
        name={name}
        value={selectedValue}
        placeholder={placeholder}
        showError={showError}
        className={classnames('styled-select--wide', className)}
        widget={widget}
        disabled={disabled}
      >
        {placeholderOption}
        {values.map(v => (
          <option value={v.value} key={v.text + '' + v.value}>
            {v.text}
          </option>
        ))}
      </StyledSelect>
      {showError && (
        <StyledErrorContainer widget={widget}>
          <StyledErrorMessage>{showErrorMessage}</StyledErrorMessage>
        </StyledErrorContainer>
      )}
    </>
  );
};

export default Select;
