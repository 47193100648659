import { getUrlFromEnv } from '@/utils/getUrlFromEnvUtil';
import { SOFT_QUOTE_BROKER_URL } from '@/utils/constants';

const brokerHeaderData = {
  site: 'Latitude',
  modalItems: [
    {
      heading: 'Get a Personal Loan quote',
      description:
        'Easily check your client\'s Personal Loan interest rate and then submit an application',
      button: {
        label: 'Get rate',
        href: getUrlFromEnv('funnel-start-broker'),
        target: '_self',
        action: 'quote-link',
        category: 'cta'
      }
    },
    {
      heading: 'Continue Personal Loan application',
      description:
        'Log in to the Broker Dashboard to review a client\'s rate and submit an application',
      button: {
        label: 'Log into the Broker Portal',
        href: getUrlFromEnv('funnel-start-broker'),
        action: 'quote-link',
        category: 'cta',
      },
    },
    {
      heading: 'Submit an Asset Loan application',
      description:
        'Easily check your client\'s Asset Loan interest rate and then submit an application',
     
      button: {
        label: 'Log into the Broker Portal',
        href: getUrlFromEnv('funnel-start-broker'),
        action: 'application-link',
        category: 'cta',
      }
    }
  ]
};

export default brokerHeaderData;
