import React from 'react';
import ReactHTMLParser from 'react-html-parser';
import './_eligibility.scss';

const Eligibility = props => (
  <div className="eligibility row col-12 col-md-6">
    <div className="col-md-4" />
    <div className="col-lg-8">
      <h3
        className="eligibility__title"
        css={{
          fontSize: '40px',
          lineHeight: '48px',
          fontWeight: '600',
          marginTop: '0'
        }}
      >
        Eligibility
      </h3>

      {props.criteria && (
        <React.Fragment>
          <h5 className="eligibility__sub-title">You must:</h5>
          <ul className="eligibility__list">
            {props.criteria.map((item, itemIndex) => (
              <li className="eligibility__list-item" key={itemIndex}>
                {ReactHTMLParser(item)}
              </li>
            ))}
          </ul>
        </React.Fragment>
      )}

      {props.supply && (
        <React.Fragment>
          {props.subtitle && (
            <h5 className="eligibility__sub-title">{props.subtitle}</h5>
          )}
          <ul className="eligibility__list">
            {props.supply.map((item, itemIndex) => (
              <li className="eligibility__list-item" key={itemIndex}>
                {ReactHTMLParser(item)}
              </li>
            ))}
          </ul>
        </React.Fragment>
      )}
    </div>
  </div>
);

Eligibility.defaultProps = {
  subtitle: 'Able to supply:'
};

export default Eligibility;
