import React, { useContext } from 'react';
import styled from 'styled-components';
import { Metadata } from '@latitude/metadata';
import { SecondaryHero } from '@latitude/hero';
import { Box } from '@latitude/box';
import { Text as LText } from '@latitude/text';
import { Heading4, Heading5 } from '@latitude/heading';
import { Vertical } from '@latitude/spacing';
import { COLOR, MARGIN } from '@latitude/core/utils/constants';
import { TabNavigation } from '@latitude/tab-navigation';
import Layout from '@/components/layout';
import { TITLE_SUFFIX } from '@/utils/constants';
import TabStyle from './_TabStyle';
import { AnalyticsContext } from 'latitude-analytics';

const Text = styled(LText)`
  color: ${COLOR.BLACK};
`;

const TermsData = {
  "items": [
    {
      "id": "/",
      "label": "Latitude Gem Visa website"
    },
    {
      "id": "/mobile",
      "label": "Latitude Gem Visa Mobile Application"
    }
  ]
}

const TermsAndConditionsWebsitePage = ({ location }) => {
  const [analytics] = useContext(AnalyticsContext);

  // adding pageData to analytics
  analytics.pageData = {
    page_pageTitle: 'terms-conditions',
    page_pageType: 'information-page',
    page_siteSection: 'information',
    page_siteSubsection: 'credit-cards'
  };

  return (
    <Layout location={location}>
    <Metadata title={`Terms and Conditions for Latitude Gem Visa website | ${TITLE_SUFFIX}`} />
    <Box backgroundColor={COLOR.GREY6}>
      <SecondaryHero heading="Terms and Conditions" />
    </Box>

    <TabStyle>
      <TabNavigation basepath="/credit-cards/gem-visa/terms-conditions"  data={TermsData.items} />
    </TabStyle>
    <Box>
      <Box.Section isBodyCopy backgroundColor={COLOR.WHITE}>
        <Heading4 color={COLOR.BLACK} marginBottom={MARGIN.M40}>
          Terms &amp; Conditions for Latitude Gem Visa website
        </Heading4>
        <Vertical spacing={MARGIN.TEXT}>
          <Text>
            Please read these terms carefully before using this site. The Gem® Visa website is provided by Latitude
            Financial Services Australia Holdings Pty Ltd (ABN 46 603 161 100 ) and Latitude Finance Australia (ABN 42
            008 583 588) (&quot;we/us/our&quot;). By using the site or downloading materials from the site, you agree to
            abide by the Terms &amp; Conditions set forth in this notice. If you do not agree to abide by these Terms
            &amp; Conditions do not use the site or download materials from the site.
          </Text>
          <Heading5 color={COLOR.BLACK}>Limited licence</Heading5>
          <Text>
            Subject to the Terms & Conditions set forth in this Agreement, we grant you a non-exclusive,
            non-transferable, limited right to access, use and display this site and the materials thereon. You agree
            not to interrupt or attempt to interrupt the operation of the site in any way.
          </Text>
          <Text>
            We authorise you to view and download the information (&quot;Materials&quot;) at this website
            (&quot;Site&quot;) only for your personal, non-commercial use. This authorisation is not a transfer of title
            in the Materials and copies of the Materials and is subject to the following restrictions: 1) you must
            retain, on all copies of the Materials downloaded, all copyright and other proprietary notices contained in
            the Materials; 2) you may not modify the Materials in any way or reproduce or publicly display, perform, or
            distribute or otherwise use them for any public or commercial purpose; and 3) you must not transfer the
            Materials to any other person unless you give them notice of, and they agree to accept, the obligations
            arising under these Terms & Conditions of use. You agree to abide by all additional restrictions displayed
            on the Site as it may be updated from time to time. This Site, including all Materials, is copyrighted and
            protected by worldwide copyright laws and treaty provisions. You agree to comply with all copyright laws
            worldwide in your use of this Site and to prevent any unauthorised copying of the Materials. Except as
            expressly provided herein, we do not grant any express or implied right to you under any patents,
            trademarks, copyrights or trade secret information.
          </Text>
          <Heading5 color={COLOR.BLACK}>Disclaimer</Heading5>
          <Text>
            THE MATERIALS MAY CONTAIN INACCURACIES AND TYPOGRAPHICAL ERRORS. WE DO NOT WARRANT THE ACCURACY OR
            COMPLETENESS OF THE MATERIALS OR THE RELIABILITY OF ANY ADVICE, OPINION, STATEMENT OR OTHER INFORMATION
            DISPLAYED OR DISTRIBUTED THROUGH THE SITE. YOU ACKNOWLEDGE THAT ANY RELIANCE ON ANY SUCH OPINION, ADVICE,
            STATEMENT, MEMORANDUM, OR INFORMATION SHALL BE AT YOUR SOLE RISK. WE RESERVE THE RIGHT, IN ITS SOLE
            DISCRETION, TO CORRECT ANY ERRORS OR OMISSIONS IN ANY PORTION OF THE SITE. WE MAY MAKE ANY OTHER CHANGES TO
            THE SITE, THE MATERIALS AND THE PRODUCTS, PROGRAMS, SERVICES OR PRICES (IF ANY) DESCRIBED IN THE SITE AT ANY
            TIME WITHOUT NOTICE.
          </Text>
          <Text>
            SUBJECT TO ANY APPLICABLE PROVISIONS OF THE TRADE PRACTICES ACT 1974 (CTH), WHICH CANNOT BE MODIFIED OR
            EXCLUDED, THIS SITE, THE INFORMATION AND MATERIALS ON THE SITE, AND THE SOFTWARE MADE AVAILABLE ON THE SITE,
            ARE PROVIDED &quot;AS IS&quot; WITHOUT ANY REPRESENTATION OR WARRANTY, EXPRESS OR IMPLIED, OF ANY KIND,
            INCLUDING, BUT NOT LIMITED TO, WARRANTIES OF MERCHANTABILITY, NONINFRINGEMENT, OR FITNESS FOR ANY PARTICULAR
            PURPOSE.
          </Text>
          <Heading5 color={COLOR.BLACK}>Third-party sites</Heading5>
          <Text>
            As a convenience to you, we may provide, on this Site, links to websites operated by other entities. If you
            use these sites, you will leave this Site. If you decide to visit any linked site, you do so at your own
            risk and it is your responsibility to take all protective measures to guard against viruses or other
            destructive elements. We make no warranty or representation regarding, and does not endorse, any linked
            websites or the information appearing thereon or any of the products or services described thereon. Links do
            not imply that we or this Site sponsors, endorses, is affiliated or associated with, or is legally
            authorised to use any trademark, trade name, logo or copyright symbol displayed in or accessible through the
            links, or that any linked site is authorised to use any trademark, trade name, logo or copyright symbol of
            ours or any of our affiliates or subsidiaries.
          </Text>
          <Text>
            Information provided by you Unless you are applying online or using the Online Service Centre, we do not
            want you to, and you should not, send any confidential or proprietary information to us via the Site. You
            agree that any such information or materials that you or individuals acting on your behalf provide to us
            will not be considered confidential or proprietary. By providing any such information or materials to us,
            you grant to us an unrestricted, irrevocable, worldwide, royalty-free license to use, reproduce, display,
            publicly perform, transmit and distribute such information and materials, and you further agree that we are
            free to use any ideas, concepts or know-how that you or individuals acting on your behalf provide to us. You
            further recognise that we do not want you to, and you warrant that you shall not, provide any information or
            materials to us that is defamatory, threatening, obscene, harassing, or otherwise unlawful, or that
            incorporates the proprietary material of another.
          </Text>
          <Heading5 color={COLOR.BLACK}>Limitation of damages</Heading5>
          <Text>
            IN NO EVENT SHALL WE OR ANY OF OUR SUBSIDIARIES BE LIABLE TO ANY ENTITY FOR ANY DIRECT, INDIRECT, SPECIAL,
            CONSEQUENTIAL OR OTHER DAMAGES (INCLUDING, WITHOUT LIMITATION, ANY LOST PROFITS, BUSINESS INTERRUPTION, LOSS
            OF INFORMATION OR PROGRAMS OR OTHER DATA ON YOUR INFORMATION HANDLING SYSTEM) THAT ARE RELATED TO THE USE
            OF, OR THE INABILITY TO USE, THE CONTENT, MATERIALS, AND FUNCTIONS OF THE SITE OR ANY LINKED WEBSITE, EVEN
            IF WE ARE EXPRESSLY ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
          </Text>
          <Heading5 color={COLOR.BLACK}>Changes</Heading5>
          <Text>
            We reserve the right, at our sole discretion, to change, modify, add or remove any portion of this Agreement
            in whole or in part, at any time. Changes in this Agreement will be effective when notice of such change is
            posted. Your continued use of the Site after any changes to this Agreement are posted will be considered
            acceptance of those changes. We may terminate, change, suspend or discontinue any aspect of our Site,
            including the availability of any features of the Site, at any time. We may also impose limits on certain
            features and services or restrict your access to parts or all of the Site without notice or liability. We
            may terminate the authorisation, rights and license given above and, upon such termination, you shall
            immediately destroy all Materials.
          </Text>
          <Heading5 color={COLOR.BLACK}>International users and choice of law</Heading5>
          <Text>
            This Site is controlled, operated and administered by us from our offices within the Commonwealth of
            Australia. We make no representation that materials at this site are appropriate or available for use at
            other locations outside of the Commonwealth of Australia and access to them from territories where their
            contents are illegal is prohibited. You may not use the Site or export the Materials in violation of
            Australian export laws and regulations. If you access this Site from a locations outside of the Commonwealth
            of Australia, you are responsible for compliance with all local laws. These Terms &amp; Conditions of use
            shall be governed by the laws of the State of Victoria, Australia, without giving effect to its conflict of
            laws provisions. This Agreement constitutes the entire agreement between us and you with respect to your use
            of the Site. Any cause of action you may have with respect to your use of the Site must be commenced within
            the period provided by any applicable statute of limitations. If for any reason a court of competent
            jurisdiction finds any provision of the Agreement or portion thereof, to be unenforceable, that provision
            shall be enforced to the maximum extent permissible so as to effect the intent of the Agreement, and the
            remainder of this Agreement shall continue in full force and effect.
          </Text>
          <Heading5 color={COLOR.BLACK}>Mobile Application Users - Limitation of Liability</Heading5>
          <Text>
            If you have accessed this information via a mobile application program (“App”), your access to, and use of
            this App is at your own risk. We acknowledge, however that certain statutory warranties cannot be excluded.
            Without limiting the above, we make no warranties or representations as to the accessibility, security,
            stability or reliability of this App and we specifically disclaim any liability or responsibility for any
            App faults, failures or interruptions or the accuracy, timeliness, completeness, security or reliability of
            any communications (including, without limitation, any transactions) made using the App.
          </Text>
        </Vertical>
      </Box.Section>
    </Box>
  </Layout>
  );
};

export default TermsAndConditionsWebsitePage;
