import React from 'react';
import { AnalyticsLocationProvider } from 'latitude-analytics';
import { FeaturesSlider } from '@latitude/features-slider';
import Section from '@latitude/section';
import { COLOR, BREAKPOINT, ALIGN, BUTTON_STYLE,MARGIN } from '@latitude/core/utils/constants';
import RewardImg from './images/lsh_money.webp';
import MoneyImg from './images/pay_img.webp';
import { Box } from '@/components/Box/Box';
import { Heading4, Heading3 } from '@latitude/heading';
import { Link } from '@latitude/link';
import { Text } from '@latitude/text';
import { Vertical } from '@latitude/spacing';
import styled from 'styled-components';


const BenifitsOfLowRateCard = (props) => {
  return (
    <>
      <Box
       id="why-us"
       backgroundColor="#E6E6E6"
       css={`
         padding: 48px 0;
         z-index: 1;
         @media (min-width: ${BREAKPOINT.LG}) {
           padding: 72px 0 120px;
         }
          @media (max-width: ${BREAKPOINT.MD}) {
          padding: 25px 0;
         }  
       `}
      >
        <Heading4
          color={COLOR.BLACK}
          align={ALIGN.CENTER}
          marginBottom={MARGIN.M56}
          isResponsive
        >
          New here? Pick an application offer that works best for you.
        </Heading4>
        <SectionBox>
          <Vertical spacing={MARGIN.M16}>
            <img
              src={RewardImg}
              css="width: 264px; margin: -81px auto 0; margin-bottom: 16px !important;"
              alt="Interest Free plans to suit you."
            />
            <Heading4
              color={COLOR.BLACK}
              align={ALIGN.LEFT}
              isResponsive={false}
            >
              Get up to $300 in bonus Latitude Rewards<sup>3</sup>
            </Heading4>
            <Text align={ALIGN.LEFT}>
              When you spend $1,000 or more on eligible transactions every statement period in your first 6 months from approval. T&Cs apply.
            </Text>
            <Link
              button={BUTTON_STYLE.PRIMARY}
              css="width: 200px; margin: 0 auto;"
              href={props.applyUrlLatitudeRewards}
              data-trackid="low-rate-rewards"
              even
              trackEventData={{
                label: 'low-rate-rewards',
                location: 'Pick one application offer'
              }}
            >
              Apply now
            </Link>
            <FooterText>
              New customers only, must be approved by 28 Feb 2025. T&Cs, card fee and other charges apply.
            </FooterText>
          </Vertical>
          <Vertical spacing={MARGIN.M16} className="ml-5">
            <img
              src={MoneyImg}
              css="width: 264px; margin: -81px auto 0; margin-bottom: 16px !important;"
              alt="Interest Free plans to suit you."
            />
            <Heading4
              color={COLOR.BLACK}
              align={ALIGN.LEFT}
              isResponsive={false}
            >
              Get 0% for 18 months on balance transfers<sup>4</sup>
            </Heading4>
            <Text align={ALIGN.LEFT}>
              3% balance transfer fee applies. T&Cs apply.
            </Text>
            <Link
              button={BUTTON_STYLE.PRIMARY}
              css="width: 200px; margin: 0 auto;"
              href={props.applyUrlBalanceTransfer}
              data-trackid="low-rate-card-balance-transfer"
              trackEventData={{
                label: 'Low rate card balance transfer',
                location: 'Pick one application offer'
              }}
            >
              Apply now
            </Link>
            <FooterText>
              New customers only, must be approved by 28 Feb 2025. T&Cs, card fee and other charges apply.
            </FooterText>
          </Vertical>
        </SectionBox>

      </Box>
    <Box>
    </Box>
    </>
  );
};

const FooterText = styled.div`
    font-family: Roboto, Open Sans, sans-serif;
    font-size: 12px;
    line-height: 15px;
}
`
const SectionBox = styled.div`
          display: flex;
          justify-content : center;
          align-items: center;
          @media (max-width: ${BREAKPOINT.MD}) {
                flex-direction: column;
                margin-left: 0px;
                [class^="Spacing__StyledVertical-"] {
                margin-bottom: 20px;
                }
           }

          [class^="Spacing__StyledVertical-"] {
          margin-left: 20px;
          width: 370px !important;

          h3 {
           margin : 25px 0 32px;
          }

          img{
              width: 350px;
              height: 220px;
              margin: 0px;
          }

          p{
          height : 70px;
          }

          a{
          margin : 15px 0px;
          }

          }
`
export default BenifitsOfLowRateCard;
