import React, { useEffect } from 'react';

function ClearScorePage({ location }) {

  useEffect(() => {
    if (window?.location) {
      window.location.replace('/credit-cards/gomastercard/');
    }
  }, []);

  return <></>;
}

export default ClearScorePage;
