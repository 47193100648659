import React, { useEffect } from 'react';


const RedirectToPriacyComponent = () => {
  function replaceURL(currentUrl, targetRelativeUrl) {
    let url = new URL(currentUrl);
    let newUrl = `${url.origin}${targetRelativeUrl}`;
    return newUrl;
  }
  useEffect(() => {
       let currentUrl = window.location.href;
       let targetRelativeUrl = '/privacy/';
       let newUrl = replaceURL(currentUrl, targetRelativeUrl);
       window.location.href = newUrl;
  }, []);
  return null;
};

export default RedirectToPriacyComponent;


