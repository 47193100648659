import React from 'react';
import styled from 'styled-components';
import { Link } from '@latitude/link';
import { JUSTIFY_CONTENT } from '@latitude/core/utils/constants';
import { BREAKPOINT, COLOR, MARGIN } from '../../utils/constants';
import { Box, Flex } from '../Box/Box';

const FramedBrandBanner = props => {
  // input values
  const { className, applyNow } = props;
  const containerBgStyle = props.containerBgStyle || COLOR.TRANSPARENT;
  const contentBgStyle = props.contentBgStyle || COLOR.BLUE;
  const framedContent = props.framedContent || null;
  const textContent = props.textContent || 'Add Your Content Here...';
  const imageOnLeft = (props.imageOnLeft === undefined)?true:props.imageOnLeft;
  const applyNowLabel = props.applyNowLabel || 'Apply Now';

  // styling
  const frameWidth = props.frameWidth || 480;
  const frameHeight = props.frameHeight || 480;
  const frameOffset = 30;
  const contentPaddingV = 32;
  const paddingMob = 16;
  const colGutter = 48;
  const offsetShadowSize = 10;

  const SectionContainer = styled.div`
    background: ${containerBgStyle};
    padding-top: 48px;

    @media (min-width: ${BREAKPOINT.SM}) {
      // make sure offset shadow is visible
      padding-bottom: ${offsetShadowSize}px;
    }

    @media (min-width: ${BREAKPOINT.LG}) {
      padding: ${frameOffset * 2}px 0;
    }
  `;

  const BackgroundWrapper = styled.div`
    color: ${COLOR.WHITE};
    background: ${contentBgStyle};
    margin: 0 auto;

    @media (min-width: ${BREAKPOINT.SM}) {
      max-width: ${frameWidth}px;
      box-shadow: ${offsetShadowSize}px ${offsetShadowSize}px 0 ${COLOR.GREY12};
    }

    @media (min-width: ${BREAKPOINT.LG}) {
      max-width: none;
      box-shadow: none;
    }
  `;

  const ContentWrapper = styled(Box)`
    margin: 0 auto;
    padding: 0;

    @media (min-width: ${BREAKPOINT.LG}) {
      display: flex;
      flex-direction: row${imageOnLeft||`-reverse`};
      align-items: center;
      width: 100%;
      overflow: visible;
    }
  `;

  const FramedContentContainer = styled.div`
    display: block;
    position: relative;
    background: ${COLOR.GREY6};
    border: ${paddingMob}px solid ${COLOR.WHITE};
    box-sizing: border-box;
    margin: 0 auto;
    overflow: hidden;

    // make sure <Image> content works responsively
    img {
      &,
      &.image,
      &.img-fluid {
        display: block;
        width: 100%;
      }
    }

    @media (min-width: ${BREAKPOINT.SM}) {
      // frame content has to be square
      min-width: ${frameWidth}px;
      max-width: ${frameWidth}px;
      width: ${frameWidth}px;
      height: ${frameHeight}px;
      border-width: ${frameOffset}px;
    }

    @media (min-width: ${BREAKPOINT.LG}) {
      flex: 0 0 ${frameWidth}px;
      box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.25);

      // popup out frame on desktop
      margin: -${frameOffset}px 0;
    }

    @media (max-width: ${BREAKPOINT.XS}) {
      box-shadow: 0px 0px 1px #888, 0px 1px 4px #888;
    }
  `;

  const TextContentContainer = styled.div`
    @media (min-width: ${BREAKPOINT.LG}) {
      flex: 1 1 auto;
      width: 100%; // IE11: requires width inside a flex column parent

      // vertical center align content
      display: flex;
      align-items: center;
      flex-direction: column;
    }
  `;

  const TextContentWrapper = styled.div`
    margin: ${paddingMob * 1.5}px ${paddingMob}px;

    @media (min-width: ${BREAKPOINT.SM}) {
      margin: ${frameOffset * 1.5}px ${frameOffset}px ${MARGIN.M16};
    }

    @media (min-width: ${BREAKPOINT.LG}) {
      flex: 1 1 auto;
      width: 100%; // IE11: requires width inside a flex column parent
      margin: ${contentPaddingV}px 0 0 ${colGutter}px;
    }
  `;

  return (
    <SectionContainer className={className}>
      <BackgroundWrapper>
        <ContentWrapper isResponsive>
          <FramedContentContainer>{framedContent}</FramedContentContainer>
          <TextContentContainer>
            <TextContentWrapper>{textContent}</TextContentWrapper>
            {applyNow && (
              <Flex justifyContent={JUSTIFY_CONTENT.CENTER}>
                <Box width="250px" margin={`${MARGIN.M16} 0 ${MARGIN.M32} 0`}>
                  <Link {...props.buttonProps}>{applyNowLabel}</Link>
                </Box>
              </Flex>
            )}
          </TextContentContainer>
        </ContentWrapper>
      </BackgroundWrapper>
    </SectionContainer>
  );
};

export default FramedBrandBanner;
