import useRowState from './useRowState';
import useCategoryState from './useCategoryState';

const useInsuranceSuperState = () => {
  const life = useRowState('Life', 'life');
  const incomeProtection = useRowState(
    'Income Protection',
    'income-protection'
  );
  const health = useRowState('Health', 'health');
  const superInsurance = useRowState('Superannuation', 'super');
  const homeContents = useRowState('Home & Contents', 'home-contents');
  const car = useRowState('Car', 'car');
  const business = useRowState('Business', 'business');
  const otherInsurance = useRowState(
    'Other (boat, caravan, trailer)',
    'other-insurance'
  );

  const insuranceSuperStateFields = [
    life,
    incomeProtection,
    health,
    superInsurance,
    homeContents,
    car,
    business,
    otherInsurance
  ];

  return useCategoryState(
    'insurance-super-accordion',
    'Insurance & Superannuation',
    insuranceSuperStateFields
  );
};

export default useInsuranceSuperState;
