import useRowState from './useRowState';
import useCategoryState from './useCategoryState';

const useTransportState = () => {
  const vehicle = useRowState('Vehicle', 'vehicle');
  const carWash = useRowState('Car Wash/ Detailing Services', 'car-wash');
  const maintenance = useRowState('Maintenance', 'car-maintenance');
  const petrol = useRowState('Petrol', 'petrol');
  const parking = useRowState('Parking', 'parking');
  const publicTransport = useRowState('Public Transport', 'public-transport');
  const otherCar = useRowState('Other', 'other-car');

  const transportStateFields = [
    vehicle,
    carWash,
    maintenance,
    petrol,
    parking,
    publicTransport,
    otherCar
  ];

  return useCategoryState(
    'transport-accordion',
    'Transport & Motor Vehicle',
    transportStateFields
  );
};

export default useTransportState;
