import React, { useContext, useEffect } from 'react';
import ReactHTMLParser from 'react-html-parser';
import styled from 'styled-components';
import { marked } from 'marked';

import Section from '@latitude/section';
import SvgInline from '@latitude/svg-inline';
import { Link } from '@latitude/link';
import { BUTTON_STYLE } from '@latitude/core/utils/constants';
import { PageContext } from '@/context/PageContext';

import CATEGORIES from './data/interest-free-categories.json';
import INTEREST_FREE_OFFERS from './data/interest-free-offers.json';
import { CONTENTFUL_COMPONENTS } from '@/utils/constants';
import { useSetState } from '@/utils/hooks';

const ANY_VALUE = '*';

function FindByCategorySection({ id, currentCategory }) {
  /** Contentful Data */
  const contentfulPageData = useContext(PageContext);

  const [state, setState] = useSetState({
    contentfulEnabled: false,
    findByCategoryData: {
      heading: "Shop by retail category",
      offers: INTEREST_FREE_OFFERS,
      allCategories: CATEGORIES
    }
  });

  useEffect(() => {
    // For componentsOfferCategory and componentsOffers
    const categoriesComponent = contentfulPageData?.body?.filter(
      b => b?.contentType?.sys?.id === CONTENTFUL_COMPONENTS.FIND_BY_CATEGORY_SECTION
    )[0];

    const findAnOfferComponent = contentfulPageData?.body?.filter(
      b => b?.contentType?.sys?.id === CONTENTFUL_COMPONENTS.FIND_AN_OFFER_SECTION
    )[0];

    if (categoriesComponent && findAnOfferComponent) {
      setState({ 
        contentfulEnabled: true,
        findByCategoryData: {
          heading: categoriesComponent.title,
          offers: findAnOfferComponent.offers,
          allCategories: categoriesComponent.offerCategory
        }
      });
    }
  }, [contentfulPageData]);
  /** - END - */

  function offerMerchantOrder(a, b) {
    if (!state.contentfulEnabled) {
      // Compare merchants by numerical order
      if (a.merchant.order > b.merchant.order) return 1;
      if (a.merchant.order < b.merchant.order) return -1;
      // Compare merchant names alphabetically
      if (a.merchant.name > b.merchant.name) return 1;
      if (a.merchant.name < b.merchant.name) return -1;
      // Otherwise, they're equal
      return 0;
    } else {
      return 0;
    }
  }

  function showAllCategories() {
    return (
      <FindByCategoryContainer id={id} heading={state.findByCategoryData.heading}>
        <div className="category-items">
          {state.findByCategoryData.allCategories.map((category, i) => (
            <a
              key={category.name}
              href={`../find-by-category/?c=${encodeURIComponent(
                category.name
              )}`}
              className="category-item"
            >
              <div className="category-image">
                <img
                  src={
                    state.contentfulEnabled
                      ? category?.image?.file?.url
                      : category.image
                  }
                  loading="lazy"
                  alt={category.name}
                  css={{
                    height: '150px',
                    width: '200px',
                    objectFit: 'contain'
                  }}
                />
              </div>
              <div className="category-name">{category.name}</div>
            </a>
          ))}
        </div>
      </FindByCategoryContainer>
    );
  }

  function showSelectedCategory(category) {
    function filterOffers(offer) {
      const categories = offer?.merchant?.categories || [];
      if (
        (state.contentfulEnabled &&
          !categories.some(cat => cat.name === category)) ||
        (!state.contentfulEnabled && !categories.includes(category))
      ) {
        return false;
      }
      return true;
    }

    return (
      <FindByCategoryContainer id={id} heading={category + ' partners'}>
        <div
          css={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            margin: '0 auto 0 auto',
            justifyContent: 'center'
          }}
        >
          {state.findByCategoryData.offers
            ?.filter(filterOffers)
            .sort(offerMerchantOrder)
            .map((offer, index, allOffers) => {
              return (
                <div
                  key={index}
                  css={{
                    backgroundColor: '#ffffff',
                    flexBasis: '300px',
                    padding: '10pt',
                    margin: '10pt',
                    borderRadius: '8px',
                    boxShadow: '0px 2px 5px 0px #0000001A'
                  }}
                >
                  <div css={{ textAlign: 'center' }}>
                    <img
                      src={
                        state.contentfulEnabled
                          ? offer?.merchant?.logo?.file?.url
                          : offer.merchant.logo
                      }
                      alt={offer?.merchant?.name}
                      loading="lazy"
                      css={{
                        height: '150px',
                        width: '200px',
                        objectFit: 'contain'
                      }}
                    />
                  </div>

                  <div
                    css={{
                      fontSize: '24px',
                      fontWeight: '600',
                      textAlign: 'left',
                      height: '80px'
                    }}
                  >
                    {offer.title}
                  </div>
                  <div
                    css={{
                      fontSize: '14px',
                      fontWeight: '300',
                      lineHeight: '16px',
                      textAlign: 'left',
                      height: '60px'
                    }}
                  >
                    {state.contentfulEnabled
                      ? offer?.termsConds &&
                        ReactHTMLParser(marked(offer.termsConds))
                      : ReactHTMLParser(offer.termsConds)}
                  </div>
                  <Link
                    href={offer?.link}
                    target="_blank"
                    rel="noreferrer noopener"
                    button={BUTTON_STYLE.SECONDARY}
                    css={{
                      margin: '20pt auto 0 auto',
                      width: 'fit-content',
                      fontSize: '13px'
                    }}
                  >
                    {state.contentfulEnabled && offer?.linkTitle
                      ? offer.linkTitle
                      : offer?.merchant?.linkTitle
                      ? offer.merchant.linkTitle
                      : 'View on Partner website'}
                    &nbsp;
                    <SvgInline name="external-link" />
                  </Link>
                </div>
              );
            })}
        </div>

        <div
          css={{
            textAlign: 'center',
            width: '100%'
          }}
        >
          <Link
            button={BUTTON_STYLE.TERTIARY}
            css={{ margin: '0 auto 0 auto', width: '150pt' }}
            href="../find-by-category/"
          >
            See all categories
          </Link>
        </div>
      </FindByCategoryContainer>
    );
  }

  if (currentCategory === ANY_VALUE) {
    return showAllCategories();
  } else {
    return showSelectedCategory(currentCategory);
  }
}

const FindByCategoryContainer = styled(Section)`
  background-color: #f8f8f8;

  div.category-items {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin: 10pt auto;
    overflow: hidden;
    padding: 5pt;
  }
  div.category-items a.category-item {
    background-color: #ffffff;
    border-radius: 4px;
    border: 1px solid #cccccc;
    box-shadow: 1pt 3pt #cccccc;
    color: #000000;
    cursor: pointer;
    flex-basis: 220px;
    margin: 10pt;
    padding: 0 0;
  }
  div.category-items a.category-item:hover {
    background-color: #e2f2ff;
  }

  div.category-items a.category-item div.category-image img {
    width: 100%;
    height: auto;
    object-fit: contain;
  }
  div.category-items a.category-item div.category-name {
    font-size: 18px;
    height: 60px;
    padding: 5pt;
    text-align: center;
  }
`;

export default FindByCategorySection;
