/* eslint-disable import/extensions, import/no-unresolved */
import React from 'react';
import { FONT_SIZE } from '@latitude/core/utils/constants';
import { StaticImage } from 'gatsby-plugin-image';

import {
  StyledHeroBranded,
  ImageBox,
  LeaderDetailsWrapper,
  DetailsContainer,
  StyledText,
  LeadershipPageLayout
} from '../../../components/Leadership';

function CampbellMorrisonPage(props) {
  const title = 'Campbell Morrison';
  const content =
    'Chief Operating Officer & Executive General Manager, Enterprise Services';

  return (
    <LeadershipPageLayout title={title} {...props}>
      <StyledHeroBranded
        title={title}
        text={content}
        breadcrumbs={[
          {
            name: 'About us',
            href: '/about-us/'
          },
          {
            name: 'Our company',
            href: '/about-us/our-company/'
          },
          {
            name: 'Campbell Morrison'
          }
        ]}
        imageContent={
          <ImageBox>
            <StaticImage
              alt={title}
              src="../images/campbell-morrison.webp"
              width={450}
              quality={100}
              placeholder="dominantColor"
            />
          </ImageBox>
        }
      />
      <LeaderDetailsWrapper>
        <DetailsContainer display="flex">
          <StyledText fontSize={FONT_SIZE.LARGE}>
            Campbell joined Latitude as Chief Operating Officer & Executive
            General Manager, Enterprise Services in October 2024 and is
            responsible for all customer care, technology and marketing.
            <br />
            <br />
            Before Latitude, Campbell spent six years with NAB and was
            accountable for leading the NAB Group's COVID response to assist
            vulnerable customers through the pandemic as Executive, NAB Assist,
            and was instrumental in building NAB's Indian entity based out of
            Gurugram near Delhi. In this role Campbell established a team of
            1000 plus operational colleagues providing scale, timezone and
            talent accessibility advantage. Before NAB, Campbell had 21 years
            with ANZ leading teams across Australia, New Zealand and India.
          </StyledText>
          <StyledText fontSize={FONT_SIZE.LARGE}>
            Campbell holds a bachelors degree in economics from Massey
            University in New Zealand.
          </StyledText>
        </DetailsContainer>
      </LeaderDetailsWrapper>
    </LeadershipPageLayout>
  );
}

export default CampbellMorrisonPage;
