import React, { useContext } from 'react';
import ReactHtmlParser from 'react-html-parser';
import Layout from '@/components/layout';
import Section from '@latitude/section';
import { AnalyticsLocationProvider } from 'latitude-analytics';
import { Box } from '@/components/Box/Box';
import { Heading4, Heading5, Heading6 } from '@latitude/heading';
import { Accordion } from '@latitude/accordion';
import { Text } from '@latitude/text';
import { Link } from '@latitude/link';
import Lframe from '@/components/Lframe/Lframe';
import HeroBranded from '@/components/HeroBranded/HeroBranded';
import FeaturesSlider from '@/components/FeaturesSlider/FeaturesSlider';
import { Promo } from '@latitude/promo';
import Metadata from '@/components/Metadata/Metadata';
import StickyNavigationBranded from '@/components/StickyNavigation/StickyNavigationBranded';
import { Tel } from '@latitude/tel';
import { List, ListItem } from '@/components/List';
import HorizontalRule from '@/components/HorizontalRule/HorizontalRule';
import ManageYourLoan from '@/components/ManageYourLoan/ManageYourLoan';
import { PageContext } from '@/context/PageContext';

import {
  ALIGN,
  BREAKPOINT,
  COLOR,
  PADDING
} from '@latitude/core/utils/constants';
import PageData from './hardship.json';
import insuranceSquare from './insurance-square.svg';
import HeroBanner from '@/components/lab-components/HeroBanner';

const hardshipApplicationFormUrl =
  'https://assets.latitudefinancial.com/forms/hardship/';
const hardshipPhoneNumber = '1800 220 718';
const hardshipHeading = "What's the best way to contact Hardship Care?";
const hardshipInsuranceHeading = 'Do you have Insurance with Latitude?';
const vimeoVideoLink = 'https://player.vimeo.com/video/720926907?h=58f77fefb0';

const Hardship = props => {
  /** Contentful Data */
  const contentfulPageData = useContext(PageContext);
  const state = contentfulPageData?.updatedBody || {};
  /** - END - */

  return (
    <Layout location={props.location}>
      <main
        className="navigation-spacer"
        css={`
          background-color: ${COLOR.GREY6};
        `}
      >
        <Metadata
          title={PageData.metaTitle}
          description={PageData.metaDesc}
          canonical={PageData.path}
        />
        {state?.heroBannerData?.[0] ? (
          <HeroBanner {...state?.heroBannerData[0]} />
        ) : (
          <>
            <Lframe financialHardship />
            <HeroBranded
              css={`
                @media (min-width: ${BREAKPOINT.XL}) {
                  .HeroContent {
                    padding-bottom: 80px;
                  }
                }
                a {
                  max-width: none;
                }
              `}
              title={PageData.content.hero.title}
              text={
                <>
                  When the unexpected happens and you're having difficulty managing
                  your Latitude repayments,{' '}
                  <strong>Hardship Care is here to help.</strong>
                </>
              }
            />
          </>
        )}


        <div className="d-none d-lg-block" css="position: relative; z-index: 11;">
          <StickyNavigationBranded
            items={PageData.nav}
            offsetElem="[data-sticky-navigation-offset]"
            ctaText="Apply for Hardship Care"
            ctaHref={hardshipApplicationFormUrl}
            trackId="sticky-hardship-application"
            css={`
              background-color: #fff;
              .sticky-navigation__cta {
                background-color: ${COLOR.BLUE} !important;
                border: 0 !important;
                &:hover {
                  background-color: ${COLOR.BLUE_DARK} !important;
                }
              }
          `}
          {...state?.inPageNavData?.[0]}
        />
      </div>

        <IntroSection />
        <HorizontalRule />
        <ApplyingForHardshipCareSection />
        <HorizontalRule />
        <InsuranceSection />
        <HorizontalRule />
        <UsefulInformationSection />
        <ContactSection />
      </main>
    </Layout>
  )
};

const IntroSection = () => (
  <Section
    id="what-is-hardship"
    align={ALIGN.LEFT}
    css={`
      padding: 72px 0 !important;
      background-color: ${COLOR.GREY6};
    `}
  >
    <Box
      backgroundColor={COLOR.GREY6}
      css={`
        max-width: 730px;
        margin: 0 auto;
        padding: 0 32px;
        text-align: left;
      `}
    >
      <Heading4
        align={ALIGN.CENTER}
        color={COLOR.BLACK}
        css={`
          margin: 0 0 24px;
        `}
      >
        What is financial hardship?
      </Heading4>
      <Text>
        Financial hardship is when you want to make your repayments on a
        Latitude credit card, <a href="/personal-loan/">personal loan</a> or car
        loan, but can&rsquo;t.
      </Text>
      <Text>
        <p>
          If you’re struggling with your repayments, we encourage you to reach
          out to us for support as early as you can so we can work together to
          find a solution that’s right for you.
        </p>
        <p>
          Anyone can experience financial hardship at any time. There are many
          reasons why you may find yourself struggling with your repayments.
          These can include, but are not limited to:
        </p>
      </Text>
      <List css="margin: 20px;">
        <ListItem>rising cost of living</ListItem>
        <ListItem>unexpected or necessary bills </ListItem>
        <ListItem>injury or illness</ListItem>
        <ListItem>unemployment or a reduction in income</ListItem>
        <ListItem>relationship breakdown</ListItem>
        <ListItem>domestic violence</ListItem>
        <ListItem>financial abuse</ListItem>
        <ListItem>natural disasters</ListItem>
        <ListItem>gambling</ListItem>
      </List>
      <Box
        css={`
          padding: 30% 0;
          position: relative;
          bottom: 12px;
          width: 100%;
          overflow: hidden;
        `}
      >
        <iframe
          css={`
            bottom: 30px;
            border: 0;
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            width: 100%;
            height: 100%;
          `}
          src={vimeoVideoLink}
          frameborder="0"
          allowfullscreen
        ></iframe>
      </Box>
      <Text>
        <strong>
          If you're experiencing financial hardship, our Hardship Care team may
          be able to help you.
        </strong>
      </Text>
    </Box>
  </Section>
);

const ContactSection = () => (
  <AnalyticsLocationProvider location={hardshipHeading}>
    <Box.Section
      id="contact"
      backgroundColor={COLOR.BLUE_BABY}
      css={`
        h5 {
          font-size: 18px;
          margin-bottom: 8px;
          line-height: 18px;
          height: auto;
        }
      `}
    >
      <FeaturesSlider
        data={[
          {
            icon: 'icon-application',
            title: 'Online',
            text:
              "You should allow about 20 minutes to complete your Hardship Care application. It's a good idea to have all the relevant information you'll need ready, before you start.",
            jsx: (
              <Link
                button="secondary"
                target="_blank"
                rel="noopener noreferrer"
                href={hardshipApplicationFormUrl}
                trackId="hardship--online--apply-for-hardship-care"
                css={`
                  margin: 8px auto 5px;
                  max-width: 220px;
                `}
              >
                Apply for Hardship Care
              </Link>
            )
          },
          {
            icon: 'icon-call',
            title: 'Phone',
            jsx: (
              <Box css="text-align: center;">
                <span data-nosnippet>
                  <Tel no={hardshipPhoneNumber} /> <br />
                  Mon to Fri, 8:30am - 5pm (AEST)
                </span>
              </Box>
            )
          },
          {
            icon: 'icon-mail',
            title: 'Mail',
            text:
              '<strong>Latitude Hardship Care</strong><br/>GPO Box 940G<br/>Melbourne VIC 8060'
          }
        ]}
        heading={hardshipHeading}
        subheading="The best and most convenient way for you to contact us is online, using our Hardship Care application form. If that doesn't suit, get in touch with us via phone or mail."
      />
    </Box.Section>
  </AnalyticsLocationProvider>
);

const ApplyingForHardshipCareSection = () => (
  <Section
    id="applying-for-hardship-care"
    align={ALIGN.LEFT}
    css={`
      padding: 72px 0 !important;
      background-color: ${COLOR.GREY6};
    `}
  >
    <Box
      backgroundColor={COLOR.GREY6}
      css={`
        max-width: 730px;
        margin: 0 auto;
        padding: 0;
        text-align: left;
      `}
    >
      <Heading4
        align={ALIGN.CENTER}
        color={COLOR.BLACK}
        css={`
          margin: 0 0 24px;
        `}
      >
        Applying for Hardship Care
      </Heading4>
      <Text>
        Our Hardship Care team has helped many customers just like you who are
        having difficulty keeping up with their Latitude repayments, so
        don&rsquo;t be put off. We&rsquo;ll either come up with a manageable way
        for you to handle your repayments, or we&rsquo;ll direct you to external
        support services who may be able to assist.
        <br />
        <br />
        The first thing you need to do is fill out our{' '}
        <strong>Hardship Care online application</strong>.
      </Text>
      <Heading6
        color={COLOR.BLACK}
        css={`
          margin: 40px 0 16px;
        `}
      >
        What happens next?
      </Heading6>
      <List css="margin: 20px;">
        <ListItem>
          Once we&rsquo;ve received your application, we&rsquo;ll let you know
          if we need more information, otherwise we'll get back to you within 21
          calendar days with an outcome.
        </ListItem>
        <ListItem>
          We&rsquo;ll create a plan to get you back on track or we&rsquo;ll
          provide information about other options you can consider.
        </ListItem>
      </List>
      <AnalyticsLocationProvider location="Applying for Hardship Care">
        <div
          className="row justify-content-center"
          css={`
            padding-top: 30px;
          `}
        >
          <Link
            href="https://latitudefs.zendesk.com/hc/en-au/requests/new?ticket_form_id=360000137818"
            target="_blank"
            rel="noopener noreferrer"
            trackId="apply-for-hardship-care"
            button="secondary"
          >
            Apply for Hardship Care
          </Link>
        </div>
      </AnalyticsLocationProvider>
    </Box>
  </Section>
);

const InsuranceSection = () => (
  <Section
    id="insurance"
    align={ALIGN.LEFT}
    css={`
      padding: 72px 0 !important;
      background-color: ${COLOR.GREY6};

      .section__content > div > div {
        > div:last-child {
          max-width: 400px;
          padding-left: 32px;
          padding-right: 32px;
          margin-left: auto;
          margin-right: auto;
          > * {
            text-align: left;
          }
        }
      }

      @media (min-width: ${BREAKPOINT.LG}) {
        img {
          width: 308px;
          height: 273px;
        }

        .section__content > div > div {
          align-items: center;
          transform: translateX(-85px);

          > div:first-child {
            width: 308px;
            height: 273px;
          }

          > div:last-child {
            min-width: 520px;
            padding-top: 0;
          }
        }
      }
    `}
  >
    <Box
      css={`
        max-width: 730px;
        margin: 0 auto;
      `}
    >
      <Promo
        description={
          <>
            <Heading5 css="margin-bottom: 16px;" color={COLOR.BLACK}>
              {hardshipInsuranceHeading}
            </Heading5>
            <AnalyticsLocationProvider location={hardshipInsuranceHeading}>
              <Text>
                You can check if you have insurance for your Latitude credit
                card on your statement. Or for personal loans, check your loan
                contract.
                <br />
                <br />
                If you have consumer credit insurance, depending on your policy
                you may be able to claim if the insured is unable to work due to
                sickness, injury or job loss. It is a good idea to speak with
                the Hallmark Insurance team to see if you're able to make a
                claim.
              </Text>
              <Heading6
                color={COLOR.BLACK}
                css={`
                  margin: 40px 0 16px;
                `}
              >
                You can still apply for Hardship Care while you make your
                insurance claim if you wish.
              </Heading6>
              <List css="margin-bottom: 20px;">
                <ListItem>
                  <span data-nosnippet>
                    Contact Hallmark Insurance on <Tel no="1800 800 230" />
                  </span>
                  {' or'}
                </ListItem>
                <ListItem>
                  Email{' '}
                  <Link
                    href="mailto:customerservice@hallmarkinsurance.com.au"
                    trackId="hallmark-email-customerservice"
                  >
                    customerservice@hallmarkinsurance.com.au
                  </Link>
                  {' or'}
                </ListItem>
                <ListItem>
                  Check website{' '}
                  <Link
                    href="https://www.hallmarkinsurance.com.au/contact"
                    trackId="hallmark-whotocontact"
                  >
                    https://www.hallmarkinsurance.com.au/contact
                  </Link>
                </ListItem>
              </List>
            </AnalyticsLocationProvider>
          </>
        }
        frameImage={<img src={insuranceSquare} alt="Umbrella" />}
        frameAlignment="left"
        frameBackgroundColor="#F2F2FD"
        frameBorderColor="#E5E4FB"
      />
    </Box>
  </Section>
);

const UsefulInformationSection = () => (
  <AnalyticsLocationProvider location={PageData.content.usefulInfo.title}>
    <Box
      id="useful-info"
      css={`
        padding: 72px 0 !important;
        h5 {
          color: ${COLOR.BLACK};
          font-size: 20px;
        }
        h6 {
          font-size: 16px;
          margin: 16px 0 8px;
        }
        h6 + p {
          margin-top: 0;
        }
      `}
    >
      <ManageYourLoan
        heading={PageData.content.usefulInfo.title}
        description={
          <>
            We want you to know that you're not alone when going through
            financial hardship. We're here to help and our customer{' '}
            <a
              href="https://latitudefs.zendesk.com/hc/en-au/"
              target="_blank"
              rel="noreferrer noopener"
              data-trackid="hardship--help-centre"
            >
              Help Centre
            </a>{' '}
            has some articles on Hardship Care that may assist further. There
            are also a number of other resources you can turn to for support and
            guidance.
          </>
        }
      >
        <Accordion
          titleBgColor="grey-light"
          items={PageData.content.usefulInfo.accordionData.map(
            accordionDataItem => ({
              id: accordionDataItem.id,
              title: accordionDataItem.title,
              content: (
                <Box padding={PADDING.P16}>
                  {ReactHtmlParser(accordionDataItem.data)}
                </Box>
              )
            })
          )}
        />
      </ManageYourLoan>
    </Box>
  </AnalyticsLocationProvider>
);

export default Hardship;
