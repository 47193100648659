/* eslint-disable import/no-unresolved */
import React from 'react';
import Helmet from 'react-helmet';
import styled from 'styled-components';
import { Heading4, Heading5, Heading6 } from '@latitude/heading';
import {
  BREAKPOINT,
  COLOR,
  ALIGN,
  FONT_SIZE,
  MARGIN,
  PADDING
} from '@latitude/core/utils/constants';
import ReactHtmlParser from 'react-html-parser';
import { Text } from '@latitude/text';
import Layout from '../../components/layout';
import HeroBranded from '../../components/HeroBranded/HeroBranded';
import { PromoSection } from '../../components/Promo/Promo';
import { CardGroup, CardGroupItem } from '../../components/CardGroup/CardGroup';
import { Link } from '@latitude/link';
import SvgInline from '../../components/SvgInline/SvgInline';
import { Chevron } from '@latitude/chevron';
import { Accordion } from '@latitude/accordion';
import heroImageTablet from '../../images/blue-accent-banner.webp';
import heroImageDesktop from '../../images/blue-accent-banner.webp';
import mobileHeroImage from '../../images/blue-accent-banner-mobile.webp';
import PageData from './id-information.json';
import { Box } from '@/components/Box/Box';

const columns = 2;

const title = 'Latitude identity information';

const ItemLink = styled(Link)`
  /* height: 100%; */
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: ${COLOR.WHITE};
  transition: all 150ms ease-in-out;

  &:hover {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.49);
    transition: all 150ms ease-in-out;
  }
`;

const ItemTitle = styled(Heading6)`
  margin-bottom: 0;
  flex-grow: 1;
  color: ${COLOR.BLACK};
  @media (min-width: ${BREAKPOINT.XL}) {
    font-size: 15px;
  }
`;

const ItemIcon = styled(SvgInline)`
  width: 40px;
  min-width: 40px;
  margin-right: 13px;
`;

const ItemChevron = styled(Chevron)`
  /* svg .chevron-line.icon-line--style {
    stroke: ${COLOR.BLUE};
  } */
`;

const StyledHeroBranded = styled(HeroBranded)`
  background-image: url(${mobileHeroImage});
  background-size: cover;
  background-position: center center;
  background-color: #cfeafe;
  background-repeat: no-repeat;
  height: 100%;
  .HeroContent {
    background-color: #cfeafe;
    width: 100%;
  }
  @media (max-width: ${BREAKPOINT.MD}) {
    [class^='HeroBranded__HeroImage'] {
      display: none;
    }

    .HeroContent {
      background-color: transparent;
      height: 320px;
      padding: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-top: 60px;

      > div {
        margin: 0 0 0 21px;
      }
    }
  }

  @media (min-width: ${BREAKPOINT.MD}) {
    background-image: url(${heroImageTablet});
    background-size: cover;
    height: 300px;
    .HeroContent {
      background-color: transparent;
    }
  }
  @media (min-width: ${BREAKPOINT.LG}) {
    height: 320px;
  }
  @media (min-width: ${BREAKPOINT.XL}) {
    background-image: url(${heroImageDesktop});
  }
`;

const StyledPromoSection = styled(PromoSection)`
  background-color: ${COLOR.GREY6};
`;

const StyledText = styled(Text)`
  margin-left: auto;
  margin-right: auto;
  text-align: left;
  background-color: white;
  @media (max-width: ${BREAKPOINT.MD}) {
    text-align: center;
  }
  @media (min-width: ${BREAKPOINT.MD}) {
    max-width: 730px;
  }
`;

const StyledHeading5 = styled(Heading5)`
  margin-left: auto;
  margin-right: auto;
  background-color: white;
  @media (max-width: ${BREAKPOINT.MD}) {
    text-align: center;
  }
  @media (min-width: ${BREAKPOINT.MD}) {
    max-width: 730px;
  }
`;

const StyledHeading4 = styled(Heading4)`
  margin-left: auto;
  margin-right: auto;
  background-color: white;
  @media (max-width: ${BREAKPOINT.MD}) {
    text-align: center;
  }
  @media (min-width: ${BREAKPOINT.MD}) {
    max-width: 730px;
  }
`;

const Seperator = styled.div`
  display: none;
  @media (min-width: ${BREAKPOINT.LG}) {
    display: block;
    width: 16px;
    height: 2px;
    background-color: ${COLOR.BLUE};
    margin: 13px 0 20px;
  }
`;

const StyledCardGroup = styled(CardGroup)`
  max-width: 100%;
  margin: 0 auto;

  @media (min-width: ${BREAKPOINT.SM}) {
    max-width: 80%;
  }
  @media (min-width: ${BREAKPOINT.MD}) {
    max-width: 50%;
    padding: 0 10%;
  }
  @media (min-width: ${BREAKPOINT.LG}) {
    max-width: 100%;
  }
`;

const StyledItem = styled.div`
  display: flex;
  padding: ${PADDING.P24};
  background-color: ${COLOR.WHITE};
  border-radius: 4px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.25);
  width: 100%;

  @media (min-width: ${BREAKPOINT.LG}) {
    display: block;
    height: 100%;
  }
`;

const breadcrumbData = [
  {
    name: 'Home',
    href: '/'
  },
  {
    name: 'Latitude Cyber Response',
    href: '/latitude-id-information/'
  },
  {
    name: 'Identity Images'
  }
];

const LatitudeCyberIncident = props => {
  return (
    <Layout location={props.location}>
      <main className="navigation-spacer">
        <Helmet>
          <link
            rel="canonical"
            href="https://www.latitudefinancial.com.au/latitude-cyber-incident/"
          />
          <title> Latitude Cyber Incident | ID image compromised</title>
          <meta
            name="description"
            content="Latitude Cyber Incident, Latitude Financial Services"
          />
        </Helmet>

        <StyledHeroBranded
          title="ID image compromised"
          text="Information, updates and advice for those impacted."
          breadcrumbs={breadcrumbData}
        />

        <StyledPromoSection
          css={`
            background-color: white;
          `}
        >
          <StyledText fontSize={FONT_SIZE.LARGE} marginBottom={MARGIN.M24}>
            The following guidance applies to those who received a direct
            notification from Latitude advising them that an image of their
            identity document was compromised.
          </StyledText>

          <StyledHeading5 color={COLOR.BLACK} align={ALIGN.CENTER}>
            Passports
          </StyledHeading5>

          <Accordion
            titleBgColor="grey-light"
            items={PageData.content.imagePassports.accordionData.map(
              accordionDataItem => ({
                id: accordionDataItem.id,
                title: accordionDataItem.title,
                content: (
                  <Box padding={PADDING.P16}>
                    {ReactHtmlParser(accordionDataItem.data)}
                  </Box>
                )
              })
            )}
            css={`
              margin-left: auto;
              margin-right: auto;
              @media (max-width: ${BREAKPOINT.MD}) {
                text-align: center;
              }
              @media (min-width: ${BREAKPOINT.MD}) {
                max-width: 730px;
              }
            `}
          />

          <Heading5
            color={COLOR.BLACK}
            align={ALIGN.CENTER}
            css={`
              margin-left: auto;
              margin-right: auto;
              margin-top: 20px;
              background-color: white;
              @media (max-width: ${BREAKPOINT.MD}) {
                text-align: center;
              }
              @media (min-width: ${BREAKPOINT.MD}) {
                max-width: 730px;
              }
            `}
          >
            Driver Licences &mdash; Images Compromised
          </Heading5>
          <Text
            fontSize={FONT_SIZE.LARGE}
            marginBottom={MARGIN.M24}
            css={`
              margin-left: auto;
              margin-right: auto;
              text-align: left;
              color: #c31b6c;
              background-color: white;
              @media (max-width: ${BREAKPOINT.MD}) {
                text-align: center;
              }
              @media (min-width: ${BREAKPOINT.MD}) {
                max-width: 730px;
              }
            `}
          >
            <strong>IMPORTANT NOTE</strong>: If we have notified you that an
            &quot;image&quot; of your driver licence was compromised, it is
            highly recommended that you take action.
          </Text>
          <StyledText fontSize={FONT_SIZE.LARGE} marginBottom={MARGIN.M24}>
            The image of your driver licence that was collected as part of your
            application for credit may no longer be the licence you currently
            have (e.g. you may have replaced it since you submitted your
            application).
            <br />
            <br />
            Please see the guidance below for the relevant Australian state or
            territory or New Zealand as applicable.
          </StyledText>

          <Accordion
            titleBgColor="grey-light"
            items={PageData.content.imageDriverLicences.accordionData.map(
              accordionDataItem => ({
                id: accordionDataItem.id,
                title: accordionDataItem.title,
                content: (
                  <Box padding={PADDING.P16}>
                    {ReactHtmlParser(accordionDataItem.data)}
                  </Box>
                )
              })
            )}
            css={`
              margin-left: auto;
              margin-right: auto;
              @media (max-width: ${BREAKPOINT.MD}) {
                text-align: center;
              }
              @media (min-width: ${BREAKPOINT.MD}) {
                max-width: 730px;
              }
            `}
          />

          <Heading5
            color={COLOR.BLACK}
            align={ALIGN.CENTER}
            css={`
              margin-left: auto;
              margin-right: auto;
              margin-top: 20px;
              background-color: white;
              @media (max-width: ${BREAKPOINT.MD}) {
                text-align: center;
              }
              @media (min-width: ${BREAKPOINT.MD}) {
                max-width: 730px;
              }
            `}
          >
            Other identity documents
          </Heading5>
          <StyledText
            fontSize={FONT_SIZE.LARGE}
            marginBottom={MARGIN.M24}
            css={`
              margin-left: auto;
              margin-right: auto;
              margin-bottom: 0;
              text-align: left;
              background-color: white;
              @media (max-width: ${BREAKPOINT.MD}) {
                text-align: center;
              }
              @media (min-width: ${BREAKPOINT.MD}) {
                max-width: 730px;
              }
            `}
          >
            If we notified you that details of other identity documents were
            compromised, we recommend that you contact the issuing agency for
            advice and recommendations on the appropriate steps to follow.
            <br />
            <br />
            This may include any proof of age card, worker permit or other
            licence or type of identity document you provided to us as part of
            your application.
          </StyledText>
        </StyledPromoSection>
        <StyledPromoSection
          css={`
            background-color: #f7f7f7;
            .section__content > div {
              display: flex;
            }
          `}
        >
          <Heading4
            id="reimbursement-claims"
            color={COLOR.BLACK}
            align={ALIGN.CENTER}
            css={`
              margin-left: auto;
              margin-right: auto;
              @media (min-width: ${BREAKPOINT.MD}) {
                max-width: 730px;
              }
            `}
          >
            Reimburse replaced ID documents
          </Heading4>
          <StyledText
            fontSize={FONT_SIZE.LARGE}
            marginBottom={MARGIN.M24}
            align={ALIGN.CENTER}
            css={`
              background-color: transparent;
            `}
          >
            As part of our response to the cyber-attack, Latitude will reimburse
            customers needing to replace their stolen ID document.
          </StyledText>
          <CardGroup
            css={`
              max-width: 100%;
              margin: 0 auto;

              a {
                padding: 15px 12px;
              }

              @media (min-width: ${BREAKPOINT.SM}) {
                max-width: 80%;
              }
              @media (min-width: ${BREAKPOINT.MD}) {
                padding: 0 10%;
                max-width: 730px;
              }
            `}
          >
            <CardGroupItem key={1} col={1}>
              <ItemLink
                noStyle
                href={
                  'https://latitudefs.zendesk.com/hc/en-au/requests/new?ticket_form_id=14268855491601'
                }
                trackId="make-a-claim-au"
                trackEventData={{
                  label: 'Make a claim (AU)',
                  location: 'make-a-claim'
                }}
              >
                <ItemIcon name="external-link" />
                <ItemTitle>Make a claim (AU)</ItemTitle>
                <ItemChevron direction="right" color={COLOR.BLUE} />
              </ItemLink>
            </CardGroupItem>
            <CardGroupItem key={2} col={1}>
              <ItemLink
                noStyle
                href="https://gemfinance.zendesk.com/hc/en-nz/requests/new?ticket_form_id=14468911403921"
                trackId="make-a-claim-nz"
                trackEventData={{
                  label: 'Make a claim (NZ)',
                  location: 'make-a-claim'
                }}
              >
                <ItemIcon name="external-link" />
                <ItemTitle>Make a claim (NZ)</ItemTitle>
                <ItemChevron direction="right" color={COLOR.BLUE} />
              </ItemLink>
            </CardGroupItem>
          </CardGroup>
          <StyledText
            fontSize={FONT_SIZE.LARGE}
            marginBottom={MARGIN.M24}
            marginTop={MARGIN.M24}
            text-align={ALIGN.CENTER}
            css={`
              background-color: transparent;
            `}
          >
            To complete this form, you will need to provide your reference
            number, BSB/Bank account number (for AU) or Branch code/bank
            code/account number and suffix (for NZ).
          </StyledText>
        </StyledPromoSection>
      </main>
    </Layout>
  );
};

export default LatitudeCyberIncident;
