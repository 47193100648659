/* eslint-disable import/no-dynamic-require */
import React from 'react';
import classnames from 'classnames';
import ReactHtmlParser from 'react-html-parser';
import { Link } from '@latitude/link';
import { BUTTON_STYLE } from '@latitude/core/utils/constants';
import './_card-media.scss';

const component = 'card-media';

const CardMedia = props => {
  const {
    title,
    subtitle,
    description,
    blockOne,
    blockOneMetaText,
    blockTwo,
    blockTwoMetaText,
    cardMediaBackground,
    cardMediaBackgroundEmotor,
    image,
    cardAwardMedia,
    isArticle,
    isVideo,
    isDocument,
    isProfile,
    hasMininumHeight,
    href,
    trackId,
    embedSrc,
    data,
    listItems,
    listLinks,
    buttons,
    className,
    isBranded
  } = props;

  const Title = isArticle ? 'p' : 'h2';

  const renderComponentVariant = () => (
    <div
      className={classnames(
        `${component}`,
        hasMininumHeight && `${component}--tall`,
        className
      )}
    >
      {/* Media Type: Image */}
      {image && (
        <Link
          noStyle
          className={classnames(
            `${component}__image`,
            `${component}__background`,
            cardMediaBackground && `${component}__background--cc`,
            cardMediaBackgroundEmotor && `${component}__background--emotor`
          )}
          href={href}
          trackId={trackId}
          trackEventData={{
            label: title
          }}
        >
          {image}
        </Link>
      )}
      {cardAwardMedia && (
        <div className={`${component}__award_media`}>{cardAwardMedia}</div>
      )}
      {/* Media Type: Video */}
      {embedSrc && (
        <div className={`${component}__video`}>
          <iframe
            className={`${component}__video-embed`}
            title={title}
            src={`${embedSrc}?title=0&byline=0&portrait=0`}
            frameBorder="0"
            allowFullScreen
          />
        </div>
      )}
      <div className={`${component}__title-container`}>
        <Title
          className={classnames(
            `${component}__title`,
            isArticle && `${component}__title--small`
          )}
        >
          {title}
        </Title>
        {subtitle && <h3 className={`${component}__subtitle`}>{subtitle}</h3>}
      </div>
      <div
        className={classnames(
          `${component}__content`,
          isArticle || isVideo || isDocument ? `p-0` : null
        )}
      >
        {/* Description */}
        {description && (
          <p className={classnames(`${component}__desc`, isProfile && `mb-0`)}>
            {ReactHtmlParser(description)}
          </p>
        )}
        {/* Fees */}
        {blockOne && (
          <div className={`${component}-fees`}>
            <div className={`${component}-fees__item ${component}-fees__unit`}>
              {blockOne}
            </div>
            <div
              className={`${component}-fees__item ${component}-fees__meta pr-3`}
            >
              {blockOneMetaText}
            </div>
            <div
              className={`${component}-fees__item ${component}-fees__unit pl-3`}
            >
              {blockTwo}
            </div>
            <div className={`${component}-fees__item ${component}-fees__meta`}>
              {blockTwoMetaText}
            </div>
          </div>
        )}
        {/* List items */}
        {listItems && (
          <ul className={`${component}__list`}>
            {listItems.map((item, index) => (
              <li className={`${component}__list-item`} key={index}>
                {ReactHtmlParser(item)}
              </li>
            ))}
          </ul>
        )}
        {/* Link items */}
        {listLinks && (
          <ul className={`${component}__list`}>
            {listLinks.map((item, index) => (
              <li
                className={`${component}__list-item ${component}__list-item--no-style mb-3`}
                key={index}
              >
                <Link
                  className={`${component}-link-item`}
                  href={item.href}
                  trackId={item.trackId}
                >
                  {item.title}
                </Link>
              </li>
            ))}
          </ul>
        )}
      </div>
      {buttons && (
        <div className={classnames(`${component}__buttons`)}>{buttons}</div>
      )}
    </div>
  );

  const renderGridLayout = () => (
    <div className="row justify-content-center lfs-grid-layout">
      {/* Media Type: Data */}

      {data.map((card, index) => {
        /*
         * Strip redundant tags and characters for Tealium event_location
         */
        const cardTitle = card.title.replace(/(°|<([^]+)>)/gi, '');

        return (
          <div
            key={index}
            className={`col-12 col-lg-6 lfs-branded-card-grid ${
              isBranded ? `col-xl-6 ${component}--staggered` : 'col-xl-4'
            }`}
          >
            <div
              className={classnames(
                'lfs-branded-card',
                `${component}`,
                hasMininumHeight && `${component}--tall`,
                card.horizontalCard && `${component}--horizontal`,
                className,
                `mb-5`
              )}
            >
              <Link
                noStyle
                className={classnames(
                  `${component}__image`,
                  `${component}__background`,
                  cardMediaBackground && `${component}__background--cc`,
                  cardMediaBackgroundEmotor &&
                    `${component}__background--emotor`,
                  card.horizontalCard && `${component}__image--horizontal`,
                  card.titleLongText && `${component}__title--tall`
                )}
                href={card.secondaryButton}
                trackId={card.imgTrackID}
                trackEventData={{
                  location: cardTitle,
                  label: 'card-image'
                }}
                trackProductId={card.productid}
              >
                <img
                  className={classnames(
                    card.imgWide && `${component}__image--wide`,
                    card.imgMaxHeight && `${component}__image--tall`,
                    card.dualCard && `${component}__image--dual`
                  )}
                  src={require(`../../images/credit-cards/${card.img}`).default}
                  alt={card.title}
                />
              </Link>
              {card.cardAwardImage && (
                <div className={`${component}__award_media`}>
                  <img
                    src={
                      require(`../../images/credit-cards/${card.cardAwardImage}`)
                        .default
                    }
                    alt={card.cardAwardImageTitle}
                  />
                </div>
              )}
              <div className={`${component}__title-container`}>
                <h2 className={`${component}__title`}>
                  {ReactHtmlParser(card.title)}
                </h2>
                {card.subtitle && (
                  <h3 className={`${component}__subtitle`}>{card.subtitle}</h3>
                )}
              </div>

              <div className={`${component}__content`}>
                <p className={`${component}__desc`}>
                  {ReactHtmlParser(card.description)}
                </p>
                <div className={`${component}-fees`}>
                  {/* Annual fee */}
                  {card.annualFee && (
                    <React.Fragment>
                      <div
                        className={`${component}-fees__item ${component}-fees__unit`}
                      >
                        {card.annualFee}
                      </div>
                      <div
                        className={`${component}-fees__item ${component}-fees__meta pr-3`}
                      >
                        Annual
                        <br />
                        fee
                      </div>
                    </React.Fragment>
                  )}
                  {/* Monthly fee */}
                  {card.monthlyFee && (
                    <React.Fragment>
                      <div
                        className={`${component}-fees__item ${component}-fees__unit`}
                      >
                        {ReactHtmlParser(card.monthlyFee)}
                      </div>
                      <div
                        className={`${component}-fees__item ${component}-fees__meta pr-3`}
                      >
                        {card.monthlyFeeText}
                        {card.showAsterisk && <sup>*</sup>}
                      </div>
                    </React.Fragment>
                  )}
                  {/* Interest fee */}
                  {card.interestFee && (
                    <React.Fragment>
                      <div
                        className={`${component}-fees__item ${component}-fees__unit pl-3`}
                      >
                        {card.interestFee}
                      </div>
                      <div
                        className={`${component}-fees__item ${component}-fees__meta`}
                      >
                        p.a.
                      </div>
                    </React.Fragment>
                  )}
                  {/* Other benefits */}
                  {card.benefit && (
                    <React.Fragment>
                      <div
                        className={`${component}-fees__item ${component}-fees__unit pl-3`}
                      >
                        {card.benefit}
                      </div>
                      <div
                        className={`${component}-fees__item ${component}-fees__meta`}
                      >
                        {ReactHtmlParser(card.benefitDesc)}
                      </div>
                    </React.Fragment>
                  )}
                  {/* Additional benefits */}
                  {card.addBenefit && (
                    <React.Fragment>
                      <div
                        className={`${component}-fees__item ${component}-fees__unit pl-3`}
                      >
                        {card.addBenefit}
                      </div>
                      <div
                        className={`${component}-fees__item ${component}-fees__meta`}
                      >
                        {ReactHtmlParser(card.addBenefitDesc)}
                      </div>
                    </React.Fragment>
                  )}
                </div>
                {/* List items */}
                {card.listItems && (
                  <ul className={`${component}__list`}>
                    {// eslint-disable-next-line no-shadow
                    card.listItems.map((item, index) => (
                      <li className={`${component}__list-item`} key={index}>
                        {ReactHtmlParser(item)}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
              <div className="card-media__buttons here">
                {card.customButton ? (
                  <Link
                    href={card.customButtonUrl}
                    trackId={card.secondaryButtonTrackID}
                    trackProductId={card.productid}
                    button={BUTTON_STYLE.TERTIARY}
                    trackEventData={{
                      location: cardTitle
                    }}
                  >
                    Find out more at David Jones
                  </Link>
                ) : (
                  <>
                    {card.secondaryButton && (
                      <Link
                        href={card.secondaryButton}
                        trackId={card.secondaryButtonTrackID}
                        trackProductId={card.productid}
                        button={BUTTON_STYLE.TERTIARY}
                        trackEventData={{
                          location: cardTitle
                        }}
                      >
                        Find out more
                      </Link>
                    )}
                    {card.primaryButton && (
                      <Link
                        href={card.primaryButton}
                        trackId={card.primaryButtonTrackID}
                        button={BUTTON_STYLE.PRIMARY}
                        trackProductId={card.productid}
                        trackEventData={{
                          category: 'cta',
                          action: 'application-link',
                          location: cardTitle
                        }}
                      >
                        Apply Now
                      </Link>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );

  return (
    <React.Fragment>
      {!data ? renderComponentVariant() : renderGridLayout()}
    </React.Fragment>
  );
};

export default CardMedia;
