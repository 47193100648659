/* eslint-disable import/no-dynamic-require */
import React from 'react';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';
import Breadcrumb from './Breadcrumb';

export default function Template({
  data // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data; // data.markdownRemark holds our post data
  const { frontmatter, html } = markdownRemark;
  const { id, title, author, description, thumbnail, heroBg, meta_title,
    meta_description, } = frontmatter;

  const ldbUrl = 'https://www.latitudefinancial.com.au/life-done-better';
  const baseUrl = `${ldbUrl}/amp`;
  const filepath = `${id}.html`;
  const image = `${ldbUrl}/${thumbnail}`;
  const url = encodeURI(`${baseUrl}/${filepath}`);
  const encodedTitle = encodeURI(title);

  const social = [
    {
      name: 'twitter',
      icon: '/assets/icons/icons-sprite.svg#twitter',
      url: `https://twitter.com/intent/tweet?url=${url}`,
      target: '_blank'
    },
    {
      name: 'linkedin',
      icon: '/assets/icons/icons-sprite.svg#linkedin',
      url: `https://www.linkedin.com/shareArticle?mini=true&url=${url}&title=${encodedTitle}&source=${url}`,
      target: '_blank'
    },
    {
      name: 'facebook',
      icon: '/assets/icons/icons-sprite.svg#facebook',
      url: `http://www.facebook.com/sharer.php?u=${url}`,
      target: '_blank'
    },
    {
      name: 'mail',
      icon: '/assets/icons/icons-sprite.svg#mail',
      url: `mailto:?subject=${encodedTitle}&body=${url}`
    }
    // {
    //   name: 'print',
    //   icon: '/assets/icons/icons-sprite.svg#print',
    //   url: 'javascript:window.print()'
    // }
  ];

  return (
    <React.Fragment>
      <Helmet title={meta_title ? meta_title : `${title} | Latitude Financial`}>
        {meta_description && (
          <meta name="description" content={meta_description} />
        )}

        <link rel="canonical" href={url} />

        <meta property="og:type" content="article" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={image} />
        <meta property="og:url" content={url} />
      </Helmet>

      <div className="nav">
        <a href="/" className="nav__logo">
          <amp-img
            src="/media/logo--header.svg"
            layout="fixed"
            width="35"
            height="35"
            alt="Latitude Financial Logo"
          />
        </a>
      </div>
      <div className="max-width-4 mx-auto pt3 pb4 px3">
        <div className="clearfix">
          <Breadcrumb title={title} style={{ display: 'none' }} />
          <h1 className="heading mt2 mb2 center">{title}</h1>
          {author && <h2 className="center">By {author}</h2>}

          <ul className="social-links mt3 center">
            {social.map(item => (
              <li key={item.url}>
                <a href={item.url} target={item.target}>
                  <svg
                    className={`social-links__icon social-links__icon--${item.name}`}
                  >
                    <use xlinkHref={item.icon} />
                  </svg>
                </a>
              </li>
            ))}
          </ul>

          <amp-img
            layout="responsive"
            width="1920"
            height="490"
            src={require(`../../../static/life-done-better/images/${heroBg}`)}
          />

          {/* eslint-disable-next-line react/no-danger */}
          <div dangerouslySetInnerHTML={{ __html: html }} />
        </div>
      </div>
    </React.Fragment>
  );
}

export const pageQuery = graphql`
  query ArticleByPathAmp($originalPath: String!) {
    markdownRemark(frontmatter: { path: { eq: $originalPath } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        id
        title
        thumbnail
        author
        heroBg
      }
    }
  }
`;
