import React from 'react';
import styled from 'styled-components';

import Section from '@latitude/section';
import { Box } from '@latitude/box';
import { Heading3 } from '@latitude/heading';
import { BREAKPOINT, FONT_FAMILY } from '@latitude/core/utils/constants';
import BrandedCard from '@/components/CardMedia/BrandedCard';
import { SectionSubtitle, SectionTitle } from './_sectionHeading';

import BRANDED_CARD_DATA from './data/interest-free-cards-alphabetical.json';

function InterestFreeCardsOnlyComparisonSection({ id }) {
  return (
    <Section id={id} css={{ padding: '64px 0' }}>
      <SectionTitle>
        Participating Latitude credit cards with Interest Free payment plans
      </SectionTitle>
      <SectionSubtitle>
        Get the lowdown on the finer details before you apply.
      </SectionSubtitle>

      <br />
      <CardContainer isResponsive>
        <BrandedCard
          isBranded
          hasMininumHeight
          cardMediaBackground
          data={BRANDED_CARD_DATA.cards}
        />
      </CardContainer>
    </Section>
  );
}

const CardContainer = styled(Box)`
  position: relative;
  margin: 20pt auto 0 auto;
  @media (min-width: 992px) {
    .card-media--staggered:nth-child(2) {
      margin-top: 0;
    }
  }
`;

const Container = styled(Box)`
  width: 100%;
  margin: 0 auto;
  padding-top: 40px;
  padding-bottom: 40px;
  @media (min-width: ${BREAKPOINT.LG}) {
    padding: 0 0 43px;
  }
`;

const Title2 = styled(Heading3)`
  color: #000;
  font-family: ${FONT_FAMILY.TITLE};
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
`;

const OtherCards = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 30pt;
  justify-content: center;
  padding: 5pt;
`;

const OtherCard = styled.div`
  padding: 5pt;
  width: 120px;
`;

const CardImage = styled.img`
  width: 100%;
  height: auto;
  margin-bottom: 8px;
`;

const CardTitle = styled.div`
  text-align: center;
  font-family: ${FONT_FAMILY.TITLE};
  font-weight: 600;
  padding-left: 2px;
  line-height: 1.2;
`;

export default InterestFreeCardsOnlyComparisonSection;
