import React, { useContext } from 'react';
import Helmet from 'react-helmet';
import styled from 'styled-components';
import { Lframe } from '@latitude/lframe';
import { Hero } from '@latitude/hero';
import { HorizontalRule } from '@latitude/horizontal-rule';
import { Box } from '@latitude/box';
import { Heading5 } from '@latitude/heading';
import { Text } from '@latitude/text';
import { COLOR, BREAKPOINT, MARGIN } from '@latitude/core/utils/constants';
import Layout from '../../components/layout';
import HeroBanner from '@/components/lab-components/HeroBanner';
import { PageContext } from '@/context/PageContext';

const SupportSocialPage = props => {
  /** Contentful Data */
  const contentfulPageData = useContext(PageContext);
  const state = contentfulPageData?.updatedBody || {};
  /** - END - */
  
  return (
    <Layout location={props.location}>
      <Main className="navigation-spacer">
        <Helmet>
          <link
            rel="canonical"
            href="https://www.latitudefinancial.com.au/support-social/"
          />
          <meta
            name="description"
            content="Latitude Financial Coronavirus Hardship support is available. Read all the info here, and lodge an online form, rather than calling and experience longer than usual wait times"
          />
          <title>
            Latitude Financial Self-Service Support | Latitude Financial
          </title>
        </Helmet>

        {state?.heroBannerData?.[0] ? (
          <HeroBanner {...state?.heroBannerData[0]} />
        ) : (
          <HeroContainer>
            <Lframe bgImage={require('./images/l-shape-social.png').default} />
            <Hero title="Latitude Financial Self-Service Support" text="" />
          </HeroContainer>
        )}

        <HorizontalRule />
        <Box.Section id="assistance" isBodyCopy backgroundColor={COLOR.GREY6}>
          <Text marginBottom={MARGIN.M24}>
            <br />
            Let&rsquo;s support you. We&rsquo;re doing our best to respond to
            customers as quickly as possible,
            <br />
            however currently our team&rsquo;s capacity is significantly impacted.
            We&rsquo;ve put together some handy links so you can begin to solve
            your enquiry.
          </Text>
          <Heading5 css="margin-bottom: 10px;" color={COLOR.BLACK}>
            Lost or Stolen Card
          </Heading5>
          <Text marginBottom={MARGIN.M32}>
            Complete this{' '}
            <a href="https://latitudefs.zendesk.com/hc/en-au/articles/360007143418">
              form
            </a>
            &nbsp;and we will get back to you with a replacement card.
          </Text>
          <Heading5 css="margin-bottom: 10px;" color={COLOR.BLACK}>
            Transaction Dispute/Fraud
          </Heading5>
          <Text marginBottom={MARGIN.M32}>
            Complete this{' '}
            <a href="https://latitudefs.zendesk.com/hc/en-au/articles/360007143458">
              form
            </a>
            &nbsp;and we will get back to you.
          </Text>
          <Heading5 css="margin-bottom: 10px;" color={COLOR.BLACK}>
            Reset Log in Details
          </Heading5>
          <Text marginBottom={MARGIN.M32}>
            Sorry you can&rsquo;t access your account. Please fill in this{' '}
            <a href="https://latitudefs.zendesk.com/hc/en-au/articles/360007147138">
              form
            </a>
            &nbsp;to reset.
          </Text>
          <Heading5 css="margin-bottom: 10px;" color={COLOR.BLACK}>
            Activate Card
          </Heading5>
          <Text marginBottom={MARGIN.M32}>
            Let&rsquo;s get that new card working{' '}
            <a href="https://latitudefs.zendesk.com/hc/en-au/articles/360007143258">
              here
            </a>
            .
          </Text>
          <Heading5 css="margin-bottom: 10px;" color={COLOR.BLACK}>
            Letter Request
          </Heading5>
          <Text marginBottom={MARGIN.M32}>
            Request a Closure/Payout or Balance Letter{' '}
            <a href="https://latitudefs.zendesk.com/hc/en-au/requests/new?ticket_form_id=360000124417">
              here
            </a>
            .
          </Text>
          <Heading5 css="margin-bottom: 10px;" color={COLOR.BLACK}>
            Payment Allocation
          </Heading5>
          <Text marginBottom={MARGIN.M32}>
            Let&rsquo;s move interest free payments from one plan to another{' '}
            <a href="https://latitudefs.zendesk.com/hc/en-au/articles/360007143438">
              here
            </a>
            .
          </Text>
          <Heading5 css="margin-bottom: 10px;" color={COLOR.BLACK}>
            Direct Debit
          </Heading5>
          <Text marginBottom={MARGIN.M32}>
            Let&rsquo;s set up, change or cancel your direct debit{' '}
            <a href="https://latitudefs.zendesk.com/hc/en-au/requests/new?ticket_form_id=360000137778">
              here
            </a>
            .
          </Text>
          <Heading5 css="margin-bottom: 10px;" color={COLOR.BLACK}>
            Credit Card Closure
          </Heading5>
          <Text marginBottom={MARGIN.M32}>
            We&rsquo;re sorry to see you go. Cancel your account{' '}
            <a href="https://latitudefs.zendesk.com/hc/en-au/articles/360007143218">
              here
            </a>
            .
          </Text>
        </Box.Section>

        <HorizontalRule />
      </Main>

      <script
        id="ze-snippet"
        src="https://static.zdassets.com/ekr/snippet.js?key=34f4d43c-fb78-460c-bfa9-258bf81fcaca"
      />
    </Layout>
  )
};

const Main = styled.main`
  background-color: ${COLOR.GREY6};

  h4,
  p,
  .sticky-navigation__nav-item a .label {
    color: ${COLOR.BLACK};
  }
`;

const HeroContainer = styled.div`
  position: relative;
  z-index: 0;
  @media (min-width: ${BREAKPOINT.LG}) {
    .HeroContent {
      display: flex;
      align-items: center;
      min-height: 400px;
    }
  }
`;

export default SupportSocialPage;
