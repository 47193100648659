import * as React from 'react';
import styled from 'styled-components';
import Icon from '@/components/Icon/Icon';
import {
  ALIGN,
  COLOR,
  ICON_SIZE,
  JUSTIFY_CONTENT,
  MARGIN,
  PADDING
} from '../../utils/constants';
import { Box, Flex } from '../Box/Box';
import { Heading5 } from '../Heading/Heading';

const StyledCard = styled(Box)`
  flex: 1 1 auto;
  width: 100%;
  height: 100%;
  ${({ backgroundColor }) =>
    backgroundColor && `background-color: ${backgroundColor}`};
  ${({ minHeight }) => (minHeight ? `min-height: ${minHeight};` : '')};
`;

export const Card = ({ heading, icon, ...props }) => {
  return (
    <StyledCard {...props}>
      {icon && (
        <Flex justifyContent={JUSTIFY_CONTENT.CENTER} marginBottom={MARGIN.M16}>
          <Icon variant={icon} size={ICON_SIZE.LG} color={COLOR.BLUE} />
        </Flex>
      )}
      {heading && (
        <Heading5
          marginBottom={MARGIN.M16}
          align={ALIGN.CENTER}
          color={COLOR.BLUE}
        >
          {heading}
        </Heading5>
      )}
      <Box>{props.children}</Box>
    </StyledCard>
  );
};

Card.Box = styled(Card)`
  display: flex;
  flex-direction: column;
  background-color: ${({ backgroundColor }) =>
    backgroundColor ? backgroundColor : COLOR.GREY5};
  > h4,
  > h5 {
    margin: ${MARGIN.M0};
    padding: ${PADDING.P16};
    background-color: ${COLOR.BLUE};
    color: ${COLOR.WHITE};
  }
  > div {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    padding: ${PADDING.P24} ${PADDING.P32};
  }
`;

Card.List = styled(Card)`
  max-width: 360px;
`;
