/* eslint-disable import/no-unresolved */
import React from 'react';
import Helmet from 'react-helmet';
import styled from 'styled-components';
import { Heading4, Heading5, Heading6 } from '@latitude/heading';
import {
  BREAKPOINT,
  COLOR,
  ALIGN,
  FONT_SIZE,
  MARGIN,
  PADDING,
  BUTTON_STYLE
} from '@latitude/core/utils/constants';
import ReactHtmlParser from 'react-html-parser';
import { Text } from '@latitude/text';
import Layout from '../../components/layout';
import HeroBranded from '../../components/HeroBranded/HeroBranded';
import { PromoSection } from '../../components/Promo/Promo';
import { CardGroup, CardGroupItem } from '../../components/CardGroup/CardGroup';
import { Link } from '@latitude/link';
import SvgInline from '../../components/SvgInline/SvgInline';
import { Chevron } from '@latitude/chevron';
import heroImageTablet from '../../images/blue-accent-banner.webp';
import heroImageDesktop from '../../images/blue-accent-banner.webp';
import mobileHeroImage from '../../images/blue-accent-banner-mobile.webp';
import PageData from './id-information.json';
import { Box } from '@/components/Box/Box';

const columns = 2;

const title = 'Latitude identity information';

const ItemLink = styled(Link)`
  /* height: 100%; */
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: ${COLOR.WHITE};
  transition: all 150ms ease-in-out;

  &:hover {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.49);
    transition: all 150ms ease-in-out;
  }
`;

const ItemTitle = styled(Heading6)`
  margin-bottom: 0;
  flex-grow: 1;
  color: ${COLOR.BLACK};
  @media (min-width: ${BREAKPOINT.XL}) {
    font-size: 15px;
  }
`;

const ItemIcon = styled(SvgInline)`
  width: 40px;
  min-width: 40px;
  margin-right: 13px;
`;

const ItemChevron = styled(Chevron)`
  /* svg .chevron-line.icon-line--style {
    stroke: ${COLOR.BLUE};
  } */
`;

const StyledHeroBranded = styled(HeroBranded)`
  background-image: url(${mobileHeroImage});
  background-size: cover;
  background-position: center center;
  background-color: #cfeafe;
  background-repeat: no-repeat;
  height: 100%;
  .HeroContent {
    background-color: #cfeafe;
    width: 100%;
  }
  @media (max-width: ${BREAKPOINT.MD}) {
    [class^='HeroBranded__HeroImage'] {
      display: none;
    }

    .HeroContent {
      background-color: transparent;
      height: 320px;
      padding: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-top: 60px;

      > div {
        margin: 0 0 0 21px;
      }
    }
  }

  @media (min-width: ${BREAKPOINT.MD}) {
    background-image: url(${heroImageTablet});
    background-size: cover;
    height: 300px;
    .HeroContent {
      background-color: transparent;
    }
  }
  @media (min-width: ${BREAKPOINT.LG}) {
    height: 320px;
  }
  @media (min-width: ${BREAKPOINT.XL}) {
    background-image: url(${heroImageDesktop});
  }
`;

const StyledPromoSection = styled(PromoSection)`
  background-color: ${COLOR.GREY6};
`;

const StyledText = styled(Text)`
  margin-left: auto;
  margin-right: auto;
  text-align: left;
  background-color: white;
  @media (max-width: ${BREAKPOINT.MD}) {
    text-align: center;
  }
  @media (min-width: ${BREAKPOINT.MD}) {
    max-width: 730px;
  }
`;

const StyledHeading5 = styled(Heading5)`
  margin-left: auto;
  margin-right: auto;
  background-color: white;
  @media (max-width: ${BREAKPOINT.MD}) {
    text-align: center;
  }
  @media (min-width: ${BREAKPOINT.MD}) {
    max-width: 730px;
  }
`;

const StyledHeading4 = styled(Heading4)`
  margin-left: auto;
  margin-right: auto;
  background-color: white;
  @media (max-width: ${BREAKPOINT.MD}) {
    text-align: center;
  }
  @media (min-width: ${BREAKPOINT.MD}) {
    max-width: 730px;
  }
`;

const Seperator = styled.div`
  display: none;
  @media (min-width: ${BREAKPOINT.LG}) {
    display: block;
    width: 16px;
    height: 2px;
    background-color: ${COLOR.BLUE};
    margin: 13px 0 20px;
  }
`;

const StyledCardGroup = styled(CardGroup)`
  max-width: 100%;
  margin: 0 auto;

  @media (min-width: ${BREAKPOINT.SM}) {
    max-width: 80%;
  }
  @media (min-width: ${BREAKPOINT.MD}) {
    max-width: 50%;
    padding: 0 10%;
  }
  @media (min-width: ${BREAKPOINT.LG}) {
    max-width: 100%;
  }
`;

const StyledItem = styled.div`
  display: flex;
  padding: ${PADDING.P24};
  background-color: ${COLOR.WHITE};
  border-radius: 4px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.25);
  width: 100%;

  @media (min-width: ${BREAKPOINT.LG}) {
    display: block;
    height: 100%;
  }
`;

const breadcrumbData = [
  {
    name: 'Home',
    href: '/'
  },
  {
    name: 'Latitude Cyber Response'
  }
];

const LatitudeCyberIncident = props => {
  return (
    <Layout location={props.location}>
      <main className="navigation-spacer">
        <Helmet>
          <link
            rel="canonical"
            href="https://www.latitudefinancial.com.au/latitude-cyber-incident/"
          />
          <title>Latitude Cyber Incident | Impacted information</title>
          <meta
            name="description"
            content="Latitude Cyber Incident, Latitude Financial Services"
          />
        </Helmet>

        <StyledHeroBranded
          title="Latitude Cyber Response"
          text="Information about how to protect your identity"
          breadcrumbs={breadcrumbData}
        />

        <StyledPromoSection
          css={`
            background-color: white;
          `}
        >
          <Heading4
            color={COLOR.BLACK}
            align={ALIGN.CENTER}
            css={`
              margin-left: auto;
              margin-right: auto;
              background-color: white;
              @media (min-width: ${BREAKPOINT.MD}) {
                max-width: 730px;
              }
            `}
          >
            Identity information impacted
          </Heading4>
          <StyledText fontSize={FONT_SIZE.LARGE} marginBottom={MARGIN.M24}>
            We understand that many people remain concerned about the March
            cybercrime, and want to protect their information from any potential
            future misuse.
            <br />
            <br />
            We've provided guidance to help everyone affected by this event.
            Click on the buttons below to learn more about the steps you can
            take to protect your information and how to be reimbursed for the
            cost of documents you had to replace. Please note reimbursement is
            for replacement costs only.
            <br />
            <br />
          </StyledText>
        </StyledPromoSection>
        <StyledPromoSection
          css={`
            background-color: #f7f7f7;
          `}
        >
          <Heading4
            color={COLOR.BLACK}
            align={ALIGN.CENTER}
            css={`
              margin-left: auto;
              margin-right: auto;
              @media (min-width: ${BREAKPOINT.MD}) {
                max-width: 730px;
              }
            `}
          >
            What information has been impacted?
          </Heading4>
          <StyledCardGroup
            css={`
              svg {
                width: 70px;
                min-width: 70px;
                height: 70px;
                min-height: 70px;
              }

              [class^='latitude-id-information__StyledItem-'],
              [class^='latitude-id-information__ItemTitle-'],
              [class^='Heading__StyledComponent-'],
              [class^='Text__StyledText-'] {
                text-align: center !important;
                margin-bottom: 20px;
                flex-direction: column;
              }

              [class^='latitude-id-information__ItemIcon-'] {
                margin: 0 auto;
              }

              [class^='Linkstyled__StyledLink-'] {
                margin: 0 auto;
                border: 0;
                background-color: transparent;
              }

              @media (min-width: ${BREAKPOINT.LG}) {
                flex-direction: row;
              }

              @media (min-width: ${BREAKPOINT.MD}) {
                max-width: 730px;
                padding: 0;
                flex-direction: row;

                [class^='CardGroup__StyledCardGroupItem-'] {
                  width: calc(50% - 15px);
                  max-width: calc(50% - 15px);
                  margin-right: 30px;
                  margin-bottom: 0;
                }
              }

              @media (max-width: ${BREAKPOINT.SM}) {
                [class^='CardGroup__StyledCardGroupItem-'] {
                  width: 100%;
                  max-width: 100%;
                }
              }
            `}
          >
            {PageData.subpageNav.map((item, i) => {
              return (
                <CardGroupItem key={i} col={columns}>
                  <ItemLink
                    href={item.href}
                    trackId={`quicklinks-${item.trackId}`}
                    trackEventData={{
                      label: item.title,
                      location: title
                    }}
                  >
                    <StyledItem>
                      <ItemIcon name={item.icon} />
                      <Box>
                        <ItemTitle>{item.title}</ItemTitle>
                        <Text>{ReactHtmlParser(item.text)}</Text>
                        <Link
                          button={BUTTON_STYLE.SECONDARY}
                          href={item.href}
                          data-trackid={item.trackId}
                          width="124px"
                          trackEventData={{
                            location: 'latitude-id-information'
                          }}
                        >
                          Learn more
                        </Link>
                      </Box>
                    </StyledItem>
                  </ItemLink>
                </CardGroupItem>
              );
            })}
          </StyledCardGroup>
        </StyledPromoSection>
        <StyledPromoSection
          css={`
            background-color: white;
          `}
        >
          <StyledHeading5
            color={COLOR.BLACK}
            align={ALIGN.CENTER}
            css={`
              margin-top: 20px;
            `}
          >
            Further Information
          </StyledHeading5>
          <StyledText fontSize={FONT_SIZE.LARGE} marginBottom={MARGIN.M24}>
            For the most up-to-date information on the March incident go to{' '}
            <a
              href="/latitude-cyber-incident/"
              target="_blank"
              rel="noreferrer noopener"
            >
              latitudefinancial.com.au/latitude-cyber-incident/
            </a>
            <br />
            <br />
            Please also refer to Latitude's announcements to the ASX via the
            'News Room' on our website:{' '}
            <a
              href="/about-us/news-room"
              target="_blank"
              rel="noreferrer noopener"
            >
              latitudefinancial.com.au/about-us/news-room
            </a>
          </StyledText>
        </StyledPromoSection>
      </main>
    </Layout>
  );
};

export default LatitudeCyberIncident;
