import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { BREAKPOINT } from '../../../utils/constants';

const CONTACTS = {
  'Anthony Spargo': {
    email: 'Anthony.spargo@latitudefinancial.com',
    phoneNumber: '+61 414 107 591'
  },
  'Melody Walker': {
    email: 'mwalker@boingo.com',
    phoneNumber: '+1 424 256 7036'
  },
  'Brett Clegg': {
    email: 'brett@catoandclegg.com',
    phoneNumber: '+61 487 436 985'
  },
  'Mark Gardy': {
    email: 'Mark.Gardy@latitudefinancial.com',
    phoneNumber: '+61 412 376 817'
  },
  'Erin Price': {
    email: 'Erin.Price@virginaustralia.com',
    phoneNumber: '+61 412 376 817'
  },
  'Steve Dabkowski': {
    email: 'Stephen.Dabkowski@latitudefinancial.com',
    phoneNumber: '+61 419 880 486'
  },
  'Frederika Walls': {
    email: 'Frederika.Walls@latitudefinancial.com',
    phoneNumber: '+64 21 982 105'
  },
  'Lizzy Kingston': {
    email: 'lizzy.kingston@latitudefinancial.com',
    phoneNumber: '+61 414 472 138'
  },
  'Matthew Abbott': {
    email: 'Matthew.Abbott@latitudefinancial.com',
    phoneNumber: '+61 402 543 128'
  }
};

const Container = styled.span`
  margin: 32px 0;
  display: flex;
  justify-content: space-between;
  @media (max-width: ${BREAKPOINT.MD}) {
    flex-direction: column;
  }
`;
const Contact = styled.span`
  @media (max-width: ${BREAKPOINT.MD}) {
    &:not(:last-child) {
      margin-bottom: 28px;
    }
  }
`;

const MediaContact = ({ name }) => {
  const contacts = name.split(/\s*,\s*/).reduce((acc, name) => {
    if (CONTACTS[name]) {
      acc.push({
        name,
        ...CONTACTS[name]
      });
    } else {
      acc.push({
        name: 'UNKNOWN CONTACT' // This way it's immediately obvious that there is a typo or contact is missing in CONTACTS
      });
    }

    return acc;
  }, []);

  // See: https://github.com/gatsbyjs/gatsby/issues/15057
  return (
    <Container>
      {contacts.map(({ name, email, phoneNumber }) => (
        <Contact key={name}>
          {name}
          <br />
          <a href={`mailto:${email}`}>{email}</a>
          <br />
          {phoneNumber}
        </Contact>
      ))}
    </Container>
  );
};

MediaContact.propTypes = {
  name: PropTypes.string.isRequired
};

export default MediaContact;
