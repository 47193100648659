import React, { useContext } from 'react';
import { Link } from '@latitude/link';
import { BUTTON_STYLE } from '@latitude/core/utils/constants';
import Hero from '../components/Hero/Hero';
import Section from '../components/Section/Section';
import { CardGroup, CardGroupItem } from '../components/CardGroup/CardGroup';
import CardMedia from '../components/CardMedia/CardMedia';
import Layout from '../components/layout';
import Metadata from '../components/Metadata/Metadata';
import { getUrlFromEnv } from '../utils/getUrlFromEnvUtil';
import heroImage from '../images/chalkboard-equation.jpg';
import cardImageGemVisa from '../images/credit-cards/latitude-gem.png';
import cardImageMastercard from '../images/credit-cards/go-mastercard.png';
import cardImageCreditline from '../images/credit-cards/creditline-afs.png';
import { PageContext } from '@/context/PageContext';
import HeroBanner from '@/components/lab-components/HeroBanner';

const APPLY_URL = getUrlFromEnv('funnel-start');
const heading = 'Continue your application';

const CalculatorsAndToolsPage = props => {
  /** Contentful Data */
  const contentfulPageData = useContext(PageContext);
  const state = contentfulPageData?.updatedBody || {};
  /** - END - */

  return (
    <Layout location={props.location}>
      <main className="navigation-spacer">
        <Metadata title="eApps error" canonical="/latitudefinancial/" noIndex />
        {state?.heroBannerData?.[0] ? (
          <HeroBanner {...state?.heroBannerData[0]} />
        ) : (
          <Hero
            h1={
              <React.Fragment>
                Our application <br className="d-none d-lg-block" /> process had an
                <br className="d-none d-lg-block" /> error
              </React.Fragment>
            }
            bg={heroImage}
            backgroundPosition="100% 0%"
            backgroundPositionLg="100% 45%"
            darkBg
          />
        )}
        <Section id="cards" className="bg-lightest pb-5">
          <div className="row">
            <div className="col-12 col-lg-6 mx-lg-auto">
              <div className="text-center text--large px-3 px-md-0">
                <h2>{heading}</h2>
                <p className="mb-4">
                  Apologies for the inconvenience, please complete your
                  application.
                  <br />
                  If this error occurs again, please{' '}
                  <Link
                    href="https://latitudefs.zendesk.com/hc/en-au"
                    target="_blank"
                    rel="noopener noreferrer"
                    trackId="eappserror--contact-us"
                    trackEventData={{
                      location: heading
                    }}
                  >
                    contact us.
                  </Link>
                </p>
                <p className="pt-2 font-weight-bold">
                  Please return to your application:
                </p>
              </div>
            </div>
          </div>
          <CardGroup className="w-100 pt-2">
            {[
              {
                title: 'Gem Visa',
                imageSrc: cardImageGemVisa,
                imageAlt: 'Latitude Gem Visa',
                href: `${APPLY_URL}gem`,
                trackId: 'eappserror--an--gemvisa',
                trackCategory: 'cta',
                trackAction: 'application-link',
                productid: ['CCAUGEM']
              },
              {
                title: 'Go Mastercard',
                imageSrc: cardImageMastercard,
                imageAlt: 'Go Mastercard',
                href: `${APPLY_URL}go`,
                trackId: 'eappserror--an--gomc',
                trackCategory: 'cta',
                trackAction: 'application-link',
                productid: ['CCAUGOM']
              },
              {
                title: 'CreditLine',
                imageSrc: cardImageCreditline,
                imageAlt: 'CreditLine',
                href: '/credit-cards/apple-creditline/',
                trackId: 'eappserror--an--cl',
                trackCategory: 'button-link',
                trackAction: 'internal-link',
                productid: ['CCAUAFS']
              }
            ].map(item => (
              <CardGroupItem col={3}>
                <CardMedia
                  increaseMinHeight
                  cardMediaBackground
                  className="mb-4"
                  image={
                    <img
                      className="card-media__image--tall"
                      src={item.imageSrc}
                      alt={item.imageAlt}
                    />
                  }
                  href={item.href}
                  title={item.title}
                  buttons={
                    <Link
                      trackProductId={item.productid}
                      button={BUTTON_STYLE.TERTIARY}
                      href={item.href}
                      trackId={item.trackId}
                      trackEventData={{
                        category: item.trackCategory,
                        action: item.trackAction,
                        location: item.title
                      }}
                      width="100%"
                    >
                      Apply Now
                    </Link>
                  }
                />
              </CardGroupItem>
            ))}
          </CardGroup>
        </Section>
      </main>
    </Layout>
  )
};

export default CalculatorsAndToolsPage;
