import * as React from 'react';
import classnames from 'classnames';
import './_callout.scss';

const component = 'callout';

const CalloutBody = props => {
  const { className, content } = props;

  return (
    <p className={classnames(`${component}__description`, className)}>
      {content}
    </p>
  );
};

export default CalloutBody;
