import React from 'react';
import styled from 'styled-components';
import { BUTTON_STYLE, BREAKPOINT } from '@latitude/core/utils/constants';
import BrandedCallout from '@latitude/callout/BrandedCallout';
import { Link } from '@latitude/link';

export default () => (
  <Container>
    <BrandedCallout
      line1={
        <React.Fragment>
          <span>For FAQs, useful information and quick and easy forms</span>
          <br />
        </React.Fragment>
      }
      cta={
        <React.Fragment>
          <p>
            <Link
              button={BUTTON_STYLE.SECONDARY}
              href='https://latitudefs.zendesk.com/hc/en-au'
              trackId="callout-visit-our-help-centre"
              trackEventData={{ location: "We're here to help" }}
            >
              Visit our Help Centre
            </Link>
          </p>
        </React.Fragment>
      }
      />
  </Container>
);

const Container = styled.div`
  background-color: #fff;

  && {
    h3,
    p,
    b a {
      color: #000 !important;
    }

    & > div {
      &:before {
        background-color: #fff;
      }
    }

    /* outer  */
    & > div > div {
      background-color: #fff;
      background-image: none;

      &:before {
        background: #fbfbfb !important;
      }

      &:after {
        background-image: url(${require('../../../images/credit-cards/callout-tubes-mobile.png')});
        @media (min-width: ${BREAKPOINT.LG}) {
          background-image: url(${require('../../../images/credit-cards/callout-tubes.png')});
        }
      }
    }

    /* inner */
    & > div > div > div {
    }
  }
`;
