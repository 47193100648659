import React, { useContext } from 'react';
import Helmet from 'react-helmet';
import { AnalyticsLocationProvider } from 'latitude-analytics';
import { Flex } from '@latitude/box';
import { CountdownTimer } from '@latitude/countdown-timer';
import { PlanTypes } from '@latitude/interest-free-plans';
import { Horizontal } from '@latitude/spacing';
import { Heading6 } from '@latitude/heading';
import { Link } from '@latitude/link';
import { ImportantInformation } from '@latitude/important-information';
import { Footer } from '@latitude/footer';
import {
  ALIGN_ITEMS,
  BUTTON_STYLE,
  BREAKPOINT,
  COLOR,
  FLEX_DIRECTION,
  JUSTIFY_CONTENT,
  MARGIN,
  PADDING
} from '@latitude/core/utils/constants';
import Navigation from './components/_navigation';
import HeroBranded from '../../../components/HeroBranded/HeroBranded';
import Layout from '../../../components/layout';
import Promo, { PromoSection } from '../../../components/Promo/Promo';
import OfferTiles from '../../../components/OfferTiles/OfferTiles';
import { getUrlFromEnv } from '../../../utils/getUrlFromEnvUtil';

import PageData from '../../../data/pages/interest-free/deal-fest.json';
import footerData from '../../../data/pages/interest-free/footer.json';
import imgPromo from '../images/promo-dealfest.png';
import imgHero from '../images/logo-dealfest-event.png';
import imgHeroBg from '../images/bg-dealfest.png';
import imgHeroBgMobile from '../images/bg-dealfest-event-mobile.png';
import imgFooter from '../../../images/gem-visa-card-illustrated.png';
import { StaticImage } from 'gatsby-plugin-image';
import HeroBanner from '@/components/lab-components/HeroBanner';
import { PageContext } from '@/context/PageContext';

const APPLY_URL = getUrlFromEnv('funnel-start-gemvisa');

const DealFestSalePage = props => {
  /** Contentful Data */
  const contentfulPageData = useContext(PageContext);
  const state = contentfulPageData?.updatedBody || {};
  /** - END - */
  
return (
  <Layout
    location={props.location}
    customFooter={{
      ...footerData,
      cardData: {
        text: footerData.cardData.text,
        href: APPLY_URL,
        img: imgFooter
      }
    }}
  >
    <main className="navigation-spacer" css={COLOR.GREY6}>
      <Helmet>
        <link
          rel="canonical"
          href="https://www.latitudefinancial.com.au/interest-free/deal-fest/"
        />
        <title>Deal Fest Sale | Latitude Interest Free</title>
        <meta
          name="description"
          content="Creating the best Interest Free shopping experience for our customers."
        />
      </Helmet>

      {/* Hero */}
      {state?.heroBannerData?.[0] ? (
        <HeroBanner {...state?.heroBannerData[0]} />
      ) : (
        <div
          css={`
            background-image: url(${imgHeroBgMobile});
            background-size: cover;
            @media (min-width: ${BREAKPOINT.SM}) {
              background-image: url(${imgHeroBg});
              .HeroContent {
                padding-top: 90px;
              }
            }
            @media (min-width: ${BREAKPOINT.MD}) {
              background-image: url(${imgHeroBg});
              .HeroContent {
                padding-top: 60px;
              }
            }
            @media (min-width: ${BREAKPOINT.LG}) {
              background-image: url(${imgHeroBg});
              .HeroContent {
                padding-top: 90px;
              }
            }
          `}
        >
          <HeroBranded
            title={
              <>
                Get $150 credit back with Latitude Interest Free<sup>*</sup>
              </>
            }
            text={
              <>
                Simply spend $1,200 or more on a Latitude Interest Free plan with
                one of our Deal Fest retailers, plus shop their amazing deals
                below. Hurry, Deal Fest ends Sunday 6 June.
              </>
            }
            transparentBg
            imageContent={
              <div
                css={`
                  display: none;
                  justify-content: center;
                  @media (min-width: ${BREAKPOINT.SM}) {
                    display: flex;
                    margin-left: 50px;
                  }
                  @media (min-width: ${BREAKPOINT.MD}) {
                    display: flex;
                    margin-left: 120px;
                  }
                  @media (min-width: ${BREAKPOINT.LG}) {
                    display: flex;
                    margin-left: 200px;
                    padding-right: 30px;
                  }
                `}
              >
                <img
                  src={imgHero}
                  alt="Latitude Deal Fest"
                  css={`
                    @media (min-width: ${BREAKPOINT.SM}) {
                      height: 380px;
                    }
                    @media (min-width: ${BREAKPOINT.MD}) {
                      height: 400px;
                    }
                    @media (min-width: ${BREAKPOINT.LG}) {
                      height: 520px;
                    }
                  `}
                />
              </div>
            }
            buttonProps={{
              id: 'dealfest-hero-cta',
              href: APPLY_URL,
              button: BUTTON_STYLE.PRIMARY,
              children: 'Apply for a Latitude Gem Visa',
              trackId: 'interest-free-deal-fest--hero--apply'
            }}
            trackEventData={{
              category: 'cta',
              action: 'application-link'
            }}
            trackProductId={['CCAUGEM']}
          />
        </div>
      )}

        <ImportantInformation
          data={{
            content: {
              importantInformationSectionOne:
                PageData.content.importantInformationSectionOneSalePage,
              importantInformationSectionTwo:
                PageData.content.importantInformationSectionTwoSalePage
            }
          }}
          sectionOneColummClasses="col-10 offset-1 col-lg-5 offset-lg-1"
          sectionTwoColummClasses="col-10 offset-1 col-lg-5 offset-lg-0"
          {...state?.importantInfoData?.[0]}
        />
      </main>
    </Layout>
  );
};

export default DealFestSalePage;
