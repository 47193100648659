/* eslint-disable import/no-unresolved */
import React from 'react';
import Helmet from 'react-helmet';
import styled from 'styled-components';
import { Heading4 } from '@latitude/heading';
import {
  BREAKPOINT,
  COLOR,
  ALIGN,
  FONT_SIZE,
  MARGIN
} from '@latitude/core/utils/constants';
import { Text } from '@latitude/text';
import Layout from '../components/layout';
import HeroBranded from '../components/HeroBranded/HeroBranded';
import { PromoSection } from '../components/Promo/Promo';
import heroImageTablet from '../images/blue-accent-banner.webp';
import heroImageDesktop from '../images/blue-accent-banner.webp';
import mobileHeroImage from '../images/blue-accent-banner-mobile.webp';

import './latitude-cyber-incident.css';
import { LINE_HEIGHT } from '@/utils/constants';

const StyledHeroBranded = styled(HeroBranded)`
  background-image: url(${mobileHeroImage});
  background-size: cover;
  background-position: center center;
  background-color: #cfeafe;
  background-repeat: no-repeat;
  height: 100%;
  .HeroContent {
    background-color: #cfeafe;
    width: 100%;
  }
  @media (max-width: ${BREAKPOINT.MD}) {
    [class^='HeroBranded__HeroImage'] {
      display: none;
    }

    .HeroContent {
      background-color: transparent;
      height: 320px;
      padding: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;

      > div {
        margin: 0 0 0 21px;
      }
    }
  }

  @media (min-width: ${BREAKPOINT.MD}) {
    background-image: url(${heroImageTablet});
    background-size: cover;
    height: 300px;
    .HeroContent {
      background-color: transparent;
    }
  }
  @media (min-width: ${BREAKPOINT.LG}) {
    height: 320px;
  }
  @media (min-width: ${BREAKPOINT.XL}) {
    background-image: url(${heroImageDesktop});
  }
`;

const StyledPromoSection = styled(PromoSection)`
  background-color: ${COLOR.GREY6};
`;

const StyledText = styled(Text)`
  font-size: ${FONT_SIZE.LARGE};
  margin-bottom: ${MARGIN.M24};
  margin-left: auto;
  margin-right: auto;
  text-align: left;
  background-color: white;
  @media (min-width: ${BREAKPOINT.MD}) {
    max-width: 730px;
  }
  span {
    display: block;
    margin-bottom: 5px;
  }
`;

const StyledItem = styled(Text)`
  font-size: ${FONT_SIZE.LARGE};
  display: block;
  margin-left: auto;
  margin-right: auto;
  text-align: left;
  background-color: white;
  margin-bottom: 10px;
  @media (min-width: ${BREAKPOINT.MD}) {
    max-width: 730px;
  }
`;

const LatitudeCyberIncident = props => {
  return (
    <Layout location={props.location}>
      <main className="navigation-spacer">
        <Helmet>
          <link
            rel="canonical"
            href="https://www.latitudefinancial.com.au/latitude-cyber-incident/"
          />
          <title>Latitude Cyber Incident | Information and support</title>
          <meta
            name="description"
            content="Latitude Cyber Incident, Latitude Financial Services"
          />
        </Helmet>

        <StyledHeroBranded title="Statement Of Notification" />

        <StyledPromoSection
          css={`
            background-color: white;
          `}
        >
          <StyledText>
            <StyledItem>
              On 16 March 2023, Latitude
              <a
                href="#note-1"
                style={{ textDecoration: 'none', borderBottom: 'none' }}
              >
                <sup>1</sup>
              </a>{' '}
              announced that it had experienced a malicious cyber-attack over
              the prior few days that resulted in a data theft.
            </StyledItem>
            <StyledItem>
              Our investigation identified that the cybercriminals used a
              compromised credential, obtained via a third-party, to access
              Latitude's network and steal personal information.
            </StyledItem>
            <StyledItem>
              We immediately alerted relevant authorities and law enforcement
              agencies, including the Australian Cyber Security Centre (ACSC)
              and the Australian Federal Police (AFP), and engaged external
              cyber security specialists to work alongside our own teams.
            </StyledItem>
            <StyledItem>
              This crime remains under investigation by the AFP. We also
              notified the Office of the Australian Information Commissioner
              (OAIC) and the New Zealand Office of the Privacy Commissioner
              (OPC) about the incident on 16 March 2023, and we continue to work
              with them.
            </StyledItem>
          </StyledText>

          <Heading4
            color={COLOR.BLACK}
            align={ALIGN.CENTER}
            css={`
              margin-left: auto;
              margin-right: auto;
              background-color: white;
              @media (min-width: ${BREAKPOINT.MD}) {
                max-width: 730px;
              }
            `}
          >
            What kind of information has been impacted?
          </Heading4>
          <StyledText>
            <StyledItem>
              The attack resulted in different kinds of personal information
              being compromised. The personal information was provided to
              Latitude and/or one of our predecessor companies in Australia and
              New Zealand, including GE Money, Australian Guarantee Corporation
              (AGC), Pacific Retail Finance or AVCO, when customers made an
              enquiry, sought a quote, or applied for a personal loan, motor
              loan or other credit product, including credit cards branded as
              Gem, GO, CreditLine, Buyer's Edge or in the name of a retailer.
              The information was used to verify identity and/or to assess and
              process applications.
            </StyledItem>
            <StyledItem>
              The kinds of personal information that were compromised could
              include some or all of the following for an affected customer:
            </StyledItem>
            <ul>
              <li>Names, contact details, dates of birth</li>
              <li>
                Images and/or numbers of driver licences, passports, or other
                identification documents such as proof of age cards
              </li>
              <li>Medicare card details</li>
              <li>
                General financial information which was provided as part of an
                application, including details about employment, income and
                expenses.
              </li>
            </ul>
          </StyledText>
          <StyledItem>
            Bank account passwords and expiry dates/3-digit CVC numbers for
            credit cards{' '}
            <strong>
              <em>were not</em>
            </strong>{' '}
            compromised.
          </StyledItem>
          <Heading4
            color={COLOR.BLACK}
            align={ALIGN.CENTER}
            css={`
              margin-left: auto;
              margin-right: auto;
              background-color: white;
              @media (min-width: ${BREAKPOINT.MD}) {
                max-width: 730px;
              }
            `}
          >
            Notification of affected customers
          </Heading4>
          <StyledText>
            <StyledItem>
              Since the attack, we have attempted to directly notify, via email
              or letter, customers who had an identification document (such as a
              driver licence or passport), Medicare card and/or financial
              information compromised.
            </StyledItem>
            <StyledItem>
              The direct notifications described the compromised information of
              the affected customer (but did not include the compromised
              information itself) and provided specific guidance and
              recommendations on steps that the affected customer could take to
              protect themselves.
            </StyledItem>
            <StyledItem>
              We completed the above notification process for the vast majority
              of affected customers by 30 June 2023.
            </StyledItem>
            <StyledItem>
              This Statement is a notification to those affected customers whom
              we have not contacted directly, or who have not successfully
              received a direct notification email or letter from us.
            </StyledItem>
          </StyledText>

          <Heading4
            color={COLOR.BLACK}
            align={ALIGN.CENTER}
            css={`
              margin-left: auto;
              margin-right: auto;
              background-color: white;
              @media (min-width: ${BREAKPOINT.MD}) {
                max-width: 730px;
              }
            `}
          >
            Steps we are taking to help affected people
          </Heading4>
          <StyledText>
            <StyledItem>
              We are fully aware that having any personal information
              compromised is likely to be distressing and concerning. We have
              therefore put in place the following measures to support all of
              our customers.
            </StyledItem>
            <ul>
              <li>
                <StyledItem>
                  Customers can contact us on (AU) 1300&nbsp;793&nbsp;416,
                  9am&ndash;6pm, Monday &mdash; Saturday, or (NZ)
                  0800&nbsp;777&nbsp;885, 9am&ndash;6pm Monday to Friday,
                  excluding public holidays.
                </StyledItem>
                <StyledItem>
                  Please be aware that wait times may be longer than we would
                  like.
                </StyledItem>
                <StyledItem>
                  If customers are concerned that they may be in a vulnerable
                  position because of this incident, our dedicated team will be
                  able to provide appropriate support and assistance.
                </StyledItem>
              </li>
              <li>
                <StyledItem>
                  If you are concerned about the impact of the theft, IDCARE
                  expert Case Managers can be contacted at{' '}
                  <a href="https://www.idcare.org/">www.idcare.org</a> or call
                  (AU) 1800&nbsp;595&nbsp;160, 8am&ndash;5pm AEST, or (NZ)
                  0800&nbsp;121&nbsp;068, 10am&ndash;7pm NZST, Monday &ndash;
                  Friday (excluding public holidays).
                </StyledItem>
                <StyledItem>Please use the referral code LAT23.</StyledItem>
                <StyledItem>
                  IDCARE's services are available at no cost.
                </StyledItem>
              </li>
              <li>
                <StyledItem>
                  Where needed{' '}
                  <a
                    href="/latitude-id-information/identity-images/#reimbursement-claims"
                    rel="noopener nofollow"
                  >
                    we will reimburse
                  </a>{' '}
                  customers for the replacement cost of their stolen ID
                  document.
                </StyledItem>
              </li>
              <li>
                Customers have the right to make a complaint to us in the first
                instance or to the OAIC at{' '}
                <a href="https://www.oaic.gov.au/privacy/privacy-complaints/what-you-can-complain-about">
                  oaic.gov.au/privacy/privacy-complaints/what-you-can-complain-about
                </a>{' '}
                or to the OPC at{' '}
                <a href="https://privacy.org.nz/your-rights/making-a-complaint-to-the-privacy-commissioner/">
                  privacy.org.nz/your-rights/making-a-complaint
                </a>
              </li>
            </ul>
          </StyledText>
          <Heading4
            color={COLOR.BLACK}
            align={ALIGN.CENTER}
            css={`
              margin-left: auto;
              margin-right: auto;
              background-color: white;
              @media (min-width: ${BREAKPOINT.MD}) {
                max-width: 730px;
              }
            `}
          >
            Steps that affected customers can take to protect themselves
          </Heading4>
          <StyledText>
            <StyledItem>
              In addition to the actions that we are taking to help customers,
              there are precautions that customers should take to protect
              themselves. Please refer to our webpage{' '}
              <a href="https://www.latitudefinancial.com.au/latitude-cyber-incident/">
                latitudefinancial.com.au/latitude-cyber-incident{' '}
              </a>
              for our recommendations on steps that customers should take.
            </StyledItem>
          </StyledText>
          <StyledText>
            <br />
            <Text fontSize={FONT_SIZE.SMALL} lineHeight={LINE_HEIGHT.TIGHT}>
              <sup id="note-1">1</sup> In Australia, this statement is given by
              Latitude Financial Services Australia Holdings Pty Ltd (trading as
              Latitude Financial Services) on behalf of Latitude Finance
              Australia (ABN 42 008 583 588), Latitude Personal Finance Pty Ltd
              (ABN 54 008 443 810), Latitude Automotive Financial Services Pty
              Ltd (ABN 80 004 187 419), Hallmark General Insurance Company Ltd
              (ABN 82 008 477 647) and Hallmark Life Insurance Company Ltd (ABN
              87 008 446 884), as applicable, as well as Symple Loans Pty Ltd
              (ABN 65 624 150 849) and LatitudePay Australia Pty Ltd (ABN 23 633
              528 873). In New Zealand, this statement is given by Latitude
              Financial Services Ltd (NZ5624865), on its own behalf and on
              behalf of the New Zealand branches of Hallmark General Insurance
              Company Ltd (ABN 82 008 477 647) and Hallmark Life Insurance
              Company Ltd (ABN 87 008 446 884), and Latitude Innovation Holdings
              Limited (7146597) as applicable.
            </Text>
          </StyledText>
        </StyledPromoSection>
      </main>
    </Layout>
  );
};

export default LatitudeCyberIncident;
