import React, { useContext } from 'react';
import styled from 'styled-components';

import { Metadata } from '@latitude/metadata';
import { SecondaryHero } from '@latitude/hero';
import { Box } from '@latitude/box';
import { Text as LText } from '@latitude/text';
import { Heading4, Heading5 } from '@latitude/heading';
import { Vertical } from '@latitude/spacing';
import { COLOR, MARGIN } from '@latitude/core/utils/constants';
import { TabNavigation } from '@latitude/tab-navigation';
import { Link } from '@latitude/link';

import Layout from '@/components/layout';
import { TITLE_SUFFIX, PRODUCT_NAME } from '@/utils/constants';
import TermsData from './_data';
import TabStyle from './_TabStyle';
import { AnalyticsContext } from 'latitude-analytics';
import { PageContext } from '@/context/PageContext';
import GoMicrositeHeader from '../_go-microsite-header';

const Text = styled(LText)`
  color: ${COLOR.BLACK};
`;

const TermsAndConditionsMobileAppPage = ({ location }) => {
  const [analytics] = useContext(AnalyticsContext);

  /** Contentful Data */
  const contentfulPageData = useContext(PageContext);
  const state = contentfulPageData?.updatedBody || {};
  /** - END - */

  // adding pageData to analytics
  analytics.pageData = {
    page_pageTitle: 'terms-conditions-mobile',
    page_pageType: 'information-page',
    page_siteSection: 'information',
    page_siteSubsection: 'credit-cards'
  };

  return (
    <Layout location={location}>
      <Metadata
        title={`Terms and Conditions for ${PRODUCT_NAME} Mobile Application | ${TITLE_SUFFIX}`}
      />
      <Box backgroundColor={COLOR.GREY6} css="margin-top:100px;">
        <SecondaryHero heading="Terms and Conditions" />
      </Box>

      <GoMicrositeHeader data={state.secondaryNavData} />

      <TabStyle>
        <TabNavigation
          basepath="/credit-cards/gomastercard/terms-conditions"
          data={TermsData}
        />
      </TabStyle>
      <Box>
        <Box.Section isBodyCopy backgroundColor={COLOR.WHITE}>
          <Heading4 color={COLOR.BLACK} marginBottom={MARGIN.M40}>
            Terms &amp; Conditions for {PRODUCT_NAME} Mobile Application
          </Heading4>
          <Vertical spacing={MARGIN.TEXT}>
            <Heading5 color={COLOR.BLACK}>1. Definitions</Heading5>
            <Text>
              In this document:
              <ul>
                <li>
                  1. we/us means Latitude Financial Services Australia Holdings
                  Pty Ltd (ABN 46 603 161 100) and Latitude Finance Australia
                  (ABN42 008 583 588), Australian Credit Licence number 392
                  145); and
                </li>
                <li>2. you means you, the user, of this page.</li>
                <li>3. App means Mobile Application.</li>
              </ul>
            </Text>
            <Heading5 color={COLOR.BLACK}>2. Acknowledgement</Heading5>
            <Text>
              THESE TERMS AND CONDITIONS FORM A LEGALLY BINDING AGREEMENT
              BETWEEN YOU AND US. YOU ACKNOWLEDGE AND AGREE THAT THESE TERMS AND
              CONDITIONS APPLY TO YOUR ACCESS TO, AND USE OF, THIS APP. IN
              ACCESSING OR USING THIS APP, YOU ACKNOWLEDGE THAT YOU HAVE READ
              AND UNDERSTOOD, AND AGREE TO BE BOUND BY, THESE TERMS AND
              CONDITIONS.
            </Text>
            <Heading5 color={COLOR.BLACK}>3. Changes</Heading5>
            <Text>
              We may at any time change, modify, add or remove portions of these
              terms and conditions. If you use this App in the future, you may
              wish to look at these terms and conditions as they may have
              changed.
            </Text>
            <Heading5 color={COLOR.BLACK}>4. Use of this App</Heading5>
            <Text>
              We do not represent or warrant that this App will be error-free or
              defect-free or that errors or defects will be corrected and we
              specifically disclaim any liability or responsibility for any App
              faults, failures or interruptions or the accuracy, timeliness,
              completeness or security of any communications transmissions of
              data. To the maximum extent permitted by law, we shall not be
              liable for any loss or damage of any kind (including, without
              limitation, liability to a third party) which you may suffer or
              incur as a result of such activity. You must use this App for
              lawful purposes only and in compliance with all applicable laws.
              You must not misuse, in any way, this App or any materials
              available through this App (including, without limitation,
              copying, adapting, reproducing, storing, distributing, printing,
              displaying, performing, publishing, communicating to the public or
              creating derivative works of the whole or any part of those
              materials except as expressly permitted by, or as is reasonably
              contemplated by, the normal use of this App). Your use of this App
              is also governed by the iTunes Store terms and conditions and you
              should familiarise yourself with those terms.
            </Text>
            <Heading5 color={COLOR.BLACK}>5. Links</Heading5>
            <Text>
              This page may contain links to internet sites maintained by third
              parties. These links to third party sites are provided for your
              convenience. Such links are not under our control and we are not
              responsible for the contents (including the accuracy, completeness
              or suitability for your intended use) of any linked site or any
              link contained in a linked site. We do not endorse any information
              on those linked sites or any associated organisation, product or
              services and your access and use of such sites is solely at your
              own risk and subject to those sites' terms of use.
            </Text>
            <Heading5 color={COLOR.BLACK}>6. Disclaimers</Heading5>
            <Text>
              We do not warrant or represent the accuracy, completeness or
              suitability for your intended use of any information on, or
              available through, this App. You are responsible for the use of
              any such information and you should make your own enquiries to
              check if the information is accurate, complete and suitable for
              your intended use.
            </Text>
            <Text>
              None of the information on this App is intended to constitute
              financial or professional advice. To the maximum extent permitted
              by law, we shall not be liable for any loss or damage of any kind
              (including, without limitation, liability to a third party),
              howsoever caused (including through our negligence), suffered or
              incurred by you arising from or in connection with your access to,
              or use of, this App. The above disclaimer does not attempt or
              purport to exclude liability under any statute if, and to the
              extent, such liability cannot be lawfully excluded.
            </Text>
            <Text>
              To the fullest extent permitted by law, our liability under any
              guarantee, condition or warranty (including, without limitation,
              any guarantee, condition or warranty of merchantability,
              acceptable quality, fitness for purpose or fitness for disclosed
              result), or any other right or remedy, under any legislation or
              implied into these terms and conditions by any legislation
              (Statutory Warranties) is hereby excluded. Where we are liable
              under any Statutory Warranties, and any legislation avoids or
              prohibits provisions in a contract excluding or modifying the
              application of, or exercise of, or liability under, such Statutory
              Warranties, our liability for any breach of such Statutory
              Warranties shall be limited, at our option, to one or more of the
              following: (a) if the breach relates to goods: the replacement of
              the goods or the supply of equivalent goods; the repair of such
              goods; the cost of replacing the goods or of acquiring equivalent
              goods; or the cost of having the goods repaired; and (b) if the
              breach relates to services: the supplying of the services again or
              the cost of having the services supplied again. You acknowledge
              and agree that reliance by us on this limitation of liability is
              fair and reasonable in all the circumstances.
            </Text>
            <Heading5 color={COLOR.BLACK}>7. Limited licence</Heading5>
            <Text>
              We grant you a non-exclusive, non-transferable, limited right to
              access and use this App. You may view and download materials
              available through this App only for your personal, non-commercial
              use.
            </Text>
            <Heading5 color={COLOR.BLACK}>8. Copyright</Heading5>
            <Text>
              All copyright in any materials provided by us on this App
              (including, without limitation, text, graphics, logos, icons,
              sound recordings and software) is owned by or licensed to us
              Except as expressly authorised, and subject to the conditions
              prescribed under, the Copyright Act 1968 (Cth) or except as
              expressly permitted by these terms and conditions, you must not,
              in any form, or by any means, copy, adapt, reproduce, store,
              distribute, print, display, perform, publish, communicate to the
              public or create derivative works of the whole or any part of, or
              commercialise the whole or any part of, these materials, without
              our express written permission or, in the case of third party
              material, from the owner of the copyright in that material.
            </Text>
            <Heading5 color={COLOR.BLACK}>9. Trade marks</Heading5>
            <Text>
              Trade marks used on this page are a trade mark or registered trade
              mark of us or its respective owner. If you use any trade marks
              owned by us in reference to us, you must include a statement
              attributing that trade mark to us. You must not use any of our
              trade marks in, or as the whole or part of, your own trade marks,
              or in connection with any business, products or services which are
              not ours, or in a manner which is or may be confusing, misleading
              or deceptive to any person, or in a manner which disparages us or
              this page.
            </Text>
            <Heading5 color={COLOR.BLACK}>10. Privacy</Heading5>
            <Text>
              Any personal information collected and held by us through your use
              of this page will be dealt with in accordance with our Privacy
              Policy available at{' '}
              <Link href="/privacy/">
                www.latitudefinancial.com.au/privacy/
              </Link>
            </Text>
            <Heading5 color={COLOR.BLACK}>11. General provisions</Heading5>
            <Text>
              If we waive, in whole or part, any rights available to us under
              these Terms &amp; Conditions on one occasion, this does not mean
              that those rights will automatically be waived on any other
              occasion.
            </Text>
            <Text>
              If any provision of these Terms &amp; Conditions is held to be
              invalid, unenforceable or illegal for any reason, it is to be read
              down to give it as much effect as possible, or if it is not
              capable of having any effect at all, it is to be severed from
              these Terms &amp; Conditions, in which case, the remainder of
              these Terms &amp; Conditions shall nevertheless continue in full
              force.
            </Text>
            <Text>
              The laws governing these Terms &amp; Conditions will be the laws
              in the State of Victoria, Australia and you irrevocably submit to
              the non-exclusive jurisdiction of the courts of that State.
            </Text>
          </Vertical>
        </Box.Section>
      </Box>
    </Layout>
  );
};

export default TermsAndConditionsMobileAppPage;
