import React, { useContext } from 'react';
import { PageContext } from '@/context/PageContext';
import Helmet from 'react-helmet';

import Section from '@latitude/section';
import { Heading2 } from '@latitude/heading';

import Layout from '@/components/layout';
import AchPageHeaderSection from './_ach-page-header';
import DegreesCardMicrositeHeader from '../_28d-microsite-header';

function AchDetailsPage({ location }) {
  /** Contentful Data */
  const contentfulPageData = useContext(PageContext);
  const state = contentfulPageData?.updatedBody || {};
  /** - END - */
  return (
    <Layout location={location}>
      <main
        className="navigation-spacer"
        css={`
          margin-top: 0 !important;
          background-color: #f8f8f8;
          .microsite-header {
            z-index: 2000 !important;
          }

          div[class^='MicrositeHeader__SecondBar'].microsite-header__nav-wrap {
            margin-top: 8px;
          }
        `}
      >
        <Helmet>
          <link
            rel="canonical"
            href="https://www.latitudefinancial.com.au/credit-cards/28-degrees/addcard/details/"
          />
          <meta
            name="description"
            content="Add an additional cardholder to your Latitude 28&deg; Global Platinum Mastercard"
          />
          <title>Additional Card Holder Request | 28 Degrees card</title>
        </Helmet>
        <DegreesCardMicrositeHeader data={state.secondaryNavData} />
        <AchPageHeaderSection />

        <Section>
          <Heading2 css={{ color: '#0046aa' }}>
            Nominate an Additional Cardholder today
          </Heading2>

          <div
            css={{ clear: 'both', margin: '10px 0' }}
            className="lfs-iframe-eform"
          >
            <iframe
              css={{
                width: '100%',
                minHeight: '800px',
                border: 'none',
                padding: '10px'
              }}
              title="Request an Additional Card Holder"
              src="https://eform.latitudefinancial.com.au/eform/Page.do?eFormName=ACH_28degrees"
            />
          </div>
        </Section>
      </main>
    </Layout>
  );
}

export default AchDetailsPage;
