import * as React from 'react';
import {
  VideoContainer,
  StyledLink,
  EmailContainer,
  EmailLink,
  TextContainer
} from './VideoResource.styles';

export const VideoResource = props => {
  return (
    <VideoContainer>
      <StyledLink
        href={props.to}
        trackId={props.title.toLowerCase().replace(/\s/g, '-')} // replace space with hyphen
        trackEventData={{
          location: props.location
        }}
      >
        {props.title}
      </StyledLink>
      <TextContainer>{props.text}</TextContainer>
      {props.email && (
        <EmailContainer>
          <EmailLink
            href={props.email}
            target="_blank"
            trackId={'email-'.concat(
              props.title.toLowerCase().replace(/\s/g, '-') // replace space with hyphen
            )}
            trackEventData={{
              location: props.location
            }}
          >
            Email Program Manager
          </EmailLink>
          &nbsp;for password
        </EmailContainer>
      )}
    </VideoContainer>
  );
};
