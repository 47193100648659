import React, { useEffect } from 'react';

const TARGET =
  'https://latitudefs.zendesk.com/hc/en-au/articles/29209141546001';

function ZendeskBalanceTransferRedirectPage({ location }) {
  useEffect(() => {
    if (window?.location) {
      window.location.replace(TARGET);
    }
  }, []);

  return <></>;
}

export default ZendeskBalanceTransferRedirectPage;
