import styled from 'styled-components';
import { Link } from '@latitude/link';
import { Flex } from '../Box/Box';
import { COLOR, FONT_SIZE, FONT_WEIGHT, PADDING } from '../../utils/constants';

export const VideoContainer = styled(Flex)`
  flex-direction: column;
  padding: ${PADDING.P16};
  border-top: solid 1px ${COLOR.GREY10};
  :last-child {
    border-bottom: solid 1px ${COLOR.GREY10};
  }
`;

export const StyledLink = styled(Link)`
  border-bottom: none;
  text-decoration: underline;
  text-shadow: none;
  &:hover {
    border-bottom: none;
    text-shadow: none;
  }
`;

export const EmailContainer = styled.div`
  display: inline-block;
  font-size: ${FONT_SIZE.NORMAL};
  font-weight: ${FONT_WEIGHT.LIGHT};
`;

export const EmailLink = styled(Link)`
  border-bottom: none;
  font-weight: lighter;
  text-decoration: underline;
  text-shadow: none;
  &:hover {
    border-bottom: none;
    text-shadow: none;
  }
`;

export const TextContainer = styled.div`
  display: inline-block;
  font-size: .97em;
  font-weight: ${FONT_WEIGHT.LIGHT};
`;
