/* eslint-disable flowtype/no-weak-types */
import React, { useState } from 'react';
import { getUrlFromEnv } from '@/utils/getUrlFromEnvUtil';
import ReactHtmlParser from 'react-html-parser';
import Slider from '@latitude/slider';
import Slide from '@latitude/slide';
import SvgInline from '@latitude/svg-inline';
import { Heading4 } from '@latitude/heading';
import { Link } from '@latitude/link';
import { BUTTON_STYLE, COLOR } from '@latitude/core/utils/constants';
import { LOAN_TYPES, MARGIN } from '@/utils/constants';
import RatesBox from '@/components/RatesBox/RatesBox';
import { Text } from '@/components/Text';
import { Box } from '@latitude/box';
import { AnalyticsLocationProvider } from 'latitude-analytics/lib/analytics';
import {
  GetLoanAboutData,
  GetLoanEligibilityData,
  GetLoanFeesData
} from './AccordionComparisonShared';
import {
  StyledSection,
  StyledLoanBoxContainer,
  StyledLoanCTAContainer,
  StyledAccordion
} from './AccordionComparisonTableSlider.styles';

/**
 * LoanBox
 */

const LoanBox = props => {
  return (
    <StyledLoanBoxContainer>
      <SvgInline name={props.icon} className="loan-box__icon" />
      <Heading4
        align="center"
        color={COLOR.BLACK}
        className="loan-box__heading"
      >
        {ReactHtmlParser(props.title)}
      </Heading4>
      {props.children}
    </StyledLoanBoxContainer>
  );
};

/**
 * LoanAccordionGroup
 */

const LoanAccordionGroup = props => {
  const { loanType, data, groupActiveIndex, setGroupActiveIndex } = props;

  // loan type variants
  const isPL = loanType === LOAN_TYPES.PL;
  const isAL = loanType === LOAN_TYPES.AL;
  const typeValue = isPL ? 'personal-loan' : isAL ? 'asset' : 'motor';

  return (
    <StyledAccordion
      borderedChildren
      titleBgColor={COLOR.WHITE}
      groupActiveIndex={groupActiveIndex}
      setGroupActiveIndex={setGroupActiveIndex}
      items={[
        {
          id: 'about-the-loan',
          title: 'About the loan',
          content: <GetLoanAboutData data={data} type={typeValue} />
        },
        {
          id: 'eligibility',
          title: 'Eligibility',
          content: <GetLoanEligibilityData data={data} type={typeValue} />
        },
        {
          id: 'fees',
          title: 'Fees',
          content: <GetLoanFeesData data={data} type={typeValue} />
        }
      ]}
    />
  );
};

/**
 * LoanCTABox
 */
const LoanCTA = props => {
  // loan type variants
  const isPL = props.loanType === LOAN_TYPES.PL;
  const isAL = props.loanType === LOAN_TYPES.AL;
  const applyUrl = isPL
    ? props.linkText === 'Applications before 8/8/22'
      ? `${getUrlFromEnv('funnel-start-salesforce-broker-dashboard')}`
      : `${getUrlFromEnv('funnel-start-broker')}`
    : isAL
    ? getUrlFromEnv('funnel-start-asset-loan')
    : getUrlFromEnv('funnel-start-broker');
  // const trackIdLoanName = isPL ? 'personal-loan' : 'motor-loan';
  const target = isPL && props.linkText === 'Get rate' ? '_self' : '_blank';
  return (
    <StyledLoanCTAContainer>
      <Link
        className="loan_cta mb-4"
        button={BUTTON_STYLE.PRIMARY}
        width="230px"
        href={applyUrl}
        trackId={props.trackId}
        trackEventData={props.trackEventData}
        trackProductId={props.trackProductId}
        target={target}
      >
        {props.linkText || 'Submit Application'}
      </Link>
    </StyledLoanCTAContainer>
  );
};

/**
 * Main Component
 */
const BrokerAccordionComparisonTableSlider = props => {
  const { productData, sliderData } = props;

  // state to sync up accordions activities
  const [accordionGroupActiveIndex, setAccordionGroupActiveIndex] = useState(
    null
  );

  return (
    <StyledSection>
      <Slider
        className="slider"
        responsiveSettings={sliderData.responsiveSettings}
      >
        {/* Personal loan slide */}
        <AnalyticsLocationProvider location="personal-loan">
          <Slide className="slide">
            <LoanBox icon="icon-pl" title="Personal<br />Loan">
              <Box
                marginLeft={MARGIN.M16}
                marginRight={MARGIN.M16}
                id="our-loans"
              >
                <Text align="center">
                  Easily check your client’s Personal Loan interest rate and
                  then submit an application
                </Text>
                <LoanCTA
                  loanType={LOAN_TYPES.PL}
                  linkText="Get rate"
                  trackId="personal-loan-get-rate"
                  trackEventData={{
                    category: 'cta',
                    action: 'quote-link'
                  }}
                  trackProductId={['PLAULF-WEB']}
                />
              </Box>
              <div style={{ marginTop: '88px' }}>
                <RatesBox isBranded type="secured" className="ml-0 mr-0" />
                <RatesBox
                  isBranded
                  type="unsecured"
                  className="ml-0 mr-0"
                  theme="secondary"
                />
              </div>
            </LoanBox>
            <LoanAccordionGroup
              loanType={LOAN_TYPES.PL}
              data={productData}
              groupActiveIndex={accordionGroupActiveIndex}
              setGroupActiveIndex={setAccordionGroupActiveIndex}
            />
          </Slide>
        </AnalyticsLocationProvider>
        {/* Asset loan slide */}
        <AnalyticsLocationProvider location="asset-loan">
          <Slide className="slide">
            <LoanBox icon="icon-car-loan" title="Asset<br />Loan">
              <Box marginLeft={MARGIN.M16} marginRight={MARGIN.M16}>
                <Text align="center">
                  Easily check your client’s Asset Loan interest rate and then
                  submit an application
                </Text>
            
                <LoanCTA
                  loanType={LOAN_TYPES.ML}
                  linkText="Get rate"
                  trackId="our-loans-explained--asset-loan-after"
                  trackEventData={{
                    label: 'Get rate',
                  }}
                  trackProductId={['MLAULF-WEB']}
                />
              </Box>
              <div>
                <RatesBox
                  isBranded
                  type="assetVariable"
                  className="ml-0 mr-0"
                />
                <RatesBox
                  isBranded
                  type="assetFixed"
                  className="ml-0 mr-0"
                  theme="secondary"
                />
              </div>
            </LoanBox>
              <LoanAccordionGroup
                loanType={LOAN_TYPES.AL}
                data={productData}
                groupActiveIndex={accordionGroupActiveIndex}
                setGroupActiveIndex={setAccordionGroupActiveIndex}
              />
          </Slide>
        </AnalyticsLocationProvider>
      </Slider>
    </StyledSection>
  );
};

export default BrokerAccordionComparisonTableSlider;
