'use strict';

import React from 'react';
import styled from 'styled-components';

import Section from '@latitude/section';
import { BREAKPOINT } from '@latitude/core/utils/constants';

import './_video-with-links.css';

const content1 = (
  <div class="featureSliderText">
    <ul className="watch-how-to-update">
      <li>
        <a href="https://www.youtube.com/embed/p0i9WZ5yZKw" target="_blank">
          Watch how to activate your card, PIN &amp; digital wallet
        </a>{' '}
        (opens video in YouTube)
      </li>
      <li>
        <a href="https://www.youtube.com/embed/8mCgX8v1dH8" target="_blank">
          Watch how to report a lost, stolen or damaged card
        </a>{' '}
        (opens video in YouTube)
      </li>
      <li>
        <a href="https://www.youtube.com/watch?v=tHRgUlMRnXQ&list=PL8sAE_plCW-N5I6aQsMsWvAd-dGuwPKMd&index=4" target="_blank">
          Watch how to set up, manage &amp; cancel your direct debit AU
        </a>{' '}
        (opens video in YouTube)
      </li>
      <li>
        <a href="https://www.youtube.com/embed/AdcN550km-A" target="_blank">
          Watch how to update your address
        </a>{' '}
        (opens video in YouTube)
      </li>
      <li>
        <a href="https://www.youtube.com/watch?v=VKTjLef7nTs&list=PL8sAE_plCW-N5I6aQsMsWvAd-dGuwPKMd&index=5" target="_blank">
        Watch how to reduce your credit limit
        </a>{' '}
        (opens video in YouTube)
      </li>
      
    </ul>
  </div>
);

const VideoWithLinks = ({ anchor, heading }) => {
  return (
    <Section
      id="video-with-links"
      className="contacting-video-with-links"
      css={`
        @media (min-width: ${BREAKPOINT.MD}) {
          height: 480px;
          padding-top: 10px;
          padding-bottom: 10px;
        }
      `}
    >
      <SectionHeading id={anchor}>{heading}</SectionHeading>
      <div className="row">
        <div className="col-12 p-4">
          <div
            className="row"
            css={`
              @media (max-width: ${BREAKPOINT.MD}) {
                flex-direction: column-reverse;
              }
            `}
          >
            <div className="col-md-6">{content1}</div>
            <div
              className="col-md-6"
              css={`
                position: relative;
                padding-bottom: 70%;
                height: 0;
                overflow: hidden;
                max-width: 100%;
              `}
            >
              <iframe
                css={`
                  position: absolute;
                  top: 0;
                  left: 0;
                  width: 100%;
                  @media (max-width: ${BREAKPOINT.MD}) {
                    height: 100%;
                  }
                `}
                width="560"
                height="315"
                src="https://www.youtube.com/embed/videoseries?list=PL8sAE_plCW-N5I6aQsMsWvAd-dGuwPKMd"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
};

const SectionHeading = styled.h2`
  margin: 40pt 0 15pt 0;
  color: #000;
  text-align: center;
  font-size: 24px;
  line-height: 32px;
  font-weight: 600;
  @media (min-width: ${BREAKPOINT.LG}) {
    font-size: 32px;
    line-height: 40px;
    padding-bottom: 0;
  }
`;

export default VideoWithLinks;
