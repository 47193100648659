import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import { Link } from '@latitude/link';
import styled from 'styled-components';
import { Box } from '../Box/Box';
import { Heading4 } from '../Heading/Heading';
import { FONT_FAMILY, COLOR, BREAKPOINT } from '../../utils/constants';

const heading = 'Looking for your card?';

const AdditionalCardsBranded = (props) => {
  const linkList = props.links ? props.links : links; // Use props.links if provided, otherwise use the default links.
  const selectedHeading = props.heading ? props.heading : heading; 
  return(<Box>
    <Container isResponsive>
      <Title>{selectedHeading}</Title>
      <Items>
        {linkList.map((item, i) => (
          <Item
            noStyle
            key={i}
            href={item.href}
            target="_blank"
            data-trackid={item.trackId}
            trackProductId={item.productid}
            trackEventData={{
              label: item.title.replace(/(°|<([^]+)>)/gi, ''),
              location: heading
            }}
          >
            <ItemImage src={item.imageSrc.default} />
            <ItemTitle>{ReactHtmlParser(item.title)}</ItemTitle>
          </Item>
        ))}
      </Items>
    </Container>
  </Box>
)};

export default AdditionalCardsBranded;

const links = [
  {
    title: 'Latitude Infinity<br /> Rewards Visa',
    href: '/credit-cards/latitude-infinity-rewards-credit-card/',
    trackId: 'credit-cards--compare-cards--fom--latitude-infinity',
    imageSrc: require('../../images/credit-cards/latitude-infinity-rewards-visa-card-horizontal.png'),
    productid: ['CCAUINF']
  },
  {
    title: 'Latitude<br /> Mastercard',
    href: '/credit-cards/latitude-mastercard/',
    trackId: 'credit-cards--compare-cards--fom--latitude-mastercard',
    imageSrc: require('../../images/credit-cards/latitude-mastercard.png'),
    productid: ['CCAULMC']
  },
  {
    title: 'Latitude Eco<br /> Mastercard',
    href: '/credit-cards/latitude-eco-mastercard',
    trackId: 'credit-cards--compare-cards--fom--latitude-eco-mastercard',
    imageSrc: require('../../images/credit-cards/latitude-eco-mastercard.png'),
    productid: ['CCAUECO']
  },
  {
    title: 'CreditLine',
    href: '/credit-cards/credit-line/',
    trackId: 'credit-cards--compare-cards--fom--creditline',
    imageSrc: require('../../images/credit-cards/creditline-120x76.webp'),
    productid: ['CCAUAFS']
  },
  {
    title: "Buyer's Edge",
    href: '/credit-cards/buyers-edge/',
    trackId: 'credit-cards--compare-cards--fom--buyers-edge',
    imageSrc: require('../../images/credit-cards/buyers-edge-120x76.webp'),
    productid: ['CCAUBE']
  },
  {
    title: 'CareCredit',
    href: '/credit-cards/care-credit/',
    trackId: 'credit-cards--compare-cards--fom--care-credit',
    imageSrc: require('../../images/credit-cards/care-credit.png'),
    productid: ['CCAUCCD']
  },
  {
    title: 'David Jones Icon credit card',
    href: 'https://www.davidjones.com/credit-card/compare/icon',
    trackId: 'credit-cards--compare-cards--fom--david-jones',
    imageSrc: require('../../images/credit-cards/david-jones.webp'),
    productid: ['CCAUDJ']
  }
];

const Container = styled(Box)`
  width: 100%;
  margin: 0 auto;
  padding-top: 40px;
  padding-bottom: 40px;
  @media (min-width: ${BREAKPOINT.LG}) {
    padding: 0 0 43px;
  }
`;

const Title = styled(Heading4)`
  font-family: ${FONT_FAMILY.TITLE};
  font-weight: 600;
  margin-bottom: 32px;
  color: #000;
  @media (min-width: ${BREAKPOINT.LG}) {
    font-size: 36px;
    margin-bottom: 48px;
  }
`;

const Items = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  @media (min-width: ${BREAKPOINT.LG}) {
    flex-wrap: nowrap;
  }
`;

const Item = styled(Link)`
  width: 120px;
  margin-right: calc(50% - 120px);
  margin-bottom: 40px;
  cursor: pointer;
  transition: all 100ms ease-in-out;

  &:hover {
    transform: scale(1.03);
    transition: all 100ms ease-in-out;
  }

  @media (min-width: ${BREAKPOINT.LG}) {
    margin-right: 0;
  }
`;

const ItemImage = styled.img`
  width: 100%;
  margin-bottom: 8px;
`;

const ItemTitle = styled.div`
  text-align: left;
  font-family: ${FONT_FAMILY.TITLE};
  font-weight: 600;
  color: ${COLOR.BLUE_MID};
  padding-left: 2px;
  line-height: 1.2;
`;
