import React from 'react';
import { BREAKPOINT, BUTTON_STYLE, COLOR, MARGIN, JUSTIFY_CONTENT } from '@latitude/core/utils/constants';
import { Box, Flex } from '@latitude/box';
import { Link } from '@latitude/link';
import { Promo } from '@latitude/promo';
import { Horizontal } from '@latitude/spacing';
import imgPromoInterestFree from './images/promo-interest-free.jpg';

const PromoInterestFree = () => (
  <Box.Section backgroundColor={COLOR.WHITE}>
    <Flex
      justifyContent={JUSTIFY_CONTENT.CENTER}
      css={`
        position: relative;
        z-index: 0;
      `}
    >
      <Promo
        title="Latest Interest Free offers"
        description="With hundreds of retail partners, there’s something for everyone."
        frameImage={<img src={imgPromoInterestFree} alt="" />}
        frameAlignment="left"
        frameBackgroundColor="#CDE9FE"
        frameBorderColor={COLOR.BLUE_SKY}
        ctaButtons={
          <Flex
            css={`
              justify-content: center;
              @media (min-width: ${BREAKPOINT.LG}) {
                justify-content: flex-start;
              }
            `}
          >
            <Horizontal spacing={MARGIN.M16}>
              <Link
                button={BUTTON_STYLE.SECONDARY}
                href="/interest-free/find-an-offer/"
                target="_self"
              >
                Explore offers
              </Link>
              <Link
                button={BUTTON_STYLE.TERTIARY}
                href="/interest-free/find-a-store/"
                target="_self"
              >
                View retailers
              </Link>
            </Horizontal>
          </Flex>
        }
        verticalAlignContent={false}
      />
    </Flex>
  </Box.Section>
);

export default PromoInterestFree;
