import React from 'react';
import { COLOR } from '@latitude/core/utils/constants';
import { Box } from '@latitude/box';
import { FeaturesSlider } from '@latitude/features-slider';

const WhyChoose = ({ featureData }) => (
  <div id="why-us">
    <Box
      backgroundColor={COLOR.GREY6}
      css={`
        position: relative;
        z-index: 1;
      `}
    >
      <FeaturesSlider
        key={featureData.heading}
        newBrand
        heading={featureData.heading}
        subheading={featureData.description}
        data={featureData.featureCards}
      />
    </Box>
  </div>
);

export default WhyChoose;
