import React, { useContext } from 'react';
import Helmet from 'react-helmet';

import { BUTTON_STYLE, BREAKPOINT } from '@latitude/core/utils/constants';
import { Link } from '@latitude/link';
import Section from '@latitude/section';
import SvgInline from '@latitude/svg-inline';

import StickyNavigationBranded from '@/components/StickyNavigation/StickyNavigationBranded';
import { Strong } from '@/components/Text/Bold';

import Layout from '@/components/layout';
import { ImportantInformation } from '@latitude/important-information';
import { Text } from '@/components/Text';

import heroImage from './images/hero-banner-image.webp';
import ExpediaWotifSection from './_expedia-wotif';
import RatesAndFees from './_rates-and-fees';
import HowToApply from './_how-to-apply';
import PromoInterestFree from './_promo-interest-free';
import CarouselHeader from '@/components/Carousel/carouselHeader';

import PaymentOptions, {
  PaymentOptionsColumn,
  PaymentOptionIcon,
  PaymentOptionTitle,
  PaymentOptionDescription,
  PaymentOptionImages,
  PaymentOptionButtons,
  PaymentOptionNote,
  PaymentOptionCallout,
  PaymentOptionList,
  PaymentOptionListItem,
  PaymentOptionHeading
} from '@/components/PaymentOptions/PaymentOptions';

import { DigitalWallets } from '@latitude/digital-wallets';
import BrandedCallout from '@latitude/callout/BrandedCallout';

import { PageContext } from '@/context/PageContext';
import PromoBanner from '@/components/lab-components/PromoBanner';
import HeroBanner from '@/components/lab-components/HeroBanner';
import OfferBanner from '@/components/lab-components/OfferBanner';
import LabRatesAndFees from '@/components/lab-components/LabRatesAndFees';
import GemMicrositeHeader from './_gem-microsite-header';
import { NotificationBanner } from '@latitude/banner';
import TilePromo from '@/components/TilePromo/tile-promo';
import { FeaturesSlider } from '@latitude/features-slider';
import StandaloneText from '@/components/lab-components/StandaloneText';
import { COLOR } from '@/utils/constants';
import FeatureCardList from '@/components/FeatureCardList/featureCardList';

const APPLY_URL = 'https://cards.latitudefinancial.com/gem';
const HEADER_ITEMS = [
  {
    title: 'Shop today with more time to pay',
    text:
      'Enjoy 6 months Interest Free on everyday purchases of $250 or more with your Latitude Gem Visa credit card.',
    termsconds: '',
    headerImage: heroImage,
    urlFindOutMore: '',
    urlApplyNow: APPLY_URL
  }
];

const schemaData = {
  "@context": "https://schema.org",
  "contactlessPayment": "true",
  "@type": "CreditCard",
  "annualPercentageRate": "27.99",
  "image": "https://assets.latitudefinancial.com/legals/key-fact-sheet/gemvisa-au/card-gem-visa.png",
  "url": "https://www.latitudefinancial.com.au/credit-cards/gem-visa/",
  "category": "Latitude Interest Free Credit Cards",
  "brand": { "@type": "Brand", "name": "Latitude Financial Services" },
  "amount": { "@type": "MonetaryAmount", "minValue": "1000", "currency": "AUD" },
  "name": "Latitude Gem Visa",
  "description":
    "Get an instant 0% Interest on everyday purchases of $250 or more and shop with 6-60 months interest free payment plans at thousands of stores.",
  "requiredCollateral": "$1000 minimum credit limit",
};

const promoBannerItems = [
  {
    title: "Don’t miss out on your $200 credit back",
    subTitle:
      "When you spend $1,000 or more on everyday purchases each month in the first 3 months of approval<sup>2</sup>. T&Cs, monthly credit card fee and other charges apply. Excludes long-term Interest Free payment plans. New customers only, must be approved by 28 Feb 2025.",
    btnText: "Apply Now",
    btnLink: "https://cards.latitudefinancial.com/gem"
  }
];

// Card data array
const cardData = [
  {
    id: 1,
    title: "Latitude Low Rate Mastercard",
    description: "Low rate.High rewards.",
    offerBadge: "★ Choose an offer",
    mainOffer: "$300 credit back offer OR 0% for 18 months on balance transfer",
    details: [
      "Get 3% back in Latitude Rewards when you set up recurring payments direct with participating providers",
      "13.99% p.a. purchase rate",
      "$69 annual credit card fee",
    ],
    image: require("../../../images/credit-cards/low-rate-card.png"),
    buttonProps: {
      href: "/credit-cards/low-rate",
      trackid: "feature-card-list-low-rate",
      button: BUTTON_STYLE.PRIMARY,
      btnText: "Choose your offer"
    },
    secBtn:{
      href:"/credit-cards/low-rate",
      btnText: "Find out more"
    }

  },
  {
    id: 2,
    title: "Latitude GO Mastercard",
    description: "Break up your bigger buys and enjoy more time to pay.",
    offerBadge: "★ Limited time offer",
    mainOffer: "$300 credit back offer",
    details: [
      "6–60 months Interest Free plans at Harvey Norman and other retailers",
      "27.99% p.a. purchase rate",
      "$10.95 monthly credit card fee",
    ],
    image: require("../../../images/credit-cards/go-mastercard.png"),
    buttonProps: {
      href: "https://cards.latitudefinancial.com/go",
      trackid: "feature-card-list-low-rate",
      button: BUTTON_STYLE.PRIMARY,
      btnText: "Apply now"
    },
    secBtn:{
      href:"/credit-cards/gomastercard/",
      btnText: "Find out more"
    }

  },
  {
    id: 3,
    title: "Latitude 28° Global Platinum Mastercard",
    description: "Travel better and shop confidently.",
    offerBadge: "",
    mainOffer: "",
    details: [
      "$0 international transaction fee on purchases overseas or online",
      "27.99% p.a. purchase rate",
      "$8 monthly credit card fee",
    ],
    image: require("../../../images/credit-cards/28-global-platinum-front.png"),
    buttonProps: {
      href: "https://cards.latitudefinancial.com/28degrees",
      trackid: "feature-card-list-low-rate",
      button: BUTTON_STYLE.PRIMARY,
      btnText: "Apply now"
    },
    secBtn:{
      href:"/credit-cards/28-degrees",
      btnText: "Find out more"
    }
  }
];
function CreditCardsPage(props) {
  /** Contentful Data */
  const contentfulPageData = useContext(PageContext);
  const state = contentfulPageData?.updatedBody || {};
  /** - END - */

  return (
    <Layout location={props.location} hasSecNav>
      <main
        className="navigation-spacer"
        css={`
          margin-top: 130px !important;
          .microsite-header {
            z-index: 2000 !important;
          }

          div[class^='MicrositeHeader__SecondBar'].microsite-header__nav-wrap {
            margin-top: 8px;
          }
        `}
      >
        <Helmet>
          <link rel="canonical" href="https://www.latitudefinancial.com.au/credit-cards/gem-visa/"  hreflang="x-default" />
          <script type="application/ld+json">{JSON.stringify(schemaData)}</script>
          <title>Gem Visa Credit Card | Shop With 0% Interest Payment Plans</title>
          <meta name="description" content="Enjoy flexible finance and exclusive retail offers with the Latitude Gem Visa credit card. Unlock a smarter way to shop with Interest Free plans. Apply online" />
        </Helmet>
        <GemMicrositeHeader data={state.secondaryNavData} />
        {!state?.alertData && (
          <NotificationBanner siteName="latitudefinancial-com-au" />
        )}
        {state?.heroBannerData?.[0] ? (
          <HeroBanner {...state?.heroBannerData[0]} />
        ) : (
          <CarouselHeader HEADER_ITEMS={HEADER_ITEMS} />
        )}

        <div
          className="d-none d-lg-block"
          css={`
            position: relative;
            z-index: 10;
            .sticky-navigation--fixed {
              top: 40px !important;
            }
          `}
        >
          <StickyNavigationBranded
            items={[
              {
                anchor: 'why-us',
                label: 'Why us?'
              },
              {
                anchor: 'rates-and-fees',
                label: 'Rates & Fees'
              },
              {
                anchor: 'payment-options',
                label: 'Payment Options'
              },
              {
                anchor: 'digital-wallets',
                label: 'Digital wallets'
              },
              {
                anchor: 'how-to-apply',
                label: 'How to apply'
              },
              {
                anchor: 'eligibility',
                label: 'Eligibility'
              }
            ]}
            offsetElem="[data-sticky-navigation-offset]"
            trackEventData={{
              category: 'cta',
              action: 'quote-link',
              location: 'sticky'
            }}
            trackId="sticky-get-started"
            {...state?.inPageNavData?.[0]}
          />
        </div>

        {state?.offerBannerData?.[0] ? (
          <OfferBanner {...state?.offerBannerData[0]} />
        ) : (
          <TilePromo bannerItem = {promoBannerItems}/>
        )}

        {state?.featureSliderData?.[0] && (
          <FeaturesSlider
            key={state?.featureSliderData?.[0].heading}
            heading={state?.featureSliderData?.[0].heading}
            id="why-us"
            className="why-choose bg-f8f8f8"
            data={state?.featureSliderData?.[0].featureCards}
            subheading={state?.featureSliderData?.[0].description}
            contentAlign={state?.featureSliderData?.[0].contentAlign}
            backgroundColor={COLOR.WHITE}
            css={`
              && {
                padding: 0 15px;
              }
              h4 p {
                margin-top: 0;
              }
            `}
          />
        )}

        {state?.standaloneTextData?.[0] && (
          <StandaloneText 
            text={state?.standaloneTextData?.[0].text}
            align={state?.standaloneTextData?.[0].align}
            background={state?.standaloneTextData?.[0].background}
            styles={`
              ul {
                padding-left: 10px;
                margin: 0;
              }
              ul p {
                margin-bottom: 5px;
                padding: 0;
              }
              p {
                font-size: 16px;
                margin-top: 0;
                padding-left: 9px;
              }
              h5 {
                margin-top: 0;
              }
            `}
          />
        )}
 
        {state?.ratesAndFeesData?.[0] ? (
          <LabRatesAndFees {...state.ratesAndFeesData[0]} />
        ) : (
          <RatesAndFees />
        )}

        {state?.promoBannerData?.[0] ? (
          <PromoBanner {...state?.promoBannerData[0]} />
        ) : (
          <ExpediaWotifSection id="expedia-wotif-offers" />
        )}


        <HowToApply />

        {state?.promoBannerData?.[1] ? (
          <PromoBanner {...state?.promoBannerData[1]} />
        ) : (
          <PromoInterestFree />
        )}

        <Section
          heading="Payment Options"
          id="payment-options"
          className="section--payment-options"
          css={`
            && {
              @media (min-width: ${BREAKPOINT.LG}) {
                padding-bottom: 72px;
              }
            }
          `}
        >
          <PaymentOptions
            data={[
              {
                id: 'latitude-app',
                title: 'Latitude App',
                titleHtml: 'Latitude App',
                content: (
                  <React.Fragment>
                    <PaymentOptionsColumn>
                      <PaymentOptionIcon>
                        <SvgInline name="payment-option-latitude-app" />
                      </PaymentOptionIcon>
                      <PaymentOptionTitle>Latitude App</PaymentOptionTitle>
                      <PaymentOptionDescription>
                        Make repayments in just a few taps with our mobile app.
                      </PaymentOptionDescription>
                      <PaymentOptionImages>
                        <Link
                          href="https://itunes.apple.com/app/apple-store/id1373059866?pt=118165058&ct=InfPayOpt0319&mt=8"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="d-inline"
                          data-trackid="payment-options-itunes-app-link"
                          trackEventData={{
                            label: ''
                          }}
                        >
                          <img
                            src={
                              require('../../../images/badge-app-store.svg')
                                .default
                            }
                            height="48px"
                            alt="app store"
                          />
                        </Link>
                        <Link
                          href="https://play.google.com/store/apps/details?id=com.latitudefinancial.latitudeapp&referrer=utm_source%3DInfPayOpt0319"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="d-inline"
                          data-trackid="payment-options-googleplay-app-link"
                          trackEventData={{
                            label: ''
                          }}
                        >
                          <img
                            src={
                              require('../../../images/badge-google-play.svg')
                                .default
                            }
                            height="48px"
                            alt="play store"
                          />
                        </Link>
                      </PaymentOptionImages>
                    </PaymentOptionsColumn>
                    <PaymentOptionsColumn>
                      <PaymentOptionList>
                        <PaymentOptionListItem>
                          <Strong>Get the app</Strong>
                          <Text fontSize="14px">
                            Simply download the Latitude App from the App Store
                            or Google Play
                          </Text>
                        </PaymentOptionListItem>
                        <PaymentOptionListItem>
                          <Strong>Create a Latitude ID</Strong>
                          <Text fontSize="14px">
                            Open the app and create your Latitude ID (this is
                            different to your Online Service Centre login).
                          </Text>
                        </PaymentOptionListItem>
                        <PaymentOptionListItem>
                          <Strong>Link your card</Strong>
                          <Text fontSize="14px">
                            Once your credit cards are linked, you’ll be able to
                            make payments via bank transfer with your BSB and
                            Account Number (Australia only).
                          </Text>
                        </PaymentOptionListItem>
                      </PaymentOptionList>
                    </PaymentOptionsColumn>
                    <PaymentOptionsColumn>
                      <PaymentOptionNote>
                        <Text fontSize="14px">
                          <Strong>Note:</Strong> Please be aware that bank
                          transfer payments can take three business days to
                          clear and you can only make one payment to your card
                          account every five days.
                        </Text>
                      </PaymentOptionNote>
                      <PaymentOptionCallout>
                        <Strong>No Fees</Strong>
                        <Text fontSize="14px">
                          We do not charge a fee for payments made via direct
                          bank transfer on the Latitude App.
                        </Text>
                      </PaymentOptionCallout>
                    </PaymentOptionsColumn>
                  </React.Fragment>
                )
              },
              {
                id: 'online-service-centre',
                title: 'Latitude Service Centre',
                titleHtml: 'Latitude Service Centre',
                content: (
                  <React.Fragment>
                    <PaymentOptionsColumn>
                      <PaymentOptionIcon>
                        <SvgInline name="payment-option-online" />
                      </PaymentOptionIcon>
                      <PaymentOptionTitle>
                        Latitude Service Centre
                      </PaymentOptionTitle>
                      <PaymentOptionDescription>
                        One of the quickest &amp; easiest way to make
                        repayments.
                      </PaymentOptionDescription>
                      <PaymentOptionButtons css="justify-content:center;">
                        <Link
                          target="_blank"
                          button="secondary"
                          href="https://servicecentre.latitudefinancial.com.au/login"
                          data-trackid="payment-options-service-center-login-link"
                          css="margin: 0 8px; flex: 1 1 50%;"
                          disableDefaultEventTracking
                        >
                          Login
                        </Link>
                        <Link
                          target="_blank"
                          button="tertiary"
                          href="https://servicecentre.latitudefinancial.com.au/register"
                          data-trackid="payment-options-service-center-register-link"
                          css="margin: 0 8px; flex: 1 1 50%;"
                          disableDefaultEventTracking
                        >
                          Register
                        </Link>
                      </PaymentOptionButtons>
                    </PaymentOptionsColumn>
                    <PaymentOptionsColumn>
                      <PaymentOptionHeading>
                        To make a payment
                      </PaymentOptionHeading>
                      <PaymentOptionList>
                        <PaymentOptionListItem>
                          <Strong>Login to the Latitude Service Centre</Strong>
                        </PaymentOptionListItem>
                        <PaymentOptionListItem>
                          <Strong>Click ‘Pay My Account’</Strong>
                          <Text fontSize="14px">
                            Then enter your bank details.
                          </Text>
                        </PaymentOptionListItem>
                      </PaymentOptionList>
                      <PaymentOptionHeading>Registering</PaymentOptionHeading>
                      <Text fontSize="14px">
                        Registering is easy and only takes a few minutes, just
                        make sure you have your card or 16-digit account number
                        on hand.
                      </Text>
                    </PaymentOptionsColumn>
                    <PaymentOptionsColumn>
                      <Text fontSize="14px">
                        <Strong>Note:</Strong> Please be aware that payments can
                        take 3 to 5 business days to clear.
                      </Text>
                      <PaymentOptionCallout>
                        <Strong>No Fees</Strong>
                        <Text fontSize="14px">
                          We do not charge a fee for payments made via the
                          Latitude Service Centre.
                        </Text>
                      </PaymentOptionCallout>
                    </PaymentOptionsColumn>
                  </React.Fragment>
                )
              },
              {
                id: 'bpay',
                title: 'BPAY',
                titleHtml:
                  'BPAY<sup style="font-size: 0.75em; top: -0.5em;">&reg;</sup>',
                content: (
                  <React.Fragment>
                    <PaymentOptionsColumn>
                      <PaymentOptionIcon>
                        <SvgInline name="payment-option-bpay" />
                      </PaymentOptionIcon>
                      <PaymentOptionTitle>
                        BPAY<sup>®</sup>
                      </PaymentOptionTitle>
                      <PaymentOptionDescription>
                        Available 24 hours a day, seven days a week.
                      </PaymentOptionDescription>
                    </PaymentOptionsColumn>
                    <PaymentOptionsColumn>
                      <PaymentOptionHeading>
                        To make a payment
                      </PaymentOptionHeading>
                      <PaymentOptionList>
                        <PaymentOptionListItem>
                          <Strong>Quote the Biller code</Strong>
                          <Text fontSize="14px">
                            Latitude Gem Visa Biller Code (154799)
                          </Text>
                        </PaymentOptionListItem>
                        <PaymentOptionListItem>
                          <Strong>Your account number</Strong>
                        </PaymentOptionListItem>
                        <PaymentOptionListItem>
                          <Strong>The amount you wish to pay</Strong>
                        </PaymentOptionListItem>
                      </PaymentOptionList>
                    </PaymentOptionsColumn>
                    <PaymentOptionsColumn>
                      <Text fontSize="14px">
                        <Strong>Note:</Strong> If you’re paying by BPAY on your
                        due date, you must complete the transaction before your
                        bank’s cut off time, otherwise the transaction may be
                        processed on the following day. Some BPAY payments can
                        take up to three days to appear on your account.
                      </Text>
                      <PaymentOptionCallout>
                        <Strong>Fees</Strong>
                        <Text fontSize="14px">
                          A payment handling fee of $1.95 applies for all
                          payments made via BPAY<sup>&reg;</sup> to pay your
                          account.
                        </Text>
                      </PaymentOptionCallout>
                    </PaymentOptionsColumn>
                  </React.Fragment>
                )
              },
              {
                id: 'direct-debit',
                title: 'Direct Debit',
                titleHtml: 'Direct Debit',
                content: (
                  <>
                    <PaymentOptionsColumn>
                      <PaymentOptionIcon>
                        <SvgInline name="payment-option-direct-debit" />
                      </PaymentOptionIcon>
                      <PaymentOptionTitle>Direct Debit</PaymentOptionTitle>
                      <PaymentOptionDescription>
                        Easily make recurring repayments.
                      </PaymentOptionDescription>
                    </PaymentOptionsColumn>
                    <PaymentOptionsColumn longColumn>
                      <Text>
                        The easiest (and quickest) way to pay off your credit
                        card is with a <strong> monthly direct debit</strong>.
                      </Text>
                      <br />
                      <Text>
                        You can choose whether to pay a set amount, the monthly
                        minimum or the full closing balance, and on your due
                        date each month your payment will automatically happen!
                      </Text>
                      <br />
                      <Text>
                        <Link href="https://latitudefs.zendesk.com/hc/en-au/articles/6027991389329-Direct-Debit-Cards">
                          Click here to find out more about setting up a direct
                          debit.
                        </Link>
                      </Text>
                    </PaymentOptionsColumn>
                  </>
                )
              }
            ]}
            // tabContentMinHeight="385px"
            css={`
              && {
                .payment-options-tabs__content {
                  padding: 32px;
                }
              }
            `}
          />
        </Section>

        <Section
          heading="Still looking for other options? Explore our other credit cards"
          id="other-cards-section"
          css={`
            && {
              @media (min-width: ${BREAKPOINT.LG}) {
                padding-bottom: 72px;
              }
            }
          `}
        >
        <FeatureCardList cardList = {cardData}/>
        </Section>
        <DigitalWallets
          heading="Digital wallets"
          subheading="You can pay any way on your phone or wearable tech device with just a tap."
        />

        <BrandedCallout
          line1={
            <React.Fragment>
              <span>For FAQs, useful information and quick and easy forms</span>
              <br />
            </React.Fragment>
          }
          cta={
            <React.Fragment>
              <p>
                <Link
                  button={BUTTON_STYLE.SECONDARY_INVERSE}
                  href="https://latitudefs.zendesk.com/hc/en-au"
                  trackId="callout-visit-our-help-centre"
                  trackEventData={{ location: "We're here to help" }}
                >
                  Visit our Help Centre
                </Link>
              </p>
            </React.Fragment>
          }
        />

        <ImportantInformation
          data={{
            content: {
              importantInformationSectionOne: [
                'Visa and the Visa brand are registered trademarks of Visa International.',
                '<sup id="note-1">1</sup>To take advantage of up to 55 days interest free on everyday credit card purchases, you need to pay the full closing balance (excluding un-expiring 0% Interest Payment Plans) on each statement of account by the applicable due date.',
                '<sup id="note-2">2</sup>Excludes long-term Interest Free payment plans. Other exclusions, T&Cs, card fee & other charges apply. Limited to new and approved Latitude Gem Visa applicants only. Limited to one offer per applicant. To qualify for $200 credit back, you must be approved and fulfil on the terms of the offer by spending $1,000 or more each month on everyday purchases for 3 consecutive months from the date of approval. The $200 credit will be applied within 60 days of meeting the spend criteria. Offer ends 15 Dec 2024. Latitude may vary, extend or withdraw this offer at any time without notice. Everyday purchases do not include cash advances, cash equivalent transactions, card fees or charges, credits, refunds, reimbursements, Long term Interest Free plan(s), interest and balance transfers into an account. Transactions must be fully processed and any pending transactions at the end of the period will not count towards the spend criteria for that period. The account must be open and not in default of the credit contract when the credit is applied.',
                'Apple, the Apple logo, Apple Pay, Apple Watch, Face ID, iPad, iPhone, Safari, and Touch ID are trademarks of Apple Inc., and App Store is a service mark of Apple Inc., registered in the U.S. and other countries.'
              ],
              importantInformationSectionTwo: [
                'Samsung and Samsung Pay are trademarks or registered trademarks of Samsung Electronics Co., Ltd.',
                'Garmin, the Garmin logo, and the Garmin delta are trademarks of Garmin Ltd. or its subsidiaries and are registered in one or more countries, including the U.S. Garmin Pay is a trademark of Garmin Ltd. or its subsidiaries.',
                'Google Pay, Android, the Google Pay Logo, Google Play and the Google Play logo are trademarks of Google LLC.',
                '&reg; Registered to BPAY Pty Ltd ABN 69 079 137 518'
              ]
            }
          }}
          sectionOneColummClasses="col-10 offset-1 col-lg-5 offset-lg-1"
          sectionTwoColummClasses="col-10 offset-1 col-lg-5 offset-lg-0"
          {...state?.importantInfoData?.[0]}
        />
      </main>
    </Layout>
  );
}

export default CreditCardsPage;
