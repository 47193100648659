import React, { useContext, useEffect, useState } from 'react';
import { PageContext } from '@/context/PageContext';

import { Metadata } from '@latitude/metadata';
import Section from '@latitude/section';
import { ALIGN, BUTTON_STYLE, COLOR } from '@latitude/core/utils/constants';
import { ListNumbered } from '@latitude/list-numbered';
import { Heading4 } from '@latitude/heading';

import Layout from '@/components/layout';
import PageHeaderSection from '@/components/PageLayout/PageHeader';
import HeroBanner from '@/components/lab-components/HeroBanner';
import { SITE_URL } from '@/utils/constants';

import BalanceTransferFaqSection from './_balance-transfer-faq';
import BalanceTransferInfoSection from '../_balance-transfer-info';
import imgPageHero from './images/pay_img.webp';
import imgOffer from '../../../images/credit-cards/promo-balance-transfer.webp';
import DegreesCardMicrositeHeader from './_low-master-microsite-header';
import LowRateMicrositeHeader from './_low-rate-header';
import { getUrlFromEnv } from '@/utils/getUrlFromEnvUtil';
import { ImportantInformation } from '@latitude/important-information';

const HOW_TO_APPLY_STEPS = [
  'Visit Latitude Service Centre by clicking the Log in button in the top right corner or open the Latitude App (please ensure you download the latest version)',
  'Log in using your Latitude ID.',
  'Select the Balance Transfer tab and fill in the form.',
  "If you don't have a Latitude ID, simply register from the login page and follow the steps above.",
  "<div>For more information on Terms and Conditions <a href='https://assets.latitudefinancial.com/legals/terms-conditions/balance-transfer-termsconds.pdf' target='_blank'>click here</a>.</div>"
];
const IMPORTANT_INFO = [
  "<sup id='note-star'>*</sup>Balance transfer applies to eligible customer's Australian non-Latitude credit cards only. Balance transfer amounts commencing from $500 or more, up to 85% of your Latitude Low Rate Mastercard credit card credit limit. Monthly payments required (exact amounts specified in your statement). Paying only the minimum monthly payment may not pay out the balance transfer amount before the end of the promotional period. If there is an outstanding balance after the promotional period, interest will be charged at the cash annual percentage rate, currently 29.99% (subject to change). A once-off balance transfer fee of 3% applies to the amount being transferred. This will be charged to your Latitude Low Rate Mastercard credit card account when the balance transfer is processed.",
  'New customers only and must be approved from 20 November 2024 to 28 February 2025. We reserve the right to close or vary this offer at any time.',
  'Latitude Credit Card General Terms and Conditions set out the T&Cs of the Latitude Low Rate Mastercard credit card. Credit provided by Latitude Finance Australia ABN 42 008 583 588 Australian Credit Licence number 392145.'
];

function BalanceTransferPage({ location }) {
  const contentfulPageData = useContext(PageContext);
  const state = contentfulPageData?.updatedBody || {};
  const balanceTransferTitle = "Get 0% for 18 months on balance transfers*"

  // set defaults
    const promoCodeBalanceTransfer = "DFGVHJUY";
    const sourceCodeBalanceTransfer = "IDAAA";
    /** - END - */
    
    const baseApplyURL = getUrlFromEnv('low-rate-credit-card');
    const [promoApplyURLBalanceTransfer, setpromoApplyURLBalanceTransfer] = useState(baseApplyURL);
    
    useEffect(() => {
      const urlParams = new URLSearchParams(window.location.search);
      const passedPromoCode = urlParams.get('promo_code');
      const passedSourceCode = urlParams.get('source_code');
  
      // Retrieve from localStorage if promoCode is not present in URL
      const sourceCode = passedSourceCode || localStorage.getItem('bt_source_code');
  
      // Update localstorage only if a new code has been passed in to override previous value
      if (passedSourceCode) {
        localStorage.setItem('bt_source_code', passedSourceCode);
      };
      
      // Construct the CTAs for code passing
      const updatedURLBalanceTransfer = new URL(promoApplyURLBalanceTransfer);
      updatedURLBalanceTransfer.searchParams.set('promo_code', passedPromoCode || promoCodeBalanceTransfer);
      updatedURLBalanceTransfer.searchParams.set('source_code', sourceCode || sourceCodeBalanceTransfer);
      setpromoApplyURLBalanceTransfer(updatedURLBalanceTransfer.toString());
    }, []);
  
    //Manually append the dynamic promo url to the contentful
    if (state) {
      const slides = state.heroBannerData?.[0].slides;
  
      if (slides) {
        slides.forEach(slide => {
          switch (slide.name) {
            case balanceTransferTitle:
              slide.buttons[0].url = promoApplyURLBalanceTransfer
              break;
          }
        })
      }
    } 
  

  return (
    <Layout location={location}>
      <main
        className="navigation-spacer"
        css={`
          margin-top: 0 !important;
          background-color: #f8f8f8;
          .microsite-header {
            z-index: 2000 !important;
          }

          div[class^='MicrositeHeader__SecondBar'].microsite-header__nav-wrap {
            margin-top: 8px;
          }
          div[class^='lfs-pageheader-item']{
            padding: 40px 0;
          }  
        `}
      >
        <Metadata
          title={'Balance Transfer | Low Rate'}
          description={'Balance transfer'}
          keywords="balance transfer"
          canonical={`${SITE_URL}${location.pathname}`}
        />
        <LowRateMicrositeHeader />

        {state?.heroBannerData?.[0] ? (
          <HeroBanner {...state?.heroBannerData[0]} />
        ) : (
          <PageHeaderSection
            title={
              <>
                Get 0% for 18 months on balance transfers<sup>*</sup>
              </>
            }
            subTitle={
              <>
                3% balance transfer fee applies.T&Cs apply.
                <br />
              </>
            }
            pageImage={imgPageHero}
            pageImageAlt="0% Balance Transfer"
              buttonProps={{
                button: BUTTON_STYLE.PRIMARY,
                href: "https://cards.latitudefinancial.com/low-rate",
                trackId: "low-rate-balance-transfer",
                btnText: 'Apply now'
              }}
              children={
                <small>
                New customers only, must be approved by 28 Feb 2025. T&Cs, card fee and other charges apply
                </small>
              }
          />
        )}

        <Section id="how-to-apply">
          <Heading4 align={ALIGN.CENTER} color={COLOR.BLACK}>
            How to apply
          </Heading4>
          <div
            css={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              justifyContent: 'space-evenly',
              margin: '10pt auto 10pt auto',
              padding: '5pt'
            }}
          >
            <div css={{ width: '350px', height: 'auto' }}>
              <img
                css={{ width: '100%' }}
                src={imgOffer}
                alt="Balance Transfer Offer"
              />
            </div>
            <div
              css={{
                maxWidth: '500px',
                padding: '0 10pt'
              }}
            >
              <ListNumbered
                data={HOW_TO_APPLY_STEPS}
                viewStyle="compact"
                counterBgColor={COLOR.BLUE_DEEP}
                counterVerticalAlign
                separator="none"
              />
            </div>
          </div>
        </Section>

        <BalanceTransferFaqSection anchor="faqs" />
        {state?.importantInfoData?.[0] ? (
          <ImportantInformation
          {...state?.importantInfoData?.[0]}
        />
        ) : (
          <BalanceTransferInfoSection
          anchor="important-Info"
          data={IMPORTANT_INFO}
        />
        )}
        
      </main>
    </Layout>
  );
}

export default BalanceTransferPage;
