import React, { Fragment, useState } from 'react';
import styled from 'styled-components';
import { Link } from '@latitude/link';
import { Tel } from '@latitude/tel';
import { Box } from '../Box/Box';
import RadioBoxes from '../RadioBoxes/RadioBoxes';
import Modal from '../Modal/Modal';
import SvgInline from '../SvgInline/SvgInline';
import { Text, Bold } from '../Text';
import {
  COLOR,
  ALIGN,
  MARGIN,
  BREAKPOINT,
  LOAN_PAY_FREQUENCY,
  PL_TEL,
  CALCULATOR_TRACKING
} from '../../utils/constants';
import { directTrackLink } from '../../utils/analyticsUtil';
import {
  customLinkNames,
  getCustomLinkEventName
} from './PersonalLoanCalculator.analytics';

const StyledSvg = styled(SvgInline)`
  width: 20px;
  height: 20px;
  position: relative;
  top: 5px;
  margin-left: 5px;
`;

const StyledBox = styled(Box)`
  margin: ${MARGIN.M16} ${MARGIN.M24} ${MARGIN.M24};

  @media (min-width: ${BREAKPOINT.LG}) {
    margin: 0 ${MARGIN.M48} ${MARGIN.M24} ${MARGIN.M48};
  }
`;

const repaymentPeriodText = {
  [LOAN_PAY_FREQUENCY.WEEKLY]: 'week',
  [LOAN_PAY_FREQUENCY.FORTNIGHTLY]: 'fortnight',
  [LOAN_PAY_FREQUENCY.MONTHLY]: 'month'
};

const ExtraRepayments = ({
  salesMode,
  repaymentPeriod,
  extraRepayments,
  setExtraRepayments,
  broker,
  loans1
}) => {
  const [showModal, setShowModal] = useState(false);

  const handleExtraRepaymentOnChange = e => {
    const newExtraValue = e.target.value;
    setExtraRepayments(parseInt(newExtraValue));

    // trigger analytics call
    !salesMode &&
      directTrackLink(
        true,
        'o',
        getCustomLinkEventName(
          customLinkNames.EXTRA_PAYMENT_CHANGE,
          newExtraValue
        )
      );
  };

  const content=(
    <Fragment>
      <Text>
        <Bold>Would {broker ? 'my client' : 'I'} be locked into paying more each month?</Bold>
      </Text>
      <p>
        Absolutely not. {broker ? 'They' : 'You'} can revert to
        {broker ? ' their' : ' your'} minimum repayment at any
        time.
      </p>
      <Text>
        <Bold>What if {broker ? 'my client only wants' : 'I only want'} to pay extra occasionally?</Bold>
      </Text>
      <p>
        At Latitude, we offer a range of flexible payment options. So, if
        {broker ? ' they' : ' you'}&apos;d like to pick and choose when
        {broker ? ' they' : ' you'} pay extra, {broker ? ' they' : ' you'} can do
        so by using BPAY® or Australia Post. Call us on{' '}
        <Tel no={broker ? '1800 035 902' : PL_TEL} /> to find out more. Please note, using BPAY® or
        Australia Post does include a payment handling fee of $1.50.
      </p>
      <Text>
        <Bold>What if {broker ? 'my client' : 'I'} repay{broker ? 's their' : ' my'} loan
          super fast?</Bold>
      </Text>
      <p>
        That&apos;s ok too, but just be aware that if {broker ? ' they ' : ' you '}
        pay off {broker ? ' their' : ' your'} loan before
        {broker ? ' they' : ' you'}&apos;re halfway through
        {broker ? ' their' : ' your'} original term,
        {broker ? ' they' : ' you'} will be charged an early
        termination fee of $300. Call us on{' '}
        <Tel no={broker ? '1800 035 902' : PL_TEL} /> to find out more.
      </p>
    </Fragment>
  );
  const contentLoans1=(
    <Fragment>
      <Text>
        <Bold>Would {broker ? 'my client' : 'I'} be locked into paying more each month?</Bold>
      </Text>
      <p>
        Absolutely not. {broker ? 'They' : 'You'} can revert to
        {broker ? ' their' : ' your'} minimum repayment at any
        time.
      </p>
      <Text>
        <Bold>What if {broker ? 'my client only wants' : 'I only want'} to pay extra occasionally?</Bold>
      </Text>
      <p>
        At Latitude, we offer a range of flexible payment options. So, if
        {broker ? ' they' : ' you'}&apos;d like to pick and choose when
        {broker ? ' they' : ' you'} pay extra, {broker ? ' they' : ' you'} can do
        so by using BPAY®. Call us on{' '}
        <Tel no={broker ? '1800 035 902' : PL_TEL} /> to find out more. Please note, using BPAY®
        includes a payment handling fee of $1.50.
      </p>
      <Text>
        <Bold>What if {broker ? 'my client' : 'I'} repay{broker ? 's their' : ' my'} loan
          super fast?</Bold>
      </Text>
      <p>
        That&apos;s ok too, but just be aware that if {broker ? ' they ' : ' you '}
        pay off {broker ? ' their' : ' your'} loan {broker ? ' they' : ' you'}
        will be charged $500 up until the last 3 months of
        {broker ? ' their' : ' your'} loan. There is no early termination fee
        for Variable loans. Call us on{' '}
        <Tel no={broker ? '1800 035 902' : PL_TEL} /> to find out more.
      </p>
    </Fragment>
  );

  const modal=(
    <StyledBox>
        <RadioBoxes
          inverted
          labelText={
            <Text align={ALIGN.CENTER} color={COLOR.WHITE}>
              <Bold>
                Pay it faster every {repaymentPeriodText[repaymentPeriod]} to
                save
              </Bold>
              <Link
                href="#"
                noStyle
                onClick={e => {
                  setShowModal(true);
                  e.preventDefault();
                }}
                trackEventData={{
                  category: CALCULATOR_TRACKING.CATEGORY,
                  action: CALCULATOR_TRACKING.ACTION,
                  label: 'i'
                }}
              >
                <StyledSvg name="info-block-outline" fillWhite />
              </Link>
            </Text>
          }
          name="extraRepayments"
          onChange={handleExtraRepaymentOnChange}
          values={[
            { value: 0, text: 'No Extra' },
            { value: 25, text: '+$25' },
            { value: 50, text: '+$50' },
            { value: 100, text: '+$100' }
          ]}
          selectedValue={extraRepayments}
          equalWidths
        />
      </StyledBox>
  );
  const modalLoans1=(
    <></>
  );
  return (
    <Box>

      <StyledBox>
        <Text>
          { salesMode ? modal: modalLoans1 }
        </Text>
      </StyledBox>
      <Modal
        isOpen={showModal}
        onRequestClose={() => setShowModal(false)}
        title="Pay it faster"
        content={
          loans1 ? contentLoans1 : content
        }
        ariaHideApp={false}
      />
    </Box>
  );
};

export default ExtraRepayments;
