import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`I acknowledge that:`}</p>
    <ul>
      <li parentName="ul">{`the information I have provided is true and correct; and`}</li>
      <li parentName="ul">{`Latitude relies on this information to determine whether or not to agree to vary my credit contract/s.`}</li>
    </ul>
    <p>{`I declare that:`}</p>
    <ul>
      <li parentName="ul">{`the income & liabilities stated represent the total income & liabilities at the time of this declaration; and`}</li>
      <li parentName="ul">{`the estimate provided by me of my living expenses is true and correct to the best of my knowledge at the time of this application.`}</li>
    </ul>
    <p>{`I permit Latitude to take whatever additional reasonable steps to process and determine my application, without any further notice.`}</p>
    <p>{`If my application is accepted:`}</p>
    <p>{`I agree that Latitude is authorised to process the arrangement on my account(s) with the information I have provided in respect to length and payment amount of the hardship care arrangement;`}</p>
    <p>{`Latitude will send me a variation notice confirming the agreed changes to my credit contract(s); and`}</p>
    <p>{`Missed and/or late payments will result in the hardship care arrangement being revoked and the original Terms & Conditions of the credit contract being reinstated on my account(s)`}</p>
    <h4>{`ADDITIONAL ACKNOWLEDGEMENTS AND AGREEMENTS WHEN APPLYING FOR HARDSHIP CARE FOR A PERSONAL LOAN OR AUTO LOAN PROVIDED BY LATITUDE:`}</h4>
    <p>{`If Latitude agrees to extend the term of the loan and/or reduce the interest rate, I agree that I will be able to maintain the agreed amended contractual monthly obligations. As a result of the change to the credit contract, all current overdue payments will no longer be treated as`}</p>
    <p>{`I agree that additional interest may be payable to Latitude as a result of this variation. If I have existing credit insurance on this loan, I will contact the insurer, as the cover may not be valid for loan extensions or variations.`}</p>
    <p>{`Latitude means the credit provider in relation to your Credit Card, Personal Loan or Motor Loan and LatitudePay.`}</p>
    <p>{`Credit Cards: Credit facility provided by Latitude Finance Australia ABN 42 008 583 588, Australian Credit Licence number 392145.`}</p>
    <p>{`Personal Loans: Credit facility provided by Latitude Personal Finance Pty Ltd 54 008 443 810, Australian Credit Licence number 392163.`}</p>
    <p>{`Motor Loans: Credit facility provided by Latitude Automotive Financial Services 80 004 187 419, trading as Latitude Financial Service, Australian Credit Licence number 392178.`}</p>
    <p>{`LatitudePay: Credit facility provided by LatitudePay Pty Ltd ABN 23 633 528 873.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      