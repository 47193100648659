// @flow

import React, { useState, useEffect, useContext } from 'react';
import classnames from 'classnames';
import 'styled-components/macro';
import smoothscroll from 'smoothscroll-polyfill';
import {
  AnalyticsContext,
  AnalyticsLocationConsumer,
  trackEvent
} from 'latitude-analytics';
import { COLOR } from '@latitude/core/utils/constants';
import AccordionButton from './AccordionButton';
import AccordionContent from './AccordionContent';
import './_accordion.scss';

const getBackgroundCssClass = color => {
  switch (color) {
    case 'white':
      return 'bg-white';
    case 'blue-light':
      return 'bg-brand-lighter';
    case 'grey-light':
      return 'bg-lightest';
    case 'grey':
      return 'bg-grey-light';
    case 'grey-dark':
      return 'bg-grey-dark';
    default:
      return 'bg-white';
  }
};

const Accordion = props => {
  const {
    borderedChildren,
    className,
    style,
    contentBgColor = COLOR.WHITE,
    category,
    fgColor = COLOR.BLACK,
    highlightActive,
    isChild,
    items,
    titleBgColor = COLOR.GREY12,
    minimumMargin,
    groupActiveIndex,
    setGroupActiveIndex,
    hasSmoothScroll
  } = props;
  const defaultActiveIndex = items.findIndex(item => item.initiallyActive);
  const [analytics] = useContext(AnalyticsContext);
  const [activeIndex, setActiveIndex] = useState(defaultActiveIndex); // set the active index, because on load the index is "-1" nothing is set
  const hasTwoLevels = items.some(item => Array.isArray(item.content)); // boolean: whether acc has 2 levels

  useEffect(() => {
    smoothscroll.polyfill();
  }, []);

  /*
   * Provides support for multi accordion interaction
   * Expands/collapses group of accordions based on active index
   */

  useEffect(() => {
    if (groupActiveIndex !== undefined) {
      setActiveIndex(groupActiveIndex);
    }
  }, [groupActiveIndex]);

  const handleOnClick = (item, event, location) => {
    /*
     * If there is a category prop (passed in from AccordionSideBar or Nested Accordion),
     * then use it for location else, use context provided location.
     */

    if (category) {
      location = category;
    }

    trackEvent(analytics, {
      category: 'accordion',
      action: 'in-page-navigation',
      label: item?.trackEventData?.label,
      location
    });
  };

  return (
    <div
      className={classnames(
        'lab-accordion',
        hasTwoLevels && 'accordion--two-level',
        isChild && 'accordion--child',
        isChild && borderedChildren && 'accordion--bordered',
        highlightActive && 'accordion--hl-active',
        minimumMargin && 'accordion--min-margin',
        className
      )}
    >
      {items.map((item, index) => {
        const isOpen = activeIndex === index;
        const buttonId = `${item.id}-button`;
        const isParent = Array.isArray(item.content);
        const showHighlightedActive = highlightActive && isOpen;

        return (
          <AnalyticsLocationConsumer key={index}>
            {(location) => {
              return (
                <div
                  data-id={item.id}
                  className={classnames('accordion__item', isOpen && 'is-open')}
                  key={item.id}
                  css={`
                    svg {
                      color: ${fgColor};
                    }
                  `}
                >
                  <AccordionButton
                    buttonid={buttonId}
                    fgColor={(showHighlightedActive && 'white') || fgColor}
                    onClick={() => {
                      const active = isOpen ? -1 : index;
                      setActiveIndex(active);
                      setGroupActiveIndex && setGroupActiveIndex(active);
                      !isOpen && handleOnClick(item, event, location);
                    }}
                    className={classnames(
                      (showHighlightedActive && 'bg-brand-bright') ||
                        getBackgroundCssClass(titleBgColor),
                      !isChild && 'font-weight-bold'
                    )}
                    itemId={item.id}
                    isOpen={isOpen}
                    iconName={item.iconName}
                    title={item.title}
                    activeIndex={activeIndex}
                    isSticky={!isChild}
                    hasSmoothScroll={hasSmoothScroll}
                  />
                  <AccordionContent
                    id={item.id}
                    buttonId={buttonId}
                    isParent={isParent}
                    isChild={isChild}
                    isOpen={isOpen}
                    contentBgColor={getBackgroundCssClass(contentBgColor)}
                    content={item.content}
                    activeIndex={activeIndex}
                    style={style}
                    nestedAccordion={
                      <Accordion
                        isChild
                        titleBgColor="white"
                        contentBgColor="white"
                        fgColor={fgColor}
                        borderedChildren={borderedChildren}
                        items={item.content}
                      />
                    }
                    hasSmoothScroll={hasSmoothScroll}
                  />
                </div>
              );
            }}
          </AnalyticsLocationConsumer>
        );
      })}
    </div>
  );
};

Accordion.defaultProps = {
  fgColor: 'grey-dark'
};

export default Accordion;
