/* eslint-disable global-require */
import React from 'react';

import Section from '@latitude/section';
import { Promo } from '@latitude/promo';
import { Link } from '@latitude/link';
import { BUTTON_STYLE } from '@latitude/core/utils/constants';

import imgDontHaveCardPromo from './images/promo-dont-have-card-yet.webp';

function DontHaveACardSection({ anchor }) {
  return (
    <Section
      id={anchor}
      css={`
        && {
          background-color: #fff;
        }
      `}
    >
      <Promo
        title="Don't have a card yet?"
        description={
          <>
            With no international or currency conversion fees on purchases, your
            Latitude 28&deg; Global Platinum Mastercard credit card helps you
            pay like a local, online or overseas.
          </>
        }
        frameImage={
          <img src={imgDontHaveCardPromo} alt="Don't have a card yet?" />
        }
        verticalAlignContent
        frameAlignment="left"
        frameBorderColor="#0061EE"
        ctaButtons={
          <Link
            href="https://cards.latitudefinancial.com/28degrees"
            button={BUTTON_STYLE.SECONDARY}
            trackId="dont-have-card-apply-now"
            css={{ width: 'fit-content' }}
          >
            Apply Now
          </Link>
        }
      />
    </Section>
  );
}
export default DontHaveACardSection;
