import React, { useContext } from 'react';
import { PageContext } from '@/context/PageContext';
import { Box } from '@latitude/box';
import { SecondaryHero } from '@latitude/hero';
import { AccordionSidebar } from '@latitude/accordion';
import { Metadata } from '@latitude/metadata';
import Layout from '@/components/layout';
import { SITE_URL } from '@/utils/constants';

import faqs from './_faqs';
import GemMicrositeHeader from './_gem-microsite-header';

const Faqs = ({ location }) => {
  const contentfulPageData = useContext(PageContext);
  const state = contentfulPageData?.updatedBody || {};

  return (
    <Layout location={location} hasSecNav>
      <main
        className="navigation-spacer"
        css={`
          margin-top: 100px !important;
          background-color: #f8f8f8;
          .microsite-header {
            z-index: 2000 !important;
          }

          div[class^='MicrositeHeader__SecondBar'].microsite-header__nav-wrap {
            margin-top: 8px;
          }
        `}
      >
        <Metadata
          title={`FAQs | Gem Visa`}
          description={`Learn all the handy hints, tips, and answers for everything you wanted to know about your Gem Visa credit card`}
          keywords="FAQ"
          canonical={`${SITE_URL}${location.pathname}`}
        />
        <GemMicrositeHeader data={state.secondaryNavData} />

        <SecondaryHero
          style={'padding-top: 80px;'}
          heading="Frequently Asked Questions"
          subHeading="Got a burning question? You may find your answer here."
        />
        <Box.Section>
          <AccordionSidebar data={faqs.items} />
        </Box.Section>
      </main>
    </Layout>
  )
};

export default Faqs;
