/* eslint-disable import/no-dynamic-require */
import * as React from 'react';

const renderLoginModalImage = imageFileName => {
  if (imageFileName && imageFileName.length) {
    return (
      <img
        alt="credit-card"
        className="img-fluid"
        src={require(`../../images/credit-cards/${imageFileName}`)}
      />
    );
  }
  return null;
};

export default renderLoginModalImage;
