import styled from 'styled-components';
import { BREAKPOINT } from '@latitude/core/utils/constants';

const VSpacing = styled.div`
  display: block;
  visibility: hidden;
  height: ${({ heights }) => heights.DEFAULT || 'auto'};

  @media (min-width: ${BREAKPOINT.MD}) {
    height: ${({ heights }) => heights[BREAKPOINT.MD] || 'auto'};
  }
  @media (min-width: ${BREAKPOINT.LG}) {
    height: ${({ heights }) => heights[BREAKPOINT.LG] || 'auto'};
  }
  @media (min-width: ${BREAKPOINT.XL}) {
    height: ${({ heights }) => heights[BREAKPOINT.XL] || 'auto'};
  }
`;

export default VSpacing;
