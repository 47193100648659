import React, { useContext, useState, useEffect } from 'react';
import ReactHTMLParser from 'react-html-parser';
import { marked } from 'marked';

import Section from '@latitude/section';
import { Link } from '@latitude/link';
import { BUTTON_STYLE } from '@latitude/core/utils/constants';
import SvgInline from '@latitude/svg-inline';
import Select from '@/components/Select/Select';
import { PageContext } from '@/context/PageContext';

import INTEREST_FREE_OFFERS from './data/interest-free-offers.json';
import { CONTENTFUL_COMPONENTS } from '@/utils/constants';
import { useSetState } from '@/utils/hooks';

const ANY_VALUE = '*';

function FindAnOfferSection({ id }) {
  const [category, setCategory] = useState(ANY_VALUE);
  const [isOnline, setIsOnline] = useState(true);
  const [isInStore, setIsInStore] = useState(true);

  const CATEGORIES = [
    { value: ANY_VALUE, text: 'Category' },
    {
      value: 'Appliances & Electrical',
      text: 'Appliances & Electrical'
    },
    { value: 'Bedding & Furniture', text: 'Bedding & Furniture' },
    { value: 'Carpets & Flooring', text: 'Carpets & Flooring' },
    // { value: 'Health & Wellbeing', text: 'Health & Wellbeing' },
    { value: 'Jewellery & Fashion', text: 'Jewellery & Fashion' },
    {
      value: 'Outdoor & Sporting Goods',
      text: 'Outdoor & Sporting Goods'
    },
    // { value: 'Solar', text: 'Solar' },
    {
      value: 'Travel & Experiences',
      text: 'Travel & Experiences'
    },
    { value: 'Other', text: 'Other' }
  ];

  /** Contentful Data */
  const contentfulPageData = useContext(PageContext);

  const [state, setState] = useSetState({
    contentfulEnabled: false,
    findAnOfferData: [{
      heading: "Find an offer",
      offers: INTEREST_FREE_OFFERS,
      allCategories: CATEGORIES
    }]
  });

  useEffect(() => {
    let [findAnOfferComponents] = [[]];
    contentfulPageData?.body?.map(item => {
      switch (item.contentType.sys.id) {
        case CONTENTFUL_COMPONENTS.FIND_AN_OFFER_SECTION:
          const updatedItem = {
            ...item,
            heading: item.title,
            allCategories: getContentfulcategories(item.offers)
          };
          findAnOfferComponents = [...findAnOfferComponents, updatedItem];
          break;
        default:
          break;
      }
    });

    setState({ 
      contentfulEnabled: findAnOfferComponents.length > 0,
      findAnOfferData: findAnOfferComponents.length > 0 ? findAnOfferComponents : state.findAnOfferData,
    });
  }, [contentfulPageData]);
  /** - END - */

  function getContentfulcategories(offers) {
    let contentfulCategories = [];
    contentfulCategories.push({
      value: ANY_VALUE,
      text: 'Category'
    });

    const categories = offers.reduce((acc, cv) => {
      cv.merchant.categories.forEach(c => {
        acc.add(
          JSON.stringify({
            value: c.name,
            text: c.name
          })
        );
      });
      return acc;
    }, new Set());

    if (categories) {
      contentfulCategories = [
        ...contentfulCategories,
        ...Array.from(categories).map(c => JSON.parse(c))
      ];
    }

    return contentfulCategories;
  }

  const onChangeCategory = event => {
    setCategory(event.target.value);
  };

  const onChangeIsOnline = () => {
    setIsOnline(!isOnline);
  };

  const onChangeIsInStore = () => {
    setIsInStore(!isInStore);
  };

  function filterOffers(offer) {
    if (category && category != ANY_VALUE) {
      const categories = offer?.merchant?.categories || [];
      if (
        (state.contentfulEnabled &&
          !categories.some(cat => cat.name === category)) ||
        (!state.contentfulEnabled && !categories.includes(category))
      ) {
        return false;
      }
    }

    const locations = offer?.merchant?.locations || [];
    if (!locationMatch(locations)) {
      return false;
    }
    return true;
  }

  function locationMatch(locations) {
    if (isOnline) {
      if (locations.includes('Online')) {
        return true;
      }
    }
    if (isInStore) {
      if (locations.includes('In-Store')) {
        return true;
      }
    }
    return false;
  }

  function offerMerchantOrder(a, b) {
    if (!state.contentfulEnabled) {
      // Compare merchants by numerical order
      if (a.merchant.order > b.merchant.order) return 1;
      if (a.merchant.order < b.merchant.order) return -1;
      // Compare merchant names alphabetically
      if (a.merchant.name > b.merchant.name) return 1;
      if (a.merchant.name < b.merchant.name) return -1;
      // Otherwise, they're equal
      return 0;
    } else {
      return 0;
    }
  }

  return (
    <Section id={id} heading={state.findAnOfferData[0].heading}>
      <div css={{ textAlign: 'center' }}>
        <div
          css={{
            display: 'flex',
            flexDirection: 'row',
            margin: '0 auto 0 auto',
            justifyContent: 'center'
          }}
        >
          <div css={{ flexBasis: '250px', padding: '10pt' }}>
            <Select
              values={state.findAnOfferData[0].allCategories}
              selectedValue={category}
              onChange={onChangeCategory}
            />
          </div>
          <div css={{ flexBasis: '200px', padding: '10pt' }}>
            <label
              css={{
                backgroundColor: '#ffffff',
                padding: '10px',
                borderRadius: '10px'
              }}
            >
              <input
                type="checkbox"
                checked={isOnline}
                onChange={onChangeIsOnline}
              />
              Online
            </label>
          </div>
          <div css={{ flexBasis: '200px', padding: '10pt' }}>
            <label
              css={{
                backgroundColor: '#ffffff',
                padding: '10px',
                borderRadius: '10px'
              }}
            >
              <input
                type="checkbox"
                checked={isInStore}
                onChange={onChangeIsInStore}
              />
              In-store
            </label>
          </div>
        </div>
        <div
          css={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            margin: '0 auto 0 auto',
            justifyContent: 'center'
          }}
        >
          {state.findAnOfferData[0].offers
            ?.filter(filterOffers)
            .sort(offerMerchantOrder)
            .map(offer => {
              return (
                <div
                  key={offer?.merchant?.name + ':' + offer.title}
                  css={{
                    backgroundColor: '#ffffff',
                    flexBasis: '300px',
                    padding: '10pt',
                    margin: '10pt',
                    borderRadius: '8px',
                    boxShadow: '0px 2px 5px 0px #0000001A'
                  }}
                >
                  <div>
                    <img
                      src={
                        state.contentfulEnabled
                          ? offer?.merchant?.logo?.file?.url
                          : offer.merchant.logo
                      }
                      alt={offer?.merchant?.name}
                      loading="lazy"
                      css={{
                        height: '150px',
                        width: '200px',
                        objectFit: 'contain'
                      }}
                    />
                  </div>

                  <div
                    css={{
                      fontSize: '24px',
                      fontWeight: '600',
                      textAlign: 'left',
                      height: '80px'
                    }}
                  >
                    {offer.title}
                  </div>
                  <div
                    css={{
                      fontSize: '14px',
                      fontWeight: '300',
                      lineHeight: '16px',
                      textAlign: 'left',
                      height: '60px'
                    }}
                  >
                    {state.contentfulEnabled
                      ? offer?.termsConds &&
                        ReactHTMLParser(marked(offer.termsConds))
                      : ReactHTMLParser(offer.termsConds)}
                  </div>
                  <Link
                    href={offer?.link}
                    target="_blank"
                    rel="noreferrer noopener"
                    button={BUTTON_STYLE.SECONDARY}
                    css={{
                      margin: '20pt auto 0 auto',
                      width: 'fit-content',
                      fontSize: '13px'
                    }}
                    trackId={offer?.merchant?.name}
                    trackEventData={{
                      category: 'cta',
                      action: 'offer-link'
                    }}
                  >
                    {state.contentfulEnabled && offer?.linkTitle
                      ? offer.linkTitle
                      : offer?.merchant?.linkTitle
                      ? offer.merchant.linkTitle
                      : 'View on Partner website'}
                    &nbsp;
                    <SvgInline name="external-link" />
                  </Link>
                </div>
              );
            })}
        </div>
      </div>
    </Section>
  );
}

export default FindAnOfferSection;
