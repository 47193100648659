import React from 'react';

import { MicrositeHeader } from '@latitude/microsite-header';

const DATA = [
  {
    id: 'home',
    label: 'Home',
    trackId: 'nav-home',
    href: '/credit-cards/low-rate/'
  },
  {
    id: 'balance-transfer',
    label: 'Balance Transfer',
    trackId: 'nav-balance-transfer',
    href: '/credit-cards/low-rate/balance-transfer/'
  },
  {
    id: 'faqs',
    label: 'FAQs',
    trackId: 'nav-faqs',
    href: '/credit-cards/low-rate/faqs/'
  },
  {
    id: 'help',
    label: 'Help',
    trackId: 'nav-help',
    href: 'https://latitudefs.zendesk.com/hc/en-au/'
  }
];

function LowRateMicrositeHeader(props) {
  return (
    <div
      css={`
        background-color: #f8f8f8;

        .microsite-header {
          z-index: 2000 !important;
        }
        [class*="Linkstyled__StyledLink"][data-trackid="header-apply-now"] {
    display: none !important;
}
      `}
    >
      <MicrositeHeader
        data={DATA}
        microSiteName="Low Rate"
        ctaText=''
      />
    </div>
  );
}

export default LowRateMicrositeHeader;
