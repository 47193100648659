import React from 'react';

import Section from '@latitude/section';
import { Link } from '@latitude/link';
import Slider from '@latitude/slider';

const COLOUR_PALE_GREY = '#F2F2F2';

const LIFE_CANT_WAIT_ITEMS = [
  {
    title: 'Get behind the wheel faster',
    text:
      "Drive away in your new car with ease. We're here to help make it happen.",
    thumbnail: 'life-cant-wait/car-loan.webp',
    button: {
      label: 'Learn more',
      link: '/car-loan/'
    }
  },
  {
    title: 'Simplify your finances',
    text:
      'Take control and get back on track with the help of our debt consolidation loans.',
    thumbnail: 'life-cant-wait/debt-consolidation-loan.webp',
    button: {
      label: 'Learn more',
      link: '/debt-consolidation-loans/'
    }
  },
  {
    title: 'Renovate your castle',
    text:
      'Build that dream kitchen, or entertain on your new deck. Turn your house into a home.',
    thumbnail: 'life-cant-wait/home-renovation-loan.webp',
    button: {
      label: 'Learn more',
      link: '/home-renovation-loans/'
    }
  },
  {
    title: 'Get the medical care you need',
    text:
      'Focus on your wellbeing with financial support tailored to your health needs.',
    thumbnail: 'life-cant-wait/medical-loan.webp',
    button: {
      label: 'Learn more',
      link: '/medical-loan/'
    }
  },
  {
    title: 'Explore the open road',
    text:
      'Enjoy the freedom of the open road with a loan for your new caravan or RV.',
    thumbnail: 'life-cant-wait/caravan-loan.webp',
    button: {
      label: 'Learn more',
      link: '/caravan-loan/'
    }
  },
  {
    title: 'Take that dream holiday',
    text:
      'Experience the trip of a lifetime now and pay it off over time with ease.',
    thumbnail: 'life-cant-wait/travel-loan.webp',
    button: {
      label: 'Learn more',
      link: '/travel-loans/'
    }
  },
  {
    title: 'Start your boating adventure',
    text:
      'Set sail on your next adventure with a loan designed for your boat purchase.',
    thumbnail: 'life-cant-wait/boat-loan.webp',
    button: {
      label: 'Learn more',
      link: '/boat-loan/'
    }
  }
];
const LIFE_CANT_WAIT_RESPONSIVE = [
  {
    breakpoint: 1020,
    settings: {
      slidesToShow: 4,
      slidesToScroll: 1,
      infinite: false
    }
  },
  {
    breakpoint: 992,
    settings: {
      slidesToShow: 3,
      slidesToScroll: 1,
      infinite: false
    }
  },
  {
    breakpoint: 768,
    settings: {
      slidesToShow: 2,
      slidesToScroll: 1,
      infinite: false
    }
  },
  {
    breakpoint: 460,
    settings: {
      slidesToShow: 1,
      slidesToScroll: 1,
      infinite: false
    }
  }
];

// eslint-disable-next-line arrow-body-style
function LifeCantWaitSection({ id, heading }) {
  return (
    <Section
      id={id}
      heading={heading}
      css={`
        background-color: ${COLOUR_PALE_GREY};
        && h2.text-center {
          text-align: left !important;
          font-size: 32px;
          line-height: 36px;
        }
      `}
    >
      <Slider
        className="lfs-explore-loans"
        slidesToShow={4}
        slidesToScroll={1}
        autoplay={false}
        responsiveSettings={LIFE_CANT_WAIT_RESPONSIVE}
      >
        {LIFE_CANT_WAIT_ITEMS.map((item, index) => {
          return (
            <div
              key={index}
              css={{
                border: '0 solid #fff',
                borderRadius: '16px',
                padding: '0',
                backgroundColor: '#fff'
              }}
            >
              <div css={{ width: '100%', height: 'auto' }}>
                <img
                  src={require(`Images/${item.thumbnail}`).default}
                  alt={item.title}
                  css={{ objectFit: 'cover', borderRadius: '16px 16px 0 0' }}
                />
              </div>
              <div
                css={{
                  padding: '10px 10px 0 10px',
                  textAlign: 'left',
                  fontWeight: 'bold',
                  fontSize: '20px',
                  height: '70px'
                }}
              >
                {item.title}
              </div>
              <div
                css={{
                  padding: '0 10px 10px 10px',
                  textAlign: 'left',
                  fontSize: '16px',
                  height: '110px'
                }}
              >
                {item.text}
              </div>
              <div
                css={{
                  padding: '0 10px 10px 10px',
                  textAlign: 'left',
                  fontSize: '16px',
                  height: '50px'
                }}
              >
                <Link
                  href={item.button.link}
                  trackEventData={{
                    category: 'loan-types',
                    action: 'learn-more',
                    label: item.title,
                    location: heading
                  }}
                  trackId="life-cant-wait"
                >
                  {item.button.label}
                </Link>
              </div>
            </div>
          );
        })}
      </Slider>
    </Section>
  );
}
export default LifeCantWaitSection;
