/* eslint-disable */

import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import ReactHtmlParser from 'react-html-parser';

import Chevron from '../Chevron/Chevron';

import './_select-list.scss';

class SelectList extends React.Component {
  constructor(props) {
    super(props);
    this.selectItem = this.selectItem.bind(this);
    this.state = { selectedItem: 0 };
  }

  handleClick = (index, e) => {
    e.preventDefault();
    this.selectItem(index);

    // handle onClick event from parent
    if (this.props.onClick) {
      this.props.onClick(index, e);
    }
  };

  selectItem(index) {
    this.setState({ selectedItem: index });
  }

  render() {
    return (
      <ul
        className={classnames('select-list"', this.props.className)}
        style={this.props.style}
      >
        {this.props.data.map((item, index) => {
          const isActive = this.state.selectedItem === index;
          const className =
            'select-list__item ' +
            (isActive ? 'select-list__item--active ' : ' ');
          return (
            <li
              className={className}
              key={index}
              onClick={e => this.handleClick(index, e)}
            >
              {isActive && (
                <Chevron direction="right" className="select-list__chevron" />
              )}
              {ReactHtmlParser(item)}
            </li>
          );
        })}
      </ul>
    );
  }
}

/* eslint-disable react/forbid-prop-types */
SelectList.propTypes = {
  data: PropTypes.array.isRequired
};

export default SelectList;
