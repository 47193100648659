import styled from 'styled-components';
import { Accordion } from '@latitude/accordion';
import {
  BREAKPOINT,
  COLOR,
  GUTTER,
  MARGIN,
  PADDING
} from '@latitude/core/utils/constants';
import {
  COL_WIDTH_MOBILE,
  COL_WIDTH_TABLET,
  COL_WIDTH_DESKTOP
} from './AccordionComparisonShared';

/**
 * Constants
 */
const SLIDER_DOT_COLOR = COLOR.GREY65;
const SLIDER_DOT_COLOR_ACTIVE = COLOR.BLUE;

const LOANBOX_ICON_SIZE = 96;

/**
 * Section and layout
 */
export const StyledSection = styled.section`
  margin: 0 auto;
  padding: 0;

  @media (min-width: ${BREAKPOINT.LG}) {
    max-width: 1100px;
    margin-top: ${MARGIN.M40};
  }

  // override LFS slider
  .lfs-slider.slick-slider {
    // move above Lframe
    z-index: 1;

    // list container
    .slick-list {
      padding: ${PADDING.P4} 0;

      // track
      .slick-track {
        // slide container
        .slick-slide {
          // tablet and up, show all columns, no slider
          @media (min-width: ${BREAKPOINT.MD}) {
            margin-right: ${GUTTER.G15};
            &:last-child {
              margin-right: 0;
            }
            // apply tablet column width
            width: ${COL_WIDTH_TABLET}px;
            max-width: ${COL_WIDTH_TABLET}px;
          }
          @media (min-width: ${BREAKPOINT.LG}) {
            margin-right: ${GUTTER.G30};
            // apply desktop column width
            width: ${COL_WIDTH_DESKTOP}px;
            max-width: ${COL_WIDTH_DESKTOP}px;
          }

          // div wrapper
          & > div {
            text-align: center;
          }

          // slide item
          .lfs-slide.slide {
            position: relative;
            background: ${COLOR.WHITE};
            box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.07);
            min-height: 200px;
            margin: 0 auto;
            padding: 0;
            text-align: center;

            // apply mobile single column width
            width: ${COL_WIDTH_MOBILE}px;
            max-width: ${COL_WIDTH_MOBILE}px;
            // apply tablet column width
            @media (min-width: ${BREAKPOINT.MD}) {
              width: ${COL_WIDTH_TABLET}px;
              max-width: ${COL_WIDTH_TABLET}px;
            }
            // apply desktop column width
            @media (min-width: ${BREAKPOINT.LG}) {
              width: ${COL_WIDTH_DESKTOP}px;
              max-width: ${COL_WIDTH_DESKTOP}px;
            }

            // override default Slider styles for Inline SVG
            svg.loan-box__icon {
              display: block;
              width: ${LOANBOX_ICON_SIZE}px;
              height: ${LOANBOX_ICON_SIZE}px;
              margin: 18px auto 2px;
              fill: none;
              stroke: none;
            }

            // override default Slider styles for heading 4
            h4.loan-box__heading {
              font-size: 20px;
              line-height: 1.2;
              margin-bottom: 24px;

              @media (min-width: ${BREAKPOINT.LG}) {
                font-size: 24px;
                line-height: 1.33;
              }
            }

            // override default Slider styles for accordion
            .accordion {
              button.link-block {
                .link-block__chevron {
                  svg {
                    display: block;
                    width: 14px;
                    height: 8px;
                    margin: 0;
                    fill: none;
                    stroke: none;
                  }
                }
              }
            }
          }
        }
      }
    }

    // controls
    &.slick-initialized .slick-dots {
      top: auto;
      bottom: auto;
      li {
        button:before {
          margin: 4px;
          background: ${SLIDER_DOT_COLOR};
          border: none;
        }
        button:hover,
        button:focus {
          &:before {
            opacity: 0.45;
          }
        }

        &.slick-active {
          button:before {
            opacity: 1;
            background: ${SLIDER_DOT_COLOR_ACTIVE};
          }
        }
      }
    }
  }
`;

/**
 * Loan boxes styles
 */

export const StyledLoanBoxContainer = styled.div`
  overflow: hidden;

  // make sure consistent height across slides
  height: auto;
  @media (min-width: ${BREAKPOINT.MD}) {
    height: auto;
  }
  @media (min-width: ${BREAKPOINT.LG}) {
    height: auto;
  }

  .rates-box.brand {
    width: calc(100% - ${GUTTER.G30});
    max-width: calc(100% - ${GUTTER.G30});
  }
`;

/**
 * Loan CTA styles
 */
export const StyledLoanCTAContainer = styled.div`
  overflow: hidden;
  padding: ${PADDING.P8} ${PADDING.P16} ${PADDING.P8} ${PADDING.P16};

  @media (min-width: ${BREAKPOINT.MD}) {
    padding-left: ${PADDING.P48};
    padding-right: ${PADDING.P48};
  }
`;

/**
 * Loan accordion styles
 */
export const StyledAccordion = styled(Accordion)`
  text-align: left;

  .accordion__item {
    border-bottom: 1px solid ${COLOR.GREY10};
    &:first-child {
      border-top: 1px solid ${COLOR.GREY10};
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
`;
