/* eslint-disable react/no-danger */
import React, { useEffect, useContext } from 'react';
import Helmet from 'react-helmet';
import styled from 'styled-components';
import { AnalyticsLocationProvider } from 'latitude-analytics';
import { FeaturesSlider } from '@latitude/features-slider';
import { Link } from '@latitude/link';
import { Flex, Box } from '@latitude/box';
import { BrandedCallout, Callout, CalloutTitle } from '@latitude/callout';
import { Heading3 } from '@latitude/heading';
import { Text } from '@latitude/text';
import Image from '@latitude/image';
import MediaEnquiry from './_media-enquiry';
import Layout from '../../components/layout';
import StickyNavigationBranded from '../../components/StickyNavigation/StickyNavigationBranded';
import Promo, { PromoSection } from '../../components/Promo/Promo';
import PageData from '../../data/pages/about-us.json';
import {
  BREAKPOINT,
  COLOR,
  BUTTON_STYLE,
  PADDING
} from '@latitude/core/utils/constants';
import imageInvestorRelations from '../../images/about-us-investor-relations.png';
import MobileAppInstallPrompts from '@latitude/mobile-app-install-prompt/MobileAppInstallPrompt';
import demoImage1 from '../../images/placeholder-1.jpg';
import demoImage2 from '../../images/placeholder-2.jpg';
import demoImage3 from '../../images/placeholder-3.jpg';
import heroImage from '../../images/hero/about-us.webp';

import PromoBanner from '@/components/lab-components/PromoBanner';
import HeroBanner from '@/components/lab-components/HeroBanner';
import { useSetState } from '@/utils/hooks';
import { PageContext } from '@/context/PageContext';
import { CONTENTFUL_COMPONENTS } from '@/utils/constants';
import PageHeaderSection from '@/components/PageLayout/PageHeader';

const AboutUsPage = props => {
  /** Contentful Data */
  const contentfulPageData = useContext(PageContext);
  const state = contentfulPageData?.updatedBody || {};

  const featureSliderData = [
    {
      heading: 'Our Company',
      featureCards: PageData.content.ourCompany
    }
  ];
  /** - END - */

  return (
    <Layout location={props.location}>
      <MobileAppInstallPrompts />
      <main
        className="navigation-spacer"
        css={`
          background-color: ${COLOR.GREY6};
        `}
      >
        <Helmet>
          <link
            rel="canonical"
            href={`https://www.latitudefinancial.com.au${PageData.path}`}
          />
          <title>{PageData.metaTitle}</title>
          <meta name="description" content={PageData.metaDesc} />
        </Helmet>
        {state?.heroBannerData?.[0] ? (
          <HeroBanner {...state?.heroBannerData[0]} />
        ) : (
          <PageHeaderSection
            title="About Us"
            subTitle="Latitude is a leading sales finance and consumer lending business that helps people shop and finance their lives"
            pageImage={heroImage}
          />
        )}

        <div
          className="d-none d-lg-block"
          css="position: relative; z-index: 10;"
        >
          <StickyNavigationBranded
            items={PageData.nav}
            offsetElem="[data-sticky-navigation-offset]"
            {...state?.inPageNavData?.[0]}
          />
        </div>

        <FeaturesSlider
          key={(state?.featureSliderData?.[0] || featureSliderData[0]).heading}
          id="our-company"
          heading={
            (state?.featureSliderData?.[0] || featureSliderData[0]).heading
          }
          subheading={
            (state?.featureSliderData?.[0] || featureSliderData[0]).description
          }
          whiteTiles
          data={
            (state?.featureSliderData?.[0] || featureSliderData[0]).featureCards
          }
          css={`
            h5 {
              text-align: left;
            }
            p {
              text-align: left;
              @media (min-width: ${BREAKPOINT.SM}) {
                height: 180px;
              }
              @media (min-width: ${BREAKPOINT.XL}) {
                height: 140px;
              }
            }
          `}
        />
        <Divider />

        <Callout className="bg-light">
          <div className="row justify-content-center">
            <div
              css={`
                h4.callout__title {
                  font-size: 24px;
                  @media (max-width: ${BREAKPOINT.XL}) {
                    margin-top: 5px;
                  }
                }
              `}
            >
              <CalloutTitle
                className="mx-4 text-dark"
                title="View all Latitude products"
              />
            </div>
            <div className="mx-4">
              <Link
                data-trackid="view-all-latitude-products__find-out-more"
                trackEventData={{
                  location: 'View all Latitude products'
                }}
                button={BUTTON_STYLE.SECONDARY}
                href="/"
              >
                Find out more
              </Link>
            </div>
          </div>
        </Callout>

        <Divider />

        <AnalyticsLocationProvider location={'Corporate Responsibility'}>
          <Box.Section
            backgroundColor={COLOR.GREY6}
            paddingTop={PADDING.P72}
            paddingBottom={PADDING.P56}
            id="corporate-responsibility"
          >
            <Heading>Corporate Responsibility</Heading>
            <Box
              css={`
                display: grid;
                gap: 24px;
                justify-items: center;

                @media (min-width: ${BREAKPOINT.LG}) {
                  grid-template-columns: repeat(3, 1fr);
                }
              `}
            >
              <Image
                src={demoImage1}
                title="Partners in the Community"
                href="/about-us/corporate-responsibility/#partners"
                alt="partners-in-the-community"
              />
              <Image
                src={demoImage2}
                title="Latitude Giving"
                href="/about-us/corporate-responsibility/#giving"
                alt="latitude-giving"
              />
              <Image
                src={demoImage3}
                title="Supporting Vulnerable Customers"
                href="/about-us/corporate-responsibility/#supporting"
                alt="supporting-vulnerable-customers"
              />
            </Box>
          </Box.Section>
        </AnalyticsLocationProvider>

        {state?.promoBannerData?.[0] ? (
          <PromoBanner {...state?.promoBannerData[0]} />
        ) : (
          <PromoSection
            heading="Investor Relations"
            id="investor-relations"
            css={`
              h2 {
                color: ${COLOR.BLACK};
                font-size: 32px;
              }
              h3 {
                font-size: 32px;
              }
            `}
          >
            <Promo
              title="Financial information for shareholders"
              description="For the latest information visit our Investor Centre."
              frameImage={
                <img src={imageInvestorRelations} alt="Investor Relations" />
              }
              frameAlignment="left"
              frameBackgroundColor="#CDE9FE"
              frameBorderColor={COLOR.GREY16}
              ctaButtons={
                <Flex
                  css={`
                    justify-content: center;
                    @media (min-width: ${BREAKPOINT.LG}) {
                      justify-content: start;
                    }
                  `}
                >
                  <Link
                    button={BUTTON_STYLE.SECONDARY}
                    href="https://investors.latitudefinancial.com.au/investor-centre/"
                    data-trackid="investor-relations__find-out-more"
                    width="156px"
                    trackEventData={{
                      location: 'Investor Relations'
                    }}
                  >
                    Find out more
                  </Link>
                </Flex>
              }
              verticalAlignContent={false}
            />
          </PromoSection>
        )}

        <Anchor id="careers" />
        <BrandedCallout
          hasConfettiBackground
          moreVerticalPadding
          heading="Join the team"
          line1="We hire the best people aligned with our values, we build strong and lasting partnerships and we put the customer at the heart of everything."
          cta={
            <Link
              href="/careers/"
              variant="secondary"
              css="margin-top: 16px;"
              button={BUTTON_STYLE.SECONDARY}
              data-trackid="callout-join-the-team"
              trackEventData={{
                location: 'Join the team'
              }}
            >
              Join the team
            </Link>
          }
        />

        <Anchor id="news" />
        <InTheMedia />
        <MediaEnquiry />
      </main>
    </Layout>
  );
};

const Divider = () => (
  <hr
    css={`
      width: 80%;
      height: 1px;
      margin: 1px auto;
      background-color: ${COLOR.GREY16};
      color: ${COLOR.GREY16};
      border: 0 none;
    `}
  />
);

const Anchor = styled.div`
  width: 0;
  height: 0;
  position: relative;
`;

const InTheMedia = () => (
  <OuterBox>
    <InnerBox isResponsive>
      <Heading>In the Media</Heading>
      <Text className="text-center text-dark">
        Visit our news room to view our latest media releases and stories.
      </Text>
    </InnerBox>
    <Link
      data-trackid="in-the-media__view-all"
      trackEventData={{
        location: 'In the Media'
      }}
      className="mx-auto"
      href="/about-us/news-room/"
      width="160px"
      button={BUTTON_STYLE.TERTIARY}
      css={`
        margin-top: 48px;
      `}
    >
      View All
    </Link>
  </OuterBox>
);

const OuterBox = styled(Box)`
  background-color: ${COLOR.GREY6}
  padding-top: 36px;
  padding-bottom: 36px;
  @media (min-width: ${BREAKPOINT.LG}) {
    padding-top: 50px;
    padding-bottom: 50px;
  }
`;

const InnerBox = styled(Box)`
  margin: 0 auto;
`;

const Heading = styled(Heading3)`
  text-align: center;
  color: ${COLOR.BLACK};
  font-size: 24px;
  @media (min-width: ${BREAKPOINT.LG}) {
    font-size: 32px;
  }
`;

export default AboutUsPage;
