import classnames from 'classnames';
import React from 'react';
import Fragment from 'react-dot-fragment';
import ReactHTMLParser from 'react-html-parser';
import { COLOR } from '../../utils/constants';
import './_table.scss';

const parseContent = content => {
  if (React.isValidElement(content)) {
    return content;
  }
  if (typeof content === 'string') {
    return ReactHTMLParser(content);
  }
  return content;
};

const Table = props => {
  if (!props.data) return null;

  const getCellandHeaderClasses = columnIndex =>
    classnames(
      props.leftAlignColumns.includes(columnIndex) && 'text-left',
      props.rightAlignColumns.includes(columnIndex) && 'text-right',
      props.hiddenOnMobileColumns.includes(columnIndex) &&
        'd-none d-md-table-cell',
      props.brightColumns.includes(columnIndex) &&
        'text--blue-bright font-weight-bold',
      props.darkTextColumns.includes(columnIndex) && 'text--grey-dark',
      props.whiteColumns.includes(columnIndex) && 'text--invert'
    );

  const getCellandHeaderStyles = (columnIndex, numberOfColumns) => {
    let width = '';
    if (props.columnWidths) {
      width = props.columnWidths[columnIndex];
    } else if (props.equalWidthColumns) {
      width = `${(1 / numberOfColumns) * 100}%`;
    }
    return {
      width
    };
  };

  return (
    <table
      className={classnames(
        'table',
        props.className,
        props.borderWhite && 'table--border-white',
        props.lastRowGap && 'table--last-row-gap',
        props.sectioned && 'table--sectioned',
        props.layoutFixed && 'table--fixed'
      )}
    >
      {props.header.length > 0 && (
        <thead className="table__head">
          <tr className="table__row-header">
            {props.header.map((headerCell, columnIndex) => (
              <th
                key={columnIndex}
                className={classnames(
                  'table__header',
                  getCellandHeaderClasses(columnIndex)
                )}
                style={getCellandHeaderStyles(columnIndex, props.header.length)}
              >
                {headerCell}
              </th>
            ))}
          </tr>
        </thead>
      )}
      <tbody className="table__body">
        {props.data.map((section, sectionIndex) => (
          <Fragment key={sectionIndex}>
            {props.sectioned && sectionIndex !== props.data.length - 0 && (
              <tr className="table__row">
                <td colSpan="100%">
                  <hr className="table__rule" />
                </td>
              </tr>
            )}
            {section.map((dataRow, rowIndex) => {
              return (
                <tr
                  className={classnames(
                    'table__row',
                    props.highlightRows.includes(rowIndex) &&
                      'table__row--highlighted',
                    props.noBorderTop &&
                      rowIndex === 0 &&
                      'table__row--no-border-top',
                    !props.noBorderBottom &&
                      rowIndex === section.length - 1 &&
                      'table__row--border-bottom',
                    props.borderless && 'table__row--borderless'
                  )}
                  style={
                    props.highlightRows.includes(rowIndex)
                      ? { backgroundColor: props.highlightColor }
                      : {}
                  }
                  key={rowIndex}
                >
                  {dataRow.map((cell, columnIndex) => (
                    // if only one td exist, then span it accross rest of the divs
                    <td
                      colSpan={dataRow.length === 1 ? 2 : 0}
                      key={columnIndex}
                      className={classnames(
                        'table__cell',
                        props.brightRows.includes(rowIndex) &&
                          'text--blue-bright',
                        (props.boldRows.includes(rowIndex) ||
                          props.boldColumns.includes(columnIndex)) &&
                          'font-weight-bold',
                        getCellandHeaderClasses(columnIndex),
                        props.cellClassNames
                      )}
                      style={getCellandHeaderStyles(
                        columnIndex,
                        dataRow.length
                      )}
                      // eslint-disable-next-line react/no-danger
                    >
                      {parseContent(cell)}
                    </td>
                  ))}
                </tr>
              );
            })}
          </Fragment>
        ))}
        {props.sectioned && (
          <tr className="table__row">
            <td colSpan="100%">
              <hr className="table__rule" />
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

Table.defaultProps = {
  header: [],
  boldColumns: [],
  boldRows: [],
  highlightRows: [],
  whiteColumns: [],
  highlightColor: COLOR.WHITE,
  hiddenOnMobileColumns: [],
  leftAlignColumns: [],
  rightAlignColumns: [],
  brightColumns: [],
  brightRows: [],
  darkTextColumns: []
};

export default Table;
