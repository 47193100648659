import React from 'react';
import styled from 'styled-components';
import { Box } from '../Box/Box';
import Table from '../Table/Table';
import CurrencyInput from '../CurrencyInput/CurrencyInput';
import Select from '../Select/Select';
import { PADDING, BREAKPOINT, COLOR } from '../../utils/constants';
import { Text } from '../Text';
import { formatCurrency } from './utilities';

const DisplayOnDesktop = styled(Box)`
  @media (max-width: ${BREAKPOINT.MD}) {
    display: none;
  }
`;

const DisplayOnMobile = styled(Box)`
  @media (min-width: ${BREAKPOINT.MD}) {
    display: none;
  }
  tr.table__row {
    td {
      p {
        font-weight: 500;
        color: ${COLOR.GREY75};
      }
      label {
        font-size: 13px;
        font-weight: 500;
        color: ${COLOR.GREY75};
        margin-top: -8px;
      }
    }
  }
  tr.table__row--border-bottom {
    td {
      position: relative;
      padding-bottom: 16px;
      &:after {
        content: '';
        display: block;
        width: calc(100% - 32px);
        height: 1px;
        left: 16px;
        bottom: 0;
        background-color: #e5e5e5;
        position: absolute;
      }
    }
    + tr.table__row {
      td {
        padding-top: 16px;
      }
    }
    &:last-child {
      td:after {
        display: none;
      }
    }
  }
`;

const DesktopView = ({ stateFields }) => {
  const tableData = stateFields.map(field => [
    <Text color={COLOR.GREY75}>{field.label}</Text>,
    <FrequencySelect field={field} />,
    <CurrencyInput
      color={COLOR.GREY75}
      id={`${field.name}-input`}
      prefix="$"
      name={`${field.name}-input`}
      min="0"
      step="1000"
      onValueChange={values => field.setValue(values.value)}
      value={field.value}
      disableDefaultEventTracking
    />,
    <Text color={COLOR.GREY75}>{`$${formatCurrency(field.rowTotal)}`}</Text>
  ]);

  return (
    <DisplayOnDesktop paddingBottom={PADDING.P16}>
      <Table
        className="table--fixed"
        borderless
        columnWidths={['32%', '25%', '20%', '23%']}
        leftAlignColumns={[1, 2, 3]}
        header={['', 'Frequency', 'Amount', 'Annual Amount']}
        data={[tableData]}
        boldColumns={[0, 3]}
      />
    </DisplayOnDesktop>
  );
};

const MobileView = ({ stateFields }) => {
  const tableData = stateFields.map(field => [
    [<Text color={COLOR.GREY75}>{field.label}</Text>],
    [<FrequencySelect label="Frequency" field={field} />],
    [
      <CurrencyInput
        label="Amount"
        color={COLOR.GREY75}
        id={`${field.name}-input`}
        prefix="$"
        name={`${field.name}-input`}
        min="0"
        step="1000"
        onValueChange={values => field.setValue(values.value)}
        value={field.value}
      />
    ],
    [`Annual total: $${formatCurrency(field.rowTotal)}`]
  ]);

  return (
    <DisplayOnMobile paddingBottom={PADDING.P16}>
      <Table
        className="table--fixed"
        borderless
        columnWidths={['100%']}
        data={tableData}
      />
    </DisplayOnMobile>
  );
};

const FrequencySelect = ({ field, label }) => (
  <Select
    id={`${field.name}-select`}
    color={COLOR.GREY75}
    label={label}
    name={`${field.name}-select`}
    values={[
      { value: '52', text: 'Weekly' },
      { value: '26', text: 'Fortnightly' },
      { value: '12', text: 'Monthly' },
      { value: '4', text: 'Quarterly' },
      { value: '1', text: 'Yearly' }
    ]}
    onChange={e => field.setFrequency(e.target.value)}
    selectedValue={field.frequency}
  />
);

const BudgetCategory = ({ stateFields, displayFrequency }) => {
  return (
    <>
      <DesktopView
        stateFields={stateFields}
        displayFrequency={displayFrequency}
      />
      <MobileView
        stateFields={stateFields}
        displayFrequency={displayFrequency}
      />
    </>
  );
};

export default BudgetCategory;
