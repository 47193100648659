/* eslint-disable global-require */
import React from 'react';
import styled from 'styled-components';
import { Heading4 } from '@latitude/heading';
import { Text } from '@latitude/text';
import { Box } from '@latitude/box';
import { Link } from '@latitude/link';
import {
  BREAKPOINT,
  COLOR,
  ALIGN,
  BUTTON_STYLE
} from '@latitude/core/utils/constants';

import backgroundImage from './images/bg-blue-confetti.svg';

function HaveAQuestionSection({ anchor, heading = 'Have a question?' }) {
  return (
    <BoxQuestion id={anchor}>
      <Heading4
        align={ALIGN.CENTER}
        color={COLOR.BLACK}
        css="margin-bottom:8px;"
      >
        {heading}
      </Heading4>
      <Text align={ALIGN.CENTER} color={COLOR.BLACK} css="margin-bottom:24px;">
        Check our FAQs page to get answers.
      </Text>
      <Link
        button={BUTTON_STYLE.SECONDARY}
        href="/credit-cards/28-degrees/faqs/"
        trackId="have-a-question-banner__find-out-more-button"
        disableDefaultEventTracking
        css={`
          display: inline-block;
        `}
      >
        Find out more
      </Link>
    </BoxQuestion>
  );
}

const BoxQuestion = styled(Box)`
  padding: 40px 0;
  background-image: url(${backgroundImage});
  background-size: cover;
  text-align: center;
  @media (min-width: ${BREAKPOINT.LG}) {
    padding: 72px 0;
  }
`;

export default HaveAQuestionSection;
