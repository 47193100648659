import * as React from 'react';
import styled from 'styled-components';
import { AnalyticsContext } from 'latitude-analytics';
import { Link } from '@latitude/link';
import { BUTTON_STYLE } from '@latitude/core/utils/constants';
import { getUrlFromEnv } from '../../utils/getUrlFromEnvUtil';
import MarketingBanner from '../MarketingBanner/MarketingBanner';
import { FONT_FAMILY, FONT_WEIGHT } from '../../utils/constants';
import navBannerCreditGvBg from '../../images/nav-banner-credit-bg.svg';
import cargBg28d from '../../images/28d-card-bg.png';
import navBannerCredit2x from '../../images/nav-banner-credit.webp';

const CC_APPLY_URL = getUrlFromEnv('funnel-start');

/**
 * Renders banner content based on provided id
 *
 * @param {string} bannerId - identifier of banner so will return matching banner element
 * @param {number} key - react key id
 * @returns {React.element} rendered banner content
 */
export const renderBannerContent = (bannerId, key) => {
  if (bannerId && bannerId.trim().length !== 0) {
    return <Banner id={bannerId} key={key} />;
  }
  return null;
};

/*
 * Banner class. Renders a custom marketing banner with the given ID inside the header
 */

const Banner = props => {
  const [analytics] = React.useContext(AnalyticsContext);
  if (!props) return null;
  switch (props.id) {
    case 'credit-card':
      return (
        <></>
        // <StyledCreditCard
        //   navPromo
        //   titleTag="h3"
        //   title="No annual fee and free Global Data Roaming"
        //   desc={<strong>28° Global is the only way to travel</strong>}
        //   image={
        //     <img
        //       className="marketing-banner__img"
        //       src={navBannerCredit2x}
        //       style={{ maxHeight: '209px' }} // ie11 bug
        //       alt=""
        //     />
        //   }
        //   primaryCta={
        //     <Link
        //       href="https://www.28degreescard.com.au/"
        //       trackId="nav-banner-28d-more"
        //       button={BUTTON_STYLE.TERTIARY_INVERSE}
        //       small
        //       trackProductId={['CCAU28D']}
        //       rel="noopener noreferrer"
        //     >
        //       Find out more
        //     </Link>
        //   }
        //   secondaryCta={
        //     <Link
        //       href={`${CC_APPLY_URL}28degrees/`}
        //       trackId="nav-banner-28d-apply"
        //       button={BUTTON_STYLE.PRIMARY}
        //       small
        //       trackProductId={['CCAU28D']}
        //       rel="noopener noreferrer"
        //     >
        //       Apply now
        //     </Link>
        //   }
        //   bgImg={cargBg28d}
        //   bgImgLarge={cargBg28d}
        // />
      );
    default:
      return null;
  }
};

const StyledCreditCard = styled(MarketingBanner)`
  background-image: url(${navBannerCreditGvBg}) !important;
  background-size: cover;
  background-position: center;

  .marketing-banner__title {
    font-family: ${FONT_FAMILY.TITLE};
    font-weight: ${FONT_WEIGHT.SEMI_BOLD};
  }

  .marketing-banner__image {
    order: -2;
  }

  .marketing-banner__desc {
    margin-bottom: 26px !important;
  }

  .marketing-banner__bg-img,
  .marketing-banner__bg-img-large {
    display: none !important;
  }

  .marketing-banner__cta a {
    max-width: 120px;
    min-width: auto;
    height: 40px;
    font-size: 15px;
    margin: 0 auto;
  }
`;
export default Banner;
