import Image from '@latitude/image';
import { List, ListItem } from '@latitude/list';
import Slide from '@latitude/slide';
import Slider from '@latitude/slider';
import React from 'react';


const data = [
  {
    image: {
      alt: 'Skiers riding chairlift down mountain in Alyeska, Alaska',
      src: '/travel-inspiration/images/alyeska-alaska.jpg'
    },
    title: 'Alyeska, Alaska, USA',
    rating: '3.5/5',
    season: 'December to April',
    link: {
      text: 'www.alyeskaresort.com',
      href: 'http://www.alyeskaresort.com/'
    },
    description:
      'Fantastic for beginners and intermediates, Big White lives up to its name with 1,147 hectares (2,655 acres) of terrain. Around a third of the ski area is groomed, giving you plenty of space and skiing variety.'
  },
  {
    image: {
      alt: 'Aspen gondala',
      src: '/travel-inspiration/images/aspen-USA.jpg'
    },
    title: 'Aspen, Colorado, USA',
    rating: '4/5',
    season: 'November to April',
    link: {
      text: 'www.aspensnowmass.com',
      href: 'https://www.aspensnowmass.com/'
    },
    description:
      "In Aspen, you're likely to sight film stars, billionaires and Olympic athletes on its celebrity slopes. America’s most famous ski resort is perfect for the more serious skiers."
  },
  {
    image: {
      alt: 'Natural mountain amphitheatre covered in snow in Banff, Canada',
      src: '/travel-inspiration/images/banff-canada-by-J@M-LaFlamme.jpg'
    },
    title: 'Banff, Canada',
    rating: '4/5',
    season: 'November to May',
    link: {
      text: 'www.banff.com/banff-skiing',
      href: 'https://www.banff.com/banff-skiing/'
    },
    description:
      'Incredible views, huge variety and a vibrant atmosphere, Banff is one of Canada’s most popular ski resorts. Located in a World Heritage-listed area, it’s home to three resorts and is fantastic for all levels of experience.'
  },
  {
    image: {
      alt: 'Ski run lined with trees in Big White, Canada',
      src: '/travel-inspiration/images/big-white-canada.jpg'
    },
    title: 'Big White, Canada',
    rating: '4.5/5',
    season: 'November to April',
    link: {
      text: 'www.bigwhite.com/geo/ausnz',
      href: 'http://www.bigwhite.com/geo/ausnz/'
    },
    description:
      'Fantastic for beginners and intermediates, Big White lives up to its name with 1,147 hectares (2,655 acres) of terrain. Around a third of the ski area is groomed, giving you plenty of space and skiing variety.'
  },
  {
    image: {
      alt: 'Snow covered mountain in Chamonix, France',
      src: '/travel-inspiration/images/chamonix-france.jpg'
    },
    title: 'Chamonix, France',
    rating: '3.5/5',
    season: 'December to April',
    link: {
      text: 'www.chamonix.com/skis-areas,22,en.html',
      href: 'http://www.chamonix.com/skis-areas,22,en.html'
    },
    description:
      'The birthplace of extreme skiing lives up to its reputation. Nestled in the French Alps, it offers plenty of challenges for diehard adrenaline junkies and extreme sport enthusiasts!'
  },
  {
    image: {
      alt: 'Mountain ski village in Cortina, Italy',
      src: '/travel-inspiration/images/cortina-italy.jpg'
    },
    title: 'Cortina d’Ampezzo, Italy',
    rating: 'N/A',
    season: 'November to March',
    link: {
      text: 'www.dolomitisuperski.com/en/ski-area/cortina',
      href: 'http://www.dolomitisuperski.com/en/ski-area/cortina'
    },
    description:
      'Some say it’s the most beautiful ski resort in the world. One of the less crowded slopes in Europe, there are plenty of great runs and excellent facilities.'
  },
  {
    image: {
      alt: 'People skiing down run in Courchevel, France',
      src: '/travel-inspiration/images/Courcheval-France.jpg'
    },
    title: 'Courchevel, France',
    rating: '4/5',
    season: 'December to April',
    link: {
      text: 'www.courchevel.com/winter/en',
      href: 'http://www.courchevel.com/winter/en'
    },
    description:
      'A fantastic all-round resort, Courchevel offers huge variety across an expansive area. Courchevel is the largest resort in France’s Three Valleys area and gives you access to the whole 600km network with a special ski pass.'
  },
  {
    image: {
      alt: 'Snow drifts on mountain summit in Fernie, Canada',
      src: '/travel-inspiration/images/fernie-canada.jpg'
    },
    title: 'Fernie, Canada',
    rating: '4/5',
    season: 'December to April',
    link: {
      text: 'www.skifernie.com',
      href: 'http://skifernie.com/'
    },
    description:
      'Well loved but little known, this ski resort boasts some of the best terrain in Canada.'
  },
  {
    image: {
      alt: 'Snow covered mountain ski village in Mount Hotham, Australia',
      src: '/travel-inspiration/images/hotham-australia.jpg'
    },
    title: 'Mount Hotham, Australia',
    rating: '4/5',
    season: 'June to September',
    link: {
      text: 'www.mthotham.com.au',
      href: 'http://www.mthotham.com.au/'
    },
    description:
      'Australia’s most serious ski resort. Offering a wide variety of runs, Hotham caters best to intermediates and experts.'
  },
  {
    image: {
      alt: 'Jackson hole gondala',
      src: '/travel-inspiration/images/jackson-USA.jpg'
    },
    title: 'Jackson Hole, Wyoming, USA',
    rating: '4/5',
    season: 'November to April',
    link: {
      text: 'www.jacksonhole.com',
      href: 'http://www.jacksonhole.com/'
    },
    description:
      'The huge range of bowls, chutes and back-country terrain are perfect for experts who want to push their boundaries. Jackson Hole is one the birthplaces of extreme skiing in the United States and it certainly delivers.'
  },
  {
    image: {
      alt: 'Snow covered mountain ski village in Lech am Arlberg, Austria',
      src: '/travel-inspiration/images/lech-am-arlberg-austria.jpg'
    },
    title: 'Lech am Arlberg, Austria',
    rating: '4/5',
    season: 'December to April',
    link: {
      text: 'www.lechzuers.com/skiing',
      href: 'http://www.lechzuers.com/skiing'
    },
    description:
      'A playground for the rich and famous, Lech am Alberg is a sophisticated ski resort that caters more for beginners and intermediates.'
  },
  {
    image: {
      alt: 'Man snowboarding down mountain in Niseko, Japan',
      src: '/travel-inspiration/images/niseko-japan.jpg'
    },
    title: 'Niseko, Japan',
    rating: '4/5',
    season: 'December to April',
    link: {
      text: 'www.skijapan.com/resorts/niseko',
      href: 'http://www.skijapan.com/resorts/niseko/'
    },
    description:
      'A paradise for powder lovers. Hokkaido offers some of the world’s best skiing and boarding, with a fantastic resort and a huge variety of runs catering for all levels of experience.'
  },
  {
    image: {
      alt: 'Skiers riding chairlift in Norzawa Onsen, Japan',
      src: '/travel-inspiration/images/nozawa-japan.jpg'
    },
    title: 'Nozawa Onsen, Japan',
    rating: '5/5',
    season: 'December to March',
    link: {
      text: 'www.nozawaski.com/winter/en',
      href: 'http://www.nozawaski.com/winter/en/'
    },
    description:
      'The birthplace of skiing in Japan. One of the most popular ski resorts in the country, Nozawa Onsen offers a wonderful atmosphere and excellent terrain.'
  },
  {
    image: {
      alt: 'Snow covered village in Solden, Austria',
      src: '/travel-inspiration/images/solden-austria.jpg'
    },
    title: 'Sölden, Austria',
    rating: 'N/A',
    season: 'November to May',
    link: {
      text: 'www.soelden.com/ski-area-information',
      href: 'https://www.soelden.com/ski-area-information'
    },
    description:
      'This Austrian ski resort has been known as a premiere ski holiday destination for decades. While it boasts the odd celebrity guest and sleek perfect powder slopes that attract ski competitions, families have been taking advantage of its range of slopes for years.'
  },
  {
    image: {
      alt: 'Snow covered mountains in Squaw Valley, USA',
      src: '/travel-inspiration/images/squaw-USA.jpg'
    },
    title: 'Squaw Valley, California, USA',
    rating: '4.5/5',
    season: 'November to April',
    link: {
      text: 'www.squawalpine.com',
      href: 'http://squawalpine.com/'
    },
    description:
      'Squaw Valley is renowned for its off-piste skiing for the expert skier. Squaw Valley’s 3600 acres offers a sprawling European-style network of trails, peaks, vertical drops, bowls and slopes that vary from terrifying to tame.'
  },
  {
    image: {
      alt: 'Snow covered mountain in Telluride, USA',
      src: '/travel-inspiration/images/telluride-USA.jpg'
    },
    title: 'Telluride, Colorado, USA',
    rating: '5/5',
    season: 'November to April',
    link: {
      text: 'www.tellurideskiresort.com',
      href: 'http://www.tellurideskiresort.com/'
    },
    description:
      'Combining spectacular views and a relaxed atmosphere, Telluride is a much-loved, high-profile ski resort with excellent infrastructure and huge variety.'
  },
  {
    image: {
      alt: 'Man snowboarding down mountain in Verbier Switzerland',
      src:
        '/travel-inspiration/images/verbier-switzerland-photo-by-malte-karger.jpg'
    },
    title: 'Verbier, Switzerland',
    rating: '3.5/5',
    season: 'November to April',
    link: {
      text: 'www.verbinet.com',
      href: 'https://www.verbinet.com/'
    },
    description:
      'One of the largest and most-famous ski resorts in Switzerland. Suited more for intermediates and experts, this park has plenty to offer freestylers and off-piste skiers.'
  },
  {
    image: {
      alt: 'Skier coming down mountain in Wanaka, New Zealand',
      src: '/travel-inspiration/images/wanaka-new-zealand.jpg'
    },
    title: 'Wanaka, New Zealand',
    rating: '3.5/5',
    season: 'June to October',
    link: {
      text: 'www.lakewanaka.co.nz/new-zealand/skiing-snowboarding',
      href: 'http://www.lakewanaka.co.nz/new-zealand/skiing-snowboarding/'
    },
    description:
      'A fantastic ski town located in the famously picturesque New Zealand mountains. Wanaka offers plenty of terrain for all levels, with some great heli skiing nearby.'
  },
  {
    image: {
      alt: 'Snow covered mountain in Whistler Blackcomb, Canada',
      src: '/travel-inspiration/images/whistler-canada.jpg'
    },
    title: 'Whistler Blackcomb, Canada',
    rating: '5/5',
    season: 'November to May',
    link: {
      text: 'www.whistlerblackcomb.com',
      href: 'https://www.whistlerblackcomb.com/'
    },
    description:
      'Canada’s best-known ski destination is famous for a reason. Boasting nearly 8,200 acres of terrain and more than 200 marked trails, Whistler is perfect for skiing and snowboarding'
  },
  {
    image: {
      alt: 'Snow covered mountain village in Zermatt, Switzerland',
      src: '/travel-inspiration/images/zermatt-switzerland.jpg'
    },
    title: 'Zermatt, Switzerland',
    rating: '4/5',
    season: 'November to April',
    link: {
      text: 'www.zermatt.ch/en/Skiing',
      href: 'http://www.zermatt.ch/en/Skiing'
    },
    description:
      'Zermatt offers some of the best skiing in the world. Pitched high in Switzerland’s crown, this famous ski resort offers the best Europe has to offer.'
  }
];

export default () => (
  <div>
    <p>
      Why would you settle for anything less than the best ski resorts in the
      world? Our list of the world's best ski resorts covers everything you're
      looking for, whether it's black runs with regular fresh snowfall, or
      family-friendly slopes for those who love the idea of a ski holiday but
      don't know where to start.
    </p>
    <p>
      We sourced our ratings from Powerhounds.com in July 2016. They’re based on
      runs, snow, lifts, facilities, atmosphere, cost and more. Visit{' '}
      <a
        href="http://www.powderhounds.com/"
        target="_blank"
        rel="noopener noreferrer"
      >
        powderhounds.com
      </a>{' '}
      to find out more. Use our gallery slider below to see the top 20 resorts
      by continent.
    </p>
    <Slider>
      {data.map((slide, i) => (
        <Slide>
          <Image src={slide.image.src} alt={slide.image.alt} />
          <h5 className="mt-3">{slide.title}</h5>
          <List pointless>
            <ListItem>
              <strong>Rating: </strong>
              {slide.rating}
            </ListItem>
            <ListItem>
              <strong>Season: </strong>
              {slide.season}
            </ListItem>
            <ListItem>
              <a href={slide.link.href}>{slide.link.text}</a>
            </ListItem>
          </List>
          <p className="mb-0">{slide.description}</p>
        </Slide>
      ))}
    </Slider>
    <p className="pt-4 mb-0">
      All photos sourced from www.flickr.com<br />
      Information sourced from
    </p>

    <List className="mt-3">
      {[
        'http://list25.com/25-of-the-worlds-best-ski-resorts/',
        'http://www.cntraveler.com/galleries/2015-01-06/best-ski-resorts-in-europe-alps-readers-choice-awards-2014',
        'http://www.powderhounds.com/Best-Ski-Resorts.aspx'
      ].map((link, i) => (
        <ListItem>
          <a href={link} target="_blank" rel="noopener noreferrer">
            {link}
          </a>
        </ListItem>
      ))}
    </List>
  </div>
);
