// Wrapper for react-slick, see docs at https://react-slick.neostack.com/docs/api

import React from 'react';
import PropTypes from 'prop-types';
import SlickSlider from 'react-slick';
import ChevronButton from '../ChevronButton/ChevronButton';
import './_boxslider.scss';

const nextArrow = <ChevronButton direction="right" />;
const prevArrow = <ChevronButton direction="left" />;
const galleryResponsiveSettings = [
  {
    breakpoint: 10000,
    settings: {
      arrows: false,
      slidesToShow: 5,
      slidesToScroll: 1
    }
  },
  {
    breakpoint: 1200,
    settings: {
      arrows: false,
      slidesToShow: 4,
      slidesToScroll: 1
    }
  },
  {
    breakpoint: 992,
    settings: {
      arrows: false,
      slidesToShow: 3,
      slidesToScroll: 1
    }
  },
  {
    breakpoint: 768,
    settings: {
      arrows: false,
      slidesToShow: 3,
      slidesToScroll: 1,
      centerMode: true
    }
  },
  {
    breakpoint: 560,
    settings: {
      slidesToShow: 2,
      slidesToScroll: 1,
      arrows: false,
      centerMode: true
    }
  },
  {
    breakpoint: 375,
    settings: {
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      centerMode: true
    }
  }
];

const BoxSlider = props => {
  let newClassName = 'lfs-slider ';
  newClassName += props.dots ? 'lfs-slider--has-dots ' : '';
  newClassName += props.darkBg ? 'lfs-slider--dark-bg ' : '';
  newClassName += props.className || '';

  let responsiveSettings;

  if (props.gallery) {
    responsiveSettings = galleryResponsiveSettings;
  } else if (props.responsiveSettings) {
    responsiveSettings = props.responsiveSettings;
  }

  return (
    <div>
      <SlickSlider
        className={newClassName}
        nextArrow={nextArrow}
        prevArrow={prevArrow}
        responsive={responsiveSettings}
        {...props}
      >
        {props.children}
      </SlickSlider>
    </div>
  );
};

// See docs at https://react-slick.neostack.com/docs/api for full propTypes
// to pass down to react-slick

BoxSlider.propTypes = {
  gallery: PropTypes.bool
};

BoxSlider.defaultProps = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  gallery: false
};

export default BoxSlider;
