/* eslint-disable */
import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import ReactHtmlParser from 'react-html-parser';
import Modal from '@/components/Modal/Modal';
import { BREAKPOINT, COLOR, GUTTER } from '@/utils/constants';
import PageData from '@/data/pages/car-loan.json';

/**
 * Constants
 */
export const COL_WIDTH_DESKTOP = 350; // multi-column
export const COL_WIDTH_TABLET = 335; // two-column
export const COL_WIDTH_MOBILE = 330; // single-column, iphone 6 up

/**
 * Styles
 */
export const StyledColumn = styled.div`
  flex: 0 1 auto;
  background: ${COLOR.WHITE};
  width: ${COL_WIDTH_MOBILE}px;
  max-width: ${COL_WIDTH_MOBILE}px;
  margin-right: ${GUTTER.G15};
  z-index: 1; // be mindful of sticky nav

  &:last-child {
    margin-right: 0;
  }

  @media (min-width: ${BREAKPOINT.MD}) {
    width: ${COL_WIDTH_TABLET}px;
    max-width: ${COL_WIDTH_TABLET}px;
  }

  @media (min-width: ${BREAKPOINT.LG}) {
    width: ${COL_WIDTH_DESKTOP}px;
    max-width: ${COL_WIDTH_DESKTOP}px;
    margin-right: ${GUTTER.G30};
  }
`;

const StyledAccordionColumn = styled(StyledColumn)`
  flex: 1 1 0;
  padding: 5px 16px;
  font-weight: 300;

  a.link {
    border-bottom: 0 !important;
    text-decoration: underline !important;
  }

  p ul {
    padding-left: 18px;

    li {
      list-style-type: none;
      position: relative;
      padding: 0 0 0 15px;
      margin: 0;

      &:before {
        content: '';
        position: absolute;
        top: 10px;
        left: 0;
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background: ${COLOR.GREY16};
      }
    }
  }
`;

const StyledRow = styled.ul`
  justify-content: space-between;
  margin: 0;
  padding: 0 0 10px 0;
`;

const StyledBullet = styled.span`
  color: ${COLOR.BLUE_LIGHT};
  font-size: 25px;
  margin-right: 8px;
  margin-top: 1px;
  display: inline-block;
  vertical-align: middle;
`;

const StyledText = styled.p`
  display: ${props => (props.disableFlex ? 'block' : 'flex')};
  margin: 0;
  padding: 0;
`;

const StyledHeading = styled.p`
  display: ${props => (props.disableFlex ? 'block' : 'flex')};
  margin: 0;
  padding: 10px 20px 10px 15px;
  font-weight: 700;
`;

const StyledCell = styled.li`
  display: flex;
  margin: 0;
  justify-content: space-between;

  // Work out custom min height value for cell, with formula below:
  // (numOfLines * lineHeight) + containerPadding
  ${({ minLinesMobile }) => {
    if (minLinesMobile !== undefined) {
      const minHeightVal = minLinesMobile * 26 + 10;
      return css`
        min-height: ${minHeightVal}px;
        @media (min-width: ${BREAKPOINT.MD}) {
          min-height: 0;
        }
      `;
    }
  }}
  ${({ minLinesTablet }) => {
    if (minLinesTablet !== undefined) {
      const minHeightVal = minLinesTablet * 26 + 10;
      return css`
        @media (min-width: ${BREAKPOINT.MD}) {
          min-height: ${minHeightVal}px;
        }
        @media (min-width: ${BREAKPOINT.LG}) {
          min-height: 0;
        }
      `;
    }
  }}
  ${({ minLinesDesktop }) => {
    if (minLinesDesktop !== undefined) {
      const minHeightVal = minLinesDesktop * 26 + 10;
      return css`
        @media (min-width: ${BREAKPOINT.LG}) {
          min-height: ${minHeightVal}px;
        }
      `;
    }
  }}
`;

const StyledLink = styled.a`
  color: inherit !important;
  text-decoration: none !important;
  border-bottom: 0.5px solid #42484d !important;
  text-decoration: none !important;
  margin-left: 30px;
`;

/*
 * Sub-components used for templating
 */

const ListItem = ({
  children,
  style,
  disableFlex,
  minLinesMobile,
  minLinesTablet,
  minLinesDesktop
}) => {
  const hasChildren =
    children !== '' &&
    children !== [] &&
    children.length !== undefined &&
    children.length > 0;

  return (
    <StyledCell
      style={style}
      minLinesMobile={minLinesMobile}
      minLinesTablet={minLinesTablet}
      minLinesDesktop={minLinesDesktop}
    >
      <StyledAccordionColumn>
        {hasChildren && (
          <StyledText disableFlex={disableFlex}>
            <StyledBullet>&bull;</StyledBullet>
            {children}
          </StyledText>
        )}
      </StyledAccordionColumn>
    </StyledCell>
  );
};

/*
 * Data structure and source
 * Custom cell heights added to keep sizing consistent across sections
 */
export const GetLoanAboutData = ({ data, type }) => {
  return data.about.map(
    (item, index) =>
      item.name === type && (
        <StyledRow key={index}>
          <ListItem>{ReactHtmlParser(item.loanAmountFrom)}</ListItem>
          <ListItem>{ReactHtmlParser(item.loanTermsFrom)}</ListItem>
          <ListItem>{ReactHtmlParser(item.loanType)}</ListItem>
          <ListItem>{ReactHtmlParser(item.rate)}</ListItem>
          <ListItem>{ReactHtmlParser(item.repaymentOption)}</ListItem>
          <ListItem>{ReactHtmlParser(item.repaymentDate)}</ListItem>
          {item.insuranceTypeURL && (
            <ListItem>{ReactHtmlParser(item.insuranceTypeURL)}</ListItem>
          )}
          <ListItem
            minLinesMobile={type === 'motor' || type === 'asset' ? 0 : 9}
            minLinesTablet={type === 'motor' || type === 'asset' ? 0 : 9}
            minLinesDesktop={type === 'motor' ? 0 : type === 'asset' ? 0 : 9}
          >
            {ReactHtmlParser(item.extraRepayments)}
          </ListItem>
          <ListItem>{ReactHtmlParser(item.vehicleUpTo15yrs)}</ListItem>
          <ListItem>{ReactHtmlParser(item.vehicleUpTo7yrs)}</ListItem>
          <ListItem>{ReactHtmlParser(item.vehiclePurchase)}</ListItem>
        </StyledRow>
      )
  );
};

export const GetLoanEligibilityData = ({ data, type }) => {
  return data.eligibility.map(
    (item, index) =>
      item.name === type && (
        <StyledRow key={index}>
          <ListItem>{ReactHtmlParser(item.ageCriteria)}</ListItem>
          <ListItem>{ReactHtmlParser(item.residentStatus)}</ListItem>
          <ListItem>{ReactHtmlParser(item.employmentStatus)}</ListItem>
          <ListItem>{ReactHtmlParser(item.bankruptcyCriteria)}</ListItem>
          <ListItem>{ReactHtmlParser(item.creditRatingStatus)}</ListItem>
          <ListItem>{ReactHtmlParser(item.homeOwnerCriteria)}</ListItem>
          <ListItem>{ReactHtmlParser(item.borrowingCriteria)}</ListItem>
          <ListItem>{ReactHtmlParser(item.customerTypeCriteria)}</ListItem>
          <ListItem
            minLinesMobile={type === 'motor' || type === 'asset' ? 5 : 0}
            minLinesTablet={type === 'motor' || type === 'asset' ? 4 : 0}
            minLinesDesktop={type === 'motor' ? 5 : type === 'asset' ? 8 : 0}
          >
            {ReactHtmlParser(item.vehicleAgeCriteria)}
          </ListItem>
          <StyledHeading>{ReactHtmlParser(item.eligibilityCriteriaForBestRateTitle)}</StyledHeading>
          <ListItem>{ReactHtmlParser(item.eligibilityCriteriaForBestRate)}</ListItem>
        </StyledRow>
      )
  );
};

export const GetLoanFeesData = ({ data, type }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  return data.fees.map(
    (item, index) =>
      item.name === type && (
        <StyledRow key={index}>
          <ListItem>
            {ReactHtmlParser(item.establishmentFee)}
          </ListItem>
          <ListItem>{ReactHtmlParser(item.serviceFee)}</ListItem>
          <ListItem>{ReactHtmlParser(item.handlingFee)}</ListItem>
          <ListItem>{ReactHtmlParser(item.bpay)}</ListItem>
          <ListItem>{ReactHtmlParser(item.lateFee)}</ListItem>
          <ListItem
            disableFlex
            minLinesMobile={type === 'motor' || type === 'asset' ? 7 : 0}
            minLinesTablet={type === 'motor' || type === 'asset' ? 7 : 0}
            minLinesDesktop={type === 'motor' || type === 'asset' ? 7 : 0}
          >
            {ReactHtmlParser(item.terminationFee)}

            {item.terminationFeeLink && (
              <StyledLink
                onClick={() => {
                  setIsModalOpen(true);
                }}
              >
                See how this can change overtime
              </StyledLink>
            )}
          </ListItem>
          <Modal
            title="Early termination fees"
            content={ReactHtmlParser(PageData.content.earlyTerminationText)}
            isOpen={isModalOpen}
            onRequestClose={() => {
              setIsModalOpen(false);
            }}
            ariaHideApp={isModalOpen}
          />
        </StyledRow>
      )
  );
};
