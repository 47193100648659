import useRowState from './useRowState';
import useCategoryState from './useCategoryState';

const useOnceOffState = () => {
  const homeImprovement = useRowState(
    'Home Improvements',
    'home-improvement',
    1
  );
  const carRego = useRowState('Car Registration', 'car-rego', 1);
  const christmas = useRowState('Christmas', 'christmas', 1);
  const otherOnceOff = useRowState('Other', 'other-once-off', 1);

  const onceOffStateFields = [
    homeImprovement,
    carRego,
    christmas,
    otherOnceOff
  ];

  return useCategoryState(
    'once-off-accordion',
    'Once-off Expenses',
    onceOffStateFields
  );
};

export default useOnceOffState;
