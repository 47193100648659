import React from 'react';
import { InfoCard } from '@latitude/info-card';
import { Text } from '@latitude/text';
import { Link } from '@latitude/link';
import { Box } from '@latitude/box';
import { Vertical } from '@latitude/spacing';

const ReportingAccess = () => (
  <Box paddingBottom="50px">
    <InfoCard bodyBGColor="#E8F4FF" heading="Securitisation Reporting Access">
      <Vertical spacing="24px">
        <Box>
          <Text align="center">
            Latitude is changing the access for Securitisation Reporting. Please
            contact Latitude via the link below or email{' '}
            <Link
              href="mailto:debt.ir@latitudefinancial.com"
              target="_blank"
              rel="noreferrer noopener"
            >
              debt.ir@latitudefinancial.com
            </Link>{' '}
            if you are an investor in Latitude Securitisation programs. Please
            ensure to provide your Full Name, Organisation, Email (mandatory)
            and phone number (optional) plus for verification purposes please
            confirm your holdings in the Trust requested.
          </Text>
          <Link
            button="tertiary"
            href="mailto:debt.ir@latitudefinancial.com?subject=Securitisation%20Reporting%20Access&body=Please%20ensure%20to%20provide%20your%20Full%20Name,%20Organisation,%20Email%20(mandatory)%20and%20phone%20number%20(optional)%20plus%20for%20verification%20purposes%20please%20confirm%20your%20holdings%20in%20the%20Trust%20requested."
            width="200px"
            css={`
              margin: 20px auto 0;
            `}
          >
            Email us
          </Link>
        </Box>
      </Vertical>
    </InfoCard>
  </Box>
);

export default ReportingAccess;
