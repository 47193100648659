import React from 'react';
import { Heading4 } from '@latitude/heading';
import Section from '@latitude/section';
import { Text } from '@latitude/text';
import { ALIGN, COLOR } from '@latitude/core/utils/constants';

const CorporateResponsibilityIntro = () => {
  return (
    <Section>
      <Heading4 color={COLOR.BLACK} align={ALIGN.CENTER}>
        Latitude Giving
      </Heading4>
      <Text align={ALIGN.CENTER}>
        In Australia our partners are: Reachout, Redkite, The Salvation Army,
        Ardoch and Murdoch Children’s Research Institute. In New Zealand we
        support Duffy Books in Homes, Child Cancer Foundation, Mental Health
        Foundation and Women’s Refuge.
      </Text>
      <br />
      <Text align={ALIGN.CENTER}>
        It’s not just Latitude that makes a difference to these partners, our
        customers also make an impact when they choose the charitable donation
        option.
      </Text>
      <br />
      <Text align={ALIGN.CENTER}>
        Our partners have shared their story about how the Latitude Giving
        program is helping them to support their communities.
      </Text>
    </Section>
  );
};

export default CorporateResponsibilityIntro;
