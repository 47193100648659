/* eslint-disable import/extensions, import/no-unresolved */
import React from 'react';
import { FONT_SIZE } from '@latitude/core/utils/constants';
import { StaticImage } from 'gatsby-plugin-image';

import {
  StyledHeroBranded,
  ImageBox,
  LeaderDetailsWrapper,
  DetailsContainer,
  StyledText,
  LeadershipPageLayout
} from '../../../components/Leadership';

export default props => {
  const title = 'Stefano Tognon';
  const content = 'Chief Financial Officer & Executive General Manager, Finance (interim), and Group Treasurer';

  return (
    <LeadershipPageLayout title={title} {...props}>
      <StyledHeroBranded
        title={title}
        text={content}
        breadcrumbs={[
          {
            name: 'About us',
            href: '/about-us/'
          },
          {
            name: 'Our company',
            href: '/about-us/our-company/'
          },
          {
            name: 'Stefano Tognon'
          }
        ]}
        imageContent={
          <ImageBox>
            <StaticImage
              alt={title}
              src="../images/stefano-tognon.webp"
              width={450}
              quality={100}
              placeholder="dominantColor"
            />
          </ImageBox>
        }
      />
      <LeaderDetailsWrapper>
        <DetailsContainer display="flex">
          <StyledText fontSize={FONT_SIZE.LARGE} css={`text-align: justify;`}>
            Stefano has over 18 years of experience in financial services,
            capital markets and corporate finance advisory.
            <br />
            <br />
            Stefano joined Latitude in March 2022 to lead Corporate Strategy and
            Development. In November 2023, he was appointed Group Treasurer and
            currently leads Latitude’s Capital Markets and Treasury Operations
            functions, while retaining the group’s Corporate Development
            responsibilities. In January 2025, Stefano was appointed as Interim
            Chief Financial Officer and EGM, Finance.
          </StyledText>
          <StyledText fontSize={FONT_SIZE.LARGE} css={`text-align: justify;`}>
            Prior to joining Latitude, Stefano was an investment banker with
            J.P. Morgan Chase covering Financial Institution clients for 15
            years across North America, Australia, Southeast Asia and Europe/UK.
            During his tenure at J.P. Morgan in New York, Stefano specifically
            focused on the Specialty Finance and Alternative Asset Management
            sectors serving an extensive portfolio of corporate clients and
            investment managers with M&A, Debt & Equity Capital Markets, funds
            formation and structured finance advice and execution. Stefano holds
            a master’s degree in Finance and a bachelor’s degree in Business
            Administration from L. Bocconi University (Milan, Italy).
          </StyledText>
        </DetailsContainer>
      </LeaderDetailsWrapper>
    </LeadershipPageLayout>
  );
};
