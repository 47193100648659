import React from 'react';
import PropTypes from 'prop-types';

import './_ariclecard.scss';
import CardBody from '../CardBody/CardBody';

const ArticleCard = props => {
  const className = `card ${props.className || ''}`;
  return (
    <div className={className}>
      {props.thumbnail && <div className="card__thumbnail" style={{backgroundImage: `url("${props.thumbnail.default}")`}}></div>}
      <CardBody>
        {props.heading && <h5>{props.heading}</h5>}
        {props.children}
      </CardBody>
    </div>
  )
};

ArticleCard.propTypes = {
  heading: PropTypes.node
}

export default ArticleCard;
