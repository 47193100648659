import React from 'react';
import { Box } from '@latitude/box';
import { Text } from '@latitude/text';
import { COLOR, MARGIN, PADDING, ALIGN, FONT_WEIGHT } from '@latitude/core/utils/constants';
import { List, ListItem } from '@latitude/list';
import { Heading4, Heading5 } from '@latitude/heading';
import SingleTextBox from '@latitude/single-text-box';

const MedicalList = () => {return (
    <Box
        paddingTop={PADDING.P48}
        paddingBottom={PADDING.P48}
        backgroundColor={COLOR.GREY6}
        css={`
          [class^='Spacing__StyledResponsive'] {
            margin: auto;
          }
          div[class*='SingleTextBox__ContentBox-'] {
            max-width: 800px;
          }
        `}
      >
        <SingleTextBox
          box1={
            <>
              <Heading4
                marginBottom={MARGIN.M16} 
                color={COLOR.BLACK}
              >
                What can I use my medical loan for?
              </Heading4>
              <Text
                marginBottom={MARGIN.M16} 
                fontWeight={FONT_WEIGHT.MEDIUM}
              >
                You can use your Latitude medical loan if you&apos;re borrowing
              for one of the below categories:
              </Text>
              <div class="row">
                <div class="col-sm">
              <List>
                <ListItem>
                Audiology services
                </ListItem>
                <ListItem>
                Bariatric surgery
                </ListItem>
                <ListItem>
                Cosmetic surgery
                </ListItem>
                <ListItem>
                Dental/Orthodontics
                </ListItem>
                <ListItem>
                ENT surgery
                </ListItem>
                <ListItem>
                Hair restoration surgery
                </ListItem>
                </List>
                </div>
                <div class="col-sm">
                    <List>
                <ListItem>
                Laser hair treatment
                </ListItem>
                <ListItem>
                Laser eye surgery
                </ListItem>
                <ListItem>
                Obstetrics
                </ListItem>
                <ListItem>
                Orthopaedics
                </ListItem>
                <ListItem>
                Sleep apnoea treatment
                </ListItem>
                <ListItem>
                Veterinary care for your pet
                </ListItem>
              </List>
              </div>
              </div>
            </>
          }
        />
    </Box>

)};

export default MedicalList;