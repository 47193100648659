/* eslint-disable global-require */
import React from 'react';

import { MicrositeHeader } from '@latitude/microsite-header';

const APPLY_NOW_URL = 'https://cards.latitudefinancial.com/low-rate';

function DegreesCardMicrositeHeader({ applyNowUrl = APPLY_NOW_URL }) {
  return (
    <MicrositeHeader
      data={[
        {
          id: 'home',
          label: 'Home',
          trackId: 'nav-home',
          href: '/credit-cards/low-rate/'
        },
        {
          id: 'balance-transfer',
          label: 'Balance Transfer',
          trackId: 'nav-balance-transfer',
          href: '/credit-cards/low-rate/balance-transfer/'
        },
        {
          id: 'faqs',
          label: 'FAQs',
          trackId: 'nav-faqs',
          href: '/credit-cards/low-rate/faqs/'
        },
        {
          id: 'help',
          label: 'Help',
          trackId: 'help',
          href: 'https://latitudefs.zendesk.com/hc/en-au/'
        }
      ]}
      ctaHref={applyNowUrl}
      microSiteName="Low Rate"
    />
  );
}

export default DegreesCardMicrositeHeader;
