import React from 'react';
import WhitepapersMediaList from './WhitepapersMediaList';

const WhitepapersMediaSection = () => (
  <React.Fragment>
    <WhitepapersMediaList />
  </React.Fragment>
);

export default WhitepapersMediaSection;
