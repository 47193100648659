import classnames from 'classnames';
import * as React from 'react';
import { Link } from '@latitude/link';
import SvgInline from '../SvgInline/SvgInline';
import './_home-button.scss';

/**
 * Renders header brand button element
 *
 * @param {boolean} isSearchOpen - if header search UI is activated
 * @returns {React.element} rendered brand button
 */
export const renderBrandButton = isSearchOpen => (
  <BrandButton mobileVisible={isSearchOpen} />
);

const BrandButton = props => {
  const { className, mobileVisible } = props;
  return (
    <Link
      noStyle
      href="/"
      className={classnames('home-button-ltd trackcustomanalytics', className, {
        'mobile-visible': mobileVisible
      })}
      trackId="nav-home"
      trackEventData={{
        label: 'Latitude'
      }}
    >
      <SvgInline
        name="logo-combination"
        aria-label="Home page"
        className="home-button-ltd__logo"
        fillBlueBright
      />
    </Link>
  );
};

export default BrandButton;
