import React from 'react';
import { BaseHeader } from '@latitude/base-header';
import HeaderData from '../../data/json/Navigation/navigation.json';
import { renderBrandButton } from './BrandButton';
import { renderBannerContent } from './Banner';
import renderLoginModalImage from './LoginModalImage';
import './_lfs-header.scss';

// get LFS header data chunks
const navTreeData = HeaderData.navTree;
const loginModalsData = HeaderData.loginModals;
const loginFormData = HeaderData.loginForm;
const cardTypesData = HeaderData.cardTypes || [];
const searchFormData = HeaderData.searchForm;

function cardTypesByName(a, b) {
  const lhs = a.name.toLowerCase();
  const rhs = b.name.toLowerCase();
  if (lhs > rhs) {
    return 1;
  } else if (lhs < rhs) {
    return -1;
  }
  return 0;
}
cardTypesData.sort(cardTypesByName);

const LfsHeader = props => (
  <BaseHeader
    navTreeData={navTreeData || {}}
    loginModalsData={loginModalsData || []}
    loginFormData={loginFormData || {}}
    logonButtonLabel="Log&nbsp;in"
    cardTypesData={cardTypesData}
    searchFormData={searchFormData || {}}
    renderBrandButton={renderBrandButton}
    renderBanner={renderBannerContent}
    renderLoginModalImage={renderLoginModalImage}
    setImageModuleSyntaxx
    showLoansPane
    // showPartnerPane
    {...props}
  />
);

export default LfsHeader;
