import React, { Fragment, useState } from 'react';
import styled from 'styled-components';
import { Link } from '@latitude/link';
import {
  ALIGN,
  BREAKPOINT,
  COLOR,
  FONT_SIZE,
  CALCULATOR_TRACKING
} from '../../utils/constants';
import Text from '../Text/Text';
import { Bold } from '../Text';
import Modal from '../Modal/Modal';
import { Box } from '../Box/Box';

/**
 * PL Disclaimer content
 */
const StyledDisclaimerText = styled(Text)`
  color: ${COLOR.WHITE};
  @media (min-width: ${BREAKPOINT.LG}) {
    color: ${COLOR.BLACK};
  }
`;

const StyledFeesMobileLink = styled(Link)`
  display: inline;
  color: ${COLOR.WHITE};
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  :hover {
    text-shadow: 0 0 1px ${COLOR.WHITE};
    border-bottom: 1px solid rgba(255, 255, 255, 1);
  }
  @media (min-width: ${BREAKPOINT.LG}) {
    display: none;
  }
`;

const StyledFeesDesktopLink = styled(Link)`
  display: none;
  :hover {
    text-shadow: 0 0 1px ${COLOR.BLUE};
  }
  @media (min-width: ${BREAKPOINT.LG}) {
    display: inline;
  }
`;

const PLDisclaimerContent = () => {
  const [showFeesModal, setShowFeesModal] = useState(false);
  const trackingData = {
    category: CALCULATOR_TRACKING.CATEGORY,
    action: CALCULATOR_TRACKING.ACTION
  };

  return (
    <Fragment>
      <StyledDisclaimerText fontSize={FONT_SIZE.SMALL} align={ALIGN.CENTER}>
        This calculator is provided for illustrative purposes only and does not
        constitute a quote or pre-qualification. The interest rate you may be
        offered can differ from the interest rate displayed above and will be
        based on your personal circumstances. The total amount paid includes
        establishment and monthly fees. See all{' '}
        <StyledFeesMobileLink
          href="#"
          onClick={e => {
            setShowFeesModal(true);
            e.preventDefault();
          }}
          trackEventData={trackingData}
          variant={COLOR.WHITE}
        >
          Fees &amp; charges
        </StyledFeesMobileLink>
        <StyledFeesDesktopLink
          href="#"
          onClick={e => {
            setShowFeesModal(true);
            e.preventDefault();
          }}
          trackEventData={trackingData}
        >
          Fees &amp; charges
        </StyledFeesDesktopLink>{' '}
        for this product.
      </StyledDisclaimerText>

      <Modal
        isOpen={showFeesModal}
        onRequestClose={() => {
          return setShowFeesModal(false);
        }}
        title="Fees & Charges"
        content={
          <Box>
            <Text>
              <Bold>Establishment Fee</Bold>
            </Text>
            <Fragment>
              <Text>$395</Text>
            </Fragment>
            <Text>
              <Bold>Loan Service Fee</Bold>
            </Text>
            <Text>$13 per month</Text>
            <Text>
              <Bold>Payment handling fee for using BPAY®</Bold>
            </Text>
            <Text>$1.95</Text>
            <Text>
              <Bold>Late Payment Fee</Bold>
            </Text>
            <Text>
              $45 In the event that you can't make the minimum repayment by your
              agreed due date
            </Text>
            <Text>
              <Bold>Early Termination Fee</Bold>
            </Text>
            <Text>
              Fixed loan:
              <br />
              $500 up until the last 3 months of the loan
            </Text>
            <Text>
              Variable loan:
              <br />
              No early termination fee
            </Text>
          </Box>
        }
        ariaHideApp={false}
      />
    </Fragment>
  );
};

export default PLDisclaimerContent;
