import React from 'react';
import Helmet from 'react-helmet';
import { SecondaryHero } from '@latitude/hero';
import styled from 'styled-components';
import Layout from '../../components/layout';

const SITE_URL = 'https://www.latitudefinancial.com.au';

const StyledIframe = styled.div`
  iframe {
    @media (min-width: 1800px) {
      padding: 0 15%;
    }
  }
`;

const ResponsibleDisclosure = ({ location }) => {
  return (
    <Layout location={location}>
      <main className="navigation-spacer">
        <Helmet>
          <link rel="canonical" href={`${SITE_URL}${location.pathname}`} />
          <title>Responsible Disclosure | Latitude Financial Services Australia</title>
          <meta
            name="description"
            content="At Latitude we are committed to keeping our customers safe and secure. Here are some of the ways we help keep you safe and secure"
          />
        </Helmet>
        <SecondaryHero heading="Responsible Disclosure Program" />
        <StyledIframe>
          <script
            async
            src="https://bugcrowd.com/776d7f83-c557-4f8d-9689-d70f2fec7b5c/external/script"
            data-bugcrowd-program="https://bugcrowd.com/776d7f83-c557-4f8d-9689-d70f2fec7b5c/external/report"
          />
        </StyledIframe>
      </main>
    </Layout>
  );
};

export default ResponsibleDisclosure;