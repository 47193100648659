import React, { useContext } from 'react';
import { PageContext } from '@/context/PageContext';
import Helmet from 'react-helmet';

import Section from '@latitude/section';
import Layout from '@/components/layout';
import AchPageHeaderSection from './_ach-page-header';
import DegreesCardMicrositeHeader from '../_28d-microsite-header';

function AchNewPasswordPage(props) {
  /** Contentful Data */
  const contentfulPageData = useContext(PageContext);
  const state = contentfulPageData?.updatedBody || {};
  /** - END - */
  return (
    <Layout location={props.location}>
      <main
        className="navigation-spacer"
        css={`
          margin-top: 0 !important;
          background-color: #f8f8f8;
          .microsite-header {
            z-index: 2000 !important;
          }

          div[class^='MicrositeHeader__SecondBar'].microsite-header__nav-wrap {
            margin-top: 8px;
          }
        `}
      >
        <Helmet>
          <link
            rel="canonical"
            href="https://www.latitudefinancial.com.au/credit-cards/28-degrees/addcard/login/"
          />
          <meta
            name="description"
            content="Add an additional cardholder to your Latitude 28&deg; Global Platinum Mastercard"
          />
          <title>Additional Card Holder New Password | 28 Degrees card</title>
        </Helmet>
        <DegreesCardMicrositeHeader data={state.secondaryNavData} />

        <AchPageHeaderSection />
        <Section>
          <div
            css={{ clear: 'both', margin: '10px 0' }}
            className="lfs-iframe-vixverify"
          >
            <iframe
              css={{
                width: '100%',
                height: '450px',
                border: 'none',
                padding: '10px'
              }}
              title="Set new password"
              src="https://au.vixverify.com/sa/customers/gecapital-ach/new-password.seam?ui=28degrees"
            />
          </div>
        </Section>
      </main>
    </Layout>
  );
}

export default AchNewPasswordPage;
