/* eslint-disable global-require */
import React, { useEffect, useContext } from 'react';
import ReactHTMLParser from 'react-html-parser';
import { marked } from 'marked';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import { Lframe } from '@latitude/lframe';
import { Hero } from '@latitude/hero';
import { StickyNavigation } from '@latitude/sticky-navigation';
import { FeaturesSlider } from '@latitude/features-slider';
import { Box } from '@latitude/box';
import { HorizontalRule } from '@latitude/horizontal-rule';
import { ImportantInformation } from '@latitude/important-information';
import { Link } from '@latitude/link';
import { BREAKPOINT, BUTTON_STYLE } from '@latitude/core/utils/constants';
import PromoBanner from '@/components/lab-components/PromoBanner';

import Layout from '@/components/layout';

import HaveAQuestionSection from '../../travelpartners/_have-a-question';
import { PageContext } from '@/context/PageContext';
import { CONTENTFUL_COMPONENTS } from '@/utils/constants';
import DegreesCardMicrositeHeader from './_28d-microsite-header';
import { useSetState } from '@/utils/hooks';
import footerData from './data/footer.json';
import DontHaveACardSection from './_dont-have-card';
import HeroBanner from '@/components/lab-components/HeroBanner';

function FlightDelayPassPage({ location }) {
  const registerUrl = 'https://flightdelaypass.mastercard.com/28degrees';

  /**
   * Opens target page in new window, without adding analytics to URL.
   * Analytics causes the "flightdelaypass.mastercard.com" page to give an error.
   * @param {*} event
   */
  function onRegisterYourCard(event) {
    event.preventDefault();
    if (window) {
      window.open(registerUrl, '_blank');
    }
  }

  const featureSliderItems = [
    {
      icon: 'icon-flight-itinerary',
      title: '1. Register your flight',
      text:
        'Just register your card and flight details for you and up to four additional guests online before your scheduled departure.'
    },
    {
      icon: 'icon-boarding-pass',
      title: '2. Receive your pass',
      text:
        "We'll track your flight, and if it's delayed by 2 hours or more, we'll email and SMS your pass(es) to you automatically - too easy!"
    },
    {
      icon: 'icon-coffee',
      title: '3. Sit back and relax',
      text:
        'Show your one-time-use code to participating airport partners and treat yourself, on us.'
    }
  ];

  /** Contentful Data */
  const contentfulPageData = useContext(PageContext);
  const state = contentfulPageData?.updatedBody || {};

  const featureSliderData = [
    {
      featureCards: featureSliderItems
    }
  ];
  /** - END - */

  return (
    <Layout customFooter={footerData} location={location}>
      <main
        className="navigation-spacer"
        css={`
          margin-top: 0 !important;
          background-color: #f8f8f8;
          .microsite-header {
            z-index: 2000 !important;
          }
        `}
      >
        <Helmet>
          <link
            rel="canonical"
            href="https://www.latitudefinancial.com.au/credit-cards/28-degrees/flight-delay-pass/"
          />
          <title>
            Flight Delay Pass | Latitude 28&deg; Global Platinum Mastercard |
            International Credit Card
          </title>
        </Helmet>
        <Main>
          <HeroContainer>
            <DegreesCardMicrositeHeader data={state.secondaryNavData} />
          {state?.heroBannerData?.[0] ? (
            <HeroBanner {...state?.heroBannerData[0]} />
          ) : (
            <>
              <StyledLframe />
              <StyledHero
                title="Turn a delay into a delight"
                text="Flight Delay Pass, a fantastic complimentary feature of your Latitude 28° Global Platinum Mastercard lets you lounge around before you fly."
                buttonProps={{
                  children: 'Register your card',
                  href: registerUrl,
                  onClick: onRegisterYourCard,
                  style: { maxWidth: '100%' },
                  button: 'secondary'
                }}
                imageContent={
                  <HeroImageContent>
                    <img
                      src={require('./images/hero-flight-delay-pass.png').default}
                      alt="flight delay pass"
                    />
                  </HeroImageContent>
                }
              />
            </>
        )}
        </HeroContainer>
          <StickyNavigation
            items={[
              {
                anchor: 'how',
                label: 'How to use Flight Delay Pass'
              },
              {
                anchor: 'dont-have-card',
                label: "Don't have a card yet?"
              },
              {
                anchor: 'have-question',
                label: 'Have a question?'
              }
            ]}
            {...state?.inPageNavData?.[0]}
          />

          <span data-header-trigger />

          <FeaturesSlider
            key={
              (state?.featureSliderData?.[0] || featureSliderData[0]).heading
            }
            id="how-to-use-pass"
            css={`
              sup {
                font-size: 0.5em;
                top: -1em;
              }
            `}
            data={
              (state?.featureSliderData?.[0] || featureSliderData[0])
                .featureCards
            }
            heading={
              (state?.featureSliderData?.[0] || featureSliderData[0]).heading
            }
            subheading={
              (state?.featureSliderData?.[0] || featureSliderData[0])
                .description
            }
            className="pt-0 pb-0"
          />

          <Box>
            <RegisterLink
              button={BUTTON_STYLE.SECONDARY}
              href={registerUrl}
              onClick={onRegisterYourCard}
            >
              Register here
            </RegisterLink>
          </Box>

          <HorizontalRule />

          {state?.promoBannerData?.[0] ? (
            <PromoBanner {...state?.promoBannerData[0]} />
          ) : (
            <DontHaveACardSection anchor="dont-have-card" />
          )}

          <HaveAQuestionSection
            anchor="have-question"
            heading="Have a question?"
          />

          <ImportantInformation
            data={{
              content: {
                importantInformationSectionOne: [
                  '<sup id="note-1">1</sup> The Flight Delay Pass gives free access to select airport lounges, alternatively cardholders can use their Flight Delay Pass to offset a dollar amount off the total bill at select dining options. This will vary based on location and affiliated LoungeKey Partners at the time. Please note that not all airports offer Flight Delay Pass and the selected airports may be restricted to certain terminals. Access to and use of the Flight Delay Pass is subject to the Terms of Use which are subject to change. Further details of the list of lounges that cardholders are eligible to visit and the specific conditions applicable to each lounge access (Conditions of Use) may also be accessed in the Terms of Use. Participating lounges and their Conditions of Use are subject to change.',
                  '<sup id="note-2">2</sup> Free access to Global Data Roaming from Flexiroam over 580 telcos in over 150 countries worldwide. Terms and conditions apply',
                  'Mastercard and the circles design are registered trademarks of Mastercard International Incorporated.'
                ]
              }
            }}
            sectionOneColummClasses="offset-1 col-10  col-lg-10"
            {...state?.importantInfoData?.[0]}
          />
        </Main>
      </main>
    </Layout>
  );
}

const Main = styled.main`
  background-color: #f8f8f8;
`;

const HeroContainer = styled.div`
  position: relative;
  background: linear-gradient(0deg, #e8fafe 0%, #d9f6ff 100%);
`;

const StyledLframe = styled(Lframe)`
  background-image: url(${require('./images/lframe-pink.svg').default});
  @media (min-width: ${BREAKPOINT.MD}) {
    right: -30px;
  }
  @media (min-width: ${BREAKPOINT.LG}) {
    right: 0;
  }
`;

const StyledHero = styled(Hero)`
  .HeroContent {
    @media (min-width: ${BREAKPOINT.MD}) {
      padding: 40px 0 48px;
    }
    @media (min-width: ${BREAKPOINT.LG}) {
      padding: 80px 0 48px;
    }
  }
`;

const HeroImageContent = styled.div`
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  @media (min-width: ${BREAKPOINT.LG}) {
    justify-content: flex-start;
  }
  img {
    max-height: 100%;
  }
`;

const FeaturesSliderText = styled(Box)`
  padding-top: 42px;
  @media (max-width: ${BREAKPOINT.LG}) {
    border-top: 1px solid #e9ebec;
  }
  @media (min-width: ${BREAKPOINT.LG}) {
    min-width: 760px;
    padding-top: 72px;
  }
`;

const RegisterLink = styled(Link)`
  display: block;
  width: 172px;
  margin: 32px auto 48px;
  @media (min-width: ${BREAKPOINT.LG}) {
    margin: 48px auto 72px;
  }
`;

export default FlightDelayPassPage;
