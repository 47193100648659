/* eslint-disable react/no-danger */
import React, { useEffect, useContext } from 'react';
import { marked } from 'marked';
import ReactHTMLParser from 'react-html-parser';
import { StaticImage } from 'gatsby-plugin-image';
import { DigitalWallets } from '@latitude/digital-wallets';
import { HorizontalRule } from '@latitude/horizontal-rule';
import FeaturesSlider from '@/components/FeaturesSlider/FeaturesSlider';
import Faq from '@/components/Faq/AccordionSidebarFaq';
import Lframe from '@/components/Lframe/Lframe';
import HeroBranded from '@/components/HeroBranded/HeroBranded';
import { ImportantInformation } from '@latitude/important-information';
import Layout from '@/components/layout';
import Metadata from '@/components/Metadata/Metadata';
import RatesAndFees from '@/components/RatesAndFees/RatesAndFees';
import StickyNavigationBranded from '@/components/StickyNavigation/StickyNavigationBranded';
import { Box } from '@/components/Box/Box';
import { Link } from '@latitude/link/Link';
import { BREAKPOINT, COLOR } from '../../utils/constants';
import PageData from '../../data/pages/credit-cards/latitude-eco-mastercard.json';
import { CalloutSection } from './_components';
import { PageContext } from '@/context/PageContext';
import LabRatesAndFees from '@/components/lab-components/LabRatesAndFees';
import { CONTENTFUL_COMPONENTS } from '../../utils/constants';
import { useSetState } from '@/utils/hooks';
import FeeChangesCCSection from './_feeChanges';
import { NotificationBanner } from '@latitude/banner';
import HeroBanner from '@/components/lab-components/HeroBanner';

const LatitudeEcoMastercard = props => {

  const featureSliderItems = [
    {
      icon: 'icon-baloon-dog-branded',
      title: 'Streamlined Rates and Fees',
      text:
        "Our rates are easy to understand and you'll only have to pay one manageable annual fee"
    },
    {
      icon: 'icon-iphone',
      title: 'Stay on track with your spending',
      text:
        "With the <a href='/mobile-app/' data-trackid='why-latitude-app-link'>Latitude App</a>, it's never been easier to manage your payments and stay on top of your due dates."
    }
  ]

  /** Contentful Data */
  const contentfulPageData = useContext(PageContext);
  const state = contentfulPageData?.updatedBody || {};

  const featureSliderData = [
    {
      heading:
        'Already using Latitude Eco Mastercard<sup style="font-size: 0.5em; top: -1em;">®</sup>? <br/>Don’t forget these great features.',
      featureCards: featureSliderItems
    }
  ];
  /** - END - */

  return (
    <Layout location={props.location}>
      <main className="navigation-spacer" css="background-color: #f8f8f8;">
        <Metadata
          title={PageData.metaTitle}
          description={PageData.metaDesc}
          canonical={PageData.path}
        />
        {!state?.alertData && (
          <NotificationBanner siteName="latitudefinancial-com-au" />
        )}

        {state?.heroBannerData?.[0] ? (
          <HeroBanner {...state?.heroBannerData[0]} />
        ) : (
          <>
            <Lframe cardLowRate />
            <HeroBranded
              imageContent={
                <div
                  css={`
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    @media (min-width: ${BREAKPOINT.LG}) {
                      justify-content: flex-end;
                    }
                  `}
                >
                  <StaticImage
                    src="../../images/credit-cards/latitude-eco-mastercard.png"
                    alt="Latitude Mastercard®"
                    width={325}
                    quality={100}
                  />
                </div>
              }
              title={
                <>
                  Latitude Eco Mastercard
                  <sup css="font-size: 0.5em; top: -1em;">®</sup>
                </>
              }
              text={
                <>
                  The Latitude Eco Mastercard
                  <sup css="font-size: 0.5em; top: -1em;">®</sup> is no longer
                  taking new applications. If you’re after a flexible card
                  alternative, view all our{' '}
                  <Link href="/credit-cards" trackId="credit-card-options">
                    credit card options
                  </Link>
                  .
                </>
              }
            />
          </>
        )}

        <NavigationSection inPageNavData={state?.inPageNavData?.[0]} />

        {/* <FeeChangesCCSection
          heading="Notice of Variation to your Latitude Eco Mastercard credit card contract"
          body="Effective from 17 September 2024, we are changing the credit card annual percentage rate on purchases from 27.49% p.a. to 27.99% p.a."
          link="https://latitudefs.zendesk.com/hc/en-au/articles/5078660193041-Credit-Card-Pricing-Changes"
        /> */}

        {state?.featureSliderData?.[0] && (
          <BenefitsSection {...state.featureSliderData[0]} />
        )}
        <HorizontalRule />
        {state?.ratesAndFeesData?.[0] ? (
          <LabRatesAndFees {...state.ratesAndFeesData[0]} />
        ) : (
          <RatesAndFeesSection />
        )}
        <HorizontalRule />
        <DigitalWallets
          heading="Digital wallets"
          subheading="You can pay any way on your phone or wearable tech device with just a tap."
        />
        <HorizontalRule />
        <Faq
          data={PageData.faq.concat(
            require('../../data/pages/credit-cards/faq-gambling.json')
          )}
          {...state?.faqData?.[0]}
        />
        <CalloutSection />
        <ImportantInformationSection
          importantInfoData={state?.importantInfoData?.[0]}
        />
      </main>
    </Layout>
  );};

const NavigationSection = ({ inPageNavData }) => (
  <>
    <div className="d-none d-lg-block" css="position: relative; z-index: 6;">
      <StickyNavigationBranded
        items={PageData.nav}
        phoneNumber="1300 552 079"
        offsetElem="[data-sticky-navigation-offset]"
        {...inPageNavData}
      />
    </div>
  </>
);

const BenefitsSection = ({ featureCards, heading, description }) =>
   (
    <Box.Section id="benefits" backgroundColor={COLOR.GREY6}>
      <FeaturesSlider
        id="why-us"
        key={heading}
        data={featureCards}
        className="why-choose pb-0"
        heading={heading}
        subheading={description}
      />
    </Box.Section>
  )


const RatesAndFeesSection = () => (
  <div
    css={`
      .rates-list {
        @media (min-width: ${BREAKPOINT.LG}) {
          max-width: 820px;
          margin: 0 auto;
        }
      }
    `}
  >
    <RatesAndFees
      id="rates"
      ratesListProps={{ data: PageData.ratesList }}
      accordionProps={{
        items: [
          {
            id: 'other-fees',
            title: 'Other fees',
            content: (
              <div className="accordion-custom">
                <div className="d-lg-flex accordion__row">
                  <p className="w-100">
                    <strong>Cash advance fee</strong>
                  </p>
                  <p className="w-100">
                    $4 or 3.5% of the cash advance, whichever is greater.
                  </p>
                </div>

                <div className="d-lg-flex accordion__row">
                  <p className="w-100">
                    <strong>Late fee</strong>
                  </p>
                  <p className="w-100">$45</p>
                </div>

                <div className="d-lg-flex accordion__row">
                  <p className="w-100">
                    <strong>Paper statement fee</strong>
                  </p>
                  <p className="w-100">$5.00</p>
                </div>

                <div className="d-lg-flex accordion__row">
                  <p className="w-100">
                    <strong>Payment handling fee</strong>
                  </p>
                  <p className="w-100">
                    $3.95 (in person)
                    <br />
                    $1.95 (online)
                  </p>
                </div>

                <div className="d-lg-flex accordion__row">
                  <p className="w-100">
                    <strong>ATM fee</strong>
                  </p>
                  <p className="w-100">
                    Varies, depending on the ATM owner. Not applicable at
                    Westpac ATMs
                  </p>
                </div>

                <div className="d-lg-flex accordion__row">
                  <p className="w-100">
                    <strong>International ATM fee</strong>
                  </p>
                  <p className="w-100">$4, on top of the cash advance fee</p>
                </div>

                <div className="d-lg-flex accordion__row">
                  <p className="w-100">
                    <strong>International over the counter fee</strong>
                  </p>
                  <p className="w-100">$5, on top of the cash advance fee</p>
                </div>

                <div className="d-lg-flex accordion__row">
                  <p className="w-100">
                    <strong>Foreign exchange fee</strong>
                  </p>
                  <p className="w-100">3% of the total amount</p>
                </div>
              </div>
            )
          }
        ]
      }}
    />
  </div>
);

const ImportantInformationSection = ({ importantInfoData }) => (
  <ImportantInformation
    data={{
      content: {
        importantInformationSectionOne: [
          'Mastercard is a registered trademark and the circles design is a trademark of Mastercard International Incorporated.'
        ]
      }
    }}
    sectionOneColummClasses="col-10 offset-1 col-lg-10 offset-lg-1"
    // sectionTwoColummClasses="col-10 offset-1 col-lg-5 offset-lg-0"
    {...importantInfoData}
  />
);

export default LatitudeEcoMastercard;
