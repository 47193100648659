import React from 'react';
import styled, { css } from 'styled-components';
import ReactHtmlParser from 'react-html-parser';
import { Heading3 } from '@latitude/heading';
import { BREAKPOINT, CARD_COLOR, COLOR } from '@/utils/constants';
import { StyledList } from '../_ContactUsStyle';

export const CardTiles = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 0 auto;
  max-width: 100%;

  @media (min-width: ${BREAKPOINT.LG}) {
    max-width: 700px;
    padding-bottom: 40px;
    justify-content: space-between;
  }
  @media (min-width: ${BREAKPOINT.XL}) {
    max-width: 1020px;
  }
`;

const CardImage = styled.div`
  position: absolute;
  top: -10px;
  right: 16px;
  z-index: 2;

  img {
    width: 83px;
  }
  ${props =>
    props.cardImageOrientation === 'horizontal' &&
    css`
      top: 16px;
      img {
        width: 112px;
      }
    `}
`;

const CardTileContainer = styled.div`
  position: relative;
  max-width: 320px;
  margin: 0 30px 50px 30px;
  flex: 0 0 100%;

  @media (min-width: ${BREAKPOINT.MD}) {
    max-width: 320px;
    margin: 0 30px 30px 0;
    flex: 0 1 calc(50% - 15px);
  }

  @media (min-width: ${BREAKPOINT.LG}) {
    margin: 0 30px 30px 0;
    flex: 0 0 50%;
  }

  @media (min-width: ${BREAKPOINT.XL}) {
    flex: 0 0 30%;
  }
`;

const CardHeader = styled.div`
  height: 137px;
  background-color: ${CARD_COLOR.BLUE_LIGHT};
  padding: 24px;
  position: relative;
  overflow: hidden;

  > h3 {
    font-size: 20px;
    line-height: 1.3;
    max-width: 196px;
    color: ${COLOR.BLUE_DEEP};
  }
  &:after {
    transform: skew(0, -6deg);
    width: 100%;
    height: 42px;
    background-color: ${COLOR.WHITE};
    content: '';
    display: block;
    position: absolute;
    bottom: -24px;
    left: 0;
  }
`;

const CardContent = styled.div`
  padding: 10px 0 0;
  background: ${COLOR.WHITE};
  .accordion__item {
    border-top: 1px solid #e9ebec;
    &:first-child {
      border-top: 0;
    }
    > button {
      outline: none;
    }
  }
  .accordion .accordion__button-fixed {
    position: relative;
    top: auto !important;
  }
  ${StyledList} {
    margin: 0 16px;
  }
`;

export const CardAccordionInner = styled.div`
  padding: 0 0 8px;
`;

const CardTile = props => {
  const { title, image, cardImageOrientation, content, className } = props;

  return (
    <CardTileContainer className={className}>
      {image && (
        <CardImage
          cardImageOrientation={
            cardImageOrientation ? cardImageOrientation : 'vertical'
          }
        >
          {props.image}
        </CardImage>
      )}
      <CardHeader>
        <Heading3>{ReactHtmlParser(title)}</Heading3>
      </CardHeader>
      <CardContent>{content}</CardContent>
    </CardTileContainer>
  );
};

export default CardTile;
