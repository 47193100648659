import React from 'react';
import { Heading3 } from '@latitude/heading';
import Section from '@latitude/section';
import { Vertical } from '@latitude/spacing';
import { Text } from '@latitude/text';
import {
  ALIGN,
  COLOR,
  FONT_SIZE,
  MARGIN
} from '@latitude/core/utils/constants';

const WhyLatitudeSection = () => {
  return (
    <Section className="bg-lightest">
      <div className="row justify-content-center">
        <div className="col-md-10 col-lg-8 text-left">
          <Vertical spacing={MARGIN.TEXT}>
            <Heading3
              align={ALIGN.CENTER}
              color={COLOR.BLACK}
              marginTop={MARGIN.M48}
            >
              Why Latitude
            </Heading3>
            <Text
              align={ALIGN.LEFT}
              fontSize={FONT_SIZE.LARGE}
              marginTop={MARGIN.M32}
            >
              We&apos;re obsessed by better outcomes every day, for our people,
              customers, partners and the communities in which we work. Our
              people are passionate about what they do, they are advocates who
              work with all areas of the business to drive new ways of thinking,
              they push the limits of what is possible and inspire others with
              new possibilities.
            </Text>
            <Text
              align={ALIGN.LEFT}
              fontSize={FONT_SIZE.LARGE}
              marginBottom={MARGIN.M48}
            >
              Joining Latitude, you will be part of the driving force building
              those intuitive and next level customer experiences.
            </Text>
          </Vertical>
        </div>
      </div>
    </Section>
  );
};

export default WhyLatitudeSection;
