import React, { useContext } from 'react';
import { PageContext } from '@/context/PageContext';
import Helmet from 'react-helmet';

import Section from '@latitude/section';
import Layout from '@/components/layout';
import AchPageHeaderSection from './_ach-page-header';
import GoMicrositeHeader from '../_go-microsite-header';

function AchSetPasswordPage(props) {
  /** Contentful Data */
  const contentfulPageData = useContext(PageContext);
  const state = contentfulPageData?.updatedBody || {};
  /** - END - */
  return (
    <Layout location={props.location}>
      <main className="navigation-spacer">
        <Helmet>
          <link
            rel="canonical"
            href="https://www.latitudefinancial.com.au/credit-cards/gomastercard/additional-cardholder/set-password/"
          />
          <meta
            name="description"
            content="Add an additional cardholder to your Latitude GO Mastercard"
          />
          <title>Additional Card Holder Set Password | GO Mastercard</title>
        </Helmet>

        <AchPageHeaderSection />
        <GoMicrositeHeader data={state.secondaryNavData} />
        <Section>
          <div
            css={{ clear: 'both', margin: '10px 0' }}
            className="lfs-iframe-vixverify"
          >
            <iframe
              css={{
                width: '100%',
                height: '400px',
                border: 'none',
                padding: '10px'
              }}
              title="Set new password"
              src="https://au.vixverify.com/sa/customers/gecapital-ach/set-password.seam?ui=go"
            />
          </div>
        </Section>
      </main>
    </Layout>
  );
}

export default AchSetPasswordPage;
