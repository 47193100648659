import React from 'react';
import PropTypes from 'prop-types';
import CircledCharacter from '../CircledCharacter/CircledCharacter';
import Chevron from '../Chevron/Chevron';

import './_chevron-button.scss';

const ChevronButton = props => {
  let className = 'chevron-button ';
  className += props.onClick || props.href ? 'chevron-button--interactable ' : '';
  className += (props.className || '');
  return (
    <a href={props.href} onClick={props.onClick} className={className}>
      <CircledCharacter>
        <Chevron direction={props.direction} />
      </CircledCharacter>
    </a>
  )
}

ChevronButton.propTyes = {
  onClick:   PropTypes.func,
  href:      PropTypes.string,
  direction: PropTypes.oneOf['up', 'right', 'down', 'left'],
}

ChevronButton.defaultProps = {}

export default ChevronButton;
