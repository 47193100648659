/* Leadership Page Navigator component */
/* eslint-disable flowtype/no-types-missing-file-annotation */
import React from 'react';
import styled from 'styled-components';
import ReactHTMLParser from 'react-html-parser';
import { Link } from '@latitude/link';
import SvgInline from '@latitude/svg-inline';
import { Text } from '@latitude/text';
import { Heading5 } from '@latitude/heading';
import { BREAKPOINT, COLOR } from '@latitude/core/utils/constants';
import teamData from '../../pages/about-us/data/_our-team';

/* component styles */
const LeaderNavigatorWrapper = styled.div`
  background-color: ${COLOR.GREY6};
  margin: auto;
  height: 200px;
  z-index: 2;
`;

const Line = styled.div`
  max-width: 1140px;
  height: 2px;
  margin: 0 auto 20px;
  border: solid 1px #e2e2e2;
  z-index: 2;

  @media (max-width: ${BREAKPOINT.XL}) {
    max-width: 95%;
  }

  @media (max-width: ${BREAKPOINT.MD}) {
    max-width: 90%;
  }
`;

const InnerNavigator = styled.div`
  max-width: 1140px;
  margin: 20px auto;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  @media (max-width: ${BREAKPOINT.XL}) {
    max-width: 95%;
  }

  @media (max-width: ${BREAKPOINT.SM}) {
    max-width: 100%;
    justify-content: space-around;
  }
`;

const StyledSvgInline = styled(SvgInline)`
  path {
    fill: ${COLOR.BLUE};
  }
`;

const StyledHeading5 = styled(Heading5)`
  @media (max-width: ${BREAKPOINT.MD}) {
    font-size: 14px;
  }

  @media (max-width: ${BREAKPOINT.XS}) {
    font-size: 12px;
  }
`;

const StyledLink = styled(Link)`
  display: flex;
  border-bottom: none;
  justify-content: center;
  z-index: 2;
  &:hover {
    border-bottom: none;
  }
`;

const NavText = styled(Text)`
  width: 250px;
  line-height: 1.6;

  @media (max-width: ${BREAKPOINT.MD}) {
    font-size: 12px;
    max-width: 150px;
  }

  @media (max-width: ${BREAKPOINT.XS}) {
    font-size: 10px;
    max-width: 110px;
  }
`;

/* Function to get the neigbour indexes of current index */
function getNeighboursIndex(name: string): { previous: number; next: number } {
  const currentIdx = teamData.findIndex(member => {
    return member.title === name;
  });

  let prevIdx: number = currentIdx - 1;
  if (prevIdx < 0) {
    prevIdx = teamData.length - 1;
  }

  let nextIdx: number = currentIdx + 1;
  if (nextIdx >= teamData.length) {
    nextIdx = 0;
  }

  return { previous: prevIdx, next: nextIdx };
}

interface Props {
  name: string;
}

const LeaderNavigator = ({ name }: Props) => {
  const { previous, next } = getNeighboursIndex(name);

  return (
    <LeaderNavigatorWrapper>
      <Line />
      <InnerNavigator>
        <StyledLink
          trackEventData={{
            label: teamData[previous].title,
            location: 'navigation previous'
          }}
          href={teamData[previous].ctaHref}
          data-trackid={`our-company-previous-${teamData[previous].slug}`}
        >
          {/* TODO: add 'chevron-left' to share-react */}
          <StyledSvgInline
            name="chevron-up"
            css={`
              transform: rotate(-90deg);
            `}
          />
          <div>
            <StyledHeading5 marginBottom="0">
              {teamData[previous].title}
            </StyledHeading5>
            <NavText>{ReactHTMLParser(teamData[previous].content)}</NavText>
          </div>
        </StyledLink>
        <StyledLink
          trackEventData={{
            label: teamData[next].title,
            location: 'navigation next'
          }}
          href={teamData[next].ctaHref}
          data-trackid={`our-company-next-${teamData[next].slug}`}
        >
          <div>
            <StyledHeading5 align="right" marginBottom="0">
              {teamData[next].title}
            </StyledHeading5>
            <NavText align="right">
              {ReactHTMLParser(teamData[next].content)}
            </NavText>
          </div>
          <StyledSvgInline name="chevron-right" />
        </StyledLink>
      </InnerNavigator>
      <Line />
    </LeaderNavigatorWrapper>
  );
};

export default LeaderNavigator;
