import React from 'react';
import { AnalyticsLocationProvider } from 'latitude-analytics';
import { StaticImage } from 'gatsby-plugin-image';
import { Heading4, Heading5 } from '@latitude/heading';
import { Box, Flex } from '@latitude/box';
import { Link } from '@latitude/link';
import { Text } from '@latitude/text';
import {
  COLOR,
  MARGIN,
  ALIGN,
  BUTTON_STYLE
} from '@latitude/core/utils/constants';
import { NarrowContentContainer, ContactUsTile } from './_styles';

const ContactUs = () => {
  const heading = 'Contact us';
  return (
    <AnalyticsLocationProvider location={heading}>
      <Box.Section
        margin={`${MARGIN.M0} ${MARGIN.MAUTO}`}
        backgroundColor={COLOR.GREY6}
      >
        <Heading4 color={COLOR.BLACK} align={ALIGN.CENTER}>
          {heading}
        </Heading4>
        <NarrowContentContainer>
          <Flex justifyContent="space-evenly" flexWrap="wrap">
            <ContactUsTile>
              <Box>
                <StaticImage
                  src="../../../static/media/leadership-team-au/eva-zileli.jpg"
                  alt="Eva Zileli"
                  width={240}
                  quality={80}
                />
              </Box>

              <Box>
                <Heading5
                  align={ALIGN.CENTER}
                  color={COLOR.BLACK}
                  marginBottom={MARGIN.M8}
                >
                  Eva Zileli
                </Heading5>
                <Text align={ALIGN.CENTER} marginBottom={MARGIN.M16}>
                  Treasurer
                </Text>
                <Link
                  button={BUTTON_STYLE.TERTIARY}
                  href="mailto:eva.zileli@latitudefinancial.com"
                >
                  Email
                </Link>
              </Box>
            </ContactUsTile>
            <ContactUsTile>
              <Box>
                <StaticImage
                  src="../../../static/media/leadership-team-au/michael-donohue.jpg"
                  alt="Michael Donohue"
                  width={240}
                  quality={80}
                />
              </Box>

              <Box>
                <Heading5
                  align={ALIGN.CENTER}
                  color={COLOR.BLACK}
                  marginBottom={MARGIN.M8}
                >
                  Michael Donohue
                </Heading5>
                <Text align={ALIGN.CENTER} marginBottom={MARGIN.M16}>
                  Assistant Treasurer
                </Text>
                <Link
                  button={BUTTON_STYLE.TERTIARY}
                  href="mailto:michael.donohue@latitudefinancial.com"
                >
                  Email
                </Link>
              </Box>
            </ContactUsTile>
          </Flex>
        </NarrowContentContainer>
      </Box.Section>
    </AnalyticsLocationProvider>
  );
};

export default ContactUs;
