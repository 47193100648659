import React from 'react';
import styled from 'styled-components';
import { SecondaryHero } from '@latitude/hero';
import { Metadata } from '@latitude/metadata';
import { AnalyticsLocationProvider } from 'latitude-analytics';
import { Heading5, Heading6 } from '@latitude/heading';
import {
  COLOR,
  MARGIN,
  PADDING,
  ALIGN,
  JUSTIFY_CONTENT
} from '@latitude/core/utils/constants';
import { Box } from '@/components/Box/Box';
import Text from '../components/Text/Text';
import Layout from '../components/layout';

function CreditCardChangesPage(props) {
  return (
    <Layout location={props.location}>
      <main className="navigation-spacer">
        <Metadata
          title="Credit Card Changes | Latitude Financial"
          description="Credit Card Change notification"
          canonical="https://www.latitudefinancial.com.au/credit-card-changes/"
        />
        <Box>
          <SecondaryHero heading="Credit Card Changes" />
        </Box>
      </main>

      <AnalyticsLocationProvider location="credit card changes">
        <Box.Section
          backgroundColor={COLOR.GREY6}
          isResponsive
          margin={`${MARGIN.MAUTO}`}
        >
          <InfoBox title="PARTICULARS OF CHANGE">
            <Text>
              <p>
                Latitude Finance Australia ABN 42 008 583 588 Australian Credit
                Licence number 392145 (&quot;<strong>Latitude</strong>&quot;)
                has undertaken a review of its agreements and gives notice of
                the changes set below.
              </p>
              <p>
                Part A of this notice sets out changes that take effect from 8
                November 2023.
              </p>
              <p>
                Part B of this notice sets out other changes that will take
                effect on 9 January 2024.
              </p>
              <p>
                <strong>
                  PART A - UNFAIR CONTRACT TERM CHANGES EFFECTIVE 8 NOVEMBER
                  2023
                </strong>
              </p>
              <p>
                Applicable for{' '}
                <strong>
                  Latitude 28&deg; Global Platinum Mastercard<sup>&reg;</sup>
                </strong>
                {', '}
                <strong>
                  Latitude GO Mastercard<sup>&reg;</sup>
                </strong>
                {' / '}
                <strong>
                  Latitude GO Platinum Mastercard<sup>&reg;</sup>
                </strong>
                {', '}
                <strong>Latitude Gem Visa</strong>
                {', '}
                <strong>CreditLine available through Apple</strong>:
              </p>
              <p>
                <strong>1) Acting reasonably</strong>
                <br /> Where we have a discretion (for example to vary a term or
                authorize or decline a transaction), our discretion is qualified
                by words under which we may only exercise that discretion acting
                reasonably and to the extent reasonably necessary to protect our
                interests.
              </p>
              <p>
                <strong>2) Set-off</strong>
                <br /> Words have been included to expressly recognize certain
                rights of set-off you may have which cannot be excluded.
              </p>
              <p>
                <strong>3) Limitation of Liability &amp; Indemnities</strong>
                <br />
                Terms which:
                <br />
                a) limit Latitude's liability; or
                <br />
                b) require you to grant an indemnity in favour of Latitude,
                <br />
                have been qualified to ensure you will not be liable for losses
                to the extent caused by negligence, mistake, wilful default or
                fraud of Latitude or its employees or agents.
              </p>
              <p>
                <strong>4) Card return</strong>
                <br />
                Terms which required the return of cards to Latitude on
                Latitude's demand or on cancellation or account closure have
                been removed &mdash; it is sufficient for the cards to be
                securely cut and destroyed.
              </p>
              <p>
                <strong>5) Linked Credit Provider</strong>
                <br />
                The terms have been changed to expressly recognize that we may
                have responsibility as a linked credit provider for certain card
                transactions.
              </p>
              <p>
                <strong>6) Default Events</strong>
                <br />
                Latitude has limited its ability to take enforcement action for
                certain events of default unless they are material or reasonably
                considered to have or likely to have a material impact on your
                ability to meet your obligations, Latitude's credit risk or
                legal or reputation risk or Latitude's ability to assess these
                things. This is not applicable to monetary events of default.
              </p>
              <p>
                <strong>7) Clarification of drafting</strong>
                <br />
                Revisions have been made to clarify clauses by being more
                specific, to ensure consistency and to correct minor errors.
              </p>
              <p>
                A copy of the updated Conditions of Use can be found online at:{' '}
                <a
                  href="https://www.latitudefinancial.com.au/forms/"
                  target="_blank"
                >
                  https://www.latitudefinancial.com.au/forms/
                </a>
              </p>
              <p>
                <strong>PART B - OTHER CHANGES EFFECTIVE 9 JANUARY 2024</strong>
              </p>
              <p>
                <strong>Effective from 9 January 2024</strong>, the following
                changes will apply to the relevant credit card products issued
                by Latitude listed below. Any defined term will have the same
                meaning as in the relevant credit contract of the credit card
                product.
              </p>
              <p>
                The cash advance fee will increase to the greater of 3.5% or
                $4.00 for{' '}
                <strong>
                  Latitude 28&deg; Global Platinum Mastercard<sup>&reg;</sup>
                </strong>
                {', '}
                <strong>
                  Latitude GO Mastercard<sup>&reg;</sup>
                </strong>
                {' / '}
                <strong>
                  Latitude GO Platinum Mastercard<sup>&reg;</sup>
                </strong>
                {', '}
                <strong>Latitude Gem Visa</strong>
                {', '}
                <strong>Buyer's Edge</strong>
                {', '}
                <strong>CreditLine and Care Credit</strong>
                {', '}
                <strong>Latitude Infinity Rewards Visa</strong>
                {', '}
                <strong>
                  Latitude Low Rate Mastercard<sup>&reg;</sup>
                </strong>
                {', '}
                <strong>
                  Latitude Eco Mastercard<sup>&reg;</sup>
                </strong>
                {' and '}
                <strong>
                  Latitude Mastercard<sup>&reg;</sup>
                </strong>
                .
              </p>
              <p>
                The ability to make a direct credit transfer will be removed for{' '}
                <strong>
                  Latitude GO Mastercard<sup>&reg;</sup>
                </strong>
                {' / '}
                <strong>
                  Latitude GO Platinum Mastercard<sup>&reg;</sup>
                </strong>
                {', '}
                <strong>
                  Latitude Eco Mastercard<sup>&reg;</sup>
                </strong>
                {', '}
                <strong>
                  Latitude 28&deg; Global Platinum Mastercard<sup>&reg;</sup>
                </strong>
                {', '}
                <strong>Buyer's Edge</strong>
                {' and '}
                <strong> CreditLine</strong>.
              </p>
              <p>
                The $30 cap for a cash advance fee will be removed for{' '}
                <strong>
                  Latitude GO Mastercard<sup>&reg;</sup>
                </strong>
                {' / '}
                <strong>
                  Latitude GO Platinum Mastercard<sup>&reg;</sup>
                </strong>
                {', '}
                <strong>
                  Latitude 28&deg; Global Platinum Mastercard<sup>&reg;</sup>
                </strong>
                {', '}
                <strong>Latitude Gem Visa</strong>
                {', '}
                <strong>Buyers Edge</strong>
                {' and '}
                <strong>CreditLine</strong>.
              </p>
              <p>
                These changes were advertised in <em>The Australian</em>{' '}
                newspaper on 8 November 2023.
              </p>
              <p>
                A copy of the updated Conditions of Use can be found online at:{' '}
                <a
                  href="https://www.latitudefinancial.com.au/forms/"
                  target="_blank"
                >
                  https://www.latitudefinancial.com.au/forms/
                </a>
              </p>
            </Text>
          </InfoBox>
        </Box.Section>
      </AnalyticsLocationProvider>
    </Layout>
  );
}

const SectionHeading = styled.h4`
  // Overrides added below to account for UX request (Ref: CEPW-1205)
  margin: 0 0 20px;
  color: ${COLOR.BLACK};
  font-size: 24px;
  line-height: 28px;
  font-weight: 600;
  font-family: Montserrat, sans-serif;
`;

const SectionListItem = styled.li`
  // Overrides added below to account for UX request (Ref: CEPW-1205)
  font-weight: 500;
  padding-bottom: 20px;
`;

const SectionLastListItem = styled.li`
  // Overrides added below to account for UX request (Ref: CEPW-1205)
  font-weight: 500;
`;

const SectionLink = styled.a`
  // Overrides added below to account for UX request (Ref: CEPW-1205)
  font-weight: 300;
`;

const InfoBox = ({ title, children }) => (
  <Box
    backgroundColor={COLOR.WHITE}
    padding={`${PADDING.P32}`}
    max-width="1100px"
    height="100%"
    align={ALIGN.LEFT}
    justifyContent={JUSTIFY_CONTENT.LEFT}
  >
    <Title color={COLOR.BLACK} align={ALIGN.LEFT}>
      {title}
    </Title>
    {children}
  </Box>
);

const Title = styled(Heading5)`
  font-size: 24px;
  margin-bottom: ${MARGIN.M24};
`;

export default CreditCardChangesPage;
