/* eslint-disable react/no-danger */
import React from 'react';
import { Link } from '@latitude/link';
import { Flex, Box } from '@latitude/box';
import SvgInline from '@latitude/svg-inline';
import { Heading5 } from '@latitude/heading';
import {
  COLOR,
  MARGIN,
  ALIGN,
  JUSTIFY_CONTENT,
  PADDING,
  FLEX_WRAP,
  FONT_SIZE
} from '@latitude/core/utils/constants';

const MediaEnquiry = () => (
  <Flex
    backgroundColor={COLOR.BLUE_BABY}
    justifyContent={JUSTIFY_CONTENT.CENTER}
    padding={PADDING.P32}
    flexWrap={FLEX_WRAP.WRAP}
  >
    <Box
      paddingLeft={PADDING.P16}
      paddingRight={PADDING.P16}
      css={`
        text-align: ${ALIGN.RIGHT};
      `}
    >
      <SvgInline name="icon-mail" />
    </Box>
    <Box paddingLeft={PADDING.P16} paddingRight={PADDING.P16}>
      <Heading5
        color={COLOR.BLACK}
        marginTop={MARGIN.M16}
        marginBottom={MARGIN.M8}
        css={`
          @media (max-width: 521px) {
            text-align: center;
            font-size: ${FONT_SIZE.NORMAL};
          }
        `}
      >
        For media and journalist enquiries only.
      </Heading5>
      <div
        css={`
          @media (max-width: 660px) {
            text-align: center;
          }
          @media (max-width: 521px) {
            font-size: ${FONT_SIZE.SMALL};
          }
        `}
      >
        <Link
          data-trackid="media-enquiries-email"
          trackEventData={{
            location: 'For media and journalist enquiries only.'
          }}
          className="link--blue text-link__exclude"
          href="mailto:media.enquiries@latitudefinancial.com"
        >
          media.enquiries@latitudefinancial.com
        </Link>
      </div>
    </Box>
  </Flex>
);

export default MediaEnquiry;
