import React, { useEffect } from 'react';

function MakeAClaimPage({ location }) {
  useEffect(() => {
    if (window?.location) {
      window.location.replace('https://www.hallmarkinsurance.com.au/claims');
    }
  }, []);

  return <></>;
}

export default MakeAClaimPage;
