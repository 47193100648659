import * as React from 'react';
import styled from 'styled-components';
import { Box } from '../Box/Box';
import { Heading5 } from '../Heading/Heading';
import { COLOR, PADDING, BREAKPOINT } from '../../utils/constants';

const StyledBox = styled(Box)`
  width: 30%;
  @media (min-width: ${BREAKPOINT.SM}) and (max-width: ${BREAKPOINT.LG}) {
    width: 100%;
  }
`;

export const LinkResourceGroup = props => (
  <StyledBox>
    {props.heading && (
      <Box paddingLeft={PADDING.P24}>
        <Heading5 color={props.branded ? COLOR.BLACK : COLOR.BLUE}>
          {props.heading}
        </Heading5>
      </Box>
    )}
    <Box>{props.children}</Box>
  </StyledBox>
);
