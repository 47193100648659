import React from 'react';
import styled from 'styled-components';
import Section from '../Section/Section';
import { Heading3 } from '../Heading/Heading';
import Text from '../Text/Text';
import { BREAKPOINT, COLOR, HEADING } from '../../utils/constants';

const FramedContainer = styled.div`
  border: solid 1px ${COLOR.GREY10};
  background-color: ${COLOR.WHITE};
  text-align: center;
  position: relative;
  display: block;
  width: 100%;
  max-width: 920px;
  margin: 0 auto 20px;
  z-index: 1;

  @media (min-width: ${BREAKPOINT.LG}) {
    margin: 0 auto 60px;
  }

  &:after {
    display: block;
    width: 100%;
    height: 100%;
    content: ' ';
    position: absolute;
    top: 10px;
    right: -10px;
    border: solid 40px #ececec;
    z-index: -1;

    @media (min-width: ${BREAKPOINT.LG}) {
      top: 60px;
      right: -60px;
    }
  }
`;

const FramedContent = styled.div`
  background-color: ${COLOR.WHITE};
  padding: 32px 24px;
  width: 100%;
  display: block;
  position: relative;
  z-index: 2;

  @media (min-width: ${BREAKPOINT.LG}) {
    padding: 56px 94px;
  }
`;

const FramedContainerHeading = styled(Heading3)`
  font-size: 24px;
  margin: 0 0 16px;
  text-align: center;
  color: ${COLOR.BLACK};
  @media (min-width: ${BREAKPOINT.LG}) {
    font-size: ${HEADING.h3.FONT_SIZE.NORMAL};
    margin: 0 0 24px;
  }
`;

const FramedContainerDescription = styled(Text)`
  text-align: center;
  display: block;
  margin: 0 0 16px;
  font-size: 16px;

  @media (min-width: ${BREAKPOINT.LG}) {
    font-size: 18px;
    margin: 0 0 24px;
  }
`;

const ManageYourLoanContainer = styled(Section)`
  margin: 0 auto;
  background-color: #f8f8f8;

  .accordion__item {
    margin-bottom: 0;
    border-bottom: 2px solid ${COLOR.WHITE};

    button {
      background-color: #f1f1f1;

      &:focus {
        outline-color: #ddd;
      }
    }
  }

  .accordion {
    margin: 0 0 24px;

    @media (min-width: ${BREAKPOINT.LG}) {
      margin: 0 0 36px;
    }
  }

  .accordion__item-body {
    padding: 16px 24px;
    text-align: left;

    &.bg-white {
      background-color: #f8f8f8 !important;
    }
  }

  .accordion .accordion__button-fixed {
    position: relative;
    top: auto !important;
  }

  a.button {
    max-width: 255px;
    margin: 16px auto 0;

    &:first-child {
      margin-top: 0;
    }

    @media (min-width: ${BREAKPOINT.LG}) {
      display: inline-flex;
      margin: 0 24px 0 0;
    }
  }
`;

const ManageYourLoan = props => {
  return (
    <ManageYourLoanContainer>
      <FramedContainer>
        <FramedContent>
          <FramedContainerHeading>{props.heading}</FramedContainerHeading>
          {props.description && (
            <FramedContainerDescription>
              {props.description}
            </FramedContainerDescription>
          )}
          {props.children}
        </FramedContent>
      </FramedContainer>
    </ManageYourLoanContainer>
  );
};

export default ManageYourLoan;
