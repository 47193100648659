import React from 'react';
import styled from 'styled-components';
import { COLOR } from '@latitude/core/utils/constants';
import Section from '@latitude/section';

import HorizontalRule from '../HorizontalRule/HorizontalRule';
import CorporateResponsibilityIntro from './CorporateResponsibilityIntro';
import CorporateResponsibilityOurPartners from './CorporateResponsibilityPartners';
import CorporateResponsibilityVideoList from './CorporateResponsibilityVideoList';
import CorporateResponsibilityModernSlavery from './CorporateResponsibilityModernSlavery';
import CorporateResponsibilitySupportingCustomers from './CorporateResponsibilitySupportingCustomers';
import CorporateResponsibilityWorkplaceEquality from './CorporateResponsibilityWorkplaceEquality';

const Anchor = styled.div`
  width: 0;
  height: 0;
  position: relative;
  top: -30px;
`;

const CorporateResponsibilitySection = () => {
  return (
    <>
      <Anchor id="partners" />
      <CorporateResponsibilityOurPartners />
      <HorizontalRule backgroundColor={COLOR.GREY5} />

      <Anchor id="giving" />
      <Section className="bg-lightest">
        <CorporateResponsibilityIntro />
        <CorporateResponsibilityVideoList />
      </Section>

      <Anchor id="supporting" />
      <Section className="bg-lightest">
        <CorporateResponsibilitySupportingCustomers />
      </Section>

      <Anchor id="equality" />
      <Section className="bg-lightest">
        <CorporateResponsibilityWorkplaceEquality />
      </Section>

      <Anchor id="slavery" />
      <Section className="bg-lightest">
        <CorporateResponsibilityModernSlavery />
      </Section>
    </>
  );
};

export default CorporateResponsibilitySection;
