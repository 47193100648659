import React, { useState, useEffect, useContext } from 'react';
import HowToApplyList from '@/components/HowToApply/HowToApplyList';
import HowToApply from '@latitude/how-to-apply';
import { EstimateRateWidgetSection } from '@/components/EstimateRateWidget/EstimateRateWidgetSection';
import { getUrlFromEnv } from '@/utils/getUrlFromEnvUtil';
import { redirectUrl } from '@/components/EstimateRateWidget/utils';
import HeroBranded from '../components/HeroBranded/HeroBranded';
import { ImportantInformation } from '@latitude/important-information';
import Layout from '../components/layout';
import Lframe from '../components/Lframe/Lframe';
import LoanRates from '../components/LoanRates/LoanRates';
import Metadata from '../components/Metadata/Metadata';
import MobileAppInstallPrompts from '@latitude/mobile-app-install-prompt/MobileAppInstallPrompt';
import PLData from '../data/pages/personal-loan.json';
import StickyNavigationBranded from '../components/StickyNavigation/StickyNavigationBranded';
import { Box } from '../components/Box/Box';
import FeesAndCharges from '@/components/PersonalLoanDetails/PersonalLoanDetails';
import { PLEligibilityCriteria } from '../components/PersonalLoanPageContent/PersonalLoanPageContent';
import { BREAKPOINT, COLOR, SOFT_QUOTE_LOANS_AU_URL } from '../utils/constants';
import imgPersonalLoansHero from '../images/hero/personal-loan-3x.png';
import PageData from '../data/pages/loans/loan-repayment-calculator.json';
import { PageContext } from '@/context/PageContext';
import FeatureTiles from '@/components/FeatureTiles/FeatureTiles';
import FeaturesStaggeredSlider from '../components/FeaturesStaggeredSlider/FeaturesStaggeredSlider';
import HeroBanner from '@/components/lab-components/HeroBanner';

const UnsecuredPersonalLoanPage = props => {
  /** Contentful Data */
  const contentfulPageData = useContext(PageContext);
  const state = contentfulPageData?.updatedBody || {};
  /** - END - */
  const applyUrl = `${getUrlFromEnv(
    'funnel-start-loans'
  )}${SOFT_QUOTE_LOANS_AU_URL}`;
  // soft quote
  const [softQuoteHref, setSoftQuoteHref] = useState();
  useEffect(() => {
    sessionStorage.setItem('purpose', 'purpose'); // reset session val used in `EstimateRateWidgetSection`
    setSoftQuoteHref(redirectUrl());
  }, []);

  return (
    <Layout location={props.location}>
      <main className="navigation-spacer">
        <Box backgroundColor={COLOR.GREY6}>
          <MobileAppInstallPrompts />
          <Metadata
            title="Unsecured Personal Loan"
            description="Our Unsecured Personal Loan comes with a choice of a fixed or variable rate. Let's get you there faster. Get a quote in under 2 mins."
            canonical="/unsecured-personal-loan/"
          />

          {state?.heroBannerData?.[0] ? (
            <HeroBanner {...state?.heroBannerData[0]} />
          ) : (
            <>
              <Lframe personalLoans />
              <HeroBranded
                css={`
                  background: ${COLOR.GREY6};
                  @media (max-width: ${BREAKPOINT.MD}) {
                    .HeroContent {
                      background-color: ${COLOR.WHITE};
                    }
                  }
                `}
                imageContent={
                  <div
                    css={`
                      height: 100%;
                      display: flex;
                      align-items: flex-end;
                      justify-content: center;
                      @media (min-width: ${BREAKPOINT.LG}) {
                        justify-content: flex-start;
                      }
                    `}
                  >
                    <img
                      src={imgPersonalLoansHero}
                      alt=""
                      css={`
                        height: 250px;
                        width: auto;
                        @media (min-width: ${BREAKPOINT.MD}) {
                          height: 300px;
                        }
                        @media (min-width: ${BREAKPOINT.LG}) {
                          height: 395px;
                        }
                      `}
                    />
                  </div>
                }
                title={<>Unsecured Personal Loan</>}
                text={
                  <>
                    Variable from 9.49%<sup>^</sup> p.a. <br />
                    Comparison rate 10.37%<sup>*</sup> p.a.
                  </>
                }
              />
            </>
          )}
          
          <div
            className="d-none d-lg-block"
            css="position:relative; z-index:2;"
          >
            <StickyNavigationBranded
              items={[
                {
                  anchor: 'check-your-rate',
                  label: 'Check your rate'
                },
                {
                  anchor: 'rates',
                  label: 'Loan details'
                },
                {
                  anchor: 'details',
                  label: 'Fees & charges'
                },
                {
                  anchor: 'how-to-apply',
                  label: 'How to apply'
                }
              ]}
              phoneNumber={PLData.phoneNumber}
              ctaHref={applyUrl}
              ctaText="Get Rate Estimate"
              offsetElem="[data-sticky-navigation-offset]"
              {...state?.inPageNavData?.[0]}
            />
          </div>

          <EstimateRateWidgetSection
            purpose="purpose"
            parentOnChangeEvent={() => {
              setSoftQuoteHref(redirectUrl());
            }}
          />

          <div
            css={`
              position: relative;
              z-index: 1;
            `}
          >
            <LoanRates
              isBranded
              rateBoxType="variableSym"
              rateBoxType2="fixedSym"
              fees={PLData.content.aboutTheLoan}
              determineRateLink
              extraTCs="true"
            />
          </div>

          <FeesAndCharges data={PLData.content.feesAndCharges} />

          <HowToApply
            id="apply"
            box1={<HowToApplyList />}
            box2={<PLEligibilityCriteria />}
          />

          <FeatureTiles
            id="calculators-and-tools"
            heading="Loan repayment calculators and tools"
            tiles={PageData.content.calculators}
          />

          <FeaturesStaggeredSlider
            css="background-color:#f8f8f8;"
            data={PageData.categories}
            className="whats-important"
            heading="What loan can I use these calculators for?"
            responsiveSettings={PageData.categoriesResponsiveSettings}
          />

          <ImportantInformation
            data={require('../data/json/disclaimer/personal-loan.json')}
            sectionOneColummClasses="col-10 offset-1 col-lg-5 offset-lg-1"
            sectionTwoColummClasses="col-10 offset-1 col-lg-5 offset-lg-0"
            additionalPaymentsDisclaimerOne
            {...state?.importantInfoData?.[0]}
          />

          <script
            type="application/ld+json"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: JSON.stringify({
                '@context': 'http://schema.org',
                '@type': 'LoanOrCredit',
                name: PLData.content.loanDetails.personal.name,
                loanTerm: PLData.content.loanDetails.personal.loanTerm,
                annualPercentageRate:
                  PLData.content.loanDetails.personal.annualPercentageRate,
                amount: PLData.content.loanDetails.personal.amount
              })
            }}
          />
        </Box>
      </main>
    </Layout>
  );
};

export default UnsecuredPersonalLoanPage;
