import React from 'react';
import styled from 'styled-components';
import { AnalyticsLocationProvider } from 'latitude-analytics';

import { Link } from '@latitude/link';
import SvgInline from '@latitude/svg-inline';
import {
  BREAKPOINT,
  BUTTON_STYLE,
  FONT_FAMILY
} from '@latitude/core/utils/constants';

import { Box } from '@/components/Box/Box';
import { Text } from '@/components/Text';
import { Heading3 } from '@/components/Heading/Heading';

const SECTION_HEADING = 'Upcoming changes';

function FeeChangesCCSection(props) {
  return (
    <AnalyticsLocationProvider location={SECTION_HEADING}>
      <Outer id={props.id} className={props.className}>
        <Inner isResponsive>
          <Title>{SECTION_HEADING}</Title>

          {/* <SmallPara>
            <strong>
              {props.heading}
            </strong>
          </SmallPara>
          <SmallPara>
            {props.body}
          </SmallPara>
          <SmallPara>
            <br />
            <Link
              css={{
                width: 'fit-content',
                margin: '0 auto'
              }}
              button={BUTTON_STYLE.TERTIARY}
              href={props.link}
              trackId="callout-visit-upcoming-changes"
              trackEventData={{ location: SECTION_HEADING }}
            >
              Learn what's changing&nbsp;
              <SvgInline name="external-link" />
            </Link>
          </SmallPara> */}
        </Inner>
      </Outer>
    </AnalyticsLocationProvider>
  );
}

const SmallPara = styled(Text)`
  text-align: center;
  margin: 8px 0;
`;

const Outer = styled(Box)`
  background-color: #e2f2ff;
  padding: 10px 15px;
  margin: 0 0;
`;

const Inner = styled(Box)`
  margin: 0 auto;
  padding: 0;
  width: 100%;
  max-width: 1140px;
`;

const Title = styled(Heading3)`
  text-align: center;
  font-family: ${FONT_FAMILY.TITLE};
  font-weight: 600;
  font-size: 24px;
  color: #000;
  margin: 0;
  padding: 5px 0;
`;

export default FeeChangesCCSection;
