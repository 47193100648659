import useRowState from './useRowState';
import useCategoryState from './useCategoryState';

const useLoansState = () => {
  const mortgage = useRowState('Mortgage', 'mortgage');
  const personalLoan = useRowState('Personal Loan Repayments', 'personal-loan');
  const creditCard1 = useRowState('Credit Card #1 Repayments', 'credit-1');
  const creditCard2 = useRowState('Credit Card #2 Repayments', 'credit-2');
  const creditCard3 = useRowState('Credit Card #3 Repayments', 'credit-3');
  const storeCard = useRowState('Store Card Repayments', 'store-card');
  const otherCard = useRowState('Other Loan Repayments', 'other-card');

  const insuranceSuperStateFields = [
    mortgage,
    personalLoan,
    creditCard1,
    creditCard2,
    creditCard3,
    storeCard,
    otherCard
  ];

  return useCategoryState(
    'loans-accordion',
    'Loans & Credit Cards',
    insuranceSuperStateFields
  );
};

export default useLoansState;
