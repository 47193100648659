import React from 'react';
import { InfoCard } from '@latitude/info-card';
import { Text } from '@latitude/text';
import { Link } from '@latitude/link';
import { Box } from '@latitude/box';
import { Vertical } from '@latitude/spacing';

const CorporateResponsibilityWorkplaceEquality = () => {
  return (
    <InfoCard
      bodyBGColor="#E8F4FF"
      heading="Workplace Gender Equality Agency (WGEA) Reports"
    >
      <Vertical spacing="24px">
        <Box>
          <Text align="center">
            The Workplace Gender Equality Act 2012 requires non-public sector
            employers with 100 or more employees to submit a report to the
            Workplace Gender Equality Agency. Latitude&rsquo;s report for the
            relevant reporting periods are below:&nbsp;
          </Text>
          <Link
            href="https://assets.latitudefinancial.com/corporate-responsibility/reports/workplace-gender-equality-agency/2022-2023.pdf"
            trackId="corporate-responsibility-workplace-equality"
            width="200px"
            css={`
              margin: 50px auto 0;
            `}
          >
            2022-2023 report
          </Link>
        </Box>
      </Vertical>
    </InfoCard>
  );
};

export default CorporateResponsibilityWorkplaceEquality;
