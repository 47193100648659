import React, { useContext } from 'react';
import styled from 'styled-components';
import { AnalyticsLocationProvider } from 'latitude-analytics';
import { Link } from '@latitude/link';
import { BUTTON_STYLE } from '@latitude/core/utils/constants';
import { FeaturesSlider } from '@latitude/features-slider';
import { Accordion } from '@latitude/accordion';
import { Box } from '@/components/Box/Box';
import { Hero } from '@latitude/hero';
import SingleTextBox from '@latitude/single-text-box';
import { Heading4, Heading5 } from '@/components/Heading/Heading';
import BrandedCallout from '@latitude/callout/BrandedCallout';
import { ImportantInformation } from '@latitude/important-information';
import Layout from '@/components/layout';
import StickyNavigationBranded from '@/components/StickyNavigation/StickyNavigationBranded';
import Lframe from '@/components/Lframe/Lframe';
import Metadata from '@/components/Metadata/Metadata';
import { Responsive } from '@/components/Spacing/Spacing';
import { Tel } from '@latitude/tel';
import Text from '@/components/Text/Text';
import { LinkResourceGroup, VideoResource } from '@/components/LinkResource';
import HorizontalRule from '@/components/HorizontalRule/HorizontalRule';
import BrokerAccordionComparisonTableSlider from '@/components/AccordionComparisonTable/BrokerAccordionComparisonTableSlider';
import { selectDataSourceForAlertBanner } from '@/utils/helpers';
import { NotificationBanner } from '@latitude/banner';

import {
  ALIGN,
  ALIGN_ITEMS,
  BREAKPOINT,
  COLOR,
  MARGIN,
  FONT_SIZE,
  PADDING
} from '@/utils/constants';
import PageData from '@/data/pages/brokers.json';

import { resetToDefault } from '@/components/EstimateRateWidget/utils';
import { handleSmoothScroll } from '@/utils/helpers';
import { PageContext } from '@/context/PageContext';
import HeroBanner from '@/components/lab-components/HeroBanner';

const Brokers = props => {
  resetToDefault(true);

  const heading = 'Get accredited'; // required for data layer location value

  /** Contentful Data */
  const contentfulPageData = useContext(PageContext);
  const state = contentfulPageData?.updatedBody || {};
  /** - END - */

  const scrollHandler = event => {
    event.preventDefault();
    handleSmoothScroll(PageData.getAccreditedHashURL, 56);
  };
  const scrollHandlerLatitudeLoans = event => {
    event.preventDefault();
    handleSmoothScroll('#our-latitude-loans', 56);
  };
  return (
    <Layout location={props.location} noHeader brokerHeader>
      <main className="navigation-spacer bg-f8f8f8">
        <Metadata
          title={PageData.metaTitle}
          description={PageData.metaDesc}
          canonical={PageData.path}
        />

        {!state?.alertData && (
          <NotificationBanner siteName="latitudefinancial-com-au" />
        )}

        {state?.heroBannerData?.[0] ? (
          <HeroBanner {...state?.heroBannerData[0]} />
        ) : (
          <>
          <Lframe brokers />
          <Hero
            title={PageData.content.hero.title}
            text={PageData.content.hero.text}
            footnote={[
              <Box
                css={`
                  display: inline-block;
                  font-weight: 400;
                  margin-top: -10px;
                  width: 188px;
                `}
              >
                <Link
                  button={BUTTON_STYLE.PRIMARY}
                  href={PageData.getAccreditedHashURL}
                  onClick={scrollHandler}
                  trackId="hero-get-accredited"
                >
                  Get Accredited
                </Link>
              </Box>,
              <Text marginTop={MARGIN.M16} marginBottom={MARGIN.M16}>
                or
              </Text>,
              <Box
                css={`
                  display: inline-block;
                  font-weight: 400;
                  margin-top: -10px;
                  width: 188px;
                `}
              >
                <Link
                  button={BUTTON_STYLE.PRIMARY}
                  href="#our-latitude-loans"
                  onClick={scrollHandlerLatitudeLoans}
                  trackEventData={{
                    label: 'Submit Application*'
                  }}
                  trackId="hero-submit-application"
                >
                  Submit Application*
                </Link>
              </Box>,
              <Text fontSize={FONT_SIZE.SMALL}>
                <sup>*</sup>Accredited broker use only
              </Text>
            ]}
          />
          </>
        )}

        <div className="d-none d-lg-block">
          <StickyNavigationBranded
            items={PageData.nav}
            phoneNumber={PageData.phoneNumber}
            ctaHref={PageData.getAccreditedHashURL}
            trackId="sticky-navigation--get-accredited"
            offsetElem="[data-sticky-navigation-offset]"
            ctaText="Get Accredited"
            ctaType={BUTTON_STYLE.PRIMARY}
            ctaOnClick={scrollHandler}
            {...state?.inPageNavData?.[0]}
          />
        </div>
        <StyledFeaturesSlider
          heading="Why choose us?"
          id="why-us"
          className="why-choose"
          data={PageData.content.whyChooseUs}
        />
        <HorizontalRule />

        <AnalyticsLocationProvider location={heading}>
          <Box
            id={PageData.getAccreditedHashURL.replace('#', '')}
            paddingTop={PADDING.P48}
            css={`
              [class^='Spacing__StyledResponsive'] {
                margin: auto;
                max-width: 445px; // custom width override as per UX requirement
              }
            `}
          >
            <SingleTextBox
              box1={
                <>
                  <Heading4
                    marginBottom={MARGIN.M16}
                    align={ALIGN.LEFT}
                    color={COLOR.BLACK}
                  >
                    {heading}
                  </Heading4>
                  <Text marginBottom={MARGIN.M16}>
                    Become an accredited Broker with Latitude today.
                  </Text>
                  <Box width="250px" margin={MARGIN.MAUTO}>
                    <Link
                      button={BUTTON_STYLE.PRIMARY}
                      href={PageData.getAccreditedBrokerURL}
                      trackId="get-accredited-broker"
                    >
                      I am a Broker
                    </Link>
                    <Text
                      marginTop={MARGIN.M8}
                      marginBottom={MARGIN.M8}
                      align={ALIGN.CENTER}
                    >
                      or
                    </Text>
                    <Link
                      button={BUTTON_STYLE.PRIMARY}
                      href={PageData.getAccreditedAggregatorURL}
                      trackId="get-accredited-aggregator"
                    >
                      I am a Director or Aggregator
                    </Link>
                    <Text
                      align={ALIGN.CENTER}
                      fontSize={FONT_SIZE.SMALL}
                      css={`
                        margin-top: 5px;
                        line-height: 20px;
                      `}
                    >
                      login to review pending accreditation requests
                    </Text>
                  </Box>
                </>
              }
            />
          </Box>
        </AnalyticsLocationProvider>
        <Box id="our-latitude-loans">
          <CompareRatesContainer backgroundColor={COLOR.GREY6}>
            <Heading4 align={ALIGN.CENTER} color={COLOR.BLACK}>
              Our Latitude Loans
            </Heading4>
            <BrokerAccordionComparisonTableSlider
              productData={PageData.content.productData}
              sliderData={PageData.content.sliderResponsiveSettings}
            />
          </CompareRatesContainer>
        </Box>

        <HorizontalRule />

        <ResourcesSection />
        <BrandedCallout
          line1={
            <React.Fragment>
              <strong>
                Got questions for our broker team? Call now on{' '}
                <Tel no="1300 783 854" color={COLOR.WHITE} />
              </strong>
            </React.Fragment>
          }
          line2={
            <React.Fragment>
              <span>Mon to Fri: 8am - 6pm (AEST)</span>
            </React.Fragment>
          }
          cta={
            <Box paddingTop={PADDING.P24} width="210px">
              <Link
                button={BUTTON_STYLE.PRIMARY}
                href={PageData.getAccreditedHashURL}
                trackId="callout-call-btn"
                onClick={scrollHandler}
              >
                Get Accredited
              </Link>
            </Box>
          }
        />
        <ImportantInformation
          data={PageData.content.importantInformationData}
          sectionOneColummClasses="col-10 offset-1 col-lg-5 offset-lg-1"
          sectionTwoColummClasses="col-10 offset-1 col-lg-5 offset-lg-0"
          additionalPaymentsDisclaimerOne
          {...state?.importantInfoData?.[0]}
        />
      </main>
    </Layout>
  );
};

const LinkResourceGroupVideos = () => {
  return (
    <div>
      <VideoResource
        to="https://vimeo.com/736029245"
        title="LatitudeMoney Broker portal training"
        text="Contact Relationship Manager for password"
        location="Broker resources"
      />
      <VideoResource
        to="https://vimeo.com/591300983/2b6fc904ca"
        title="Merging Files in DocuSign"
        location="Broker resources"
      />
    </div>
  );
};

const DesktopResourceSection = () => {
  return (
    <Box
      className="pt-5 mt-0 d-none d-md-block d-lg-block d-xl-block"
      isResponsive
      margin={`${MARGIN.M32} ${MARGIN.MAUTO} ${MARGIN.M64}`}
      id="resources"
    >
      <Heading4 align={ALIGN.CENTER} color={COLOR.BLACK}>
        Broker resources
      </Heading4>
      <Responsive
        alignItems={ALIGN_ITEMS.FLEX_START}
        spacing={MARGIN.M40}
        breakpoint={BREAKPOINT.LG}
      >
        <LinkResourceGroup branded>
          <Heading5
            className="ml-3"
            marginBottom={MARGIN.M16}
            color={COLOR.BLACK}
          >
            Videos
          </Heading5>
          <LinkResourceGroupVideos />
        </LinkResourceGroup>
      </Responsive>
    </Box>
  );
};

const MobileResourceSection = () => {
  return (
    <Box
      className="mb-5 d-block d-md-none d-lg-none d-xl-none"
      marginTop={MARGIN.M48}
      id="resources"
    >
      <Heading4 className="mb-3 ml-3" color={COLOR.BLACK}>
        Broker resources
      </Heading4>
      <StyledAccordion
        titleBgColor="grey-light"
        contentBgColor="grey-light"
        items={[
          {
            title: 'Videos',
            content: <LinkResourceGroupVideos />
          }
        ]}
      />
    </Box>
  );
};

const ResourcesSection = () => {
  return (
    <Box backgroundColor={COLOR.GREY6}>
      <DesktopResourceSection />
      <MobileResourceSection />
    </Box>
  );
};

const CompareRatesContainer = styled(Box.Section)`
  @media (min-width: ${BREAKPOINT.MD}) {
    margin-top: 16px;
    margin-bottom: 16px;
    max-width: 90%;
  }
`;

const StyledAccordion = styled(Accordion)`
  margin: 0 15px;
  .accordion__item {
    border-top: 1px solid #e5e5e5;
    :last-child {
      border-bottom: 1px solid #e5e5e5;
    }
    button {
      background-color: #f8f8f8;
      font-size: 18px;
      &:focus {
        outline: none;
      }
    }
  }

  .accordion__item-body {
    background-color: #f8f8f8;
    div {
      :last-child {
        border-bottom: none;
      }
    }
  }
`;

const StyledFeaturesSlider = styled(FeaturesSlider)`
  div[class*='FeaturesSliderstyled__CardIcon-'] {
    width: 100px !important;
    height: 100px !important;
    @media (min-width: ${BREAKPOINT.LG}) {
      width: 128px !important;
      height: 128px !important;
    }
  }
  li {
    padding-bottom: 12px;
  }
`;

export default Brokers;
