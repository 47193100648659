import React from 'react';
import MediaList from './MediaList';

const MediaSection = () => (
  <React.Fragment>
    <MediaList />
  </React.Fragment>
);

export default MediaSection;
