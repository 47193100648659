import React from 'react';

import { BREAKPOINT } from '@latitude/core/utils/constants';

import PageHeaderSection from '@/components/PageLayout/PageHeader';

import heroImage from '../images/addcard-hero.webp';
import heroImageBackground from '../images/addcard-hero-bg.webp';

function AchPageHeaderSection({ children }) {
  return (
    <div
      className="pageHeader"
      css={`
        && section.lfs-pageheader {
          background-color: #ccc;
          background-image: url(${heroImageBackground});
          background-size: cover;
        }
        && section.lfs-pageheader h1.lfs-pageheader-title {
          color: #0046aa;
        }
        && section.lfs-pageheader div.lfs-pageheader-image img {
          height: 100%;
          padding: 30pt 0;
        }
        @media (min-width: ${BREAKPOINT.LG}) {
          && div.lfs-pageheader-hero > div {
            max-height: 400px;
          }
          && div.HeroContent {
            padding: 0 0;
          }
        }
      `}
    >
      <PageHeaderSection
        title="BECOME THE ULTIMATE SHOPPING TEAM"
        subTitle={null}
        pageImage={heroImage}
        pageImageAlt="28 Degrees cards"
      >
        {children}
      </PageHeaderSection>
    </div>
  );
}

export default AchPageHeaderSection;
