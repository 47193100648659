const trackingPrefix = 'lfs au:loans:personal-loan:calculator:';

// Custom link event names for calculator field interactions
export const customLinkNames = Object.freeze({
  AMOUNT_CHANGE: `${trackingPrefix}loan-amount-change`,
  PERIOD_CHANGE: `${trackingPrefix}payment-period-change`,
  RATE_TYPE_CHANGE: `${trackingPrefix}rate-type-change`,
  RATE_CHANGE: `${trackingPrefix}interest-rate-change`,
  TERM_CHANGE: `${trackingPrefix}loan-term-change`,
  EXTRA_PAYMENT_CHANGE: `${trackingPrefix}extra-payment-change`
});

/**
 * getCustomLinkEventName - work out complete custom tracking link event name
 * @param linkEventName {string} - custom link event name
 * @param linkEventValue {string} - value for custom link event
 * @returns {string}
 */
export const getCustomLinkEventName = (linkEventName, linkEventValue = '') => {
  const eventValue =
    linkEventValue && linkEventValue.length ? `:${linkEventValue}` : '';
  return `${linkEventName}${eventValue}`;
};
