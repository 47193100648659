import styled from 'styled-components';
import { COLOR } from '../../utils/constants';
import imgHorizontalRule from './img/horizontal-rule@3x.png';

const HorizontalRule = styled.div`
  width: 100%;
  height: 32px;
  background: ${({ backgroundColor = COLOR.GREY6 }) =>
    `${backgroundColor} url(${imgHorizontalRule})`};
  background-size: auto 24px;
  background-position: center center;
  background-repeat: repeat-x;
`;

export default HorizontalRule;
