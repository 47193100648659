import React, { useEffect, useContext } from 'react';
import Helmet from 'react-helmet';

import StickyNavigationBranded from '../../components/StickyNavigation/StickyNavigationBranded';
import Layout from '../../components/layout';

import WhyInterestFreeSection from './_whyInterestFree';
import HowPlansWorkSection from './_howPlansWork';
import GetStartedSection from './_getStarted';
import InterestFreeCardsSection from './_interestFreeCards';
import HowToApplySection from './_howToApply';
import AreYouAMerchantSection from './_areYouAMerchant';
import HereToHelpSection from './_hereToHelp';
import ImportantInformationSection from './_importantInformation';
import ShopByRetailerSection from './_shopByRetailer';
import ShopByCategorySection from './_shopByCategory';
import InterestFreeOffersSection from './_interestFreeOffers';
import PAGE_HEADER_ITEMS from './data/interest-free-header.json';
import CarouselHeader from '@/components/Carousel/carouselHeader';
import { PageContext } from '@/context/PageContext';
import { CONTENTFUL_COMPONENTS } from '@/utils/constants';
import HeroBanner from '@/components/lab-components/HeroBanner';
import { useSetState } from '@/utils/hooks';
import '@/components/PageLayout/pageHeader.css';

const pageNavigation = [
  {
    anchor: 'how-plans-work',
    label: 'How it works'
  },
  {
    anchor: 'interest-free-cards',
    label: 'Interest Free cards'
  },
  {
    anchor: 'get-started',
    label: 'Get started'
  },
  {
    anchor: 'how-to-apply',
    label: 'How to apply'
  },
  {
    anchor: 'latest-offers',
    label: 'Latest offers'
  },
  {
    anchor: 'shop-by-category',
    label: 'Shop by category'
  },
  {
    anchor: 'shop-by-retailer',
    label: 'Find a store'
  },
  {
    anchor: 'become-a-merchant',
    label: 'Become a partner'
  },
  {
    anchor: 'here-to-help',
    label: 'Help'
  }
];

function InterestFreePage(props) {
  /** Contentful Data */
  const contentfulPageData = useContext(PageContext);
  const state = contentfulPageData?.updatedBody || {};
  /** - END - */

  return (
    <Layout location={props.location}>
      <Helmet>
        <link
          rel="canonical"
          href="https://www.latitudefinancial.com.au/interest-free/"
        />
        <title>Shop With Interest Free Finance Options | Latitude</title>
        <meta name="description" content="Latitude makes big purchases easier with Interest Free payment plans. Shop confidently with flexible financing options. Browse our credit cards today." />
      </Helmet>

      <main className="navigation-spacer" css="background-color: #f8f8f8;">
        {state?.heroBannerData?.[0] ? (
          <HeroBanner {...state?.heroBannerData[0]} />
        ) : (
          <CarouselHeader HEADER_ITEMS={PAGE_HEADER_ITEMS} />
        )}

        <div
          className="d-none d-lg-block"
          css="position: relative; z-index: 11;"
        >
          <StickyNavigationBranded
            items={pageNavigation}
            offsetElem="[data-sticky-navigation-offset]"
            css={{ backgroundColor: '#ffffff' }}
            {...state?.inPageNavData?.[0]}
          />
        </div>

        <WhyInterestFreeSection id="why-interest-free" />
        <HowPlansWorkSection id="how-plans-work" />
        <InterestFreeCardsSection id="interest-free-cards" />
        <GetStartedSection id="get-started" />
        <HowToApplySection id="how-to-apply" />
        <InterestFreeOffersSection id="latest-offers" />
        <ShopByCategorySection id="shop-by-category" />
        <ShopByRetailerSection id="shop-by-retailer" />
        <AreYouAMerchantSection id="become-a-merchant" />
        <HereToHelpSection id="here-to-help" />
        <ImportantInformationSection />
      </main>
    </Layout>
  );
}

export default InterestFreePage;
