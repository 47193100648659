import React, { useContext } from 'react';
import Hero from '../components/Hero/Hero';
import Layout from '../components/layout';
import Metadata from '../components/Metadata/Metadata';
import { Box } from '../components/Box/Box';
import { BREAKPOINT, COLOR, MARGIN, PADDING } from '../utils/constants';
import utilityHero from '../images/hero/utility-hero.jpg';
import { PageContext } from '@/context/PageContext';
import HeroBanner from '@/components/lab-components/HeroBanner';

const PartnerDiscount = props => {
  /** Contentful Data */
  const contentfulPageData = useContext(PageContext);
  const state = contentfulPageData?.updatedBody || {};
  /** - END - */

  return (
    <Layout location={props.location}>
      <main className="navigation-spacer">
        <Metadata
          title="CreditLine Account Keeping Fee Waiver Form"
          description=""
          canonical="/partner-discount/"
        />
        
        {state?.heroBannerData?.[0] ? (
          <HeroBanner {...state?.heroBannerData[0]} />
        ) : (
          <Hero h1="CreditLine" h2="Apple Staff Offer" bg={utilityHero} darkBg />
        )}

        <FormSection />
      </main>
    </Layout>
  )
};


const FormSection = () => (
  <Box
    backgroundColor={COLOR.GREY6}
    css={`
      padding: ${PADDING.P16} 0;
      @media (min-width: ${BREAKPOINT.SM}) {
        padding: ${PADDING.P48} ${PADDING.P16};
      }
    `}
  >
    <Box isResponsive margin={`0 ${MARGIN.MAUTO}`}>
      <iframe
        css={`
          width: 100%;
          height: 1380px;
          padding: ${PADDING.P16};

          background-color: ${COLOR.WHITE};
          border: solid 1px ${COLOR.GREY16};

          @media (min-width: ${BREAKPOINT.MD}) {
            padding: ${PADDING.P24};
            height: 1360px;
          }
          @media (min-width: ${BREAKPOINT.LG}) {
            height: 1340px;
          }
          @media (min-width: ${BREAKPOINT.XL}) {
            height: 1110px;
          }
        `}
        title="afscreditline-annual-fee-waiver-form"
        className="eform-iframe"
        src="https://eform.latitudefinancial.com.au/eform/Page.do?eFormName=afscreditline_annual_fee_waiver_form"
      />
    </Box>
  </Box>
);

export default PartnerDiscount;
