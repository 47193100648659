/* eslint-disable global-require */
import React from 'react';
import styled from 'styled-components';

import { Heading4 } from '@latitude/heading';
import { Text } from '@latitude/text';
import { Box } from '@latitude/box';
import { Link } from '@latitude/link';
import SvgInline from '@latitude/svg-inline';
import {
  BREAKPOINT,
  COLOR,
  ALIGN,
  BUTTON_STYLE
} from '@latitude/core/utils/constants';

import calloutLeftBg from './images/confetti_left.webp';
import calloutRightBg from './images/confetti_right.webp';

function HaveAQuestionFlexiSection({ anchor, heading }) {
  return (
    <BoxQuestion id={anchor}>
      <Heading4
        align={ALIGN.CENTER}
        color={COLOR.BLACK}
        css="margin-bottom:8px;"
      >
        {heading}
      </Heading4>
      <Text align={ALIGN.CENTER} color={COLOR.BLACK} css="margin-bottom:24px;">
        Check our FAQs page to find answers.
      </Text>
      <Link
        button={BUTTON_STYLE.TERTIARY}
        href="/credit-cards/28-degrees/faqs/"
        trackId="have-a-question-banner__find-out-more-button"
        disableDefaultEventTracking
        css={`
          display: inline-block;
          svg { 
            position: relative;
            bottom: 2px;
            width: 35px;
          }
          &:hover{
            svg *{
              fill #fff !imporatant;
            }
          }
        `}
      >
        Explore FAQ
        <SvgInline name="external-link" />
      </Link>
    </BoxQuestion>
  );
}

const BoxQuestion = styled(Box)`
  padding: 40px 0;
  background-color: #e8f4ff;
  background-image: url(${calloutLeftBg}), url(${calloutRightBg});
  background-position: left top, right 122%;
  background-repeat: no-repeat, no-repeat;
  text-align: center;
  [class*='Linkstyled__StyledLink']:hover {
    svg * {
      fill: #fff !important;
    }
  }
  @media (min-width: ${BREAKPOINT.LG}) {
    padding: 72px 0;
  }
`;

export default HaveAQuestionFlexiSection;
