import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import smoothscroll from 'smoothscroll-polyfill';
import AccordionButton from './AccordionButton';
import AccordionContent from './AccordionContent';
import './_accordion.scss';

const getBackgroundCssClass = color => {
  switch (color) {
    case 'white':
      return 'bg-white';
    case 'blue-light':
      return 'bg-brand-lighter';
    case 'grey-light':
      return 'bg-lightest';
    default:
      return 'bg-white';
  }
};

const Accordion = props => {
  const {
    borderedChildren,
    className,
    style,
    contentBgColor,
    fgColor,
    highlightActive,
    isChild,
    items,
    titleBgColor,
    minimumMargin,
    groupActiveIndex,
    setGroupActiveIndex
  } = props;
  const defaultActiveIndex = items.findIndex(item => item.initiallyActive);

  const [activeIndex, setActiveIndex] = useState(defaultActiveIndex); // set the active index, because on load the index is "-1" nothing is set

  const hasTwoLevels = items.some(item => Array.isArray(item.content)); // boolean: whether acc has 2 levels

  useEffect(() => {
    smoothscroll.polyfill();
  }, []);

  /*
   * Provides support for multi accordion interaction
   * Expands/collapses group of accordions based on active index
   */

  useEffect(() => {
    setActiveIndex(groupActiveIndex);
  }, [groupActiveIndex]);

  return (
    <div
      className={classnames(
        'accordion',
        hasTwoLevels && 'accordion--two-level',
        isChild && 'accordion--child',
        isChild && borderedChildren && 'accordion--bordered',
        highlightActive && 'accordion--hl-active',
        minimumMargin && 'accordion--min-margin',
        className
      )}
      style={style}
    >
      {items.map((item, index) => {
        const isOpen = activeIndex === index;
        const buttonId = `${item.id}-button`;
        const isParent = Array.isArray(item.content);
        const showHighlightedActive = highlightActive && isOpen;
        return (
          <div
            data-id={item.id}
            className={classnames('accordion__item', isOpen && 'is-open')}
            key={item.id}
          >
            <AccordionButton
              buttonid={buttonId}
              fgColor={(showHighlightedActive && 'white') || fgColor}
              onClick={() => {
                const activeIndex = isOpen ? -1 : index;
                setActiveIndex(activeIndex);
                setGroupActiveIndex && setGroupActiveIndex(activeIndex);
              }}
              className={classnames(
                (showHighlightedActive && 'bg-brand-bright') ||
                  getBackgroundCssClass(titleBgColor),
                !isChild && 'font-weight-bold'
              )}
              itemId={item.id}
              isOpen={isOpen}
              iconName={item.iconName}
              title={item.title}
              activeIndex={activeIndex}
              isSticky={!isChild}
            />
            {isOpen && (
              <AccordionContent
                id={item.id}
                buttonId={buttonId}
                isParent={isParent}
                isChild={isChild}
                contentBgColor={getBackgroundCssClass(contentBgColor)}
                content={item.content}
                activeIndex={activeIndex}
                nestedAccordion={
                  <Accordion
                    isChild
                    titleBgColor="white"
                    contentBgColor="white"
                    fgColor={fgColor}
                    borderedChildren={borderedChildren}
                    items={item.content}
                  />
                }
              />
            )}
          </div>
        );
      })}
    </div>
  );
};

Accordion.defaultProps = {
  fgColor: 'grey-dark'
};

export default Accordion;
