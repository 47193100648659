import React, { useState, useEffect } from 'react';
import { ALIGN, BREAKPOINT, COLOR } from '@latitude/core/utils/constants';
import { Box } from '@latitude/box';
import { Heading4 } from '@latitude/heading';
import { TabNavigation } from '@latitude/tab-navigation';
import videoCoverImage from './images/partner-video-cover-image.webp';
import PromoListBox, { PromoSection } from '../../components/Promo/Promo.list';
import pageData from '../../data/pages/interest-free/become-a-partner.json';
import {
  AnalyticsContext,
  AnalyticsLocationProvider,
} from 'latitude-analytics';


const tabData = [
  { id: '#merchant', label: 'For Merchants' },
  { id: '#consumer', label: 'For your Customers' }
];


const PartneringBenifits = () => (
  <Box
    id="payment-options"
    css={`
      background-color: ${COLOR.WHITE};
      padding-top: 30px;
      @media (min-width: ${BREAKPOINT.LG}) {
        background-color: ${COLOR.WHITE};
      }
    `}
  >
    <Box backgroundColor={COLOR.WHITE} id="how-it-works" >
      <Heading4 align={ALIGN.CENTER} color={COLOR.BLACK} css={`
            @media (max-width: ${BREAKPOINT.SM}) {
              padding: 0px 10px 0px 10px;
            }
      `}>
      Partnering with us has benefits
      </Heading4>
     <Tabs/>
    </Box>
  </Box>
);

const Tabs = () => {
  const [activeSection, setActiveSection] = useState(null);
  const defaultSection = '#merchant';

  useEffect(() => {
    setActiveFromHash();
    window.addEventListener('hashchange', setActiveFromHash, false);
  }, []);

  const setActiveFromHash = () => {
    const { hash } = location;
    if (hash && hash !== '') {
      const tabIds = tabData.map(tab => tab.id);
      setActiveSection(false); // unset, forces TabNavigation to rerender
      if (tabIds.includes(hash.toLowerCase())) {
        setActiveSection(hash.toLowerCase());
      } else {
        setActiveSection(defaultSection);
      }
    } else {
      setActiveSection(defaultSection);
    }
  };

  const onClickTabHandler = linkId => {
    setActiveSection(linkId);
  };

  return (
    <>
      {activeSection && (
        <AnalyticsLocationProvider location="Partnering Benefits">
        <TabNavigation
          basepath="/interest-free/business/"
          data={tabData}
          defaultActiveTabId={activeSection}
          onTabClick={onClickTabHandler}
          css={`
          background-color:${COLOR.WHITE} !important;
            > a {
              font-weight: 300;
              letter-spacing: -0.35px;
              border-bottom: solid 4px ${COLOR.WHITE} !important;
              padding-left: 24px;
              padding-right: 24px;

              &.isActive {
                font-weight: 600;
                border-bottom: solid 4px ${COLOR.BLUE} !important;
              }
            }
            > div {
              background-color:${COLOR.WHITE} !important;
            }

            @media (max-width: ${BREAKPOINT.SM}) {
              justify-content: space-evenly;
            }
            
          `}
        />
        </AnalyticsLocationProvider>
      )}
      {activeSection == '#merchant' && (
              <Box display={activeSection == '#merchant' ? 'block' : 'none'} css={`padding:0px !important; background-color:${COLOR.BLUE_SKY};` }> 
              <PromoSection>
                <PromoListBox
                  frameAlignment="left"
                  frameBackgroundColor="#FFFFF"
                  frameBorderColor="#C31B6C"
                  verticalAlignContent={false}
                  frameVideoSrc="https://youtu.be/2s7pBKMzsng"
                  frameVideoImage={videoCoverImage}
                  listData={pageData.partneringBenifits}
                />
              </PromoSection>
          </Box>
      )}

      <Box display={activeSection == '#consumer' ? 'block' : 'none'} css={`padding:0px !important; background-color:${COLOR.BLUE_SKY};` }>
      <PromoSection>
            <PromoListBox
              listData={pageData.consumerList}
            />
          </PromoSection>
      </Box>
    </>
  );
};

export default PartneringBenifits;
