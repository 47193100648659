import React, { useContext } from 'react';
import Helmet from 'react-helmet';
import styled from 'styled-components';
import { StaticImage } from 'gatsby-plugin-image';
import { Box, Flex } from '@latitude/box';
import { CardIcon } from '@latitude/card-icon';
import { FeatureTiles } from '@latitude/feature-tiles';
import { Heading4, Heading5 } from '@latitude/heading';
import { Lframe } from '@latitude/lframe';
import { Link } from '@latitude/link';
import Section from '@latitude/section';
import SvgInline from '@latitude/svg-inline';
import { Text } from '@latitude/text';
import { Vertical } from '@latitude/spacing';
import {
  ALIGN,
  BREAKPOINT,
  COLOR,
  FLEX_WRAP,
  FONT_SIZE,
  JUSTIFY_CONTENT,
  MARGIN,
  PADDING
} from '@latitude/core/utils/constants';

import HeroBranded from '../../components/HeroBranded/HeroBranded';
import HorizontalRule from '../../components/HorizontalRule/HorizontalRule';
import Layout from '../../components/layout';
import StickyNavigationBranded from '../../components/StickyNavigation/StickyNavigationBranded';
import PageData from './data/our-company.json';
import TeamData from './data/_our-team';
import imageHero from './images/lshape-hero.png';
import imageShowCare from './images/show-care.png';
import imageBeCurious from './images/be-curious.png';
import imageActRight from './images/act-right.png';
import { PageContext } from '@/context/PageContext';
import HeroBanner from '@/components/lab-components/HeroBanner';

const Anchor = styled.div`
  width: 0;
  height: 0;
  position: relative;
  top: -30px;
`;

const OurCompanyPage = props => {
  /** Contentful Data */
  const contentfulPageData = useContext(PageContext);
  const state = contentfulPageData?.updatedBody || {};
  /** - END - */

  return (
    <Layout location={props.location}>
      <main className="navigation-spacer">
        <Helmet>
          <link
            rel="canonical"
            href="https://www.latitudefinancial.com.au/about-us/our-company/"
          />
          <title>{PageData.meta.title}</title>
          <meta name="description" content={PageData.meta.description} />
        </Helmet>
        {state?.heroBannerData?.[0] ? (
          <HeroBanner {...state?.heroBannerData[0]} />
        ) : (
          <>
            <Lframe bgImage={imageHero} />
            <HeroBranded
              className="bg-lightest"
              title={PageData.hero.title}
              text={PageData.hero.text}
              breadcrumbs={PageData.hero.breadcrumbs}
              css={`
                @media (min-width: ${BREAKPOINT.MD}) {
                  .HeroTitle {
                    padding-top: 80px;
                  }
                  .HeroText {
                    padding-bottom: 30px;
                  }
                }
              `}
            />
          </>
        )}
        
        <StickyNavigationBranded
          items={PageData.stickyNavigation}
          trackId="our-company-stickynav"
          {...state?.inPageNavData?.[0]}
        />

        <Anchor id="who-are-we" />
        <OurStorySection />
        <HorizontalRule backgroundColor={COLOR.GREY5} />

        <Anchor id="our-values" />
        <OurValues />

        <Anchor id="leadership-team" />
        <FeatureTiles
          bgColor={COLOR.WHITE}
          useLocalAssets
          heading="Our leadership team"
          tiles={TeamData}
        />
        <MediaEnquiry />
      </main>
    </Layout>
  );
};

const OurStorySection = () => {
  return (
    <Section className="bg-lightest">
      <div className="row justify-content-center">
        <div className="col-md-10 col-lg-8 text-left">
          <Vertical spacing={MARGIN.TEXT}>
            <Heading4
              color={COLOR.BLACK}
              align={ALIGN.CENTER}
              marginTop={MARGIN.M48}
            >
              Our story
            </Heading4>
            <Text
              fontSize={FONT_SIZE.NORMAL}
              marginTop={MARGIN.M32}
              align={ALIGN.LEFT}
            >
              Our mission is to be the preferred partner, to help businesses
              grow and people shop and finance their lives. We help fuel hopes,
              seize opportunities and solve unexpected problems. We&apos;re here
              for life&apos;s moments, big or small.
            </Text>
            <Text fontSize={FONT_SIZE.NORMAL} align={ALIGN.LEFT}>
              We help our customers shop and purchase the things they need with
              interest free instalments plans and personal loans.
            </Text>
            <Text fontSize={FONT_SIZE.NORMAL} align={ALIGN.LEFT}>
              We help our partners with easy payment and lending solutions, fast
              decisions, great insights and sales opportunities with our
              millions of customers.
            </Text>
            <Text fontSize={FONT_SIZE.NORMAL} align={ALIGN.LEFT}>
              We are always motivated by our responsibility to deliver great
              outcomes for people underpinned by our values: to act right, be
              curious, show care.
            </Text>
            <Text fontSize={FONT_SIZE.NORMAL} align={ALIGN.LEFT}>
              That&apos;s true partnership:
            </Text>
            <Text
              align={ALIGN.LEFT}
              fontSize={FONT_SIZE.NORMAL}
              marginBottom={MARGIN.M40}
            >
              Partners in Shopping. Partners in Lending.
            </Text>
            <Heading4
              align={ALIGN.CENTER}
              color={COLOR.BLACK}
              marginTop={MARGIN.M40}
            >
              Our History
            </Heading4>
            <Text
              align={ALIGN.LEFT}
              fontSize={FONT_SIZE.NORMAL}
              marginTop={MARGIN.M32}
            >
              Latitude has a long history of over 100 years dating back to the
              1920s when Australian Guarantee Corporation ('AGC'), one of
              Latitude's predecessor businesses, was founded to provide finance
              for the purchasing of household items. Latitude's corporate
              history traces back to a range of acquisitions made by GE in
              Australia and New Zealand, including Nissan Finance, AVCO
              Financial (including Hallmark Insurance) and AGC.
            </Text>
            <Text fontSize={FONT_SIZE.NORMAL} align={ALIGN.LEFT}>
              In 2015, backed by Värde Partners, KKR and Deutsche Bank, we
              transitioned from GE into what we are today &mdash; a digital
              payments, instalments and lending business called Latitude.
            </Text>
            <Text
              align={ALIGN.LEFT}
              fontSize={FONT_SIZE.NORMAL}
              marginBottom={MARGIN.M56}
            >
              In April 2021, Latitude listed on the ASX. In late 2021 Symple
              Loans was acquired, followed by the launch of LatitudePay in
              Singapore.
            </Text>
          </Vertical>
          <StaticImage
            src="./images/office.png"
            alt="Latitude Office"
            placeholder="blurred"
          />
        </div>
      </div>
    </Section>
  );
};

const OurValues = () => {
  return (
    <Section className="w-100 bg-lightest">
      <Heading4 color={COLOR.BLACK} align={ALIGN.CENTER} marginTop={MARGIN.M32}>
        Our values
      </Heading4>
      <div
        className="row"
        css={`
          h5 {
            padding-top: ${PADDING.P24};
          }
          @media (min-width: ${BREAKPOINT.SM}) {
            h5 {
              padding-top: ${PADDING.P48};
            }
          }
        `}
      >
        <div className="col-12 col-lg-4 mb-4">
          <CardIcon
            newBrand
            thumbnail
            image={
              <img
                style={{ maxWidth: '200px' }}
                src={imageShowCare}
                alt="Show Care"
              />
            }
            title="Show Care"
          >
            <Text fontSize={FONT_SIZE.NORMAL} align={ALIGN.CENTER}>
              We are kind to one another. It&apos;s a simple yet profound thing
              where we ask more of ourselves and in doing so unleash the power
              of our community.
            </Text>
          </CardIcon>
        </div>
        <div className="col-12 col-lg-4 mb-4">
          <CardIcon
            newBrand
            thumbnail
            image={
              <img
                style={{ maxWidth: '200px' }}
                src={imageBeCurious}
                alt="Be Curious"
              />
            }
            title="Be Curious"
          >
            <Text fontSize={FONT_SIZE.NORMAL} align={ALIGN.CENTER}>
              With curiosity we continue our fascination with the world.
              We&apos;re constantly experimenting, challenging ourselves and
              encouraging others to share ideas.
            </Text>
          </CardIcon>
        </div>
        <div className="col-12 col-lg-4 mb-4">
          <CardIcon
            newBrand
            thumbnail
            image={
              <img
                style={{ maxWidth: '200px', padding: '50px 0 0 0' }}
                src={imageActRight}
                alt="Act Right"
              />
            }
            title="Act Right"
          >
            <Text fontSize={FONT_SIZE.NORMAL} align={ALIGN.CENTER}>
              From the little moments to the big decisions, we take our
              responsibility seriously. We always step outside what feels
              comfortable to stand up for what we believe is right.
            </Text>
          </CardIcon>
        </div>
      </div>
    </Section>
  );
};

const MediaEnquiry = () => {
  return (
    <Flex
      backgroundColor={COLOR.BLUE_BABY}
      justifyContent={JUSTIFY_CONTENT.CENTER}
      padding={PADDING.P32}
      flexWrap={FLEX_WRAP.WRAP}
    >
      <Box
        paddingLeft={PADDING.P16}
        paddingRight={PADDING.P16}
        css={`
          text-align: ${ALIGN.RIGHT};
        `}
      >
        <SvgInline name="icon-mail" />
      </Box>
      <Box paddingLeft={PADDING.P16} paddingRight={PADDING.P16}>
        <Heading5
          color={COLOR.BLACK}
          marginTop={MARGIN.M16}
          marginBottom={MARGIN.M8}
          css={`
            @media (max-width: 521px) {
              text-align: center;
              font-size: 16px;
            }
          `}
        >
          For media and journalist enquiries only.
        </Heading5>
        <div
          css={`
            @media (max-width: 660px) {
              text-align: center;
            }
            @media (max-width: 521px) {
              font-size: 14px;
            }
          `}
        >
          <Link
            trackId="media-enquiries-email"
            trackEventData={{
              location: 'For media and journalist enquiries only.'
            }}
            className="link--blue text-link__exclude"
            href="mailto:media.enquiries@latitudefinancial.com"
          >
            media.enquiries@latitudefinancial.com
          </Link>
        </div>
      </Box>
    </Flex>
  );
};

export default OurCompanyPage;
