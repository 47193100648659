import React from 'react';
import Helmet from 'react-helmet';
import { SecondaryHero } from '@latitude/hero';
import { Heading2 } from '@latitude/heading';
import { ALIGN } from '@latitude/core/utils/constants';
import Layout from '../../components/layout';
import Section from '../../components/Section/Section';
import TermAndConditionsPage from '../../data/pages/hardship-care/terms-and-conditions.mdx';

const TermsAndConditions = ({ location }) => {
  return (
    <Layout location={location}>
      <main className="navigation-spacer">
        <Helmet>
          <title>Hardship Care Terms &amp; Conditions </title>
          <meta
            name="description"
            content="Hardship Care Terms &amp; Conditions"
          />
          <link
            href="https://www.latitudefinancial.com.au/hardship-care/terms-and-conditions"
            rel="canonical"
          />
        </Helmet>

        <SecondaryHero heading="Hardship Care" />

        <Section id="terms">
          <Heading2 align={ALIGN.CENTER}>Terms &amp; Conditions</Heading2>
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
              <TermAndConditionsPage />
            </div>
          </div>
        </Section>
      </main>
    </Layout>
  );
};

export default TermsAndConditions;
