/* eslint-disable react/no-danger */
import React, { useState, useEffect, useContext } from 'react';
import ReactHTMLParser from 'react-html-parser';
import Helmet from 'react-helmet';
import { marked } from 'marked';
import { FeaturesSlider } from '@latitude/features-slider';
import { getUrlFromEnv } from '@/utils/getUrlFromEnvUtil';
import HowToApply from '@latitude/how-to-apply';
import Layout from '@/components/layout';
import StickyNavigationBranded from '@/components/StickyNavigation/StickyNavigationBranded';
import Faq from '@/components/Faq/Faq';
import LoanRates from '@/components/LoanRates/LoanRates';
import PLData from '@/data/pages/personal-loan.json';
import HowToApplyList from '@/components/HowToApply/HowToApplyList';
import { EstimateRateWidgetSection } from '@/components/EstimateRateWidget/EstimateRateWidgetSection';
import { redirectUrl } from '@/components/EstimateRateWidget/utils';
import CalculatorTile from '@/components/PersonalLoanSoftQuote/CalculatorTile';
import { PLEligibilityCriteria } from '../../components/PersonalLoanPageContent/PersonalLoanPageContent';
import FeesAndCharges from '@/components/PersonalLoanDetails/PersonalLoanDetails';
import { ImportantInformation } from '@latitude/important-information';
import Lframe from '@/components/Lframe/Lframe';
import HeroBranded from '@/components/HeroBranded/HeroBranded';
import {
  BREAKPOINT,
  COLOR,
  SOFT_QUOTE_LOANS_AU_URL,
  SITE_URL
} from '@/utils/constants';
import footerData from '@/data/json/footer.json';
import PageData from '../../data/pages/motorbike-loan.json';
import { PageContext } from '@/context/PageContext';
import { CONTENTFUL_COMPONENTS } from '@/utils/constants';
import { useSetState } from '@/utils/hooks';
import HeroBanner from '@/components/lab-components/HeroBanner';

const MotorbikeLoanPage = ({ location }) => {
  const applyUrl = `${getUrlFromEnv(
    'funnel-start-loans'
  )}${SOFT_QUOTE_LOANS_AU_URL}`;

  // soft quote
  const [softQuoteHref, setSoftQuoteHref] = useState();

  /** Contentful Data */
  const contentfulPageData = useContext(PageContext);
  const state = contentfulPageData?.updatedBody || {};

  const featureSliderData = [
    {
      heading: 'Why choose a Latitude loan?',
      featureCards: PLData.content.whyChooseLatitude
    }
  ];
  /** - END - */

  useEffect(() => {
    sessionStorage.setItem('purpose', 'purpose'); // reset session val used in `EstimateRateWidgetSection`
    setSoftQuoteHref(redirectUrl('au', true));
  }, []);

  return (
    <Layout location={location} customFooter={footerData} hasStickyNav>
      <main
        className="navigation-spacer navigation-spacer--hasStickyNav"
        css="background-color:#f8f8f8;"
      >
        <Helmet>
          <link rel="canonical" href={`${SITE_URL}${location.pathname}`} />
          <title>{PageData.metaTitle}</title>
          <meta name="description" content={PageData.metaDesc} />
        </Helmet>

        {state?.heroBannerData?.[0] ? (
          <HeroBanner {...state?.heroBannerData[0]} />
        ) : (
          <>
            <Lframe motorbikeLoans ariaLabel="motorbike loan" role="img" />
            <HeroBranded
              css={`
                background: ${COLOR.GREY6};
                @media (max-width: ${BREAKPOINT.MD}) {
                  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.13);
                  .HeroContent {
                    background-color: ${COLOR.WHITE};
                  }
                }
              `}
              title={<>Motorbike Loan</>}
              text="Whether you are looking for a new car, used car, motorbike or caravan, we're your go to for car finance."
              buttonProps={{
                href: applyUrl,
                children: 'Get Rate Estimate',
                trackId: 'hero-get-started',
                width: '160px'
              }}
              trackEventData={{
                category: 'cta',
                action: 'quote-link'
              }}
            />
          </>
        )}

        <div className="d-none d-lg-block" css="position:relative; z-index:10;">
          <StickyNavigationBranded
            items={PLData.nav}
            phoneNumber={PLData.content.phoneNumber}
            ctaHref={applyUrl}
            ctaText="Get Rate Estimate"
            offsetElem="[data-sticky-navigation-offset]"
            trackEventData={{
              category: 'cta',
              action: 'quote-link',
              location: 'sticky'
            }}
            trackId="sticky-get-started"
            {...state?.inPageNavData?.[0]}
          />
        </div>
        <EstimateRateWidgetSection
          purpose="purpose"
          parentOnChangeEvent={() => {
            setSoftQuoteHref(redirectUrl());
          }}
        />
        <LoanRates
          isBranded
          rateBoxType="variableSym"
          rateBoxType2="fixedSym"
          fees={PLData.content.aboutTheLoan}
          determineRateLink
        />
        <FeesAndCharges data={PLData.content.feesAndCharges} />
        <CalculatorTile motor1 />
        <FeaturesSlider
          key={(state?.featureSliderData?.[0] || featureSliderData[0]).heading}
          id="why-us"
          data={
            (state?.featureSliderData?.[0] || featureSliderData[0]).featureCards
          }
          className="pt-4 why-choose pt-md-5"
          heading={
            (state?.featureSliderData?.[0] || featureSliderData[0]).heading
          }
          subheading={
            (state?.featureSliderData?.[0] || featureSliderData[0]).description
          }
          css={`
            h2 {
              @media (min-width: ${BREAKPOINT.SM}) {
                font-size: 32px !important;
                line-height: 36px !important;
              }
            }
            && {
              background-color: #f8f8f8;
              padding-top: 40px !important;
              padding-bottom: 40px;

              .card-icon__image svg {
                fill: inherit !important;
                stroke: inherit !important;
              }

              @media (min-width: ${BREAKPOINT.LG}) {
                padding-top: 72px !important;
                padding-bottom: 49px;
              }
            }
          `}
        />
        <HowToApply
          id="how-to-apply"
          box1={<HowToApplyList variant="sym" />}
          box2={<PLEligibilityCriteria />}
        />
        <Faq data={PLData.content.faq} {...state?.faqData?.[0]} />
        <ImportantInformation
          data={require('@/data/json/disclaimer/personal-loan1.json')}
          sectionOneColummClasses="col-10 offset-1 col-lg-5 offset-lg-1"
          sectionTwoColummClasses="col-10 offset-1 col-lg-5 offset-lg-0"
          additionalPaymentsDisclaimerOne
          {...state?.importantInfoData?.[0]}
        />
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify({
              '@context': 'http://schema.org',
              '@type': 'LoanOrCredit',
              name: PLData.content.loanDetails.personal.name,
              loanTerm: PLData.content.loanDetails.personal.loanTerm,
              annualPercentageRate:
                PLData.content.loanDetails.personal.annualPercentageRate,
              amount: PLData.content.loanDetails.personal.amount
            })
          }}
        />
        {/* </Box> */}
      </main>
    </Layout>
  );
};

export default MotorbikeLoanPage;
