import React from 'react';
import classnames from 'classnames';
import './_callout.scss';

const component = 'callout';

const CalloutTitle = props => {
  const { className, title } = props;

  return (
    <h4 className={classnames(`${component}__title`, className)}>{title}</h4>
  );
};

export default CalloutTitle;
