import React from 'react';
import Helmet from 'react-helmet';
import styled from 'styled-components';
import Hero from '../../components/Hero/Hero';
import Layout from '../../components/layout';
import PageData from '../../data/pages/campaigns/closed.json';
import breakpoints from '../../data/json/breakpoints.json';

const Page = props => (
  <Layout location={props.location}>
    <main className="navigation-spacer">
      <Helmet>
        <link
          rel="canonical"
          href="https://www.latitudefinancial.com.au/campaigns/closed"
        />
        <title>{PageData.metaTitle}</title>
        <meta name="description" content={PageData.metaDesc} />
      </Helmet>

      <StyledHero
        h1="Offer ended"
        intro="This offer has ended and is no longer available."
        introTextcolor="blue"
        bg={require('../../images/hero/egg-shell-hero.jpg')}
      />
    </main>
  </Layout>
);

const StyledHero = styled(Hero)`
  min-height: 365px;
  background-position: top right;

  @media (min-width: ${breakpoints.lg}px) {
    background-position: top 40% center;
  }
`;
export default Page;
