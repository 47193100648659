import React, { useState } from 'react';
import { AnalyticsLocationProvider } from 'latitude-analytics';
import styled from 'styled-components';
import { Box, Flex } from '../Box/Box';
import {
  MARGIN,
  BREAKPOINT,
  LOAN_PAY_FREQUENCY,
  COLOR,
  PADDING,
  PL_APPLY_CTA_TRACK_ID
} from '../../utils/constants';
import CalculatorTotals from './CalculatorTotals';
import CalculatorFields from './CalculatorFields';

const StyledCalculatorFlex = styled(Flex)`
  flex-direction: column;
  @media (min-width: ${BREAKPOINT.LG}) {
    flex-direction: row;
  }
`;

const StyledDisclaimerBox = styled(Box)`
  padding: ${PADDING.P0} ${PADDING.P48} ${PADDING.P24} ${PADDING.P48};
  background-color: ${COLOR.BLUE_DEEP};
  margin-top: 0;
  width: 100vw;
  margin-left: calc(-50vw + 50%);

  @media (min-width: ${BREAKPOINT.LG}) {
    padding-top: 0;
    margin-top: ${MARGIN.M32};
    background-color: transparent;
    margin-left: 0;
    width: 100%;
  }
`;

const PersonalLoanCalculator = ({
  salesMode = false,
  disclaimerContent,
  applyUrl,
  applyCTATrackId,
  renderCalculatorIntro,
  loans1
}) => {
  const [repaymentAmount, setRepaymentAmount] = useState(0);
  const [totalInterestPaid, setTotalInterestPaid] = useState(0);
  const [totalAmountPaid, setTotalAmountPaid] = useState(0);
  const [repaymentPeriod, setRepaymentPeriod] = useState(
    LOAN_PAY_FREQUENCY.MONTHLY
  );
  const [extraRepayments, setExtraRepayments] = useState(0);
  const [interestSaved, setInterestSaved] = useState(0);
  const [timeSavedYears, setTimeSavedYears] = useState(0);
  const [timeSavedMonths, setTimeSavedMonths] = useState(0);
  const [userInputLoanAmount, setUserInputLoanAmount] = useState(false);
  const [userInputRepaymentYears, setUserInputRepaymentYears] = useState(false);

  return (
    <AnalyticsLocationProvider location="Calculate your repayments">
      <Box>
        <StyledCalculatorFlex>
          <CalculatorFields
            loans1={loans1}
            salesMode={salesMode}
            setRepaymentAmount={setRepaymentAmount}
            setTotalInterestPaid={setTotalInterestPaid}
            setTotalAmountPaid={setTotalAmountPaid}
            repaymentPeriod={repaymentPeriod}
            setRepaymentPeriod={setRepaymentPeriod}
            extraRepayments={extraRepayments}
            setInterestSaved={setInterestSaved}
            setTimeSavedMonths={setTimeSavedMonths}
            setTimeSavedYears={setTimeSavedYears}
            setUserInputLoanAmount={setUserInputLoanAmount}
            setUserInputRepaymentYears={setUserInputRepaymentYears}
          />
          <CalculatorTotals
            loans1={loans1}
            salesMode={salesMode}
            repaymentPeriod={repaymentPeriod}
            repaymentAmount={repaymentAmount}
            totalInterestPaid={totalInterestPaid}
            totalAmountPaid={totalAmountPaid}
            timeSavedMonths={timeSavedMonths}
            timeSavedYears={timeSavedYears}
            interestSaved={interestSaved}
            extraRepayments={extraRepayments}
            setExtraRepayments={setExtraRepayments}
            applyUrl={applyUrl}
            applyCTATrackId={applyCTATrackId || PL_APPLY_CTA_TRACK_ID}
            renderIntro={renderCalculatorIntro}
            userInputLoanAmount={userInputLoanAmount}
            userInputRepaymentYears={userInputRepaymentYears}
          />
        </StyledCalculatorFlex>
        <StyledDisclaimerBox>{disclaimerContent}</StyledDisclaimerBox>
      </Box>
    </AnalyticsLocationProvider>
  );
};

export default PersonalLoanCalculator;
