/* eslint-disable import/no-unresolved */
import React from 'react';
import Helmet from 'react-helmet';
import styled from 'styled-components';
import { Heading4 } from '@latitude/heading';
import {
  BREAKPOINT,
  COLOR,
  ALIGN,
  FONT_SIZE,
  MARGIN
} from '@latitude/core/utils/constants';
import { Text } from '@latitude/text';
import { CardGroup, CardGroupItem } from '../components/CardGroup/CardGroup';
import Layout from '../components/layout';
import HeroBranded from '../components/HeroBranded/HeroBranded';
import { PromoSection } from '../components/Promo/Promo';
import { Link } from '@latitude/link';
import SvgInline from '../components/SvgInline/SvgInline';
import { Chevron } from '@latitude/chevron';
import { Heading6 } from '../components/Heading/Heading';
import heroImageTablet from '../images/blue-accent-banner.webp';
import heroImageDesktop from '../images/blue-accent-banner.webp';
import mobileHeroImage from '../images/blue-accent-banner-mobile.webp';

import './latitude-cyber-incident.css';

const heading = 'Latitude Cyber Incident';

const ItemLink = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 27px;
  padding: 35px 24px;
  background-color: ${COLOR.WHITE};
  border-radius: 4px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.25);
  transition: all 150ms ease-in-out;

  &:hover {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.49);
    transition: all 150ms ease-in-out;
  }
`;

const ItemTitle = styled(Heading6)`
  margin-bottom: 0;
  flex-grow: 1;
  color: ${COLOR.BLACK};
  @media (min-width: ${BREAKPOINT.XL}) {
    font-size: 15px;
  }
`;

const ItemIcon = styled(SvgInline)`
  width: 40px;
  min-width: 40px;
  margin-right: 13px;
`;

const ItemChevron = styled(Chevron)``;

const StyledHeroBranded = styled(HeroBranded)`
  background-image: url(${mobileHeroImage});
  background-size: cover;
  background-position: center center;
  background-color: #cfeafe;
  background-repeat: no-repeat;
  height: 100%;
  .HeroContent {
    background-color: #cfeafe;
    width: 100%;
  }
  @media (max-width: ${BREAKPOINT.MD}) {
    [class^='HeroBranded__HeroImage'] {
      display: none;
    }

    .HeroContent {
      background-color: transparent;
      height: 320px;
      padding: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;

      > div {
        margin: 0 0 0 21px;
      }
    }
  }

  @media (min-width: ${BREAKPOINT.MD}) {
    background-image: url(${heroImageTablet});
    background-size: cover;
    height: 300px;
    .HeroContent {
      background-color: transparent;
    }
  }
  @media (min-width: ${BREAKPOINT.LG}) {
    height: 320px;
  }
  @media (min-width: ${BREAKPOINT.XL}) {
    background-image: url(${heroImageDesktop});
  }
`;

const StyledPromoSection = styled(PromoSection)`
  background-color: ${COLOR.GREY6};
`;

const StyledText = styled(Text)`
  font-size: ${FONT_SIZE.LARGE};
  margin-bottom: ${MARGIN.M24};
  margin-left: auto;
  margin-right: auto;
  text-align: left;
  background-color: white;
  @media (min-width: ${BREAKPOINT.MD}) {
    max-width: 730px;
  }
`;

const LatitudeCyberIncident = props => {
  return (
    <Layout location={props.location}>
      <main className="navigation-spacer">
        <Helmet>
          <link
            rel="canonical"
            href="https://www.latitudefinancial.com.au/latitude-cyber-incident/"
          />
          <title>Latitude Cyber Incident | Information and support</title>
          <meta
            name="description"
            content="Latitude Cyber Incident, Latitude Financial Services"
          />
        </Helmet>

        <StyledHeroBranded
          title="Latitude Cyber Response"
          text="Information, updates and support for those affected"
        />

        <StyledPromoSection
          css={`
            background-color: white;
          `}
        >
          <Heading4
            color={COLOR.BLACK}
            align={ALIGN.CENTER}
            css={`
              margin-left: auto;
              margin-right: auto;
              background-color: white;
              @media (min-width: ${BREAKPOINT.MD}) {
                max-width: 730px;
              }
            `}
          >
            What's happened?
          </Heading4>
          <StyledText>
            Latitude Financial was subject to a cyber-attack in March 2023 that
            resulted in the theft of personal information. The theft affects
            customers, past customers and applicants in Australia and New
            Zealand.
          </StyledText>

          <Heading4
            color={COLOR.BLACK}
            align={ALIGN.CENTER}
            css={`
              margin-left: auto;
              margin-right: auto;
              background-color: white;
              @media (max-width: ${BREAKPOINT.MD}) {
                text-align: center;
              }
              @media (min-width: ${BREAKPOINT.MD}) {
                max-width: 730px;
              }
            `}
          >
            What we are doing
          </Heading4>
          <StyledText>
            We acted immediately to contain the attack and engaged with external
            cyber-security experts, the Australian Cyber Security Centre, the
            Australian Federal Police and relevant government agencies.
            <br />
            <br />
            We notified customers and applicants affected by the breach by email
            or letter, where we had contact details, telling them what personal
            information has been stolen and the support we are providing or is
            available to them.
            <br />
            <br />
            These were customers and applicants in Australia and New Zealand who
            had one or more of the following compromised:
          </StyledText>
          <StyledText>
            <ul>
              <li>
                Approximately 7.9 million driver licence numbers and some but
                not necessarily all of: name, address, telephone and date of
                birth
              </li>
              <li>
                Approximately 103,000 copies of driver licences or passports
              </li>
              <li>Approximately 53,000 passport numbers</li>
              <li>Less than 100 monthly account statements</li>
              <li>
                Income and expense information used to assess approximately
                900,000 loan applications, including approximately 308,000 bank
                account numbers (but not passwords) for the disbursement of
                funds and approximately 143,000 credit card or credit card
                account numbers (but not card expiry or 3-digit CVC) for debt
                consolidation, the overwhelming majority of which are expired or
                closed.
              </li>
            </ul>
          </StyledText>
          <StyledText>
            Customers and applicants can also refer to{' '}
            <a
              href="https://www.latitudefinancial.com.au/cyber-statement-of-notification/"
              target="_blank"
            >
              A Statement of Notification
            </a>{' '}
            on the Latitude website for an overview of the personal information
            that was compromised.
          </StyledText>

          <Heading4
            color={COLOR.BLACK}
            align={ALIGN.CENTER}
            css={`
              margin-left: auto;
              margin-right: auto;
              background-color: white;
              @media (max-width: ${BREAKPOINT.MD}) {
                text-align: center;
              }
              @media (min-width: ${BREAKPOINT.MD}) {
                max-width: 730px;
              }
            `}
          >
            Support for affected customers
          </Heading4>
          <StyledText></StyledText>
          <StyledText>
            The security and safety of our customers is our number one priority.
          </StyledText>
          <StyledText>
            Affected individuals wanting to contact Latitude should call:
            <br />
            <strong>Australia</strong> call 1300&nbsp;793&nbsp;416 9am to 6pm,
            Monday &mdash; Saturday (excluding public holidays)
            <br />
            <strong>New Zealand</strong> call 0800&nbsp;777&nbsp;885 9am to 6pm,
            Monday &mdash; Friday (excluding public holidays)
          </StyledText>
          <StyledText>
            Support is available for customers placed in a uniquely vulnerable
            position because of this incident. Our contact centre teams are able
            to provide direct access to the support we have available.
          </StyledText>
          <StyledText>
            Where needed{' '}
            <a
              href="/latitude-id-information/identity-images/#reimbursement-claims"
              rel="noopener nofollow"
            >
              we will reimburse
            </a>{' '}
            customers for the replacement cost of their stolen ID document.
          </StyledText>
          <StyledText>
            We have also partnered with IDCARE to help support those affected.
            IDCARE is Australia and New Zealand's national identity and cyber
            support service. Their expert Case Managers can work with affected
            individuals to provide advice on specific concerns about the theft
            of personal information. Please visit{' '}
            <a
              href="https://www.idcare.org/latitude-incident-response"
              target="_blank"
              rel="noopener nofollow"
            >
              https://www.idcare.org/latitude-incident-response
            </a>{' '}
            or call 1800 595 160 (Australia) or 0800 121 068 (New Zealand) and
            use referral code LAT23.
          </StyledText>

          <Heading4
            color={COLOR.BLACK}
            align={ALIGN.CENTER}
            css={`
              margin-left: auto;
              margin-right: auto;
              background-color: white;
              @media (min-width: ${BREAKPOINT.MD}) {
                max-width: 730px;
              }
            `}
          >
            Answers to your questions
          </Heading4>
          <StyledText>
            For updates or further information, you can read the FAQs at our
            online Help Centre by clicking on these links:
            <ul>
              <li>
                <a
                  href="https://latitudefs.zendesk.com/hc/en-au"
                  target="_blank"
                  rel="noopener nofollow"
                >
                  Australia Help Centre
                </a>
              </li>
              <li>
                <a
                  href="https://gemfinance.zendesk.com/"
                  target="_blank"
                  rel="noopener nofollow"
                >
                  New Zealand Help Centre
                </a>
              </li>
            </ul>
            You can view the latest news and the timeline of events in our{' '}
            <a href="/about-us/news-room/" target="_blank">
              online newsroom
            </a>
            .
            <br />
            <br />
            <strong>Steps you can take to protect yourself</strong>
            <br />
            <br />
            There are immediate precautions that you can take to protect
            yourself from cybercrime:
            <ol>
              <li>
                Contacting one of Australia's credit reporting agencies for a
                credit report so you can check if your identity has been used to
                obtain credit without your knowledge.
                <br />
                <br />
                In New Zealand, check your credit record to confirm if your
                identity has been used to obtain credit without your knowledge.
                Please refer to{' '}
                <a
                  href="https://www.govt.nz/browse/consumer-rights-and-complaints/debt-and-credit-records/check-your-own-credit-report"
                  target="_blank"
                  rel="noopener nofollow"
                >
                  www.govt.nz/browse/consumer-rights-and-complaints/debt-and-credit-records/check-your-own-credit-report
                </a>{' '}
                for further information.
              </li>
              <li>
                You can request the agencies to place a credit ban or suspension
                on your credit file via their website or by contacting them
                directly. While a ban or suppression is in place it may be more
                difficult for you to apply for credit. For example, a credit
                provider may need to collect more personal information directly
                from you.
              </li>
            </ol>
            <table className="cyberIncidentAgencies">
              <thead>
                <tr>
                  <th>Credit Reporting Agency</th>
                  <th>Website</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Illion</td>
                  <td>
                    {'AU: '}
                    <a
                      href="https://illion.com.au/credit-report-ban-request/"
                      target="_blank"
                    >
                      illion.com.au/credit-report-ban-request/
                    </a>
                    <br />
                    {'NZ: '}
                    <a
                      href="https://dbcsprodaecdn.azureedge.net/web/themes/cycnz/assets/static/SuppressionRequestFormCYCNZ.pdf"
                      target="_blank"
                    >
                      dbcsprodaecdn.azureedge.net/web/themes/cycnz/
                      assets/static/SuppressionRequestFormCYCNZ.pdf
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>Equifax</td>
                  <td>
                    {'AU: '}
                    <a
                      href="https://www.equifax.com.au/eform/submit/credit-ban"
                      target="_blank"
                    >
                      www.equifax.com.au/eform/submit/credit-ban
                    </a>
                    <br />
                    {'NZ: '}
                    <a
                      href="https://equifax.co.nz/credit-file-suppression"
                      target="_blank"
                    >
                      equifax.co.nz/credit-file-suppression
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>Experian</td>
                  <td>
                    <a
                      href="https://experian.com.au/consumer/request-a-ban"
                      taget="_blank"
                    >
                      experian.com.au/consumer/request-a-ban
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>Centrix</td>
                  <td>
                    <a
                      href="https://centrix.co.nz/my-credit-score/suppress-your-credit-file/"
                      target="_blank"
                    >
                      centrix.co.nz/my-credit-score/suppress-your-credit-file/
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
            <br />
            <br />
            <strong>Be Alert</strong>
            <br />
            <br />
            We urge customers to be vigilant with all online communications and
            transactions including:
            <ul>
              <li>
                Staying alert for any phishing scams via SMS, phone, post or
                email.
              </li>
              <li>
                Always verifying the sender of any communications received to
                make sure they're legitimate.
              </li>
              <li>Not opening texts from unknown or suspicious numbers.</li>
              <li>
                Updating passwords regularly with 'strong' passwords, not
                re-using passwords and activating multi-factor authentications
                when available on any online accounts.
              </li>
            </ul>
            Latitude will not contact customers asking for passwords or
            sensitive information.
            <br />
            <br />
            If you are a victim of cybercrime, you can report it at ReportCyber
            on the Australian Cyber Security Centre website.
            <br />
            <br />
            In New Zealand You can refer to the Office of the Privacy
            Commissioner for information privacy.org.nz
            <br />
            <br />
            If you wish to report a scam or a vulnerability, go to ScamWatch.
          </StyledText>
        </StyledPromoSection>

        <StyledPromoSection
          css={`
            background-color: #f8f8f8;

            .section__content > div {
              display: flex;
            }
          `}
        >
          <Heading4
            color={COLOR.BLACK}
            align={ALIGN.CENTER}
            css={`
              margin-left: auto;
              margin-right: auto;
              @media (min-width: ${BREAKPOINT.MD}) {
                max-width: 730px;
              }
            `}
          >
            Further Support
          </Heading4>
          <StyledText>
            <div style={{ textAlign: 'center' }}>
              Information for further support.
            </div>
          </StyledText>
          <CardGroup>
            <CardGroupItem key={1} col={3}>
              <ItemLink
                noStyle
                href={'/latitude-id-information/'}
                trackId="cyber-incident-link-id-information"
                trackEventData={{
                  label: 'ID Information',
                  location: heading
                }}
              >
                <ItemIcon name="external-link" />
                <ItemTitle>ID Information</ItemTitle>
                <ItemChevron direction="right" color={COLOR.BLUE} />
              </ItemLink>
            </CardGroupItem>
            <CardGroupItem key={2} col={3}>
              <ItemLink
                noStyle
                href="https://latitudefs.zendesk.com/hc/en-au/articles/360009263517-Contact-Us"
                trackId="contact-us-au"
                trackEventData={{
                  label: 'Contact Us (AU)',
                  location: heading
                }}
              >
                <ItemIcon name="external-link" />
                <ItemTitle>Contact Us (AU)</ItemTitle>
                <ItemChevron direction="right" color={COLOR.BLUE} />
              </ItemLink>
            </CardGroupItem>
            <CardGroupItem key={3} col={3}>
              <ItemLink
                noStyle
                href="https://gemfinance.zendesk.com/hc/en-nz/articles/360010403418-Contact-Us"
                trackId="contact-us-nz"
                trackEventData={{
                  label: 'Contact Us (NZ)',
                  location: heading
                }}
              >
                <ItemIcon name="external-link" />
                <ItemTitle>Contact Us (NZ)</ItemTitle>
                <ItemChevron direction="right" color={COLOR.BLUE} />
              </ItemLink>
            </CardGroupItem>
          </CardGroup>
        </StyledPromoSection>

        <StyledPromoSection
          css={`
            css="background-color:#f8f8f8;"
          `}
        >
          <Text
            fontSize={FONT_SIZE.LARGE}
            marginBottom={MARGIN.M24}
            css={`
              margin-left: auto;
              margin-right: auto;
              text-align: left;
              @media (max-width: ${BREAKPOINT.MD}) {
                text-align: center;
              }
              @media (min-width: ${BREAKPOINT.MD}) {
                max-width: 730px;
              }
            `}
          >
            <div style={{ textAlign: 'center' }}>
              <strong>Mental Health and wellbeing support</strong> is available
              free of charge through our Support Line:
              <br /> 1800 808 374 (AU) or <br />
              0800 808 374 (NZ).
            </div>
          </Text>
        </StyledPromoSection>
      </main>
    </Layout>
  );
};

export default LatitudeCyberIncident;
