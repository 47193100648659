import { createClient } from 'contentful';

const contentfulClient = createClient({
  space: process.env.GATSBY_CONTENTFUL_SPACE_ID,
  environment: process.env.GATSBY_CONTENTFUL_ENVIRONMENT,
  accessToken: process.env.GATSBY_CONTENTFUL_ACCESS_TOKEN,
  host: process.env.GATSBY_CONTENTFUL_API_ENDPOINT,
  removeUnresolved: true
});

export default contentfulClient;
