import React, { useState, useEffect, useContext} from 'react';
import ReactHTMLParser from 'react-html-parser';
import { marked }  from 'marked';
import {
  COLOR,
  BREAKPOINT,
  MARGIN,
  ALIGN,
  BUTTON_STYLE
} from '@latitude/core/utils/constants';
import { Box } from '@latitude/box';
import { FeaturesSlider } from '@latitude/features-slider';
import { PRODUCT_NAME } from '@/utils/constants';
import { Heading4, Heading3 } from '@latitude/heading';
import { Text } from '@latitude/text';
import { Vertical } from '@latitude/spacing';
import { Link } from '@latitude/link';
import HowToApplyComponent, {
  InnerContainer,
  ContentBox
} from '@latitude/how-to-apply';
import imageAppliances from '../../../images/credit-cards/appliances.svg';
import imageGuitar from '../../../images/credit-cards/guitar.png';
import { PageContext } from '@/context/PageContext';
import FeatureCol from '@/components/lab-components/FeatureCol';

const WhyChoose = ({ showButtons = true }) => {
  const featureSliderItems = [
    {
      icon: 'icon-great-offers',
      title: `Make it yours, Interest Free.`,
      text: `Enjoy it now, pay later, Interest Free at thousands of <a href="https://www.interestfree.com.au/shop-interest-free/view-retailer-listing.html" target="_blank">retailers</a> online and in-store.`
    },
    {
      icon: 'icon-everyday',
      title: 'The perfect card, everyday.',
      text:
        'The Latitude GO Mastercard credit card offers up to 55 days interest free<sup>1</sup> on everyday purchases, so it’s the perfect go-to card for your everyday spending too.'
    },
    {
      icon: 'icon-cardless-branded',
      title: 'Ready, Set, Tap',
      text:
        'Introducing our new <a href="/digital-wallets/apple-pay">instant cardless payment</a> feature where you can apply, get approval, and start shopping from your phone in minutes.'
    },
    {
      icon: 'icon-app-latitude-branded',
      title: 'Stay on track with your spending',
      text:
        'With the <a href="/mobile-app/" target="_blank">Latitude App</a>, it&apos;s never been easier to manage your payments and stay on top of your due dates.'
    },
    {
      icon: 'icon-pl-2',
      title: 'Booking.com partnership',
      jsx:(
        <p
        css={`
        font-family: Roboto, Open Sans, sans-serif;
        font-size: 16px;
        font-weight: 300;
        line-height: 26px;
        text-align: center;
        color: #42484d;
        `}>
          Get 6% off your stay instantly with <a href="/travelpartners">Booking.com</a> every day, all year round via the Latitude App<sup>3</sup>.
        </p>

      )
    },
  ]

  /** Contentful Data */
  const contentfulPageData = useContext(PageContext);
  const state = contentfulPageData?.updatedBody || {};

  const featureSliderData = [
    {
      featureCards: featureSliderItems
    }
  ];
  /** - END - */

  return (
    <>
      <Box
        id="why-us"
        backgroundColor={COLOR.GREY6}
        css={`
          padding: 48px 0;
          z-index: 1;
          @media (min-width: ${BREAKPOINT.LG}) {
            padding: 72px 0 120px;
          }
        `}
      >
        <Heading4
          color={COLOR.BLACK}
          align={ALIGN.CENTER}
          marginBottom={MARGIN.M56}
          isResponsive
        >
          Why choose Latitude GO Mastercard?
        </Heading4>
        {state?.featureColsData?.[0] ? (
            <FeatureCol {...state?.featureColsData?.[0]} />
          ) : (
          <HowToApplyComponent
            css={`
              ${InnerContainer} {
                background-color: #e8f4ff;
              }
              ${ContentBox} {
                border-color: #b1dbff;
              }
            `}
            box1={
              <Vertical spacing={MARGIN.M16}>
                <img
                  src={imageAppliances}
                  css="width: 264px; margin: -81px auto 0;"
                  alt="Interest Free plans to suit you."
                />
                <Heading3
                  color={COLOR.BLACK}
                  align={ALIGN.CENTER}
                  isResponsive={false}
                >
                  Interest Free plans to suit you.
                </Heading3>
                <Text align={ALIGN.CENTER}>
                  Keep it simple with regular monthly payments or enjoy the
                  flexibility of making them when it suits you. We’ve partnered
                  with Harvey Norman, Domayne and Joyce Mayne to create a range of
                  Interest Free Payment Plans to match the way you want to pay.
                </Text>
                {showButtons && (
                  <Link
                    button={BUTTON_STYLE.TERTIARY}
                    css="width: 200px; margin: 0 auto;"
                    href="/credit-cards/gomastercard/interest-free-offers"
                    data-trackid="find-out-more-about-interest-free-plans"
                    trackEventData={{
                      label: 'Find out more about interest free plans'
                    }}
                  >
                    Find out more
                  </Link>
                )}
              </Vertical>
            }
            box2={
              <Vertical spacing={MARGIN.M16}>
                <img
                  src={imageGuitar}
                  css="width: 200px; margin: 0 auto 0;"
                  alt="Enjoy 6 months Interest Free on your everyday purchases of $250 or more."
                />
                <Heading3
                  color={COLOR.BLACK}
                  align={ALIGN.CENTER}
                  isResponsive={false}
                >
                  Enjoy 6 months Interest Free on your everyday purchases of $250
                  or more.
                </Heading3>
                <Text align={ALIGN.CENTER}>
                  Select your purchases in the Latitude app within 30 days.
                </Text>
                {showButtons && (
                  <Link
                    button={BUTTON_STYLE.TERTIARY}
                    css="width: 200px; margin: 0 auto;"
                    href="https://latitudefs.zendesk.com/hc/en-au/articles/4409706884113-Latitude-GO-Mastercard-s-6-months-Interest-Free"
                    data-trackid="find-out-more-about-interest-free-plans"
                    trackEventData={{
                      label: 'Find out more about interest free plans'
                    }}
                  >
                    Find out more
                  </Link>
                )}
              </Vertical>
            }
          />
        )}
      </Box>

      <Box
        backgroundColor={COLOR.GREY6}
        css={`
          margin-top: -72px;
          @media (min-width: ${BREAKPOINT.LG}) {
            margin-top: -144px;
          }
        `}
      >
        <FeaturesSlider
          key={(state?.featureSliderData?.[0] || featureSliderData[0]).heading}
          newBrand
          data={
            (state?.featureSliderData?.[0] || featureSliderData[0]).featureCards
          }
          heading={
            (state?.featureSliderData?.[0] || featureSliderData[0]).heading
          }
          subheading={
            (state?.featureSliderData?.[0] || featureSliderData[0]).description
          }
        />
      </Box>
    </>
  );
}

export default WhyChoose;
