import React from 'react';

export default ({ children }) => (
  <div
    css={`
      > div {
        background-color: transparent !important;

        a {
          border-bottom: none !important;
          white-space: normal !important;

          &:not(.isActive) {
            font-weight: normal;
            color: grey !important;
          }
        }

        > div {
          display: none;
        }
      }
    `}
  >
    {children}
  </div>
);
