import React, { useContext } from 'react';
import { PageContext } from '@/context/PageContext';

import { SecondaryHero } from '@latitude/hero';
import { Metadata } from '@latitude/metadata';
import { SITE_URL } from '@/utils/constants';

import Layout from '@/components/layout';
import Faq from '@/components/Faq/AccordionSidebarFaq';
import LowRateMicrositeHeader from './_low-rate-header';
import PageData from './data/low-rate-data.json';

const Faqs = ({ location }) => {
  /** Contentful Data */
  const contentfulPageData = useContext(PageContext);
  const state = contentfulPageData?.updatedBody || {};
  /** - END - */
  return (
    <Layout location={location}>
      <Metadata
        title={`Low Rate FAQs | Low Rate Mastercard`}
        description={`Learn all the handy hints, tips, and answers for everything you wanted to know about your Low rate Card`}
        keywords="FAQ"
        canonical={`${SITE_URL}${location.pathname}`}
      />
      <LowRateMicrositeHeader />
      <SecondaryHero
        style={'padding-top: 80px;'}
        heading="Frequently Asked Questions"
        subHeading="Got a burning question? You may find your answer here."
      />

      <Faq data={PageData.faqs} />
    </Layout>
  );
};

export default Faqs;
