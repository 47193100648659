import React from 'react';
import Helmet from 'react-helmet';

import Layout from '@/components/layout';
import { SecondaryHero } from '@latitude/hero';

import UploadCards from './components/_uploadCards';
import { Main } from './components/_uploadStyle';

const Page = props => (
  <Layout location={props.location}>
    <Main className="navigation-spacer">
      <Helmet>
        <link
          rel="canonical"
          href="https://www.latitudefinancial.com.au/upload"
        />
        <title>Document upload | Latitude Financial</title>
        <meta name="description" content="Document upload" />
      </Helmet>
      <SecondaryHero heading="Document upload" />

      <UploadCards />
    </Main>
  </Layout>
);

export default Page;
