import { css } from 'styled-components';
import { BREAKPOINT } from '@latitude/core/utils/constants';

const styles = css`
  width: 100%;
  margin: 0 auto;
  padding: 40px 15px;

  @media (min-width: ${BREAKPOINT.LG}) {
    max-width: 992px !important;
    padding: 72px 15px;
  }
  @media (min-width: ${BREAKPOINT.XL}) {
    max-width: 1140px !important;
    padding: 72px 15px;
  }

  #visa-widget-header {
    font-family: 'Montserrat', sans-serif !important;
    font-weight: 600 !important;
    color: #000 !important;
  }

  h1,
  h2,
  h3,
  h4,
  a.clamp-title {
    font-family: 'Montserrat', sans-serif !important;
    font-weight: 600 !important;
    color: #000 !important;

    :hover {
      color: #000 !important;
      opacity: 1 !important;
      text-shadow: none !important;
    }
  }

  h2 {
    font-size: 24px !important;
  }

  h4 {
    font-size: 20px !important;
  }

  p,
  li,
  .clamp-merchant,
  .pagination {
    font-family: 'Roboto', 'Open Sans', sans-serif !important;
    font-size: 16px !important;
    font-weight: 300 !important;
    color: #000 !important;
  }

  .offer-image {
    @media (max-width: ${BREAKPOINT.SM}) {
      width: 100% !important;
      float: left !important;
      margin-bottom: 24px !important;
      img {
        max-width: 48% !important;
        height: auto !important;
      }
    }
  }

  #visaOfferTitleName {
    font-weight: 400 !important;
  }

  p a,
  li a {
    color: #006aff !important;
    &:hover {
      text-shadow: none !important;
      text-decoration: none !important;
      opacity: 1 !important;
      color: #006aff !important;
    }
  }

  #visa-offer-title {
    line-height: 1.3 !important;
  }

  .clamp-merchant {
    color: #000 !important;
    &:hover {
      color: #000 !important;
    }
  }

  select,
  input,
  input.search-inpt,
  .dropdown,
  .dropdown .selected {
    font-family: 'Roboto', 'Open Sans', sans-serif !important;
    font-size: 14px !important;
    font-weight: 300 !important;
    color: #363636 !important;
  }

  a.btn-primary {
    display: flex;
    justify-content: center;
    align-content: center;
    padding: 12px 24px !important;
    font-family: 'Roboto', 'Open Sans', sans-serif !important;
    font-size: 18px !important;
    color: #006aff !important;
    border: solid 1px #006aff !important;
    border-radius: 4px !important;
    line-height: 22px !important;
    transition: all 0.15s ease-in-out !important;
    text-shadow: none !important;
    background-color: transparent !important;

    :hover {
      background-color: #006aff !important;
      color: #fff !important;
    }

    &.back-button {
      border: none !important;
      padding: 0 !important;
      :hover {
        background-color: transparent !important;
        color: #006aff !important;
      }
      :before {
        content: '<';
        margin-right: 5px;
      }
    }

    &.visa-links {
      background-color: #21a637 !important;
      border: solid 1px #21a637 !important;
      color: #fff !important;
      :hover {
        background-color: #007414 !important;
        border-color: #007414 !important;
      }
    }
  }

  a.btn-secondary {
    border: none !important;
    background-color: #fff !important;
  }
  .col-xs-6 {
    width: 50% !important;
    @media (max-width: ${BREAKPOINT.SM}) {
      width: 100% !important;
    }
  }
  .promo-wrapper .row {
    border: solid 1px #f0efef !important;
  }
  .promo-wrapper .row > div {
    border: solid 1px #f0efef !important;
  }
  .filter-cell {
    @media (max-width: ${BREAKPOINT.MD}) {
      margin-bottom: 10px;
      padding-right: 15px;
      width: 100%;
      .dropdown {
        max-width: 100% !important;
      }
    }
  }
`;

export default styles;
