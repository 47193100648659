/* eslint-disable react/no-danger */
import React, { useContext } from 'react';
import styled from 'styled-components';
import { Link } from '@latitude/link';
import { Tel } from '@latitude/tel';
import { AnalyticsLocationProvider } from 'latitude-analytics';
import { BUTTON_STYLE } from '@latitude/core/utils/constants';
import ListNumbered from '@latitude/list-numbered/ListNumbered';
import { StickyNavigation } from '@latitude/sticky-navigation';
import Icon from '@latitude/icon';
import { Accordion } from '@latitude/accordion';
import Eligibility from '../../components/Eligibility/Eligibility';
import Faq from '../../components/Faq/AccordionSidebarFaq';
import FeaturesAndBenefitsSlider from '../../components/FeaturesAndBenefitsSlider/FeaturesAndBenefitsSlider';
import Hero from '../../components/Hero/Hero';
import { ImportantInformation } from '@latitude/important-information';
import Layout from '../../components/layout';
import Metadata from '../../components/Metadata/Metadata';
import Modal from '../../components/Modal/Modal';
import RatesList from '../../components/RatesList/RatesList';
import LabRatesAndFees from '@/components/lab-components/LabRatesAndFees';
import Text from '../../components/Text/Text';
import { Box } from '../../components/Box/Box';
import { Card } from '../../components/Card/Card';
import { CardGroup, CardGroupItem } from '../../components/CardGroup/CardGroup';
import { Heading2, Heading6 } from '../../components/Heading/Heading';
import {
  ALIGN,
  BREAKPOINT,
  COLOR,
  MARGIN,
  PADDING,
  ICON_VARIANT,
  ICON_SIZE
} from '../../utils/constants';
import PageData from '../../data/pages/credit-cards/apple-creditline.json';
import heroImage from '../../images/hero/banner-creditline.jpg';
import creditlineImage from '../../images/credit-cards/creditline-afs-300x190.webp';
import faqData from '../../data/pages/credit-cards/faq-gambling.json';
import { CalloutSection } from './_components';

import { PageContext } from '@/context/PageContext';
import FeeChangesCCSection from './_feeChanges';
import { NotificationBanner } from '@latitude/banner';
import HeroBanner from '@/components/lab-components/HeroBanner';

const URL_SUBMIT = 'https://www.apple.com/au/shop/browse/financing';

const CreditCardImageContainer = styled(Box)`
  position: absolute;
  top: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  height: 212px;

  @media (min-width: ${BREAKPOINT.MD}) {
    position: static;
    height: auto;
    flex-direction: column;
  }

  img {
    max-height: 150px;
    position: relative;
    top: 0;

    @media (min-width: ${BREAKPOINT.LG}) {
      max-height: 190px;
    }
    @media (max-width: ${BREAKPOINT.MD}) {
      margin-top: 150px;
    }
  }
`;

const StyledBox = styled.div`
  @media (min-width: ${BREAKPOINT.LG}) {
    display: flex;
  }
`;

const CreditLine = props => {
  /** Contentful Data */
  const contentfulPageData = useContext(PageContext);
  const state = contentfulPageData?.updatedBody || {};
  /** - END - */

  return (
    <Layout location={props.location}>
      <main className="navigation-spacer">
        <Metadata
          title="CreditLine Card - Interest Free Credit Card"
          description="The CreditLine Credit card provides 6 months Interest Free on credit card purchases that are $250 and over. Read the full details inside and apply online!"
          canonical="/credit-cards/apple-creditline/"
        />
        {!state?.alertData && (
          <NotificationBanner siteName="latitudefinancial-com-au" />
        )}
        {state?.heroBannerData?.[0] ? (
          <HeroBanner {...state?.heroBannerData[0]} />
        ) : (
          <Hero
            h1={<>Buy the Apple products you know and love. Interest Free.</>}
            intro="Enjoy now and pay later when you shop Interest Free with your CreditLine card at Apple. New customers must apply and be approved for a CreditLine credit card. Available on participating Latitude credit cards only."
            bg={heroImage}
            image={
              <CreditCardImageContainer>
                <img src={creditlineImage} alt="CreditLine Card" />
              </CreditCardImageContainer>
            }
            darkBg
          >
            <StyledBox>
              <Box paddingTop={PADDING.P24} width="240px">
                <Link
                  button={BUTTON_STYLE.PRIMARY}
                  href={URL_SUBMIT}
                  trackId="credit-cards-gemvisa--header--an"
                  trackEventData={{
                    action: 'application-link',
                    location: 'hero'
                  }}
                >
                  Apply Now at Apple
                </Link>
              </Box>
            </StyledBox>
          </Hero>
        )}
        <NavigationSection inPageNavData={state?.inPageNavData?.[0]} />

        {/* <FeeChangesCCSection
          heading="Notice of Variation to your CreditLine credit contract"
          body={
            <>
              Effective from 31st Oct 2024, <b>EFTPOS</b> functionality is being
              removed from your credit card account and will not be available at
              point-of-sale and ATM withdrawals. References to <b>EFTPOS</b>{' '}
              have been removed in your Conditions of Use Booklet.{' '}
              <b>Cash advances</b> will still be available where a{' '}
              <b>BPAY payment</b> is treated as a <b>cash advance</b>. These
              changes will not impact your ability to make purchases at
              participating retailers, with an interest free payment plan
              subject to terms and conditions.
            </>
          }
          link="/eftpos-removal-faq/"
        /> */}
        <BenefitsSection />
        {state?.ratesAndFeesData?.[0] ? (
          <LabRatesAndFees {...state.ratesAndFeesData[0]} />
        ) : (
          <RatesAndFeesSection />
        )}
        <ValueSection />
        <HowtoApplySection />
        <CalloutSection />
        <Faq
          data={PageData.content.faq.concat(faqData)}
          {...state?.faqData?.[0]}
        />
        <ImportantInformationSection
          contentfulData={state.importantInfoData?.[0]}
        />
      </main>
    </Layout>
  );
};

const StickyNavigationContainer = styled(Box)`
  display: none;
  @media (min-width: ${BREAKPOINT.MD}) {
    display: block;
  }
`;

const NavigationSection = ({ inPageNavData }) => (
  <StickyNavigationContainer>
    <StickyNavigation
      items={[
        {
          anchor: 'benefits',
          label: 'Right for you'
        },
        {
          anchor: 'rates',
          label: 'Rates & Fees'
        },
        {
          anchor: 'how-to-apply',
          label: 'How to apply'
        },
        {
          anchor: 'faq',
          label: 'FAQs'
        }
      ]}
      phoneNumber="1300 552 079"
      // ctaText="Apply now at Apple"
      // ctaHref="https://www.apple.com/au/shop/browse/finance/instant_credit"
      trackEventData={{
        action: 'application-link'
      }}
      offsetElem="[data-sticky-navigation-offset]"
      {...inPageNavData}
    />
  </StickyNavigationContainer>
);

const StyledFeatureTitle = styled.span`
  color: ${COLOR.BLUE};
`;

const BenefitsSection = () => {
  const [isOpen, setIsOpen] = React.useState(false);
  return (
    <FeaturesAndBenefitsSlider
      id="benefits"
      data={[
        {
          icon: (
            <Icon
              variant={ICON_VARIANT.THUMBS_UP}
              size={ICON_SIZE.LG}
              color={COLOR.BLUE}
            />
          ),
          title: (
            <StyledFeatureTitle>
              0% interest offers <sup>1</sup>
            </StyledFeatureTitle>
          ),
          jsx: (
            <Text align={ALIGN.CENTER}>
              To enjoy 0% interest offers, either: <br />
              Find an{' '}
              <Link href="https://www.apple.com/au/retail/">
                Apple Retail Store
              </Link>
              .
              <br />
              Or
              <br />
              Browse{' '}
              <Link href="https://www.apple.com/au/shop/browse/finance/instant_credit">
                Apple Online Store
              </Link>{' '}
              then call Apple on 133 622 to place your order.
            </Text>
          )
        },
        {
          icon: (
            <Icon
              variant={ICON_VARIANT.HAND_DOLLAR}
              size={ICON_SIZE.LG}
              color={COLOR.BLUE}
            />
          ),
          title: <StyledFeatureTitle>Streamlined costs</StyledFeatureTitle>,
          jsx: (
            <Text align={ALIGN.CENTER}>
              Our rates are easy to understand. You only pay a monthly account
              keeping fee. No annual or establishment fees apply.
            </Text>
          )
        },
        {
          icon: (
            <Icon
              variant={ICON_VARIANT.SHIELD_PADLOCK}
              size={ICON_SIZE.LG}
              color={COLOR.BLUE}
            />
          ),
          title: <StyledFeatureTitle>Safe and secure</StyledFeatureTitle>,
          jsx: (
            <React.Fragment>
              <Text align={ALIGN.CENTER}>
                Shop in the knowledge that your card and account are protected
                by stringent{' '}
                <Link href={null} onClick={() => setIsOpen(true)}>
                  security systems
                </Link>
                .
              </Text>
              <Modal
                isOpen={isOpen}
                onRequestClose={() => setIsOpen(!isOpen)}
                title="Added protection for your card"
                content={
                  <React.Fragment>
                    <Heading6 marginBottom={MARGIN.M8}>
                      Our fraud protection promise to you
                    </Heading6>
                    <Text>
                      Your CreditLine card is protected by robust security
                      measures - both when you shop in-store and online - so you
                      can spend safe in the knowledge that we&apos;ve always got
                      your back.
                    </Text>
                    <Heading6 marginBottom={MARGIN.M8}>
                      Stopping fraud in its tracks
                    </Heading6>
                    <Text>
                      As soon as a suspect transaction is detected, our card
                      security experts get to work immediately reviewing any
                      suspicious activity while taking necessary action.
                    </Text>
                    <Heading6 marginBottom={MARGIN.M8}>
                      Money back guarantee
                    </Heading6>
                    <Text>
                      If there is fraudulent activity on your CreditLine card,
                      you will not be held liable - provided you notify us
                      promptly, have not contributed to the loss and have stuck
                      to the{' '}
                      <Link href="https://assets.latitudefinancial.com/legals/conditions-of-use/creditline-afs/cou.pdf">
                        Conditions of Use
                      </Link>
                      .
                    </Text>
                  </React.Fragment>
                }
              />
            </React.Fragment>
          )
        },
        {
          icon: (
            <Icon
              variant={ICON_VARIANT.SLINKY}
              size={ICON_SIZE.LG}
              color={COLOR.BLUE}
            />
          ),
          title: <StyledFeatureTitle>Flexible payments</StyledFeatureTitle>,
          jsx: (
            <Text align={ALIGN.CENTER}>
              Take advantage of 0% interest at Apple. You need to make a minimum
              monthly payment each month but you have the flexibility to make
              extra payments at anytime.
            </Text>
          )
        }
      ]}
      className="pt-4 why-choose pt-md-5 bg-lightest"
      heading="Is CreditLine right for you?"
    />
  );
};

const RatesAndFeesSection = () => (
  <Box.Section id="rates">
    <Heading2 align={ALIGN.CENTER} color={COLOR.BLUE}>
      Rates &amp; Fees
    </Heading2>
    <RatesList
      data={[
        {
          largeText: '0%',
          showExtras: true,
          smallText: 'On purchases $150 and over at Apple<sup>1</sup>'
        },
        {
          largeText: '$0',
          smallText: 'annual fee'
        },
        {
          largeText: '25.90',
          showExtras: true,
          smallText: 'standard purchase interest rate<sup>2</sup>'
        },
        {
          largeText: '25.90',
          showExtras: true,
          smallText: 'cash advance interest rate<sup>2</sup>'
        }
      ]}
    />
    {/* TODO: replace this with table component */}
    <style
      dangerouslySetInnerHTML={{
        __html: `
          .accordion-custom .accordion-item__heading { background-color:#f3f3f3; }
          .accordion-custom .accordion-item__body { padding:0; }
          .accordion-custom .accordion__row {
            padding: 5px 30px;
            background: white;
            border-bottom: 1px solid #dae3e7;
          }
          .accordion-custom .accordion__row:last-child {
            border-bottom:0;
          }
        `
      }}
    />
    <div style={{ maxWidth: '800px', margin: '1rem auto 0' }}>
      <AnalyticsLocationProvider location="Rates &amp; Fees">
        <Accordion
          className="mt-5"
          titleBgColor="grey-light"
          items={[
            {
              id: 'account-fees',
              title: 'Establishment and account fees',
              content: (
                <div className="accordion-custom">
                  <div className="d-lg-flex accordion__row">
                    <p className="w-100">
                      <strong>Annual fee</strong>
                    </p>
                    <p className="w-100">$0</p>
                  </div>
                  <div className="d-lg-flex accordion__row">
                    <p className="w-100">
                      <strong>Monthly account keeping fee</strong>
                    </p>
                    <p className="w-100">$5.95</p>
                  </div>
                  <div className="d-lg-flex accordion__row">
                    <p className="w-100">
                      <strong>Establishment fee</strong>
                    </p>
                    <p className="w-100">$0</p>
                  </div>
                  <div className="d-lg-flex accordion__row">
                    <p className="w-100">
                      <strong>Paper statements</strong>
                    </p>
                    <p className="w-100">$5.00 per statement</p>
                  </div>
                </div>
              )
            },
            {
              id: 'transaction-fees',
              title: 'Transaction fees',
              content: (
                <div className="accordion-custom">
                  <div className="d-lg-flex accordion__row">
                    <p className="w-100">
                      <strong>Minimum payment</strong>
                    </p>
                    <p className="w-100">
                      $25 or 3% of the balance, if less than $25
                    </p>
                  </div>
                  <div className="d-lg-flex accordion__row">
                    <p className="w-100">
                      <strong>Late fee</strong>
                    </p>
                    <p className="w-100">$45</p>
                  </div>
                  <div className="d-lg-flex accordion__row">
                    <p className="w-100">
                      <strong>ATM cash advance or withdrawal fee</strong>
                    </p>
                    <p className="w-100">
                      $4 or 3.5% of the cash advance, whichever is greater.
                    </p>
                  </div>

                  {/* <div className="d-lg-flex accordion__row">
                    <p className="w-100">
                      <strong>EFTPOS withdrawal fee</strong>
                    </p>
                    <p className="w-100">85c</p>
                  </div>
                  <div className="d-lg-flex accordion__row">
                    <p className="w-100">
                      <strong>Giropost withdrawal fee</strong>
                    </p>
                    <p className="w-100">85c</p>
                  </div> */}
                  <div className="d-lg-flex accordion__row">
                    <p className="w-100">
                      <strong>Payment handling fee</strong>
                    </p>
                    <p className="w-100">
                      $3.95 (in person)
                      <br />
                      $1.95 (online)
                    </p>
                  </div>
                </div>
              )
            },
            {
              id: 'other-information',
              title: 'Other information',
              content: (
                <div className="accordion-custom">
                  <div className="d-lg-flex accordion__row">
                    <p className="w-100 col-sm-9">
                      Before making a decision, please read these documents to
                      ensure you&rsquo;re aware of all fees, charges and
                      interest rates.
                    </p>
                    <p className="w-100 col-sm-3">
                      <a href="https://assets.latitudefinancial.com/legals/key-fact-sheet/creditline-afs/kfs.html">
                        Key facts sheet
                      </a>
                      <br />
                      <a href="https://assets.latitudefinancial.com/legals/conditions-of-use/creditline-afs/cou.pdf">
                        Conditions of use
                      </a>
                    </p>
                  </div>
                </div>
              )
            }
          ]}
        />
      </AnalyticsLocationProvider>
    </div>
    <div
      css={`
        text-align: center;
        margin: auto;
        padding-bottom: 30px;
        max-width: 800px;
      `}
    >
    </div>
  </Box.Section>
);

const ButtonContainer = styled(Box)`
  flex: 1 1 auto;
  display: flex;
  align-self: center;
  align-items: flex-end;
  width: 100%;
  max-width: 280px;
`;

const ValueSection = () => {
  const CARD_MIN_HEIGHT = '260px';
  const heading = 'Making the most of your CreditLine';
  return (
    <AnalyticsLocationProvider location={heading}>
      <Box.Section backgroundColor={COLOR.GREY5}>
        <Heading2 align={ALIGN.CENTER} color={COLOR.BLUE}>
          {heading}
        </Heading2>
        <CardGroup>
          <CardGroupItem col={3}>
            <Card.Box
              heading="Start shopping"
              backgroundColor={COLOR.WHITE}
              minHeight={CARD_MIN_HEIGHT}
            >
              <Text align={ALIGN.CENTER}>
                Visit your nearest Apple Retail Store to find your perfect
                product.
              </Text>
              <ButtonContainer>
                <Link
                  button={BUTTON_STYLE.TERTIARY}
                  href="https://www.apple.com/au/retail/storelist/"
                  width="100%"
                >
                  Find a store
                </Link>
              </ButtonContainer>
            </Card.Box>
          </CardGroupItem>
          <CardGroupItem col={3}>
            <Card.Box
              heading={['0% interest offers', <sup>1</sup>]}
              backgroundColor={COLOR.WHITE}
              minHeight={CARD_MIN_HEIGHT}
            >
              <Text align={ALIGN.CENTER}>
                Take advantage of the latest 0% interest offers at Apple.
              </Text>
              <ButtonContainer>
                <Link
                  button={BUTTON_STYLE.TERTIARY}
                  href="https://www.apple.com/au/shop/browse/finance/instant_credit"
                  width="100%"
                >
                  View offers at Apple
                </Link>
              </ButtonContainer>
            </Card.Box>
          </CardGroupItem>
          <CardGroupItem col={3}>
            <Card.Box
              heading="Shop online"
              backgroundColor={COLOR.WHITE}
              minHeight={CARD_MIN_HEIGHT}
            >
              <Text align={ALIGN.CENTER}>
                Browse the Apple Online Store, and then call Apple on{' '}
                <Tel no="133 622" /> to place your order.
              </Text>
              <ButtonContainer>
                <Link
                  button={BUTTON_STYLE.TERTIARY}
                  href="https://www.apple.com/au/shop/browse/finance/instant_credit"
                  width="100%"
                >
                  Browse online store
                </Link>
              </ButtonContainer>
            </Card.Box>
          </CardGroupItem>
        </CardGroup>
      </Box.Section>
    </AnalyticsLocationProvider>
  );
};

const HowtoApplySection = () => (
  <Box.Section id="how-to-apply">
    <div className="row">
      <div className="col col-md-6 pr-lg-5">
        <ListNumbered
          title="How to apply"
          data={[
            '<div><strong>Apply online</strong><br />Check your eligibility and complete your application in less than 10 minutes.</div>',
            '<div><strong>Get your approval</strong><br />We will give you a response on your application in under 60 seconds.</div>',
            '<div><strong>Get your card</strong><br />If approved, you’ll be able to shop with your account number straight away. You do not need to wait for your card to arrive.</div>'
          ]}
          counterVerticalAlign
          separator="light"
        />
      </div>
      <Eligibility
        alignContent="centerAlign"
        criteria={[
          'Be 18 years or over',
          'Be a permanent resident of Australia'
        ]}
        supply={[
          "Driver's licence, passport or proof of age card",
          'Details of your income, assets and liabilities',
          "Your employer's name and contact number"
        ]}
      />
    </div>
  </Box.Section>
);

const ImportantInformationSection = ({ contentfulData }) => (
  <ImportantInformation
    data={{
      content: {
        importantInformationSectionOne: [
          '<sup>1</sup> Extended Interest Free terms may vary. Available at Apple Retail Stores &amp; the Apple Online Store. Online purchases must be made via Apple Telesales on 133 622. Approved applicants only. Conditions, fees and charges apply.'
        ],
        importantInformationSectionTwo: [
          '<sup>2</sup> The same rate applies to interest bearing balances such as cash advances and outstanding retail purchases balances after an interest free promotion period is over.'
        ]
      }
    }}
    sectionOneColummClasses="col-10 offset-1 col-lg-5 offset-lg-1"
    sectionTwoColummClasses="col-10 offset-1 col-lg-5 offset-lg-0"
    {...contentfulData}
  />
);

export default CreditLine;
