/* eslint-disable import/extensions, import/no-unresolved */
import React from 'react';
import { FONT_SIZE } from '@latitude/core/utils/constants';
import { StaticImage } from 'gatsby-plugin-image';

import {
  StyledHeroBranded,
  ImageBox,
  LeaderDetailsWrapper,
  DetailsContainer,
  StyledText,
  LeadershipPageLayout
} from '../../../components/Leadership';

function SteveRubensteinPage(props) {
  const title = 'Steve Rubenstein';
  const content = 'Executive General Manager, Money';

  return (
    <LeadershipPageLayout title={title} {...props}>
      <StyledHeroBranded
        title={title}
        text={content}
        breadcrumbs={[
          {
            name: 'About us',
            href: '/about-us/'
          },
          {
            name: 'Our company',
            href: '/about-us/our-company/'
          },
          {
            name: 'steve-rubenstein'
          }
        ]}
        imageContent={
          <ImageBox>
            <StaticImage
              alt={title}
              src="../images/steve-rubenstein.webp"
              width={450}
              quality={100}
              placeholder="dominantColor"
            />
          </ImageBox>
        }
      />
      <LeaderDetailsWrapper>
        <DetailsContainer display="flex">
          <StyledText fontSize={FONT_SIZE.LARGE}>
            Steve joined Latitude in August 2024 as Executive General Manager,
            Money where he leads Latitude's personal and motor loan business.
            <br />
            <br />
            Before Latitude, Steve was most recently Managing Director Consumer
            Finance at Westpac Group where he was the end-to-end business owner
            for credit cards, personal loans, and emerging payments.
          </StyledText>
          <StyledText fontSize={FONT_SIZE.LARGE}>
            Steve brings over 25 years global financial services experience with
            executive roles at the Royal Bank of Scotland Group in the UK, and
            ANZ Banking Group in Australia. Over that period, he has specialised
            in running large card and loan businesses. Steve began his career as
            a financial services management consultant with First Manhattan
            Consulting Group out of New York.
            <br />
            <br />
            He holds a Bachelor of Science in Economics from the Wharton School
            of the University of Pennsylvania with concentrations in Finance and
            Decision Science. Steve also qualified as a CFA Charterholder in
            2004.
          </StyledText>
        </DetailsContainer>
      </LeaderDetailsWrapper>
    </LeadershipPageLayout>
  );
}

export default SteveRubensteinPage;
