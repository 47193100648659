import React from 'react';
import classnames from 'classnames';
import {
  AnalyticsContext,
  AnalyticsLocationConsumer,
  trackEvent
} from 'latitude-analytics';
import {
  StyledStepperTabContainer,
  StyledSelectList,
  StyledStepperContent
} from './Stepper.styled';

class Stepper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 0
    };
  }

  handleClickTabItem = (id, analytics, location) => {
    this.setState({ activeTab: id });

    /**
     * Tab event tracking
     */
    const isActiveTab = this.props.data.map(tab => tab.title);

    if (trackEvent) {
      trackEvent(analytics, {
        category: 'tab',
        action: 'in-page-navigation',
        label: isActiveTab[id],
        location
      });
    }
  };

  render() {
    return (
      <StepperTabs
        darkBg={this.props.darkBg}
        data={this.props.data}
        activeTab={this.state.activeTab}
        handleClickTabItem={this.handleClickTabItem}
        tabContentMinHeight={this.props.tabContentMinHeight}
      />
    );
  }
}

/* Tabs - desktop only
 *
 */
const StepperTabs = ({
  darkBg,
  data,
  handleClickTabItem,
  activeTab,
  tabContentMinHeight
}) => (
    <StyledStepperTabContainer
      className={classnames(
        'benefit-stages-tabs',
        darkBg && 'benefit-stages-tabs--dark-bg'
      )}
    >
      <AnalyticsContext.Consumer>
        {([analytics]) => (
          <AnalyticsLocationConsumer>
            {location => (
              <StyledSelectList
                className="benefit-stages-tabs__tabs"
                horizontal
                data={data.map(item => item.titleHTML)}
                onClick={event =>
                  handleClickTabItem(event, analytics, location)
                }
                selectedItem={activeTab}
                hideChevron
              />
            )}
          </AnalyticsLocationConsumer>
        )}
      </AnalyticsContext.Consumer>

      <div
        className="benefit-stages-tabs__content"
        style={{ minHeight: tabContentMinHeight || 0 }}
        data-active-tab={activeTab}
      >
        {data.map((item, i) => (
          <StyledStepperContent
            className={classnames('benefit-stages-tabs__item', {
              'benefit-stages-tabs__item--active': activeTab === i
            })}
            key={`benefit-stages-tabs__item-${i}`}
          >
            {item.image && (
              <div className="benefit-stages-tabs__image">
                <img
                  src={item.image}
                  title={item.titleHTML}
                  alt={item.titleHTML}
                />
              </div>
            )}
            {item.content}
          </StyledStepperContent>
        ))}
      </div>
    </StyledStepperTabContainer>
  );

export default Stepper;
