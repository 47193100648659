import React, { useState } from 'react';
import styled from 'styled-components';

import Section from '@latitude/section';
import SvgInline from '@latitude/svg-inline';
import { Link } from '@latitude/link';
import { Button } from '@latitude/button';
import { BUTTON_STYLE, COLOR } from '@latitude/core/utils/constants';

import imgSearchIcon from './images/search-icon.svg';
import MERCHANTS from './data/interest-free-merchants.json';
const ANY_VALUE = '';
const MAX_STORES_INITIAL = 8;
const MAX_STORES_INCREMENT = 16;

function FindAStoreSection({ id }) {
  const [maxStores, setMaxStores] = useState(MAX_STORES_INITIAL);
  const [storeName, setStoreName] = useState(ANY_VALUE);

  function onChangeStoreName(event) {
    setStoreName(event.target.value);
  }

  function retailerOrder(a, b) {
    // Compare order numerically
    if (a.order > b.order) return 1;
    if (a.order < b.order) return -1;
    // Compare name alphabetically (case-insensitive)
    if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
    if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
    // Otherwise, they're equal
    return 0;
  }

  function filterByCount(merchant, index, allMerchants) {
    return index < maxStores;
  }

  function filterByName(merchant, index, allMerchants) {
    if (storeName) {
      if (!merchant.name.toLowerCase().includes(storeName.toLowerCase())) {
        return false;
      }
    }
    return true;
  }

  function onClickLoadMoreStores() {
    const max = maxStores + MAX_STORES_INCREMENT;
    setMaxStores(max);
  }

  return (
    <FindAStoreContainer id={id} heading="So many retail stores to explore">
      <div className="merchant-search">
        <div css={{ flexBasis: '400px', padding: '10pt' }}>
          <Search
            value={storeName}
            onChange={onChangeStoreName}
            placeholder="Search by store name"
          />
        </div>
      </div>
      <div className="merchant-list">
        {MERCHANTS.filter(filterByName)
          .sort(retailerOrder)
          .filter(filterByCount)
          .map((merchant, index, allMerchants) => (
            <div key={index} className="merchant-info">
              <div className="merchant-logo">
                <img src={merchant.logo} alt={merchant.name} loading="lazy" />
              </div>

              <Link
                className="merchant-link"
                href={merchant.url}
                target="_blank"
                rel="noreferrer noopener"
                button={BUTTON_STYLE.SECONDARY}
              >
                {merchant.linkTitle ? merchant.linkTitle : 'View website'}&nbsp;
                <SvgInline name="external-link" />
              </Link>
            </div>
          ))}
      </div>
      <div>
        <Button
          onClick={onClickLoadMoreStores}
          variant={BUTTON_STYLE.TERTIARY}
          css={{ margin: '0 auto 0 auto' }}
        >
          Load more stores
        </Button>
      </div>
    </FindAStoreContainer>
  );
}

const FindAStoreContainer = styled(Section)`
  div.merchant-search {
    display: flex;
    flex-direction: row;
    margin: 0 auto 0 auto;
    justify-content: center;
  }

  div.merchant-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 auto 0 auto;
  }

  div.merchant-info {
    background-color: #ffffff;
    border-radius: 8px;
    border: 1px solid #eeeeee;
    box-shadow: 0px 2px 5px 0px #0000001a;
    flex-basis: 250px;
    margin: 10pt;
    padding: 10pt;
    text-align: center;
  }
  div.merchant-info div.merchant-logo img {
    height: 100px;
    width: 200px;
    object-fit: contain;
  }
  div.merchant-info a.merchant-link {
    margin: 20pt auto 0 auto;
    width: 150pt;
    font-size: 90%;
  }
`;

const Search = styled.input`
  padding: 14px 66px 14px 18px;
  border: none;
  width: 100%;
  max-width: 325px;
  font-size: 16px;
  line-height: 22px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  background: ${COLOR.WHITE} url(${imgSearchIcon}) no-repeat right 14px center;
  z-index: 1;

  ::placeholder {
    color: ${COLOR.BLACK};
  }
`;

export default FindAStoreSection;
