import React from 'react';
import PersonalLoanPageContent from '@/components/PersonalLoanPageContent/PersonalLoanPageContent';

const PersonalLoanPage = props => {
  return (
    <PersonalLoanPageContent
      {...props}
      title="Personal Loan - Fixed Interest Rate"
      description="Whether your dream is to renovate your home or go travelling, Latitude's personal loan can help make that come true faster. Apply in less than 10 minutes."
    />
  );
};

export default PersonalLoanPage;
