import React from 'react';

import Section from '@latitude/section';
import './tile-promo.css';
import styled from 'styled-components';
import { Link } from '@latitude/link';
import { BUTTON_STYLE } from '@latitude/core/utils/constants';



const TilePromo = (props) => {
  // Determine the column class dynamically
  const promoBanner = props.bannerItem;
  const columnClass = promoBanner.length === 1 ? "col-12" : "col-lg-5 col-sm-12";

  return (
    <Section className="promoheader">
     <div class="row promotile">
      {promoBanner.map((item, index) => (
        <div className={`promoheader-item ${columnClass}`} key={index}>
          <div className="promoheader-ribbon"><span className='white-star'>&#9733;</span>Limited time offer</div>
          <div className="promoheader-text">
            <h3 className="promoheader-title">{item.title}</h3>
            <div className="promoheader-subtitle" dangerouslySetInnerHTML={{ __html: item.subTitle }}></div>
            <Link
              href={item.btnLink}
              trackId="promo-banner"
              button={BUTTON_STYLE.PRIMARY}
              trackProductId={item.trackId || ""}
              className="max-width-fit-content"
            >
              {item.btnText}
            </Link>
          </div>
        </div>
      ))}
      </div>
    </Section>
  );
};

export default TilePromo;
