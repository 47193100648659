/* eslint-disable import/extensions, import/no-unresolved */
import React from 'react';
import { FONT_SIZE } from '@latitude/core/utils/constants';
import { StaticImage } from 'gatsby-plugin-image';

import {
  StyledHeroBranded,
  ImageBox,
  LeaderDetailsWrapper,
  DetailsContainer,
  StyledText,
  LeadershipPageLayout
} from '../../../components/Leadership';

export default props => {
  const title = 'Adriana Martinez';
  const content = 'Executive General Manager, Pay';

  return (
    <LeadershipPageLayout title={title} {...props}>
      <StyledHeroBranded
        title={title}
        text={content}
        breadcrumbs={[
          {
            name: 'About us',
            href: '/about-us/'
          },
          {
            name: 'Our company',
            href: '/about-us/our-company/'
          },
          {
            name: 'Adriana Martinez'
          }
        ]}
        imageContent={
          <ImageBox>
            <StaticImage
              alt={title}
              src="../images/adriana-martinez.webp"
              width={450}
              quality={100}
              placeholder="dominantColor"
            />
          </ImageBox>
        }
      />
      <LeaderDetailsWrapper>
        <DetailsContainer display="flex">
          <StyledText fontSize={FONT_SIZE.LARGE}>
            Adriana has over 25 years financial services experience spanning
            funds and wealth management, insurance, retail banking, consumer
            cards and payments. She has worked across different geographies
            including Australia, United Kingdom, United States and Asia.
            <br />
            <br />
            She joined Latitude in September 2023 as Executive General Manger
            Pay, where she leads Latitude's instalments and credit cards
            business and the enterprise Marketing function.
            <br />
            <br />
            Before Latitude, Adriana spent five years at Mastercard in Singapore
            as Senior Vice President, where she led a team of 100+ loyalty &
            engagement and marketing experts across Asia Pacific and was
            responsible for developing and managing a suite of innovative
            products and solutions. Adriana has also held senior executive roles
            at both ANZ and Citibank leading large businesses and implementing
            transformational and turnaround strategies to deliver significant
            commercial results.
          </StyledText>
          <StyledText fontSize={FONT_SIZE.LARGE}>
            Adriana holds a Masters of Management from Macquarie Graduate School
            of Management, a Graduate Diploma in Marketing, a Graduate Diploma
            in Project Management and a Bachelor of Commerce (Economics and
            Finance).
          </StyledText>
        </DetailsContainer>
      </LeaderDetailsWrapper>
    </LeadershipPageLayout>
  );
};
