import {
  FUNNEL_URL,
  ML_APPLY_URL_DEV,
  ML_APPLY_URL_QA,
  ML_APPLY_URL,
  PL_HOME_URL
} from './constants';

const env = process.env.GATSBY_TEALIUM_ENV || 'dev';
const branchName = process.env.GATSBY_BRANCH_NAME;
const URLS = {
  'funnel-start': {
    dev: 'https://cx-dev04.lfs.corefinance.io/apply/',
    qa: 'https://cardapp-sandbox.latitudefinancial.com/apply/',
    prod: 'https://cardapp.latitudefinancial.com/apply/'
  },
  'funnel-start-gemvisa': {
    dev: 'https://cx-dev04.lfs.corefinance.io/apply/gem/',
    qa: 'https://cx-dev04.lfs.corefinance.io/apply/gem/',
    prod: 'https://cards.latitudefinancial.com/gem'
  },
  'funnel-start-gomastercard': {
    dev: 'https://cx-dev04.lfs.corefinance.io/apply/go/',
    qa: 'https://cx-test04.lfs.corefinance.io/apply/go/',
    prod: 'https://cards.latitudefinancial.com/go?merchant=lfs'
  },
  'funnel-pl-apply-now-button': {
    dev: FUNNEL_URL.PL.DEV,
    qa: FUNNEL_URL.PL.QA,
    prod: FUNNEL_URL.PL.LANDING_PAGE
  },
  'funnel-pl-start-application-button': {
    dev: FUNNEL_URL.PL.DEV,
    qa: FUNNEL_URL.PL.QA,
    prod: FUNNEL_URL.PL.PROD
  },
  'funnel-start-motor-loan': {
    dev: ML_APPLY_URL_DEV,
    qa: ML_APPLY_URL_QA,
    prod: ML_APPLY_URL
  },
  'funnel-start-asset-loan': {
    dev: FUNNEL_URL.AL.DEV,
    qa: FUNNEL_URL.AL.QA,
    prod: FUNNEL_URL.AL.PROD
  },
  'funnel-start-soft-quote': {
    dev: FUNNEL_URL.SQ.DEV,
    qa: FUNNEL_URL.SQ.QA,
    prod: FUNNEL_URL.SQ.PROD
  },
  'funnel-start-loans': {
    dev: FUNNEL_URL.SQ.DEV_LOANS,
    qa: FUNNEL_URL.SQ.QA_LOANS,
    prod: FUNNEL_URL.SQ.PROD_LOANS
  },
  'funnel-start-salesforce-broker-dashboard': {
    dev: FUNNEL_URL.SF_BROKER_DASHBOARD.DEV,
    qa: FUNNEL_URL.SF_BROKER_DASHBOARD.QA,
    prod: FUNNEL_URL.SF_BROKER_DASHBOARD.PROD
  },
  'funnel-start-broker': {
    // dev: FUNNEL_URL.SF_BROKER_DASHBOARD.DEV,
    // qa: FUNNEL_URL.SF_BROKER_DASHBOARD.QA,
    // TODO: change dev and qa URLs to above when testing is complete (before 9/08/22)
    dev: FUNNEL_URL.SQ.PROD_BROKER,
    qa: FUNNEL_URL.SQ.PROD_BROKER,
    prod: FUNNEL_URL.SQ.PROD_BROKER
  },
  'funnel-home-personal-loan': {
    dev: PL_HOME_URL.AU.DEV,
    qa: PL_HOME_URL.AU.QA,
    prod: PL_HOME_URL.AU.PROD
  },
  'funnel-home-personal-loan-broker': {
    dev: PL_HOME_URL.BROKER.DEV,
    qa: PL_HOME_URL.BROKER.QA,
    prod: PL_HOME_URL.BROKER.PROD
  },
  'funnel-home-personal-loan-gem': {
    dev: PL_HOME_URL.GEM.DEV,
    qa: PL_HOME_URL.GEM.QA,
    prod: PL_HOME_URL.GEM.PROD
  },
  'funnel-start-gemvisa-partners': {
    dev: 'https://cardapp-sandbox.latitudefinancial.com/apply/gem',
    qa: 'https://cardapp-sandbox.latitudefinancial.com/apply/gem',
    prod: 'https://cards.latitudefinancial.com/gem'
  },
  'funnel-lpay-smallticket': {
    dev: 'https://app.uat.latitudepay.com/#sign-up',
    qa: 'https://app.uat.latitudepay.com/#sign-up',
    prod: 'https://app.latitudepay.com/#sign-up'
  },
  'funnel-start-go': {
    dev: 'https://cx-dev04.lfs.corefinance.io/apply/go/',
    qa: 'https://cx-test04.lfs.corefinance.io/apply/go/',
    prod: 'https://cards.latitudefinancial.com/go?merchant=lfs'
  },
  'low-rate-credit-card': {
    dev: 'https://develop.apply-test.originations-np.lfscnp.com/low-rate',
    qa: 'https://develop.apply-test.originations-np.lfscnp.com/low-rate',
    prod: 'https://cards.latitudefinancial.com/low-rate'
  }
};

function getUrlForLocalOrStagingBranch({ urlId, queryString }) {
  // See README.md for an explanation of this function.
  if (urlId === 'funnel-start-soft-quote') {
    const softQuoteBranchNameByLatitudeFinancialBranchName = {};
    // For CTAs getting the URL for SoftQuote, we look first for a local dev environment, and return port number 8000 or 8001 (or the current port +1)
    if (
      typeof window !== 'undefined' &&
      ['localhost', '127.0.0.1', ''].includes(window.location.hostname)
    ) {
      if (window.location.port === '8001')
        return `http://localhost:8000${queryString}`;
      return `http://localhost:${parseInt(window.location.port, 10) +
        1}${queryString}`;
    }
    // If we are not running locally, we see if the branch is a staging branch that also has a similarly named SoftQuote staging branch:
    if (softQuoteBranchNameByLatitudeFinancialBranchName[branchName]) {
      return `https://${softQuoteBranchNameByLatitudeFinancialBranchName[branchName]}.staging.quote-latitudefinancial.digitalweb-np.lfscnp.com${queryString}`;
    }
  }
  return false;
}

export function getUrlFromEnv(urlId, queryString = '') {
  // TODO: Uncomment when testing the redirection of same branch name in quote and latitude site for staging branch
  // Or when testing local redirection for quote and latitude site
  // const stagingUrl = getUrlForLocalOrStagingBranch({ urlId, queryString });
  // if (stagingUrl) {
  //   return stagingUrl;
  // }
  const url = URLS[urlId][env];

  if (url) {
    return url + queryString;
  }

  throw new Error(`${urlId} URL doesn't exist in ${env} environment`);
}
