import React from 'react';
import { Box } from '../Box/Box';
import { MARGIN } from '../../utils/constants';

const Title = ({ title }) => (
  <Box marginTop={MARGIN.M24}>
    <strong>{title}</strong>
  </Box>
);

const formatCurrency = number => {
  var formatter = new Intl.NumberFormat('en-AU');

  return formatter.format(number);
};

const getRowTotalValue = (value, frequency) => {
  return parseInt(value) * parseInt(frequency) || 0;
};

const getCategoryTotalValue = fieldsArray => {
  return fieldsArray.reduce((acc, curr) => acc + curr.rowTotal, 0);
};

const getRowSummaryData = (title, total) => {
  return [
    title,
    `$${formatCurrency(Math.round(total / 52))}`,
    `$${formatCurrency(Math.round(total / 26))}`,
    `$${formatCurrency(Math.round(total / 12))}`,
    `$${formatCurrency(total)}`
  ];
};

const getRowSummaryDataMobile = (title, total) => {
  return [
    [<Title title={title} />],
    [
      `Weekly: <br/>$${formatCurrency(Math.round(total / 52))}`,
      `Fortnightly: <br/>$${formatCurrency(Math.round(total / 26))}`
    ],
    [
      `Monthly: <br/>$${formatCurrency(Math.round(total / 12))}`,
      `Yearly: <br/>$${formatCurrency(total)}`
    ]
  ];
};

const getAllSummaryDataDesktop = ({
  incomeTotal,
  livingExpensesTotal,
  insuranceTotal,
  loansTotal,
  transportTotal,
  leisureTotal,
  onceOffTotal,
  surplusTotal
}) => {
  return [
    getRowSummaryData('Income', incomeTotal),
    getRowSummaryData('Living Expenses', livingExpensesTotal),
    getRowSummaryData('Insurance/Super', insuranceTotal),
    getRowSummaryData('Loans', loansTotal),
    getRowSummaryData('Transport', transportTotal),
    getRowSummaryData('Leisure', leisureTotal),
    getRowSummaryData('Once-off', onceOffTotal),
    getRowSummaryData('Surplus', surplusTotal)
  ];
};

const getAllSummaryDataMobile = ({
  incomeTotal,
  livingExpensesTotal,
  insuranceTotal,
  loansTotal,
  transportTotal,
  leisureTotal,
  onceOffTotal,
  surplusTotal
}) => {
  return [
    getRowSummaryDataMobile('Income', incomeTotal),
    getRowSummaryDataMobile('Living Expenses', livingExpensesTotal),
    getRowSummaryDataMobile('Insurance/Super', insuranceTotal),
    getRowSummaryDataMobile('Loans', loansTotal),
    getRowSummaryDataMobile('Transport', transportTotal),
    getRowSummaryDataMobile('Leisure', leisureTotal),
    getRowSummaryDataMobile('Once-off', onceOffTotal),
    getRowSummaryDataMobile('Surplus', surplusTotal)
  ];
};

export {
  getRowTotalValue,
  getCategoryTotalValue,
  getRowSummaryData,
  getAllSummaryDataMobile,
  getAllSummaryDataDesktop,
  formatCurrency
};
