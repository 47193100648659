import React from 'react';

import { CardIcon } from '@latitude/card-icon';
import { Heading4 } from '@latitude/heading';
import { Link } from '@latitude/link';
import Section from '@latitude/section';
import { Text } from '@latitude/text';
import {
  ALIGN,
  COLOR,
  FONT_SIZE,
  MARGIN,
  PADDING
} from '@latitude/core/utils/constants';

import imageBelonging from './images/icon-belonging.png';
import imageGiving from './images/icon-giving.png';
import imageFinancialCapability from './images/icon-financial-capability.png';
import imageCommunityPartners from './images/icon-community-partners.svg';
import imageVolunteer from './images/icon-volunteer.svg';

const CorporateResponsibilityOurPartners = () => {
  return (
    <Section className="w-100 bg-lightest">
      <Heading4 color={COLOR.BLACK} align={ALIGN.CENTER} marginTop={MARGIN.M32}>
        Partners in the Community
      </Heading4>
      <Text marginBottom={MARGIN.M32} align={ALIGN.CENTER}>
        Our corporate responsibility program has been shaped by our purpose and
        values. Working in collaboration with our people we’ve designed a
        program that focuses on key initiatives across the countries we operate
        in to make a difference in the community.
      </Text>
      <div
        className="row"
        css={`
          padding-top: ${PADDING.P24};
        `}
      >
        <div className="col-12 col-lg-4 mb-4">
          <CardIcon
            newBrand
            thumbnail
            image={
              <img
                style={{ maxWidth: '200px', minHeight: '150px' }}
                src={imageGiving}
                alt="Latitude Giving"
              />
            }
            title="Latitude Giving"
          >
            <Text fontSize={FONT_SIZE.NORMAL} align={ALIGN.CENTER}>
              Is a way for our people to donate to charities by making regular
              donations direct from their pay, matched dollar for dollar by
              Latitude. This means twice the benefit and impact for our charity
              partners and invites us to work as a team knowing that Workplace
              Giving has the potential to deliver better outcomes for those in
              the community.
            </Text>
          </CardIcon>
        </div>
        <div className="col-12 col-lg-4 mb-4">
          <CardIcon
            newBrand
            thumbnail
            image={
              <img
                style={{ maxWidth: '200px', minHeight: '150px' }}
                src={imageFinancialCapability}
                alt="Financial Capability"
              />
            }
            title="Financial Capability"
          >
            <Text fontSize={FONT_SIZE.NORMAL} align={ALIGN.CENTER}>
              Our program has been designed to build the financial wellbeing of
              our people and help them develop good money habits. Partnering
              with Map My Plan in Australia and Te Ara Ahunga Ora Retirement
              Commission in New Zealand. The program includes access to online
              resources and tools as well as the delivery of workshops by
              experienced facilitators.
            </Text>
          </CardIcon>
        </div>
        <div className="col-12 col-lg-4 mb-4">
          <CardIcon
            newBrand
            thumbnail
            image={
              <img
                style={{ maxWidth: '200px', minHeight: '150px' }}
                src={imageCommunityPartners}
                alt="Local community partnerships"
              />
            }
            title="Belonging"
          >
            <Text fontSize={FONT_SIZE.NORMAL} align={ALIGN.CENTER}>
              We believe that everyone deserves to work in an inclusive
              environment that values diversity. We bring this to life in
              Belonging. A diverse workforce that recognises they belong
              delivers better outcomes for our customer and partners. It
              increases our ability to think differently, to apply unique
              approaches to innovation, creativity and problem solving.&nbsp;
              <Link href="/careers/" trackId="belonging-find-out-more">
                Find out more
              </Link>
              .
            </Text>
          </CardIcon>
        </div>
      </div>
      <div
        className="row"
        css={`
          justify-content: center;
          padding-top: ${PADDING.P24};
        `}
      >
        <div className="col-12 col-lg-4 mb-4">
          <CardIcon
            newBrand
            thumbnail
            image={
              <img
                style={{ maxWidth: '200px', minHeight: '150px' }}
                src={imageVolunteer}
                alt="Volunteering"
              />
            }
            title="Volunteering"
          >
            <Text fontSize={FONT_SIZE.NORMAL} align={ALIGN.CENTER}>
              Our people told us that the opportunity to volunteer and support
              those in the community is really important to them. We provide
              three days of volunteer leave a year and opportunities with our
              Latitude Giving partners.
            </Text>
          </CardIcon>
        </div>
        <div className="col-12 col-lg-4 mb-4">
          <CardIcon
            newBrand
            thumbnail
            image={
              <img
                style={{ maxWidth: '200px', minHeight: '150px' }}
                src={imageBelonging}
                alt="Local community partnerships"
              />
            }
            title="Local community partnerships"
          >
            <Text fontSize={FONT_SIZE.NORMAL} align={ALIGN.CENTER}>
              We are very proud of our two long-running and successful local
              partnerships, with Ardoch in Australia and Duffy Books in Homes in
              New Zealand. These partners focus on empowering our communities
              and children through education.
            </Text>
          </CardIcon>
        </div>
      </div>
    </Section>
  );
};

export default CorporateResponsibilityOurPartners;
