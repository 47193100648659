import React, { useContext } from 'react';
import { BREAKPOINT, COLOR, PADDING } from '@latitude/core/utils/constants';
import { Box } from '@latitude/box';
import { SecondaryHero } from '@latitude/hero';
import { Metadata } from '@latitude/metadata';
import Layout from '../../../components/layout';
import { SITE_URL, TITLE_SUFFIX } from '@/utils/constants';
import { AnalyticsContext } from 'latitude-analytics';

const PartnerDiscount = ({ location }) => {
  const [analytics] = useContext(AnalyticsContext);

  // adding pageData to analytics
  analytics.pageData = {
    page_pageTitle: 'partner-discount',
    page_pageType: 'information-page',
    page_siteSection: 'information',
    page_siteSubsection: 'credit-cards'
  };

  return (
    <Layout location={location}>
    <Metadata
      title={`Partner Discount |  ${TITLE_SUFFIX}`}
      description="Latitude Gem Visa is not just another credit card. That's probably because it's not from just another finance company. Find out more!"
      keywords="interest free, credit card"
      canonical={`${SITE_URL}${location.pathname}`}
    />
    <SecondaryHero heading="Partner Discount" />
    <Box
      css={`
        position: relative;
        z-index: 1;
      `}
    >
      <Box.Section backgroundColor={COLOR.GREY6}>
        <Box
          css={`
            background-color: ${COLOR.WHITE};
            padding: ${PADDING.P0} ${PADDING.P16};
            @media (min-width: ${BREAKPOINT.SM}) {
              padding: ${PADDING.P8} ${PADDING.P32};
            }
          `}
        >
          <iframe
            css={`
              width: 100%;
              height: 1340px;
              border: none;
              @media (min-width: ${BREAKPOINT.XL}) {
                height: 1100px;
              }
            `}
            title="partner-discount-iframe"
            src="https://eform.latitudefinancial.com.au/eform/Page.do?eFormName=gem_visa_annual_fee_waiver_form"
          />
        </Box>
      </Box.Section>
    </Box>
  </Layout>
  );
};

export default PartnerDiscount;
