import React, { useEffect } from 'react';

function RedirectToZenDesk({ location }) {
  useEffect(() => {
    if (window?.location) {
      window.location.replace('https://latitudefs.zendesk.com/hc/en-au');
    }
  }, []);

  return <></>;
}

export default RedirectToZenDesk;
