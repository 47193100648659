import React from 'react';
import Section from './Section';
import {
  BREAKPOINT
} from '@latitude/core/utils/constants';

export default props => (
  <div
    id={props.id}
    className="bg-white"
    css={`
      && {
        padding: 48px 0;
        @media (min-width: ${BREAKPOINT.LG}) {
          padding: 72px 0;
        }
        .section {
          padding: 0;
        }
        h5 {
          font-size: 20px;
          margin: 24px 0 8px;
          &:first-child {
            margin-top: 0;
          }
        }
        ul {
          margin-bottom: 16px;
        }
        ol {
          margin-left: 16px;
        }
      }
    `}
  >
    <div className="container">
      <div className="row">
        <div className="col-md-1">
          {/* <div className="ribbon-block bg-blue-bright" style={{ width: "112px", height: "134px" }}>
          <img src={require('../../images/latitude-logo-blue-bg.png')} alt="Latitude-Financial-Services-Logo" />
        </div> */}
        </div>
        <div className="col-md-10">
          <Section>{props.children}</Section>
        </div>
      </div>
    </div>
  </div>
);
