import React from 'react';

import Section from '@latitude/section';
import { Text } from '@latitude/text';
import { Link } from '@latitude/link';

import PaymentOptions, {
  PaymentOptionsColumn,
  PaymentOptionIcon,
  PaymentOptionTitle,
  PaymentOptionDescription,
  PaymentOptionImages,
  PaymentOptionCallout,
  PaymentOptionList,
  PaymentOptionListItem
} from '@/components/PaymentOptions/PaymentOptions';
import { Strong } from '@/components/Text/Bold';
import './_link-your-card.scss';

import { LFS_APP } from '../../utils/constants';
import imgApp01 from './images/mobile-app-hero.webp';

const LinkYourCardSection = ({ anchor, title }) => {
  return (
    <Section  heading={title} id={anchor} className="section--payment-options link-your-card" >
      <PaymentOptions
      tabContentMinHeight="385px"
        data={[
          {
            id: 'latitude-app',
            title: 'Latitude App',
            titleHtml: 'Latitude App',
            content: (
              <>
                <PaymentOptionsColumn>
                  <PaymentOptionTitle>Latitude App</PaymentOptionTitle>
                  <PaymentOptionDescription>
                    Make repayments in just a few taps with our mobile app.
                  </PaymentOptionDescription>
                  <PaymentOptionIcon>
                    <img
                      src={imgApp01}
                      css={`
                        height: 110px;
                        width: auto;
                        position: relative;
                        bottom: 20px;
                      `}
                    />
                  </PaymentOptionIcon>
                  <PaymentOptionImages>
                    <Link
                      href="https://itunes.apple.com/app/apple-store/id1373059866?pt=118165058&ct=InfPayOpt0319&mt=8"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="d-inline"
                      data-trackid="payment-options-itunes-app-link"
                      trackEventData={{
                        label: LFS_APP.APP_STORE
                      }}
                    >
                      <img
                        src={require('../../images/badge-app-store.svg').default}
                        height="48px"
                        alt="app store"
                      />
                    </Link>
                    <Link
                      href="https://play.google.com/store/apps/details?id=com.latitudefinancial.latitudeapp&referrer=utm_source%3DInfPayOpt0319"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="d-inline"
                      data-trackid="payment-options-googleplay-app-link"
                      trackEventData={{
                        label: LFS_APP.GOOGLE_PLAY
                      }}
                    >
                      <img
                        src={require('../../images/badge-google-play.svg').default}
                        height="48px"
                        alt="play store"
                      />
                    </Link>
                  </PaymentOptionImages>
                </PaymentOptionsColumn>
                <PaymentOptionsColumn>
                  <PaymentOptionList>
                    <PaymentOptionListItem>
                      <Strong>Get the app</Strong>
                      <Text fontSize="14px">
                        Simply download the Latitude App from the App Store or
                        Google Play
                      </Text>
                    </PaymentOptionListItem>
                    <PaymentOptionListItem>
                      <Strong>Create a Latitude ID</Strong>
                      <Text fontSize="14px">
                        Open the app and create your Latitude ID.
                      </Text>
                    </PaymentOptionListItem>
                    <PaymentOptionListItem>
                      <Strong>Link your card</Strong>
                      <Text fontSize="14px">
                        Once your credit cards are linked, you'll be able to
                        make payments via bank transfer with your BSB and
                        Account Number (Australia only).
                      </Text>
                    </PaymentOptionListItem>
                  </PaymentOptionList>
                </PaymentOptionsColumn>
                <PaymentOptionsColumn>
                  <PaymentOptionCallout css={`margin-top:120px !important`}>
                    <Strong>No Fees</Strong>
                    <Text fontSize="14px">
                      We do not charge a fee for payments made via direct bank
                      transfer on the Latitude App.
                    </Text>
                  </PaymentOptionCallout>
                    <Text css={`font-size: 14px;
                                position: relative;
                                right: 25px;
                                width: 310px;
                                margin: 5px 0 0 2px;`}>
                      <a href="https://www.youtube.com/playlist?list=PL8sAE_plCW-N5I6aQsMsWvAd-dGuwPKMd" target='_blank'>Check out our how to guides</a> for more
                      helpful tips
                    </Text>
                </PaymentOptionsColumn>
              </>
            )
          }
        ]}
      />
    </Section>
  );
};

export default LinkYourCardSection;
