import React, { useEffect } from 'react';

const TARGET =
  'https://latitudefs.zendesk.com/hc/en-au/articles/29210517450641-Credit-Card-Changes-FAQs';

function ZendeskCreditCardChangeRedirectPage({ location }) {
  useEffect(() => {
    if (window?.location) {
      window.location.replace(TARGET);
    }
  }, []);

  return <></>;
}

export default ZendeskCreditCardChangeRedirectPage;
