/* eslint-disable import/no-unresolved */
import React from 'react';
import Helmet from 'react-helmet';
import styled from 'styled-components';
import { Heading4, Heading5, Heading6 } from '@latitude/heading';
import {
  BREAKPOINT,
  COLOR,
  ALIGN,
  FONT_SIZE,
  MARGIN,
  PADDING
} from '@latitude/core/utils/constants';
import ReactHtmlParser from 'react-html-parser';
import { Text } from '@latitude/text';
import Layout from '../../components/layout';
import HeroBranded from '../../components/HeroBranded/HeroBranded';
import { PromoSection } from '../../components/Promo/Promo';
import { CardGroup, CardGroupItem } from '../../components/CardGroup/CardGroup';
import { Link } from '@latitude/link';
import SvgInline from '../../components/SvgInline/SvgInline';
import { Chevron } from '@latitude/chevron';
import { Accordion } from '@latitude/accordion';
import heroImageTablet from '../../images/blue-accent-banner.webp';
import heroImageDesktop from '../../images/blue-accent-banner.webp';
import mobileHeroImage from '../../images/blue-accent-banner-mobile.webp';
import PageData from './id-information.json';
import { Box } from '@/components/Box/Box';

const columns = 2;

const title = 'Latitude identity information';

const ItemLink = styled(Link)`
  /* height: 100%; */
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: ${COLOR.WHITE};
  transition: all 150ms ease-in-out;

  &:hover {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.49);
    transition: all 150ms ease-in-out;
  }
`;

const ItemTitle = styled(Heading6)`
  margin-bottom: 0;
  flex-grow: 1;
  color: ${COLOR.BLACK};
  @media (min-width: ${BREAKPOINT.XL}) {
    font-size: 15px;
  }
`;

const ItemIcon = styled(SvgInline)`
  width: 40px;
  min-width: 40px;
  margin-right: 13px;
`;

const ItemChevron = styled(Chevron)`
  /* svg .chevron-line.icon-line--style {
    stroke: ${COLOR.BLUE};
  } */
`;

const StyledHeroBranded = styled(HeroBranded)`
  background-image: url(${mobileHeroImage});
  background-size: cover;
  background-position: center center;
  background-color: #cfeafe;
  background-repeat: no-repeat;
  height: 100%;
  .HeroContent {
    background-color: #cfeafe;
    width: 100%;
  }
  @media (max-width: ${BREAKPOINT.MD}) {
    [class^='HeroBranded__HeroImage'] {
      display: none;
    }

    .HeroContent {
      background-color: transparent;
      height: 320px;
      padding: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-top: 60px;

      > div {
        margin: 0 0 0 21px;
      }
    }
  }

  @media (min-width: ${BREAKPOINT.MD}) {
    background-image: url(${heroImageTablet});
    background-size: cover;
    height: 300px;
    .HeroContent {
      background-color: transparent;
    }
  }
  @media (min-width: ${BREAKPOINT.LG}) {
    height: 320px;
  }
  @media (min-width: ${BREAKPOINT.XL}) {
    background-image: url(${heroImageDesktop});
  }
`;

const StyledPromoSection = styled(PromoSection)`
  background-color: ${COLOR.GREY6};
`;

const StyledText = styled(Text)`
  margin-left: auto;
  margin-right: auto;
  text-align: left;
  background-color: white;
  @media (max-width: ${BREAKPOINT.MD}) {
    text-align: center;
  }
  @media (min-width: ${BREAKPOINT.MD}) {
    max-width: 730px;
  }
`;

const StyledHeading5 = styled(Heading5)`
  margin-left: auto;
  margin-right: auto;
  background-color: white;
  @media (max-width: ${BREAKPOINT.MD}) {
    text-align: center;
  }
  @media (min-width: ${BREAKPOINT.MD}) {
    max-width: 730px;
  }
`;

const StyledHeading4 = styled(Heading4)`
  margin-left: auto;
  margin-right: auto;
  background-color: white;
  @media (max-width: ${BREAKPOINT.MD}) {
    text-align: center;
  }
  @media (min-width: ${BREAKPOINT.MD}) {
    max-width: 730px;
  }
`;

const Seperator = styled.div`
  display: none;
  @media (min-width: ${BREAKPOINT.LG}) {
    display: block;
    width: 16px;
    height: 2px;
    background-color: ${COLOR.BLUE};
    margin: 13px 0 20px;
  }
`;

const StyledCardGroup = styled(CardGroup)`
  max-width: 100%;
  margin: 0 auto;

  @media (min-width: ${BREAKPOINT.SM}) {
    max-width: 80%;
  }
  @media (min-width: ${BREAKPOINT.MD}) {
    max-width: 50%;
    padding: 0 10%;
  }
  @media (min-width: ${BREAKPOINT.LG}) {
    max-width: 100%;
  }
`;

const StyledItem = styled.div`
  display: flex;
  padding: ${PADDING.P24};
  background-color: ${COLOR.WHITE};
  border-radius: 4px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.25);
  width: 100%;

  @media (min-width: ${BREAKPOINT.LG}) {
    display: block;
    height: 100%;
  }
`;

const breadcrumbData = [
  {
    name: 'Home',
    href: '/'
  },
  {
    name: 'Latitude Cyber Response',
    href: '/latitude-id-information/'
  },
  {
    name: 'Identity Information'
  }
];

const LatitudeCyberIncident = props => {
  return (
    <Layout location={props.location}>
      <main className="navigation-spacer">
        <Helmet>
          <link
            rel="canonical"
            href="https://www.latitudefinancial.com.au/latitude-cyber-incident/"
          />
          <title>
            Latitude Cyber Incident | Identity information compromised
          </title>
          <meta
            name="description"
            content="Latitude Cyber Incident, Latitude Financial Services"
          />
        </Helmet>

        <StyledHeroBranded
          title="Identity information compromised"
          text="Information, updates and advice for those impacted."
          breadcrumbs={breadcrumbData}
        />

        <StyledPromoSection
          css={`
            background-color: white;
          `}
        >
          <StyledText fontSize={FONT_SIZE.LARGE} marginBottom={MARGIN.M24}>
            As part of your application for credit, we needed to verify your
            identity and keep a record of this. Our records generally include
            the type of identity document provided and its number.
          </StyledText>

          <StyledHeading5 color={COLOR.BLACK} align={ALIGN.CENTER}>
            Passports
          </StyledHeading5>

          <Accordion
            titleBgColor="grey-light"
            items={PageData.content.informationPassports.accordionData.map(
              accordionDataItem => ({
                id: accordionDataItem.id,
                title: accordionDataItem.title,
                content: (
                  <Box padding={PADDING.P16}>
                    {ReactHtmlParser(accordionDataItem.data)}
                  </Box>
                )
              })
            )}
            css={`
              margin-left: auto;
              margin-right: auto;
              margin-bottom: 20px;
              @media (max-width: ${BREAKPOINT.MD}) {
                text-align: center;
              }
              @media (min-width: ${BREAKPOINT.MD}) {
                max-width: 730px;
              }
            `}
          />

          <StyledHeading5 color={COLOR.BLACK} align={ALIGN.CENTER}>
            Drivers Licences &mdash; Details Compromised
          </StyledHeading5>

          <Accordion
            titleBgColor="grey-light"
            items={PageData.content.informationDriverLicences.accordionData.map(
              accordionDataItem => ({
                id: accordionDataItem.id,
                title: accordionDataItem.title,
                content: (
                  <Box padding={PADDING.P16}>
                    {ReactHtmlParser(accordionDataItem.data)}
                  </Box>
                )
              })
            )}
            css={`
              margin-left: auto;
              margin-right: auto;
              margin-bottom: 20px;
              @media (max-width: ${BREAKPOINT.MD}) {
                text-align: center;
              }
              @media (min-width: ${BREAKPOINT.MD}) {
                max-width: 730px;
              }
            `}
          />

          <StyledHeading5 color={COLOR.BLACK} align={ALIGN.CENTER}>
            Other Identity Documents
          </StyledHeading5>

          <Accordion
            titleBgColor="grey-light"
            items={PageData.content.informationOther.accordionData.map(
              accordionDataItem => ({
                id: accordionDataItem.id,
                title: accordionDataItem.title,
                content: (
                  <Box padding={PADDING.P16}>
                    {ReactHtmlParser(accordionDataItem.data)}
                  </Box>
                )
              })
            )}
            css={`
              margin-left: auto;
              margin-right: auto;
              margin-bottom: 20px;
              @media (max-width: ${BREAKPOINT.MD}) {
                text-align: center;
              }
              @media (min-width: ${BREAKPOINT.MD}) {
                max-width: 730px;
              }
            `}
          />
        </StyledPromoSection>
      </main>
    </Layout>
  );
};

export default LatitudeCyberIncident;
