import { useState } from 'react';
import { getCategoryTotalValue, formatCurrency } from '../utilities';

const useCategoryState = (id, title, stateFields) => {
  const [categoryTotal, setCategoryTotal] = useState(0);

  const fields = {
    id,
    title: `${title}${
      categoryTotal === 0 ? '' : ` ($${formatCurrency(categoryTotal)})`
    }`,
    categoryTotal,
    stateFields
  };

  const updateTotal = () => {
    setCategoryTotal(getCategoryTotalValue(stateFields));
  };

  return [fields, categoryTotal, updateTotal];
};

export default useCategoryState;
