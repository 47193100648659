import * as React from 'react';
import styled from 'styled-components';
import Callout from './Callout';
import { Flex } from '../Box/Box';
import { Text } from '../Text';
import { Heading3 } from '../Heading/Heading';
import {
  ALIGN,
  ALIGN_ITEMS,
  BREAKPOINT,
  COLOR,
  FONT_WEIGHT,
  FLEX_DIRECTION,
  JUSTIFY_CONTENT,
  MARGIN,
  PADDING
} from '../../utils/constants';

export const Title = styled(Flex)`
  flex-grow: 1;
  justify-content: ${JUSTIFY_CONTENT.CENTER};
  h3 {
    color: ${COLOR.WHITE};
    margin-bottom: ${MARGIN.M24};
    @media (min-width: ${BREAKPOINT.LG}) {
      margin-bottom: 0;
    }
  }
`;

export const ContactDetails = styled(Flex)`
  flex-grow: 1;
  flex-direction: ${FLEX_DIRECTION.COLUMN};
  align-items: ${ALIGN_ITEMS.CENTER};
  padding: 0 ${PADDING.P24};
  margin-bottom: ${MARGIN.M24};
  @media (min-width: ${BREAKPOINT.LG}) {
    margin-bottom: 0;
  }
  > p {
    color: ${COLOR.WHITE};
    text-align: ${ALIGN.CENTER};
  }
`;

const CallToAction = styled(Flex)`
  flex-grow: 1;
  align-items: ${ALIGN_ITEMS.CENTER};
  justify-content: ${JUSTIFY_CONTENT.CENTER};
`;

const CalloutThreeColumn = ({
  heading = 'We\u0027re here to help',
  line1,
  line2,
  cta
}) => {
  return (
    <Callout>
      <Title>
        <Heading3>{heading}</Heading3>
      </Title>
      <ContactDetails>
        {line1 && <Text>{line1}</Text>}
        {line2 && <Text fontWeight={FONT_WEIGHT.BOLD}>{line2}</Text>}
      </ContactDetails>
      <CallToAction>{cta}</CallToAction>
    </Callout>
  );
};

export default CalloutThreeColumn;
