import React, { useEffect } from 'react';

function GarminPayRedirectPage({ location }) {

  useEffect(() => {
    if (window?.location) {
      window.location.replace('/digital-wallets/garmin-pay/');
    }
  }, []);

  return <></>;
}

export default GarminPayRedirectPage;
