import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { Heading4 } from '@latitude/heading';
import Section from '@latitude/section';
import { Text } from '@latitude/text';
import { Vertical } from '@latitude/spacing';
import {
  ALIGN,
  COLOR,
  FONT_SIZE,
  MARGIN
} from '@latitude/core/utils/constants';

const OurStorySection = () => {
  return (
    <Section className="bg-lightest">
      <div className="row justify-content-center">
        <div className="col-md-10 col-lg-8 text-left">
          <Vertical spacing={MARGIN.M24}>
            <Heading4
              align={ALIGN.CENTER}
              color={COLOR.BLACK}
              marginTop={MARGIN.M32}
            >
              Our story
            </Heading4>
            <StaticImage
              src="./images/our-story.png"
              alt="Latitude Office"
              placeholder="blurred"
            />
            <Text
              fontSize={FONT_SIZE.LARGE}
              marginTop={MARGIN.M16}
              align={ALIGN.LEFT}
            >
              Our mission is to be the preferred partner, to help businesses grow and people shop
              and finance their lives. We help fuel hopes, seize opportunities and solve unexpected
              problems. We&apos;re here for life&apos;s moments, big or small.
            </Text>
            <Text fontSize={FONT_SIZE.LARGE} align={ALIGN.LEFT}>
              We help our customers shop and purchase the things they need with interest free instalments
              plans and personal loans.
            </Text>
            <Text fontSize={FONT_SIZE.LARGE} align={ALIGN.LEFT}>
              We help our partners with easy payment and lending solutions, fast decisions, great insights
              and sales opportunities with our millions of customers.
            </Text>
            <Text fontSize={FONT_SIZE.LARGE} align={ALIGN.LEFT}>
              We are always motivated by our responsibility to deliver great outcomes for people
              underpinned by our values: to act right, be curious, show care.
            </Text>
            <Text fontSize={FONT_SIZE.LARGE} align={ALIGN.LEFT}>
              That&apos;s true partnership:
            </Text>
            <Text
              fontSize={FONT_SIZE.LARGE}
              marginBottom={MARGIN.M48}
              align={ALIGN.LEFT}
            >
              Partners in Shopping, Partners in Lending
            </Text>
          </Vertical>
        </div>
      </div>
    </Section>
  );
};

export default OurStorySection;
