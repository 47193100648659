import React from 'react';

import Section from '@latitude/section';
import './promoBanner.css';
import bannerImage from './images/rewards_program.webp'
import styled from 'styled-components';
import { BREAKPOINT } from '@/utils/constants';

const PromoBanner = () => {
  return (
    <Section className="low-promoheader"
      css={`
              @media (max-width: ${BREAKPOINT.MD}) {
                  padding: 30px !important;
                }
      `}
    >
      <div className="low-promoheader-item">
        <div className="low-promoheader-image">
          <img src={bannerImage} alt="banner image" />
        </div>

        <div className="low-promoheader-text">
          <h4 className="low-promoheader-title">Welcome to the home of Latitude Rewards!</h4>
          <div className="low-promoheader-subtitle">
            A low rate rewards card? You don’t see that every day. But you will see amazing offers right here in the Latitude Rewards Marketplace.
          </div>
          <StyledLink
            data-trackid="latitude-rewards-promo-banner-cta"
            href="low-rate/latitude-rewards/"
          >
            Learn more
          </StyledLink>
          <br />
          <text>
            T&Cs apply
          </text>
        </div>
      </div>
    </Section>
  )
}

export default PromoBanner;

const StyledLink = styled.a`
    background-color: white;
    height: 40px;
    position: relative;
    display: block;
    width: 130px;
    border-radius: 5px;
    margin-top: 10px;
    color: blue;
    padding: 7px 20px;
    cursor: pointer;
`
