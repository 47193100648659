import React, { useState, useEffect } from 'react';
import { SecondaryHero } from '@latitude/hero';
import { Metadata } from '@latitude/metadata';
import { TabNavigation } from '@latitude/tab-navigation';
import { Box } from '@latitude/box';
import { COLOR } from '@latitude/core/utils/constants';
import Layout from '../../components/layout';
import Securitisation from './_Securitisation';
import ContactUs from './_ContactUs';
import { StyledContainer } from './_styles';

const tabData = [
  { id: '#securitisation', label: 'Securitisation' },
  { id: '#contact-us', label: 'Contact Us' }
];

const Investor = ({ location }) => (
  <Layout location={location}>
    <main className="navigation-spacer">
      <Metadata
        title="Investor Relations | Latitude Financial"
        description="If you’re in market for a new car or another vehicle, a Car Loan from Latitude can help you achieve your goals. Apply today!"
        canonical={location.pathname}
      />
      <SecondaryHero
        heading="Investor Relations"
        subHeading="Information on securitisation programmes"
      />
      <Tabs />
    </main>
  </Layout>
);
const Tabs = () => {
  const [activeSection, setActiveSection] = useState(null);
  const defaultSection = '#securitisation';

  const setActiveFromHash = () => {
    const hash = location.hash;
    if (hash && hash !== '') {
      const tabIds = tabData.map(tab => tab.id);
      setActiveSection(false); // unset, forces TabNavigation to rerender
      if (tabIds.includes(hash.toLowerCase())) {
        setActiveSection(hash.toLowerCase());
      } else {
        setActiveSection(defaultSection);
      }
    } else {
      setActiveSection(defaultSection);
    }
  };

  useEffect(() => {
    setActiveFromHash();
    window.addEventListener('hashchange', setActiveFromHash, false);
  }, []);

  const onClickTabHandler = linkId => {
    setActiveSection(linkId);
  };

  return (
    <StyledContainer>
      {activeSection && (
        <TabNavigation
          id="tabs"
          basepath="/investor-relations/"
          data={tabData}
          defaultActiveTabId={activeSection}
          onTabClick={onClickTabHandler}
          css={`
            background-color: #fff;
            > a {
              font-weight: 300;
              letter-spacing: -0.35px;
              border-bottom: solid 4px ${COLOR.WHITE} !important;
              padding-left: 24px;
              padding-right: 24px;

              &.isActive {
                font-weight: 600;
                border-bottom: solid 4px ${COLOR.BLUE} !important;
              }
            }
            > div {
              background-color: #fff;
            }
          `}
        />
      )}
      <Box
        backgroundColor={COLOR.GREY6}
        display={activeSection === '#securitisation' ? 'block' : 'none'}
      >
        <Securitisation />
      </Box>
      <Box
        backgroundColor={COLOR.GREY6}
        display={activeSection === '#contact-us' ? 'block' : 'none'}
      >
        <ContactUs />
      </Box>
    </StyledContainer>
  );
};

export default Investor;
