/* eslint-disable global-require */
import React from 'react';

import { Promo } from '@latitude/promo';
import Section from '@latitude/section';
import { Link } from '@latitude/link';
import { BREAKPOINT } from '@latitude/core/utils/constants';

import { AnalyticsLocationProvider } from 'latitude-analytics';
import imgOffer from '../../../images/credit-cards/expedia-wotif-offer.webp';
const TITLE = 'Add another star to your accommodation!';

function ExpediaWotifSection({ id }) {
  return (
    <Section
      id={id}
      css={`
        && {
          @media (max-width: ${BREAKPOINT.LG}) {
            padding: 40px 0 calc(40px - 24px);
          }

          background-color: #f8f8f8;
          padding: 72px 0;
        }
      `}
    >
      <div
        css={`
          max-width: 920px;
          margin: 0 auto;

          [class^='Promo__PromoContentContainer'] {
            padding-top: 0;

            @media (max-width: ${BREAKPOINT.LG}) {
              h3 {
                max-width: 320px;
                margin: 0 auto;
                padding: 15px 0;
              }
            }
          }
        `}
      >
        <AnalyticsLocationProvider location={TITLE}>
          <Promo
            title={TITLE}
            description={
              <div>
                <p>
                  Book with your Latitude GO Mastercard&reg; credit card before
                  30 September 2024 to enjoy 10% discount on selected hotels at
                  Expedia and Wotif when you stay before 31 December 2024
                  <sup>2</sup>.
                </p>
              </div>
            }
            frameImage={
              <div
                css={`
                  width: 390px;
                  height: auto;
                  @media (max-width: ${BREAKPOINT.MD}) {
                    width:auto;
                  };
                `}
              >
                <img src={imgOffer} alt="Expedia and Wotif Offers" />
              </div>
            }
            frameAlignment="left"
            frameBackgroundColor="#6DC8D9"
            frameBorderColor="#0061EE"
            ctaButtons={
              <div>
                <Link
                  button="secondary"
                  href="/travelpartners"
                  trackId="expedia-wotif-offers__learn-more-button"
                  css={`
                    && {
                      @media (max-width: ${BREAKPOINT.LG}) {
                        margin-left: auto;
                        margin-right: auto;
                      }
                    }
                  `}
                >
                  Learn more
                </Link>
              </div>
            }
            verticalAlignContent={false}
          />
        </AnalyticsLocationProvider>
      </div>
    </Section>
  );
}

export default ExpediaWotifSection;
