import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import path from 'path';
import { Heading4 } from '@latitude/heading';
import {
  ALIGN,
  BUTTON_STYLE,
  COLOR,
  MARGIN
} from '@latitude/core/utils/constants';
import CardNews from './_CardNews';
import MediaFeatured from './MediaFeatured';
import Section from '../Section/Section';
import './_media.scss';
import featuredImage from '../../templates/media/images/whitepaper-interest-free.webp';

const Anchor = styled.div`
  width: 0;
  height: 0;
  position: relative;
  top: -30px;
`;

const WhitepapersMediaList = () => {
  const data = useStaticQuery(
    graphql`
      query {
        mediaReleasesWhitePapers: allMarkdownRemark(
          filter: {
            fileAbsolutePath: {
              glob: "**/src/templates/media/pastWhitePapers/*.md"
            }
          }
          sort: { fields: [frontmatter___date], order: DESC }
        ) {
          edges {
            node {
              fileAbsolutePath
              excerpt(pruneLength: 200)
              frontmatter {
                title
                date(formatString: "MMMM YYYY")
                trackId
                heroBg
                image {
                  publicURL
                }
              }
            }
          }
        }
      }
    `
  );

  const allMediaReleases = data.mediaReleasesWhitePapers.edges.map(edge => {
    const { fileAbsolutePath, excerpt, frontmatter } = edge.node;
    const { title, date, trackId, image, publicURL } = frontmatter;
    const markdownFileNameWithoutExtension = path.basename(
      fileAbsolutePath,
      path.extname(fileAbsolutePath)
    );

    return {
      path: `/about-us/whitepapers/${markdownFileNameWithoutExtension}.html`,
      title,
      date,
      excerpt,
      trackId,
      image,
      publicURL
    };
  });

  return (
    <>
      <Section className="bg-lightest">
        <Anchor id="featured-whitepaper" />
        <Heading4
          color={COLOR.BLACK}
          align={ALIGN.CENTER}
          marginTop={MARGIN.M48}
          marginBottom={MARGIN.M48}
        >
          Featured
        </Heading4>
        <MediaFeatured
          imageProps={{
            src: [featuredImage],
            alt: allMediaReleases[0].title,
            href: allMediaReleases[0].path
          }}
          titleProps={{
            title: allMediaReleases[0].title,
            isGreyBg: true,
            maxLines: 3,
            textAlign: 'left'
          }}
          date={allMediaReleases[0].date}
          description={allMediaReleases[0].excerpt}
          maxLine="5"
          buttonProps={{
            button: BUTTON_STYLE.SECONDARY,
            href: allMediaReleases[0].path,
            trackId: allMediaReleases[0].trackId,
            btnText: 'Download Whitepaper'
          }}
        />
      </Section>
      <Section className="bg-white">
        <Anchor id="past-whitepapers" />
        <Heading4
          color={COLOR.BLACK}
          align={ALIGN.CENTER}
          marginBottom={MARGIN.M48}
        >
          Past Whitepapers
        </Heading4>
        <div className="media-list">
          {allMediaReleases.map(
            ({ path, title, excerpt, trackId, image, date }) => (
              <div className="media-list-item" key={path}>
                <CardNews
                  imageProps={{
                    src: image.publicURL,
                    href: path,
                    alt: title
                  }}
                  titleProps={{ title, isGreyBg: true, maxLines: '2' }}
                  date={date}
                  description={excerpt}
                  maxLines="3"
                  buttonProps={{
                    href: path,
                    trackId,
                    btnText: 'Download Whitepaper'
                  }}
                />
              </div>
            )
          )}
        </div>
      </Section>
    </>
  );
};

export default WhitepapersMediaList;
