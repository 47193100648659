import React, { useContext, useEffect, useState } from 'react';
import Layout from '@/components/layout';
import { getUrlFromEnv } from '@/utils/getUrlFromEnvUtil';
import CarouselHeader from '@/components/Carousel/carouselHeader';
import PaymentOption from './_payment-options';
import HowToApply from './_how-to-apply';
import { Helmet } from 'react-helmet';
import AdditionalCardsBranded from '@/components/AdditionalCardsBranded/AdditionalCardsBranded';
import { ImportantInformation } from '@latitude/important-information';
import styled from 'styled-components';
import { BUTTON_STYLE, COLOR } from '@latitude/core/utils/constants';
import { ALIGN, BREAKPOINT, MARGIN } from '@/utils/constants';
import PageData from './data/low-rate-data.json';
import { Accordion } from '@latitude/accordion';
import { Box } from '@latitude/box';
import { Link } from '@latitude/link';
import { Heading4 } from '@latitude/heading';
import { AnalyticsLocationProvider } from 'latitude-analytics';
import { PageContext } from '@/context/PageContext'; // Ensure this import is correct
import { FeaturesSlider } from '@latitude/features-slider';
import PromoBanner from '@/components/lab-components/PromoBanner';
import LabRatesAndFees from '@/components/lab-components/LabRatesAndFees';
import lowRatePageDataBackUp from './data/low-rate-data-backup.json';
import HeroBanner from '@/components/lab-components/HeroBanner';

const cardList = [
  {
    title: 'Latitude 28° <br />Global Platinum Mastercard',
    href: '/credit-cards/28-degrees/',
    trackId: 'credit-cards--compare-cards--fom--latitude-low-rate',
    imageSrc: require('../../../images/credit-cards/latitude-28-degrees.png'),
    productid: ['CCAULRM'],
  },
  {
    title: 'Latitude <br /> Gem Visa',
    href: '/credit-cards/gem-visa/',
    trackId: 'credit-cards--compare-cards--fom--latitude-infinity',
    imageSrc: require('../../../images/credit-cards/latitude-gem.png'),
    productid: ['CCAUINF'],
  },
  {
    title: 'Latitude GO<br /> Mastercard',
    href: '/credit-cards/gomastercard/',
    trackId: 'credit-cards--compare-cards--fom--latitude-mastercard',
    imageSrc: require('../../../images/credit-cards/go-mastercard.png'),
    productid: ['CCAULMC'],
  },
];

const LowRateBTOfferCardPage = (props) => {
  const latitudeRewardsTitle = "Get up to $300 in bonus Latitude Rewards3"
  const balanceTransferTitle = "Get 0% for 18 months on balance transfers4" //!update this title whenever contentful data changed
  // set defaults
  const promoCodeLatitudeRewards = "GHYTSERT";
  const promoCodeBalanceTransfer = "DFGVHJUY";
  const sourceCodeCodeLatitudeRewards = "IDAAB";
  const sourceCodeBalanceTransfer = "IDAAA";

  /** Contentful Data */
  const contentfulPageData = useContext(PageContext);
  const state = contentfulPageData?.updatedBody || lowRatePageDataBackUp;
  /** - END - */
  
  const baseApplyURL = getUrlFromEnv('low-rate-credit-card');
  const [promoApplyURLLatitudeRewards, setpromoApplyURLLatitudeRewards] = useState(baseApplyURL);
  const [promoApplyURLBalanceTransfer, setpromoApplyURLBalanceTransfer] = useState(baseApplyURL);
  
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const passedPromoCode = urlParams.get('promo_code');
    const passedSourceCode = urlParams.get('source_code');

    // Retrieve from localStorage if promoCode is not present in URL
    const sourceCode = passedSourceCode || localStorage.getItem('source_code');

    // Update localstorage only if a new code has been passed in to override previous value
    if (passedSourceCode) {
      localStorage.setItem('source_code', passedSourceCode);
    };
    
    // Construct the CTAs for code passing
    const updatedURLLatitudeRewards = new URL(promoApplyURLLatitudeRewards);
    const updatedURLBalanceTransfer = new URL(promoApplyURLBalanceTransfer);
    updatedURLLatitudeRewards.searchParams.set('promo_code', passedPromoCode || promoCodeLatitudeRewards);
    updatedURLLatitudeRewards.searchParams.set('source_code', sourceCode || sourceCodeCodeLatitudeRewards);
    updatedURLBalanceTransfer.searchParams.set('promo_code', passedPromoCode || promoCodeBalanceTransfer);
    updatedURLBalanceTransfer.searchParams.set('source_code', sourceCode || sourceCodeBalanceTransfer);
    setpromoApplyURLLatitudeRewards(updatedURLLatitudeRewards.toString());
    setpromoApplyURLBalanceTransfer(updatedURLBalanceTransfer.toString());

  }, []);

  //Manually append the dynamic promo url to the contentful
  if (state) {
    const slides = state.heroBannerData?.[0].slides;

    if (slides) {
      slides.forEach(slide => {
        switch (slide.name) {
          case latitudeRewardsTitle:
            slide.buttons[0].url = promoApplyURLLatitudeRewards
            break;
          case balanceTransferTitle:
            slide.buttons[0].url = promoApplyURLBalanceTransfer
            break;
        }
      })
    }
  }

  return (
    <Layout location={props.location} noIndex>
      <main className="navigation-spacer">
        <Helmet>
          <link
            rel="canonical"
            href={props.canonical || 'https://www.latitudefinancial.com.au/'}
          />
          <title>{props.title || 'Low Rate balance transfer offer | Latitude Financial'}</title>
          <meta name="description" content="Latitude Low Rate Balance Transfer Offer" />
        </Helmet>

        {state?.heroBannerData?.[0] && (
          <HeroBanner {...state?.heroBannerData[0]} />
        )}

        {state?.featureSliderData?.[0] && (
          <FeaturesSlider
            key={state?.featureSliderData?.[0].heading}
            heading={state?.featureSliderData?.[0].heading}
            id="why-us"
            className="why-choose bg-f8f8f8"
            data={state?.featureSliderData?.[0].featureCards}
            subheading={state?.featureSliderData?.[0].description}
            css={`
              && {
                padding-bottom: 0;
              }
            `}
          />
        )}
        {state?.featureSliderData?.[1] && (
          <FeaturesSlider
            key={state?.featureSliderData?.[1].heading}
            heading={state?.featureSliderData?.[1].heading}
            id="why-us-2"
            className="why-choose bg-f8f8f8"
            data={state?.featureSliderData?.[1].featureCards}
            subheading={state?.featureSliderData?.[1].description}
            css={`
              && {
                padding-top: 0;
              }
            `}
          />
        )}

        {state?.promoBannerData?.[0] && (
          <PromoBanner {...state?.promoBannerData[0]} />
        )}

        {state?.ratesAndFeesData?.[0] && (
          <LabRatesAndFees {...state.ratesAndFeesData[0]} />
        )}

        <PaymentOption />
        <HowToApply />
        <CustomStyledComponent>
          <AdditionalCardsBranded
            links={cardList}
            heading="Which Latitude credit card is right for you?"
          />
        </CustomStyledComponent>
        <FAQSection />
        <ImportantInformation
          {...state?.importantInfoData?.[0]}
        />
      </main>
    </Layout>
  );
};
const CarouselSection = (prop) => (
  <CarouselWrapper>
    <CarouselHeader HEADER_ITEMS={prop.HeaderData} />
  </CarouselWrapper>
);


const FAQSection = () => (
  <Box.Section
    id="faq"
    css={`
      background-color: ${COLOR.WHITE};
      @media (min-width: ${BREAKPOINT.LG}) {
        width: 50%;
      }
    `}
  >
    <Heading4 align={ALIGN.CENTER} color={COLOR.BLACK} paddingBottom={MARGIN.M64}>
      Frequently Asked Questions
    </Heading4>
    <AnalyticsLocationProvider location="frequently asked questions">
      <Accordion
        css={`
          .accordion__item-body {
            padding: 16px 24px;
            background-color: #f8f8f8 !important;
          }
        `}
        titleBgColor="grey-light"
        items={PageData.Faq}
      />
    </AnalyticsLocationProvider>
    <Link
      button={BUTTON_STYLE.TERTIARY}
      css="width: 100%; margin: 64px auto; width: 320px;"
      href="/credit-cards/low-rate/faqs/"
      data-trackid="see-all-faq"
      trackEventData={{
        label: 'See all FAQ',
      }}
    >
      See all FAQ
    </Link>
  </Box.Section>
);

const CustomStyledComponent = styled.div`
  /* Custom styles here */

  [class^="Box__StyledComponent-"] {
    margin-top: 20px !important;
  }

  [class^="Heading__StyledComponent-"] {
    text-align: center !important;
  }

  [class^="AdditionalCardsBranded__Items-"] {
    display: flex;
    justify-content: space-evenly !important;
  }

  [class^="Linkstyled__StyledLink-"] {
    margin-bottom: 20px;
  }

  [class^="AdditionalCardsBranded__ItemTitle-"] {
    text-align: center;
  }

  @media (max-width: ${BREAKPOINT.MD}) {
    [class^="Linkstyled__StyledLink-"] {
      width: 80px !important;
      margin-right: 5px !important;
    }
  }
`;

const CarouselWrapper = styled.div`
  margin-top: 80px !important;
  background-color: #f8f8f8;

  .microsite-header {
    z-index: 2000 !important;
  }

  div[class^='MicrositeHeader__SecondBar'].microsite-header__nav-wrap {
    margin-top: 8px;
  }

  div[class^='pageHeaderItemImage'] {
    align-self: center !important;
    vertical-align: middle;
    align-items: center;
    display: flex;
  }

  div[class^='pageHeaderItem'] {
    height: inherit !important;
    min-height : 450px !important;
  }
`;

export default LowRateBTOfferCardPage;
