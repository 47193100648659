import React, { useEffect, useContext } from 'react';
import Helmet from 'react-helmet';
import styled from 'styled-components';
import { AnalyticsLocationProvider } from 'latitude-analytics';
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from '@latitude/link';
import { COLOR, BREAKPOINT } from '@latitude/core/utils/constants';
import { FeaturesSlider } from '@latitude/features-slider';
import { Heading4 } from '@latitude/heading';
import Section from '@latitude/section';
import { LFS_APP } from '../../utils/constants';
import { Box } from '../../components/Box/Box';
import BRANDED_CARD_DATA from '../../data/pages/credit-cards/instant-cardless-payment.json';
import HeroBranded, {
  HeroImage
} from '../../components/HeroBranded/HeroBranded';
import FilterList from '../../components/FilterList/FilterList';
import Promo, { PromoSection } from '../../components/Promo/Promo';
import Layout from '../../components/layout';
import PageData from '../../data/pages/credit-cards/index.json';
import HorizontalRule from '../../components/HorizontalRule/HorizontalRule';
import heroImage from '../../images/hero/instant-payment.webp';

import { PageContext } from '@/context/PageContext';
import { CONTENTFUL_COMPONENTS } from '@/utils/constants';
import HeroBanner from '@/components/lab-components/HeroBanner';
import { useSetState } from '@/utils/hooks';
import PageHeaderSection from '@/components/PageLayout/PageHeader';

const featureSliderItems = [
  {
    icon: 'icon-provisioning-step-1',
    title: 'Download',
    text: 'Download the Latitude App and create an ID to login.'
  },
  {
    icon: 'icon-provisioning-step-2',
    title: 'Link',
    text: 'Once you’re logged in, choose the Link Credit Card option.'
  },
  {
    icon: 'icon-provisioning-step-3',
    title: 'Verify',
    text:
      'Add your account number and date of birth. We’ll then send you an SMS to verify. Just enter the number to continue.'
  },
  {
    icon: 'icon-provisioning-step-4',
    title: 'Add',
    text:
      'Simply tap the button to add it to your Apple or Android wallet and follow the prompts.'
  },
  {
    icon: 'icon-provisioning-step-5',
    title: 'Shop',
    text: 'That’s it. You’re all set to start using your new card up to $100!*'
  }
];

const InstantProvisioning = props => {
  /** Contentful Data */
  const contentfulPageData = useContext(PageContext);
  const state = contentfulPageData?.updatedBody || {};

  const featureSliderData = [
    {
      heading: 'How to set up Instant Cardless Payment',
      featureCards: featureSliderItems
    }
  ];
  /** - END - */

  return (
    <Layout location={props.location}>
      <main className="navigation-spacer" css="background-color: #f8f8f8;">
        <Helmet>
          <link
            rel="canonical"
            href="https://www.latitudefinancial.com.au/credit-cards/instant-cardless-payment/"
          />
          <title>
            Ready, Set, Tap. Instant Cardless Payment | Latitude Financial
          </title>
          <meta name="description" content={PageData.metaDesc} />
        </Helmet>
        {state?.heroBannerData?.[0] ? (
          <HeroBanner {...state?.heroBannerData[0]} />
        ) : (
          <PageHeaderSection
            pageImage={heroImage}
            title="Ready, Set, Tap. Instant Cardless Payment"
            subTitle="Waiting for your card to arrive in the mail is a thing of the past. Start shopping from your phone in minutes, with your new applicable Latitude credit card."
          />
        )}
        <HorizontalRule />

        <Section
          id="cards"
          css={`
            && {
              padding: 48px 0;
              @media (min-width: ${BREAKPOINT.LG}) {
                padding: 72px 0;
              }
            }
          `}
        >
          <Heading4
            css={`
              color: ${COLOR.BLACK};
              text-align: center;
              margin-bottom: 16px;
            `}
          >
            Don’t have a card?
          </Heading4>
          <p className="text-center mt-0">
            Choose one to get started. Apply for your card below, and get
            tapping!
          </p>

          <CardContainer
            isResponsive
            css={`
              @media (min-width: ${BREAKPOINT.LG}) {
                margin-top: -24px;
              }
            `}
          >
            <SimpleBrandedFilterList
              isBranded
              simple
              className="branded"
              data={BRANDED_CARD_DATA.cards}
              selectedItem={PageData.categoryList}
              css={`

            &&{
              "position:relative;
              z-index:3"
            }
            [class^='card-media__buttons'] a{
              width: auto;
              max-width: unset;
              padding-left: 15px;
              padding-right: 15px;
            }
            `}
            />
            <br />
          </CardContainer>
        </Section>

        <Section
          css={`
            && {
              background-color: #e5f4ff;
              padding: 0;

              .slick-slide div[class*='FeaturesSliderstyled__CardIcon-'] {
                position: relative;
                margin-bottom: 24px;
                margin-top: 32px;

                svg {
                  z-index: 2;
                }

                &:before {
                  content: '';
                  background-color: #d0e9ff;
                  border-radius: 50%;
                  position: absolute;
                  top: -16px;
                  left: -15px;
                  width: 114px;
                  height: 114px;
                  display: block;
                  z-index: 1;
                }

                &:after {
                  display: none;
                  width: 54px;
                  height: 4px;
                  color: #b1dbff;
                  font-size: 16px;
                  font-weight: 700;
                  position: absolute;
                  top: 56px;
                  right: -75px;
                  transform: translateY(-4px);
                  letter-spacing: -1px;
                }

                @media (min-width: ${BREAKPOINT.LG}) {
                  &:before {
                    width: 150px;
                    height: 150px;
                  }
                  &:after {
                    display: block;
                    content: '• • •';
                  }
                }

                @media (min-width: ${BREAKPOINT.XL}) {
                  &:after {
                    content: '• • • • • • •';
                  }
                }
              }

              .slick-slide:last-child
                div[class*='FeaturesSliderstyled__CardIcon-'] {
                @media (min-width: ${BREAKPOINT.LG}) {
                  &:after {
                    display: none;
                  }
                }
              }
            }
          `}
        >
          <FeaturesSlider
            key={
              (state?.featureSliderData?.[0] || featureSliderData[0]).heading
            }
            id="why-choose"
            data={
              (state?.featureSliderData?.[0] || featureSliderData[0])
                .featureCards
            }
            responsive={[
              {
                breakpoint: 10000,
                settings: {
                  slidesToShow: 5
                }
              },
              {
                breakpoint: 1024,
                settings: {
                  slidesToShow: 5
                }
              },
              {
                breakpoint: 992,
                settings: {
                  slidesToShow: 3
                }
              },
              {
                breakpoint: 768,
                settings: {
                  slidesToShow: 3
                }
              },
              {
                breakpoint: 576,
                settings: {
                  slidesToShow: 1
                }
              },
              {
                breakpoint: 320,
                settings: {
                  slidesToShow: 1
                }
              }
            ]}
            className="how-to"
            heading={
              (state?.featureSliderData?.[0] || featureSliderData[0]).heading
            }
            subheading={
              (state?.featureSliderData?.[0] || featureSliderData[0])
                .description
            }
          />
        </Section>

        <AnalyticsLocationProvider location="Already have a Latitude card? Link your card to your phone">
          <PromoSection>
            <Promo
              title={
                <>
                  <Heading4
                    css={`
                      color: ${COLOR.BLACK};
                      margin: 10px auto 0;
                      // margin-top: 10px;
                      text-align: center;

                      @media (min-width: ${BREAKPOINT.LG}) {
                        text-align: left;
                        margin-top: -20px 0 0 0;
                      }
                    `}
                  >
                    Already have a Latitude card?
                    <br />
                    Link your card to your phone
                  </Heading4>
                </>
              }
              description={
                <div
                  css={`
                    font-size: 18px;
                    font-weight: 300;
                    line-height: 1.63;
                    color: ${COLOR.BLACK};
                    max-width: 334px;

                    @media (min-width: ${BREAKPOINT.LG}) {
                      font-size: 24px;
                      max-width: none;
                    }
                  `}
                >
                  <p>
                    You can pay any way on your phone or wearable tech device.
                    To get started, download the Latitude App now.
                  </p>
                </div>
              }
              frameImage={
                <StaticImage
                  src="../../images/frame-download-app@2x.jpg"
                  alt="Link your Latitude card"
                  width={400}
                />
              }
              verticalAlignContent
              frameAlignment="left"
              frameBorderColor="#ffc9e4"
              ctaButtons={
                <>
                  <Link
                    noStyle
                    href="https://itunes.apple.com/app/apple-store/id1373059866?pt=118165058&ct=InfPayOpt0319&mt=8"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="d-inline link-noline"
                    data-trackid="payment-options-itunes-app-link"
                    trackEventData={{
                      label: LFS_APP.APP_STORE
                    }}
                  >
                    <img
                      src={require('../../images/badge-app-store.svg').default}
                      height="40px"
                      alt="app store"
                    />
                  </Link>
                  <Link
                    noStyle
                    href="https://play.google.com/store/apps/details?id=com.latitudefinancial.latitudeapp&referrer=utm_source%3DInfPayOpt0319"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="d-inline link-noline"
                    data-trackid="payment-options-googleplay-app-link"
                    trackEventData={{
                      label: LFS_APP.GOOGLE_PLAY
                    }}
                    css={`
                      margin: 0 0 0 10px;
                    `}
                  >
                    <img
                      src={
                        require('../../images/badge-google-play.svg').default
                      }
                      height="40px"
                      alt="play store"
                    />
                  </Link>
                </>
              }
            />
          </PromoSection>
        </AnalyticsLocationProvider>

        <Section
          css={`
            && {
              background-color: #f2f2f2;
              font-weight: 300;
              padding: 40px 20px;
              text-align: center;

              @media (min-width: ${BREAKPOINT.LG}) {
                padding: 48px 0;
                text-align: left;
              }
            }
          `}
        >
          <div
            css={`
              max-width: 1024px;
              margin: 0 auto;
            `}
          >
            <p>
              Google Pay, Android, the Google Pay Logo, Google Play and the
              Google Play logo are trademarks of Google LLC.
            </p>
            <p>
              Apple, the Apple logo, Apple Pay, Apple Watch, Face ID, iPad,
              iPhone, Safari, and Touch ID are trademarks of Apple Inc., and App
              Store is a service mark of Apple Inc., registered in the U.S. and
              other countries.
            </p>
            <p>
              *&nbsp;It's important to be aware that you might be limited to
              transactions under $100 until your card arrives in the post, you
              activate it and set a PIN.
            </p>
            <p>
              ^&nbsp;The Latitude 28&deg; Global Platinum Mastercard was awarded
              the{' '}
              <Link href="https://www.canstar.com.au/star-rating-reports/travel-credit-debit-card-star-ratings/">
                Canstar
              </Link>{' '}
              five star rating for Outstanding Value Travel Credit Card from
              2015&ndash;2023.
            </p>
          </div>
        </Section>
      </main>
    </Layout>
  );
};

const StyledHeroBranded = styled(HeroBranded)`
  .HeroContent {
    background: transparent;
    margin-top: -60px;
    @media (min-width: ${BREAKPOINT.SM}) {
      margin-top: 0;
    }
  }
  ${HeroImage} {
    height: 310px;

    @media (min-width: ${BREAKPOINT.MD}) {
      height: auto;
    }
  }
`;

const CardContainer = styled(Box)`
  position: relative;
  margin: 0 auto;
`;

const SimpleBrandedFilterList = styled(FilterList)`
  .dropdown-container,
  .dropdown-container-fixed {
    display: none;
  }
  .card-media-fees,
  .card-media__list {
    display: none;
  }
  .card-media--tall {
    @media (min-width: ${BREAKPOINT.LG}) {
      min-height: 385px;
    }
  }
  .card-media__desc:after {
    background-color: #fff;
    @media (min-width: ${BREAKPOINT.LG}) {
      bottom: -38px;
      height: 70px;
    }
  }
  .card-media--staggered {
    @media (max-width: ${BREAKPOINT.SM}) {
      padding: 0;
    }
    .card-media--tall {
      @media (max-width: ${BREAKPOINT.LG}) {
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
  .card-media--staggered:nth-child(2) {
    .card-media__desc {
      @media (min-width: ${BREAKPOINT.LG}) {
        // background: red;
        padding-bottom: 90px;
      }
    }
  }
  .card-media__buttons {
    a:first-child {
    }
    a:last-child {
      margin-right: auto;
    }
  }
  .card-media--staggered:nth-child(3),
  .card-media--staggered:nth-child(4),
  .card-media--staggered:nth-child(5),
  .card-media--staggered:nth-child(6) {
    .card-media--tall {
      @media (min-width: ${BREAKPOINT.LG}) {
        min-height: 365px;
      }
    }
  }

  // Remove bottom margin for 5th tile
  .card-media--staggered:nth-child(5) {
    > .card-media {
      @media (min-width: ${BREAKPOINT.LG}) {
        margin-bottom: 0 !important;
      }
    }
  }

  // Hide creditline card (the 6th tile)
  .card-media--staggered:nth-child(6) {
    display: none;
    @media (min-width: ${BREAKPOINT.LG}) {
      display: block;
      height: 1px;
      overflow: hidden;
      visibility: hidden;
    }
  }
`;

export default InstantProvisioning;
