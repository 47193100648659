import React from 'react';
import styled from 'styled-components';
import CardMedia from '../CardMedia/CardMedia';
import videos from './videos.json';
import './_corporate-responsibility.scss';

const CorporateResponsibilityVideoListItem = props => {
  const { video } = props;
  return (
    <div className="col-sm-6 col-md-6 col-lg-4 corporate-responsibility-video-list-item mb-5">
      <StyledCardMedia isVideo embedSrc={video.src} title={video.title} />
    </div>
  );
};

const CorporateResponsibilityVideoList = () => {
  const items = videos.map((video, index) => (
    <CorporateResponsibilityVideoListItem video={video} key={index} />
  ));
  return <div className="row justify-content-center">{items}</div>;
};

const StyledCardMedia = styled(CardMedia)`
  .card-media__title-container {
    background-color: white;
  }
  .card-media__title {
    color: black;
  }
`;

export default CorporateResponsibilityVideoList;
