import React, { useEffect, useContext } from 'react';
import Layout from '@/components/layout';
import Metadata from '@/components/Metadata/Metadata';
import FeatureTiles from '@/components/FeatureTiles/FeatureTiles';
import PageData from '../data/pages/loans/loan-repayment-calculator.json';
import FeaturesStaggeredSlider from '../components/FeaturesStaggeredSlider/FeaturesStaggeredSlider';

import heroImage from '../images/hero/pl-loan-repayment.webp';

import { PageContext } from '@/context/PageContext';
import { CONTENTFUL_COMPONENTS } from '@/utils/constants';
import HeroBanner from '@/components/lab-components/HeroBanner';
import { useSetState } from '@/utils/hooks';
import PageHeaderSection from '@/components/PageLayout/PageHeader';

const CalculatorsAndToolsPage = props => {
  /** Contentful Data */
  const contentfulPageData = useContext(PageContext);
  const state = contentfulPageData?.updatedBody || {};
  /** - END - */

  return (
    <Layout location={props.location}>
      <main className="navigation-spacer">
        <Metadata
          title={PageData.metaTitle}
          description={PageData.metaDesc}
          canonical="/loan-repayment-calculator/"
        />
        {state?.heroBannerData?.[0] ? (
          <HeroBanner {...state?.heroBannerData[0]} />
        ) : (
          <PageHeaderSection
            pageImage={heroImage}
            title="Loan Repayment Calculators and Tools"
            subTitle="Some simple tools to help you kick your financial goals faster."
          />
        )}
        <FeatureTiles
          id="calculators-and-tools"
          tiles={PageData.content.calculators}
        />

        <FeaturesStaggeredSlider
          css={`
            background-color: #f8f8f8;
            .card-icon__image::after {
              background: transparent !important;
            }
            .slick-track .slick-slide:nth-child(2n + 2) {
              -webkit-transform: none !important;
              -ms-transform: none !important;
              transform: none !important;
            }
            .lfs-slide {
              box-shadow: 0 3px 2px 0px rgba(0, 0, 0, 0.25) !important;
            }
            .card-icon h5 {
              font-size: 18px !important;
              margin: 10px 0 !important;
            }
            .card-icon__content p {
              color: #696969 !important;
            }
            .whats-important .slick-slider .slick-slide.slick-active {
              margin: 0 5px !important;
            }
          `}
          data={PageData.categories}
          className="whats-important"
          heading="What loan can I use these calculators for?"
          responsiveSettings={PageData.categoriesResponsiveSettings}
        />
      </main>
    </Layout>
  );
};

export default CalculatorsAndToolsPage;
