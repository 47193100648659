import React, { useEffect } from 'react';

function RedirectToZenDeskGemFaq({ location }) {
  useEffect(() => {
    if (window?.location) {
      window.location.replace('https://latitudefs.zendesk.com/hc/en-au/articles/30486892787985-CreditLine-available-through-Apple-Update-to-Latitude-Gem-Visa-FAQs');
    }
  }, []);

  return <></>;
}

export default RedirectToZenDeskGemFaq;
