/* eslint-disable import/no-dynamic-require */
import React from 'react';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';
import Hero from '../../components/Hero/Hero';
import Section from '../../components/Section/Section';
import ArticleTile from './ArticleTile';
import Layout from '../../components/layout';
import Remark from '../../components/Remark/Remark';
import { Heading2, Heading3, Heading4 } from '../../components/Heading/Heading';
import Text from '../../components/Text/Text';
import { COLOR, MARGIN } from '../../utils/constants';

import './article.scss';

export default function Template({
  data // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark, allMarkdownRemark } = data; // data.markdownRemark holds our post data
  const { frontmatter, htmlAst } = markdownRemark;
  const {
    id,
    title,
    author,
    meta_title,
    meta_description,
    thumbnail,
    heroBg,
    heroAriaLabel
  } = frontmatter;

  const baseUrl = 'https://www.latitudefinancial.com.au/life-done-better';
  const filepath = `${id}.html`;
  const url = encodeURI(`${baseUrl}/${filepath}`);

  const encodedTitle = encodeURI(title);

  const social = [
    {
      icon: '/assets/icons/icons-sprite.svg#twitter',
      url: `https://twitter.com/intent/tweet?url=${url}`,
      target: '_blank'
    },
    {
      icon: '/assets/icons/icons-sprite.svg#linkedin',
      url: `https://www.linkedin.com/shareArticle?mini=true&url=${url}&title=${encodedTitle}&source=${url}`,
      target: '_blank'
    },
    {
      icon: '/assets/icons/icons-sprite.svg#facebook',
      url: `http://www.facebook.com/sharer.php?u=${url}`,
      target: '_blank'
    },
    {
      icon: '/assets/icons/icons-sprite.svg#mail',
      url: `mailto:?subject=${encodedTitle}&body=${url}`
    },
    {
      icon: '/assets/icons/icons-sprite.svg#print',
      url: 'javascript:window.print()'
    }
  ];

  return (
    <Layout noBreadcrumb>
      <main className="navigation-spacer">
        <Helmet
          title={meta_title ? meta_title : `${title} | Latitude Financial`}
        >
          {meta_description && (
            <meta name="description" content={meta_description} />
          )}

          <link rel="canonical" href={url} />
          <link rel="amphtml" href={`${baseUrl}/amp/${filepath}`} />
          <meta property="og:type" content="article" />
          <meta property="og:title" content={title} />
          <meta property="og:description" content={meta_description} />
          <meta
            property="og:image"
            content={`${baseUrl}/images/${thumbnail}`}
          />
          <meta property="og:url" content={url} />
          <meta property="og:meta_title" content={meta_title} />
          <meta property="og:meta_description" content={meta_description} />

          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content={title} />
          <meta name="twitter:description" content={meta_description} />
          <meta
            name="twitter:image"
            content={`${baseUrl}/images/${thumbnail}`}
          />
          <meta name="twitter:image:alt" content={title} />
        </Helmet>

        <Hero
          h1={title}
          intro={author ? `By ${author}` : ''}
          darkBg
          bg={
            require(`../../../static/life-done-better/images/${heroBg}`).default
          }
          bgAriaLabel={heroAriaLabel}
        />

        <Section className="bg-white life-done-better">
          <div className="row">
            <div className="col-lg-8">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/">Home</a>
                  </li>
                  <li className="breadcrumb-item">
                    <a href="/life-done-better">Life done better</a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    {title}
                  </li>
                </ol>
              </nav>
            </div>
            <div className="col-lg-4">
              <div className="legacy">
                <ul className="article-actions">
                  {social.map(item => (
                    <li className="article-actions__action" key={item.url}>
                      <a
                        className="article-actions__action__link js-article-action--twitter"
                        href={item.url}
                        target={item.target}
                      >
                        <svg className="article-actions__action__link__icon">
                          <use xlinkHref={item.icon} />
                        </svg>
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-8">
              <Remark
                htmlAst={htmlAst}
                H2Component={
                  <Heading2 color={COLOR.BLUE} marginTop={MARGIN.M32} />
                }
                H3Component={<Heading3 color={COLOR.BLUE} />}
                H4Component={<Heading4 color={COLOR.BLUE} />}
                PComponent={
                  <Text marginTop={MARGIN.M16} marginBottom={MARGIN.M16} />
                }
              />
            </div>

            <div className="col-lg-4 latest-articles">
              <h4>Latest articles:</h4>
              {allMarkdownRemark.edges.map(item => {
                const article = item.node.frontmatter;
                return <ArticleTile key={article.path} {...article} />;
              })}
            </div>
          </div>
        </Section>
      </main>
    </Layout>
  );
}

export const pageQuery = graphql`
  query ArticleByPath($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      htmlAst
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        id
        title
        thumbnail
        author
        heroBg
        heroAriaLabel
        meta_title
        meta_description
      }
    }

    allMarkdownRemark(
      filter: {
        fileAbsolutePath: {
          glob: "**/src/templates/life-done-better/articles/*.md"
        }
      }
      sort: { order: DESC, fields: [frontmatter___date] }
      limit: 5
    ) {
      edges {
        node {
          html
          frontmatter {
            id
            title
            description
            author
            date
            path
            thumbnail
          }
        }
      }
    }
  }
`;
