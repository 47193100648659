import React, { useContext } from 'react';
import styled from 'styled-components';
import Layout from '@/components/layout';
import { Helmet } from 'react-helmet';
import { ALIGN, BREAKPOINT, BUTTON_STYLE } from '@latitude/core/utils/constants';
import { Link } from '@latitude/link';
import { PageContext } from '@/context/PageContext';
import HeroBanner from '@/components/lab-components/HeroBanner';
import PromoBanner from '@/components/lab-components/PromoBanner';
import StandaloneText from '@/components/lab-components/StandaloneText';
import { FeaturesSlider } from '@latitude/features-slider';
import { ImportantInformation } from '@latitude/important-information';
import Section from '@latitude/section';
import { Text } from '@latitude/text';
import HowToApply from '@latitude/how-to-apply';
import { Heading4, Heading5 } from '@latitude/heading';
import { List, ListItem } from '@latitude/list';
import { Box } from '@latitude/box';
import SvgInline from '@latitude/svg-inline';
import insurancePageDataBackUp from './data/insurance-data-backup.json';

const BoxQuestion = styled(Box)`
  padding: 40px 0;
  background-color: #e8f4ff;
  background-position: left top, right 122%;
  text-align: center;
  [class*='Linkstyled__StyledLink']:hover {
    svg * {
      fill: #fff !important;
    }
  }
  @media (min-width: ${BREAKPOINT.LG}) {
    padding: 72px 0;
  }
`;

const InsurancePage = (props) => {
  const featureSliderItems = [];

  /** Contentful Data */
  const contentfulPageData = useContext(PageContext);
  const state = contentfulPageData?.updatedBody || insurancePageDataBackUp;
  /** - END - */

  return (
    <Layout location={props.location}>
      <main className="navigation-spacer" css="background-color: #f8f8f8;">
        <Helmet>
          <link
            rel="canonical"
            href="https://www.latitudefinancial.com.au/credit-cards/low-rate/insurance/"
          />
          <title>Latitude Low Rate Insurance | Latitude Financial</title>
          <meta name="description" content="Latitude Low Rate Insurance" />
        </Helmet>

        {state?.heroBannerData?.[0] && (
          <HeroBanner {...state?.heroBannerData[0]} />
        )}

        {state?.promoBannerData?.[0] && (
          <PromoBanner {...state?.promoBannerData[0]} />
        )}
        
        <Section heading="Policy coverage">
          <Text align={ALIGN.CENTER}>
            Below is a limited summary only. For the full list of benefits and the terms, conditions, exclusions, limits, applicable sub-limits and excesses that apply, please view the
            {' '}
            <Link
              href="https://assets.latitudefinancial.com/legals/Low-Rate-Mastercard-Complimentary-Insurance-Terms-and-conditions-2024.pdf"
              target="_blank"
            >
              E-Commerce Purchase Protection terms and conditions
            </Link>
            .
          </Text>
          <HowToApply
            box1={
              <>
                <Heading5>Complimentary E-Commerce Purchase Protection Insurance</Heading5>
                <Text><b>Covers you for:</b></Text>
                <List>
                  <ListItem>
                    Non-delivery/and or incomplete delivery of Goods, that are purchased on the internet, if 
                    (i)	unless otherwise advised by the Seller of a delay, the Goods have not been delivered within 30 days of the scheduled delivery date, and 
                    (ii) the Seller has failed to refund You within 60 days of non-delivery.
                  </ListItem>
                  <ListItem>
                    Improper functioning due to physical damage of delivered Goods that are purchased on the internet, provided 
                    (i)	this resulted from physical damage which was apparent or manifest at time of delivery of the Goods to You; and 
                    (ii) the Seller has failed to refund You within 60 days of delivery
                  </ListItem>
                </List>
                <br />
                <Text><b>Benefit amount (AUD)</b></Text>
                <List>
                  <ListItem>
                    Per Occurrence Limit: $270 
                  </ListItem>
                  <ListItem>
                    Annual Total Limit: $270 
                  </ListItem>
                </List>
                <br />
                <Text><b>Excess</b></Text>
                <List>
                  <ListItem>
                    Nil
                  </ListItem>
                </List>
                <br />
                <Text>
                  <b>
                    Exclusions <br />
                    (NOTE this list is not exhaustive, please refer T&Cs for full list and definitions)
                  </b>
                </Text>
                <List>
                  <ListItem>
                    Animals or plant life
                  </ListItem>
                  <ListItem>
                    Cash, negotiable instruments, shares, travellers cheques, or tickets of any description (eg entertainment, sports, travel)
                  </ListItem>
                  <ListItem>
                    Consumable or perishable items
                  </ListItem>
                  <ListItem>
                    Goods purchased for commercial use
                  </ListItem>
                  <ListItem>
                    Access to internet websites, software or data files downloaded off the internet
                  </ListItem>
                  <ListItem>
                    Services provided via the Internet such as cinema tickets, air tickets, hotel bookings, car rental, financial advice
                  </ListItem>
                  <ListItem>
                    Goods purchased for resale or items which are used goods, damaged goods or second-hand goods at the time of purchase                  </ListItem>
                  <ListItem>
                    Permanent household and/or business fixtures
                  </ListItem>
                  <ListItem>
                    Art, antiques, firearms and Collectable Items
                  </ListItem>
                  <ListItem>
                    Furs, watches, jewellery, gems, precious stones and articles made of or containing gold/precious metals)                  </ListItem>
                  <ListItem>
                    Goods deemed to be illegal
                  </ListItem>
                </List>
                <br />
              </>
            }
          />
        </Section>

        {state?.standaloneTextData?.[0] && (
          <StandaloneText {...state?.standaloneTextData?.[0]} />
        )}

        {state?.featureSliderData?.[0] && (
          <FeaturesSlider
            key={state?.featureSliderData?.[0].heading}
            heading={state?.featureSliderData?.[0].heading}
            id="why-us"
            className="why-choose bg-f8f8f8"
            data={state?.featureSliderData?.[0].featureCards}
            subheading={state?.featureSliderData?.[0].description}
            css={`
              && {
                padding-bottom: 0;
              }
            `}
          />
        )}
        {state?.featureSliderData?.[1] && (
          <FeaturesSlider
            key={state?.featureSliderData?.[1].heading}
            heading={state?.featureSliderData?.[1].heading}
            id="why-us-2"
            className="why-choose bg-f8f8f8"
            data={state?.featureSliderData?.[1].featureCards}
            subheading={state?.featureSliderData?.[1].description}
            css={`
              && {
                padding-top: 0;
              }
            `}
          />
        )}

        <BoxQuestion>
          <Heading4
            align={ALIGN.CENTER}
            css="margin-bottom:8px;"
          >
            Have a question?
          </Heading4>
          <Text
            align={ALIGN.CENTER}
            css="margin-bottom:24px;"
          >
            Contact AIG on the details above or check our FAQs page to get answers.
          </Text>
          <Link
            button={BUTTON_STYLE.TERTIARY}
            href="/credit-cards/low-rate/faqs/"
            target='_blank'
            trackId="have-a-question-banner__find-out-more-button"
            disableDefaultEventTracking
            css={`
              display: inline-block;
              svg {
                position: relative;
                bottom: 2px;
                width: 35px;
              }
              &:hover{
                svg *{
                  fill #fff !imporatant;
                }
              }
            `}
          >
            Explore FAQ
            <SvgInline name="external-link" />
          </Link>
        </BoxQuestion>

        {state?.importantInfoData?.[0] && 
          <ImportantInformation
            {...state?.importantInfoData?.[0]}
          />
        }

      </main>
    </Layout>
  );
};

export default InsurancePage;
