import React from 'react';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';
import Hero from '../../components/Hero/Hero';
import Layout from '../../components/layout';
import { Box } from '../../components/Box/Box';
import { MARGIN, COLOR, PADDING, SITE_URL } from '../../utils/constants';
import Remark from '../../components/Remark/Remark';
import Text from '../../components/Text/Text';
import { Heading5 } from '../../components/Heading/Heading';
import utilityHero from '../../images/hero/utility-hero.jpg';

const TermsAndConditionsTemplate = ({ data }) => {
  const { markdownRemark } = data;
  const { frontmatter, htmlAst } = markdownRemark;
  const { title, path } = frontmatter;

  const url = `${SITE_URL}${path}`;

  return (
    <Layout noBreadcrumb>
      <main className="navigation-spacer">
        <Helmet title={`${title} | Terms and Conditions`}>
          <link rel="canonical" href={url} />

          <meta
            property="og:title"
            content={`${title} - Terms and Conditions`}
          />
          <meta property="og:url" content={url} />
        </Helmet>

        <Hero h1={title} h2="Terms & Conditions" bg={utilityHero} darkBg />
        <Box
          isResponsive
          isBodyCopy
          margin={`0 ${MARGIN.MAUTO}`}
          paddingBottom={PADDING.P32}
        >
          <Remark
            htmlAst={htmlAst}
            H5Component={<Heading5 color={COLOR.BLUE} marginTop={MARGIN.M32} />}
            PComponent={
              <Text marginTop={MARGIN.M16} marginBottom={MARGIN.M16} />
            }
          />
        </Box>
      </main>
    </Layout>
  );
};

export const pageQuery = graphql`
  query TCsByPath($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      htmlAst
      frontmatter {
        path
        title
      }
    }
  }
`;

export default TermsAndConditionsTemplate;
