import React from 'react';
import styled from 'styled-components';
import Layout from '@/components/layout';
import ReactHtmlParser from 'react-html-parser';
import { StaticImage } from 'gatsby-plugin-image';
import { ListItem } from '@latitude/list';
import { Heading3 } from '@latitude/heading';
import { Metadata } from '@latitude/metadata';
import { SecondaryHero } from '@latitude/hero';
import { Flex, Box } from '@latitude/box';
import { BREAKPOINT } from '@latitude/core/utils/constants';
import { ALIGN, COLOR, MARGIN, FLEX_WRAP } from '@/utils/constants';
import { Link } from '@latitude/link';
import { Tile, TileContainer, TileIcon, StyledList } from './_FormsStyle';
import PageData from './data/forms.json';
import FormLists from './components/_formLists';

const FormsPage = props => (
  <Layout location={props.location}>
    <Main className="navigation-spacer">
      <Metadata
        title="Useful Forms | Latitude Financial Services"
        description="Choose a credit card to suit your needs using Latitude's credit card comparison tool. Find the best credit card for you and apply online!"
        canonical="/forms/"
      />

      <SecondaryHero
        heading={
          <>
            Useful card related <BrDesktop /> forms
          </>
        }
      />
      <Box.Section id="letters-forms" backgroundColor={COLOR.GREY6}>
        <TileGroup>
          <TileForms />
          <LetterForms />
        </TileGroup>
      </Box.Section>
      <Flex
        isResponsive
        flexWrap={FLEX_WRAP.WRAP}
        margin={`0px ${MARGIN.MAUTO}`}
        padding="0px 0 42px"
      >
        <FormLists />
      </Flex>
    </Main>
  </Layout>
);

const TileGroup = styled.div`
  @media (min-width: ${BREAKPOINT.LG}) {
    display: flex;
    flex-direction: row;
  }
`;

const Main = styled.main`
  background-color: #f8f8f8;
  h1 {
    color: ${COLOR.BLACK} !important;
  }
`;

const BrDesktop = styled.br`
  display: none;
  @media (min-width: ${BREAKPOINT.LG}) {
    display: block;
  }
`;

const TileForms = () => (
  <div
    css={`
      & > div {
        margin-left: auto;
        margin-right: auto;
      }
      @media (min-width: ${BREAKPOINT.LG}) {
        display: flex;
        flex-basis: 65%;
        & > div {
          margin-right: 30px;
        }
      }
    `}
  >
    <TileContainer>
      <Tile tileBackground="blue">
        <TileIcon>
          <StaticImage src="./icon-forms.svg" alt="forms-icon" />
        </TileIcon>
        <Heading3
          color={COLOR.BLACK}
          align={ALIGN.CENTER}
          marginBottom={MARGIN.M8}
        >
          {ReactHtmlParser(PageData.forms[0].title)}
        </Heading3>
      </Tile>
      <Tile>
        <StyledList pointless>
          {PageData.forms[0].links.map(item => (
            <ListItem>
              <Link
                className="no-wrap"
                target="_blank"
                href={item.value}
                trackEventData={{
                  location: PageData.forms[0].title
                }}
              >
                {item.label}
              </Link>
            </ListItem>
          ))}
        </StyledList>
      </Tile>
    </TileContainer>
  </div>
);

const LetterForms = () => (
  <div
    css={`
      & > div {
        margin-left: auto;
        margin-right: auto;
        t
      }
      @media (min-width: ${BREAKPOINT.LG}) {
        display: flex;
        flex-basis: 65%;
        & > div {
          margin-right: 0px;
        }
      }
    `}
  >
    <TileContainer>
      <Tile tileBackground="blue">
        <TileIcon>
          <StaticImage src="./icon-letters.svg" alt="letters-icon" />
        </TileIcon>
        <Heading3
          color={COLOR.BLACK}
          align={ALIGN.CENTER}
          marginBottom={MARGIN.M8}
        >
          {ReactHtmlParser(PageData.letter[0].title)}
        </Heading3>
      </Tile>
      <Tile>
        <StyledList pointless>
          {PageData.letter[0].links.map(item => (
            <ListItem>
              <Link className="no-wrap" target="_blank" href={item.value}>
                {item.label}
              </Link>
            </ListItem>
          ))}
        </StyledList>
      </Tile>
    </TileContainer>
  </div>
);

export default FormsPage;
