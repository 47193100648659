import React, { useState, useEffect, useContext } from 'react';

import Section from '@latitude/section';
import { List, ListItem } from '@latitude/list';
import { FeaturesSlider } from '@latitude/features-slider';
import { Text } from '@latitude/text';
import { FeatureTiles } from '@latitude/feature-tiles';
import MobileAppInstallPrompts from '@latitude/mobile-app-install-prompt/MobileAppInstallPrompt';

import { ImportantInformation } from '@latitude/important-information';
import Layout from '@/components/layout';
import { Box } from '@/components/Box/Box';
import PageData from '@/data/pages/calculators-and-tools/car-loan-repayment-calculator.json';
import StickyNavigationBranded from '@/components/StickyNavigation/StickyNavigationBranded';
import PersonalLoanCalculator from '@/components/PersonalLoanCalculator2/PersonalLoanCalculator';
import {
  ALIGN,
  COLOR,
  FONT_SIZE,
  FONT_WEIGHT,
  PL_APPLY_CTA_TRACK_ID,
  PL_SYMPLE_MINIMUM_AMOUNT,
  PL_SYMPLE_DEFAULT_PERIOD
} from '@/utils/constants';
import {
  redirectUrl,
  softQuoteHrefFunc
} from '@/components/EstimateRateWidget/utils';
import heroImage from '../images/hero/car-loans-image.webp';

import { PageContext } from '@/context/PageContext';
import HeroBanner from '@/components/lab-components/HeroBanner';
import PageHeaderSection from '@/components/PageLayout/PageHeader';
import ApplyingIsSimpleSection from '@/components/PersonalLoanPageContent/ApplyingIsSimpleSection';

const COLOUR_WHITE = '#FFF';
const COLOUR_PALE_BLUE = '#E5F6FF';
const CTA_LABEL = 'Get my rate estimate';

const WHY_CHOOSE_ITEMS = [
  {
    icon: 'icon2-snap-fingers-blue',
    title: 'Simple application',
    jsx: (
      <List>
        <ListItem>Apply online in under 7 minutes</ListItem>
        <ListItem>
          Only a few documents needed for ID and income verification
        </ListItem>
      </List>
    )
  },
  {
    icon: 'icon2-flexible-blue',
    title: 'Flexible financing',
    jsx: (
      <List>
        <ListItem>No early repayment fee on variable loans</ListItem>
        <ListItem>
          Access additional repayments you've made when you need them with
          Redraw on variable loans
        </ListItem>
      </List>
    )
  },
  {
    icon: 'icon2-handshake-blue',
    title: 'Solutions to suit you',
    jsx: (
      <List>
        <ListItem>Our team will help you through the process</ListItem>
        <ListItem>Loan options tailored to your specific needs</ListItem>
      </List>
    )
  }
];

import iconWhy1 from './personal-loan-repayment-calculator/images/why-latitude1.webp';
import iconWhy2 from './personal-loan-repayment-calculator/images/why-latitude2.webp';
import NextStepsSection from '@/components/PersonalLoanPageContent/NextStepsSection';
import AreYouEligibleSection from '@/components/PersonalLoanPageContent/AreYouEligibleSection';

const WHY_LATITUDE_ITEMS = [
  {
    icon: iconWhy1,
    title: 'Why Latitude?',
    jsx: (
      <Text color={COLOR.WHITE}>
        We've been helping Aussies manage their finances for close to a century.
        Trusted by 2.1 millions customers today, we're ready to back your
        journey, wherever it leads.
      </Text>
    )
  },
  {
    icon: iconWhy2,
    title: 'Award-winning Personal Loans',
    jsx: (
      <Text color={COLOR.WHITE}>
        WeMoney Best in Flexibility 2 years running.
      </Text>
    )
  }
];

// eslint-disable-next-line arrow-body-style
function CarLoanCalculatorPage({ location }) {
  // soft quote
  const [softQuoteHref, setSoftQuoteHref] = useState();
  useEffect(() => {
    sessionStorage.setItem('purpose', 'purpose'); // reset session val used in `EstimateRateWidgetSection`
    sessionStorage.setItem('loanAmount', PL_SYMPLE_MINIMUM_AMOUNT.toString());
    sessionStorage.setItem('repaymentPeriod', PL_SYMPLE_DEFAULT_PERIOD);
    setSoftQuoteHref(redirectUrl('au', true));
  }, []);

  /** Contentful Data */
  const contentfulPageData = useContext(PageContext);
  const state = contentfulPageData?.updatedBody || {};
  /** - END - */

  return (
    <Layout
      location={location}
      title={PageData.title}
      metaDesc={PageData.description}
      noMetaKeywords
      canonical="/car-loan-repayment-calculator/"
    >
      <main className="navigation-spacer">
        <Box backgroundColor={COLOR.GREY6}>
          <MobileAppInstallPrompts />

          {state?.heroBannerData?.[0] ? (
            <HeroBanner {...state?.heroBannerData[0]} />
          ) : (
            <PageHeaderSection
              pageImage={heroImage}
              title={
                <>
                  Car Loan
                  <br />
                  Repayment Calculator
                </>
              }
              subTitle="How much will a Latitude Car Loan actually cost you? Use our calculator now to explore potential car loan repayments now."
            />
          )}
          <div
            className="d-none d-lg-block"
            css="position:relative; z-index:2;"
          >
            <StickyNavigationBranded
              items={PageData.nav}
              phoneNumber={PageData.content.phoneNumber}
              ctaHref={softQuoteHrefFunc(softQuoteHref)}
              ctaText={CTA_LABEL}
              offsetElem="[data-sticky-navigation-offset]"
              target="_self"
              trackEventData={{
                category: 'cta',
                action: 'quote-link',
                label: CTA_LABEL,
                location: 'check-your-rate'
              }}
              {...state?.inPageNavData?.[0]}
            />
          </div>
          <Section id="calculator" css={{ backgroundColor: COLOUR_PALE_BLUE }}>
            <PersonalLoanCalculator
              loans1
              applyCTATrackId={PL_APPLY_CTA_TRACK_ID}
              ctaButtonLabel={CTA_LABEL}
            />
          </Section>
          <Section
            id="why-latitude"
            heading="Why choose a Latitude car loan?"
            css={{ backgroundColor: COLOUR_WHITE }}
          >
            <FeaturesSlider
              css={`
                && {
                  padding-top: 0;
                }
                && div.lfs-card {
                  border-radius: 14px;
                }
              `}
              data={WHY_CHOOSE_ITEMS}
            />
          </Section>
          <Section css={{ backgroundColor: '#5E47B9' }}>
            <div
              css={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                justifyContent: 'center',
                gap: '20px'
              }}
            >
              {WHY_LATITUDE_ITEMS.map((item, index) => {
                return (
                  <div
                    key={index}
                    css={{
                      flexBasis: '350px',
                      padding: '20pt 10pt'
                    }}
                  >
                    <div css={{ width: '100%', height: 'auto' }}>
                      <img
                        css={{ width: '100%', objectFit: 'cover' }}
                        src={item.icon}
                        alt={item.title}
                      />
                    </div>
                    <Text
                      align={ALIGN.CENTER}
                      fontSize={FONT_SIZE.LARGE}
                      fontWeight={FONT_WEIGHT.BOLD}
                      color={COLOR.WHITE}
                    >
                      {item.title}
                    </Text>
                    <div
                      css={{
                        marginTop: '15px'
                      }}
                    >
                      {item.jsx}
                    </div>
                  </div>
                );
              })}
            </div>
          </Section>
          <ApplyingIsSimpleSection
            id="how-to-apply"
            heading="Applying for a Latitude Personal Loan is simple"
          />
          <AreYouEligibleSection
            id="eligibility-criteria"
            heading="Are you eligible for a Personal Loan?"
            css={{ backgroundColor: COLOUR_WHITE }}
          />
          <NextStepsSection
            ctaLinkHref={softQuoteHref}
            ctaTrackingLocation="car-loan-calculator-next-step"
            ctaButtonLabel={CTA_LABEL}
          />

          <FeatureTiles
            id={PageData.nav[4].anchor}
            heading={PageData.content.calcTools.title}
            tiles={PageData.content.calcTools.tiles}
          />
          <ImportantInformation
            data={require('@/data/json/disclaimer/personal-loan1.json')}
            sectionOneColummClasses="col-10 offset-1 col-lg-5 offset-lg-1"
            sectionTwoColummClasses="col-10 offset-1 col-lg-5 offset-lg-0"
            additionalPaymentsDisclaimerOne
            {...state?.importantInfoData?.[0]}
          />
        </Box>
      </main>
    </Layout>
  );
}

export default CarLoanCalculatorPage;
