import React, { useContext, useEffect } from 'react';
import { AnalyticsContext, trackEvent } from 'latitude-analytics';
import useScript from '@/hooks/use-script';
import config from './_widget-config';
import styles from './_styled';

const VisaOffers = () => {
  const [analytics] = useContext(AnalyticsContext);
  const [loaded] = useScript('https://offerswidget.visa.com/vos/scripts/VisaSyndicationWidget.js');

  useEffect(() => {
    if (loaded && window) {
      window.visaClientwidgetConfig = config;
      if (window.syndication) {
        window.syndication.init();

        document.addEventListener('click', e => {
          let current = e.target;
          let tracked = false;
          let inLink = false;

          // there are several links within .promo-item which open details page
          // traverse up to root to determine if promo item was clicked,
          // get title attribute from first link and send track event
          while (current && !tracked) {
            // check click event was inside an anchor
            if (current.tagName === 'A') {
              inLink = true;
            } else if (current.className == 'promo-item') {
              // div.promo-item > div.promo-img > a
              const label = current.children[0].children[0].getAttribute('title');
              if (inLink) {
                trackEvent(analytics, {
                  category: 'visa-offer',
                  action: 'interaction',
                  label,
                  location: 'Visa Offers'
                });
              }
              tracked = true;
            }
            current = current.parentElement;
          }
        });
      }
    }
  });

  return <div css={styles} id="visaoffers" visa-responsive="true" />;
};

export default VisaOffers;
