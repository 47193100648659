import useRowState from './useRowState';
import useCategoryState from './useCategoryState';

const useIncomeState = () => {
  const salary = useRowState('Net Salary', 'net-salary');
  const bonus = useRowState('Net Bonuses', 'net-bonuses');
  const investment = useRowState('Investment Income', 'investment-income');
  const allowances = useRowState('Allowances', 'allowances');
  const otherIncome = useRowState('Other Income', 'other-income');

  const incomeStateFields = [
    salary,
    bonus,
    investment,
    allowances,
    otherIncome
  ];

  return useCategoryState('income-accordion', 'Income', incomeStateFields);
};

export default useIncomeState;
