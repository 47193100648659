import React from 'react';
import classnames from 'classnames';
import { Link } from '@latitude/link';
import CardTitle from '../CardTitle/CardTitle';
import './_card-links.scss';

const component = 'card-links';

const CardLinks = props => {
  const {
    className,
    hasImage,
    imageHref,
    imageTrackId,
    title,
    listLinks
  } = props;
  return (
    <div className={classnames(`${component}`, className)}>
      {hasImage && (
        <Link
          className={classnames(`${component}__image`)}
          href={imageHref}
          trackId={imageTrackId}
        >
          <img alt="" {...props.imageProps} />
        </Link>
      )}
      <CardTitle title={title} />
      <div className={`${component}__list-container`}>
        <ul className={`${component}__list`}>
          {listLinks.map((item, index) => {
            const linkProps = {
              className: `${component}-link-item`,
              href: item.href,
              trackId: item.trackId
            };

            if (item.onClick) {
              linkProps.onClick = item.onClick;
            }

            return (
              <li
                className={`${component}__list-item ${component}__list-item--no-style mb-3`}
                key={index}
              >
                <Link {...linkProps}>{item.title}</Link>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default CardLinks;

// TODO: Enable Title component to cater to font-size: 1.5rem for CardLinks (anything that is not just title)
