import React, { useContext } from 'react';
import Helmet from 'react-helmet';

import StickyNavigationBranded from '../../components/StickyNavigation/StickyNavigationBranded';
import Layout from '../../components/layout';

import CarouselHeader from '@/components/Carousel/carouselHeader';
import WhyInterestFreeSection from './_whyInterestFree';
import HowPlansWorkSection from './_howPlansWork';
import GetStartedSection from './_getStarted';
import InterestFreeCardsSection from './_interestFreeCards';
import InterestFreeCardsOnlyComparisonSection from './_interestFreeCardsOnlyComparison';
import HowToApplySection from './_howToApply';
import AreYouAMerchantSection from './_areYouAMerchant';
import HereToHelpSection from './_hereToHelp';
import ImportantInformationSection from './_importantInformation';
import ShopByRetailerSection from './_shopByRetailer';
import ShopByCategorySection from './_shopByCategory';
import InterestFreeOffersSection from './_interestFreeOffers';
import { PageContext } from '@/context/PageContext';
import '@/components/PageLayout/pageHeader.css';
import PAGE_HEADER_ITEMS from './data/interest-free-header.json';

const ShowBrandedContent = false;
const APPLY_NOW_URL_GEM =
  'https://cards.latitudefinancial.com/gem?merchantID=000420797&channel=Online&source=0&stream=Upstream';

  const FIND_OUT_MORE_URL =
  'https://www.amazon.com.au/b?node=24505303051&tag=amazonpayments2024_latitude_amazon-onsite-22&ref_=lat_web_banner';
const pageNavigation = [
  {
    anchor: 'how-plans-work',
    label: 'How it works'
  },
  {
    anchor: 'get-started',
    label: 'Get started'
  },
  {
    anchor: 'interest-free-cards',
    label: 'Interest Free cards'
  },
  {
    anchor: 'how-to-apply',
    label: 'How to apply'
  },
  {
    anchor: 'latest-offers',
    label: 'Latest offers'
  },
  {
    anchor: 'shop-by-category',
    label: 'Shop by category'
  },
  {
    anchor: 'shop-by-retailer',
    label: 'Find a store'
  },
  {
    anchor: 'become-a-merchant',
    label: 'Become a partner'
  },
  {
    anchor: 'here-to-help',
    label: 'Help'
  }
];
const pageHeaderItem = {
  title: 'Buy today and enjoy more time to pay.',
  text:
    'With an Interest Free payment plan available on your participating Latitude credit card.',
  termsconds: 'T&Cs, fees and charges apply.',
  headerImage: '/images/header/amazon.webp',
  urlFindOutMore: FIND_OUT_MORE_URL,
  urlApplyNow: APPLY_NOW_URL_GEM
};

function AmazonPage(props) {
  /** Contentful Data */
  const contentfulPageData = useContext(PageContext);
  const state = contentfulPageData?.updatedBody || {};
  /** - END - */

  return (
    <Layout location={props.location}>
      <Helmet>
        <link
          rel="canonical"
          href="https://www.latitudefinancial.com.au/interest-free/amazon/"
        />
        <title>Latitude Interest Free</title>
        <meta name="description" content="Interest Free." />
      </Helmet>

      <main className="navigation-spacer" css="background-color: #f8f8f8;">
        {state?.heroBannerData?.[0] ? (
          <HeroBanner {...state?.heroBannerData[0]} />
        ) : (
          <CarouselHeader
            HEADER_ITEMS={
              ShowBrandedContent ? PAGE_HEADER_ITEMS : pageHeaderItem
            }
          />
        )}

        {ShowBrandedContent && (
          <div
            className="d-none d-lg-block"
            css="position: relative; z-index: 11;"
          >
            <StickyNavigationBranded
              items={pageNavigation}
              offsetElem="[data-sticky-navigaßtion-offset]"
              css={{ backgroundColor: '#ffffff' }}
              {...state?.inPageNavData?.[0]}
            />
          </div>
        )}
        {!ShowBrandedContent && (
          <InterestFreeCardsOnlyComparisonSection id="interest-free-cards" />
        )}
        <WhyInterestFreeSection
          id="why-interest-free"
          applyNowUrl={APPLY_NOW_URL_GEM}
        />
        <HowPlansWorkSection id="how-plans-work" />
        <GetStartedSection id="get-started" applyNowUrl={APPLY_NOW_URL_GEM} />
        {ShowBrandedContent && (
          <InterestFreeCardsSection id="interest-free-cards" />
        )}
        <HowToApplySection id="how-to-apply" applyNowUrl={APPLY_NOW_URL_GEM} />
        {ShowBrandedContent && (
          <div>
            <InterestFreeOffersSection id="latest-offers" />
            <ShopByCategorySection id="shop-by-category" />
            <ShopByRetailerSection id="shop-by-retailer" />
            <AreYouAMerchantSection id="become-a-merchant" />
          </div>
        )}
        <HereToHelpSection id="here-to-help" />
        <ImportantInformationSection />
      </main>
    </Layout>
  );
}

export default AmazonPage;
