import React, { Component } from 'react';
import $ from 'jquery';
import { geURLParameterByName } from '../../utils';
import './_style.scss';

class VisaOffers extends Component {
  componentDidMount() {
    if (typeof document !== 'undefined') {
      $(document).ready(function() {
        if ($('#visaoffers').length) {
          var url =
            'https://offerswidget.visa.com/vos/scripts/VisaSyndicationWidget.js';
          $.getScript(url)
            .done(function() {
              window.visaClientwidgetConfig = {
                apiKey: 'OWS_latitude_AU_FD79FD87-0B09-42EA-9FC2-E6C18B6CA722',
                template: 'A',
                enabledFilters: ['country', 'category', 'segmentation'],
                cards: [],
                categories: [
                  'CS_latitude_AU_dine',
                  'CS_latitude_AU_experience',
                  'CS_latitude_AU_shop',
                  'CS_latitude_AU_travel',
                  'CS_latitude_AU_other'
                ],
                includeCountryList: ['KH','CN','FR','DE','HK','IN','ID','IT','JP','MO','MY','NZ','PH','SG','KR','TW','TH','UK','US','VN'],
                viewMode: {
                  display: 'list',
                  listFilterParam: '',
                  focusOntop: true
                },
                language: 'en',
                pageSize: 9,
                sortOptions: {
                  order: 'desc',
                  params: 'merchantCountry,featured,lastModifiedDatetime'
                },
                offersSegment: {
                  type1: {
                    code: 'local',
                    title: 'Local Offers',
                    listFilterParam: '&cardType=classic&programName=AU&sortMerchantCountry=AU',
                    sortOptions: {
                      order: 'desc',
                      params: 'merchantCountry,featured,lastmodifieddatetime'
                    },
                    hideCountry: true
                  },
                  type2: {
                    code: 'overseas',
                    title: 'Overseas Offers',
                    listFilterParam: '&cardType=classic&redemptionCountry=KH,CN,FR,DE,HK,IN,ID,IT,JP,MO,MY,NZ,PH,SG,KR,TW,TH,UK,US,VN',
                    sortOptions: {
                      order: 'desc',
                      params: 'programName,merchantCountry,featured,lastmodifieddatetime'
                    }
                  },
                  type3: {
                    code: 'online',
                    title: 'Online Offers',
                    listFilterParam: '&sortProgramName=AU&cardType=classic&redemptionChannel=online',
                    sortOptions: {
                      order: 'desc',
                      params: 'programName,featured,lastmodifieddatetime'
                    },
                    hideCountry: true
                  }
                },
                title: 'Be rewarded for using your Visa card!',
                description: 'Enjoy exclusive deals, discounts and offers by simply using your Visa card at Merchants worldwide.'
                // header: 'Visa Offers'
              };
              window.syndication.init();
              var search = geURLParameterByName('search');
              if (search) {
                setTimeout(function() {
                  $('[name="vosw-visa-search"]').val(search);
                  window.syndication.searchFilter();
                  // var e = $.Event( "keypress", { which: 13 } );
                  // $('[name="vosw-visa-search"]').trigger(e);
                  // window.syndication.executeDetailAction(id);
                  // window.history.pushState('', '', '/credit-cards/visa-offers.html');
                }, 2000);
              }
            })
            .fail(function() {
            });
        }
      });
    }
  }

  render() {
    return (
      <div className="legacy">
        <link
          type="text/css"
          rel="stylesheet"
          href="https://offerswidget.visa.com/vos/styles/syndication-style.css"
        />
        <link
          type="text/css"
          rel="stylesheet"
          href="https://offerswidget.visa.com/vos/styles/syndication/latitude-syndication-style.css"
        />
        <div id="visaoffers" visa-responsive="true"/>
      </div>
    );
  }
}

export default VisaOffers;
