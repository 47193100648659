import React, { useEffect } from 'react';


const RedirectComponent = () => {
  useEffect(() => {
    window.location.href = 'https://latitudefs.zendesk.com/hc/en-au/articles/24989484965265-Credit-Card-Pricing-Changes-Account-Keeping-Fee-FAQs';
  }, []);
  return null;
};

export default RedirectComponent;


