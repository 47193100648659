import useRowState from './useRowState';
import useCategoryState from './useCategoryState';

const useLeisureState = () => {
  const holidays = useRowState('Holidays', 'holidays');
  const weekends = useRowState('Weekends', 'weekends');
  const restuarants = useRowState('Restaurants & Takeaway', 'restuarants');
  const sports = useRowState('Sports, Hobbies and Memberships', 'sports');
  const newspapers = useRowState(
    'Newspapers, Magazines and Books',
    'newspapers'
  );
  const gifts = useRowState('Gifts (birthdays)', 'gifts');
  const hair = useRowState('Salon/Barber', 'hair');
  const school = useRowState('School Supplies', 'school');
  const otherLeisure = useRowState('Other', 'other-leisure');

  const leisureStateFields = [
    holidays,
    weekends,
    restuarants,
    sports,
    newspapers,
    gifts,
    hair,
    school,
    otherLeisure
  ];

  return useCategoryState(
    'leisure-accordion',
    'Leisure & Entertainment',
    leisureStateFields
  );
};

export default useLeisureState;
