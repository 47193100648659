'use strict';

import React, { Component } from 'react';
import Slider from 'react-slick';

import { BREAKPOINT } from '@latitude/core/utils/constants';
import { Box } from '@/components/Box/Box';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './_image-slider.css';

import imgApp01 from './images/mobile-app-01.webp';
import imgApp02 from './images/mobile-app-02.webp';
import imgApp03 from './images/mobile-app-03.webp';
import imgApp04 from './images/mobile-app-04.webp';
import imgApp05 from './images/mobile-app-05.webp';
import imgApp06 from './images/mobile-app-06.webp';
import imgApp07 from './images/mobile-app-07.webp';
import imgApp08 from './images/mobile-app-08.webp';
import imgAppman from './images/image-eight.webp';

export class ImageSliderSection extends Component {
  render() {
    var settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 1,
      initialSlide: 0,
      arrows: true,
      responsive: [
        {
          breakpoint: parseInt(BREAKPOINT.LG),
          settings: {
            slidesToShow: 4,
            slidesToScroll: 1,
            infinite: true,
            dots: true,
            arrows: true
          }
        },
        {
          breakpoint: parseInt(BREAKPOINT.MD),
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            initialSlide: 2,
            arrows: false
          }
        },
        {
          breakpoint: parseInt(BREAKPOINT.SM),
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            arrows: false
          }
        }
      ]
    };

    return (
      <Box
        css={`
          display: block;
          height: 100%;
          width: 70%;
          content: '';
          position: relative;
          margin: 0 auto;
          border: 20px solid rgb(177, 219, 255);
          background: #f8f8f8;
          margin-bottom: 35px;
          padding-bottom: 30px;
        `}
      >
        <div className="slider-section">
          <Slider {...settings}>
            <div>
              <h3></h3>
              <img src={imgApp01} alt="Transactions" />
            </div>
            <div>
              <h3></h3>
              <img src={imgApp06} alt="Account balances" />
            </div>
            <div
            >
              <h3></h3>
              <img src={imgApp07} alt="Statements" />
            </div>

            <div>
              <h3></h3>
              <img src={imgApp08} alt="See how you're tracking" />
            </div>
          </Slider>
        </div>
      </Box>
    );
  }
}

export default ImageSliderSection;
