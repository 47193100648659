import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Accordion } from '@latitude/accordion';
import BudgetCategory from './BudgetCategory';
import { Box } from '../Box/Box';
import Table from '../Table/Table';
import {
  COLOR,
  MARGIN,
  BREAKPOINT,
  PADDING,
  ALIGN,
  CARD_COLOR
} from '../../utils/constants';
import {
  useIncomeState,
  useLivingExpensesState,
  useInsuranceSuperState,
  useLeisureState,
  useOnceOffState,
  useLoansState,
  useTransportState
} from './categoryStateHooks';
import { Heading4 } from '../Heading/Heading';
import { getAllSummaryDataDesktop, getAllSummaryDataMobile } from './utilities';
import { Text } from '../Text';

const DisplayOnDesktop = styled(Box)`
  background-color: ${CARD_COLOR.BLUE_LIGHTER};
  td {
    color: ${COLOR.GREY75};
  }
  padding: 16px 24px 32px;

  .table__cell {
    padding-left: 8px;
    padding-right: 8px;
  }
  .table__cell:first-child {
    font-weight: 500;
  }
  .table__row--highlighted td {
    font-weight: 500;
    color: ${COLOR.BLUE};
  }
  @media (max-width: ${BREAKPOINT.MD}) {
    display: none;
  }
`;

const DisplayOnMobile = styled(Box)`
  background-color: ${CARD_COLOR.BLUE_LIGHTER};
  margin-top: 24px;
  padding: 0 16px;
  @media (min-width: ${BREAKPOINT.MD}) {
    display: none;
  }
  td.text--invert {
    color: ${COLOR.GREY75};
  }
`;

const BudgetPlannerTool = ({ id }) => {
  const [incomeFields, incomeTotal, updateIncomeTotal] = useIncomeState();
  const [
    livingExpensesFields,
    livingExpensesTotal,
    updateLivingExpensesTotal
  ] = useLivingExpensesState();
  const [
    insuranceFields,
    insuranceTotal,
    updateInsuranceTotal
  ] = useInsuranceSuperState();
  const [leisureFields, leisureTotal, updateLeisureTotal] = useLeisureState();
  const [onceOffFields, onceOffTotal, updateOnceOffTotal] = useOnceOffState();
  const [loansFields, loansTotal, updateLoansTotal] = useLoansState();
  const [
    transportFields,
    transportTotal,
    updateTransportTotal
  ] = useTransportState();

  const accordionData = [
    incomeFields,
    livingExpensesFields,
    insuranceFields,
    loansFields,
    transportFields,
    leisureFields,
    onceOffFields
  ];

  useEffect(() => {
    updateIncomeTotal();
    updateLivingExpensesTotal();
    updateInsuranceTotal();
    updateLeisureTotal();
    updateOnceOffTotal();
    updateLoansTotal();
    updateTransportTotal();
  });

  const allCategoryTotals = {
    incomeTotal,
    livingExpensesTotal,
    insuranceTotal,
    loansTotal,
    transportTotal,
    leisureTotal,
    onceOffTotal
  };
  const displaySummaryTable = Object.values(allCategoryTotals).some(
    value => value !== 0
  );

  let surplusTotal =
    incomeTotal -
    Object.keys(allCategoryTotals).reduce((previous, key, currentIndex) => {
      return currentIndex === 0 ? previous : previous + allCategoryTotals[key];
    }, 0);

  const tableDataForDesktop = getAllSummaryDataDesktop({
    ...allCategoryTotals,
    surplusTotal
  });

  const tableDataForMobile = getAllSummaryDataMobile({
    ...allCategoryTotals,
    surplusTotal
  });

  return (
    <StyledBudgetPlaner>
      <Box id={id}>
        <Box isResponsive isBodyCopy margin={`0 ${MARGIN.MAUTO}`}>
          <Accordion
            borderedChildren
            items={accordionData.map(data => ({
              id: data.id,
              title: data.title,
              content: <BudgetCategory stateFields={data.stateFields} />
            }))}
          />
        </Box>
        <Box
          isResponsive
          isBodyCopy
          css={`
            margin: 0 ${MARGIN.MAUTO} ${MARGIN.M24};
            @media (min-width: ${BREAKPOINT.MD}) {
              margin-bottom: 0;
            }
          `}
        >
          {displaySummaryTable ? (
            <>
              <Box paddingTop={MARGIN.M24}>
                <Heading4
                  color={COLOR.GREY75}
                  id="repayment-calculator"
                  marginBottom={MARGIN.M16}
                  align={ALIGN.CENTER}
                >
                  Your budget summary
                </Heading4>
              </Box>
              <Box
                css={`
                  padding: 0 ${MARGIN.M16} 0 ${MARGIN.M16};
                  @media (min-width: ${BREAKPOINT.MD}) {
                    padding: 0 ${MARGIN.M24} ${MARGIN.M32} ${MARGIN.M24};
                  }
                `}
              >
                <Text align={ALIGN.CENTER} color={COLOR.GREY75}>
                  Great work, you’ve made a budget! Use this to plan for those
                  things you really want out of life and get ahead.
                </Text>
              </Box>

              <DisplayOnDesktop paddingBottom={PADDING.P24}>
                <Table
                  className="table--fixed"
                  highlightRows={[7]}
                  brightRows={[7]}
                  columnWidths={['26%', '18%', '18%', '18%', '18%']}
                  header={['', 'Weekly', 'Fortnightly', 'Monthly', 'Yearly']}
                  data={[tableDataForDesktop]}
                />
              </DisplayOnDesktop>
              <DisplayOnMobile>
                <StyleSummaryMobileTable>
                  <Table
                    className="table--fixed"
                    noBorderTop
                    noBorderBottom
                    leftAlignColumns={[0, 1]}
                    columnWidths={['100%']}
                    data={tableDataForMobile}
                  />
                </StyleSummaryMobileTable>
              </DisplayOnMobile>
            </>
          ) : (
            <Box isResponsive isBodyCopy>
              <Box
                css={`
                  padding: ${PADDING.P16} 0;
                  @media (min-width: ${BREAKPOINT.LG}) {
                    padding-bottom: 0;
                  }
                `}
              >
                <Text align={ALIGN.CENTER}>
                  Begin entering different expense types above to automatically
                  populate your personal budget summary
                </Text>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </StyledBudgetPlaner>
  );
};

const StyleSummaryMobileTable = styled.div`
  @media (max-width: ${BREAKPOINT.MD}) {
    tr.table__row--border-bottom + tr {
      td:first-child {
        padding-top: 24px;
      }
    }
    .table__row--no-border-top {
      td {
        border-top: 1px solid #d0e9ff;
      }
      td > div {
        margin-top: 0;
      }
      &:first-child {
        td {
          border-top: 0;
        }
      }
    }
    .table__row {
      border-top: 0;
      td {
        padding: 8px 0;
      }
    }
    .table__row + .table__row:not(.table__row--no-border-top) {
      td {
        padding-top: 0;
      }
    }
  }
`;

const StyledBudgetPlaner = styled.div`
  .accordion button.bg-white {
    background-color: #f1f2f3 !important;
  }
  .accordion .accordion__item-body {
    background-color: #f8f8f8 !important;
    color: ${COLOR.GREY75};
    @media (min-width: ${BREAKPOINT.MD}) {
      position: relative;
      &:after {
        width: calc(100% - 32px);
        height: 1px;
        background: ${COLOR.GREY75};
        position: absolute;
        top: 36px;
        left: 16px;
        content: '';
        display: block;
      }
    }
  }
  th.table__header {
    color: ${COLOR.GREY75};
  }
  .currency-input__prepend {
    color: ${COLOR.GREY75};
    font-weight: 300;
  }
  .currency-input__field,
  select {
    border: 1px solid rgba(0, 0, 0, 0);
    font-weight: 300;
    color: ${COLOR.GREY75};
    &:focus {
      border: 1px solid ${COLOR.GREY75};
    }
  }
  select {
    padding-left: 10px;
  }
  .font-weight-bold p {
    font-weight: 500;
  }
  .table__cell,
  .table__cell:first-child {
    vertical-align: middle;
  }
  .table__row {
    font-size: 15px;
  }
  .accordion .accordion__button-fixed {
    position: relative;
    top: auto !important;
  }
`;

export default BudgetPlannerTool;
