import React from 'react';
import { SecondaryHero } from '@latitude/hero';
import Layout from '../components/layout';
import { Box } from '../components/Box/Box';
import Metadata from '../components/Metadata/Metadata';
import { Heading6 } from '../components/Heading/Heading';
import { Vertical } from '../components/Spacing/Spacing';
import { Text } from '../components/Text';
import { COLOR, MARGIN } from '../utils/constants';

const TermsAndConditions = props => {
  return (
    <Layout location={props.location}>
      <main className="navigation-spacer">
        <Metadata
          title="LatitudePay 2023 Events Promotion Terms and Conditions"
          description="By using the Latitude site or downloading its materials, you agree to abide by the terms &amp; conditions set forth in this notice. Read the full T&amp;C's here."
          canonical="/share-the-love/"
        />

        <SecondaryHero
          heading={<>Latitude ‘2023 Events’ Promotion – Terms and Conditions</>}
        />

        <TermsSection />
      </main>
    </Layout>
  );
};

const TermsSection = () => (
  <Box.Section backgroundColor={COLOR.WHITE} isBodyCopy>
    <Heading6 marginTop={MARGIN.M24} marginBottom={MARGIN.M8}>
      Version: July 2023
    </Heading6>
    <Text>
      <br />
      <strong>
        &quot;Promoter&quot;, &quot;us&quot;, &quot;our&quot;, &quot;we&quot;
      </strong>{' '}
      means Latitude Finance Australia (Latitude), ABN 42008583588
      <br />
      <br />
      <strong>'Promotion Period'</strong> means the period starting at 8:00am on
      19/07/2023 and ending at the end of the last afternoon break on 20/07/2023
      <br />
      <br />
      <strong>Event</strong> - Online Retailer Australia held at ICC Sydney,
      NSW 
      <br />
      <br />
      <strong>Draw Date</strong> - means 20/07/2023
    </Text>

    <Heading6 marginTop={MARGIN.M24} marginBottom={MARGIN.M8}>
      Acceptance of conditions
    </Heading6>
    <Vertical spacing={MARGIN.TEXT}>
      <Text>
        <ol>
          <li>
            Any instructions on how to enter and other details contained within
            our advertisements for this Promotion form part of these Terms and
            Conditions. By entering the Promotion, entrants agree to be bound by
            these Terms and Conditions.
          </li>
        </ol>
      </Text>
    </Vertical>

    <Heading6 marginTop={MARGIN.M24} marginBottom={MARGIN.M8}>
      Eligibility
    </Heading6>
    <Vertical spacing={MARGIN.TEXT}>
      <Text>
        <ol>
          <li>
            Entry to the Promotion is only open to registered
            attendees/delegates of the Event who are aged 18 years or over and
            are residents of Australia. Entrants must be an individual, not a
            company or organisation.
          </li>
          <li>
            Our employees and those of our related bodies corporate, and their
            immediate families. are not eligible to enter. “Immediate family”
            means a spouse, de facto partner, parent, child or sibling, whether
            or not they live in the same household as the employee.
          </li>
        </ol>
      </Text>
    </Vertical>

    <Heading6 marginTop={MARGIN.M24} marginBottom={MARGIN.M8}>
      Method of Entry
    </Heading6>
    <Vertical spacing={MARGIN.TEXT}>
      <Text>
        <ol>
          <li>
            Entry is free. Entrants are responsible for all costs associated
            with entering the competition, which may include costs associated
            with accessing the internet.
          </li>
          <li>
            To enter the Promotion, entrants must 'follow' the Latitude Partner
            Hub page on Linkedin and 'like' the latest post which will refer to
            the applicable event during the Promotional Period.
          </li>
        </ol>
      </Text>
    </Vertical>

    <Heading6 marginTop={MARGIN.M24} marginBottom={MARGIN.M8}>
      Disqualification
    </Heading6>
    <Vertical spacing={MARGIN.TEXT}>
      <Text>
        <ol>
          <li>
            At our discretion we may disqualify and remove any entries that
            display or publicise any political slogans, homophobic language,
            images of a lewd or explicitly sexual nature, images containing
            logos of competitors and overt brand sponsorship or anything else
            deemed to be ambush marketing or an abuse of its products or
            services, content which is defamatory, obscene, illegal, vulgar,
            offensive or otherwise unsuitable or infringes others’ rights
            (including intellectual property rights) or reflects negatively upon
            us or our related bodies corporate.
          </li>
          <li>
            At our discretion we may disqualify all entries from, and prohibit
            further participation in the Promotion by, any person who tampers or
            attempts to tamper with, or benefits from any tampering with the
            entry process or with the operation of the Promotion or acts in
            violation of these Terms and Conditions, acts in a disruptive manner
            or acts with the intent to annoy, abuse, threaten or harass any
            other person.
          </li>
        </ol>
      </Text>
    </Vertical>

    <Heading6 marginTop={MARGIN.M24} marginBottom={MARGIN.M8}>
      Prize
    </Heading6>
    <Vertical spacing={MARGIN.TEXT}>
      <Text>
        <ol>
          <li>
            There will be one (1) prize valued at $1,000 consisting of a gift
            card or cards that can be used to make multiple purchases everywhere
            Visa is accepted.
          </li>
          <li>
            Prizes are not transferable, exchangeable nor redeemable for cash.
          </li>
          <li>
            The prize winner agrees to comply with the terms and conditions
            applicable to the issue and use of the gift cards and is responsible
            for all costs associated with using the prize.
          </li>
        </ol>
      </Text>
    </Vertical>

    <Heading6 marginTop={MARGIN.M24} marginBottom={MARGIN.M8}>
      Draw
    </Heading6>
    <Vertical spacing={MARGIN.TEXT}>
      <Text>
        <ol>
          <li>
            The prize winner will be selected by a random draw from all eligible
            entries on the Draw Date at our registered address.
          </li>
          <li>
            If a redraw is required, it will be conducted at the same location
            and by the same method as the original draw. Redraws will continue
            in the same manner until the prize has been successfully claimed by
            a prize winner.
          </li>
        </ol>
      </Text>
    </Vertical>

    <Heading6 marginTop={MARGIN.M24} marginBottom={MARGIN.M8}>
      Notification of winners
    </Heading6>
    <Vertical spacing={MARGIN.TEXT}>
      <Text>
        <ol>
          <li>
            We reserve the right to verify the validity of the entries before
            announcing the winning entrant.
          </li>
          <li>
            Within seven (7) days after the Draw Date, the prize winner will be
            notified by a private message to the LinkedIn account used to enter
            the Promotion and their details will be published in a post on our
            or our related bodies corporate’s LinkedIn accounts.
          </li>
          <li>
            If a prize winner does not respond to claim the prize within one (1)
            month of the Draw Date, the prize winner forfeits the prize and a
            redraw will occur.
          </li>
        </ol>
      </Text>
    </Vertical>

    <Heading6 marginTop={MARGIN.M24} marginBottom={MARGIN.M8}>
      Awarding prizes
    </Heading6>
    <Vertical spacing={MARGIN.TEXT}>
      <Text>
        <ol>
          <li>
            A prize winner found to be in breach of these Terms and Conditions
            winner forfeits the prize and a redraw will occur.
          </li>
          <li>
            At our discretion we may require a prize winner to provide proof of
            identity, age, Australian residency, and any other identification
            considered necessary for verification. A prize winner that does not
            or cannot provide proof as requested forfeits the prize and a redraw
            will occur.
          </li>
        </ol>
      </Text>
    </Vertical>

    <Heading6 marginTop={MARGIN.M24} marginBottom={MARGIN.M8}>
      Publicity
    </Heading6>
    <Vertical spacing={MARGIN.TEXT}>
      <Text>
        <ol>
          <li>
            A prize winner consents to us using and publishing their name on
            Latitude LinkedIn associated pages, in promotional material,
            including on ours or our related bodies corporate’s websites,
            electronic publications or social media accounts.
          </li>
        </ol>
      </Text>
    </Vertical>

    <Heading6 marginTop={MARGIN.M24} marginBottom={MARGIN.M8}>
      Privacy
    </Heading6>
    <Vertical spacing={MARGIN.TEXT}>
      <Text>
        <ol>
          <li>
            We collect, use and disclose personal information in order to
            conduct this Promotion. Entry is conditional on providing this
            information. Our Privacy Policy explains how we manger your personal
            information and a copy is available online at{' '}
            <a
              href="http://www.latitudefinancial.com.au/privacy"
              target="_blank"
              rel="noopener nofollow"
            >
              latitudefinancial.com.au/privacy
            </a>
            .
          </li>
        </ol>
      </Text>
    </Vertical>

    <Heading6 marginTop={MARGIN.M24} marginBottom={MARGIN.M8}>
      Exclusion of liability
    </Heading6>
    <Vertical spacing={MARGIN.TEXT}>
      <Text>
        <ol>
          <li>
            We accept no responsibility for any late, lost, incomplete,
            misdirected, incorrectly submitted, delayed, incorrectly formatted
            or illegible entries or correspondence whether due to error,
            omission, alteration, tampering, theft, destruction or otherwise.
          </li>
          <li>
            To the extent permitted by law, we shall not be liable for any loss
            or damage whatsoever (including but not limited to direct or
            consequential loss) or personal injury suffered or sustained in
            connection with the prize(s).
          </li>
          <li>
            We accept no responsibility for any tax liabilities that may arise
            from winning a prize.
          </li>
        </ol>
      </Text>
    </Vertical>

    <Heading6 marginTop={MARGIN.M24} marginBottom={MARGIN.M8}>
      Problems affecting the Promotion
    </Heading6>
    <Vertical spacing={MARGIN.TEXT}>
      <Text>
        <ol>
          <li>
            If for any reason any aspect of this Promotion is not capable of
            running as planned, including by reason of infection by computer
            virus, mobile network failure, bugs, tampering, unauthorised
            intervention, fraud, technical failures or any cause beyond our
            reasonable control which corrupts or affects the administration,
            security, fairness, integrity or proper conduct of this Promotion,
            we may at our sole discretion cancel, terminate, modify or suspend
            the Promotion, or invalidate any affected entries.
          </li>
        </ol>
      </Text>
    </Vertical>

    <Heading6 marginTop={MARGIN.M24} marginBottom={MARGIN.M8}>
      Disputes
    </Heading6>
    <Vertical spacing={MARGIN.TEXT}>
      <Text>
        <ol>
          <li>
            Our decision in relation to any aspect of the Promotion is final and
            binding on each person who enters. No correspondence will be entered
            into.
          </li>
        </ol>
      </Text>
    </Vertical>

    <Heading6 marginTop={MARGIN.M24} marginBottom={MARGIN.M8}>
      General
    </Heading6>
    <Vertical spacing={MARGIN.TEXT}>
      <Text>
        <ol>
          <li>
            This Agreement is to be interpreted in accordance with the laws of
            the State of Victoria, Australia and all parties submit to the
            jurisdiction of the Courts of Victoria.
          </li>
          <li>
            If any term or condition of these Terms and Conditions or the
            application thereof to any person or any circumstance shall be or
            become illegal, invalid or unenforceable, the same shall be read
            down, if such reading down is possible, and if found to be
            impossible, shall be severed and the remaining terms and conditions
            shall not be affected.
          </li>
        </ol>
      </Text>
    </Vertical>
  </Box.Section>
);

export default TermsAndConditions;
