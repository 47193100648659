/* Leadership Page Layout component */
/* eslint-disable import/extensions, import/no-unresolved  */
import styled from 'styled-components';
import { Lframe } from '@latitude/lframe';
import { Text } from '@latitude/text';
import { Box } from '@latitude/box';
import React from 'react';
import Helmet from 'react-helmet';
import { FeatureTiles } from '@latitude/feature-tiles';
import { BREAKPOINT, COLOR } from '@latitude/core/utils/constants';
import HeroBranded from '../HeroBranded/HeroBranded';
import Layout from '../layout';
import LeaderNavigator from './LeaderNavigator';
import imageHero from '../../pages/about-us/images/lshape-white.png';
import TeamData from '../../pages/about-us/data/_our-team';

/* component styles */
export const StyledLframe = styled(Lframe)`
  background-size: 800px;
  background-position: 60vw 0;

  @media (max-width: ${BREAKPOINT.MD}) {
    display: none;
  }
`;

export const StyledHeroBranded = styled(HeroBranded)`
  background-color: ${COLOR.GREY6};
`;

export const LeaderDetailsWrapper = styled.div`
  background-color: ${COLOR.GREY6};
`;

export const StyledText = styled(Text)`
  position: relative;
  text-align: left;
  z-index: 1;
  @media (min-width: ${BREAKPOINT.SM}) {
    width: 90%;
  }

  @media (min-width: ${BREAKPOINT.MD}) {
    width: 400px;
    padding: 20px 10px;
  }

  @media (min-width: ${BREAKPOINT.LG}) {
    width: 450px;
    padding: 0 0 48px;
  }

  @media (min-width: ${BREAKPOINT.XL}) {
    width: 500px;
    padding: 0 0 48px;
  }
`;

export const DetailsContainer = styled(Box)`
  margin: 0 auto;
  position: relative;
  padding: 0;
  display: flex;
  flex-direction: column;
  padding: 26px 16px;
  align-items: flex-start;

  @media (min-width: ${BREAKPOINT.MD}) {
    flex-direction: row;
    max-width: calc(${BREAKPOINT.MD} - 30px);
  }

  @media (min-width: ${BREAKPOINT.LG}) {
    max-width: calc(${BREAKPOINT.LG} - 30px);
    justify-content: space-between;
    padding: 26px 0px;
  }

  @media (min-width: ${BREAKPOINT.XL}) {
    max-width: calc(1140px - 30px);
    padding: 26px 0px;
    justify-content: space-between;
  }
`;

export const ImageBox = styled(Box)`
  height: 100%;
  display: flex;
  margin-top: 60px;
  align-items: center;
  justify-content: center;

  @media (max-width: ${BREAKPOINT.MD}) {
    margin-top: 0px;
    div {
      width: 370px;
    }
  }
`;

export const Anchor = styled.div`
  width: 0;
  height: 0;
  position: relative;
  top: -30px;
`;

export const LeadershipPageLayout = (props: any) => {
  const { title } = props;
  return (
    <Layout location={props.location}>
      <main className="navigation-spacer">
        <Helmet>
          <link
            rel="canonical"
            href="https://www.latitudefinancial.com.au/about-us/our-company/"
          />
          <title>{`${title} | Latitude Financial Services`} </title>
          <meta name="description" content={title} />
        </Helmet>
        <StyledLframe infinity bgImage={imageHero} />
        {props.children}

        <LeaderNavigator name={title} />

        <Anchor id="leadership-team" />
        <FeatureTiles
          bgColor={COLOR.WHITE}
          useLocalAssets
          heading="Our leadership team"
          tiles={TeamData}
        />
      </main>
    </Layout>
  );
};
