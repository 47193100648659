/* eslint-disable global-require */
import React from 'react';
import { FeaturesSlider } from '@latitude/features-slider';

const sliderResponsiveSettings = [
  {
    breakpoint: 10000,
    settings: {
      slidesToShow: 4,
      slidesToScroll: 1
    }
  },
  {
    breakpoint: 1024,
    settings: {
      slidesToShow: 4,
      slidesToScroll: 1
    }
  },
  {
    breakpoint: 992,
    settings: {
      slidesToShow: 3,
      slidesToScroll: 1
    }
  },
  {
    breakpoint: 768,
    settings: {
      slidesToShow: 2,
      slidesToScroll: 1
    }
  },
  {
    breakpoint: 450,
    settings: {
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false
    }
  }
];

function QuickStartFlexiGuide({ anchor, featureData }) {
  return (
    <FeaturesSlider
      css={`
        && svg.lfs-svg-inline {
          height: 70% !important;
        }
      `}
      className="lfs-flexiroam-quickstart"
      key={featureData.heading}
      heading={featureData.heading}
      subheading={featureData.description}
      id={anchor}
      data={featureData.featureCards}
      responsiveSettings={sliderResponsiveSettings}
    />
  );
}

export default QuickStartFlexiGuide;
