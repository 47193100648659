import React, { useEffect } from 'react';

const TARGET =
  'https://latitudefs.zendesk.com/hc/en-au/articles/26651180360977-Low-Rate-Mastercard-Credit-Card-Changes-FAQs';

function ZendeskLowrateMasterCardRedirectPage({ location }) {
  useEffect(() => {
    if (window?.location) {
      window.location.replace(TARGET);
    }
  }, []);

  return <></>;
}

export default ZendeskLowrateMasterCardRedirectPage;
