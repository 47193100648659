/* eslint-disable react/no-danger */
import React, { useEffect, useContext } from 'react';
import Helmet from 'react-helmet';
import { FeaturesSlider } from '@latitude/features-slider';
import { Link } from '@latitude/link';
import { COLOR } from '@latitude/core/utils/constants';
import heroImage from './images/hero-cyber-month.webp';
import Section from '@latitude/section';
import MobileAppInstallPrompts from '@latitude/mobile-app-install-prompt/MobileAppInstallPrompt';

import Layout from '@/components/layout';
import PageHeaderSection from '@/components/PageLayout/PageHeader';
import HeroBanner from '@/components/lab-components/HeroBanner';
import { useSetState } from '@/utils/hooks';
import { PageContext } from '@/context/PageContext';
import { CONTENTFUL_COMPONENTS } from '@/utils/constants';
import PageData from './cyber-month.json';

function CyberAwarenessPage(props) {
  /** Contentful Data */
  const contentfulPageData = useContext(PageContext);

  const [state, setState] = useSetState({
    heroBannerData: [],
    promoBannerData: [],
    featureSliderData: [
      {
        heading: 'Red flags of scams',
        featureCards: PageData.content.scamRedFlags
      }
    ],
    featureSliderData2: [
      {
        heading: 'Top Tips to protect yourself',
        featureCards: PageData.content.topTips1
      }
    ],
    featureSliderData3: [
      {
        featureCards: PageData.content.topTips2
      }
    ]
  });

  useEffect(() => {
    let [
      featureSliders,
      heroBanners,
      promoBanners,
      featureSliders2,
      featureSliders3
    ] = [[], [], [], [], []];
    contentfulPageData?.body?.map(item => {
      switch (item.contentType.sys.id) {
        case CONTENTFUL_COMPONENTS.FEATURE_SLIDER:
          const updatedItem = {
            heading: marked(item.heading || ''),
            description: ReactHTMLParser(marked(item.description || '')),
            featureCards: item.featureCards?.map(card => ({
              ...card,
              text: marked(card.text || '')
            }))
          };
          featureSliders = [...featureSliders, updatedItem];

          const updatedItem2 = {
            heading: marked(item.heading || ''),
            description: ReactHTMLParser(marked(item.description || '')),
            featureCards: item.featureCards?.map(card => ({
              ...card,
              text: marked(card.text || '')
            }))
          };
          featureSliders2 = [...featureSliders2, updatedItem2];

          const updatedItem3 = {
            heading: marked(item.heading || ''),
            description: ReactHTMLParser(marked(item.description || '')),
            featureCards: item.featureCards?.map(card => ({
              ...card,
              text: marked(card.text || '')
            }))
          };
          featureSliders3 = [...featureSliders3, updatedItem3];
          break;
        case CONTENTFUL_COMPONENTS.HERO_BANNER_CAROUSEL:
          heroBanners = [...heroBanners, item];
          break;
        case CONTENTFUL_COMPONENTS.PROMO_BANNER:
          promoBanners = [...promoBanners, item];
          break;
        default:
          break;
      }
    });

    setState({
      featureSliderData:
        featureSliders.length > 0 ? featureSliders : state.featureSliderData,
      featureSliderData2:
        featureSliders2.length > 0 ? featureSliders2 : state.featureSliderData2,
      featureSliderData3:
        featureSliders3.length > 0 ? featureSliders3 : state.featureSliderData3,
      heroBannerData:
        heroBanners.length > 0 ? heroBanners : state.heroBannerData,
      promoBannerData:
        promoBanners.length > 0 ? promoBanners : state.promoBannerData
    });
  }, [contentfulPageData]);
  /** - END - */
  return (
    <Layout location={props.location}>
      <MobileAppInstallPrompts />
      <main
        className="navigation-spacer"
        css={`
          background-color: ${COLOR.GREY6};
        `}
      >
        <Helmet>
          <link
            rel="canonical"
            href="https://www.latitudefinancial.com.au/cyber-month/"
          />
          <title>{PageData.metaTitle}</title>
          <meta name="description" content={PageData.metaDesc} />
        </Helmet>
        {state?.heroBannerData?.[0] ? (
          <HeroBanner {...state?.heroBannerData[0]} />
        ) : (
          <PageHeaderSection
            pageImage={heroImage}
            title="Cyber Security Awareness Month"
            subTitle="Every October, Cyber Security Awareness Month serves as a reminder for all of us to remain vigilant and secure in our online activities. Below, you'll find red flags of scams and our top tips to help the community stay safe online."
          />
        )}

        <Section
          css={`
            background-color: #fff;
            h4 {
              color: #0046aa;
            }
          `}
        >
          {state?.featureSliderData?.[0] && (
            <FeaturesSlider
              id="red-flags"
              data={state.featureSliderData[0].featureCards}
              className="red-flags pt-4 pt-md-5"
              heading={state.featureSliderData[0].heading}
              subheading={state.featureSliderData[0].description}
            />
          )}
        </Section>
        <Section
          css={`
            background: #0555c8;
            * {
              color: #fff;
            }
          `}
        >
          {state?.featureSliderData2?.[0] && (
            <FeaturesSlider
              id="top-tips-part1"
              data={state.featureSliderData2[0].featureCards}
              className="op-tips pt-4 pt-md-5"
              heading={state.featureSliderData2[0].heading}
              subheading={state.featureSliderData2[0].description}
              css={`
                && {
                  padding-bottom: 0;
                }
              `}
            />
          )}

          {state?.featureSliderData3?.[0] && (
            <FeaturesSlider
              id="top-tips-part2"
              data={state.featureSliderData3[0].featureCards}
              className="top-tips pt-4 pt-md-5"
              heading={state.featureSliderData3[0].heading}
              subheading={state.featureSliderData3[0].description}
              css={`
                && {
                  padding-top: 0;
                }
              `}
            />
          )}
        </Section>

        <Section
          class="more-support"
          css={`
            text-align: center;
          `}
        >
          <h4
            css={`
              color: #0046aa;
              font-size: 30px !important;
            `}
          >
            More support when you need it
          </h4>
          <p>
            Visit <Link href="/scams-hub/">Latitude Scams Hub</Link> to learn
            more about the latest scams.
          </p>
          <p>
            Visit the{' '}
            <Link href="https://www.scamwatch.gov.au/" target="_blank">
              Scamwatch website
            </Link>{' '}
            for further information on scams, fraud and cyber threats.
          </p>
          <p>
            Contact{' '}
            <Link href="https://www.idcare.org/" target="_blank">
              IDCARE
            </Link>
            , an independent organisation providing free support to individuals
            impacted by fraud and scams by calling{' '}
            <Link href="tel:1800595160">1800 595 160</Link>.
          </p>
          <p>The Latitude Cyber Team</p>
        </Section>
      </main>
    </Layout>
  );
}

export default CyberAwarenessPage;
