import React from 'react';
import styled from 'styled-components';
import { Link } from '@latitude/link';
import { BUTTON_STYLE } from '@latitude/core/utils/constants';
import MarketingBanner from './MarketingBanner';
import {
  BREAKPOINT,
  FONT_FAMILY,
  FONT_SIZE,
  COLOR
} from '../../utils/constants';

const StyledPromo = styled(MarketingBanner)`
  && {
    background: linear-gradient(135deg, #006bff 70%, ${COLOR.BLUE_DARK});

    @media (max-width: ${BREAKPOINT.MD}) {
      background: linear-gradient(165deg, #006bff 70%, ${COLOR.BLUE_DARK});
    }

    .marketing-banner__inner {
      padding-bottom: 24px;
    }

    .marketing-banner__title,
    .marketing-banner__sub-title {
      font-family: ${FONT_FAMILY.TITLE};
      font-weight: 600;
      color: ${COLOR.WHITE};
    }

    .marketing-banner__title {
      font-size: 32px;
      line-height: 36px;

      @media (max-width: ${BREAKPOINT.MD}) {
        margin-top: 280px;
      }

      @media (min-width: ${BREAKPOINT.LG}) {
        font-size: 48px;
        line-height: 56px;
      }
    }

    .marketing-banner__sub-title {
      font-size: ${FONT_SIZE.NORMAL};
      line-height: 27px;
      br {
        display: none;
      }
      @media (min-width: ${BREAKPOINT.LG}) {
        br {
          display: block;
        }
      }
    }

    .marketing-banner__disclaimer {
      @media (max-width: ${BREAKPOINT.MD}) {
        padding: 32px 0;
      }
      @media (min-width: ${BREAKPOINT.MD}) {
        display: none;
      }
    }

    .marketing-banner__cta:nth-child(1) {
    }

    .marketing-banner__cta:nth-child(2) {
      display: none;

      @media (min-width: ${BREAKPOINT.MD}) {
        display: flex;
        align-items: center;
        color: #fff;
        text-align: right;
        min-width: 175px;
      }
    }

    .marketing-banner__bg-img,
    .marketing-banner__bg-img-large {
      background-size: contain;
      background-repeat: no-repeat;
      background-position: top right;
    }

    .marketing-banner__bg-img {
      background-size: auto 355px;
    }
  }
`;

export default () => (
  <StyledPromo
    wideButtons
    widthHalf
    title="Visa offers"
    subTitle={<>Ready for more? Like exclusive deals, discounts and offers.</>}
    disclaimer={<PresentedByText />}
    primaryCta={
      <Link
        href="/credit-cards/visa-offers/"
        trackId="btn-visa-offers-marketing-banner"
        button={BUTTON_STYLE.TERTIARY_INVERSE}
        trackEventData={{
          location: 'Visa offers'
        }}
      >
        View all offers
      </Link>
    }
    secondaryCta={<PresentedByText />}
    bgImg={require(`../../images/marketing-banner/visa-offers.png`).default}
    bgImgLarge={
      require(`../../images/marketing-banner/visa-offers.png`).default
    }
  />
);

const PresentedByText = () => (
  <span css="font-size: 13px; font-weight: lighter;">
    in partnership with{' '}
    <img
      src={require('../../images/logos/logo-visa.svg').default}
      style={{ width: '75px', verticalAlign: 'middle' }}
      alt="visa logo"
    />
  </span>
);
