// extracted by mini-css-extract-plugin
export var loaderAnimation = "loadingIcon-module--loader-animation--36hHn";
export var loaderAnimation_moveInASquare = "loadingIcon-module--loader-animation_moveInASquare--enfvQ";
export var loaderAnimationWhite = "loadingIcon-module--loader-animation--white--3LsbA";
export var loaderAnimationWhite_moveInASquare = "loadingIcon-module--loader-animation--white_moveInASquare--4Hcxp";
export var loaderAnimationMedium = "loadingIcon-module--loader-animation--medium--2fzXJ";
export var loaderAnimationMedium_moveInASquare = "loadingIcon-module--loader-animation--medium_moveInASquare--1RJIl";
export var loaderAnimationMediumWhite = "loadingIcon-module--loader-animation--medium--white--13CuE";
export var loaderAnimationMediumWhite_moveInASquare = "loadingIcon-module--loader-animation--medium--white_moveInASquare--1AheW";
export var loaderAnimationLarge = "loadingIcon-module--loader-animation--large--3IzrB";
export var loaderAnimationLarge_moveInASquare = "loadingIcon-module--loader-animation--large_moveInASquare--3fxgX";
export var loaderAnimationLargeWhite = "loadingIcon-module--loader-animation--large--white--3a_qS";
export var loaderAnimationLargeWhite_moveInASquare = "loadingIcon-module--loader-animation--large--white_moveInASquare--2ZXbr";