import React, { useState, useEffect, useContext } from 'react';
import { AnalyticsContext, trackEvent } from 'latitude-analytics';
import { debounce as _debounce } from 'lodash';
import styled from 'styled-components';
import { Link } from '@latitude/link';
import { Text } from '@latitude/text';
import { Tel } from '@latitude/tel';
import Select from '../Select/Select';
import RadioBoxes from '../RadioBoxes/RadioBoxes';
import CurrencyInput from '../CurrencyInput/CurrencyInput';
import { Box, Flex } from '../Box/Box';
import { Vertical } from '../Spacing/Spacing';
import { formatAsCurrency } from '../../utils';
import {
  MARGIN,
  COLOR,
  JUSTIFY_CONTENT,
  BREAKPOINT,
  LOAN_PAY_FREQUENCY,
  PL_MINIMUM_AMOUNT,
  PL_SYMPLE_MINIMUM_AMOUNT,
  PL_MAXIMUM_AMOUNT,
  PL_SP_FEE_AMOUNT,
  PL_MONTHLY_FEE,
  PL_UNSECURED_MIN_IR,
  PL_SECURED_MIN_IR,
  PL_ESTABLISHMENT_FEE,
  PL_UNSECURED_COMPARISION_MIN_IR,
  PL_SECURED_COMPARISON_MIN_IR,
  PL_TEL,
  FONT_WEIGHT,
  PL_SECURED_MAX_IR,
  PL_UNSECURED_MAX_IR,
  PL_SECURED_COMPARISON_MAX_IR,
  PL_UNSECURED_COMPARISON_MAX_IR,
  PL_SYMPLE_SECURED_MIN_IR,
  PL_SYMPLE_SECURED_MAX_IR,
  PL_SYMPLE_SECURED_COMPARISON_MIN_IR,
  PL_SYMPLE_SECURED_COMPARISON_MAX_IR,
  PL_SYMPLE_SETTLEMENT_FEE_CAP,
  PL_SYMPLE_UNSECURED_MIN_IR,
  PL_SYMPLE_UNSECURED_MAX_IR,
  PL_SYMPLE_UNSECURED_COMPARISON_MIN_IR,
  PL_SYMPLE_UNSECURED_COMPARISON_MAX_IR,
  CALCULATOR_TRACKING
} from '../../utils/constants';
import { Bold } from '../Text';
import SvgInline from '../SvgInline/SvgInline';
import { getRepaymentFigures } from '../../utils/loanCalculatorUtil';
import Modal from '../Modal/Modal';
import InterestRateWidget from './InterestRateWidget';
import {
  directTrackLink,
  normaliseForAnalytics
} from '../../utils/analyticsUtil';
import {
  customLinkNames,
  getCustomLinkEventName
} from './PersonalLoanCalculator.analytics';

import {List, ListItem} from "@latitude/list";

const StyledInputsBox = styled(Box)`
  width: 100%;

  @media (min-width: ${BREAKPOINT.LG}) {
    width: 50%;
  }
`;

const StyledSvg = styled(SvgInline)`
  width: 20px;
  height: 20px;
  position: relative;
  top: 5px;
  margin-left: 5px;
`;

const StyledFlex = styled(Flex)`
  flex-wrap: wrap;

  @media (min-width: ${BREAKPOINT.MD}) {
    flex-wrap: nowrap;
  }
`;

const StyledLoanAmountBox = styled(Box)`
  margin-right: 0;
  width: 100%;

  @media (min-width: ${BREAKPOINT.MD}) {
    margin-right: ${MARGIN.M16};
    width: 50%;
  }
`;

const StyledLoanPeriodBox = styled(Box)`
  margin-top: ${MARGIN.M32};
  width: 100%;

  @media (min-width: ${BREAKPOINT.MD}) {
    margin-top: 0;
    width: 50%;
  }
`;

/**
 * validateLoanAmount
 *
 * Notes:
 * - error will stop calculation
 * - warning will still calculate
 * - both error and warning will display validation message
 *
 * @param {number} amountValue - loan amount for validation
 * @returns {object} validation result
 */
const validateLoanAmount = (amountValue, loans1 = false) => {
  let isError;
  let isWarning;
  let message = '';

  // determine minimum loan amount for product
  const plMinAmount = loans1 ? PL_SYMPLE_MINIMUM_AMOUNT : PL_MINIMUM_AMOUNT;

  // validate input amount value
  if (!amountValue || amountValue < plMinAmount) {
    isError = true;
    isWarning = false;
    message = `Loan amount must be at least ${formatAsCurrency(plMinAmount)}`;
  } else if (amountValue > PL_MAXIMUM_AMOUNT) {
    isError = false;
    isWarning = true;
    message = (
      <Text color={COLOR.TEXT_ERROR} fontWeight={FONT_WEIGHT.NORMAL}>
        {`For loan amounts more than ${formatAsCurrency(
          PL_MAXIMUM_AMOUNT
        )} please call us on `}
        <Tel color={COLOR.TEXT_ERROR} no={PL_TEL} />, Mon - Fri, 8:30am - 6pm
        (AEST)
      </Text>
    );
  } else {
    isError = false;
    isWarning = false;
  }

  return {
    isError,
    isWarning,
    message
  };
};

// loan amount analytics tracking method, trying to only capture
// the final amount input value by having a long debounce duration
const debouncedTrackAmountChange = _debounce(
  (e, validationResult, salesMode = false) => {
    const newAmountValue = e.value; // use 'value' for string type
    const { isError, isWarning } = validationResult;

    let eventValue;
    if (newAmountValue && isError) {
      eventValue = `error:${newAmountValue}`;
    } else if (newAmountValue && isWarning) {
      eventValue = `warning:${newAmountValue}`;
    } else if (newAmountValue) {
      eventValue = newAmountValue;
    } else {
      eventValue = '';
    }

    // trigger analytics call
    !salesMode &&
      eventValue.length &&
      directTrackLink(
        true,
        'o',
        getCustomLinkEventName(customLinkNames.AMOUNT_CHANGE, eventValue)
      );
  },
  1600
);

const CalculatorFields = ({
  salesMode,
  setRepaymentAmount,
  setTotalInterestPaid,
  setTotalAmountPaid,
  repaymentPeriod,
  setRepaymentPeriod,
  extraRepayments,
  setInterestSaved,
  setTimeSavedMonths,
  setTimeSavedYears,
  setUserInputLoanAmount,
  setUserInputRepaymentYears,
  loans1
}) => {
  const [loanAmount, setLoanAmount] = useState(loans1 ? PL_SYMPLE_MINIMUM_AMOUNT : PL_MINIMUM_AMOUNT);
  const [isSecured, setIsSecured] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [hasWarning, setHasWarning] = useState(false);
  const [amountValidationMessage, setAmountValidationMessage] = useState('');
  const [repaymentYears, setRepaymentYears] = useState(loans1 ? 2 : 5);
  const [showModal, setShowModal] = useState(false);
  const [interestRate, setInterestRate] = useState(loans1 ? PL_SYMPLE_SECURED_MIN_IR : PL_SECURED_MIN_IR);

  let minInterestRate;
  let maxInterestRate;
  let minComparisonRate;
  let maxComparisonRate;
  if (loans1) {
    if (isSecured) {
      minInterestRate = PL_SYMPLE_SECURED_MIN_IR;
      maxInterestRate = PL_SYMPLE_SECURED_MAX_IR;
      maxComparisonRate = PL_SYMPLE_SECURED_COMPARISON_MAX_IR;
      minComparisonRate = PL_SYMPLE_SECURED_COMPARISON_MIN_IR
    } else {
      minInterestRate = PL_SYMPLE_UNSECURED_MIN_IR;
      maxInterestRate = PL_SYMPLE_UNSECURED_MAX_IR;
      maxComparisonRate = PL_SYMPLE_UNSECURED_COMPARISON_MAX_IR;
      minComparisonRate = PL_SYMPLE_UNSECURED_COMPARISON_MIN_IR
    }
  } else {
    if (isSecured) {
      minInterestRate = PL_SECURED_MIN_IR;
      maxInterestRate = PL_SECURED_MAX_IR
      maxComparisonRate = PL_SECURED_COMPARISON_MAX_IR;
      minComparisonRate = PL_SECURED_COMPARISON_MIN_IR;
    } else {
      minInterestRate = PL_UNSECURED_MIN_IR;
      maxInterestRate = PL_UNSECURED_MAX_IR;
      maxComparisonRate = PL_UNSECURED_COMPARISON_MAX_IR;
      minComparisonRate = PL_UNSECURED_COMPARISION_MIN_IR;
    }
  }
  const [analytics] = useContext(AnalyticsContext);

  const handleRepaymentPeriodOnChange = e => {
    const newPeriodValue = e.target.value;
    const selectedItemTextValue = e.target.options[e.target.selectedIndex].text;
    setRepaymentPeriod(newPeriodValue);

    // trigger analytics call
    !salesMode &&
      directTrackLink(
        true,
        'o',
        getCustomLinkEventName(
          customLinkNames.PERIOD_CHANGE,
          normaliseForAnalytics(newPeriodValue)
        )
      );

    /*
     * Tealium tracking
     * TODO: Below values are pending confirmation from tagging team (see CGT-164 for details)
     */
    if (trackEvent) {
      trackEvent(analytics, {
        category: CALCULATOR_TRACKING.CATEGORY,
        action: CALCULATOR_TRACKING.ACTION,
        label: 'With repayments every',
        location: CALCULATOR_TRACKING.LOCATION,
        value: selectedItemTextValue
      });
    }
  };

  const handleRateTypeOnChange = e => {
    const secured = e.target.value === 'true';
    const unsecuredMinInterestRate = loans1 ? PL_SYMPLE_UNSECURED_MIN_IR : PL_UNSECURED_MIN_IR;
    const securedMaxInterestRate = loans1 ? PL_SYMPLE_SECURED_MAX_IR : PL_SECURED_MAX_IR;
    if (!secured && interestRate < unsecuredMinInterestRate) {
      setInterestRate(unsecuredMinInterestRate);
    }
    if (secured && interestRate > securedMaxInterestRate) {
      setInterestRate(securedMaxInterestRate);
    }
    setIsSecured(secured);

    // trigger analytics call
    const eventValue = secured ? 'secured' : 'unsecured';
    !salesMode &&
      directTrackLink(
        true,
        'o',
        getCustomLinkEventName(customLinkNames.RATE_TYPE_CHANGE, eventValue)
      );
  };

  const handleTermOnChange = e => {
    const newTermValue = e.target.value;
    sessionStorage.setItem('repaymentPeriod', newTermValue);
    setRepaymentYears(parseInt(newTermValue));
    setUserInputRepaymentYears(parseInt(newTermValue));

    // trigger analytics call
    !salesMode &&
      directTrackLink(
        true,
        'o',
        getCustomLinkEventName(customLinkNames.TERM_CHANGE, newTermValue)
      );
  };

  const setTimeSavedValues = timeSaved => {
    // average amount of weeks in a month
    const weekToMonthModifier = 4.35;

    // calculate total time saved in months based on repayment frequency
    let timeSavedInMonths = timeSaved;
    if (repaymentPeriod === LOAN_PAY_FREQUENCY.FORTNIGHTLY) {
      timeSavedInMonths = (timeSaved / weekToMonthModifier) * 2;
    } else if (repaymentPeriod === LOAN_PAY_FREQUENCY.WEEKLY) {
      timeSavedInMonths = timeSaved / weekToMonthModifier;
    }

    // update time saved years and months to absolute rounded value
    timeSavedInMonths = Math.abs(Math.round(timeSavedInMonths));

    setTimeSavedYears(Math.floor(timeSavedInMonths / 12));
    setTimeSavedMonths(timeSavedInMonths % 12);
  };

  useEffect(() => {
    // stop calculation ONLY when we have invalid input or error
    // should still calculate when having validation warning
    if (loanAmount && loanAmount !== 0 && !hasError) {
      const principle = loanAmount + PL_ESTABLISHMENT_FEE;
      const roundedInterestRate = parseFloat(interestRate.toFixed(2));

      const {
        repayment,
        totalInterest,
        totalAmountPaid,
        timeSavedByExtra,
        interestSavedByExtra
      } = getRepaymentFigures(
        principle,
        roundedInterestRate,
        repaymentYears,
        repaymentPeriod,
        PL_MONTHLY_FEE,
        extraRepayments,
        true
      );

      setRepaymentAmount(repayment);
      setTotalInterestPaid(totalInterest);
      setTotalAmountPaid(totalAmountPaid);
      setInterestSaved(interestSavedByExtra);
      setTimeSavedValues(timeSavedByExtra);
    }
  }, [
    loanAmount,
    isSecured,
    repaymentYears,
    repaymentPeriod,
    interestRate,
    extraRepayments
  ]);

  const defaultRepaymentPeriod = [
    { text: '2', value: 2 },
    { text: '3', value: 3 },
    { text: '4', value: 4 },
    { text: '5', value: 5 },
    { text: '6', value: 6 },
    { text: '7', value: 7 }
  ];
  const sympleDefaultRepaymentPeriod = [
    { text: '2', value: 2 },
    { text: '3', value: 3 },
    { text: '4', value: 4 },
    { text: '5', value: 5 },
    { text: '6', value: 6 },
    { text: '7', value: 7 }
  ];

  return (
    <StyledInputsBox>
      <Vertical spacing={MARGIN.M32}>
        <StyledFlex justifyContent={JUSTIFY_CONTENT.SPACE_BETWEEN}>
          <StyledLoanAmountBox width="50%">
            <CurrencyInput
              type="tel"
              labelText={CALCULATOR_TRACKING.LABEL}
              maxLength={7}
              prefix="$"
              name="loanAmount"
              value={loanAmount}
              errorStatus={hasError || hasWarning}
              showError={hasError || hasWarning}
              onValueChange={e => {
                // validate and set new amount
                const validationResult = validateLoanAmount(e.floatValue, loans1);
                setHasError(validationResult.isError);
                setHasWarning(validationResult.isWarning);
                setAmountValidationMessage(validationResult.message);
                setLoanAmount(e.floatValue);
                setUserInputLoanAmount(e.floatValue);
                sessionStorage.setItem('loanAmount', e.floatValue);
                // any operations that needs to be debounced
                debouncedTrackAmountChange(e, validationResult, salesMode);
              }}
              showErrorMessage={amountValidationMessage}
            />
          </StyledLoanAmountBox>
          <StyledLoanPeriodBox width="50%">
            <Select
              id="pl-calc-repaymentPeriod"
              color={COLOR.BLUE}
              label={<Text>With repayments every</Text>}
              name="repaymentPeriod"
              selectedValue={repaymentPeriod}
              onChange={handleRepaymentPeriodOnChange}
              onBlur={() => {}}
              values={[
                { text: 'Week', value: LOAN_PAY_FREQUENCY.WEEKLY },
                {
                  text: 'Fortnight',
                  value: LOAN_PAY_FREQUENCY.FORTNIGHTLY
                },
                { text: 'Month', value: LOAN_PAY_FREQUENCY.MONTHLY }
              ]}
            />
          </StyledLoanPeriodBox>
        </StyledFlex>
        <Box>
          <RadioBoxes
            labelText={
              <Text>
                Fixed or Variable?
                <Link
                  href="#"
                  noStyle
                  onClick={e => {
                    setShowModal(true);
                    e.preventDefault();
                  }}
                  trackEventData={{
                    category: CALCULATOR_TRACKING.CATEGORY,
                    action: CALCULATOR_TRACKING.ACTION,
                    label: 'i'
                  }}
                >
                  <StyledSvg name="info-block-outline" />
                </Link>
              </Text>
            }
            name="repaymentsYears"
            onChange={handleRateTypeOnChange}
            values={[
              { value: true, text: 'Variable' },
              { value: false, text: 'Fixed' }
            ]}
            selectedValue={isSecured}
            equalWidths
          />
          <Modal
            isOpen={showModal}
            onRequestClose={() => setShowModal(false)}
            title="Fixed vs Variable loan?"
            content={
              <>
                When applying for a personal loan, one decision you need is to make is whether you want a fixed or variable loan.
                <br /><br /><strong>Fixed loan:</strong>
                <List>
                  <ListItem>Your rate and repayments remain fixed for the life of your loan</ListItem>
                  <ListItem>An early termination fee of $500 applies if you payout your loan in full up until the last 3 months of the loan. No early termination fee applies in the final 3 months of the loan</ListItem>
                </List>
                <br /><strong>Variable loan:</strong>
                <List>
                  <ListItem>Your rate and repayments could change, up or down, during your loan term</ListItem>
                  <ListItem>No early termination fee</ListItem>
                </List>
              </>
            }
            ariaHideApp={false}
          />
        </Box>
        <Box>
          <InterestRateWidget
            salesMode={salesMode}
            interestRate={interestRate}
            setInterestRate={setInterestRate}
            minInterestRate={minInterestRate}
            minComparisonRate={minComparisonRate}
            maxInterestRate={maxInterestRate}
            maxComparisonRate={maxComparisonRate}
          />
        </Box>
        <Box>
          <RadioBoxes
            labelText={
              <Text>
                And pay it over{' '}
                <Bold>
                  {repaymentYears} year
                  {parseInt(repaymentYears) === 1 ? '' : 's'}
                </Bold>
              </Text>
            }
            name="repaymentYears"
            onChange={handleTermOnChange}
            values={loans1 ? sympleDefaultRepaymentPeriod : defaultRepaymentPeriod}
            selectedValue={repaymentYears}
            equalWidths
            pluralLabel="year"
          />
        </Box>
      </Vertical>
    </StyledInputsBox>
  );
};

export default CalculatorFields;
