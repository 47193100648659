/* eslint-disable import/no-unresolved */
import React from 'react';
import Helmet from 'react-helmet';
import styled from 'styled-components';
import { StaticImage } from 'gatsby-plugin-image';
import { Heading4 } from '@latitude/heading';
import { Box } from '@latitude/box';
import {
  BREAKPOINT,
  COLOR,
  ALIGN,
  FONT_SIZE,
  MARGIN
} from '@latitude/core/utils/constants';
import { Promo } from '@latitude/promo';
import { Text } from '@latitude/text';
import Layout from '../components/layout';
import FeaturesStaggeredSlider from '../components/FeaturesStaggeredSlider/FeaturesStaggeredSlider';
import HeroBranded from '../components/HeroBranded/HeroBranded';
import PageData from '../data/pages/richmond-football-club.json';
import { PromoSection } from '../components/Promo/Promo';
import Section from '../components/Section/Section';
import heroImageTablet from '../images/richmond-football-club/hero-module2x.webp';
import heroImageDesktop from '../images/richmond-football-club/hero-module3x.webp';
import mobileHeroImage from '../images/richmond-football-club/mobile-hero-image-fans.webp';
import tigerFansVideoCoverImage from '../images/richmond-football-club/tiger-fans-video.webp';
import houseGiftVideoCoverImage from '../images/richmond-football-club/housewarming-gift-video.webp'

const StyledHeroBranded = styled(HeroBranded)`
  background-image: url(${mobileHeroImage});
  background-size: auto 490px;
  background-position: top center;
  background-color: #cfeafe;
  background-repeat: no-repeat;
  height: 100%;
  .HeroContent {
    background-color: #cfeafe;
  }
  @media (min-width: ${BREAKPOINT.MD}) {
    background-image: url(${heroImageTablet});
    background-size: cover;
    height: 300px;
    .HeroContent {
      background-color: transparent;
    }
  }
  @media (min-width: ${BREAKPOINT.LG}) {
    height: 500px;
  }
  @media (min-width: ${BREAKPOINT.XL}) {
    background-image: url(${heroImageDesktop});
    background-size: auto 650px;
    background-position: right center;
  }
`;

const StyledPromoSection = styled(PromoSection)`
  background-color: ${COLOR.GREY6};
`;

const StyledGallerySection = styled(Section)`
  margin-top: 30px;
  .container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    max-width: 1400px;
  }
`;

const GalleryImageWrapper = styled.div`
  width: 160px;
  display: flex;
  justify-content: center;
  align-item: center;
  margin: 10px auto;

  @media (min-width: ${BREAKPOINT.LG}) {
    width: 240px;
    height: 240px;
    margin: 10px;
  }
`;

const RichmondFootballClub = props => {
  return (
    <Layout location={props.location}>
      <main className="navigation-spacer">
        <Helmet>
          <link
            rel="canonical"
            href="https://www.latitudefinancial.com.au/richmond-football-club/"
          />
          <title>{PageData.metaTitle}</title>
          <meta name="description" content={PageData.metaDesc} />
        </Helmet>

        <StyledHeroBranded
          title="Proud to back the Yellow and Black."
          text="As one of Australia’s leading consumer lenders, and major partner of the Richmond Football Club, we’ve got your back in season 2023 and beyond. Go Tiges!"
        />

        <VideoModule
          title="Your Tigers obsession. Backed by Latitude."
          text="Whether you’re renovating your Tiger Den, shopping Interest Free for the things you love or following the Richmond Football Club, home and away, we’re ready to help."
          videoSrc="https://youtu.be/d9oglRWgUIM"
          videoCoverImage={tigerFansVideoCoverImage}
        />

        <VideoModule
          title="The perfect housewarming gift, Interest Free!"
          text="Join Shorty on his quest to find the perfect housewarming gift for Bakes at Harvey Norman – backed by Latitude."
          videoSrc="https://youtu.be/VGwMpWzvz9g"
          videoCoverImage={houseGiftVideoCoverImage}
        />

        <FeaturesStaggeredSlider
          css={`
            background-color: #f8f8f8;
          `}
          data={PageData.categories}
          className="whats-important"
          heading="Your Partners in Money"
          responsiveSettings={PageData.categoriesResponsiveSettings}
        />

        <FootballClubGallery />
      </main>
    </Layout>
  );
};

const VideoModule = ({title, text, videoSrc, videoCoverImage}) => {
  return (
    <StyledPromoSection
      css={`
        background-color: white;
      `}
    >
      <Heading4
        color={COLOR.BLACK}
        align={ALIGN.CENTER}
        css={`
          margin-left: auto;
          margin-right: auto;
          background-color: white;
          @media (min-width: ${BREAKPOINT.MD}) {
            max-width: 500px;
          }
        `}
      >
        {title}
      </Heading4>
      <Text
        fontSize={FONT_SIZE.LARGE}
        marginBottom={MARGIN.M24}
        css={`
          margin-left: auto;
          margin-right: auto;
          text-align: center;
          background-color: white;
          @media (min-width: ${BREAKPOINT.MD}) {
            max-width: 500px;
          }
        `}
      >
        {text}
      </Text>
      <Promo
        frameVideoSrc={videoSrc}
        frameAlignment="left"
        frameVideoImage={videoCoverImage}
        frameBorderColor="#B7F4FB"
        noContent
      />
    </StyledPromoSection>
  )
}

const FootballClubGallery = () => {
  return (
    <StyledGallerySection>
      <GalleryImageWrapper>
        <StaticImage
          src="../images/richmond-football-club/club-gallery-1.webp"
          alt="club gallery pic1"
          width={240}
        />
      </GalleryImageWrapper>
      <GalleryImageWrapper>
        <StaticImage
          src="../images/richmond-football-club/club-gallery-2.webp"
          alt="club gallery pic2"
          width={240}
        />
      </GalleryImageWrapper>
      <GalleryImageWrapper>
        <StaticImage
          src="../images/richmond-football-club/club-gallery-3.webp"
          alt="club gallery pic3"
          width={240}
        />
      </GalleryImageWrapper>
      <GalleryImageWrapper>
        <StaticImage
          src="../images/richmond-football-club/club-gallery-4.webp"
          alt="club gallery pic4"
          width={240}
        />
      </GalleryImageWrapper>
      <GalleryImageWrapper>
        <StaticImage
          src="../images/richmond-football-club/club-gallery-5.webp"
          alt="club gallery pic5"
          width={240}
        />
      </GalleryImageWrapper>
      <GalleryImageWrapper>
        <StaticImage
          src="../images/richmond-football-club/club-gallery-6.webp"
          alt="club gallery pic6"
          width={240}
        />
      </GalleryImageWrapper>
      <GalleryImageWrapper>
        <StaticImage
          src="../images/richmond-football-club/club-gallery-7.webp"
          alt="club gallery pic7"
          width={240}
        />
      </GalleryImageWrapper>
      <GalleryImageWrapper>
        <StaticImage
          src="../images/richmond-football-club/club-gallery-8.webp"
          alt="club gallery pic8"
          width={240}
        />
      </GalleryImageWrapper>
      <GalleryImageWrapper>
        <StaticImage
          src="../images/richmond-football-club/club-gallery-9.webp"
          alt="club gallery pic9"
          width={240}
        />
      </GalleryImageWrapper>
      <GalleryImageWrapper>
        <StaticImage
          src="../images/richmond-football-club/club-gallery-10.webp"
          alt="club gallery pic10"
          width={240}
        />
      </GalleryImageWrapper>
    </StyledGallerySection>
  );
};

export default RichmondFootballClub;
