import React from 'react';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';
import Layout from '../../components/layout';
import { Box } from '../../components/Box/Box';
import { MARGIN, COLOR, PADDING, SITE_URL } from '../../utils/constants';
import Remark from '../../components/Remark/Remark';
import Text from '../../components/Text/Text';
import { Heading5 } from '../../components/Heading/Heading';

const PrivacyAndTermsTemplate = ({ data }) => {
  const { markdownRemark } = data;
  const { frontmatter, htmlAst } = markdownRemark;
  const { title, path } = frontmatter;

  const url = `${SITE_URL}${path}`;

  return (
    <div
      css={`
        .top-menu-items {
          visibility: hidden;
        }
        .logon-button-wrapper {
          visibility: hidden;
        }
        .search-button {
          visibility: hidden !important;
        }
        .hamburger-button {
          display: none !important;
        }
        .PrivacyAndTermsTemplateBody{
                p,ul{
                    color: #000000;
                    font-weight: 400;
                }
                @media (max-width: 990px) {
                    margin-left:20px;
                }
              }
        .home-button-ltd{
        pointer-events: none;}
      `}
    >
      <Layout noBreadcrumb noFooter={true}>
        <main className="navigation-spacer">
          <Helmet title={`${title} | Terms and Conditions`}>
            <link rel="canonical" href={url} />

            <meta
              property="og:title"
              content={`${title} - Terms and Conditions`}
            />
            <meta property="og:url" content={url} />
          </Helmet>
          <Box>
            <h1
              css={`
                 {
                  color: #0555c8;
                  font-size: 71px;
                  font-weight: 600;
                  line-height: 1;
                  text-align: center;
                  max-width: 960px;
                  margin: 0 auto;
                }
                @media (max-width: 990px) {
                    margin-left:20px;
                }
              `}
            >
              {title}
            </h1>
          </Box>
          <Box
            isResponsive
            isBodyCopy
            margin={`0 ${MARGIN.MAUTO}`}
            paddingBottom={PADDING.P32}
            maxWidth="961px !important"
            className="PrivacyAndTermsTemplateBody"
          >
            <Remark
              htmlAst={htmlAst}
              H5Component={
                <Heading5 color={COLOR.BLACK} marginTop={MARGIN.M32} />
              }
              PComponent={
                <Text marginTop={MARGIN.M16} marginBottom={MARGIN.M16} />
              }
            />
          </Box>
        </main>
      </Layout>
    </div>
  );
};

export const pageQuery = graphql`
  query PrivacyTCsByPath($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      htmlAst
      frontmatter {
        path
        title
      }
    }
  }
`;

export default PrivacyAndTermsTemplate;
