import React from 'react';
import { CardIcon } from '@latitude/card-icon';
import { Heading4 } from '@latitude/heading';
import Section from '@latitude/section';
import { Text } from '@latitude/text';
import {
  ALIGN,
  BREAKPOINT,
  COLOR,
  FONT_SIZE,
  MARGIN,
  PADDING
} from '@latitude/core/utils/constants';

import imageShowCare from '../../images/show-care.png';
import imageBeCurious from '../../images/be-curious.png';
import imageActRight from '../../images/act-right.png';

const OurValues = () => {
  return (
    <Section className="w-100 bg-lightest">
      <Heading4 color={COLOR.BLACK} align={ALIGN.CENTER} marginTop={MARGIN.M32}>
        Our values
      </Heading4>
      <Text
        align={ALIGN.CENTER}
        fontSize={FONT_SIZE.LARGE}
        marginBottom={MARGIN.M8}
      >
        Our values provide the framework for everything we do and how we do it. They
        are our guiding principles and desired behaviours.
      </Text>
      <Text
        align={ALIGN.CENTER}
        fontSize={FONT_SIZE.LARGE}
        marginBottom={MARGIN.M48}
      >
        We are proud that our values were created by our people.
      </Text>
      <div
        className="row"
        css={`
          h5 {
            padding-top: ${PADDING.P24};
          }
          @media (min-width: ${BREAKPOINT.SM}) {
            h5 {
              padding-top: ${PADDING.P48};
            }
          }
        `}
      >
        <div className="col-12 col-lg-4 mb-4">
          <CardIcon
            newBrand
            thumbnail
            image={
              <img
                style={{ maxWidth: '200px' }}
                src={imageShowCare}
                alt="Show Care"
              />
            }
            title="Show Care"
          >
            <Text fontSize={FONT_SIZE.NORMAL} align={ALIGN.CENTER}>
              We are kind to one another. It&apos;s a simple yet profound thing
              where we ask more of ourselves and in doing so unleash the power
              of our community.
            </Text>
          </CardIcon>
        </div>
        <div className="col-12 col-lg-4 mb-4">
          <CardIcon
            newBrand
            thumbnail
            image={
              <img
                style={{ maxWidth: '200px' }}
                src={imageBeCurious}
                alt="Be Curious"
              />
            }
            title="Be Curious"
          >
            <Text fontSize={FONT_SIZE.NORMAL} align={ALIGN.CENTER}>
              With curiosity we continue our fascination with the world.
              We&apos;re constantly experimenting, challenging ourselves and
              encouraging others to share ideas.
            </Text>
          </CardIcon>
        </div>
        <div className="col-12 col-lg-4 mb-4">
          <CardIcon
            newBrand
            thumbnail
            image={
              <img
                style={{ maxWidth: '200px', padding: '50px 0 0 0' }}
                src={imageActRight}
                alt="Act Right"
              />
            }
            title="Act Right"
          >
            <Text fontSize={FONT_SIZE.NORMAL} align={ALIGN.CENTER}>
              From the little moments to the big decisions, we take our
              responsibility seriously. We always step outside what feels
              comfortable to stand up for what we believe is right.
            </Text>
          </CardIcon>
        </div>
      </div>
    </Section>
  );
};

export default OurValues;
