import React from 'react';
import classnames from 'classnames';
import LinesEllipsis from 'react-lines-ellipsis';
import { Link } from '@latitude/link';
import Image from '@latitude/image';
import CardTitle from '../CardTitle/CardTitle';
import './_card-news.scss';

const component = 'card-news';

const CardNews = props => {
  const {
    date,
    description,
    buttonProps,
    maxLines,
    titleProps,
    className,
    imageProps,
    style
  } = props;

  return (
    <article className={classnames(`${component}`, className)} style={style}>
      <div className={`${component}__image`}>
        <Image {...imageProps} />
      </div>
      <CardTitle {...titleProps} />
      <div className={`${component}__content`}>
        <strong className={`${component}__date`}>{date}</strong>
        <div className={`${component}__text`}>
          <LinesEllipsis
            text={description}
            maxLine={maxLines || '5'}
            ellipsis="..."
            trimRight
            basedOn="letters"
          />
        </div>
        {buttonProps && (
          <Link
            trackEventData={{
              location: titleProps.title
            }}
            {...buttonProps}
          >
            {buttonProps.btnText}
          </Link>
        )}
      </div>
    </article>
  );
};

export default CardNews;
