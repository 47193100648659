import * as React from 'react';
import styled from 'styled-components';
import * as constants from '../../utils/constants';

const Icon = ({
  id,
  className,
  color = constants.COLOR.BLACK,
  size = constants.ICON_SIZE.MD,
  variant
}) => {
  const props = { id, className, color, size };
  // eslint-disable-next-line import/no-dynamic-require
  const Svg = require(`./svg/${variant}.svg`).default;
  const StyledSvg = styled(Svg)`
    ${({ size, color }) => `
      width: ${size};
      height: ${size};
      fill: none;
      stroke: ${color};
      stroke-width: 3px;
      stroke-miterlimit: 10;
      stroke-linecap: round;
      stroke-linejoin: round;
    `}
  `;

  return <StyledSvg {...props} />;
};

export default Icon;
