import React from 'react';
import PersonalLoanToGoPageContent from '@/components/PersonalLoanPageContent/PersonalLoanToGoPageContent';

const PersonalLoanPage = props => {
  return (
    <PersonalLoanToGoPageContent
      {...props}
      title="Personal Loan 2 Go - Fixed Interest Rate"
      description="Whether your dream is to renovate your home or go travelling, Latitude's personal loan can help make that come true faster. Apply in less than 10 minutes."
    />
  );
};

export default PersonalLoanPage;
