import styled from 'styled-components';
import SelectList from '@latitude/select-list';
import {
  BREAKPOINT,
  COLOR,
  FONT_FAMILY,
  MARGIN,
  PADDING
} from '@latitude/core/utils/constants';

export const StyledStepperTabContainer = styled.div`
  max-width: 360px;
  margin: 0 auto;

  @media (min-width: ${BREAKPOINT.LG}) {
    max-width: 730px;
    margin: 0 auto;
  }
`;

export const StyledSelectList = styled(SelectList)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0;

  .select-list__item {
    width: 100px;
    height: 100px;
    margin-right: 32px;
    border: solid 6px transparent;
    background-color: ${COLOR.GREY6};
    border-radius: 50%;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    @media (min-width: ${BREAKPOINT.LG}) {
      border: solid 8px ${COLOR.TRANSPARENT};
      width: 180px;
      height: 180px;
      margin-right: 80px;
    }

    &:last-child {
      margin-right: 0;

      &:after {
        display: none;
      }
    }

    &:hover {
      border-color: #f2f2f2;
    }

    &.select-list__item--active {
      background-color: ${COLOR.BLUE_BABY};
      border: solid 6px ${COLOR.BLUE_SKY};

      > .select-list__chevron {
        display: none;
      }

      &:hover {
        border-bottom: solid 6px ${COLOR.BLUE_SKY};
      }

      > span {
        color: #000;
      }

      /* 
       * Set top arrow position relative to parent 
       */

      &:before {
        content: '';
        position: absolute;
        bottom: -84px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 24px 24px 24px;
        border-color: transparent transparent ${COLOR.BLUE_BABY} transparent;
      }

      @media (min-width: ${BREAKPOINT.LG}) {
        border: solid 8px ${COLOR.BLUE_SKY};
        &:hover {
          border-bottom: solid 8px ${COLOR.BLUE_SKY};
        }
      }
    }

    &:after {
      width: 24px;
      height: 4px;
      content: '• • •';
      color: #d8d8d8;
      font-size: 13px;
      font-weight: 700;
      position: absolute;
      top: 50%;
      transform: translateY(-4px);
      display: block;
      right: -34px;

      @media (min-width: ${BREAKPOINT.LG}) {
        content: '• • • • • • •';
        right: -74px;
        width: 54px;
      }
    }

    img {
      width: 100%;
      max-width: 60px;

      @media (min-width: ${BREAKPOINT.LG}) {
        max-width: 90px;
      }
    }

    > span {
      position: absolute;
      display: block;
      bottom: -54px;
      left: 0;
      width: 100%;
      text-align: center;
      color: ${COLOR.GREY75};
      font-family: ${FONT_FAMILY.TITLE};
      font-size: 14px;
      font-weight: 600;
      line-height: 1.38;
      letter-spacing: -0.35px;
      height: 38px;

      @media (min-width: ${BREAKPOINT.LG}) {
        font-size: 15px;
        height: auto;
        white-space: nowrap;
        bottom: -40px;
        padding: 0;
      }
    }
  }
`;

export const StyledStepperContent = styled.div`
  background-color: ${COLOR.BLUE_BABY};
  display: none;
  margin: 72px 0 0;
  padding: ${PADDING.P24};
  line-height: 28px;
  font-size: 1rem;
  position: relative;

  p:first-child {
    margin-top: ${MARGIN.M0};
  }

  p:last-child {
    margin-bottom: ${MARGIN.M0};
  }

  &.benefit-stages-tabs__item--active {
    display: block;
  }

  @media (min-width: 992px) {
    padding: ${PADDING.P40};
  }
`;
