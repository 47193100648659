import * as React from 'react';
import Helmet from 'react-helmet';
import { SITE_URL, TITLE_SUFFIX } from './Metadata.constants';

const Metadata = ({
  isCanonicalURLRelative = true,
  addTitleSuffix = true,
  canonical,
  description,
  keywords,
  title,
  noIndex = false,
  noFollow = false,
  children
}) => {
  // work out meta robots value
  let metaRobotsValue = null;
  if (noIndex && !noFollow) {
    metaRobotsValue = 'noindex';
  } else if (!noIndex && noFollow) {
    metaRobotsValue = 'nofollow';
  } else if (noIndex && noFollow) {
    metaRobotsValue = 'noindex, nofollow';
  }

  return (
    <Helmet>
      <title>
        {title}
        {addTitleSuffix && `${TITLE_SUFFIX}`}
      </title>
      {description && <meta name="description" content={description} />}
      {metaRobotsValue && <meta name="robots" content={metaRobotsValue} />}
      {canonical && (
        <link
          rel="canonical"
          href={getCanonicalURL(canonical, isCanonicalURLRelative)}
        />
      )}
      {children}
    </Helmet>
  );
};

const getCanonicalURL = (canonical, isCanonicalURLRelative) => {
  return canonical
    ? `${isCanonicalURLRelative ? SITE_URL : ''}${canonical}`
    : '';
};

export default Metadata;
