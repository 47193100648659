import React, { useState } from 'react';
import styled from 'styled-components';
import { AnalyticsLocationProvider } from 'latitude-analytics';
import { Vertical } from '@latitude/spacing';
import { Button } from '@latitude/button';
import { Heading4, Heading5 } from '@latitude/heading';
import { COLOR, MARGIN, PADDING, ALIGN } from '@latitude/core/utils/constants';
import { Box, Flex } from '@latitude/box';
import { Text } from '@latitude/text';
import SvgInline from '@latitude/svg-inline';
import { Accordion } from '@latitude/accordion';
import { BasisProvider, defaultTheme, Grid } from 'basis';
import data from './data.json';
import {
  NarrowContentContainer,
  StyledContainer,
  StyledErrorMessage
} from './_styles';
import ReportingAccess from './_ReportingAccess';

const handleScrollToTabs = () => {
  const tabsElOffset = document.querySelector(`#tabs`).offsetTop;

  window.scrollTo({
    top: tabsElOffset,
    behavior: 'smooth'
  });
};

const Securitisation = () => {
  const [section, setActiveSection] = useState('terms');
  const heading = 'Securitisation';

  return (
    <AnalyticsLocationProvider location={heading}>
      <Box.Section
        margin={`${MARGIN.M0} ${MARGIN.MAUTO}`}
        backgroundColor={COLOR.GREY6}
      >
        <Box display={section === 'resources' ? 'block' : 'none'}>
          <ReportingAccess />
        </Box>
        <Heading4 color={COLOR.BLACK} align={ALIGN.CENTER}>
          {heading}
        </Heading4>

        <Box display={section === 'terms' ? 'block' : 'none'}>
          <Heading5 color={COLOR.BLACK} align={ALIGN.CENTER}>
            Terms and Conditions of Access to this section of the Website
          </Heading5>
          <Terms />
          <Agreement setActiveSection={setActiveSection} />
        </Box>
        <NarrowContentContainer
          display={section === 'resources' ? 'block' : 'none'}
        >
          <Button
            onClick={() => {
              setActiveSection('terms');
              handleScrollToTabs();
            }}
            variant="tertiary"
            width="130px"
            className="mt-4 mb-4"
          >
            Back
          </Button>
          <AccordionData />
        </NarrowContentContainer>
      </Box.Section>
    </AnalyticsLocationProvider>
  );
};

const Terms = () => (
  <Box>
    <BasisProvider theme={defaultTheme}>
      <Grid preset="page">
        <Grid.Item colSpan="all" colSpan-lg="0-5">
          <Vertical spacing={MARGIN.M16}>
            <strong>Important Information</strong>
            <Text marginBottom={MARGIN.M24}>
              Before accessing and using this website (this &quot;
              <strong>Website</strong>
              &quot;), please read the following terms and conditions (the
              &quot;
              <strong>Terms and Conditions</strong>&quot;) carefully. The Terms
              and Conditions govern your access to, and use of, this Website and
              you will need to agree to be bound by the Terms and Conditions
              before you will be granted access to this Website.
            </Text>
          </Vertical>

          <Vertical spacing={MARGIN.M16}>
            <strong>Access and Use of this Website</strong>
            <Text>
              The materials and information on this Website (the &quot;
              <strong>Materials</strong>&quot;) have been placed on this Website
              by [Latitude Finance Australia] (&quot;
              <strong>Latitude</strong>&quot;) for information purposes only in
              order to comply with legal and regulatory requirements. The
              Materials do not, under any circumstances or in any jurisdiction,
              constitute or form part of, and shall not be construed as:
            </Text>
            <Text>
              (i) a public offer of securities or other financial instruments
              under any applicable legislation;
              <br />
              (ii) an offer to sell, or solicitation of an offer to buy, any
              securities or other financial instruments; or
              <br />
              (iii) any advice or recommendation with respect to any securities
              or other financial instruments.
            </Text>
            <Vertical spacing={MARGIN.M16}>
              <Text>
                The distribution and/or use of the Materials may be restricted
                by local law or regulations in certain jurisdictions. The
                Materials are not intended for distribution to, and/or for use
                by, any person or entity in any jurisdiction where such
                distribution and/or use would be contrary to local law or
                regulation. Persons accessing this Website are required to
                inform themselves about and observe such restrictions and by
                accessing this Website you represent that you are a person into
                whose possession the Materials can be lawfully delivered in
                accordance with the laws of the jurisdiction in which you are
                located.
              </Text>
              <Text>
                The Materials are intended to be distributed only to, and are
                directed only at:
              </Text>
            </Vertical>
            <Vertical spacing={MARGIN.M16}>
              <Text>
                (i) persons who are &quot;wholesale clients&quot; within the
                meaning of section 761G of the Australian Corporations Act 2001
                (Cth); and
                <br />
                (ii) any other persons to whom the Materials may otherwise be
                lawfully communicated without needing to provide a regulated
                offer document.
              </Text>
            </Vertical>

            <Text>
              The Materials must not be acted on or relied on by, nor are the
              securities or other financial instruments referred to on this
              Website available to, any other persons.
            </Text>
            <Text>
              The securities or other financial instruments referred to on this
              Website have not been, and will not be, registered under the
              United States Securities Act of 1933, as amended (the &quot;
              <strong>Securities Act</strong>&quot;), and have not been offered,
              directly or indirectly, and will not be offered or sold, directly
              or indirectly, in or into the United States or to, or for the
              account or benefit of, any U.S. person (as defined in Regulation S
              under the Securities Act) absent an applicable exemption from the
              registration requirements under the Securities Act. Accordingly,
              none of the Materials or any information relating to such
              securities or other financial instruments is directed at any
              person or entity within the United States or at any U.S. person
              and must not be accessed if you are located in or you are a
              resident of the United States and no such Materials or information
              should be released, published or distributed directly or
              indirectly in or into the United States.
            </Text>

            <Text marginBottom={MARGIN.M24}>
              Where the Materials have been made available to you, this has been
              done on the basis that you are a person into whose possession such
              Materials may be lawfully delivered in accordance with the laws of
              the jurisdiction in which you are located and you may not, nor are
              you authorised to, deliver the Materials, electronically or
              otherwise, to any other person.
            </Text>
          </Vertical>
        </Grid.Item>
        <Grid.Item colSpan="all" colSpan-lg="6-11">
          <Vertical spacing={MARGIN.M16}>
            <strong>No Representations or Advice</strong>
            <Text>
              Whilst Latitude has taken all reasonable steps to ensure that the
              Materials are accurate as of the relevant date stated, neither
              Latitude nor any of its affiliates or their respective directors,
              officers, employees or agents (or any related person or entity)
              makes any representation or warranty with respect to the
              Materials. Neither Latitude nor any other person has any
              obligation, or has assumed any responsibility, to update any of
              the Materials or any other information for facts or events arising
              after the date that such Materials or other information is
              published. Any person accessing this Website should therefore be
              aware that the Materials may be out of date.
            </Text>

            <Text marginBottom={MARGIN.M24}>
              None of the Materials is, or purports to be, investment, tax or
              other advice or is intended to provide the basis of any credit or
              other evaluation, nor should any such Materials be considered a
              recommendation by Latitude or any of its affiliates or their
              respective directors, officers, employees or agents (or any
              related person or entity) to purchase or sell any securities or
              other financial instruments. Neither Latitude, nor any of its
              affiliates, agents or representatives, makes any representation
              regarding the possible legal, regulatory, accounting or tax
              consequences of an investment in the securities or other financial
              instruments referred to on this Website. Latitude acts neither as
              an adviser to, nor owes any fiduciary duty to, any person
              accessing this Website. Any person accessing this Website should
              make its own independent evaluation of the transactions described,
              the risks involved, and the relevance and adequacy of the
              Materials and should make such other investigations as it deems
              necessary. Investors should also consult their own legal, tax and
              other advisers in order to make any investment decision. The
              Materials are not tailored for any particular investor and do not
              constitute individual investment, tax or other advice. Any person
              accessing the Materials should also be aware that such Materials
              may contain additional disclaimers or limitations on liability.
            </Text>
          </Vertical>

          <Vertical spacing={MARGIN.M16}>
            <strong>No On-selling or Other Use</strong>
            <Text>
              Except to the extent expressly set out in the relevant offering
              circular, contract, report or other document, no person or entity
              may use or rely on the Materials for any purpose whatsoever,
              including, in particular, the making of any offer to sell, or any
              solicitation of an offer to buy, any securities or other financial
              instruments referred to on this Website.
            </Text>
            <Text marginBottom={MARGIN.M24}>
              Neither Latitude, nor any person who controls it, nor any
              affiliate, director, officer, employee nor agent of it or
              affiliate of such person accepts any liability arising out of or
              in connection with the use of the Materials.
            </Text>
          </Vertical>

          <Vertical spacing={MARGIN.M16}>
            <strong>Electronic Form</strong>
            <Text marginBottom={MARGIN.M24}>
              The Materials have been made available to you in electronic form
              and neither Latitude nor any of its affiliates or their respective
              directors, officers, employees or agents (or any related person or
              entity) warrants that such access, or use of this Website
              generally, will be uninterrupted or free of error. You are
              reminded that documents transmitted electronically may be altered
              or changed during the process of transmission and consequently
              none of Latitude or any of its affiliates or their respective
              directors, officers, employees or agents (or any related person or
              entity) accepts any liability or responsibility whatsoever in
              respect of any difference between the Materials made available to
              you in electronic form and the hard copy equivalent.
            </Text>
          </Vertical>
        </Grid.Item>
      </Grid>
    </BasisProvider>
  </Box>
);

const Agreement = ({ setActiveSection }) => {
  const [errorMsg, setErrorMsg] = useState(false);
  const [check, setCheck] = useState(false);

  const hasAgreed = () => {
    if (check) {
      setErrorMsg(false);
      setActiveSection('resources');
      handleScrollToTabs();
    } else {
      setErrorMsg(true);
    }
  };
  return (
    <Box paddingTop={PADDING.P24} paddingBottom={PADDING.P24}>
      <StyledContainer>
        <Vertical spacing={MARGIN.TEXT}>
          <Text marginBottom={MARGIN.M24}>
            <form>
              <label htmlFor="terms">
                <input
                  id="terms"
                  name="agree"
                  type="checkbox"
                  defaultChecked={check}
                  onChange={() => setCheck(!check)}
                />
                I confirm that I have read, understand and agree to the above
                terms and conditions
              </label>
            </form>
          </Text>
        </Vertical>
        <StyledErrorMessage id="errorMsg" display={errorMsg ? 'flex' : 'none'}>
          <SvgInline name="alert" small />
          To view this content you must first accept the terms and conditions
          above.
        </StyledErrorMessage>
        <Box
          css={`
            display: inline-flex;
          `}
        >
          <Button
            onClick={() => {
              hasAgreed();
            }}
            variant="secondary"
          >
            Access to material
          </Button>
        </Box>
      </StyledContainer>
    </Box>
  );
};

const AccordionData = () => {
  const Li = styled.li`
    svg {
      height: 20px;
      margin-right: 5px;
      margin-top: 3px;
      width: 20px;
    }

    strong {
      display: block;
      margin: ${MARGIN.M16} 0;
    }
  `;

  return (
    <Accordion
      titleBgColor="grey-light"
      contentBgColor={COLOR.WHITE}
      items={data.resources.map(item => ({
        id: item.id,
        title: item.title,
        content: (
          <div
            style={{
              padding: `${PADDING.P16}`
            }}
          >
            <ul className="link-list">
              {item.content.map((link, index) => (
                <Li key={index}>
                  {link.heading && (
                    <strong style={{ fontWeight: 500 }}>{link.heading}</strong>
                  )}
                  <Flex marginBottom={`${MARGIN.M8}`}>
                    <Box>
                      <SvgInline name="document" />
                    </Box>
                    <Vertical spacing={MARGIN.TEXT}>
                      <Text>
                        <a className="link" href={link.linkURL}>
                          {link.linkText}
                        </a>
                        <span> ({link.fileSize}) </span>
                      </Text>
                    </Vertical>
                  </Flex>
                </Li>
              ))}
            </ul>
          </div>
        )
      }))}
    />
  );
};

export default Securitisation;
