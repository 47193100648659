import React, { useEffect, useState, useContext } from 'react';
import { PageContext } from '@/context/PageContext';
import Helmet from 'react-helmet';

import Section from '@latitude/section';
import Layout from '@/components/layout';
import { Heading2 } from '@latitude/heading';
import AchPageHeaderSection from './_ach-page-header';
import DegreesCardMicrositeHeader from '../_28d-microsite-header';

const urlVixVerify =
  'https://au.vixverify.com/sa/customers/gecapital-ach/home.seam?ui=28degrees';

function AchAdditionalDetailsPage(props) {
  const [urlFrame, setUrlFrame] = useState(null);

  /** Contentful Data */
  const contentfulPageData = useContext(PageContext);
  const state = contentfulPageData?.updatedBody || {};
  /** - END - */

  function fetchIframeSource() {
    if (window?.location?.search) {
      const queryParameters = new URLSearchParams(window.location.search);
      const applicationId = queryParameters.get('applicationid');

      if (applicationId) {
        return urlVixVerify + '&applicationid=' + applicationId;
      }
    }
    return null;
  }

  useEffect(() => {
    const iframeSource = fetchIframeSource();
    setUrlFrame(iframeSource);
  }, []);

  return (
    <Layout location={props.location}>
      <main
        className="navigation-spacer"
        css={`
          margin-top: 0 !important;
          background-color: #f8f8f8;
          .microsite-header {
            z-index: 2000 !important;
          }

          div[class^='MicrositeHeader__SecondBar'].microsite-header__nav-wrap {
            margin-top: 8px;
          }
        `}
      >
        <Helmet>
          <link
            rel="canonical"
            href="https://www.latitudefinancial.com.au/credit-cards/28-degrees/addcard/ach-form/"
          />
          <meta
            name="description"
            content="Add an additional cardholder to your Latitude 28&deg; Global Platinum Mastercard"
          />
          <title>Additional Card Holder Details | 28 Degrees card</title>
        </Helmet>
        <DegreesCardMicrositeHeader data={state.secondaryNavData} />

        <AchPageHeaderSection />
        <Section>
          <Heading2 css={{ color: '#0046aa' }}>
            Additional cardholder details
          </Heading2>

          <div
            css={{ clear: 'both', margin: '10px 0' }}
            className="lfs-iframe-vixverify"
          >
            {urlFrame ? (
              <iframe
                css={{
                  width: '100%',
                  minHeight: '800px',
                  border: 'none',
                  padding: '10px'
                }}
                title="Verify Your Identity"
                src={urlFrame}
              />
            ) : (
              <p>Application identifier is missing.</p>
            )}
          </div>
        </Section>
      </main>
    </Layout>
  );
}

export default AchAdditionalDetailsPage;
