import React, { useContext } from 'react';
import { PageContext } from '@/context/PageContext';
import Helmet from 'react-helmet';
import { AnalyticsLocationProvider } from 'latitude-analytics';
import { SecondaryHero } from '@latitude/hero';
import {
  PlanTypes,
  PLAN_TYPE_MINIMUM_MONTHLY,
  PLAN_TYPE_EQUAL_MONTHLY,
  PLAN_TYPE_FLEXIBLE_MONTHLY
} from '@latitude/interest-free-plans';
import { ImportantInformation } from '@latitude/important-information';
import { handleSmoothScroll } from '@/utils/helpers';
import { List, ListItem } from '@latitude/list';
import { Box } from '../../components/Box/Box';
import { Heading6 } from '../../components/Heading/Heading';
import { Text, Strong } from '../../components/Text';
import { COLOR } from '../../utils/constants';
import HorizontalRule from '../../components/HorizontalRule/HorizontalRule';
import Layout from '../../components/layout';

const scrollHandler = event => {
  event.preventDefault();
  handleSmoothScroll(event.target.hash, 56);
};

const TermsAndConditions = props => {
  PLAN_TYPE_MINIMUM_MONTHLY.href = '#minimum-monthly';
  PLAN_TYPE_MINIMUM_MONTHLY.linkText = 'View terms and conditions';
  PLAN_TYPE_MINIMUM_MONTHLY.onClick = scrollHandler;
  PLAN_TYPE_EQUAL_MONTHLY.href = '#equal';
  PLAN_TYPE_EQUAL_MONTHLY.linkText = 'View terms and conditions';
  PLAN_TYPE_EQUAL_MONTHLY.onClick = scrollHandler;
  PLAN_TYPE_FLEXIBLE_MONTHLY.href = '#flexible';
  PLAN_TYPE_FLEXIBLE_MONTHLY.linkText = 'View terms and conditions';
  PLAN_TYPE_FLEXIBLE_MONTHLY.onClick = scrollHandler;
  const heading = 'Our Interest Free Payment Plans explained';

  /** Contentful Data */
  const contentfulPageData = useContext(PageContext);
  const state = contentfulPageData?.updatedBody || {};
  /** - END - */

  return (
    <Layout location={props.location}>
      <main className="navigation-spacer">
        <Helmet>
          <link
            rel="canonical"
            href="https://www.latitudefinancial.com.au/interest-free/terms-and-conditions/"
          />
          <title>
            Interest Free terms and conditions | Latitude Interest Free
          </title>
          <meta
            name="description"
            content="Terms and conditions for Equal Monthly, Minimum Monthly and Flexible interest free payment plans"
          />
        </Helmet>
        <SecondaryHero heading={<>Interest Free terms & conditions</>} />
        <AnalyticsLocationProvider location={heading}>
          <PlanTypes
            heading={heading}
            types={[
              PLAN_TYPE_MINIMUM_MONTHLY,
              PLAN_TYPE_EQUAL_MONTHLY,
              PLAN_TYPE_FLEXIBLE_MONTHLY
            ]}
          />
        </AnalyticsLocationProvider>
        <HorizontalRule />
        <EqualTermsSection />
        <HorizontalRule />
        <MinimumTermsSection />
        <HorizontalRule />
        <FlexibleTermsSection />
        <ImportantInformation
          data={{
            content: {
              importantInformationSectionOne: [
                '<strong>Important changes to your Latitude Gem Visa are coming. Find out more <a href="https://latitudefs.zendesk.com/hc/en-au/articles/5078660193041-Latitude-GO-Mastercard-and-Gem-Visa-APR-Increase" target="_blank">here</a>.</strong><br /><br />' +
                  '<strong>General Terms and Conditions</strong><br /><br />This notice is given under the Latitude Gem Visa Conditions of Use (as applicable), which specify all other conditions for this offer. Credit is provided by Latitude Finance Australia ABN 42 008 583 588 Australian Credit Licence number 392145.<br /><br />' +
                  'Visa and the Visa brand are registered trademarks of Visa International.'
              ]
            }
          }}
          sectionOneColummClasses="col-10 offset-1 col-lg-10 offset-lg-1"
          {...state?.importantInfoData?.[0]}
        />
      </main>
    </Layout>
  );
};

const EqualTermsSection = () => (
  <Box.Section backgroundColor={COLOR.GREY6} isBodyCopy id="equal">
    <Heading6>
      Interest Free with Equal Monthly Payment plans{' '}
      <em>(Instalment Interest Free Promotion).</em>
    </Heading6>
    <Text>
      Approved customers only. Conditions, fees and charges apply. Available on
      advertised or ticketed price upon request. Minimum spends apply. Payable
      in equal monthly payments (exact amounts specified in your statement). At
      the end of the plan, interest will be charged at 24.99% p.a. Account
      service fee of $9.95 per month applies.
      <br />
      <br />
      For plans with a term less than 33 months, the minimum monthly payment
      will be greater than an interest free payment for the same term and you
      may pay more interest on any other outstanding balance on your credit card
      account, as your repayments will be allocated to this plan first. If you
      don't make your minimum monthly payment, for two consecutive months, you
      agree that all equal monthly payment plans (Instalment Interest Free
      Promotion) with a term less than 33 months will change to a minimum
      monthly payment plan (Interest Free Promotion) for the remainder of the
      original plan term. Interest may apply on any outstanding balance upon
      expiry of the promotional period.
    </Text>
  </Box.Section>
);

const MinimumTermsSection = () => (
  <Box.Section backgroundColor={COLOR.GREY6} isBodyCopy id="minimum-monthly">
    <Heading6>
      Interest Free with Minimum Monthly Payment plans{' '}
      <em>(Interest Free Promotion).</em>
    </Heading6>
    <Text>
      Approved customers only. Conditions, fees and charges apply. Minimum
      spends apply. Monthly payments must be made during the promotional period.
      Interest and payments are payable after the interest free period expires.
      Paying only the minimum monthly payment will not pay out the purchase
      before the end of the plan. At the end of the plan, interest will be
      charged at 24.99% p.a. Account service fee of $9.95 per month applies.
    </Text>
  </Box.Section>
);

const FlexibleTermsSection = () => (
  <Box.Section backgroundColor={COLOR.GREY6} isBodyCopy id="flexible">
    <Heading6>
      Interest Free with Flexible Payment plans{' '}
      <em>(Buy Now Pay Later Promotion).</em>
    </Heading6>
    <Text>
      Approved customers only. Conditions, fees and charges apply. Minimum
      spends apply. Interest and payments are payable on any outstanding balance
      after the plan expires. At the end of the plan, interest will be charged
      at 24.99% p.a. Account service fee of $9.95 per month applies.
    </Text>
  </Box.Section>
);

export default TermsAndConditions;
