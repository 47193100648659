import React from 'react';

import Section from '@latitude/section';
import './pageHeader.css';
import { Link } from '@latitude/link';
import { BUTTON_STYLE } from '@latitude/core/utils/constants';

function PageHeaderSection({
  title,
  subTitle,
  pageImage,
  pageImageAlt = '',
  buttonProps,
  children
}) {
  return (
    <Section className="lfs-pageheader">
      <div className="lfs-pageheader-item">
        <div className="lfs-pageheader-image">
          <img src={pageImage} alt={pageImageAlt} />
        </div>

        <div className="lfs-pageheader-text">
          <h1 className="lfs-pageheader-title">{title}</h1>
          {subTitle && (
            <div className="lfs-pageheader-subtitle">{subTitle}</div>
          )}
          {buttonProps && (
            <Link
              className="lfs-pageheader-button"
              href={buttonProps.href}
              trackId={buttonProps.trackid || ''}
              button={buttonProps.button ?? BUTTON_STYLE.TERTIARY}
            >
              {buttonProps.btnText}
            </Link>
          )}
          {children && <div className="lfs-pageheader-content">{children}</div>}
        </div>
      </div>
    </Section>
  );
}

export default PageHeaderSection;
