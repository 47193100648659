import React from 'react';
import { InfoCard } from '@latitude/info-card';
import { Text } from '@latitude/text';
import { Link } from '@latitude/link';
import { Box } from '@latitude/box';
import { Vertical } from '@latitude/spacing';

const CorporateResponsibilityModernSlavery = () => {
  return (
    <Box paddingBottom="30px">
      <InfoCard bodyBGColor="#E8F4FF" heading="Modern Slavery Statement">
        <Vertical spacing="24px">
          <Box>
            <Text align="center">
              This statement has been published in accordance with the{' '}
              <em>Modern Slavery Act 2018</em>. It sets out the steps taken by
              Latitude during the year ending 31&nbsp;December&nbsp;2023 to
              ensure that modern slavery and human trafficking are not operating
              within its business and supply chain.
            </Text>
            <Link
              button="tertiary"
              href="https://assets.latitudefinancial.com/corporate-responsibility/modern-slavery-statement.pdf"
              trackId="corporate-responsibility-modern-slavery"
              width="200px"
              css={`
                margin: 20px auto 0;
              `}
            >
              Read more
            </Link>
          </Box>
        </Vertical>
      </InfoCard>
    </Box>
  );
};

export default CorporateResponsibilityModernSlavery;
