import React from 'react';
import HowToApplyComponent from '@latitude/how-to-apply';
import { ListNumbered } from '@latitude/list-numbered';
import { Bold } from '@latitude/text';
import { Box } from '@latitude/box';
import { BREAKPOINT, COLOR, MARGIN } from '@latitude/core/utils/constants';
import { Heading4 } from '@latitude/heading';
import { List, ListItem } from '@latitude/list';
import { Vertical } from '@latitude/spacing';

const HowToApply = () => (
  <div id="how-to-apply">
    <div id="eligibility">
      <Box
        backgroundColor={COLOR.GREY6}
        css={`
          padding: 16px 0;
          @media (min-width: ${BREAKPOINT.LG}) {
            padding: 48px 0;
          }
        `}
      >
        <HowToApplyComponent
          box1={
            <Vertical spacing={MARGIN.M16}>
              <Heading4 color={COLOR.BLACK} isResponsive={false}>
                Let&apos;s do this.
              </Heading4>
              <div
                css={`
                  ol li {
                    align-items: flex-start;
                  }
                `}
              >
                <ListNumbered
                  data={[
                    '<div><strong>Apply online</strong><br />You can check your eligibility and apply in 10 minutes.</div>',
                    '<div><strong>Wait a minute</strong><br />60 seconds is all it takes for us to respond to your application.</div>',
                    '<div><strong>Ready, set, shop</strong><br />Set up your card in your digital wallet and start shopping today!</div>'
                  ]}
                  separator="none"
                  counterBgColor={COLOR.BLUE_DEEP}
                />
              </div>
            </Vertical>
          }
          box2={
            <Vertical spacing={MARGIN.M16}>
              <Heading4 color={COLOR.BLACK} isResponsive={false}>
                Eligibility
              </Heading4>
              <Vertical spacing={MARGIN.M8}>
                <Bold>You must be:</Bold>
                <List>
                  <ListItem>Over 18 years of age</ListItem>
                  <ListItem>A resident of Australia or a temporary resident who legally has the right to work in Australia (and intend to stay) for at least the next 12 months.</ListItem>
                </List>
              </Vertical>
              <Vertical spacing={MARGIN.M8}>
                <Bold>Able to supply:</Bold>
                <List>
                  <ListItem>Your driver&apos;s licence, passport or Medicare card</ListItem>
                  <ListItem>Details of your income, living expenses & debts</ListItem>
                </List>
              </Vertical>
            </Vertical>
          }
        />
      </Box>
    </div>
  </div>
);

export default HowToApply;
