import React from 'react';
import classnames from 'classnames';
import styled, { css } from 'styled-components';
import ReactHTMLParser from 'react-html-parser';
import Accordion from '../Accordion/Accordion';
import SelectList from '../SelectList/SelectList';
import {
  CARD_COLOR,
  COLOR,
  BREAKPOINT,
  FONT_FAMILY
} from '../../utils/constants';
import './_payment-options.scss';

class PaymentOptions extends React.Component {
  state = {
    activeTab: 0
  };

  setActiveTab = id => {
    this.setState({ activeTab: id });
  };

  render() {
    return (
      <div className={classnames('payment-options', this.props.className)}>
        <PaymentOptionsAccordion data={this.props.data} />
        <PaymentOptionsTabs
          data={this.props.data}
          activeTab={this.state.activeTab}
          setActiveTab={this.setActiveTab}
          tabContentMinHeight={this.props.tabContentMinHeight}
        />
      </div>
    );
  }
}

/* Accordion - mobile only
 *
 */
const PaymentOptionsAccordion = props => (
  <div className="payment-options-accordion">
    <Accordion
      titleBgColor="white"
      contentBgColor="white"
      items={props.data.map(item => ({
        id: item.id,
        title: ReactHTMLParser(item.title),
        content: (
          <div className="payment-options-accordion__content">
            {item.content}
          </div>
        )
      }))}
    />
  </div>
);

/* Tabs - desktop only
 *
 */
const PaymentOptionsTabs = props => (
  <div className="payment-options-tabs">
    <SelectList
      className="payment-options-tabs__tabs select-list--horizontal"
      data={props.data.map(item => item.title)}
      onClick={props.setActiveTab}
    />

    <div
      className="payment-options-tabs__content"
      style={{ minHeight: props.tabContentMinHeight || 0 }}
    >
      {props.data.map((item, i) => (
        <div
          className={classnames(
            'payment-options-tabs__item',
            {
              'payment-options-tabs__item--active': props.activeTab == i
            },
            {
              'payment-options-tabs__item--betterer-image': item.bettererImage
            }
          )}
          key={`payment-options-tabs__item-${i}`}
        >
          {item.image && (
            <div className="payment-options-tabs__image">
              <img
                src={item.image}
                className="payment-options-tabs__img"
                alt=""
              />
            </div>
          )}

          {item.content}
        </div>
      ))}
    </div>
  </div>
);

export const PaymentOptionsColumn = styled.div`
  font-size: 14px;
  line-height: 1.5;
  max-width: ${props => (props.longColumn ? 'none' : '320px')};
  margin: 0 auto 24px;
  text-align: left;

  @media (min-width: ${BREAKPOINT.MD}) {
    margin-bottom: 0;
  }

  ${props =>
    props.longColumn
      ? css`
          flex-grow: 1;
          flex-basis: 50%;
        `
      : css``};
`;

export const PaymentOptionTitle = styled.h4`
  font-size: 24px;
  font-family: ${FONT_FAMILY.TITLE};
  font-weight: 600;
  line-height: 1.33;
  margin: 0;
  color: ${COLOR.BLACK};
  text-align: center;
`;

export const PaymentOptionDescription = styled.p`
  margin: 8px auto 32px;
  font-size: 18px;
  font-weight: 300;
  line-height: 1.5;
  text-align: center;
  max-width: 240px;
`;

export const PaymentOptionImages = styled.div`
  display: flex;
  margin: 0 auto 32px;
  justify-content: center;

  > a {
    display: inline-flex !important;
    margin-right: 8px;
    border: 0 !important;
    flex: 1 1 auto;
  }
  img {
    display: block;
    border: 0;
    width: 100%;
    height: auto;
  }
`;

export const PaymentOptionButtons = styled.div`
  display: flex;
  margin: 0 0 32px;

  > a.button {
    font-size: 16px;
    font-weight: 500;
    margin-right: 10px;

    &:last-child {
      margin: 0;
    }
  }
`;

export const PaymentOptionNote = styled.div``;

export const PaymentOptionCallout = styled.div`
  background-color: ${COLOR.WHITE};
  padding: 12px 24px;
  margin: 24px 0 0;

  > p {
    margin: 0;
  }

  @media (min-width: ${BREAKPOINT.LG}) {
    margin: 12px -24px 0;
  }
`;

export const PaymentOptionsContactDetails = styled.div`
  text-align: center;
  margin: 0 auto 32px;
  max-width: 300px;

  @media (min-width: ${BREAKPOINT.LG}) {
    display: flex;
    align-items: center;
    margin-left: 0;
    margin-right: 0;
    margin-top: 16px;
    max-width: none;
    justify-content: flex-start;
    text-align: left;
  }

  .button {
    font-size: 15px;
    font-weight: 500;
    margin: 0 auto 16px;

    @media (min-width: ${BREAKPOINT.LG}) {
      margin: 0 32px 0 0;
    }
  }

  > p {
    max-width: 350px;
    text-align: center;

    @media (min-width: ${BREAKPOINT.LG}) {
      text-align: left;
    }
  }
`;

export const PaymentOptionList = styled.ul`
  text-align: left;
  padding: 0 0 0 48px;
  counter-reset: section;
  margin: 10px 0 16px;

  @media (min-width: ${BREAKPOINT.MD}) {
    margin: 10px 0 32px;
  }
`;

export const PaymentOptionListItem = styled.li`
  position: relative;
  margin: 0 auto 16px;
  list-style-type: none;
  min-height: 42px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &:before {
    counter-increment: section;
    content: counter(section);
    width: 32px;
    height: 32px;
    border-radius: 50%;
    position: absolute;
    top: 4px;
    left: -48px;
    display: flex;
    background-color: ${CARD_COLOR.BLUE_DARK};
    color: ${COLOR.WHITE};
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: 600;
  }

  > p {
    margin: 0;
  }
`;

export const PaymentOptionIcon = styled.div`
  width: 98px;
  height: 98px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const PaymentOptionHeading = styled.h6`
  color: ${CARD_COLOR.BLUE_DARK};
  font-size: 16px;
  font-weight: 600;
  line-height: 2;
  margin: 0;
`;

const StyledPaymentOption = styled(PaymentOptions)`
  .accordion__item {
    > button {
      background-color: ${CARD_COLOR.BLUE_LIGHT} !important;
      color: ${CARD_COLOR.BLUE_DARK};
    }

    .chevron {
      .chevron-line.icon-line--style {
        stroke: ${CARD_COLOR.BLUE_DARK};
      }
    }
  }

  .payment-options-accordion__content {
    background-color: ${CARD_COLOR.BLUE_LIGHTER};
    padding: 32px 16px;
    text-align: center;
  }

  .payment-options-tabs__item {
    width: 100%;
  }

  .payment-options-tabs__content {
    padding: 32px 32px 72px;

    ${PaymentOptionsColumn} {
      flex: 1 1 33%;

      @media (min-width: ${BREAKPOINT.LG}) {
        margin-left: 36px;
        margin-right: 0;

        &:nth-child(2) {
          &:last-child {
            margin-left: auto;
            margin-right: 0;
          }
        }
      }

      @media (min-width: ${BREAKPOINT.XL}) {
        margin-left: 72px;
      }

      &:first-child {
        margin-left: 0;
      }
    }

    ${PaymentOptionCallout} {
      margin-right: -12px;
    }
  }
`;

export default StyledPaymentOption;
