import React from 'react';
import PersonalLoanPageContentLEFWaiver from '@/components/PersonalLoanPageContent/PersonalLoanPageContentLEFWaiver';

const PersonalLoanPage = props => {
  return (
    <PersonalLoanPageContentLEFWaiver
      {...props}
      title="Personal Loan - Establishment Fee Waiver"
      description="Whether your dream is to renovate your home or go travelling, Latitude's personal loan can help make that come true faster. Apply in less than 10 minutes."
    />
  );
};

export default PersonalLoanPage;
