/* eslint-disable import/no-unresolved */
import React from 'react';
import Helmet from 'react-helmet';
import styled from 'styled-components';

import { Heading4 } from '@latitude/heading';
import {
  BREAKPOINT,
  COLOR,
  ALIGN,
  FONT_SIZE,
  MARGIN
} from '@latitude/core/utils/constants';
import { Text } from '@latitude/text';

import Layout from '@/components/layout';
import HeroBranded from '@/components/HeroBranded/HeroBranded';
import { PromoSection } from '@/components/Promo/Promo';

import heroImageTablet from '../../images/blue-accent-banner.webp';
import heroImageDesktop from '../../images/blue-accent-banner.webp';
import mobileHeroImage from '../../images/blue-accent-banner-mobile.webp';

import '../latitude-cyber-incident.css';
import { Link } from '@latitude/link';

const StyledHeroBranded = styled(HeroBranded)`
  background-image: url(${mobileHeroImage});
  background-size: cover;
  background-position: center center;
  background-color: #cfeafe;
  background-repeat: no-repeat;
  height: 100%;
  .HeroContent {
    background-color: #cfeafe;
    width: 100%;
  }
  @media (max-width: ${BREAKPOINT.MD}) {
    [class^='HeroBranded__HeroImage'] {
      display: none;
    }

    .HeroContent {
      background-color: transparent;
      height: 320px;
      padding: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;

      > div {
        margin: 0 0 0 21px;
      }
    }
  }

  @media (min-width: ${BREAKPOINT.MD}) {
    background-image: url(${heroImageTablet});
    background-size: cover;
    height: 300px;
    .HeroContent {
      background-color: transparent;
    }
  }
  @media (min-width: ${BREAKPOINT.LG}) {
    height: 320px;
  }
  @media (min-width: ${BREAKPOINT.XL}) {
    background-image: url(${heroImageDesktop});
  }
`;

const StyledPromoSection = styled(PromoSection)`
  background-color: ${COLOR.GREY6};
`;

const StyledText = styled(Text)`
  font-size: ${FONT_SIZE.LARGE};
  margin-bottom: ${MARGIN.M24};
  margin-left: auto;
  margin-right: auto;
  text-align: left;
  background-color: white;
  @media (min-width: ${BREAKPOINT.MD}) {
    max-width: 730px;
  }
`;

const CyberResponsePage = props => {
  return (
    <Layout location={props.location}>
      <main className="navigation-spacer">
        <Helmet>
          <link
            rel="canonical"
            href="https://www.latitudefinancial.com.au/latitude-cyber-incident/"
          />
          <title>Latitude Financial Services | Cyber Response</title>
          <meta
            name="description"
            content="Latitude Cyber Incident, Latitude Financial Services"
          />
        </Helmet>

        <StyledHeroBranded
          title="Latitude Cyber Response"
          text="Information, updates and support for those affected"
        />

        <StyledPromoSection
          css={`
            background-color: white;
          `}
        >
          <Heading4
            color={COLOR.BLACK}
            align={ALIGN.CENTER}
            css={`
              margin-left: auto;
              margin-right: auto;
              background-color: white;
              @media (min-width: ${BREAKPOINT.MD}) {
                max-width: 730px;
              }
            `}
          >
            Cyber Attack
          </Heading4>
          <StyledText>
            In March 2023, Latitude experienced a malicious cyber-attack that
            resulted in the theft of customer data.
          </StyledText>
          <StyledText>
            Since that time, Latitude has been working hard to support people
            whose data has been affected.
          </StyledText>
          <StyledText>
            Details of the cyber-attack and the available support can be found{' '}
            <Link href="/latitude-cyber-incident/">here</Link>.
          </StyledText>

          <hr />
          <div
            css={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              flexWrap: 'wrap',
              gap: 0,
              width: '100%',
              padding: '10pt 20pt'
            }}
          >
            <div css={{ flexGrow: '2', padding: '5pt 10pt' }}>
              <StyledText>
                <strong>What's happened?</strong>
                <ul>
                  <li>
                    <Link
                      href="/cyber-statement-of-notification/"
                      trackId="statement-of-notification"
                    >
                      Statement of Notification
                    </Link>
                  </li>
                  <li>
                    <Link
                      href="/latitude-cyber-incident/"
                      trackId="information-and-support"
                    >
                      Information and support
                    </Link>
                  </li>
                  <li>
                    <Link
                      href="/latitude-id-information/"
                      trackId="impacted-information"
                    >
                      Impacted information
                    </Link>
                  </li>
                  <li>
                    <Link
                      href="https://latitudefs.zendesk.com/hc/en-au/articles/13777669694225-Latitude-Cyber-Incident-Update"
                      trackId="faqs"
                    >
                      FAQs
                    </Link>
                  </li>
                </ul>
              </StyledText>
            </div>
            <div css={{ flexGrow: '2', padding: '5pt 10pt' }}>
              <StyledText>
                <strong>I've been Affected</strong>
                <ul>
                  <li>
                    <Link
                      href="/latitude-id-information/identity-information/"
                      trackId="personal-details-and-id-numbers"
                    >
                      Personal details and ID numbers
                    </Link>
                  </li>
                  <li>
                    <Link
                      href="/latitude-id-information/identity-images/"
                      trackId="id-images"
                    >
                      ID images
                    </Link>
                  </li>
                  <li>
                    <Link
                      href="/latitude-id-information/identity-images#reimbursement-claims"
                      trackId="reimbursement-of-id"
                    >
                      Reimbursement of ID
                    </Link>
                  </li>
                </ul>
              </StyledText>
            </div>
          </div>
        </StyledPromoSection>
      </main>
    </Layout>
  );
};

export default CyberResponsePage;
