import React, { useEffect } from 'react';

function ZendeskRedirectPage({ location }) {

  useEffect(() => {
    if (window?.location) {
      window.location.replace('https://latitudefs.zendesk.com/hc/en-au/articles/25970394220689-28-Degrees-Value-Prop-Change-Service');
    }
  }, []);

  return <></>;
}

export default ZendeskRedirectPage;
