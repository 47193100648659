/* eslint-disable import/extensions, import/no-unresolved */
import React from 'react';
import { FONT_SIZE } from '@latitude/core/utils/constants';
import { StaticImage } from 'gatsby-plugin-image';

import {
  StyledHeroBranded,
  ImageBox,
  LeaderDetailsWrapper,
  DetailsContainer,
  StyledText,
  LeadershipPageLayout
} from '../../../components/Leadership';

export default props => {
  const title = 'Karl Hoffman';
  const content =
    'Executive General Manager, Corporate Strategy & Transformation';

  return (
    <LeadershipPageLayout title={title} {...props}>
      <StyledHeroBranded
        title={title}
        text={content}
        breadcrumbs={[
          {
            name: 'About us',
            href: '/about-us/'
          },
          {
            name: 'Our company',
            href: '/about-us/our-company/'
          },
          {
            name: 'karl-hoffman'
          }
        ]}
        imageContent={
          <ImageBox>
            <StaticImage
              alt={title}
              src="../images/karl-hoffman.webp"
              width={450}
              quality={100}
              placeholder="dominantColor"
            />
          </ImageBox>
        }
      />
      <LeaderDetailsWrapper>
        <DetailsContainer display="flex">
          <StyledText fontSize={FONT_SIZE.LARGE}>
            Karl is Executive General Manager, Corporate Strategy &amp;
            Transformation, a position to which he was appointed in May 2023,
            having led strategy and performance in the Money division. Before
            that, Karl was CFO and Head of Corporate Strategy at Symple Loans
            which Latitude acquired in 2021.
            <br />
            <br />
            Karl has over 20 years of international and local experience in
            financial services. He began his career in asset management with
            Merrill Lynch and Partners Capital Group in London where he managed
            institutional investment strategies for ultra-high net worth clients
            and endowment funds. After moving to Australia in 2008, Karl worked
            as a strategy consultant at Bain & Company and then joined ANZ's
            Group Strategy team leading numerous growth and optimization
            initiatives across ANZ's Retail, Business and Institutional banking
            divisions.
          </StyledText>
          <StyledText fontSize={FONT_SIZE.LARGE}>
            In 2017, Karl was appointed General Manager of Credit Cards &
            Personal Lending at ANZ and was responsible for the management of
            all aspects of the bank's consumer unsecured lending businesses.
            <br />
            <br />
            Karl holds a Bachelors degree in Business Administration from Simon
            Fraser University in Canada, a MBA from Melbourne Business School
            and qualified as a CFA Charterholder in 2007.
          </StyledText>
        </DetailsContainer>
      </LeaderDetailsWrapper>
    </LeadershipPageLayout>
  );
};
