import React from 'react';
import PropTypes from 'prop-types';
import Image from '../Image/Image';

import './_image-tile.scss';

const ImageTile = props => {
  const className = `bg-blue image-tile ${props.className || ''}`;
  return (
    <div className={className}>
      {props.title && <h4 className="image-tile__title">{props.title}</h4>}
      <div className="image-tile__image" style={{ backgroundImage: `url("${props.image.default}")` }} />
    </div>
  )
};

ImageTile.propTypes = {
  image: PropTypes.string,
  title: PropTypes.string,
}

export default ImageTile;
