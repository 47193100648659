import React from 'react';
import classnames from 'classnames';
import { Button } from '@latitude/button';
import Link from 'gatsby-link';
import { Link as LLink } from '@latitude/link';
import { Heading2 } from '@latitude/heading';
import { ALIGN, COLOR, BREAKPOINT } from '@latitude/core/utils/constants';

import Slide from '@latitude/slide';
import Section from '@latitude/section';
import ImageTile from '@/components/ImageTile/ImageTile';
import Slider from '../../../../components/Slider/Slider';
import BoxSlider from '@/components/BoxSlider/BoxSlider';


const TravelInspirationCategories = props => {
  return(<Section
    id="inspiration"
    className={classnames(props.darkBg && 'bg-blue', props.className)}
    css={`
      @media (min-width: ${BREAKPOINT.LG}) {
        &.section {
          padding-top: 72px;
          padding-bottom: 72px;
        }
      }

      & > .container {
        padding: 0;
      }
    `}
  >
    <Heading2
      align={ALIGN.CENTER}
      color={COLOR.BLACK}
      css={`
        font-size: 24px;
        @media (min-width: ${BREAKPOINT.LG}) {
          font-size: 32px;
        }
      `}
    >
      Find Your Inspiration
    </Heading2>

    <BoxSlider
      gallery
      darkBg={props.darkBg}
      css={`
        margin-bottom: 24px;

        .slick-dots {
          position: static;
          margin-top: 16px;

          li button:before {
            color: #d0d0d0;
            border-color: transparent;
            font-size: 12px;
            opacity: 1;
          }

          li.slick-active button:before {
            color: #006aff;
          }
        }
      `}
    >
      {[
        {
          tags: 'adventure',
          title: 'Adventure',
          thumbnail: 'adventure@2x.png'
        },
        {
          tags: 'culture',
          title: 'Culture',
          thumbnail: 'culture@2x.png'
        },
        {
          tags: 'relax',
          title: 'Relaxing',
          thumbnail: 'relaxing@2x.png'
        },
        {
          tags: 'shopping',
          title: 'Shopping',
          thumbnail: 'shopping@2x.png'
        },
        {
          tags: 'tips',
          title: 'Travel Tips',
          thumbnail: 'travel-tips@2x.png'
        }
      ].map(category => (
        <Slide key={category.tags}>
          <Link
            to={`travel-inspiration?filter=${category.tags}`}
            className="link--no-style"
            style={{ textDecoration: 'none' }}
          >
            <ImageTile
              title={category.title}
              image={require(`../../../../images/${category.thumbnail}`)}
            />
          </Link>
        </Slide>
      ))}
    </BoxSlider>

    <LLink
      href="/credit-cards/28-degrees/travel-inspiration"
      button="tertiary"
      css="margin: 0 auto;"
      disableDefaultEventTracking
      width="160px"
      onClick={() => {
        window.open('/credit-cards/28-degrees/travel-inspiration', '_self');
      }}
    >
      Explore all
    </LLink>
  </Section>)
};

export default TravelInspirationCategories