/* eslint-disable import/extensions, import/no-unresolved */
import React from 'react';
import { FONT_SIZE } from '@latitude/core/utils/constants';
import { StaticImage } from 'gatsby-plugin-image';

import {
  StyledHeroBranded,
  ImageBox,
  LeaderDetailsWrapper,
  DetailsContainer,
  StyledText,
  LeadershipPageLayout
} from '../../../components/Leadership';

export default props => {
  const title = 'Bob Belan';
  const content = 'Managing Director & CEO';

  return (
    <LeadershipPageLayout title={title} {...props}>
      <StyledHeroBranded
        title={title}
        text={content}
        breadcrumbs={[
          {
            name: 'About us',
            href: '/about-us/'
          },
          {
            name: 'Our company',
            href: '/about-us/our-company/'
          },
          {
            name: 'Bob Belan'
          }
        ]}
        imageContent={
          <ImageBox>
            <StaticImage
              alt={title}
              src="../images/bob-belan.webp"
              width={450}
              quality={100}
              placeholder="dominantColor"
            />
          </ImageBox>
        }
      />
      <LeaderDetailsWrapper>
        <DetailsContainer display="flex">
          <StyledText fontSize={FONT_SIZE.LARGE}>
            Bob was appointed Managing Director and CEO of Latitude Financial
            Services in April 2023.
            <br />
            <br />
            He was previously responsible for leading Latitude Money, Latitude's
            personal lending division as Executive General Manager, Money. Prior
            to that, Bob was co-founder and CEO of Symple loans which was
            acquired by Latitude in 2021.
          </StyledText>
          <StyledText fontSize={FONT_SIZE.LARGE}>
            With over 20 years of global management experience, Bob has held
            executive roles at major financial services firms including American
            Express, JPMorgan Chase, and ANZ Banking Group leading large scale
            consumer payment and lending businesses. He holds a Bachelor of Arts
            degree from The University of Western Ontario in Canada and a MBA
            from Columbia Business School in New York City.
          </StyledText>
        </DetailsContainer>
      </LeaderDetailsWrapper>
    </LeadershipPageLayout>
  );
};
