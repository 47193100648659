import * as React from 'react';
import styled from 'styled-components';
import ReactHTMLParser from 'react-html-parser';
import { Link } from '@latitude/link';
import { BUTTON_STYLE } from '@latitude/core/utils/constants';
import SvgInline from '../SvgInline/SvgInline';
import { Box } from '../Box/Box';
import { Heading4, Heading5 } from '../Heading/Heading';
import Text from '../Text/Text';
import {
  BREAKPOINT,
  MARGIN,
  ALIGN,
  COLOR,
  TEXT_VARIANT,
  FONT_SIZE,
  DISPLAY,
  GUTTER,
  JUSTIFY_CONTENT,
  PADDING
} from '../../utils/constants';

const Heading = styled(Heading4)`
  color: ${COLOR.BLACK};

  ${({ subheading }) =>
    subheading
      ? `margin-bottom: ${MARGIN.M8};`
      : `margin-bottom: ${MARGIN.M24};
         @media (min-width: ${BREAKPOINT.SM}) {
           margin-bottom: ${MARGIN.M40};
         }`}
`;

const Subheading = styled(Text)`
  text-align: ${ALIGN.CENTER};
  color: ${COLOR.GREY75};
  margin: 0 auto ${MARGIN.M24};
  max-width: 750px;
  @media (min-width: ${BREAKPOINT.SM}) {
    margin-bottom: ${MARGIN.M32};
  }
`;

export const FeatureTilesWrapper = styled(Box)`
  overflow: hidden;
  margin: -${GUTTER.G15} 0;

  // change layout to grid from stacking list
  @media (min-width: ${BREAKPOINT.MD}) {
    display: flex;
    flex-flow: row wrap;
    align-items: stretch;
    justify-content: center;
    margin: -${GUTTER.G15};
  }
`;

const FeatureTiles = ({
  id,
  heading,
  headingAlign = ALIGN.CENTER,
  subheading,
  bgColor,
  tiles,
  tileColumns
}) => {
  return (
    <Box.Section
      id={id}
      backgroundColor={bgColor || COLOR.GREY6}
      paddingTop={PADDING.P32}
      paddingBottom={PADDING.P32}
    >
      {heading && (
        <Heading align={headingAlign} subheading={!!subheading}>
          <span>{ReactHTMLParser(heading)}</span>
        </Heading>
      )}
      {subheading && (
        <Subheading variant={TEXT_VARIANT.DIV} fontSize={FONT_SIZE.LARGE}>
          {subheading}
        </Subheading>
      )}
      <FeatureTilesWrapper>
        {tiles.map((tile, index) => (
          <FeatureTile
            key={index}
            icon={tile.icon}
            title={tile.title}
            description={tile.content}
            buttonHref={tile.ctaHref}
            buttonText={tile.ctaLabel}
            buttonTrackId={tile.ctaTrackId || ''}
            tileColumns={tileColumns}
          />
        ))}
      </FeatureTilesWrapper>
    </Box.Section>
  );
};

const featureTileBaseWidth = '350px';
const StyledFeatureTile = styled(Box)`
  background: ${COLOR.WHITE};
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.07);
  position: relative;
  width: 100%;
  max-width: ${featureTileBaseWidth};
  margin: ${GUTTER.G15} auto;
  padding-bottom: 80px; // this is based on single line CTA height
  z-index: 1;

  @media (min-width: ${BREAKPOINT.MD}) {
    flex: 1 1 auto;
    width: auto;
    margin: ${GUTTER.G15};
  }
`;

const StyledHeading5 = styled(Heading5)`
  font-size: 24px;
  line-height: 32px;
`;

const ButtonWrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: auto;
  margin: 0 auto;
  border-bottom: 32px solid transparent;

  // override anchor styles
  a.button {
    font-size: 16px;
    text-align: center;
    padding-left: 24px;
    padding-right: 24px;
    white-space: nowrap;
  }
`;

const FeatureTile = ({
  icon,
  title,
  description,
  buttonText,
  buttonHref,
  buttonTrackId
}) => {
  return (
    <StyledFeatureTile>
      <Box alignContent={ALIGN.CENTER} padding={PADDING.P24}>
        <Box
          margin={`${MARGIN.M24} 0 0`}
          display={DISPLAY.FLEX}
          justifyContent={JUSTIFY_CONTENT.CENTER}
        >
          <SvgInline name={icon} align={ALIGN.CENTER} />
        </Box>
        <StyledHeading5
          color={COLOR.BLACK}
          align={ALIGN.CENTER}
          marginTop={MARGIN.M16}
          marginBottom={MARGIN.M16}
        >
          {ReactHTMLParser(title)}
        </StyledHeading5>
        <Text align={ALIGN.CENTER}>{ReactHTMLParser(description)}</Text>
      </Box>
      <ButtonWrapper>
        <Link
          className="button"
          href={buttonHref}
          trackId={buttonTrackId || ''}
          button={BUTTON_STYLE.TERTIARY}
          trackEventData={{
            location: title.replace(/<[^>]+>/g, '')
          }}
        >
          {buttonText}
        </Link>
      </ButtonWrapper>
    </StyledFeatureTile>
  );
};

export default FeatureTiles;
export { FeatureTile };
