import React, { useContext } from 'react';
import queryString from 'query-string';
import Helmet from 'react-helmet';
import Layout from '../components/layout';
import Hero from '../components/Hero/Hero';
import SearchResultList from '../components/SearchResultList/SearchResultList';
import useFetchSearchResults from '../hooks/useFetchSearchResults';
import { Loading } from '../components/Loading/Loading';
import { Box } from '../components/Box/Box';
import { MARGIN, PADDING, COLOR } from '../utils/constants';
import utilityHero from '../images/hero/utility-hero.jpg';
import { PageContext } from '@/context/PageContext';
import HeroBanner from '@/components/lab-components/HeroBanner';

const SearchPage = ({ location }) => {
  /** Contentful Data */
  const contentfulPageData = useContext(PageContext);
  const state = contentfulPageData?.updatedBody || {};
  /** - END - */

  let subTitle;
  const queryParams = queryString.parse(location.search);
  const searchQuery = queryParams.q ? queryParams.q : '';
  const { results, isFetching, hasServerErrored } = useFetchSearchResults(
    searchQuery
  );

  if (hasServerErrored) {
    subTitle =
      'The server encountered a temporary error and could not complete the search. Please try again in 30 seconds.';
  } else if (!searchQuery) {
    subTitle = 'No search term was entered';
  } else if (!isFetching) {
    if (results.length) {
      subTitle = `${results.length} results found for ‘${searchQuery}’`;
    } else {
      subTitle = `Sorry, we were unable to locate any search results for ‘${searchQuery}’`;
    }
  }

  const mostRelevantResults = results.slice(0, 3);
  const otherResults = results.slice(3, results.length);

  return (
    <Layout location={location}>
      <main className="navigation-spacer">
        <Helmet>
          <link
            rel="canonical"
            href="https://www.latitudefinancial.com.au/search/"
          />
          <meta
            name="description"
            content="Latitude Financial Services offers simple, flexible options to help you get ahead. From loans to credit cards to insurance, you can do better. Learn more."
          />
          <meta name="robots" content="noindex,nofollow" />
          <title>Credit Cards and Personal Loans | Latitude Financial</title>
        </Helmet>

        {state?.heroBannerData?.[0] ? (
          <HeroBanner {...state?.heroBannerData[0]} />
        ) : (
          <Hero
            h1="Results"
            darkBg
            className="hero--home"
            bg={utilityHero}
          >
            <h5 className="text--invert">
              {subTitle || <React.Fragment>&nbsp;</React.Fragment>}
            </h5>
          </Hero>
        )}
        
        <Box
          isResponsive
          isBodyCopy
          margin={`0 ${MARGIN.MAUTO}`}
          padding={`${PADDING.P32} 0 0`}
        >
          {isFetching ? (
            <Box paddingTop={MARGIN.M32} paddingBottom={MARGIN.M32}>
              <Loading />
            </Box>
          ) : (
            <SearchResultList
              title="Most Relevant"
              results={mostRelevantResults}
            />
          )}
        </Box>
        {otherResults.length > 0 && (
          <Box backgroundColor={COLOR.GREY5}>
            <Box
              isResponsive
              isBodyCopy
              margin={`0 ${MARGIN.MAUTO}`}
              padding={`${PADDING.P32} 0 0`}
            >
              <SearchResultList title="More results" results={otherResults} />
            </Box>
          </Box>
        )}
      </main>
    </Layout>
  );
};

export default SearchPage;
