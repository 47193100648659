import React from 'react';
import { Link } from '@latitude/link';

const SearchResultList = props => {
  const { title, results } = props;
  return (
    <React.Fragment>
      {title && results.length > 0 && <h4 className="mt-0 mb-3">{title}</h4>}
      <ul className="list-unstyled pl-0 mt-0 mb-0 pt-1 pb-2">
        {results.map(result => (
          <li key={result.id} className="mb-4">
            <Link
              className="text--large d-inline pb-1"
              trackId="search-result-link"
              href={`/${result.url}`}
              trackEventData={{
                location: title
              }}
            >
              {result.metaTitle}
            </Link>
            <p className="my-0 pb-1 mt-1">{result.metaDescription}</p>
          </li>
        ))}
      </ul>
    </React.Fragment>
  );
};

export default SearchResultList;
