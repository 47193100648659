import React from 'react';
import classnames from 'classnames';
import LinesEllipsis from 'react-lines-ellipsis';
import './_card-title.scss';

const component = 'title';

const CardTitle = props => {
  const { isGreyBg, title, height, maxLines, textAlign } = props;

  return (
    <h2
      className={classnames(
        `${component}`,
        isGreyBg ? `title__grey-bg` : `title__blue-bg`
      )}
      style={{ height, textAlign }}
    >
      <LinesEllipsis
        text={title}
        maxLine={maxLines || '4'}
        ellipsis="..."
        trimRight
        basedOn="words"
      />
    </h2>
  );
};

export default CardTitle;
