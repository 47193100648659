import { useState } from 'react';
import { getRowTotalValue } from '../utilities';

const useRowState = (label, name, initialFrequency = 52) => {
  const [value, setValue] = useState(0);
  const [frequency, setFrequency] = useState(initialFrequency);

  const rowTotal = getRowTotalValue(value, frequency);

  const rowObject = {
    label,
    name,
    value,
    setValue,
    frequency,
    setFrequency,
    rowTotal
  };

  return rowObject;
};

export default useRowState;
