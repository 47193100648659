import * as React from 'react';
import classnames from 'classnames';
import styled from 'styled-components';
import { BREAKPOINT, COLOR } from '../../utils/constants';

export const CalloutOuter = styled.div`
  width: 100%;
  padding: 40px 15px;
  background-color: ${COLOR.BLUE_DARK};
  ${({ isResponsive }) =>
    isResponsive &&
    `@media (min-width: ${BREAKPOINT.XL}) {
      padding: 32px 0;
    }`}
`;

export const CalloutInner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 1140px;
  margin: 0 auto;
  ${({ isResponsive }) =>
    isResponsive &&
    `@media (min-width: ${BREAKPOINT.LG}) {
      flex-direction: row;
    }`}
`;

const Callout = ({ children, className, id, isResponsive = true }) => {
  const props = { id, isResponsive };
  return (
    <CalloutOuter className={classnames('callout', className)} {...props}>
      <CalloutInner {...props}>{children}</CalloutInner>
    </CalloutOuter>
  );
};

export default Callout;
