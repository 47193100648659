import React from 'react';

export default props => (
  <nav aria-label="breadcrumb" style={props.style}>
    <ol className="breadcrumb">
      <li className="breadcrumb-item">
        <a href="/">Home</a>
      </li>
      <li className="breadcrumb-item">
      <a href="/life-done-better">Life done better</a>
      </li>
      <li className="breadcrumb-item active" aria-current="page">
        {props.title}
      </li>
    </ol>
  </nav>
);
