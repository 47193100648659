import React from 'react';
import styled from 'styled-components';
import { Heading3 } from '@latitude/heading';
import { FONT_FAMILY } from '@latitude/core/utils/constants';

export const SectionTitle = styled(Heading3)`
  font-family: ${FONT_FAMILY.TITLE};
  font-size: 32px;
  font-weight: 600;
  line-height: 36px;
  text-align: center;
  margin: 0 0 20px 0;
  color: #000000;
`;

export const SectionSubtitle = styled.p`
  font-family: ${FONT_FAMILY.BODY};
  font-size: 18px;
  font-weight: 300;
  line-height: 28px;
  text-align: center;
  margin: 0 0 10px 0;
  color: #000000;
`;
