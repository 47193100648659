import * as React from 'react';
import './_callout.scss';

const component = 'callout';

const CalloutIcon = props => {
  const { icon, style } = props;

  return (
    <div className={`${component}__image`} style={style}>
      {icon}
    </div>
  );
};

export default CalloutIcon;
