import styled from 'styled-components';
import { Text } from '@latitude/text';
import { Box } from '@latitude/box';
import { COLOR, MARGIN, BREAKPOINT } from '../../utils/constants';

export const StyledContainer = styled.div`
  margin: auto;
  div,
  p {
    color: ${COLOR.BLACK};
  }
`;

export const StyledErrorMessage = styled(Text)`
  align-items: center;
  color: ${COLOR.TEXT_ERROR};
  margin-bottom: ${MARGIN.M16};

  svg {
    margin-right: ${MARGIN.M8};
  }
`;
export const NarrowContentContainer = styled(Box)`
  max-width: 730px;
  margin: auto;
`;

export const ContactUsTile = styled(Box)`
  justify-content: center;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.25);
  padding: 29px 37px;
  background-color: ${COLOR.WHITE};
  a {
    width: fit-content;
    margin: auto;
  }

  @media (max-width: ${BREAKPOINT.MD}) {
    :nth-child(2) {
      margin-top: 20px;
    }
  }
`;
