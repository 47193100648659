import React from "react";
import "./featureCardList.css";
import { Box } from "@latitude/box";
import cardImg from "../../images/credit-cards/28-global-platinum-front.png";
import { Link } from "@latitude/link";
import { BUTTON_STYLE } from "@latitude/core/utils/constants";
import { Text } from "@latitude/text";



const FeatureCardList = (props) => {
  const cardData = props.cardList;
  return (
    <Box>
      <div className="card-list">
        {cardData.map((card) => (
          <div className="card-container" key={card.id}>
            {/* Card Image and Title Section */}
            <div className="card-content">
              <img src={card.image.default} alt={card.title} className="card-image" />
              <div className="text-left">
                <h4 className="card-title">{card.title}</h4>
                <p className="card-subtext">{card.description}</p>
              </div>
            </div>

            {/* Card Body Section */}
            <div className="card-body">
              <div className={`${!card.offerBadge ? "dummy-height" : 'offer-badge'}`}>
                {card.offerBadge}
              </div>

              <h4 className="main-offer">{card.mainOffer}</h4>

              {/* Details Section */}
              <ul className="card-details">
                {card.details.map((detail, index) => (
                  <li key={index}>{detail}</li>
                ))}
              </ul>

              {/* Call-to-Action */}
              {/* Call-to-Action */}
              <div className="card-footer">
              {card.secBtn && (<a href={card.secBtn.href || ''} className="find-out-more">
                  {card.secBtn.btnText}
                </a>)}
                {card.buttonProps && (
                  <Link
                    className="cta-button"
                    href={card.buttonProps.href || ''}
                    trackId={card.buttonProps.trackid || ''}
                    button={card.buttonProps.button ?? BUTTON_STYLE.PRIMARY}
                  >
                    {card.buttonProps.btnText}
                  </Link>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
      <Text className="mt-5 text-left" css={`margin-left: 60px`}>All offers are subject to T&Cs.</Text>
    </Box>
  );
};

export default FeatureCardList;
