import useRowState from './useRowState';
import useCategoryState from './useCategoryState';

const useLivingExpensesState = () => {
  const rent = useRowState('Rent', 'rent');
  const rates = useRowState('Rates (council, water)', 'rates');
  const utilities = useRowState('Utilities (electric etc)', 'utilities');
  const phoneInternet = useRowState('Phone & Internet', 'phone-internet');
  const homeMaintenance = useRowState('Home Maintenance', 'home-maintenance');
  const groceries = useRowState('Groceries', 'groceries');
  const lunchesAndCoffee = useRowState('Lunches & Coffees', 'lunches-coffee');
  const medical = useRowState('Medical & Pharmaceutical', 'medical');
  const cosmetics = useRowState('Beauty & Cosmetics', 'cosmetics');
  const clothes = useRowState('Clothes', 'clothes');
  const education = useRowState(
    'Education (fees, uniforms, childcare)',
    'education'
  );
  const pets = useRowState('Pets', 'pets');
  const otherExpenses = useRowState('Other Expenses', 'other-expenses');

  const livingExpensesStateFields = [
    rent,
    rates,
    utilities,
    phoneInternet,
    homeMaintenance,
    groceries,
    lunchesAndCoffee,
    medical,
    cosmetics,
    clothes,
    education,
    pets,
    otherExpenses
  ];

  return useCategoryState(
    'living-expenses-accordion',
    'Living Expenses',
    livingExpensesStateFields
  );
};

export default useLivingExpensesState;
