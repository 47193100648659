import React, { useEffect } from 'react';

function GooglePayRedirectPage({ location }) {

  useEffect(() => {
    if (window?.location) {
      window.location.replace('/digital-wallets/google-pay/');
    }
  }, []);

  return <></>;
}

export default GooglePayRedirectPage;
