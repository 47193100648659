import React, { useEffect } from 'react';

const TARGET =
  'https://latitudefs.zendesk.com/hc/en-au/articles/27358280315025-EFTPOS-Removal-FAQs';

function ZendeskFaqRedirectPage({ location }) {
  useEffect(() => {
    if (window?.location) {
      window.location.replace(TARGET);
    }
  }, []);

  return <></>;
}

export default ZendeskFaqRedirectPage;
