import React, { useContext} from 'react';

import BrandedCallout from '@latitude/callout/BrandedCallout';
import { Link } from '@latitude/link';
import {
  BREAKPOINT,
  COLOR,
  PADDING,
  BUTTON_STYLE
} from '@latitude/core/utils/constants';

import StickyNavigationBranded from '@/components/StickyNavigation/StickyNavigationBranded';
import HorizontalRule from '@/components/HorizontalRule/HorizontalRule';
import Layout from '@/components/layout';
import HeroBranded from '@/components/HeroBranded/HeroBranded';
import Lframe from '@/components/Lframe/Lframe';
import Metadata from '@/components/Metadata/Metadata';
import { Box } from '@/components/Box/Box';

import {
  AppStoreBadge,
  AppStoreBadgeContainer
} from '@/components/AppStoreBadge/AppStoreBadge';
import { FeaturesSlider } from '@latitude/features-slider';

import PageData from '../data/pages/mobile-app.json';
import HowToGuidesSection from './mobile/_video-with-links';
import ImageSliderSection from './mobile/_image-slider';
import LinkYourCardSection from './mobile/_link-your-card';
import FaqsSection from './mobile/_faqs';
import { AnalyticsLocationProvider } from 'latitude-analytics';

import imgHero from './mobile/images/mobile-app-hero.webp';
import { PageContext } from '@/context/PageContext';

const MobileApp = props => {
  /** Contentful Data */
  const contentfulPageData = useContext(PageContext);
  const state = contentfulPageData?.updatedBody || {};

  const featureSliderData = [
    {
      heading: PageData.content.whyChooseLatitude.title,
      featureCards: PageData.content.whyChooseLatitude.tiles
    }
  ];
  /** - END - */

  return (
    <Layout location={props.location}>
      <main
        className="navigation-spacer"
        css={`
          background: #f8f8f8;
        `}
      >
        <Metadata
          title="Mobile App"
          description="Latitude Financial Services is a rebranded consumer finance company in Australia &amp; NZ, offering loans, credit cards, insurance, and retail finance."
          canonical="/mobile-app/"
        />

        <HeroBranded
          fastApply
              className="hero-control mobile-hero"
              css={`
                background: linear-gradient(128.6deg, #0146AA, #0162ED);
                @media (max-width: ${BREAKPOINT.MD}) {
                  .HeroContent {
                    background-color: transparent;
                  }
                }
              `}
              imageContent={
                <div
                  css={`
                    height: 100%;
                    display: flex;
                    align-items: flex-start;
                    justify-content: center;
                    @media (min-width: ${BREAKPOINT.LG}) {
                      justify-content: flex-start;
                    }
                  `}
                >
                  <img
                    src={imgHero}
                    alt=""
                    css={`
                      height: 260px;
                      width: auto;
                      position: absolute;
                      bottom: 0px;
                      @media (min-width: ${BREAKPOINT.MD}) {
                        height: 300px;
                      }
                      @media (min-width: ${BREAKPOINT.LG}) {
                        height: 400px;
                        position: absolute;
                        bottom: 0px;
                        left: 160px;
                      }
                    `}
                  />
                </div>
              }
              title={
                <div css={`color: white;`}>Get the Latitude App.</div>
              }
              text={
                <div
                  css={`
                    padding: 8px 0 0;
                  `}
                >
                  <div
                    css={`
                      margin-bottom: 24px;
                      color: white;
                    `}
                  >
                    Manage your account and make repayments in just a few taps with
                    our mobile app.
                  </div>
                  <div
                    css={`
                      color: white;
                      margin: 12px 0;
                    `}
                  >
                    Download now!
                  </div>

                  <AnalyticsLocationProvider location="hero">
                    <AppStoreBadgeContainer>
                      <AppStoreBadge url="https://apps.apple.com/app/apple-store/id1373059866" />
                      <AppStoreBadge url="https://play.google.com/store/apps/details?id=com.latitudefinancial.latitudeapp" />
                    </AppStoreBadgeContainer>
                  </AnalyticsLocationProvider>
                </div>
          }
        />

        <div className="d-none d-lg-block" css="position:relative; z-index:10;">
          <StickyNavigationBranded
            items={PageData.nav}
            offsetElem="[data-sticky-navigation-offset]"
            {...state?.inPageNavData?.[0]}
          />
        </div>
        <FeaturesSlider
          key={(state?.featureSliderData?.[0] || featureSliderData[0]).heading}
          id="features"
          heading={
            (state?.featureSliderData?.[0] || featureSliderData[0]).heading
          }
          subheading={
            (state?.featureSliderData?.[0] || featureSliderData[0]).description
          }
          data={
            (state?.featureSliderData?.[0] || featureSliderData[0]).featureCards
          }
        />
        <ImageSliderSection />

        <LinkYourCardSection
          anchor="payment"
          title={
            <span>
              Link your card on the<br></br> Latitude App
            </span>
          }
        />

        <HowToGuidesSection
          id="how-to-guide"
          anchor="how-to-guide"
          heading="How to Guides"
        />
        <HorizontalRule />

        <FaqsSection anchor="faq" title="FAQs" contentful={state?.faqData?.[0]} />

        <BrandedCallout
          line1={
            <React.Fragment>
              For FAQs, useful information and quick and easy forms
            </React.Fragment>
          }
          cta={
            <Box paddingTop={PADDING.P24} width="210px">
              <Link
                button={BUTTON_STYLE.SECONDARY_INVERSE}
                href="https://latitudefs.zendesk.com/hc/en-au/categories/360003342078-Latitude-Mobile-App"
                trackId="callout-help-btn"
                target="_blank"
                rel="noopener noreferrer"
              >
                Visit our Help Centre
              </Link>
            </Box>
          }
        />
      </main>
    </Layout>
  );
};

export default MobileApp;
